export const isSystemVerifiedRow = (name?: string): boolean => {
    const verifiedInvestments: string[] = [
        "Listed Derivatives",
        "Listed Securities",
        "Managed Funds",
        "Managed Investments",
        "Shares In Listed Companies",
        "Derivatives",
        "Units In Listed Unit Trusts",
        "Stapled Securities"
    ];

    return !!name && verifiedInvestments.some(v => name.toLowerCase().startsWith(v.toLowerCase()));
}

export const isBankRow = (name: string): boolean => {
    let bankNames = [
        "Bank",
        "Cash At Bank",
        "Foreign Cash at bank",
        "CashBankAccounts",
        "Property"
    ];
    return bankNames.indexOf(name) !== -1;
}