import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    eventSummaryId: number;
    jobId: number;
}

function useDeleteEventSummary() {
    const context = useContext(Context);
    const {
        mutate: deleteItem,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.proceduresUrl}/api/1.0/jobs/${data.jobId}/eventSummary/${data.eventSummaryId}`));

    return {deleteItem, isDeleting};
}

export {useDeleteEventSummary};