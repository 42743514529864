import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    itemId: number;
    jobId:number;
}

function useDeleteJobSuggestedTestingItem() {
    const context = useContext(Context);
    const {
        mutate: deleteItem,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.suggestedTestingUrl}/api/1.0/jobs/${data.jobId}/suggestedTesting/${data.itemId}`));

    return {deleteItem, isDeleting};
}

export {useDeleteJobSuggestedTestingItem};