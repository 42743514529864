import { useQuery } from "react-query";
import * as localStorageService from '../services/localStorageService';
import {REFRESH_TOKEN_KEY} from "../constants/authorization";
import { commonHeaders } from "utils";
import axios, { AxiosError } from "axios";

type StorageConfigResponse = {
    references: string[]
};


function useGetStorageConfiguration(url: string, tenantId: string) {
    const refreshToken: string = localStorageService.getData(REFRESH_TOKEN_KEY);

    const {
        data: segments,
        isLoading,
        error
    } = useQuery(
        ['getStorageConfiguration'],
        () => axios.get<string[]>(`${url}/api/1.0/storage/files/configuration`, commonHeaders()),
        {
            enabled: !!url && !!tenantId && !!refreshToken && refreshToken !== 'undefined',
            retry: false,
            onError: (error:AxiosError) => {
            }
        }
    );

    return {segments, isLoading, error};
}

export {useGetStorageConfiguration};