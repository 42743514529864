import { IComboBoxOption, SelectableOptionMenuItemType, VirtualizedComboBox, useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

interface ISelectProps {
    label?: string;
    placeholder?: string;
    options: IComboBoxOption[];
    selectAll?: boolean;
    dropdownWidth?: number;
    dropdownMaxWidth?: number;
    allowFreeform?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    onChange: (option: any) => void;
    selectedKey?: string;
}

export const Select = ({
    options,
    selectAll,
    label,
    disabled,
    placeholder,
    dropdownWidth,
    dropdownMaxWidth,
    allowFreeform,
    autoComplete,
    onChange,
    selectedKey,
}: ISelectProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const selectAllOption = {
        key: 'selectAll',
        text: formatMessage({ id: 'selectAll' }),
        itemType: SelectableOptionMenuItemType.SelectAll,
    };

    const defaultStyles = {
        label: { color: theme.schemes?.default?.semanticColors.bodyText },
        root: {
            '.ms-Icon': { color: theme.schemes?.default?.semanticColors.bodyText },
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            ':after': { border: '1px ' + theme.schemes?.default?.palette.blackTranslucent40 },
            '.ms-ComboBox .ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
        },
        input: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            '::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        callout: {
            '.ms-ComboBox-optionText': { color: theme.schemes?.default?.semanticColors.bodyText },
        },
        rootHovered: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootDisabled: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootPressed: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootFocused: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
    };

    const [filterText, setFilterText] = useState<string | undefined>(''); // State to store the filter text
    const [filteredOptions, setFilteredOptions] = useState(options); // State to store filtered options

    const filterOptions = React.useCallback(
        (inputText?: string) => {
            const filtered =
                !!inputText && inputText !== ''
                    ? options.filter((option) => option.text.toLowerCase().includes(inputText.toLowerCase()))
                    : options;

            // Include the "Select All" option if necessary
            if (selectAll) {
                return [selectAllOption, ...filtered];
            }

            return filtered;
        },
        [options]
    );

    const handleInputChange = React.useCallback(
        (newValue?: string) => {
            setFilterText(newValue);
            setFilteredOptions(filterOptions(newValue));
        },
        [filterOptions]
    );

    return (
        <VirtualizedComboBox
            onBlur={() => {setFilteredOptions(options)}}
            calloutProps={{
                styles: { root: { width: 'max-content' } },
            }}
            openOnKeyboardFocus
            styles={defaultStyles}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            options={
                !!filterText
                    ? selectAll
                        ? [selectAllOption, ...filteredOptions]
                        : filteredOptions
                    : selectAll
                    ? [selectAllOption, ...options]
                    : options
            }
            dropdownWidth={dropdownWidth}
            dropdownMaxWidth={dropdownMaxWidth}
            onChange={(_, option) => onChange(option)}
            selectedKey={selectedKey}
            allowFreeform={allowFreeform}
            autoComplete={autoComplete ? 'on' : 'off'}
            onInputValueChange={handleInputChange}
        />
    );
};
