import {IInvoice, IInvoiceExtraAmount, NewInvoice} from "../interfaces";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";
import {useCreateInvoice} from "../hooks";
import {
    DefaultButton,
    IColumn,
    mergeStyleSets,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from "@fluentui/react";
import {
    Card,
    ControlledDatePicker,
    ControlledDropdown,
    ControlledNumberField,
    DataTable
} from "../../../../../components";
import {useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {InvoiceParagraphType} from "../enums";
import {_ParagraphDropdownOptions, calculateExtraAmountSum, calculateTotal} from "../helpers";
import {BillingEntityType} from "../../../enums";
import { useGetFees } from "../../../hooks";

interface IInvoiceAddMultipleFormPros {
    items: IInvoice[];
    onClose: () => void;
    onSubmit?: (item: IInvoice) => void;
}

interface IInvoiceAddMultipleFormItem {
    invoiceDate: Date,
    paragraph: InvoiceParagraphType,
    items: SimplifiedInvoice[]
}

type SimplifiedInvoice = Pick<IInvoice, 'invoiceJobs' | 'amount'> & { extraAmount: number };

export const InvoiceAddMultipleForm: FunctionComponent<IInvoiceAddMultipleFormPros> = ({ items, onClose, ...props }: IInvoiceAddMultipleFormPros) => {
    const { formatMessage, formatNumber } = useIntl();

    const { clientIdentifier } = useInvoicePortalContext();
    const { create, isCreating} = useCreateInvoice(clientIdentifier);

    const [selectedJobIds, setSelectedJobIds] = useState<number[]>([]);
    const {fees, isFeesLoading, refetchFees} = useGetFees(clientIdentifier, selectedJobIds);
    
    const [totalAmount, setTotalAmount] = useState<number>(0);

    const { control, watch, setValue, getValues, handleSubmit, formState } = useForm<IInvoiceAddMultipleFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            items: [...items.map(i => ({ invoiceJobs: [...i.invoiceJobs], amount: i.amount, extraAmount: 0}))],
            paragraph: InvoiceParagraphType.DD
        }
    });

    const {fields, append, update, remove} = useFieldArray({
        control,
        name: 'items'
    })

    const onSubmit = (formItem: IInvoiceAddMultipleFormItem) => {
        create({
            requests: [...formItem.items.map((i, index) => ({
                billingEntityType: BillingEntityType.Client,
                paragraph: formItem.paragraph,
                gst: items[index].gst,
                amount: i.amount,
                extraAmounts: [{ amount: i.extraAmount, paragraph: 1 }],
                jobId: i.invoiceJobs[0].jobId,
                creationDate: new Date(formItem.invoiceDate)
            } as NewInvoice))
            ],
            applyCreditNotes: false,
            creationDate: formItem.invoiceDate,
            paragraph: formItem.paragraph
        }, {
            onSuccess: (resp: any) => {
                props.onSubmit?.(resp.data);
            }
        })
    }

    const amountsWatcher = watch([...fields.map((field, index: number) => `items.${index}.amount`)] as any);
    const extraAmountsWatcher = watch([...fields.map((field, index: number) => `items.${index}.extraAmount`)] as any);

    useEffect(() => {
        setTotalAmount([...amountsWatcher, ...extraAmountsWatcher].reduce((acc, i) => acc + +i, 0) ?? 0);
    }, [amountsWatcher, extraAmountsWatcher]);
    
    const columns: IColumn[] = useMemo(() => [
        {
            key: 'jobName',
            name: formatMessage({ id: 'job' }),
            fieldName: 'jobName',
            minWidth: 100,
            maxWidth: 120,
            isMultiline: true,
            onRender: (item: SimplifiedInvoice, index?: number) => (
                <Text>{item.invoiceJobs[0]?.jobName}</Text>
            )
        },
        {
            key: 'fundName',
            name: formatMessage({ id: 'fund' }),
            fieldName: 'fundName',
            minWidth: 190,
            maxWidth: 500,
            isMultiline: true,
            onRender: (item: SimplifiedInvoice, index?: number) => (
                <Text>{item.invoiceJobs[0]?.fundName}</Text>
            ),
        },
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: SimplifiedInvoice, index?: number) => (
                <ControlledNumberField
                    name={`items.${index!}.amount`}
                    control={control}
                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                />
            ),
        },
        {
            key: 'extraAmount',
            name: formatMessage({ id: 'extraAmount' }),
            fieldName: 'extraAmount',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: SimplifiedInvoice, index?: number) => (
                <ControlledNumberField
                    name={`items.${index!}.extraAmount`}
                    control={control}
                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                />
            ),
        },
        {
            key: 'total',
            name: formatMessage({ id: 'total' }),
            fieldName: 'total',
            minWidth: 55,
            maxWidth: 100,
            isMultiline: true,
            onRender: (item: SimplifiedInvoice, index?: number) => (
                <Text>{formatNumber(calculateTotal(getValues(`items.${index!}.amount`), getValues(`items.${index!}.extraAmount`)), { style: 'currency', currency: 'USD' })}</Text>
            ),
        },
    ], [formatMessage]);

    const onUpdateFeesClick = useCallback(() => {
        setSelectedJobIds(prev => (items ?? []).map(s => s.invoiceJobs[0].jobId))
    }, [items])

    useEffect(() => {
        if (!!fees?.length && !!items.length) {
            for (let i = 0; i < items.length; i++) {
                const fee = fees.find(x => x.jobId === items[i].invoiceJobs[0].jobId);
                if (!fee) continue;
                setValue(`items.${i}.amount`, fee.feeQuote, { shouldDirty: true });
                setValue(`items.${i}.extraAmount`, fee.additionalCharges, { shouldDirty: true });
            }
        }
    }, [fees]);

    const classNames = mergeStyleSets({
        dataTable: {
            '.ms-DetailsRow-cell': {
                'justify-content': 'center'
            }
        }
    })

    const fieldWidth = 'calc(50% - 16px)';
    
    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                       <Stack tokens={{ childrenGap: 16 }}>
                           <Stack.Item>
                               <Stack horizontal horizontalAlign='space-between' tokens={{ childrenGap: 16 }}>
                                   <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                       <ControlledDatePicker
                                           control={control}
                                           name='invoiceDate'
                                           rules={{ required: formatMessage({id: 'creationDateRequired'}) }}
                                           label={formatMessage({ id: 'creationDate' })}
                                       />
                                   </Stack.Item>
                                   <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                       <ControlledDropdown
                                           name='paragraph'
                                           control={control}
                                           label={formatMessage({ id: 'invoiceParagraph' })}
                                           options={_ParagraphDropdownOptions}
                                       />
                                   </Stack.Item>
                               </Stack>
                           </Stack.Item>
                           <Stack.Item>
                               <DataTable className={classNames.dataTable}
                                          initialColumns={columns}
                                          hideIfEmpty={false}
                                          items={fields || []}
                               />
                           </Stack.Item>
                           <Stack.Item>
                               <Stack horizontal horizontalAlign='space-between' verticalAlign='center' tokens={{ childrenGap: 16 }}>
                                   <Stack.Item>
                                       <PrimaryButton disabled={!items.length} onClick={onUpdateFeesClick}>
                                           {isFeesLoading
                                               ? <Spinner size={SpinnerSize.small}></Spinner>
                                               : formatMessage({id: 'updateFees'})}
                                       </PrimaryButton>
                                   </Stack.Item>
                                   <Stack.Item>
                                       <Stack horizontal horizontalAlign={'end'} verticalAlign='center' tokens={{ childrenGap: 16 }}>
                                           <Stack.Item>
                                               <FormattedMessage id={'{count}selected'} values={{count: items.length ?? 0}} />
                                           </Stack.Item>
                                           <Stack.Item>
                                               <FormattedMessage id={'total{value}'} values={{ value: formatNumber(totalAmount, { style: 'currency', currency: 'USD' }) }}/>
                                           </Stack.Item>
                                       </Stack>
                                      
                                   </Stack.Item>
                               </Stack>
                           </Stack.Item>
                       </Stack>
                    </Card>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                        <Stack.Item>
                            <PrimaryButton disabled={!formState.isDirty || !formState.isValid || isCreating}
                                           onClick={handleSubmit(onSubmit)} >
                                {isCreating
                                    ? <Spinner size={SpinnerSize.small}></Spinner>
                                    : formatMessage({id: 'save'})}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton disabled={isCreating} text={formatMessage({id: 'close'})} onClick={onClose} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    )
}