import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";
import { FeeCalculationAnswer } from "../Types";

type MutateDataType = {
    jobId: number;
    jobGuid: string;
    feeCalculationId: number;
    answer: FeeCalculationAnswer | null;
    manualFeeAmount: number | null;
    feeAmount: number | null;
    howManyQuantity: number | null;
}

function useSaveFeeCalculationItem() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.calculationUrl}/api/1.0/feeCalculation`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getFeeCalculationSection'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useSaveFeeCalculationItem};