import {Card, ControlledDatePicker, ControlledDropdown} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Stack} from '@fluentui/react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, useImperativeHandle, useMemo} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";
import {useGetEmployeesByPosition} from "pages/WorkflowPortal/Employees";
import {useUpdateJobManagerInfo} from "../../../hooks";
import {EmployeePosition} from "pages/WorkflowPortal/shared";

interface IUpdateJobManagerInfoCommand {
    jobGuid: string;
    managerId?: number;
    managerPointsRaised?: Date;
    managerReviewed?: Date;
    managerQueriesReviewed?: Date;
}

export const ManagerTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {
        employeesByPosition: managers,
        isEmployeesLoading: isManagersLoading
    } = useGetEmployeesByPosition(EmployeePosition.Manager);
    const {updateJobManagerInfo, isManagerInfoUpdating} = useUpdateJobManagerInfo();
    const {control, handleSubmit} = useForm<IUpdateJobManagerInfoCommand>({
            ...DefaultFormSettings,
            defaultValues: {
                managerId: props.job?.manager?.id,
                managerPointsRaised: props.job?.manager?.pointsRaised,
                managerReviewed: props.job?.manager?.reviewed,
                managerQueriesReviewed: props.job?.manager?.queriesReviewed
            }
        })
    ;

    const managerOptions = useMemo<IDropdownOption[]>(() => {
        return managers?.data.map((manager) => ({
            key: manager.id,
            text: `${manager.firstName} ${manager.lastName}`
        })) || Array.of<IDropdownOption>();
    }, [managers]);

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: any) => {
        updateJobManagerInfo({
            jobGuid: props.job?.guid || '',
            managerId: data.managerId,
            managerPointsRaised: data.managerPointsRaised,
            managerReviewed: data.managerReviewed,
            managerQueriesReviewed: data.managerQueriesReviewed
        }, {
            onSuccess: () => {
                if (props.onSubmitted)
                    props.onSubmitted();
            }
        });
    };


    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='managerId'
                        label={formatMessage({id: 'manager'})}
                        options={managerOptions || []}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='managerPointsRaised'
                        label={formatMessage({id: 'mgrPointsRaised'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='managerQueriesReviewed'
                        label={formatMessage({id: 'mgrPointsReviewedByAuditor'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='managerReviewed'
                        label={formatMessage({id: 'readyForPartnerReview'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});