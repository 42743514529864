import * as React from 'react';
import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {FormState} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {observer} from 'mobx-react';
import {
    DefaultButton,
    IShimmerElement,
    Label,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Shimmer,
    ShimmerElementType,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import {Card, ControlledMonacoEditor, ControlledNumberField, ControlledTextField} from 'components';
import {ICreateRuleCommand} from 'interfaces';
import {useBoolean} from '@fluentui/react-hooks';
import {useIsDestroy} from 'utils';
import {takeUntil} from 'rxjs';
import {RulesetRuleDeleteDialog} from './RulesetRuleDeleteDialog';
import {RulesetRuleRestoreDialog} from './RulesetRuleRestoreDialog';
import {useRulesetStore} from '../../RulesetLayoutPage';
import {CardBody, CardFooter} from 'reactstrap';
import {ScopedVariables} from '../ScopedVariables/components/ScopedVariables';
import {usePrompt} from 'hooks/usePrompt';
import {useNotifications} from "../../../../../components/notifications";

interface Props {
    control: Control<ICreateRuleCommand>;
    onSubmit: () => void;
    formState: FormState<ICreateRuleCommand>;
    isEditing?: boolean;
}

export const RulesetRuleEditForm: FunctionComponent<Props> = observer(({
                                                                           control,
                                                                           onSubmit,
                                                                           formState,
                                                                           isEditing
                                                                       }: Props) => {
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {
        rulesetLoading,
        rule,
        ruleset,
        rulesetRuleDeleting,
        rulesetRuleRestoring,
        deleteRule,
        restoreRule,
        loadRuleset
    } =
        useRulesetStore();

    const shimmerElement: IShimmerElement[] = [
        {
            type: ShimmerElementType.line,
            height: 30,
            width: 145,
            verticalAlign: 'bottom',
        },
    ];
    const shimmerElementExpression: IShimmerElement[] = [
        {
            type: ShimmerElementType.line,
            height: 200,
            verticalAlign: 'bottom',
        },
    ];

    const existedExecutionOrders: number[] =
        ruleset?.rules?.filter((r) => r.id !== rule?.id && !r.isDeleted)?.map((r) => r.executionOrder) ?? [];

    const [hiddenDeleteDialog, {toggle: toggleHiddenDeleteDialog}] = useBoolean(true);
    const [hiddenRestoreDialog, {toggle: toggleHiddenRestoreDialog}] = useBoolean(true);
    const [disablePrompt, setDisablePrompt] = React.useState<boolean>(false);
    const isDestroy = useIsDestroy();

    const whenPrompt = formState.isDirty && !disablePrompt && !isEditing;
    usePrompt(formatMessage({id: 'youHaveUnsavedChangesDoYouWantToLeave'}), whenPrompt);

    React.useEffect(() => {
        if (whenPrompt) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [whenPrompt]);

    const onDelete = () => {
        setDisablePrompt(true);
        deleteRule(rule!.id)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                showNotification({
                    name: formatMessage({id: 'success'}),
                    type: MessageBarType.success,
                    description: formatMessage({id: 'successRuleDeleteMessage'}),
                });
                toggleHiddenDeleteDialog();
                loadRuleset();
            });
        setDisablePrompt(false);
    };
    const onRestore = () => {
        setDisablePrompt(true);
        restoreRule(rule!.id)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                showNotification({
                    name: formatMessage({id: 'success'}),
                    type: MessageBarType.success,
                    description: formatMessage({id: 'successRuleRestoreMessage'}),
                });
                toggleHiddenRestoreDialog();
                loadRuleset();
            });
        setDisablePrompt(false);
    };

    // const isSomeDirtyFields = React.useMemo(() => {
    //     return (
    //         formState.dirtyFields.executionOrder ||
    //         formState.dirtyFields.name ||
    //         formState.dirtyFields.expression ||
    //         formState.dirtyFields.variables
    //     );
    // }, [
    //     formState.dirtyFields.executionOrder,
    //     formState.dirtyFields.expression,
    //     formState.dirtyFields.name,
    //     formState.dirtyFields.variables,
    // ]);

    return (
        <>
            <Card styles={{root: {height: '100%', boxSizing: 'border-box', overflowY: 'auto'}}}>
                <CardBody>
                    <Pivot linkSize='large' styles={{link: {width: '50%'}}}>
                        <PivotItem headerText={rule?.ruleName ?? 'New rule'}>
                            <Stack tokens={{childrenGap: 16}}>
                                <Stack tokens={{childrenGap: 16}} horizontal horizontalAlign='start'>
                                    <Stack.Item styles={{root: {width: 215}}}>
                                        {rulesetLoading ? (
                                            <>
                                                <Label>{formatMessage({id: 'executionOrder'})}</Label>
                                                <Shimmer shimmerElements={shimmerElement}/>
                                            </>
                                        ) : (
                                            <ControlledNumberField
                                                label={formatMessage({id: 'executionOrder'})}
                                                name='executionOrder'
                                                control={control}
                                                rules={{
                                                    required: formatMessage({id: 'requiredRuleExecutionOrder'}),
                                                    min: {
                                                        value: 1,
                                                        message: formatMessage({id: 'minValue'}, {value: 0}),
                                                    },
                                                    validate: (value) =>
                                                        existedExecutionOrders.includes(Number(value))
                                                            ? formatMessage(
                                                                {id: 'executionOrderShouldBeUnique'},
                                                                {values: existedExecutionOrders.join(', ')}
                                                            )
                                                            : true,
                                                }}
                                                disabled={rule?.isDeleted}
                                            />
                                        )}
                                    </Stack.Item>
                                    <Stack.Item styles={{root: {width: '100%'}}}>
                                        {rulesetLoading ? (
                                            <>
                                                <Label>{formatMessage({id: 'ruleName'})}</Label>
                                                <Shimmer
                                                    shimmerElements={[
                                                        {
                                                            type: ShimmerElementType.line,
                                                            height: 32,
                                                        },
                                                    ]}
                                                />{' '}
                                            </>
                                        ) : (
                                            <ControlledTextField
                                                name='name'
                                                control={control}
                                                rules={{required: formatMessage({id: 'requiredRuleName'})}}
                                                disabled={rule?.isDeleted}
                                                label={formatMessage({id: 'ruleName'})}
                                                styles={{
                                                    wrapper: {
                                                        display: 'block',
                                                        label: {paddingLeft: 0, height: 'auto'},
                                                    },
                                                }}
                                            />
                                        )}
                                    </Stack.Item>
                                </Stack>
                                <Stack.Item>
                                    {rulesetLoading ? (
                                        <>
                                            <Label>{formatMessage({id: 'expression'})}</Label>
                                            <Shimmer shimmerElements={shimmerElementExpression}/>
                                        </>
                                    ) : (
                                        <ControlledMonacoEditor
                                            label={formatMessage({id: 'expression'})}
                                            name='expression'
                                            control={control}
                                            rules={{required: formatMessage({id: 'requiredRuleExpression'})}}
                                            height={130}
                                        />
                                    )}
                                </Stack.Item>
                                <Stack.Item>
                                    {rulesetLoading ? (
                                        <>
                                            <Label>{formatMessage({id: 'onSuccess'})}</Label>
                                            <Shimmer shimmerElements={shimmerElementExpression}/>
                                        </>
                                    ) : (
                                        <ControlledMonacoEditor
                                            label={formatMessage({id: 'onSuccess'})}
                                            name='onSuccess'
                                            control={control}
                                            height={130}
                                        />
                                    )}
                                </Stack.Item>
                                <Stack.Item>
                                    {rulesetLoading ? (
                                        <>
                                            <Label>{formatMessage({id: 'onFailed'})}</Label>
                                            <Shimmer shimmerElements={shimmerElementExpression}/>
                                        </>
                                    ) : (
                                        <ControlledMonacoEditor
                                            label={formatMessage({id: 'onFailed'})}
                                            name='onFailed'
                                            control={control}
                                            height={130}
                                        />
                                    )}
                                </Stack.Item>
                            </Stack>
                        </PivotItem>
                        <PivotItem headerText={(rule?.ruleName ?? 'New rule') + ' variables'}>
                            <ScopedVariables/>
                        </PivotItem>
                    </Pivot>
                </CardBody>
                <CardFooter>
                    <Stack horizontal horizontalAlign='start' reversed tokens={{childrenGap: 16}}
                           styles={{root: {marginTop: 15}}}>
                        <PrimaryButton
                            disabled={!formState.isDirty}
                            //text={formatMessage({ id: 'save' })}
                            onClick={onSubmit}
                            onRenderChildren={() =>
                                isEditing ? <Spinner size={SpinnerSize.small}/> : <>{formatMessage({id: 'save'})}</>
                            }
                        />
                        {rule && !rule.isDeleted ? (
                            <DefaultButton
                                disabled={rulesetLoading}
                                text={formatMessage({id: 'delete'})}
                                onClick={toggleHiddenDeleteDialog}
                            />
                        ) : rule && rule.isDeleted ? (
                            <DefaultButton
                                disabled={rulesetLoading}
                                text={formatMessage({id: 'restore'})}
                                onClick={toggleHiddenRestoreDialog}
                            />
                        ) : null}
                    </Stack>
                </CardFooter>
            </Card>
            <RulesetRuleDeleteDialog
                hidden={hiddenDeleteDialog}
                toggleHidden={toggleHiddenDeleteDialog}
                onAgree={onDelete}
                yesDisabled={rulesetRuleDeleting}
            />
            <RulesetRuleRestoreDialog
                hidden={hiddenRestoreDialog}
                toggleHidden={toggleHiddenRestoreDialog}
                onAgree={onRestore}
                yesDisabled={rulesetRuleRestoring}
            />
        </>
    );
});
