import {FontSizes, IconButton, PrimaryButton, Spinner, SpinnerSize, Stack} from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import {useIntl} from 'react-intl';
import {useGetJobByGuid} from 'hooks/useGetJobByGuid';
import {useParams} from 'react-router-dom';
import {useCreateAttachment} from '../hooks/attachments';
import {TableType} from "../../../enums";
import Dropzone, {useDropzone} from "react-dropzone";
import { PortalRole, useWorkContext } from "../../../providers";
import { useTabContext } from "../JobPortalPage";

interface IUploadAttachment {
    itemId: number;
    tableType: TableType;
    label?: string;
    disabled?: boolean;
}

export const UploadAttachment: FunctionComponent<IUploadAttachment> = ({itemId, tableType, label, disabled}) => {
    const {createAttachment, isLoading: isCreateLoading} = useCreateAttachment();
    const ref = useRef<HTMLInputElement>(null);
    const {formatMessage} = useIntl();
    const {isTabEnabled} = useTabContext();
    const {guid} = useParams();
    const {dataJobs} = useGetJobByGuid({guid});

    const upload = useCallback((selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        createAttachment({
            fundId: dataJobs?.data?.fund?.id,
            jobId: dataJobs?.data?.id,
            clientId: dataJobs?.data?.client?.id,
            itemId,
            tableType: tableType,
            data: formData,
        });
    }, [])

    const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsUploadEnabled(!disabled && !isCreateLoading && isTabEnabled);
    }, [disabled, isCreateLoading, isTabEnabled]);

    const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isUploadEnabled) return;
        
        const fileList = e.target.files;
        if (!fileList?.length) return;

        upload(fileList[0]);
    }, [isUploadEnabled]);

    const onDrop = useCallback(files => {
        if (!isUploadEnabled) return;
        if (!files?.length) return;

        upload(files[0])
    }, [isUploadEnabled])

    return (
        <Dropzone onDrop={onDrop} noDragEventsBubbling={true}>
            {({getRootProps, getInputProps, inputRef}) => (
                <div {...getRootProps()}>
                    <Stack styles={{root: {width: 'max-content'}}}>
                        <PrimaryButton
                            disabled={!isUploadEnabled}
                            iconProps={{iconName: 'Upload'}}
                            width='100%'
                            text={label ?? formatMessage({id: 'uploadAttachment'})}
                        >
                        </PrimaryButton>
                        <input
                            name='attachmentBlob'
                            ref={inputRef}
                            style={{display: 'none'}}
                            type='file'
                            onChange={onUpload}
                            disabled={!isUploadEnabled}
                            {...getInputProps()}
                        />
                    </Stack>

                </div>
            )}
        </Dropzone>

    );
};
