import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDateType = {
    jobId: number;
}

function useCreateAduitTrail() {
    const context = useContext(Context);
    const host = context?.config?.proceduresUrl;

    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: MutateDateType) => axios.post(`${host}/api/1.0/procedures/auditTrail/${data.jobId}`, null, commonHeaders()));

    return {create, createAsync, isCreating};
}

export {useCreateAduitTrail}