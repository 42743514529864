import * as React from 'react';
import {Outlet} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';


export const AdministrationLayoutPage = () => {
    const {formatMessage} = useIntl();


    return (

        <PageWithBreadcrumb
            breadcrumb={{
                id: 'administration',
                key: RouterPaths.administration.root,
                text: formatMessage({id: 'administration'}),
            }}>
            <Outlet/>
        </PageWithBreadcrumb>
    );
};