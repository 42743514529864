import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {BaseDialog} from 'components';

interface Props {
    noDisabled?: boolean;
    yesDisabled?: boolean;
    hidden: boolean;
    toggleHidden: () => void;
    onAgree: () => void;
}

export const RulesetDeleteDialog: FunctionComponent<Props> = (props: Props) => {
    const {formatMessage} = useIntl();
    return (
        <BaseDialog
            title={formatMessage({id: 'deleteRulesetDialogTitle'})}
            subText={formatMessage({id: 'deleteRulesetDialogSubText'})}
            {...props}
        />
    );
};
