import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';

export const DashboardWorkflowLayoutPage = () => {
	const { formatMessage } = useIntl();
	return (
		<PageWithBreadcrumb
			breadcrumb={{
				id: 'workflowDashboard',
				key: RouterPaths.workflow.dashboard,
				text: formatMessage({ id: 'dashboard' }),
			}}>
			<Outlet />
		</PageWithBreadcrumb>
	);
};
