import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {useNotifications} from "../../../../components/notifications";
import {useWorkflows} from "../../WorkflowLayoutPage";
import {useIntl} from "react-intl";
import {MessageBarType} from "@fluentui/react";


type MutateDataType = {
    fundId: number;
    clientId: number;
    code: string;
};

function useUpdateFundsAuditInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: updateFundsAuditInfo, isLoading: isUpdatingFundsAuditInfo} = useMutation(
        (data: MutateDataType) =>
            axios.put(
                `${context?.config?.clientUrl}/api/1.0/clients/${data.clientId}/funds/${data.fundId}/auditInfo`,
                {code: data.code},
                commonHeaders()
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getFundsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Fund audit info'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateFundsAuditInfo, isUpdatingFundsAuditInfo};
}

export {useUpdateFundsAuditInfo};
