import {IColumn, IDetailsRowProps, Stack, useTheme} from '@fluentui/react';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import {cloneElement, ReactElement, useMemo} from 'react';
import { useIntl } from 'react-intl';
import { ReportTable } from '../Table/ReportTable';
import { formatDate } from 'utils';
import { SanitizedText } from 'components';
import {ReportTemplateTypeProps} from "../Types";



export const ClassSuperFeedsConsole = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const getItemColor = (item: any) => {
        return item?.colorLine === 'red'
            ? theme.palette.red
            : item?.colorLine === 'green'
                ? theme.palette.green
                : 'inherit'
    }
    
    const onRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        
        return defaultRender({
            ...props
        })
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 200,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'status',
                name: formatMessage({ id: 'status' }),
                minWidth: 140,
                maxWidth: 200,
                fieldName: 'status',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'product',
                name: formatMessage({ id: 'product' }),
                minWidth: 200,
                maxWidth: 300,
                fieldName: 'product',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'provider',
                name: formatMessage({ id: 'provider' }),
                minWidth: 140,
                maxWidth: 200,
                fieldName: 'provider',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'accountName',
                name: formatMessage({ id: 'accountName' }),
                minWidth: 200,
                maxWidth: 300,
                fieldName: 'accountName',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'firstProcessed',
                name: formatMessage({ id: 'firstProcessed' }),
                minWidth: 140,
                maxWidth: 200,
                fieldName: 'firstProcessed',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'lastProcessed',
                name: formatMessage({ id: 'lastProcessed' }),
                minWidth: 140,
                maxWidth: 200,
                fieldName: 'lastProcessed',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'feed',
                name: formatMessage({ id: 'feed' }),
                minWidth: 140,
                maxWidth: 200,
                fieldName: 'feed',
                onRender: (item, _, column) => <SanitizedText color={getItemColor(item)} data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable onRenderRow={onRowRender} initialColumns={columns} items={items} isFetching={isFetching} />;
};
