import { IColumn, Stack } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { dateFortmatString, formatDate, formatStringDate } from "utils";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const SuperMateRealisedCapitalGains = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'accountNumber',
                name: formatMessage({ id: 'accountNumber' }),
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'accountNumber',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'name',
                name: formatMessage({ id: 'accountDescription' }),
                minWidth: 200,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {<GeneratedAnswer itemId={item?.id} tableType={item?.reportType}
                            auto={item?.isAnswerDisabled} disabled={item?.isAnswerDisabled}
                            value={item?.answerText} answerControlType={item?.answerControlType} />}
                    </Stack>
                )
            },
            {
                key: 'investmentCode',
                name: formatMessage({ id: 'investmentCode' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'investmentCode',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'dateAcquired',
                name: formatMessage({ id: 'dateAcquired' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'dateAcquired',
                onRender: (item, _, column) => <SanitizedText data={formatStringDate(item[column?.fieldName || 0], 'DD/MM/yyyy')} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'dateDisposed',
                name: formatMessage({ id: 'dateDisposed' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'dateDisposed',
                onRender: (item, _, column) => <SanitizedText data={formatStringDate(item[column?.fieldName || 0], 'DD/MM/yyyy')} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'amountReceived',
                name: formatMessage({ id: 'amountReceived' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'amountReceived',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'costBaseUsed',
                name: formatMessage({ id: 'costBaseUsed' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'costBaseUsed',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'costBaseAdjustment',
                name: formatMessage({ id: 'costBaseAdjustment' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'costBaseAdjustment',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'indexPurchaseSale',
                name: formatMessage({ id: 'gainMethod' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'indexPurchaseSale',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'adjustedCost',
                name: formatMessage({ id: 'adjustedCost' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'adjustedCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'gainLoss',
                name: formatMessage({ id: 'gainLoss' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'gainLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'lossesRecouped',
                name: formatMessage({ id: 'lossesRecouped' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'lossesRecouped',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
