import {IIconProps, TooltipHost} from "@fluentui/react";
import {IStatusFilterParams, StatusFilter} from "./StatusFilter";
import React, {FunctionComponent, ReactNode} from "react";
import {useIntl} from "react-intl";
import {ExportActions, IExportProps} from "./ExportActions";
import {IconButton} from "@fluentui/react/lib/Button";
import {useId} from "@fluentui/react-hooks";
import {ISearchRowProps, SearchRow} from "components";

export interface IFilterParams extends ISearchRowProps {

    status?: IStatusFilterParams;
    export?: IExportProps;
    hideStatusFilter?: boolean;
    additionalFilter?: boolean;
    placeholders?: {
        add?: string;
    }
    children?: ReactNode;
    onAddClick?: Function;
}

export const DataTableFilter: FunctionComponent<IFilterParams> = (props: IFilterParams) => {

    const {formatMessage} = useIntl();

    const add: IIconProps = {iconName: 'add'};
    const addBtnToolTipId = useId('add-tooltip');

    const leftSide = !props.hideStatusFilter && (<StatusFilter selectedStatus={props.status?.selectedStatus || null}
                                                               changeStatus={(newValue: string) => props.status?.changeStatus && props.status.changeStatus(newValue)}/>);

    const rightSide = (<>
            {props.export && (<ExportActions onPdfClick={props.export?.onPdfClick}
                                             onExcelClick={props.export?.onExcelClick}/>)}
            {props.onAddClick && (<TooltipHost
                content={props.placeholders?.add || formatMessage({id: 'add'})}
                id={addBtnToolTipId}>
                <IconButton aria-describedby={addBtnToolTipId} iconProps={add}
                            onClick={() => props.onAddClick && props.onAddClick()} label='Add'/>
            </TooltipHost>)}
        </>
    )

    return (
        <SearchRow {...props} leftSide={leftSide} rightSide={rightSide} showAdditionalFilter={props.additionalFilter}
                   search={{
                       onSearch: (newValue) => props.search?.onSearch && props.search.onSearch(newValue),
                       onClear: () => props.search?.onClear && props.search.onClear(),
                       minWidth: props.search?.minWidth,
                       underline: props.search?.underline,
                       placeholder: props.search?.placeholder
                   }}>
            {props.children}
        </SearchRow>
    );
}
