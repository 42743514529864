import {FunctionComponent, useContext, useMemo, useState} from 'react';
import {
    DashboardTableTypeEnum,
    GeneratedDataTable,
    GeneratedFilters,
    ITableFilters,
    useGetTableHeaders,
    useGetTableItems,
    useWorkflowDashboard
} from 'pages/shared/Dashboard';
import {FontWeights, mergeStyleSets, Spinner, SpinnerSize, Stack, useTheme} from '@fluentui/react';
import {IOrderedRequest} from 'interfaces';
import {Context, getSort} from 'utils';
import {useExportData} from 'hooks';
import {useIntl} from "react-intl";
import { useAdministrationDashboard } from 'pages/ClientPortal/Administration';

export interface ITableWidget {
    tableType: DashboardTableTypeEnum;
    isWorkflow?: boolean;
}

export const TableWidget: FunctionComponent<ITableWidget> = ({tableType, isWorkflow = false}) => {
    const theme = useTheme();
    const {formatMessage} = useIntl();

    const workflowDashboardContext = useWorkflowDashboard();
    const administrationDashboardContext = useAdministrationDashboard();
    const dashboardContext = (workflowDashboardContext ?? administrationDashboardContext)!;

    const {
        filterData: {filterByYear, clientGuid, fundGuid, contactGuid},
    } = dashboardContext;

    const initialFilters: ITableFilters = {
        page: 1,
        pageSize: 10,
    };

    const context = useContext(Context);
    const [filters, setFilters] = useState<ITableFilters>(initialFilters);
    const [sort, setSort] = useState<IOrderedRequest | undefined>();

    const {tableHeaders, isTableHeadersLoading} = useGetTableHeaders(tableType);
    // don't use global filters if table has date filter
    const globalFilters = useMemo(
        () => (tableHeaders?.data.hasDateFilter ? {} : {clientGuid, fundGuid, filterByYear, contactGuid}),
        [clientGuid, fundGuid, filterByYear, tableHeaders, contactGuid]
    );
    const {tableItems, isTableItemsLoading} = useGetTableItems({tableType, isWorkflow, ...globalFilters, ...filters, ...sort});
    const {exportData} = useExportData(`${context?.config?.dashboardUrl}/api/1.0/Dashboard`, {tableType, ...globalFilters, ...filters, ...sort});

    const handleSort = (orderdRequest: IOrderedRequest | undefined): void => {
        const newSort = getSort(orderdRequest, tableHeaders?.data.columns || []);
        setSort(newSort);
    };

    const classNames = mergeStyleSets({
        container: {
            height: '100%',
            boxSizing: 'border-box',
            border: `2px solid ${theme.palette.themePrimary}`,
            borderRadius: 2,
        },
        title: {
            padding: '8px 16px',
            backgroundColor: theme.palette.themeTertiary,
        },
        heading: {
            fontWeight: FontWeights.bold,
            color: theme.semanticColors.bodyText
        }
    });

    return isTableHeadersLoading || !tableHeaders?.data ? (
        <Stack verticalAlign={"center"} className={classNames.container} styles={{root: {padding: '16px 0x'}}}
               tokens={{childrenGap: 16}}>
            <Spinner size={SpinnerSize.large} label={formatMessage({id: 'loading'})}/>
        </Stack>
    ) : (
        <Stack tokens={{childrenGap: 16}} className={classNames.container}>
            <Stack.Item className={classNames.title}>
                <GeneratedFilters
                    tableName={tableHeaders.data.tableName}
                    exportData={exportData}
                    searchPlaceholder={tableHeaders.data.searchPlaceholder}
                    setFilters={setFilters}
                    hasDateFilter={tableHeaders.data.hasDateFilter}
                    hasFilterByPeriod={tableHeaders.data.hasFilterByPeriod}
                    hasYearFilter={tableHeaders.data.hasYearFilter}
                    isWidget
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <GeneratedDataTable
                    setFilters={setFilters}
                    itemsData={tableItems?.data}
                    columnsData={tableHeaders.data}
                    isItemsLoading={isTableItemsLoading}
                    setSort={handleSort}
                    isWidget
                    containerProps={{
                        styles: {root: {height: '100%'}},
                    }}
                />
            </Stack.Item>
        </Stack>
    );
};
