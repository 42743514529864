import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IJobOmlItem} from "../interfaces/IJobOmlItem";
import {TableType} from "../../../../../enums";

function useGetOmlItems(jobId: number, itemId: number, tableType: TableType) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getJobOmlItems', jobId, itemId, tableType], () => axios.get<IJobOmlItem[]>(`${context?.config?.queriesUrl}/api/1.0/queries/get/${jobId}?itemId=${itemId}&tableType=${tableType}&raised=false`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.annotationsUrl && !!jobId && !!itemId && !!tableType
    });

    return {response, isLoading, isFetching};
}

function useGetRaisedOmlItems(jobId: number, itemId: number, tableType: TableType) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getJobRaisedOmlItems', jobId, itemId, tableType], () => axios.get<IJobOmlItem[]>(`${context?.config?.queriesUrl}/api/1.0/queries/get/${jobId}?itemId=${itemId}&tableType=${tableType}&raised=true`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.annotationsUrl && !!jobId && !!itemId && !!tableType
    });

    return {response, isLoading, isFetching};
}

export {useGetOmlItems, useGetRaisedOmlItems};