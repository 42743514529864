import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CardTableContainerStyles } from '../../../../../constants/tableStylesPeset';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { Card, DataTable, Modal } from '../../../../../components';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    IconButton,
    IShimmerElement,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Shimmer,
    ShimmerElementType,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { ICreditNote, useCreditNoteContext } from '../inderfaces';
import { formatDate, formatIdNumber } from '../../../../../utils';
import { CreditNoteStatus } from './CreditNoteStatus';
import { useInvoicePortalContext } from '../../../InvoicePortalLayoutPage';
import { useDeleteCreditNote } from '../hooks';
import { BillingEntityType } from '../../../enums';
import { CreditNoteDetailsForm } from './CreditNoteDetailsForm';
import { CreditNoteStatusType } from '../enums';
import { CreditNoteForm } from './CreditNoteForm';
import { IDataTableProps } from '../../../interfaces';
import { useWorkContext } from '../../../../../providers';

interface ICreditNotesDataTableProps extends IDataTableProps<ICreditNote> {}

export const CreditNotesDataTable: FunctionComponent<ICreditNotesDataTableProps> = ({
    items,
    isLoading,
    isFetching,
}: ICreditNotesDataTableProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();
    const { clientIdentifier } = useInvoicePortalContext();
    const { filter } = useCreditNoteContext();
    const { isInRoleOrSuperAdministrator } = useWorkContext();

    const { deleteCreditNote, isDeleting } = useDeleteCreditNote();

    const [addModal, { toggle: toggleAddModal }] = useBoolean(false);

    const [deleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);
    const [deleteItemId, setDeleteItemId] = useState<number | undefined>();

    const [detailsModal, { toggle: toggleDetailsModal }] = useBoolean(false);
    const [detailsItemId, setDetailsItemId] = useState<number | undefined>();

    const shimmerElements: IShimmerElement[] = [{ type: ShimmerElementType.line, height: 8, verticalAlign: 'center' }];

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'add' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleAddModal,
            },
        ],
        [toggleAddModal, formatMessage]
    );

    const onDeleteClick = (item: ICreditNote) => {
        setDeleteItemId(item.id);
        toggleDeleteDialog();
    };

    const onViewClick = (item: ICreditNote) => {
        setDetailsItemId(item.id);
        toggleDetailsModal();
    };

    const onAddSubmit = (item: ICreditNote) => {
        toggleAddModal();
    };

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'creditNoteNumber',
                name: formatMessage({ id: 'creditNoteNumber' }),
                fieldName: 'id',
                minWidth: 90,
                maxWidth: 120,
                onRender: (item: ICreditNote) => (
                    <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                        {formatIdNumber(item.id)}
                    </Text>
                ),
                isMultiline: true,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                fieldName: 'amount',
                minWidth: 80,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: ICreditNote) => (
                    <Text>
                        {formatNumber(item.amount, {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Text>
                ),
            },
            {
                key: 'status',
                name: formatMessage({ id: 'status' }),
                fieldName: 'status',
                minWidth: 60,
                maxWidth: 70,
                isMultiline: true,
                onRender: (item: ICreditNote) => <CreditNoteStatus status={item.status} />,
            },
            {
                key: 'fundName',
                name: `${formatMessage({ id: 'associatedWith' })}\n(${formatMessage({ id: 'client' })}/${formatMessage({ id: 'fund' })})`,
                minWidth: 190,
                isMultiline: true,
                onRender: (item: ICreditNote) => (
                    <Text>{item.billingEntity.entityType === BillingEntityType.Fund ? item.billingEntity.name : 'Client'}</Text>
                ),
            },
            {
                key: 'creationDate',
                name: formatMessage({ id: 'creationDate' }),
                fieldName: 'creationDate',
                minWidth: 115,
                maxWidth: 150,
                isMultiline: true,
                onRender: (item: ICreditNote) => <Text>{item.creationDate ? formatDate(item.creationDate, 'dd/MM/yyyy') : null}</Text>,
            },
            {
                key: 'actions',
                name: '',
                fieldName: 'actions',
                minWidth: 52,
                maxWidth: 52,
                onRender: (item: ICreditNote) => (
                    <Stack horizontal>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'View' }}
                                onClick={() => {
                                    onViewClick(item);
                                }}
                            />
                        </Stack.Item>
                        {isInRoleOrSuperAdministrator() && (
                            <Stack.Item>
                                <IconButton
                                    disabled={item.status === CreditNoteStatusType.Closed}
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}

                                    onClick={() => {
                                        onDeleteClick(item);
                                    }}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                ),
            },
        ],
        [formatMessage]
    );

    const classNames = mergeStyleSets({
        dataTable: {
            '.ms-Shimmer-container': {
                width: '100%',
            },
            '.ms-DetailsRow-cell': {
                'justify-content': 'center',
            },
        },
        blackText: {
            color: theme.schemes?.default?.semanticColors.bodyText,
        },
    });

    return (
        <>
            <Card styles={CardTableContainerStyles}>
                <CommandBar
                    items={[]}
                    farItems={commandBarItems}
                    styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
                />
                <DataTable
                    className={classNames.dataTable}
                    initialColumns={columns}
                    items={items ?? []}
                    enableShimmer={isLoading}
                    shimmerLines={filter.pageSize}
                    selectionMode={SelectionMode.none}
                    setKey='none'
                    isHeaderVisible
                    hideIfEmpty
                    emptyProps={{
                        iconName: 'ComplianceAudit',
                    }}
                    containerHeight='calc(100% - 32px - 16px)'
                    disableDragDrop={true}
                />
                <Text variant='small' className={classNames.blackText}>
                    <FormattedMessage
                        id='youHaveInOutstandingCreditsForSelectedInvoice'
                        values={{ value: formatNumber(0, { style: 'currency', currency: 'USD' }) }}
                    />
                </Text>
            </Card>

            <Dialog
                hidden={!deleteDialog || !deleteItemId}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: formatMessage({ id: 'warning' }),
                    closeButtonAriaLabel: formatMessage({ id: 'close' }),
                    subText: formatMessage({ id: 'deleteCreditNote' }),
                    theme: theme.schemes?.default,
                }}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            deleteCreditNote(
                                { clinetIdentifier: clientIdentifier, id: deleteItemId! },
                                { onSettled: () => setDeleteItemId(undefined) }
                            );
                            toggleDeleteDialog();
                        }}
                        text={formatMessage({ id: 'yes' })}
                    />
                    <DefaultButton
                        onClick={() => {
                            setDeleteItemId(undefined);
                            toggleDeleteDialog();
                        }}
                        text={formatMessage({ id: 'no' })}
                    />
                </DialogFooter>
            </Dialog>

            {detailsItemId && (
                <Modal
                    isOpen={detailsModal}
                    title={`${formatMessage({ id: 'creditNoteNumber' })}: ${detailsItemId}`}
                    onDismiss={() => {
                        toggleDetailsModal();
                    }}>
                    <CreditNoteDetailsForm
                        creditNoteId={detailsItemId!}
                        onClose={toggleDetailsModal}
                        onSubmit={toggleDetailsModal}></CreditNoteDetailsForm>
                </Modal>
            )}

            <Modal isOpen={addModal} title={formatMessage({ id: 'addCreditNote' })} onDismiss={toggleAddModal}>
                <CreditNoteForm onClose={toggleAddModal} onSubmit={onAddSubmit}></CreditNoteForm>
            </Modal>
        </>
    );
};
