import {Card, ControlledDropdown} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IconButton, PrimaryButton, Stack, TooltipHost} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control, useFieldArray} from 'react-hook-form';
import {useId} from "@fluentui/react-hooks";
import {IChangeClientInfoCommand} from "..";
import {useTenantInfo} from "../../../../../../providers";
import {AuthCodeField} from "../../../../shared";

type AccountTabType = {
    control: Control<IChangeClientInfoCommand>;
};

export const APIAuthorisationFields = ({control}: AccountTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const addCodeBtnId = useId("add-code-btn");
    const {fields, append, remove} = useFieldArray({control, name: 'accSoftwareCodes'});

    const addNewCode = () => {
        append({});
    }
    const removeLastCode = (index: number) => {
        remove(index);
    }

    const optionsApi = [
        {key: 'admin', text: formatMessage({id: 'admin'})},
        {key: 'client', text: formatMessage({id: 'client'})},
    ];

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 16}} wrap>
                {!isWorkpapers && (
                    <Stack horizontal verticalAlign={'center'} styles={{root: {width: '100%'}}}
                           horizontalAlign={"space-between"} tokens={{childrenGap: 16}}>

                        <Stack.Item styles={{root: {width: '90%'}}}>
                            <ControlledDropdown
                                options={optionsApi}
                                name='apiAuthorisationType'
                                label={formatMessage({id: 'apiAuthorisationType'})}
                                control={control}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{root: {width: '5%', paddingTop: 25}}}>
                            {fields.length < 6 && (<TooltipHost
                                content={formatMessage({id: 'addNewCode'})}
                                id={addCodeBtnId}>
                                <IconButton aria-describedby={addCodeBtnId} onClick={addNewCode}
                                            iconProps={{iconName: 'add'}}/>
                            </TooltipHost>)}
                        </Stack.Item>
                    </Stack>
                )}

                {fields.map((code, index) => {
                    return (
                        <Stack.Item styles={stackItemStyles}>
                            <AuthCodeField index={index} value={code.value} control={control} onChange={() => {
                            }} onDelete={removeLastCode}/>
                        </Stack.Item>
                    )
                })}
                {isWorkpapers && (
                    <Stack horizontal verticalAlign={'center'} styles={{root: {width: '100%'}}}
                           horizontalAlign={"space-between"} tokens={{childrenGap: 16}}>
                        <Stack.Item styles={{root: {width: '100%'}}}>
                            {fields.length < 6 && (<TooltipHost
                                content={formatMessage({id: 'addNewCode'})}
                                id={addCodeBtnId}>
                                <PrimaryButton styles={{root: {width: '100%'}}} aria-describedby={addCodeBtnId}
                                               onClick={addNewCode}
                                               text={formatMessage({id: 'addNewCode'})}
                                />
                            </TooltipHost>)}
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
};

