import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { JobYear } from './useGeneralYearGet';

type UpdateGeneralYearReq = {
    jobYears: JobYear[];
};

/**Mutation query for updating Years for Portal Settings*/

function useUpdateGeneralYear() {
    const context = React.useContext(Context);

    const { mutate: updateGeneralYear, isLoading: updateGeneralYearLoading } = useMutation(
        (data: UpdateGeneralYearReq) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/jobYears`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getGeneralYear'),
        }
    );

    return { updateGeneralYear, updateGeneralYearLoading };
}

export { useUpdateGeneralYear };
