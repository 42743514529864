import {useContext} from "react";
import {Context} from "utils";
import {useMutation} from "react-query";
import axios from "axios";
import {IRiskNote} from "../interfaces";

type MutateDataType = {
    jobId: number;
    itemId: number;
}

function useCreateRiskNote() {
    const context = useContext(Context);
    const host = context?.config?.riskMatrixUrl;

    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post<IRiskNote>(`${host}/api/1.0/jobs/${data.jobId}/riskMatrix/notes`, data));

    return {create, createAsync, isCreating};
}

export {useCreateRiskNote};