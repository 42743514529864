import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import {IPropertyDetailItem} from "../interfaces";
import axios from "axios";


function useUpdatePropertyDetail() {
    const context = useContext(Context);
    const host = context?.config?.proceduresUrl;

    const{mutate:update, isLoading: isUpdating}=useMutation((data: IPropertyDetailItem) => axios.put(`${host}/api/1.0/procedures/propertyDetails/${data.id}`, data).then(resp => resp.data));
    
    return {update, isUpdating};
}

export {useUpdatePropertyDetail};