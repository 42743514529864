import * as React from 'react';
import { FunctionComponent } from 'react';
import { Shimmer, ShimmerElementType, Stack } from '@fluentui/react';
import { RiskMatrixSettingsMainTable, RiskMatrixSettingsPercentageApplied } from './components/RiskMatrixSettings';
import { useGetRiskMatrixDetailed } from './hooks/RiskMatrix/useGetRiskMatrixDetailed';
import { useUpdateRiskMatrixDetailed } from './hooks/RiskMatrix/useUpdateRiskMatrixDetailed';
import { useGetPerfomanceMaterially } from './hooks/RiskMatrix/useGetPerfomanceMaterially';
import { useUpdatePerfomanceMateriality } from './hooks/RiskMatrix/useUpdatePerfomanceMaterially';

/**
 * Risk Matrix Wrapper component.
 * Only for getting API data.
 * Consist from 2 tables(Percentage and Main)
 */

export const RiskMatrixSettingsPage: FunctionComponent = () => {
    const { riskMatrixDetailed, getRiskMatrixDetailedLoading } = useGetRiskMatrixDetailed();
    const { updateRiskMatrixDetailed, updateRiskMatrixDetailedLoading } = useUpdateRiskMatrixDetailed();

    const { perfomanceMaterially, getPerfomanceMateriallyLoading } = useGetPerfomanceMaterially();
    const { updatePerfomanceMateriality, updatePerfomanceMaterialityLoading } = useUpdatePerfomanceMateriality();

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item styles={{ root: { height: 'calc(100% - 16px)' } }}>
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%', overflow: 'auto' } }}>
                    {getPerfomanceMateriallyLoading || !perfomanceMaterially?.data || updatePerfomanceMaterialityLoading ? (
                        <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 345 }]} />
                    ) : (
                        <RiskMatrixSettingsPercentageApplied
                            perfomanceMaterially={perfomanceMaterially.data}
                            updatePerfomanceMateriality={updatePerfomanceMateriality}
                        />
                    )}

                    {getRiskMatrixDetailedLoading || !riskMatrixDetailed?.data || updateRiskMatrixDetailedLoading ? (
                        <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 430 }]} />
                    ) : (
                        <RiskMatrixSettingsMainTable
                            mainTableFields={riskMatrixDetailed.data}
                            updateRiskMatrixDetailed={updateRiskMatrixDetailed}
                        />
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    );
};
