import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IDashboardConfig,
    IManagerAPWidgetData,
    WidgetsEnum
} from "pages/shared/Dashboard";

export const ManagerAuditPortalDashboard = () => {
    const {formatMessage} = useIntl();

    const getManagerDashboardConfig = useCallback(
        (props: IManagerAPWidgetData): IDashboardConfig => {
            return {
                id: 'manager',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedToday',
                            props: {
                                number: props.reviewedToday,
                                title: formatMessage({id: 'reviewedToday'}),
                                variant: 'white',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedYesterday',
                            props: {
                                number: props.reviewedYesterday,
                                title: formatMessage({id: 'reviewedYesterday'}),
                                variant: 'white',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedThisWeek',
                            props: {
                                number: props.reviewedThisWeek,
                                title: formatMessage({id: 'reviewedThisWeek'}),
                                variant: 'white',
                            },
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'myAuditsInProgress',
                            tables: [{ tableType: DashboardTableTypeEnum.Manager_MyAuditsInProgress, isWorkflow: true}],
                            props: {
                                number: props.myAuditsInProgress,
                                title: formatMessage({id: 'myAuditsInProgress'}),
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'teamAuditsByStatus',
                            size: 2,
                            tables: [{ tableType: DashboardTableTypeEnum.Manager_TeamAuditsAssignedNotStarted, isWorkflow: true}],
                            props: {
                                title: formatMessage({id: 'teamAuditsByStatus'}),
                                data: props.teamAuditsByStatus,
                            },
                        },

                        {
                            type: WidgetsEnum.BAR_CHART_WIDGET,
                            id: 'teamAuditsByAuditor',
                            size: 2,
                            tables: [{ tableType: DashboardTableTypeEnum.Manager_TeamAuditsInProgress, isWorkflow: true}],
                            props: {
                                title: formatMessage({id: 'teamAuditsByAuditor'}),
                                data: props.teamAuditsByAuditor,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'myAuditsWithReviewPoints',
                            tables: [{ tableType: DashboardTableTypeEnum.Manager_MyAuditsWithReviewPoints, isWorkflow: true}],
                            props: {
                                number: props.myAuditsWithReviewPoints,
                                title: formatMessage({id: 'myAuditsWithReviewPoints'}),
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '9',
                            props: {
                                tableType: DashboardTableTypeEnum.Manager_AuditsReadyForReview,
                                isWorkflow: true
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '10',
                            props: {
                                tableType: DashboardTableTypeEnum.Manager_ReviewPointsToBeCleared,
                                isWorkflow: true
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return (
        <DashboardContainer dashboardTab={DashboardTabsEnum.AP_Manager}
            getDashboardConfig={getManagerDashboardConfig as GetDashboardConfig}
            isWorkflow={true} />
    );
};
