import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IFundInfo} from "../interfaces";


function useGetClientFunds(clientIdentifier: string) {
    const context = useContext(Context);
    const {
        data: clientFunds,
        isLoading: isClientFundsLoading,
        refetch
    } = useQuery(['getClientFunds', clientIdentifier],
        () => axios.get<IFundInfo[]>(`${context?.config?.clientUrl}/api/1.0/clients/${clientIdentifier}/funds`, {
            ...commonHeaders()
        })
            .then(resp => resp.data ?? []), 
        {
            enabled: !!context?.config?.clientUrl && !!clientIdentifier
        }
    );

    return {clientFunds, isClientFundsLoading, refetch};
}

export {useGetClientFunds};