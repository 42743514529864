import { FunctionComponent, useMemo } from 'react';
import { getFlatProcedures, getOpenOnStartupIds } from 'utils';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { SanitizedText } from 'components';

export const StaticListProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items)), [items]);
  
  return (
    <ProceduresDataTable
      items={rows}
      isLoading={isLoading}
      contextMenuOptions={{ disable: true }}
      isHeaderVisible={false}
      borderless={true}
      columns={[
        {
          key: 'title',
          name: '',
          minWidth: 300,
          fieldName: 'title',
          onRender: (items) => <SanitizedText data={items.title} />,
        },
      ]}
    />
  );
};