import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

type SingleMateriality = {
    id: number;
    overallAuditJobRiskRating: string;
    applyOverallMateriality: boolean;
    percentageApplied: number;
};

export type PerfomanceMaterially = SingleMateriality[];

/**
 * Query for get Job Risk Settings data in Portal Settings.
 * First table in page Risk Matrix Settings
 */

function useGetPerfomanceMaterially() {
    const context = React.useContext(Context);
    const { data: perfomanceMaterially, isLoading: getPerfomanceMateriallyLoading } = useQuery(
        ['getPerfomanceMaterially'],
        () => axios.get<PerfomanceMaterially>(`${context?.config?.settingsUrl}/api/1.0/Settings/performanceMateriality`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
            onError: (err: AxiosError) => {
                    queryClient.invalidateQueries('getRiskMatrixDetailed')
            },
        }
    );

    return { perfomanceMaterially, getPerfomanceMateriallyLoading };
}

export { useGetPerfomanceMaterially };
