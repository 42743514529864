import {useContext} from "react";
import { Context, queryClient } from "../../../../../../../utils";
import {useMutation, useQuery} from "react-query";
import axios from "axios";

function useChangeSuggestedTestingProcess(jobId: number) {
    const context = useContext(Context);
    const host = context?.config?.suggestedTestingUrl;
    
    const {
        mutate: changeSuggestedTestingProcess,
        isLoading
    } = useMutation(() => axios.put(`${host}/api/1.0/jobs/${jobId}/SuggestedTesting/changeProcess`, { jobId: jobId }), {
        onSuccess: () => {
            queryClient.invalidateQueries(['jobSuggestedTesting', jobId])
        }
    });

    return {isLoading, changeSuggestedTestingProcess};
}

export {useChangeSuggestedTestingProcess};