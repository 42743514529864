import React from "react";
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {TeamInfo} from "pages/WorkflowPortal/shared";


function useGetAllTeams() {
    const context = React.useContext(Context);
    const {data: teams, isLoading: isLoading} = useQuery(
        ['getAllTeams'],
        () => axios.get<TeamInfo[]>(`${context?.config?.userUrl}/api/1.0/teams`, {
            ...commonHeaders()
        }),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId,
           
        }
    );

    return {teams, isLoading};
}

export {useGetAllTeams}