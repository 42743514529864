import { FunctionComponent, useEffect, useState } from "react";
import {useIntl} from "react-intl";
import {useInvoicePortalContext} from "../../InvoicePortalLayoutPage";
import { IInvoice, IInvoicesFilter, InvoicesContext } from "./interfaces";
import {DefaultPaginationPageSize} from "../../../../constants/paginationSettings";
import {Stack} from "@fluentui/react";
import {useGetInvoices} from "./hooks";
import {InvoicesDataTable, InvoicesPaginator} from "./components";
import {InvoiceStatusType} from "./enums";
import { useGetExtraAmountParagraphs } from "./hooks/useGetExtraAmountParagraphs";

export const InvoicesPageTemplate: FunctionComponent = () => {
    const {clientIdentifier} = useInvoicePortalContext();

    const [filter, setFilter] = useState<IInvoicesFilter>({ pageNumber: 1, pageSize: DefaultPaginationPageSize, status: InvoiceStatusType.Open })
    const [selectedItems, setSelectedItems] = useState<IInvoice[]>([])
    
    const {invoices, isLoading, isFetching} = useGetInvoices(clientIdentifier, filter);
    const {extraAmountParagraphs} = useGetExtraAmountParagraphs(clientIdentifier);

    const onPagination = (pageSize: number, page: number) => {
        setFilter(prev => ({...prev, pageNumber: page, pageSize: pageSize }));
    }
    
    return (
        <InvoicesContext.Provider value={{
            filter: filter,
            setFilter: setFilter,
            selection: {
                items: selectedItems,
                setItems: setSelectedItems,
                clear: () => setSelectedItems([])
            },
            extraAmountParagraphs: extraAmountParagraphs!
        }}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item grow={1}>
                    <InvoicesDataTable items={invoices?.items ?? []}
                                       isLoading={isLoading}
                                       isFetching={isFetching}
                    />
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <InvoicesPaginator
                        pageCount={invoices?.items.length ?? 0}
                        pageNumber={filter.pageNumber}
                        pageSize={filter.pageSize}
                        totalCount={invoices?.totalCount ?? 0}
                        onChange={onPagination}
                    />
                </Stack.Item>
            </Stack>
        </InvoicesContext.Provider>
    )
}