import {FunctionComponent} from 'react';
import {Spinner, SpinnerSize, Stack, Text} from '@fluentui/react';
import {Widget, Widgets} from './Widget';
import {IDashboardConfig} from './DashboardContainer';
import {useIntl} from "react-intl";

export interface IDashboardRow {
    rowIndex: number;
    widgets: Widgets[];
}

export interface IDashboardContent {
    dashboardConfig: IDashboardConfig | null;
    isLoading?: boolean;
}


const DashboardRow: FunctionComponent<IDashboardRow> = ({widgets, rowIndex}) => {
    if (!widgets.length) return null;
    const gapWidth = 16 * (widgets.length - 1);
    const totalSize = widgets.reduce((acc, widget) => acc + (widget?.size || 1), 0);
    return (
        <Stack tokens={{childrenGap: 16}} horizontal>
            {widgets.map(({size = 1, ...widget}, idx) => {
                const width = `calc((100% - ${gapWidth}px) / ${totalSize} * ${size})`
                return (
                    <Stack.Item styles={{root: {width}}} key={`dashboard-cell-${idx}-${rowIndex}`}>
                        <Widget {...widget} />
                    </Stack.Item>
                );
            })}
        </Stack>
    );
};

export const DashboardContent: FunctionComponent<IDashboardContent> = ({dashboardConfig, isLoading}) => {
    const {formatMessage} = useIntl();
    if (isLoading) return (
        <Stack verticalAlign='center' styles={{root: {height: '100%'}}}>
            <Spinner size={SpinnerSize.large} label={formatMessage({id: 'loading'})} />
                </Stack>
                );
                if (!dashboardConfig?.rows) return (
                    <Stack verticalAlign='center' styles={{root: {height: '100%'}}}>
                        <Text>Nothing found...</Text>
                    </Stack>
                );
                return (
                <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            {dashboardConfig.rows.map((row, idx) => (
                <DashboardRow widgets={row} rowIndex={idx} key={`dashboard-row-${idx}`} />
                ))}
                </Stack>
                );
            };