import React, {FunctionComponent, RefObject} from "react";
import {CalculatorType} from "./CalculatorType";
import {useJobContext} from "../../JobPortalLayoutPage";
import {useGetCalculatorData} from "./hooks";
import {ChangeInMVCalculator} from "./components/ChangeInMV";
import {TaxRecExemptPensionGrossCalculator} from "./components/TaxRecExemptPensionIncomeGrossMethod";
import {TaxRecExemptPensionIncomeNetMethodCalculator} from "./components/TaxRecExemptPensionIncomeNetMethod";
import {DeferredTaxCalculator} from "./components/DefferedTax";
import {IncomeTaxExpenseCalculator} from "./components/IncomeTaxExpenses";
import {InterestCalculator} from "./components/Interest/InterestCalculator";
import {RentalIncomeYieldCalculator} from "./components/RentalIncomeYield";
import {OverallMaterialityCalculator} from "./components/OverallMateriality";
import {ContributionTaxCalculator} from "./components/ContributionTax";
import {ProfitLossAllocationCalculator} from "./components/ProfitLossAllocation";
import {PensionCalculator} from "./components/Pension";
import {Loading} from "../../../../components";

export const CalculatorContent: FunctionComponent<{ type: CalculatorType | null, childRef: RefObject<any> }> = ({
                                                                                                             type,
                                                                                                             childRef
                                                                                                         }) => {
    const {job} = useJobContext();
    const {calculationData, isLoading, isFetching} = useGetCalculatorData(job.id, type!, job.softwareType, job.year);
    const getCalculatorTemplate = () => {
        switch (type) {
            case CalculatorType.ChangeInMV:
                return <ChangeInMVCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.TaxRecExemptPensionIncomeGrossMethod:
                return <TaxRecExemptPensionGrossCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.TaxRecExemptPensionIncomeNetMethod:
                return <TaxRecExemptPensionIncomeNetMethodCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.DeferredTax:
                return <DeferredTaxCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.IncomeTaxExpense:
                return <IncomeTaxExpenseCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.Interest:
                return <InterestCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.RentalIncomeYield:
                return <RentalIncomeYieldCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.OverallMateriality:
                return <OverallMaterialityCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.Contribution:
                return <ContributionTaxCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.ProfitLossAllocation:
                return <ProfitLossAllocationCalculator ref={childRef} data={calculationData}/>;
            case CalculatorType.Pension:
                return <PensionCalculator ref={childRef} data={calculationData}/>;
        }

        return null;
    };

    if (isLoading || isFetching)
        return <Loading/>;

    return (
        <>
            {getCalculatorTemplate()}
        </>);
}
