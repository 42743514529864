import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {INavLink, Shimmer, ShimmerElementType, Stack, useTheme} from '@fluentui/react';
import {ModalWithForm, VerticalMenu} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useBoolean} from '@fluentui/react-hooks';
import {
    IChangeClientInfoCommand,
    useGetClientByGuid,
    useUpdateClientsAddresses,
    useUpdateClientsApiAuth,
    useUpdateClientsNotes
} from "pages/WorkflowPortal/Clients";
import {DetailedSoftwareType, SoftwareType} from "enums";
import {TrusteeInfoTab} from './tabs/TrusteeInfoTab';
import {
    AddressTab,
    APIAuthorisationFields,
    ContactTab,
    FundsTab,
    NotesTab
} from 'pages/WorkflowPortal/Clients/components/ClientModal/tabs';
import {AuditTrusteeTab} from './tabs/AuditTrusteeTab';
import {useUpdateTrusteeMainInfo, useUpdateTrusteesAuditInfo} from "../../hooks";

export enum TrusteeModalTabs {
    ACCOUNT_INFO,
    ADDRESS,
    AUDIT,
    API_AUTHORISATION,
    NOTES,
    FUNDS,
    CONTACTS,
}

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    guid?: string | null;
}

export const TrusteeModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title, guid}: Props) => {
    const {formatMessage} = useIntl();
    const [activeTab, setActiveTab] = useState<TrusteeModalTabs>(TrusteeModalTabs.ACCOUNT_INFO);
    const {currentClientData, isCurrentClientLoading} = useGetClientByGuid(guid || '');

    const {updateClientAddresses, isUpdatingClientAddresses} = useUpdateClientsAddresses();
    const {updateTrusteeMainInfo, isUpdating: isUpdatingMainInfo} = useUpdateTrusteeMainInfo();
    const {update: updateTrusteeAuditInfo, isUpdating: isUpdatingAuditInfo} = useUpdateTrusteesAuditInfo();
    const {updateClientApiAuth, isUpdatingClientApiAuth} = useUpdateClientsApiAuth();
    const {updateClientNotes, isUpdatingClientNotes} = useUpdateClientsNotes();

    const globalLoading = useMemo(() => {
        return (
            isUpdatingMainInfo ||
            isUpdatingClientAddresses ||
            isCurrentClientLoading ||
            isUpdatingAuditInfo ||
            isUpdatingClientApiAuth ||
            isUpdatingClientNotes
        );
    }, [
        isCurrentClientLoading,
        isUpdatingMainInfo,
        isUpdatingClientAddresses,
        isUpdatingAuditInfo,
        isUpdatingClientApiAuth,
        isUpdatingClientNotes,
    ]);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({id: 'general'}),
            url: '',
            key: 'General',
            iconProps: {iconName: 'Settings'},
            links: [
                {
                    name: formatMessage({id: 'accountInformation'}),
                    url: '',
                    key: TrusteeModalTabs[TrusteeModalTabs.ACCOUNT_INFO],
                    iconProps: {iconName: 'ComplianceAudit'},
                    onClick: () => {
                        setActiveTab(TrusteeModalTabs.ACCOUNT_INFO);
                    },
                },
                {
                    name: formatMessage({id: 'address'}),
                    url: '',
                    key: TrusteeModalTabs[TrusteeModalTabs.ADDRESS],
                    iconProps: {iconName: 'MapPin'},
                    onClick: () => {
                        setActiveTab(TrusteeModalTabs.ADDRESS);
                    },
                },
                {
                    name: formatMessage({id: 'auditParticulars'}),
                    url: '',
                    key: TrusteeModalTabs[TrusteeModalTabs.AUDIT],
                    iconProps: {iconName: 'MapLayers'},
                    onClick: () => {
                        setActiveTab(TrusteeModalTabs.AUDIT);
                    },
                },
                {
                    name: formatMessage({id: 'apiAuthorisation'}),
                    url: '',
                    key: TrusteeModalTabs[TrusteeModalTabs.API_AUTHORISATION],
                    iconProps: {iconName: 'ColumnOptions'},
                    onClick: () => {
                        setActiveTab(TrusteeModalTabs.API_AUTHORISATION);
                    },
                },
                {
                    name: formatMessage({id: 'notes'}),
                    url: '',
                    key: TrusteeModalTabs[TrusteeModalTabs.NOTES],
                    iconProps: {iconName: 'Calculator'},
                    onClick: () => {
                        setActiveTab(TrusteeModalTabs.NOTES);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({id: 'funds'}),
            url: '',
            key: TrusteeModalTabs[TrusteeModalTabs.FUNDS],
            iconProps: {iconName: 'MapLayers'},
            onClick: () => {
                setActiveTab(TrusteeModalTabs.FUNDS);
            },
        },
        {
            name: formatMessage({id: 'contacts'}),
            url: '',
            key: TrusteeModalTabs[TrusteeModalTabs.CONTACTS],
            iconProps: {iconName: 'ComplianceAudit'},
            onClick: () => {
                setActiveTab(TrusteeModalTabs.CONTACTS);
            },
        },
    ]);

    const {control, handleSubmit, reset, watch, formState, setValue} = useForm<IChangeClientInfoCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            titleSearchConduct: false,
            asicSearchConduct: false,
            clientName: '',
            mainPhone: '',
            primaryContact: '',
            primaryContactPhone: '',
            secondaryContact: '',
            secondaryContactPhone: '',
            accountingSoftware: null,
            turnAroundTime: '',
            unsignedARDate: null,
            clientRiskRating: null,
            aRGeneration: null,
            apiAuthorisationType: '',
            notes: '',
            detailedSoftwareType: null,
            isDraftAuditReport: false,
        },
    });

    useEffect(() => {
        if (currentClientData?.data) {
            setValue('primaryContact', currentClientData.data.primaryContact?.guid || '');
            setValue('primaryContactPhone', currentClientData.data.primaryContact?.mobilePhone || '');
            setValue('clientName', currentClientData.data?.name);
            setValue('mainPhone', currentClientData.data?.mainPhone);
            setValue('addresses', currentClientData.data.addresses);
            setValue('type', currentClientData.data.type);
            if (currentClientData?.data.auditInfo?.softwareType === SoftwareType.ClassSuper) {
                setValue('accountingSoftware', currentClientData.data.auditInfo?.detailedSoftwareType);
            } else {
                setValue('accountingSoftware', currentClientData.data.auditInfo?.softwareType);
            }

            setValue('detailedSoftwareType', currentClientData.data.auditInfo?.detailedSoftwareType);
            setValue('titleSearchConduct', currentClientData.data.auditInfo?.titleSearchConduct);
            setValue('asicSearchConduct', currentClientData.data.auditInfo?.asicSearchConduct);
            setValue('turnAroundTime', currentClientData.data.auditInfo?.turnAroundTime || '');

            //AUTH
            setValue('accSoftwareCodes', currentClientData.data.apiAuthInfo.authCodes.map(x => ({value: x})) || []);
            setValue('notes', currentClientData.data.notes || '');
        }
    }, [currentClientData?.data, setValue]);

    const onSubmit = (data: IChangeClientInfoCommand) => {
        if (currentClientData?.data) {
            switch (activeTab) {
                case TrusteeModalTabs.ACCOUNT_INFO:
                    updateTrusteeMainInfo({
                        name: data.clientName,
                        type: data.type,
                        clientId: currentClientData.data.id,
                        primaryContactId: data.primaryContact
                    })
                    return;
                case TrusteeModalTabs.ADDRESS:
                    updateClientAddresses({
                        clientId: currentClientData.data.id,
                        addresses: data.addresses
                    });
                    return;

                case TrusteeModalTabs.AUDIT:
                    updateTrusteeAuditInfo({
                        clientId: currentClientData.data.id,
                        turnAroundTime: Number(data.turnAroundTime) || 0,
                        titleSearchConduct: data.titleSearchConduct,
                        asicSearchConduct: data.asicSearchConduct,
                        softwareType: (data.accountingSoftware === DetailedSoftwareType.ClassSuperAPI || data.accountingSoftware === DetailedSoftwareType.ClassSuperPDF) ? SoftwareType.ClassSuper : data.accountingSoftware || null,
                        detailedSoftwareType: (data.accountingSoftware === DetailedSoftwareType.ClassSuperAPI || data.accountingSoftware === DetailedSoftwareType.ClassSuperPDF) ? data.accountingSoftware : data.detailedSoftwareType || null,
                        partnerId: data.partnerId
                    })
                    return;
                case TrusteeModalTabs.API_AUTHORISATION:
                    updateClientApiAuth({
                        clientId: currentClientData.data.id,
                        isAdminFlow: true,
                        codes: data.accSoftwareCodes.map(x => x.value),
                    });
                    return;
                case TrusteeModalTabs.NOTES:
                    updateClientNotes({
                        clientId: currentClientData.data.id,
                        notes: data.notes || '',
                    });
                    return;

                default:
                    return;
            }
        }
    };
    const onClose = () => {
        onDismiss();
        reset();
        setActiveTab(TrusteeModalTabs.ACCOUNT_INFO);
    }
    const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);

    return (
        <ModalWithForm minWidth={'80vw'}
                       isLoading={isCurrentClientLoading}
                       isOpen={isOpen}
                       onDismiss={onClose}
                       title={title}
                       isSubmitting={globalLoading}
                       submitDisabled={globalLoading || (activeTab !== TrusteeModalTabs.FUNDS && activeTab !== TrusteeModalTabs.CONTACTS ? (!formState.isDirty || !formState.isValid) : false)}
                       onSubmit={handleSubmit(onSubmit)}
                       closeAfterSubmit={true}>
            <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -16px'}}}>

                <VerticalMenu selectedKey={TrusteeModalTabs[activeTab || TrusteeModalTabs.ACCOUNT_INFO]} links={links}
                              isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>

                <Stack
                    styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`}}}
                    tokens={{childrenGap: 16}}>
                    <Stack.Item styles={{root: {height: 'calc(100% - 50px)'}}}>
                        {activeTab === TrusteeModalTabs.ACCOUNT_INFO ? (
                            <TrusteeInfoTab control={control} contacts={currentClientData?.data.contacts}
                                            selectedContactGuid={watch('primaryContact')}/>
                        ) : activeTab === TrusteeModalTabs.ADDRESS ? (
                            <AddressTab control={control}/>
                        ) : activeTab === TrusteeModalTabs.AUDIT ? (
                            <AuditTrusteeTab control={control}/>
                        ) : activeTab === TrusteeModalTabs.API_AUTHORISATION ? (
                            <APIAuthorisationFields control={control}/>
                        ) : activeTab === TrusteeModalTabs.NOTES ? (
                            <NotesTab control={control}/>
                        ) : activeTab === TrusteeModalTabs.FUNDS ? (
                            <FundsTab clientGuid={currentClientData?.data.guid}/>
                        ) : activeTab === TrusteeModalTabs.CONTACTS ? (
                            <ContactTab
                                clientId={currentClientData?.data.id || 0}
                                contacts={currentClientData?.data.contacts || []}
                            />
                        ) : (
                            ''
                        )}
                    </Stack.Item>
                </Stack>
            </Stack>
        </ModalWithForm>
    );
});
