import { FunctionComponent, useMemo } from 'react';
import {formatDate, getFlatProcedures, getOpenOnStartupIds} from 'utils';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { SanitizedText } from 'components';
import { useIntl } from 'react-intl';

export const CorresspondanceProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const { formatMessage } = useIntl();
  const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items)), [items]);
  
  return (
    <ProceduresDataTable
      items={rows}
      hideIfEmpty={false}
      isLoading={isLoading}
      columns={[
        {
          key: 'from',
          name: formatMessage({ id: 'from' }),
          fieldName: 'from',
          onRender: (items) => <SanitizedText data={items?.from} />,
          size: 2,
        },
        {
          key: 'subject',
          name: formatMessage({ id: 'subject' }),
          fieldName: 'subject',
          onRender: (items) => <SanitizedText data={items?.subject} />,
          size: 12,
        },
        {
          key: 'sentDate',
          name: formatMessage({ id: 'sentDate' }),
          fieldName: 'sentDate',
          onRender: (items) => <SanitizedText data={formatDate(items?.sentDateTime)} />,
          size: 3,
        },
        {
          key: 'sentTime',
          name: formatMessage({ id: 'sentTime' }),
          fieldName: 'sentTime',
          onRender: (items) => <SanitizedText data={formatDate(items?.sentDateTime, 'HH:mm:ss')} />,
          size: 3,
        },
      ]}
    />
  );
};