import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {PropertyDetailsState} from "../../enums";

type OrderSearchDataType = {
    jobId: number;
    year: number;
    itemId: number;
    tableType: number;
    verify: boolean;
    titleReference: string;
    volume: string;
    folio: string;
    name: string;
    abn: string | null;
    propertyState?: PropertyDetailsState;

}

function useMakeOrderSearch() {
    const context = useContext(Context);
    const host =context?.config?.orderSearchUrl;
    const {
        mutate: makeSearch,
        mutateAsync: makeSearchAsync,
        isLoading
    } = useMutation((data: OrderSearchDataType) => axios.post(`${host}/api/1.0/jobs/${data.jobId}/orderSearch/schedule`, data).then(resp => resp.data));

    return {makeSearch, makeSearchAsync, isLoading};
}

export {useMakeOrderSearch};