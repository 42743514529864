import React from "react";
import {commonHeaders, Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {DashboardCodes} from "../../../WorkflowPortal/shared";

function useGetCurrentUserDashboards() {
    const context = React.useContext(Context);

    const {data: response, isLoading: isLoading} = useQuery(
        ['getCurrentUserDashboards'],
        () => axios.get<DashboardCodes[]>(`${context?.config?.userUrl}/api/1.0/employee/me/dashboards`, {
            ...commonHeaders(),
        }),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {response, isLoading};
}

export {useGetCurrentUserDashboards};