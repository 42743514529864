import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    FilterByPeriod,
    GetDashboardConfig,
    IAuditorAPWidgetData,
    IDashboardConfig,
    WidgetsEnum
} from "pages/shared/Dashboard";

export const AuditorAuditPortalDashboard = () => {
    const {formatMessage} = useIntl();
    const getAuditorDashboardConfig = useCallback(
        (props: IAuditorAPWidgetData): IDashboardConfig => {
            return {
                id: 'auditor',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedToday',
                            props: {
                                number: props.auditedToday,
                                title: formatMessage({id: 'auditedToday'}),
                                variant: 'white',
                            },
                            tables: [{
                                tableType: DashboardTableTypeEnum.Auditor_JobsByPeriod,
                                filterByPeriod: FilterByPeriod.Day,
                                isWorkflow: true,
                            }],
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedYesterday',
                            props: {
                                number: props.auditedYesterday,
                                title: formatMessage({id: 'auditedYesterday'}),
                                variant: 'white',
                            },
                            tables: [{
                                tableType: DashboardTableTypeEnum.Auditor_JobsByPeriod,
                                filterByPeriod: FilterByPeriod.Yesterday,
                                isWorkflow: true,
                            }],
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditedThisWeek',
                            props: {
                                number: props.auditedThisWeek,
                                title: formatMessage({id: 'auditedThisWeek'}),
                                variant: 'white',
                            },
                            tables: [{
                                tableType: DashboardTableTypeEnum.Auditor_JobsByPeriod,
                                isWorkflow: true,
                                filterByPeriod: FilterByPeriod.Week,
                            }],
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'allocatedAudits',
                            size: 2,
                            tables: [
                                { tableType: DashboardTableTypeEnum.Auditor_MyAuditsNotStarted, isWorkflow: true},
                                { tableType: DashboardTableTypeEnum.Auditor_AuditsInProgress, isWorkflow: true}
                            ],
                            props: {
                                title: formatMessage({id: 'allocatedAudits'}),
                                data: props.allocatedAudits,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'preARQueriesWaitingToBeReviewed',
                            tables: [{ tableType: DashboardTableTypeEnum.Auditor_PreArQueriesToBeReviewed, isWorkflow: true}],
                            props: {
                                number: props.preArQueriesToBeReviewed,
                                title: formatMessage({id: 'preARQueriesWaitingToBeReviewed'}),
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'postARQueriesWaitingToBeReviewed',
                            tables: [{ tableType: DashboardTableTypeEnum.Auditor_PostArQueriesToBeReviewed, isWorkflow: true}],
                            props: {
                                number: props.postArQueriesToBeReviewed,
                                title: formatMessage({id: 'postARQueriesWaitingToBeReviewed'}),
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.BAR_CHART_WIDGET,
                            id: 'timesheet',
                            size: 2,
                            tables: [{ tableType: DashboardTableTypeEnum.Auditor_Timesheet, isWorkflow: true }],
                            props: {
                                title: formatMessage({id: 'timesheet'}),
                                data: props.timesheets,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Auditor_ReviewPointsToBeCleared,
                                isWorkflow: true
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return <DashboardContainer
        dashboardTab={DashboardTabsEnum.AP_Auditor}
        getDashboardConfig={getAuditorDashboardConfig as GetDashboardConfig}
        isWorkflow={true} />;
};
