import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type Setting = {
    value: string
};

/**Query for getting Years for Portal Settings*/

function useGetAmazonBucketName() {
    const context = React.useContext(Context);

    const { data: setting, isLoading } = useQuery(
        ['getAmazonBucketName'],
        () => axios.get<Setting>(`${context?.config?.settingsUrl}/api/1.0/Settings/PortalSettings/Amazon.S3Bucketname`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { setting, isLoading };
}

export { useGetAmazonBucketName };
