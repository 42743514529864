import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IPaginationResult} from "../../../interfaces";
import {IInvoice, IInvoicesFilter} from "../../Invoices/interfaces";
import {InvoiceStatusType} from "../../Invoices/enums";

export type ClientInvoicesFilter = Pick<IInvoicesFilter, 'year' | 'fundName'> & { clientIdentifier: string }

function useGetClientInvoices(filter: ClientInvoicesFilter) {
    const context = useContext(Context);
    const {
        data: invoices,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['getInvoices', filter.clientIdentifier, filter.fundName, filter.year,],
        () => axios.get<IInvoice[]>(`${context?.config?.billingUrl}/api/1.0/invoice/GetAll/${filter.clientIdentifier}`, {
            ...commonHeaders(),
            params: {
                page: 1,
                size: 1000,
                status: InvoiceStatusType.NotCreated,

                year: filter.year,
                fundName: filter.fundName,
            }
        })
            .then(resp => resp.data as any)
            .then((response: { items: IInvoice[], totalItemsCount: number }): IPaginationResult<IInvoice> => {
                return {
                    items: response.items,
                    pageNumber: 1,
                    pageSize: 1000,
                    totalCount: response.totalItemsCount
                }
            }), {
            enabled: !!context?.config?.billingUrl && !!filter.clientIdentifier
        }
    );

    return {invoices, isLoading, isFetching, refetch};
}

export {useGetClientInvoices};