import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Attachment, GeneratedAnswer, JobComment} from 'pages/JobPortal/components';
import {useIntl} from 'react-intl';
import {useValidationsContext} from "./ValidationsContent";
import {IColumn, SelectionMode, Stack, useTheme} from "@fluentui/react";
import {DataTable, SanitizedText} from "../../../../../components";
import {AnswerControlType} from "../../../../../enums";
import {ValidationIcon} from "./ValidationIcon";
import {IValidationError, ValidationState} from "../../../interfaces";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {useUpdateValidationError} from "../../../hooks";

export const ValidationErrors: FunctionComponent = () => {
    const {errors, options, isLoading} = useValidationsContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const {jobId} = useJobContext();
    const {update, isUpdating} = useUpdateValidationError();
    
    const getValidationState = (answer: string): ValidationState => {
        switch (answer) {
            case 'Accept as reasonable':
            case 'Agreed':
            case 'Clearly Trivial':
                return ValidationState.Valid
            default:
                return ValidationState.Invalid
        }
    }
    
    const getReviewStyles = (value: string): any => {
        return {
            root: {
                '.ms-Dropdown-title': {
                    backgroundColor: !value || getValidationState(value) === ValidationState.Valid ? 'inherit' : theme.palette.red
                }
            }
        }
    }
    
    const composeErrors = useCallback((errors: IValidationError[], options: any[]): any[] => {
        let dropdownOptions = options.map(o => { return { text: o.value, key: o.value}});
        dropdownOptions.unshift(...[{ text: '', key: ''}]);
        
        return errors.map((e, idx, array) => {
            e.sNo = idx + 1
            e.options = dropdownOptions;
            e.hasComments = !!(e.comments.length || e.permanentComments.length)
            e.validationState = getValidationState(e.answerText)
            return e;
        })
    }, [errors])
    
    const getInitialValidationState = (items: IValidationError[]): { [id: number]: ValidationState } => {
        return items.reduce((acc: { [id: number]: ValidationState }, cur) => {
            acc[cur.id] = cur.validationState;
            return acc;
        }, {})
    }
    
    const [validationStates, setValidationState] = useState<{ [id: number]: ValidationState }>(getInitialValidationState(composeErrors(errors, options)));
    const items = useMemo<any[]>(() => composeErrors(errors, options), [errors, validationStates]);

    const onAnswerChanged = (answer: string, item: IValidationError): void => {
        item.answerText = answer;
        item.validationState = getValidationState(item.answerText);
        setValidationState(prev => {
            prev[item.id] = item.validationState
            return { ...prev };
        });
        
        update({
            jobId: jobId,
            id: item.id,
            text: item.answerText
        })
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                        <JobComment itemId={item?.id} hasComments={item?.hasComments}/>
                    </Stack>
                )
            },
            {
                key: 'sNo',
                name: formatMessage({id: 'sNo'}),
                minWidth: 50,
                maxWidth: 50,
                fieldName: 'sNo',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} format={item?.format} />
            },
            {
                key: 'errorDetail',
                name: formatMessage({id: 'errorDetails'}),
                minWidth: 800,
                maxWidth: 800,
                fieldName: 'errorDetail',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} format={item?.format} />
            },
            {
                key: 'review',
                name: formatMessage({id: 'review'}),
                minWidth: 180,
                maxWidth: 180,
                fieldName: 'review',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer
                            itemId={item?.id} styles={getReviewStyles(item?.answerText)}
                            value={item?.answerText} answerControlType={AnswerControlType.Dropdown}
                            options={item?.options} onChange={(value: string) => onAnswerChanged(value, item, )}/>
                    </Stack>
                )
            },
            {
                key: 'validation',
                name: formatMessage({id: 'validation'}),
                fieldName: 'validation',
                minWidth: 80,
                maxWidth: 80,
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' horizontalAlign='center' grow>
                        <ValidationIcon state={item?.validationState} items={item?.validationErrorItems || []} type={item.validationType} />
                    </Stack>
                )
            },
    ], [formatMessage])
    
    return <DataTable
        initialColumns={columns}
        columns={columns}
        items={items}
        selectionMode={SelectionMode.none}
        enableShimmer={isLoading}
        containerHeight='100%'
    />
};