import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultButton, DialogType, IconButton, mergeStyleSets, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { ControlledTextField, DialogMessage, ITableColumn, SanitizedText } from 'components';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { Control } from 'react-hook-form/dist/types/form';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useCreateUOCItem, useDeleteUOCItem, useUpdateUOCItem } from './hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { logger } from 'services';

interface IUndersAndOversCalculationItem {
    id: number;
    item?: string;
    value?: number;
    comment?: string;
    isEditable: boolean;
    jobId: number;
}

type UndersAndOversCalculationTableItem = IUndersAndOversCalculationItem & { itemId: number };

export const UndersAndOversCalculationProcedureTemplate: FunctionComponent = () => {
    const { items, isLoading } = useProcedureContext();
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { job, jobId } = useJobContext();

    const { deleteUOC, isDeleting } = useDeleteUOCItem();
    const { createUOC, isCreating } = useCreateUOCItem();
    const { updateUOC, isUpdating } = useUpdateUOCItem();

    const overallMateriality = useMemo<number>(() => items[0].overallMateriality, [items]);
    const [total, setTotal] = useState<number>(0);

    const [currentItem, setCurrentItem] = useState<(UndersAndOversCalculationTableItem & { index?: number }) | null>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const { control, setValue, handleSubmit } = useForm<{ items: UndersAndOversCalculationTableItem[] }>({
        ...DefaultFormSettings,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    });

    const columns: ITableColumn[] = [
        {
            key: 'remove',
            name: '',
            minWidth: 60,
            maxWidth: 60,
            fieldName: 'remove',
            onRender: (field, index) => (
                <Stack grow horizontalAlign='center'>
                    {index !== undefined && !job.dateARSent && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                            onClick={() => {
                                if (job.dateARSent) return;

                                setCurrentItem({ ...field, index: index });
                                toggleDeleteDialog();
                            }}
                        />
                    )}
                </Stack>
            ),
        },
        {
            key: 'item',
            name: formatMessage({ id: 'item' }),
            minWidth: 180,
            fieldName: 'item',
            onRender: (field, index, column) =>
                index !== undefined && (
                    <UndersAndOvevrsCalculationCell item={field} index={index} name={'item'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'value',
            name: formatMessage({ id: 'value' }),
            minWidth: 180,
            fieldName: 'value',
            onRender: (field, index, column) =>
                index !== undefined && (
                    <UndersAndOvevrsCalculationCell item={field} index={index} name={'value'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'comment',
            name: formatMessage({ id: 'comment' }),
            minWidth: 180,
            fieldName: 'comment',
            onRender: (field, index, column) =>
                index !== undefined && (
                    <UndersAndOvevrsCalculationCell item={field} index={index} name={'comment'} control={control} onBlur={onBlur} />
                ),
        },
    ];

    const onDelete = (id: number, index: number) => {
        deleteUOC(id, {
            onSuccess: () => {
                remove(index);
                calculateTotal(control._formValues.items);
            },
        });
    };

    const onAdd = () => {
        createUOC(
            { jobId: jobId },
            {
                onSuccess: (response: any) =>
                    append({
                        id: response.data.id,
                        itemId: response.data.id,
                        jobId: jobId,
                        item: '',
                        value: 0,
                        comment: '',
                        isEditable: true,
                    }),
            }
        );
    };

    const onBlur = (index: number) => {
        handleSubmit((items: { items: UndersAndOversCalculationTableItem[] }) => {
            updateItem(items.items[index]);
            calculateTotal(items.items);
        })();
    };

    const updateItem = (item: UndersAndOversCalculationTableItem) => {
        updateUOC({
            id: item.itemId,
            jobId: item.jobId,
            item: item.item,
            value: item.value?.toString() ?? '',
            comment: item.comment,
        });
    };

    const calculateTotal = (items: UndersAndOversCalculationTableItem[]) => {
        setTotal(
            items.reduce((arr, item) => {
                arr += +(item.value ?? 0);
                return arr;
            }, 0)
        );
    };

    const conclusion = 'No Risks of material misstatement at the financial report level have been identified as being material.';

    useEffect(() => {
        const tableItems = [
            ...(items[0].items || []).map((x: IUndersAndOversCalculationItem) => {
                return { ...x, itemId: x.id };
            }),
        ];
        setValue('items', tableItems);
        calculateTotal(tableItems);
    }, [items]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Text>
                <strong>{formatMessage({ id: 'objective' }) + ': '}</strong>
                {formatMessage({ id: 'toInsertAnyMisstatementsInTheFinancialStatements' })}
            </Text>
            <ProceduresDataTable
                items={fields}
                enableShimmer={isLoading || isDeleting}
                columns={columns}
                hideIfEmpty={false}
                disableDragDrop={true}
            />
            <Stack grow styles={{ root: { display: 'grid', gridTemplateColumns: '1fr 5fr 3fr 3fr', marginBottom: '32px' } }}>
                <Stack horizontalAlign='center'>
                    {!job.dateARSent && (
                        <DefaultButton onClick={onAdd}>
                            {isCreating ? <Spinner size={SpinnerSize.small} /> : formatMessage({ id: 'addRow' })}
                        </DefaultButton>
                    )}
                </Stack>
                <span />
                <Stack horizontalAlign='center'>
                    <SanitizedText data={total.toString()} prefix={'$'} numberFormat={'fractional'} />
                </Stack>
                <span />
            </Stack>
            <Stack grow horizontal tokens={{ childrenGap: 64 }}>
                <Stack>
                    <Text>
                        <strong>{formatMessage({ id: 'materialityAsPerMaterialityCalculator' })}</strong>
                    </Text>
                    <Text>${overallMateriality}</Text>
                </Stack>
                <Stack styles={{ root: { width: '1px', backgroundColor: theme.semanticColors.bodyText } }} />
                <Stack>
                    <Text>
                        <strong>{formatMessage({ id: 'conclusion' })}</strong>
                    </Text>
                    <Text>{conclusion}</Text>
                </Stack>
            </Stack>

            <DialogMessage
                onClick={() => {
                    toggleDeleteDialog();
                    onDelete(currentItem!.itemId, currentItem!.index!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}
            />
        </Stack>
    );
};

interface IUndersAndOvevrsCalculationCellProps {
    item: UndersAndOversCalculationTableItem;
    index: number;
    control: Control<{ items: UndersAndOversCalculationTableItem[] }>;
    name: keyof IUndersAndOversCalculationItem;
    onBlur: (index: number) => void;
}

const UndersAndOvevrsCalculationCell: FunctionComponent<IUndersAndOvevrsCalculationCellProps> = ({
    index,
    control,
    name,
    onBlur,
    item,
}: IUndersAndOvevrsCalculationCellProps) => {
    const { job } = useJobContext();
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const classNames = mergeStyleSets({
        stack: {
            width: '100%',
            height: '100%',
            'align-items': 'start',
            'justify-content': 'center',

            '> .ms-TextField': {
                width: 'inherit',
            },
        },
    });

    const onEditClick = useCallback(() => {
        logger.debug('[UNDERS_CALC:CELL:DISABLED]', !!job.dateARSent);

        if (!!job.dateARSent) return;
        if (!isEditMode) setEditMode(true);
    }, [job.dateARSent]);

    return (
        <Stack grow horizontalAlign='center' className={classNames.stack} onClick={onEditClick}>
            {isEditMode ? (
                <ControlledTextField
                    autoFocus
                    name={`items.${index}.${name}`}
                    control={control}
                    disabled={job.dateARSent}
                    onBlur={() => {
                        if (item[name] !== control._formValues.items[index][name]) onBlur(index);
                        setEditMode(false);
                    }}
                />
            ) : (
                <Text>{control._formValues.items[index][name]}</Text>
            )}
        </Stack>
    );
};
