import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField, ControlledTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import {EmailTemplate} from "../../hooks/Email/useEmailTemplateGet";


interface Props {
    control: Control<EmailTemplate>;
}

export const APIAuthorisationEmail: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='APIAuthorisationEmail' />}>
            <>
                <ControlledTextField
                    name='oAuthLinkEmailSubject'
                    //name={'auditConnectionEmailBody'}
                    control={control}
                    label={formatMessage({ id: 'APIAuthorisationEmailSubject' })}
                />

                <ControlledRichTextField
                    name='oAuthLinkEmailBody'
                    //name={'auditConnectionEmailBody'}
                    control={control}
                    label={formatMessage({ id: 'APIAuthorisationEmailBody' })}
                />
            </>
        </EmailTemplatePanel>
    );
};
