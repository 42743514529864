import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import { useInvoicePortalNotifications } from "../../../hooks";

type MutateDataType = {
    clinetIdentifier: string;
    id: number;
}

function useDeleteCreditNote() {
    const context = useContext(Context);
    const {showOnDeleteNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: deleteCreditNote,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.billingUrl}/api/1.0/CreditNote/Delete/${data.clinetIdentifier}/${data.id}`,
        commonHeaders()), {
            onSuccess: () => { 
                queryClient.invalidateQueries('getCreditNotes');
                showOnDeleteNotification.success({ values: { name: 'Credit Note' }});
            },
            onError: (error: AxiosError) => {
                showOnDeleteNotification.error({ values: { ...error?.response?.data ?? {} }})
            }
        })

    return {deleteCreditNote, isDeleting};
}

export {useDeleteCreditNote};