import React, { FunctionComponent, PropsWithChildren } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontWeights, Stack, Text, useTheme } from "@fluentui/react";

type AuditAcceptanceSectionProps = {
	name: string;
	bodrered?: boolean
	children: any;
}

export const AuditAcceptanceSection: FunctionComponent<AuditAcceptanceSectionProps> = ({ name, bodrered = true, children, ...props }: AuditAcceptanceSectionProps) => {
	const {palette, spacing, fonts, schemes, effects} = useTheme()

	const classNames = mergeStyleSets({
		header: {
			height: 50,
			paddingLeft: 10,
			overflow: 'hidden',
			flexGrow: 1,
			borderBottom: `1px solid ${palette.neutralTertiaryAlt}`,
			boxShadow: effects.elevation4,
			background: 'rgba(184, 184, 184, 0.2)',
			borderRadius: effects.roundedCorner2,
		},
		headerName: [
			fonts.medium,
			{
				padding: spacing.m,
				fontWeight: FontWeights.regular,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				'span': { color: schemes?.default?.semanticColors.bodyText },
			},
		],
		content: {
			padding: 16,
			border: bodrered ?? true ? `1px solid ${palette.neutralTertiaryAlt}` : 'none',
		}
	})
	
	return (
		<Stack>
			<Stack.Item>
				<Stack tokens={{childrenGap: 16}}
				       horizontal
				       className={classNames.header}
				       horizontalAlign={"space-between"}
				       verticalAlign={"center"}>
					<Stack verticalAlign={'center'}
					       horizontal
					       tokens={{childrenGap: 8}}
					       className={classNames.headerName}>
						<Text>{name}</Text>
					</Stack>
				</Stack>
			</Stack.Item>
			<Stack.Item>
				<Stack tokens={{childrenGap: 16}} className={classNames.content}>
					{children}
				</Stack>
			</Stack.Item>
		</Stack>
	);
};
