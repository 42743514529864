import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import { EmployeePosition, EmployeeRole, PortalRole } from "../pages/WorkflowPortal/shared";

export interface ICurrentUserInfo {
    accessToDashboard: boolean;
    b2CObjectId: string;
    displayName: string;
    email: string;
    employeeId: number;
    employeeRole?: EmployeeRole
    firstName: string;
    id: number;
    isSuperUser: boolean;
    lastName: string;
    position?: EmployeePosition;
    rate: number;
    roles: PortalRole[];
    username?: string
}

function useGetCurrentUserInfo() {
    const context = React.useContext(Context);

    const {data, isLoading} = useQuery(
        ['getCurrentUser', context],
        () => axios.get<ICurrentUserInfo>(`${context?.config?.userUrl}/api/1.0/user/me`, commonHeaders())
            .then(response => {
                return response.data
            }),
        {
            enabled: !!context?.config,
        }
    );

    return {user: data, isLoading};
}

export {useGetCurrentUserInfo};
