import { ConfigurationService } from './ConfigurationService';
import { httpClientInstanceFactory } from './HttpClientInstance';
import { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios-observable';
import { BehaviorSubject, filter, map, Observable, switchMap, take } from 'rxjs';
import { IAppConfiguration } from '../interfaces';
import { stores } from '../stores';

export abstract class BaseService {
    private httpClient: BehaviorSubject<Axios | null> = new BehaviorSubject<Axios | null>(null);

    protected constructor(configurationSettingKey: keyof IAppConfiguration, configurationService: ConfigurationService) {
        configurationService.config
            .pipe(
                filter((value) => !!value),
                take(1)
            )
            .subscribe((config) => this.httpClient.next(httpClientInstanceFactory(config![configurationSettingKey])));
    }

    protected request<T = any>(config: AxiosRequestConfig, disableAuthorization: boolean = false): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.request<T>(requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected get<T = any>(url: string, config: AxiosRequestConfig = {}, disableAuthorization: boolean = false): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.get<T>(url, requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected head<T = any>(url: string, config: AxiosRequestConfig = {}, disableAuthorization: boolean = false): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.head<T>(url, requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected post<T = any>(
        url: string,
        data?: any,
        config: AxiosRequestConfig = {},
        disableAuthorization: boolean = false
    ): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.post<T>(url, data, requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected put<T = any>(url: string, data?: any, config: AxiosRequestConfig = {}, disableAuthorization: boolean = false): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.put<T>(url, data, requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected patch<T = any>(
        url: string,
        data?: any,
        config: AxiosRequestConfig = {},
        disableAuthorization: boolean = false
    ): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.patch<T>(url, data, requestConfig as any)),
            map((response) => response.data)
        );
    }

    protected delete<T = any>(url: string, config: AxiosRequestConfig = {}, disableAuthorization: boolean = false): Observable<T> {
        const requestConfig = !disableAuthorization ? BaseService.setAuthorizationHeaders(config) : config;

        return this.httpClient.pipe(
            filter((value) => !!value),
            take(1),
            switchMap((httpClient) => httpClient!.delete<T>(url, requestConfig as any)),
            map((response) => response.data)
        );
    }

    public static setAuthorizationHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
        if (stores.AuthStore.accessToken) {
            if (!config.headers) {
                config.headers = {};
            }
            config.headers.Authorization = `Bearer ${stores.AuthStore.accessToken}`;
        }

        return config;
    }
}
