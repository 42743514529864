import React, {FunctionComponent, ReactNode} from "react";
import {FontWeights, IGroup, Stack, Text, useTheme} from "@fluentui/react";
import {useIntl} from "react-intl";
import {SanitizedText} from "../../../../../../components";
import {Col} from "reactstrap";

export type GroupSummaryType = 'manual' | 'system' | 'total' | 'verified';

export interface IGroupSummary {
    [key: string]: number;
}

export interface IGroupSummaryColors {
    [key: string]: GroupSummaryItemColor;
}

export interface IGroupWithSummary extends IGroup {
    summary: IGroupSummary;
    colors?: IGroupSummaryColors;
}

export interface IGroupSummaryProps {
    group: IGroupWithSummary;
    style?: any
    childRenderer(group: IGroupWithSummary): ReactNode
}

export type GroupSummaryItemColor = 'default' | 'red' | 'green';

export interface IGroupSummaryItemProps {
    value?: number,
    type: GroupSummaryType,
    color?: GroupSummaryItemColor
}

export const GetSummaryItemColor = (value: any, comapreTo: any): GroupSummaryItemColor => {
    let valueN = Math.ceil(+(value ?? NaN) * 100);
    let compareToN = Math.ceil(+(comapreTo ?? NaN) * 100) / 100;
    if (!isNaN(valueN) && !isNaN(compareToN)) {
        return valueN < compareToN ? 'red' : 'green';
    }
    return 'default';
} 

export const GroupSummary: FunctionComponent<IGroupSummaryProps> = ({
                                                                        group, style = {},
                                                                        childRenderer,
                                                                    }: IGroupSummaryProps) => {
    return (
        <Stack horizontal tokens={{childrenGap: 16}} style={{paddingLeft: 16, paddingRight: 16, width: '100%', ...style}} verticalAlign={"center"}>
            <Stack.Item>
                <SanitizedText data={group.name} isHeadingRow={true} />
            </Stack.Item>
            <Stack.Item>
                {childRenderer(group)}
            </Stack.Item>
        </Stack>
    )
}

export const GroupSummaryItem: FunctionComponent<IGroupSummaryItemProps> = ({
                                                                                value,
                                                                                type, 
                                                                                color = 'default'
                                                                            }: IGroupSummaryItemProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const textColor = color === 'red' ? theme.palette.red : color === 'green' ? theme.palette.green : 'inherit'
    
    return (
        <Stack horizontal>
            <Stack.Item>
                <SanitizedText color={textColor} data={(value ?? 0).toString()} numberFormat='percentage-fractional' format={'Bold'}/>
            </Stack.Item>
            <Stack.Item>
                <SanitizedText color={textColor} data={' ' + formatMessage({id: type})} format={'Bold'}/>
            </Stack.Item>
        </Stack>
    )
}