import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";



export const BGL360Trialbalance = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'lastYear',
                name: formatMessage({ id: 'lastYear' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'lastYear',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} format={item?.isBold ? 'Bold' : item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'code',
                name: formatMessage({ id: 'code' }),
                minWidth: 200,
                maxWidth: 200,
                fieldName: 'code',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.isBold ? 'Bold' : item?.format} />,
            },
            {
                key: 'accountName',
                name: formatMessage({ id: 'accountName' }),
                minWidth: 400,
                maxWidth: 428,
                fieldName: 'accountName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.isBold ? 'Bold' : item?.format} />,
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.isBold ? 'Bold' : item?.format} />,
            },
            {
                key: 'debits',
                name: formatMessage({ id: 'debits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'debits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.isBold ? 'Bold' : item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'credits',
                name: formatMessage({ id: 'credits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'credits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.isBold ? 'Bold' : item?.format} textAlign='right' numberFormat='fractional' />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
