import React, { FunctionComponent, useMemo } from "react";
import { Stack, Text } from "@fluentui/react";
import { FormatType, SanitizedText } from "../../../../../../../components";

type ReportMoneyValueCellProps = {
	value?: number;
	isBold: boolean;
	allowNull?: boolean;
}

export const ReportMoneyValueCell: FunctionComponent<ReportMoneyValueCellProps> = ({ value, isBold, allowNull, ...props }: Partial<ReportMoneyValueCellProps>) => {

	const data = useMemo(() => {
		const _ = (value ?? 0);
		if (_ === 0) {
			return !!allowNull ? _.toString() : ''
		}
		
		return _?.toString() || ''
	}, []);
	
	return (
		<SanitizedText data={data} isHeadingRow={isBold} textAlign='right' numberFormat='fractional' />
	);
};
