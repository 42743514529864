import {Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo} from 'react';
import {FilterByPeriod, ITableFilters} from '../GeneratedDataTable';
import {IDropdownOption, Stack} from '@fluentui/react';
import {ControlledDropdown} from 'components';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';

export interface IGeneratedPeriodsForm {
    filterByPeriod?: string | number;
}

export interface IGeneratedPeriodFilterProps {
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
}

export const GeneratedPeriodsFilter: FunctionComponent<IGeneratedPeriodFilterProps> = ({setFilters}) => {
    const {formatMessage} = useIntl();
    const {control, watch} = useForm<IGeneratedPeriodsForm>({
        mode: 'onChange',
        defaultValues: {
            filterByPeriod: FilterByPeriod.Week,
        },
    });

    const options: IDropdownOption[] = useMemo(() => [
        {
            text: formatMessage({id: 'week'}),
            key: FilterByPeriod.Week,
            selected: true
        },
        {
            text: formatMessage({id: 'month'}),
            key: FilterByPeriod.Month,
        },
        {
            text: formatMessage({id: 'year'}),
            key: FilterByPeriod.Year,
        },
    ], [formatMessage]);

    const onSubmit = useCallback((newFilterData: IGeneratedPeriodsForm) => setFilters((prev) => ({
        ...prev, ...newFilterData,
        page: 1
    })), [setFilters]);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    return (
        <Stack styles={{root: {width: 80}}}>
            <ControlledDropdown name='filterByPeriod' control={control} options={options}/>
        </Stack>
    );
};