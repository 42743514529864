import { Stack } from '@fluentui/react';
import { FunctionComponent } from 'react';

export const LabelAnswer: FunctionComponent = () => {
  // !TODO
  return (
    <Stack grow>
      <label></label>
    </Stack>
  );
};