import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IValidationErrors} from "../../../../interfaces";

function useGetReportValidations(jobId: number) {
    const context = useContext(Context);

    const {
        data,
        isLoading: isValidationsLoading,
        refetch: refetchValidations,
        isFetching: isValidationsFetching
    } = useQuery(['getReportsValidations', jobId], () =>
            axios.get<IValidationErrors>(`${context?.config?.validationsUrl}/api/1.0/validation/${jobId}?isValidationSection=false`, {
                ...commonHeaders(),
            }),
        {
            enabled: !!context?.config?.reportsUrl && !!context.tenantId,
            retry: false,
        }
    );

    return {validations: data?.data?.validationErrors ?? [], isValidationsLoading, refetchValidations, isValidationsFetching};
}

export {useGetReportValidations};