import React, { forwardRef, FunctionComponent, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Checkbox, DialogType, IconButton, IDropdownOption, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { ControlledCheckbox, ControlledDropdown, ControlledTextField, DialogMessage, ITableColumn, SanitizedText } from 'components';
import { ColumnWithSize } from '../../../../interfaces';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { Control } from 'react-hook-form/dist/types/form';
import { useCreateIdentifiedRisksItem, useDeleteIdentifiedRisksItem, useUpdateIdentifiedRisksItem } from './hooks';
import { useUpdateRisksConclusion } from './hooks/useUpdateRisksConclusion';
import { useBoolean } from '@fluentui/react-hooks';

interface IIdentifiedRisksItem {
    id: number;
    finReport?: string;
    identifiedRisk?: string;
    lHood?: number;
    cQuence?: number;
    inherentRisk?: number;
    existingControls?: string;
    controlRating?: number;
    empty?: string;
    residualRisk?: number;
    furtherAudit?: string;
    jobId: number;
}

type IdentifiedRisksTableItem = IIdentifiedRisksItem & { itemId: number };

export const AssessmentIdentifiedRiskProcedureTemplate = forwardRef(({}, ref) => {
    const { items, isLoading } = useProcedureContext();
    const { formatMessage } = useIntl();
    const { jobId } = useJobContext();
    const theme = useTheme();

    const { deleteIdentifiedRisks, isDeleting } = useDeleteIdentifiedRisksItem();
    const { createIdentifiedRisks, isCreating } = useCreateIdentifiedRisksItem();
    const { updateIdentifiedRisks, isUpdating } = useUpdateIdentifiedRisksItem();

    const [currentItem, setCurrentItem] = useState<(IdentifiedRisksTableItem & { index?: number }) | null>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const { control, setValue, handleSubmit } = useForm<{ items: IdentifiedRisksTableItem[] }>({
        ...DefaultFormSettings,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    });

    const lHoodOptions: IDropdownOption<any>[] = useMemo<IDropdownOption<any>[]>(() => {
        return [
            { key: 0, text: '' },
            { key: 5, text: '5 - ' + formatMessage({ id: 'guaranteedToOccur' }) },
            { key: 4, text: '4 - ' + formatMessage({ id: 'highlyLikelyToOccur' }) },
            { key: 3, text: '3 - ' + formatMessage({ id: 'likelyToOccur' }) },
            { key: 2, text: '2 - ' + formatMessage({ id: 'mayOccur' }) },
            { key: 1, text: '1 - ' + formatMessage({ id: 'unlikelyToOccur' }) },
        ];
    }, [formatMessage]);

    const cQuenceOptions: IDropdownOption<any>[] = useMemo<IDropdownOption<any>[]>(() => {
        return [
            { key: 0, text: '' },
            { key: 5, text: '5 - ' + formatMessage({ id: 'catastrophic' }) },
            { key: 4, text: '4 - ' + formatMessage({ id: 'severe' }) },
            { key: 3, text: '3 - ' + formatMessage({ id: 'damaging' }) },
            { key: 2, text: '2 - ' + formatMessage({ id: 'moderate' }) },
            { key: 1, text: '1 - ' + formatMessage({ id: 'minor' }) },
        ];
    }, [formatMessage]);

    const controlRatingOptions: IDropdownOption<any>[] = useMemo<IDropdownOption<any>[]>(() => {
        return [
            { key: 0, text: '' },
            { key: 5, text: '5 - ' + formatMessage({ id: 'excellent' }) },
            { key: 4, text: '4 - ' + formatMessage({ id: 'veryGood' }) },
            { key: 3, text: '3 - ' + formatMessage({ id: 'good' }) },
            { key: 2, text: '2 - ' + formatMessage({ id: 'moderate' }) },
            { key: 1, text: '1 - ' + formatMessage({ id: 'nonExistent' }) },
        ];
    }, [formatMessage]);

    const columns: ColumnWithSize[] = [
        {
            key: 'identifiedRisk',
            name: formatMessage({ id: 'identifiedRiskIncludeDescription' }),
            fieldName: 'identifiedRisk',
            size: 6,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell item={field} index={index} name={'identifiedRisk'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'finReport',
            name: formatMessage({ id: 'finReportOrAssertions' }),
            fieldName: 'finReport',
            size: 5,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell item={field} index={index} name={'finReport'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'lHood',
            name: formatMessage({ id: 'lHood' }),
            fieldName: 'lHood',
            size: 2,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksDropdownCell
                        item={field}
                        index={index}
                        name={'lHood'}
                        options={lHoodOptions}
                        control={control}
                        onChange={onBlur}
                    />
                ),
        },
        {
            key: 'cQuence',
            name: formatMessage({ id: 'cQuence' }),
            fieldName: 'cQuence',
            size: 6,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksDropdownCell
                        item={field}
                        index={index}
                        name={'cQuence'}
                        options={cQuenceOptions}
                        control={control}
                        onChange={onBlur}
                    />
                ),
        },
        {
            key: 'inherentRisk',
            name: formatMessage({ id: 'inherentRisk' }),
            fieldName: 'inherentRisk',
            size: 1,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell
                        item={field}
                        index={index}
                        editable={false}
                        name={'inherentRisk'}
                        control={control}
                        onBlur={onBlur}
                    />
                ),
        },
        {
            key: 'existingControls',
            name: formatMessage({ id: 'existingControls' }),
            fieldName: 'existingControls',
            size: 1,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell item={field} index={index} name={'existingControls'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'controlRating',
            name: formatMessage({ id: 'controlRating' }),
            fieldName: 'controlRating',
            size: 2,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksDropdownCell
                        item={field}
                        index={index}
                        name={'controlRating'}
                        options={controlRatingOptions}
                        control={control}
                        onChange={onBlur}
                    />
                ),
        },
        {
            key: 'empty',
            name: '',
            fieldName: 'empty',
            size: 2,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell item={field} index={index} name={'empty'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'residualRisk',
            name: formatMessage({ id: 'residualRisk' }),
            fieldName: 'residualRisk',
            size: 1,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell
                        item={field}
                        index={index}
                        editable={false}
                        name={'residualRisk'}
                        control={control}
                        onBlur={onBlur}
                    />
                ),
        },
        {
            key: 'furtherAudit',
            name: formatMessage({ id: 'furtherAuditProcedures' }),
            fieldName: 'furtherAudit',
            size: 4,
            onRender: (field, index, column) =>
                index !== undefined && (
                    <IdentifiedRisksTextCell item={field} index={index} name={'furtherAudit'} control={control} onBlur={onBlur} />
                ),
        },
        {
            key: 'remove',
            name: '',
            size: 1,
            fieldName: 'remove',
            onRender: (field, index) => (
                <Stack grow horizontalAlign='center'>
                    {index !== undefined && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                            onClick={() => {
                                setCurrentItem({ ...field, index: index });
                                toggleDeleteDialog();
                            }}
                        />
                    )}
                </Stack>
            ),
        },
    ];

    const onDelete = (id: number, index: number) => {
        deleteIdentifiedRisks(id, {
            onSuccess: () => {
                remove(index);
            },
        });
    };

    const onBlur = (index: number) => {
        handleSubmit((items: { items: IdentifiedRisksTableItem[] }) => {
            updateIdentifiedRisks(items.items[index]);

            updateCalculatedFields(items.items[index], index);
        })();
    };

    const createItem = () => {
        createIdentifiedRisks(
            { jobId: jobId },
            {
                onSuccess: (response: any) =>
                    append({
                        id: response.data.id,
                        itemId: response.data.id!,
                        finReport: '',
                        identifiedRisk: '',
                        lHood: 0,
                        cQuence: 0,
                        inherentRisk: 0,
                        existingControls: '',
                        controlRating: 0,
                        empty: '',
                        residualRisk: 0,
                        furtherAudit: '',
                        jobId: jobId,
                    }),
            }
        );
    };

    const updateCalculatedFields = (item: IdentifiedRisksTableItem, index: number) => {
        const lHood = item.lHood ?? 0;
        const cQuence = item.cQuence ?? 0;
        const controlRating = item.controlRating ?? 0;

        setValue(`items.${index}.inherentRisk`, lHood * cQuence);
        setValue(`items.${index}.residualRisk`, lHood * cQuence - controlRating);
    };

    useImperativeHandle(ref, () => ({
        onAddClick() {
            createItem();
        },
    }));

    useEffect(() => {
        const tableItems: IdentifiedRisksTableItem[] = [
            ...(items[0].gridItems || []).map((x: IIdentifiedRisksItem) => {
                return {
                    ...x,
                    itemId: x.id,
                };
            }),
        ];

        setValue('items', tableItems);
        tableItems.map((v, index) => updateCalculatedFields(v, index));
    }, [items]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Text>
                <strong>{formatMessage({ id: 'objective' }) + ': '}</strong>
                {formatMessage({ id: 'toIdentifyAndAssessTheRisks' })}
            </Text>
            <ProceduresDataTable
                header={{ rowHeight: 138 }}
                items={fields}
                enableShimmer={isLoading || isCreating || isDeleting}
                hideIfEmpty={false}
                disableDragDrop={true}
                columns={columns}
            />

            <RisksConclusionTemplate></RisksConclusionTemplate>
            <Stack tokens={{ childrenGap: 16 }}>
                <Text>
                    <strong>{formatMessage({ id: 'keyResidualRisk' })}</strong>
                </Text>
                <Text>20 - 24 {formatMessage({ id: 'extremeRisk' })}</Text>
                <Text>15 - 19 {formatMessage({ id: 'significantRisk' })}</Text>
                <Text>10 - 14 {formatMessage({ id: 'majorRisk' })}</Text>
                <Text>5 - 9 {formatMessage({ id: 'moderateRisk' })}</Text>
                <Text>1 - 4 {formatMessage({ id: 'minorRisk' })}</Text>
            </Stack>
            <Stack grow horizontal horizontalAlign='center' tokens={{ childrenGap: 64 }}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Text>
                        <strong>{formatMessage({ id: 'riskLikelihood' })}</strong>
                    </Text>
                    <Text>5 {formatMessage({ id: 'guaranteedToOccur' })}</Text>
                    <Text>4 {formatMessage({ id: 'highlyLikelyToOccur' })}</Text>
                    <Text>3 {formatMessage({ id: 'likelyToOccur' })}</Text>
                    <Text>2 {formatMessage({ id: 'mayOccur' })}</Text>
                    <Text>1 {formatMessage({ id: 'unlikelyToOccur' })}</Text>
                </Stack>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Text>
                        <strong>{formatMessage({ id: 'riskConsequence' })}</strong>
                    </Text>
                    <Text>5 {formatMessage({ id: 'catastrophicRC' })}</Text>
                    <Text>4 {formatMessage({ id: 'serveRC' })}</Text>
                    <Text>3 {formatMessage({ id: 'damagingRC' })}</Text>
                    <Text>2 {formatMessage({ id: 'moderateRC' })}</Text>
                    <Text>1 {formatMessage({ id: 'minorRC' })}</Text>
                </Stack>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Text>
                        <strong>{formatMessage({ id: 'controlRating' })}</strong>
                    </Text>
                    <Text>5 {formatMessage({ id: 'excellent' })}</Text>
                    <Text>4 {formatMessage({ id: 'veryGood' })}</Text>
                    <Text>3 {formatMessage({ id: 'good' })}</Text>
                    <Text>2 {formatMessage({ id: 'moderate' })}</Text>
                    <Text>1 {formatMessage({ id: 'nonExistent' })}</Text>
                </Stack>
            </Stack>

            <DialogMessage
                onClick={() => {
                    toggleDeleteDialog();
                    onDelete(currentItem!.itemId, currentItem!.index!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}
            />
        </Stack>
    );
});

interface IIdentifiedRisksCell {
    item: IdentifiedRisksTableItem;
    index: number;
    control: Control<{ items: IdentifiedRisksTableItem[] }>;
    name: keyof IdentifiedRisksTableItem;
    onBlur: (index: number) => void;
}

type IdentifiedRisksTextCell = IIdentifiedRisksCell & { editable?: boolean };

const IdentifiedRisksTextCell: FunctionComponent<IdentifiedRisksTextCell> = ({
    index,
    control,
    name,
    onBlur,
    editable = true,
}: IdentifiedRisksTextCell) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const classNames = mergeStyleSets({
        stack: {
            width: '100%',
            height: '100%',
            'align-items': 'start',
            'justify-content': 'center',

            '> .ms-TextField': {
                width: 'inherit',
            },
        },
    });

    return (
        <Stack
            grow
            horizontalAlign='center'
            className={classNames.stack}
            onClick={() => (editable && !isEditMode ? setEditMode(true) : (() => {})())}>
            {isEditMode ? (
                <ControlledTextField
                    name={`items.${index}.${name}`}
                    control={control}
                    onBlur={() => {
                        onBlur(index);
                        setEditMode(false);
                    }}
                />
            ) : (
                <Text>{control._formValues.items[index][name]}</Text>
            )}
        </Stack>
    );
};

type IdentifiedRisksDropdownCell = Omit<IIdentifiedRisksCell, 'onBlur'> & {
    options: IDropdownOption<any>[];
    onChange: (index: number, key: number) => void;
};

const IdentifiedRisksDropdownCell: FunctionComponent<IdentifiedRisksDropdownCell> = ({
    index,
    control,
    name,
    onChange,
    options,
}: IdentifiedRisksDropdownCell) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const classNames = mergeStyleSets({
        stack: {
            width: '100%',
            height: '100%',
            'align-items': 'start',
            'justify-content': 'center',

            '> .ms-Dropdown-container': {
                width: 'inherit',
            },
        },
    });

    return (
        <Stack grow horizontalAlign='center' className={classNames.stack} onClick={() => (!isEditMode ? setEditMode(true) : (() => {})())}>
            {isEditMode ? (
                <ControlledDropdown
                    options={options}
                    control={control}
                    name={`items.${index}.${name}`}
                    onValueChange={(key: number) => {
                        onChange(index, key);
                        setEditMode(false);
                    }}
                />
            ) : (
                <Text>{options.find((o) => o.key === control._formValues.items[index][name])?.text ?? ''}</Text>
            )}
        </Stack>
    );
};

interface IRisksConclusionProps {
    responseCompleted: boolean;
    disclosureCompleted: boolean;
    jobId: number;
    id: number;
}

type RisksConclusionFormProps = Omit<IRisksConclusionProps, 'jobId'>;

const RisksConclusionTemplate: FunctionComponent = ({}) => {
    const { items, isLoading } = useProcedureContext();
    const { formatMessage } = useIntl();
    const { jobId } = useJobContext();

    const { update, isUpdating } = useUpdateRisksConclusion();

    const { control, setValue, handleSubmit, watch } = useForm<RisksConclusionFormProps>({
        ...DefaultFormSettings,
        mode: 'onChange',
    });

    const onSubmit = useCallback((data: any) => {
        update({
            jobId: jobId,
            ...data,
        });
    }, []);

    useEffect(() => {
        setValue('id', items[0].riskConclusion?.id ?? null);
        setValue('responseCompleted', !!items[0].riskConclusion?.responseCompleted);
        setValue('disclosureCompleted', !!items[0].riskConclusion?.disclosureCompleted);
    }, [items]);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <Text>
                    <strong>{formatMessage({ id: 'conclusionTick' })}</strong>
                </Text>
            </Stack.Item>
            <Stack.Item>
                <ControlledCheckbox
                    name={'responseCompleted'}
                    control={control}
                    label={formatMessage({ id: 'allRisksOfMaterialMisstatement' })}
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledCheckbox
                    name={'disclosureCompleted'}
                    control={control}
                    label={formatMessage({ id: 'afterCompletingTheAuditProgram' })}
                />
            </Stack.Item>
        </Stack>
    );
};
