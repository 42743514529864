import {FunctionComponent, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {INavLink} from '@fluentui/react';
import {IMenuLink, Layout} from 'components';
import {RouterPaths} from 'navigation';

export const AdminLayoutPage: FunctionComponent = () => {
    const [links] = useState<IMenuLink[]>([
        {
            name: 'Rulesets',
            url: RouterPaths.admin.rulesets.root,
            key: 'ruleset',
            iconProps: { iconName: 'ProductVariant' },
        },
    ]);

    return (
        <Layout links={links}>
            <Outlet />
        </Layout>
    );
};
