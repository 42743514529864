import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';
import { FunctionComponent } from 'react';

export const RiskMatrixSettingsLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'riskMatrixSettings',
                key: RouterPaths.portalSettings.riskMatrixSettings,
                text: formatMessage({ id: 'riskMatrixSettings' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
