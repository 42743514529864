import {DatePicker, Icon, Label, mergeStyleSets, Stack, useTheme} from "@fluentui/react";
import {format} from "date-fns";
import React from "react";
import {useIntl} from "react-intl";

interface IARDateFilterProps {

    arDateFrom?: Date | undefined;
    arDateTo?: Date | undefined;
    onFromSet: (date: Date | undefined) => void;
    onToSet: (date: Date | undefined) => void;
}

export const JobARDateFilter = (props: IARDateFilterProps) => {
    const {formatMessage} = useIntl();
    const styles = mergeStyleSets({
        control: {
            minWidth: 165,
            maxWidth: 300,
        }
    });

    const theme = useTheme();

    const pickerStyles = {
        icon: { color: theme.palette.themePrimary, left: "9px", right: "unset" },
        callout: {
            selectors:
            {
                'span, i, button, th': { color: theme.schemes?.default?.semanticColors.bodyText },
                'span:hover, i:hover, button:hover': { color: theme.palette.themePrimary },
            }
        },
        root: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            selectors: {
                '.ms-TextField-fieldGroup': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 },
                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
            }
        },
        readOnlyPlaceholder: { color: theme.schemes?.default?.semanticColors.disabledBodyText }
    }

    const fieldProps = (type: "from" | "to") => {
        return {
            onRenderSuffix: () => (
                <Icon iconName={'clear'} onClick={() => clearDate(type)}/>),
            styles: {
                field: {
                    paddingLeft: 40
                },
                suffix: {
                    padding: "0 8px",
                    fontSize: "x-small",
                    cursor: "pointer",
                    background: 'transparent'
                }
            }
        }
    };


    const clearDate = (type: "from" | "to") => {
        if (type == "from") props.onFromSet(undefined);
        if (type == "to") props.onToSet(undefined);
    };

    const onFormatDate = (date?: Date): string => {
        return date ? format(date, 'dd/MM/yyyy') : '';
    };
    return (
        <Stack horizontal tokens={{childrenGap: 16}}>
            <Label>{formatMessage({id: 'dateARSent'})}:</Label>
            <DatePicker placeholder={formatMessage({id: 'from'})}
                        formatDate={onFormatDate}
                        textField={fieldProps("from")}
                        onSelectDate={(date) => props.onFromSet(date as Date)}
                        styles={pickerStyles}
                        value={props.arDateFrom}
                        className={styles.control}/>
            <DatePicker placeholder={formatMessage({id: 'to'})}
                        formatDate={onFormatDate}
                        textField={fieldProps("to")}
                        value={props.arDateTo}
                        styles={pickerStyles}
                        onSelectDate={(date) => props.onToSet(date as Date)}
                        className={styles.control}/>
        </Stack>
    )
};
