import { Stack, Link, useTheme, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SoftwareType } from '../../../../../../enums';
import { useGetJobByGuid } from '../../../../../../hooks';
import { Context } from '../../../../../../utils';
import { useAdministrationContext } from '../../JobsLayoutPage';
import { ClientPortalTabs } from './ClientPortalTabs';
import { JobInformation } from './JobInformation';
import { SoftwareTypeInformation } from './SoftwareTypeInformation';
import { WizardQuestions } from './WizardQuestions';
import { WizardQuestionsInfo } from './WizardQuestionsInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface IInitialInformationChecklistPageProps {
    jobGuid: string;
    toggle: () => void;
}

export const InitialInformationChecklistPage: FunctionComponent<IInitialInformationChecklistPageProps> = ({ jobGuid, toggle }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const context = useContext(Context);
    const { dataJobs, isJobLoading, isJobRefetching } = useGetJobByGuid({ guid: jobGuid });
    const { selectedClient } = useAdministrationContext();
    const [areSoftwareFilesUploaded, setAreSoftwareFilesUploaded] = useState(false);
    const [selectedSoftwareType, setSelectedSoftwareType] = useState(dataJobs?.data?.clientPortalSoftwareType);
    const [isFundCodeNeeded, setIsFundCodeNeeded] = useState(dataJobs?.data?.clientPortalSoftwareType === SoftwareType.ClassApiInCp || dataJobs?.data?.clientPortalSoftwareType === SoftwareType.BGL360);

    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const [isNavigatedNotFromIic, setIsNavigatedNotFromIic] = useState(false);

    useEffect(() => {
        if (searchParams[0].get("showIic") === "true" && !!searchParams[0].get("jobGuid")) {
            setIsNavigatedNotFromIic(true);
        }
    }, [searchParams]);

    const updateSoftwareFilesState = (state: boolean) => {
        setAreSoftwareFilesUploaded(state);
    }

    const updateIsFundCodeNeeded = (state: boolean) => {
        setIsFundCodeNeeded(state);
    }

    const updateSelectedSoftwareType = (softwareType: SoftwareType) => {
        setSelectedSoftwareType(softwareType);
    }

    if (!dataJobs?.data || isJobLoading || isJobRefetching) {
        return (
            <Stack tokens={{ childrenGap: 12 }} styles={{ root: { height: '100%' } }}>
                <Link style={{ color: theme.schemes?.default?.palette.blue }} onClick={() => { isNavigatedNotFromIic ? navigate(-1) : toggle(); }}>{formatMessage({ id: 'back' })}</Link>
                <Spinner style={{ height: '100%' }} size={SpinnerSize.large} />
            </Stack>);
    }

    return (
        <Stack tokens={{ childrenGap: 12 }} styles={{ root: { height: '100%' } }}>
            <Link style={{ color: theme.schemes?.default?.palette.blue }} onClick={() => { isNavigatedNotFromIic ? navigate(-1) : toggle(); }}>{formatMessage({ id: 'back' })}</Link>
            <Stack horizontal styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, width: '100%' } }}>
                <Stack.Item grow={1}>
                    <JobInformation job={dataJobs?.data} areSoftwareFilesUploaded={areSoftwareFilesUploaded} isFundCodeNeeded={isFundCodeNeeded} clientName={selectedClient?.name ?? ''}/>
                </Stack.Item>
                <Stack.Item grow={1}>
                    <SoftwareTypeInformation job={dataJobs?.data} updateSoftwareFilesState={updateSoftwareFilesState}
                        updateIsFundCodeNeeded={updateIsFundCodeNeeded} updateSelectedSoftwareType={updateSelectedSoftwareType} />
                </Stack.Item>
            </Stack>
            {(!context?.tenantInfo?.IsWhiteLabel && dataJobs?.data?.clientQuestionsState === "NeedAnswers" && dataJobs?.data?.iicQuestionsEnabled) &&
                <Stack horizontal styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, width: '100%' } }}>
                    <Stack.Item grow={1}>
                        <WizardQuestions job={dataJobs?.data} selectedSoftwareType={selectedSoftwareType} />
                    </Stack.Item>
                </Stack>}
            
                <Stack horizontalAlign={'space-between'} horizontal styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, width: '100%' } }}>
                <Stack.Item styles={{ root: { width: (dataJobs?.data?.clientQuestionsState === "Completed" && !context?.tenantInfo?.IsWhiteLabel && dataJobs?.data?.iicQuestionsEnabled) ? '45%' : '100%' } }}>
                    <ClientPortalTabs job={dataJobs?.data} selectedSoftwareType={selectedSoftwareType} isWhiteLabel={context?.tenantInfo?.IsWhiteLabel} />
                </Stack.Item>
                {(dataJobs?.data?.clientQuestionsState === "Completed" && !context?.tenantInfo?.IsWhiteLabel && dataJobs?.data?.iicQuestionsEnabled) &&
                    <Stack.Item styles={{ root: { width: '45%' } }}>
                        <WizardQuestionsInfo job={dataJobs?.data} />
                    </Stack.Item>
                }
                </Stack>
        </Stack>
    );
}