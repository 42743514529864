import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

import {ClientInfo, EntityStatus, FilteredResult, IPaginationFilter} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";

export interface IClientPaginationFilter extends IPaginationFilter {
    status?: EntityStatus;
    name?: string;
}


function useGetClientByFilter(filter: IClientPaginationFilter) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();

    const {data: dataClientsFilter, isLoading: isLoadingClientsFilter} = useQuery(
        ['getClientsByFilter', filter],
        () =>
            axios.get<FilteredResult<ClientInfo>>(`${context?.config?.clientUrl}/api/1.0/Clients/filter`, {
                ...commonHeaders(),
                params: filter
            }),
        {
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            },
            enabled: !!context?.config?.clientUrl,
        }
    );

    return {dataClientsFilter, isLoadingClientsFilter};
}

function useGetClientsWithoutFilter() {
    const context = React.useContext(Context);


    const {data: allClients, isLoading: isAllClientsLoading} = useQuery(
        ['getAllClients'],
        () => axios.get<FilteredResult<ClientInfo>>(`${context?.config?.clientUrl}/api/1.0/Clients/filter`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl,
        }
    );

    return {allClients, isAllClientsLoading};
}

export {useGetClientByFilter, useGetClientsWithoutFilter};
