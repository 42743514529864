import * as React from 'react';
import {FunctionComponent} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'mobx-react';
import {IntlProvider} from 'react-intl';
import {stores} from 'stores';
import en from 'lang/en.json';
import {AppTheme} from 'AppTheme';
import {AppRoutes} from 'navigation';
import {QueryClientProvider} from 'react-query';
import './App.scss';
import {queryClient} from 'utils/queryClient';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorPage} from 'pages/shared';
import {NotificationProvider} from "./components/notifications";
import { AuthProvider, HttpServiceProvider, SignalRProvider, TenantProvider, WorkContextProvider } from "./providers";
import {ContextMenuProvider} from "./components";

interface Props {
    baseUrl: string;
}

export const App: FunctionComponent<Props> = (props: Props) => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Provider {...stores}>
                <IntlProvider messages={en} locale='en' defaultLocale='en'>
                    <BrowserRouter basename={props.baseUrl}>
                        <QueryClientProvider client={queryClient}>
                            <NotificationProvider>
                                <HttpServiceProvider>
                                    <AppTheme>
                                        <AuthProvider>
                                            <TenantProvider>
                                                <SignalRProvider>
                                                    <ContextMenuProvider>
                                                        <WorkContextProvider>
                                                            <AppRoutes/>
                                                        </WorkContextProvider>
                                                    </ContextMenuProvider>
                                                </SignalRProvider>
                                            </TenantProvider>
                                        </AuthProvider>
                                    </AppTheme>
                                </HttpServiceProvider>
                            </NotificationProvider>
                        </QueryClientProvider>
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        </ErrorBoundary>
    );
}
