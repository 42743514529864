import {Card, DataTable, FileViewer, Loading} from "../../../../../../components";
import {IconButton, Link, Spinner, Stack, useTheme} from "@fluentui/react";
import {IAttachment, useGetAttachmentByDocumentType, useRemoveAttachment} from "../../../../hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {DocumentType} from "../../../../enums";
import {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useGetFile} from "../../../../../../hooks";
import {getFileIcon} from "../../../../../../utils";
import { useRemoveScheduledExportAttachment } from "./hooks/useRemoveScheduledExportAttachment";

type PrintToPdfDocumentsProps = {
    title: string;
}

export const PrintToPdfDocuments = ({title}: PrintToPdfDocumentsProps) => {
        const {job} = useJobContext();
        const {palette} = useTheme();
        const {getFile} = useGetFile();
        const {deleteAttachment, isLoading: isDeleting} = useRemoveScheduledExportAttachment(job.id);
        const {formatMessage} = useIntl();
        const [viewerState, setViewerState] = useState<{ show: boolean, file: IAttachment | null }>({
            show: false,
            file: null
        });
        const [pdfDocuments, setPdfDocuments] = useState<IAttachment[]>();
        const {
            response,
            isLoading,
        } = useGetAttachmentByDocumentType(job.id, DocumentType.PdfPublish);

        const handleOnDownload = (url: string, fileName: string) => {
            getFile({url: url, fileName: fileName});

        };
        const handleOnDelete = (attachmentId: number) => {
            deleteAttachment(attachmentId, {
                onSuccess: () => {
                    setPdfDocuments(pdfDocuments?.filter(x => x.id !== attachmentId));
                }
            });
        };

        const columns = useMemo(() => {
            return [
                {
                    key: 'fileIcon',
                    name: '',
                    minWidth: 20,
                    maxWidth: 20,
                    onRender: (item: IAttachment) => {
                        const fileInfo = getFileIcon(item.fileName);
                        return (<Stack horizontalAlign={'center'} grow>
                                <img key={`job-export-${fileInfo.docType}-${item.id}`} src={fileInfo.url}
                                     alt={fileInfo.docType}/>
                            </Stack>
                        )
                    }

                },
                {
                    key: 'fileName',
                    name: formatMessage({id: 'printToPdf_title_fileName'}),
                    fieldName: 'fileName',
                    minWidth: 160,
                    onRender: (item: IAttachment) => {
                        return (
                            <Link onClick={() => setViewerState({
                                show: true,
                                file: item
                            })}>{item.fileName}</Link>
                        )
                    }
                },
                {
                    key: 'actions',
                    name: '',
                    minWidth: 90,
                    maxWidth: 90,
                    onRender: (item: IAttachment) => {
                        return (
                            <Stack horizontal horizontalAlign={'center'} grow>
                                {isDeleting && (<Spinner/>)}
                                {!isDeleting && (
                                    <><IconButton onClick={() => handleOnDownload(item.downloadUrl, item.fileName)}
                                                  iconProps={{iconName: 'download'}}/>
                                        <IconButton onClick={() => handleOnDelete(item.id)} iconProps={{iconName: 'delete'}}
                                                    styles={{
                                                        icon: {color: palette.red},
                                                        iconHovered: {color: palette.redDark}
                                                    }}/>
                                    </>
                                )}
                            </Stack>
                        )
                    }
                }
            ];
        }, [pdfDocuments, isDeleting]);

        useEffect(() => {
            setPdfDocuments(response);
        }, [response])

        if (isLoading)
            return <Loading/>;

        return (
            <>
                <Card title={title}>
                    <Stack wrap horizontal grow tokens={{childrenGap: 16}}>
                        <DataTable initialColumns={columns} columns={columns} items={pdfDocuments || []} emptyProps={{iconName:'ReportDocument'}}/>
                    </Stack>
                </Card>

                <FileViewer
                    fileName={viewerState.file?.fileName || ''}
                    url={viewerState.file?.downloadUrl || ''}
                    onDismiss={() => setViewerState({show: false, file: null})}
                    isOpen={viewerState.show}/>
            </>
        );
    }
;