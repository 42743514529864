import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IContraventionAPWidgetData,
    IDashboardConfig,
    WidgetsEnum
} from "pages/shared/Dashboard";


export const ContraventionAuditPortalDashboard = () => {
    const {formatMessage} = useIntl();

    const getContraventionDashboardConfig = useCallback(
        (props: IContraventionAPWidgetData): IDashboardConfig => {
            return {
                id: 'auditor',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrReadyForLodgement',
                            tables: [{ tableType: DashboardTableTypeEnum.Contravention_AcrReadyForLodgement, isWorkflow: true}],
                            props: {
                                number: props.acrReadyForLodgement,
                                title: formatMessage({id: 'acrReadyForLodgement'}),
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrLodgedThisMonth',
                            tables: [{ tableType: DashboardTableTypeEnum.Contravention_AcrLodgedThisMonth, isWorkflow: true}],
                            props: {
                                number: props.acrLodgedThisMonth,
                                title: formatMessage({id: 'acrLodgedThisMonth'}),
                                variant: 'tertiary',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'acrLodgedThisYear',
                            tables: [{ tableType: DashboardTableTypeEnum.Contravention_AcrLodgedThisYear, isWorkflow: true}],
                            props: {
                                number: props.acrLodgedThisYear,
                                title: formatMessage({id: 'acrLodgedThisYear'}),
                            },
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Contravention_AuditsWithinContraventionProcess,
                                isWorkflow: true
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return <DashboardContainer dashboardTab={DashboardTabsEnum.AP_Contravention}
        getDashboardConfig={getContraventionDashboardConfig as GetDashboardConfig}
        isWorkflow={true} />;
};
