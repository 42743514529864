import { Checkbox, Stack,ICheckboxProps } from '@fluentui/react';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {ControlledCheckbox} from "../../../../../components";
import {useForm} from "react-hook-form";

interface ICheckboxAnswerProps extends ICheckboxProps{
    onChange?: (answer: any) => void;
    
}

export const CheckboxWorkDoneAnswer: FunctionComponent<ICheckboxAnswerProps> = ({ label, name, checked, onChange, ...otherProps }) => {
    const {control, watch} = useForm<{ value: boolean }>({
        mode: 'onChange',
        defaultValues: {
            value: checked,
        },
    });

    const onSubmit = useCallback((data: any) => {
        if (onChange)
            onChange(data.value);
    }, []);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);
    
  return (
    <Stack grow>
      <ControlledCheckbox name={'value'} control={control} label={label} {...otherProps}/>
    </Stack>
  );
};