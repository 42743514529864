import axios from 'axios';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {useContext} from "react";
import * as url from "url";
import {useTenantInfo} from "../../../../../providers";

type AuditFirmResponse = {
    id: number;
    auditFirmTitle: string;
    auditFirmUrl: string;
    isSendingAnnotationsEnabled: boolean;
    isWhiteLabelAuditFirm: boolean;
    isExternalAuditFirm: boolean;
    isDefaultAuditFirm: boolean;
    isValidApiToken: boolean;
};

function useGetAuditFirms() {
    const context = useContext(Context);
    const {isWorkpapers} = useTenantInfo();
    const {data: auditFirms, isLoading} = useQuery(
        ['getAuditFirms'],
        () => axios.get<AuditFirmResponse[]>(`${context?.config?.settingsUrl}/api/1.0/Settings/auditFirmApiConnections`, commonHeaders()),
        {
            enabled: !!context?.config && !!context.tenantId && isWorkpapers,
        }
    );

    return {auditFirms, isLoading};
}

export {useGetAuditFirms};
