import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

type MutateDataType = {
    id: number;
    finReport?: string;
    identifiedRisk?: string;
    lHood?: number;
    cQuence?: number;
    inherentRisk?: number;
    existingControls?: string;
    controlRating?: number;
    empty?: string;
    residualRisk?: number;
    furtherAudit?: string;
    jobId: number;
}
function useUpdateIdentifiedRisksItem() {
    const context = useContext(Context);
    const {
        mutate: updateIdentifiedRisks,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/identifiedrisks/${data.jobId}`, data))

    return {updateIdentifiedRisks, isUpdating};
}

export {useUpdateIdentifiedRisksItem};