import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";

type MutateDataType = {
    clientId: number;
    contactId: number;
    firstName: string;
    lastName: string;
    jobTitle?: string | null;
}

function useUpdateContactInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {mutate: updateClientContact, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) => axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/contact/${data.contactId}/mainInfo`, data, commonHeaders()),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('getFilteredClients');
                queryClient.invalidateQueries('getClientByGuid');
                queryClient.invalidateQueries(['getClientContactById',variables.contactId, variables.clientId]);

            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateClientContact, isUpdating};
}

export {useUpdateContactInfo};