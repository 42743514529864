import { createRef, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryButton, useTheme } from '@fluentui/react';
import { CalculatorContent } from './CalculatorContent';
import { CalculatorType } from './CalculatorType';
import { FormattedMessage, useIntl } from 'react-intl';
import { JobPortalExternalPageContent } from '../../JobPortalExternalLayoutPage';
import { ISectionContext, SectionContext } from '..';
import { useGetSectionById } from '../Section/hooks';
import { useJobContext } from '../../JobPortalLayoutPage';
import { ITabContext, TabContext } from 'pages/JobPortal';
import { useWorkContext } from 'providers';
import { useGetJobTabs } from 'hooks';
import { logger } from 'services';

type CalculatorExternalTemplateProps = {};

export const CalculatorExternalTemplate: FunctionComponent<CalculatorExternalTemplateProps> = ({
    ...props
}: CalculatorExternalTemplateProps) => {
    const { formatMessage } = useIntl();
    const { calculatorType, sectionId } = useParams();
    const { job } = useJobContext();
    const { isJobPortalEnabled } = useWorkContext();
    const [isTabEnabled, setIsTabEnabled] = useState<boolean>(true);
    const [type, setType] = useState<CalculatorType | undefined>();

    const { section, isSectionLoading } = useGetSectionById({
        sectionId: +sectionId!,
        jobTemplateId: job.templateId,
        jobId: job.id,
        includeSectionAnswer: true,
    });
    const { dataJobsTab } = useGetJobTabs(job.templateId);
    const childRef = useRef<any>();

    const handleSubmit = useCallback(() => {
        childRef?.current?.onSubmit?.();
    }, [childRef?.current]);

    useEffect(() => {
        setType(calculatorType as CalculatorType);
    }, [calculatorType]);

    useEffect(() => {
        logger.debug('CALC:SECTION_ID', section?.tabId);

        const tab = dataJobsTab?.data.find((t: any) => t.id === section?.tabId);

        logger.debug('CALC:IS_TAB_ENABLED', isJobPortalEnabled, !tab?.isFreezedEnabled);

        setIsTabEnabled(isJobPortalEnabled || !tab?.isFreezedEnabled);
    }, [isJobPortalEnabled, dataJobsTab, section]);

    const sectionContextValues: ISectionContext = {
        section: section!,
        isOpened: true,
        open: () => {},
        close: () => {},
        refresh: () => {},
        fullScreen: () => {},
        setApprovingDisable: () => {},
        isPositiveSectionAnswer: false,
        forcePositiveSectionAnswer: (isPositive: boolean) => {},
        updateSectionAnswer: () => {},
        updateSectionAnswerIcon: () => {},
        hide: () => {},
        refreshSectionAnswers: () => {},
        sectionAnswersRefreshedTick: 0,
        enableWarningMessage: () => {},
        isWarningMessageForced: false,
        isWarningMessageEnabled: false,
        updateLoadedState: () => {},
        isLoaded: false,
    };

    const tabContextValues: ITabContext = useMemo(() => {
        return {
            tabId: section?.tabId || 0,
            closeSection: (reference) => {},
            closeSectionById: () => {},
            disableSection: () => {},
            openSection: () => {},
            refreshSection: () => {},
            refreshSectionById: () => {},
            updateSectionAnswerById: () => {},
            sections: [],
            isTabEnabled: isTabEnabled,
        };
    }, [isTabEnabled, section]);

    const content = useMemo(() => {
        return (
            <>
                {section && (
                    <TabContext.Provider value={tabContextValues}>
                        <SectionContext.Provider value={sectionContextValues}>
                            {type && <CalculatorContent childRef={childRef} type={type} />}
                        </SectionContext.Provider>
                    </TabContext.Provider>
                )}
            </>
        );
    }, [type, section, isTabEnabled, childRef]);

    const controls = useMemo(() => {
        return (
            <PrimaryButton onClick={handleSubmit} disabled={!isTabEnabled}>
                <FormattedMessage id='save' />
            </PrimaryButton>
        );
    }, [handleSubmit, childRef, isTabEnabled]);

    const theme = useTheme();

    if (!type) {
        return <></>;
    }

    return (
        <>
            <JobPortalExternalPageContent
                title={formatMessage({ id: `calculator_title_${type}` })}
                content={content}
                isLoading={isSectionLoading}
                controls={controls}
                topStackStyles={
                    isSectionLoading
                        ? undefined
                        : {
                              root: {
                                  marginRight: 'auto',
                                  marginLeft: 'auto',
                                  marginTop: '16px',
                                  boxShadow: theme.effects.elevation4,
                                  width: 'fit-content',
                              },
                          }
                }
            />
        </>
    );
};
