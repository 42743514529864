import {DefaultButton, DialogType, MessageBarType, Stack, Text, useTheme} from "@fluentui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {useMakeOrderSearch} from "../../../hooks/answers";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {PropertyDetailsState} from "../../../enums";
import {useProcedureContext} from "../../ProceduresContent";
import {IAuditProcedureInfo} from "../../../interfaces";
import {ControlledCheckbox, DialogMessage} from "../../../../../components";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNotifications} from "../../../../../components/notifications";
import {useGetClientAuditInfoByGuid} from "../../../hooks";
import {PortalRole} from "../../../../../providers";
import {PortalRoleGuard, TenantGuard} from "../../../../../guards";

interface OrderSearchButtonProps {
    folio: string;
    volume: string;
    tableType: number;
    titleReference: string;
    propertyState?: PropertyDetailsState
    itemId: number;
    disabled?: boolean;
}

export const OrderSearchButton = ({
                                      folio,
                                      titleReference,
                                      volume,
                                      propertyState,
                                      disabled,
                                      itemId,
                                      tableType
                                  }: OrderSearchButtonProps) => {
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const {job, clientGuid} = useJobContext();
    const {items} = useProcedureContext();
    const {showNotification} = useNotifications();
    const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
    const {makeSearchAsync, isLoading: isScheduling} = useMakeOrderSearch();
    const {control, watch} = useForm<{ override: boolean }>({
        ...DefaultButton,
        defaultValues: {override: false}
    });

    const [orderSearchState, setOrderSearchState] = useState<{ disabled: boolean, loading: boolean }>({
        loading: false,
        disabled: !!disabled
    });

    const {
        isLoading: isGettingAuditInfo,
        refetch: getClientAuditInfo
    } = useGetClientAuditInfoByGuid(clientGuid, false);


    const watchField = watch();
    const getAbn = (): string | null => {
        const children = items.find((x: IAuditProcedureInfo) => x.reference === "Trustees, Members and Fund Compliance")?.children;

        if (!children) {
            return null;
        }

        const trustyTypeItems = children.find((x: IAuditProcedureInfo) => x.reference === "Trustee Type")?.children;
        if (!trustyTypeItems) {
            return null;
        }

        return trustyTypeItems.find((x: IAuditProcedureInfo) => x.reference === 'Corporate Trustee Name')?.answerText;
    };

    const scheduleSearch = async (verify: boolean) => {
        setOrderSearchState(prev => ({...prev, loading: !prev.loading}));

        if (verify) {
            const valid = await verifyOrder();

            if (!valid) {
                setShowWarningAlert(true);
                setOrderSearchState(prev => ({...prev, loading: !prev.loading}));

                return;
            }
        }

        await makeSearchAsync({
            jobId: job.id,
            name: job.fund.name,
            year: job.year,
            itemId: itemId,
            tableType: tableType,
            abn: getAbn(),
            folio: folio,
            verify: false,
            volume: volume,
            titleReference: titleReference,
            propertyState: propertyState
        }, {
            onSuccess: () => {
                showNotification({
                    name: formatMessage({id: 'orderSearch'}),
                    description: formatMessage({id: 'orderSearch_notification_scheduled'}),
                    type: MessageBarType.info
                });
                setOrderSearchState(prev => ({...prev, loading: !prev.loading}));
            },
            onError: () => setOrderSearchState(prev => ({...prev, loading: !prev.loading}))
        });
    };
    const verifyOrder = async () => {
        const auditInfoResp = await getClientAuditInfo();
        const abn = getAbn();
        return !(!abn && !auditInfoResp.data!.titleSearchConduct || !!abn && !auditInfoResp.data!.asicSearchConduct);
    };

    const handleDismissAlert = async () => {
        setShowWarningAlert(false);

        if (watchField.override) {
            await scheduleSearch(false);
        }
    };

    const orderSearchDialogProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: <FormattedMessage id={'warning'}/>,
        closeButtonAriaLabel: 'Close'
    };

    useEffect(() => {
        setOrderSearchState(prev => ({...prev, disabled: isScheduling || isGettingAuditInfo || !!disabled}));
    }, [isScheduling, isGettingAuditInfo, disabled]);

    return (
        <Stack horizontalAlign='center' horizontal grow>
            <Stack.Item>
                <DefaultButton disabled={orderSearchState.disabled || orderSearchState.loading}
                               onClick={() => scheduleSearch(true)}>
                    {orderSearchState.loading && isGettingAuditInfo && (
                        <FormattedMessage id={'jobPortal_button_verifying'}/>)}
                    {orderSearchState.loading && isScheduling && (
                        <FormattedMessage id={'jobPortal_button_scheduling'}/>)}
                    {!orderSearchState.loading && (
                        <FormattedMessage id={'jobPortal_button_orderSearch'}/>)}
                </DefaultButton>
                <DialogMessage hidden={!showWarningAlert}
                               onClick={handleDismissAlert}
                               showCancel={false}
                               dialogContentProps={orderSearchDialogProps}
                               onDismis={handleDismissAlert}>
                    <Stack grow tokens={{childrenGap: 16}}>
                        <Text>
                            {formatMessage({id: "orderSearch_label_warning"})}
                        </Text>
                        <TenantGuard behavior='hide' types={['black']}>
                            <PortalRoleGuard roles={[PortalRole.CPAdministrator, PortalRole.Administrator]}
                                             behavior='hide'
                                             includeSuperAdministrator>
                                <ControlledCheckbox name={'override'} control={control}
                                                    label={formatMessage({id: 'orderSearch_label_override'})}/>
                            </PortalRoleGuard>
                        </TenantGuard>
                        <TenantGuard behavior='hide' types={['white']}>
                            <PortalRoleGuard roles={[PortalRole.Administrator]}
                                             behavior='hide'
                                             includeSuperAdministrator>
                                <ControlledCheckbox name={'override'} control={control}
                                                    label={formatMessage({id: 'orderSearch_label_override'})}/>
                            </PortalRoleGuard>
                        </TenantGuard>
                    </Stack>
                </DialogMessage>
            </Stack.Item>
        </Stack>
    )
}