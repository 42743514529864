import {TableType} from "../../../../enums/TableType";
import {ColumnInfo} from "../../enums";
import React from "react";
import {commonHeaders, Context} from "../../../../utils";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";

type MutateDataType = {
    jobId: number;
    itemId: number;
    tableType: TableType;
    text: string | null;
    columnInfo: ColumnInfo;
}

function useUpdateItemAnswer(callback?: Function) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const host = context?.config?.answersUrl;
    const {formatMessage} = useIntl();

    const {mutate: update, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.post(`${host}/api/1.0/jobs/${data.jobId}/${data.tableType}/items/${data.itemId}/answer`, data, commonHeaders()),
        {
            onSuccess: () => {
                callback?.();
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }

        }
    );

    return {update, isUpdating};
}

export {useUpdateItemAnswer};