import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    rating: number;
}

function useUpdateQueryRating() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/queryRating/${data.id}`, { rating: data.rating }))

    return {update, updateAsync, isUpdating};
}

export {useUpdateQueryRating};