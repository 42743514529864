import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { PrimaryButton, Stack } from '@fluentui/react';
import { DefaultFormSettings } from 'constants/forms';
import { QueriesEmails } from './QueriesEmails';
import { AuditDispatchEmails } from './AuditDispatchEmails';
import { SetPasswordEmail } from './SetPasswordEmail';
import { SummarisedWorkflowReportEmail } from './SummarisedWorkflowReportEmail';
import { OutstandingQueriesReportEmail } from './OutstandingQueriesReportEmail';
import { DebtorsStatementEmail } from './DebtorsStatementEmail';
import { DetailedAuditWorkflowReportEmail } from './DetailedAuditWorkflowReportEmail';
import { JobLetterEmail } from './JobLetterEmail';
import { EvolvGreyDispatchEmail } from './EvolvGreyDispatchEmail';
import { MonthlyAgedBalancesReportEmail } from './MonthlyAgedBalancesReportEmail';
import {EmailTemplate} from "../../hooks/Email/useEmailTemplateGet";
import { Context } from 'utils';
import { useContext } from 'react';
import { DraftAuditReportEmail } from './DraftAuditReportEmail';
import { ContraventionEmail } from './ContraventionEmail';
import { APIAuthorisationEmail } from './APIAuthorisationEmail';
import React from 'react';


type EmailTemplateProps = {
    emailTemplate: EmailTemplate;
    updateEmailTemplate: (a: EmailTemplate) => void;
};

/**
 * THIS COMPONENT IT"S SIMPLE CONTAINER FOR EMAIL TEMPLATES.
 * WE USE useForm hook here and call all part of our page
 * API Data we get from props
 */

export const EmailTemplates = (props: EmailTemplateProps) => {
    const { control, handleSubmit, formState, watch } = useForm<EmailTemplate>({ ...DefaultFormSettings, defaultValues: props.emailTemplate });
    const { formatMessage } = useIntl();
    const context = useContext(Context);

    const onUpdateEmailTemplate = (form: EmailTemplate) => {
        props.updateEmailTemplate(form);
    };

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) =>
          console.log(value, name, type)
        )
        return () => subscription.unsubscribe()
      }, [watch])

    if(context?.tenantInfo?.IsWhiteLabel){
        return (
            <Stack tokens={{ childrenGap: 16 }}>
                <QueriesEmails control={control} />
    
                <AuditDispatchEmails control={control} />

                <DraftAuditReportEmail control={control} />

                <ContraventionEmail control={control} />
    
                <SetPasswordEmail control={control} />
    
                <SummarisedWorkflowReportEmail control={control} />
    
                <OutstandingQueriesReportEmail control={control} />
    
                <APIAuthorisationEmail control={control} />
    
                <Stack.Item styles={{ root: { marginBottom: '16px', justifyContent: 'flex-end', display: 'flex' } }}>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit(onUpdateEmailTemplate)}
                        text={formatMessage({ id: 'save' })}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <QueriesEmails control={control} />

            <AuditDispatchEmails control={control} />

            <DraftAuditReportEmail control={control} />

            <SetPasswordEmail control={control} />

            <SummarisedWorkflowReportEmail control={control} />

            <OutstandingQueriesReportEmail control={control} />

            <DebtorsStatementEmail control={control} />

            <DetailedAuditWorkflowReportEmail control={control} />

            <JobLetterEmail control={control} />

            <EvolvGreyDispatchEmail control={control} />

            <MonthlyAgedBalancesReportEmail control={control} />

            <Stack.Item styles={{ root: { marginBottom: '16px', justifyContent: 'flex-end', display: 'flex' } }}>
                <PrimaryButton
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(onUpdateEmailTemplate)}
                    text={formatMessage({ id: 'save' })}
                />
            </Stack.Item>
        </Stack>
    );
};
