import {useContext} from "react";
import {Context} from "../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    guid: string;
    auditorDate?: string;
    managerId?: number;
    managerDate?: string;
    partnerId?: number;
    partnerDate?: string;


}

function useUpdateJobDetails() {
    const context = useContext(Context);
    const host = context?.config?.jobUrl;

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${host}/api/1.0/jobs/${data.id}/details`, data));
    
    return{update, updateAsync, isUpdating};
}

export {useUpdateJobDetails};