import { map, Observable, tap, timer } from 'rxjs';
import { ICreateSettingCommand, ISettingInfo, IUpdateSettingCommand } from 'interfaces';
import { cloneDeep, maxBy } from 'lodash';
import { BaseService } from './BaseService';
import { ConfigurationService, configurationService } from './ConfigurationService';

class SettingsService extends BaseService {
    // TODO: remove after implementing real api
    private allSettings: Array<ISettingInfo> = [
        {
            id: 0,
            systemName: 'Web Settings',
            isDeleted: false,
        },
        {
            id: 1,
            systemName: 'Audit ready connections',
            isDeleted: false,
        },
        {
            id: 2,
            systemName: 'General Settings',
            isDeleted: false,
        },
        {
            id: 3,
            systemName: 'SMTP Settings',
            isDeleted: false,
        },
        {
            id: 4,
            systemName: 'Risk Matrix Settings',
            isDeleted: false,
        },
        {
            id: 5,
            systemName: 'Return on Investment Settings',
            isDeleted: false,
        },
        {
            id: 6,
            systemName: 'Materiality  Calculator Settings',
            isDeleted: false,
        },
        {
            id: 7,
            systemName: 'Testing and Methodology Settings',
            isDeleted: false,
        },
        {
            id: 8,
            systemName: 'Job Years Settings',
            isDeleted: false,
        },
        {
            id: 9,
            systemName: 'Email Templates',
            isDeleted: false,
        },
    ];

    constructor(configurationService: ConfigurationService) {
        super('settingsUrl', configurationService);
    }

    public getAllSettings(includeDeleted?: boolean): Observable<Array<ISettingInfo>> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(map(() => cloneDeep(this.allSettings.filter((setting) => (includeDeleted ? true : !setting.isDeleted)))));
        // FUI: example of api
        // return this.httpClient
        //     .get<Array<ISettingInfo>>('/api/Settings/getAll', { params: { includeDeleted } })
        //     .pipe(map((response) => response.data));
    }

    public getSettingById(id: number): Observable<ISettingInfo | undefined> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(map(() => this.allSettings.find((r) => r.id === id)));
        // FUI: example of api
        // return this.httpClient.get<ISettingInfo | undefined>(`/api/Settings/${id}`).pipe(map((response) => response.data));
    }

    public getSettingBySystemName(systemName: string): Observable<ISettingInfo | undefined> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(map(() => this.allSettings.find((r) => r.systemName === systemName)));
        // FUI: example of api
        // return this.httpClient.get<ISettingInfo | undefined>(`/api/Settings/${systemName}`).pipe(map((response) => response.data));
    }

    public createSetting(setting: ICreateSettingCommand): Observable<ISettingInfo> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(
            map(() => ({ ...setting, id: (maxBy(this.allSettings, (s) => s.id)?.id ?? 0) + 1, isDeleted: false })),
            tap((createdSetting: ISettingInfo) => (this.allSettings = [...this.allSettings, createdSetting]))
        );
        // FUI: example of api
        // return this.httpClient.post<ISettingInfo>('/api/Settings/add', setting).pipe(map((response) => response.data));
    }

    public updateSetting(setting: IUpdateSettingCommand): Observable<ISettingInfo> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(
            tap(() => (this.allSettings = this.allSettings.map((s) => (s.systemName === setting.systemName ? { ...s, ...setting } : s)))),
            map(() => this.allSettings.find((s) => s.systemName === setting.systemName) as ISettingInfo)
        );
        // FUI: example of api
        // return this.httpClient.put<ISettingInfo>('/api/Settings/update', setting).pipe(map((response) => response.data));
    }

    public deleteSetting(systemName: string): Observable<any> {
        // TODO: remove after implementing real api
        return timer(1000).pipe(tap(() => (this.allSettings = this.allSettings.filter((s) => s.systemName !== systemName))));
        // FUI: example of api
        // return this.httpClient.delete(`/api/Settings/delete/${systemName}`).pipe(map((response) => response.data));
    }
}

export const settingsService = new SettingsService(configurationService);
