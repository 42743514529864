import { Icon, Link, PrimaryButton, Stack, useTheme } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Card, ControlledTextField } from 'components';
import { DefaultFormSettings } from 'constants/forms';

export const AuthoriseClassSuperAPI = () => {
    const { control, formState } = useForm({
        ...DefaultFormSettings,
        defaultValues: {
            businessCode: '',
        },
    });
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
        <Card>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign='end'>
                    <Stack.Item>
                        <ControlledTextField
                            name='businessCode'
                            control={control}
                            label={formatMessage({ id: 'authoriseClassSuperAPI' })}
                            placeholder={formatMessage({ id: 'businessCode' })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty} text={formatMessage({ id: 'authorise' })} />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                        href='https://app.class.com.au/connect/authorize?response_type=code&client_id=40e89d0a-5e35-47ed-a8ee-864826e510c2&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIkJ1c2luZXNzQ29kZSI6ImV2b2x2c3VwZXIiLCJTb2Z0d2FyZVR5cGUiOiJDbGFzc1N1cGVyIiwiaXNzIjoiaHR0cHM6Ly9kZXZhcGkuZXZvbHZzdXBlci5jb20uYXUiLCJhdWQiOiI0MTRlMTkyN2EzODg0ZjY4YWJjNzlmNzI4MzgzN2ZkMSIsImV4cCI6MTY0NzA0NjkzOCwibmJmIjoxNjQ3MDAzNzM4fQ.3Klz2ckT7veojfl9mxG7_VW4sLfdfiWsOne9O_3CEp8::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=business.fund.list%20fund.read%20offline_access'>
                        evolvsuper
                    </Link>
                    <Icon iconName='CheckMark' styles={{ root: { color: theme.palette.green } }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                        href='https://app.class.com.au/connect/authorize?response_type=code&client_id=40e89d0a-5e35-47ed-a8ee-864826e510c2&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIkJ1c2luZXNzQ29kZSI6ImV2b2x2c3VwZXIiLCJTb2Z0d2FyZVR5cGUiOiJDbGFzc1N1cGVyIiwiaXNzIjoiaHR0cHM6Ly9kZXZhcGkuZXZvbHZzdXBlci5jb20uYXUiLCJhdWQiOiI0MTRlMTkyN2EzODg0ZjY4YWJjNzlmNzI4MzgzN2ZkMSIsImV4cCI6MTY0NzA0NjkzOCwibmJmIjoxNjQ3MDAzNzM4fQ.3Klz2ckT7veojfl9mxG7_VW4sLfdfiWsOne9O_3CEp8::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=business.fund.list%20fund.read%20offline_access'>
                        evolvsuper
                    </Link>
                    <Icon iconName='Cancel' styles={{ root: { color: theme.palette.red } }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center'>
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                        href='https://app.class.com.au/connect/authorize?response_type=code&client_id=40e89d0a-5e35-47ed-a8ee-864826e510c2&redirect_uri=https://devapi.evolvsuper.com.au/oauth/response&state=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJDbGllbnRJZCI6IjAiLCJIb3N0bmFtZSI6ImRldi5ldm9sdnN1cGVyLmNvbS5hdSIsIkJ1c2luZXNzQ29kZSI6ImV2b2x2c3VwZXIiLCJTb2Z0d2FyZVR5cGUiOiJDbGFzc1N1cGVyIiwiaXNzIjoiaHR0cHM6Ly9kZXZhcGkuZXZvbHZzdXBlci5jb20uYXUiLCJhdWQiOiI0MTRlMTkyN2EzODg0ZjY4YWJjNzlmNzI4MzgzN2ZkMSIsImV4cCI6MTY0NzA0NjkzOCwibmJmIjoxNjQ3MDAzNzM4fQ.3Klz2ckT7veojfl9mxG7_VW4sLfdfiWsOne9O_3CEp8::hostname:dev.evolvsuper.com.au&response_mode=form_post&scope=business.fund.list%20fund.read%20offline_access'>
                        evolvsuper
                    </Link>
                </Stack>
            </Stack>
        </Card>
    );
};
