import * as React from "react";
import {FunctionComponent, useMemo} from "react";
import {Card, ControlledDatePicker, ControlledDropdown, ControlledNumberField,} from "../../../../../components";
import {DefaultButton, IDropdownOption, PrimaryButton, Spinner, SpinnerSize, Stack} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";
import {BillingEntityType} from "../../../enums";
import {useCreateCreditNote} from "../hooks";
import {ICreditNote, NewCreditNote} from "../inderfaces";

interface ICreditNoteFormProps {
    onClose: () => void;
    onSubmit?: (item: ICreditNote) => void;
}

interface ICreditNoteFormItem {
    amount: number;
    creationDate: Date,
    billingEntityId: string;
    
}
export const CreditNoteForm: FunctionComponent<ICreditNoteFormProps> = ({ onClose, ...props }: ICreditNoteFormProps) => {
    const { formatMessage } = useIntl();
    const { funds, clientId, clientIdentifier } = useInvoicePortalContext();
    
    const { create, isCreating } = useCreateCreditNote(clientIdentifier);
    
    const { control, handleSubmit, formState } = useForm<ICreditNoteFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            billingEntityId: `${BillingEntityType.Client}_${clientId}`
        }
    });

    const options: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        return [
            { key: `${BillingEntityType.Client}_${clientId}`, text: formatMessage({id: 'client'} )},
            ...funds.map(f => ({ key: `${BillingEntityType.Fund}_${f.id}`, text: f.name }))
        ]
    }, [formatMessage]);
    
    const onSubmit = (item: ICreditNoteFormItem) => {
        const [billingEntityType, billingEntityId] = item.billingEntityId.split('_');
        
        const newCreditNote: NewCreditNote = {
            clientId: clientId,
            amount: item.amount,
            creationDate: item.creationDate,
            billingEntityType: +billingEntityType,
            billingEntityId: +billingEntityId
        }
        
        create({
            ...newCreditNote
        }, {
            onSuccess: (resp: any) => {
                props.onSubmit?.(resp.data);
            }
        })
    }

    const fieldWidth = 'calc(50% - 16px)';
    
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                        <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledNumberField
                                name='amount'
                                control={control}
                                rules={{ required: formatMessage({ id: 'requiredAmount' }) }}
                                label={formatMessage({ id: 'amount' })}
                                disabled={isCreating}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledDatePicker
                                name='creationDate'
                                control={control}
                                label={formatMessage({ id: 'createdOn' })}
                                rules={{ required: formatMessage({ id: 'requiredCreatedOn' }) }}
                                disabled={isCreating}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledDropdown
                                name='billingEntityId'
                                control={control}
                                label={formatMessage({ id: 'fund' })}
                                options={options}
                                rules={{ required: formatMessage({ id: 'requiredFund' }) }}
                                disabled={isCreating}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
            </Stack.Item>
            
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty || isCreating}
                                       onClick={handleSubmit(onSubmit)} >
                            {isCreating 
                                ? <Spinner size={SpinnerSize.small}></Spinner>
                                : formatMessage({id: 'save'})}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isCreating} text={formatMessage({id: 'close'})} onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}