import { ReplaySubject } from 'rxjs';
import { useEffect, useState } from 'react';

export const useIsDestroy: () => ReplaySubject<boolean> = () => {
    const [subject] = useState<ReplaySubject<boolean>>(new ReplaySubject());

    useEffect(
        () => () => {
            subject.next(true);
            subject.complete();
        },
        [subject]
    );

    return subject;
};
