import {IButtonStyles, IconButton, useTheme} from "@fluentui/react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {FunctionComponent, useEffect, useState} from "react";
import {Modal} from "../../../../components";
import {JobOmlForm} from "./JobOmlForm";
import {useIntl} from "react-intl";
import {TableType} from "../../../../enums";
import {IJobOmlItem} from "./interfaces/IJobOmlItem";
import {useSectionContext} from "../Section";
import {JobOmlPreview} from "./JobOmlPreview";
import {useTabContext} from "../../JobPortalPage";

type JobOmlsProps = {
    hasOmls: boolean;
    tableType: TableType;
    itemId: number;
    onAfterSubmit?: (items: IJobOmlItem[]) => void;
    disabled?: boolean;
    showRaisedItems?: boolean;
    showNonWitholdingColumn?: boolean;
}
export const JobOmls: FunctionComponent<JobOmlsProps> = ({
                                                             hasOmls,
                                                             itemId,
                                                             tableType,
                                                             onAfterSubmit,
                                                             disabled,
                                                             showRaisedItems = true,
                                                             showNonWitholdingColumn = true
                                                         }) => {
    const buttonId = useId('callout-button');
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    const [isPreviewVisible, {toggle: toggleIsPreviewVisible}] = useBoolean(false);

    const [isPointerOverPreview, setIsPointerOverPreview] = useState<boolean>(false);

    const {refresh} = useSectionContext();
    const {refreshSection} = useTabContext();

    const styles: IButtonStyles = {
        root: {
            color: hasOmls ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: hasOmls ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color: hasOmls ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    const handlePointerEnter = () => {
        setIsPointerOverPreview(true);
    };

    const handlePointerLeave = () => {
        setIsPointerOverPreview(false);
    };

    useEffect(() => {
        if (!isPointerOverPreview && isPreviewVisible) {
            toggleIsPreviewVisible()
        }
    }, [isPointerOverPreview, toggleIsPreviewVisible])

    const refreshParent = () => {
        refresh?.()
        refreshSection('Initial Audit Queries')
    };

    return (
        <>
            <IconButton id={buttonId}
                        styles={styles}
                        disabled={disabled}
                        onClick={toggleOpenModal}
                        onPointerEnter={toggleIsPreviewVisible}
                        onPointerLeave={() => {
                            setTimeout(() => {
                                if (!isPointerOverPreview) {
                                    toggleIsPreviewVisible()
                                }
                            }, 800);
                        }}
                        iconProps={{iconName: 'AlertSolid'}}
            />
            {isPreviewVisible && hasOmls && (<JobOmlPreview isPointerOver={isPointerOverPreview}
                                                            itemId={itemId}
                                                            target={buttonId}
                                                            tableType={tableType}
                                                            onPointerEnter={handlePointerEnter}
                                                            onPointerLeave={handlePointerLeave}
            />)}
            <Modal resize={'both'} isOpen={isOpenModal} onDismiss={() => {
                toggleOpenModal();
            }} title={formatMessage({id: 'standardPoints'})}>
                {isOpenModal && (<JobOmlForm onAfterSubmit={(items: IJobOmlItem[]) => {
                    onAfterSubmit?.(items);
                    toggleOpenModal();
                    refreshParent();
                }}
                                             itemId={itemId}
                                             tableType={tableType}
                                             showRaisedItems={showRaisedItems}
                                             showNonWitholdingColumn={showNonWitholdingColumn}
                />)}
            </Modal>
        </>
    );
};