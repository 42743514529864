import axios from 'axios';
import {IAppTenantIdResponse} from 'interfaces/IAppTenantIdResponse';
import {useQuery} from 'react-query';
import * as localStorageService from 'services/localStorageService';

/**
 * Getting Data for tenant-id.
 * One of the main hooks, because tenant-id use in every query in headers */

function useGetTenantId(url: string) {

    const {data, isLoading} = useQuery(
        ['getTenantId', url],
        () =>
            axios.get<IAppTenantIdResponse>(`${url}&hostname=${document.domain}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }),
        {
            onSuccess: (res) => {
                 localStorageService.setData('tenantId', res.data.GlobalId);
                 localStorageService.setData('dataBaseName', res.data.DatabaseName);
                 localStorageService.setData("isWhiteLabel", res.data.IsWhiteLabel.toString());
            },
            useErrorBoundary: true,
            enabled: !!url,
        }
    );

    return {data, isLoading};
}

export {useGetTenantId};
