import {CalculatorType} from "./CalculatorType";
import React, {createRef, FunctionComponent, RefObject, useState} from "react";
import {ChangeInMVCalculator} from "./components/ChangeInMV";
import {ContextualMenu, FontSizes, IconButton, IDragOptions, Link} from "@fluentui/react";
import {useIntl} from "react-intl";
import {Loading, ModalWithForm} from "components";
import {TaxRecExemptPensionGrossCalculator} from "./components/TaxRecExemptPensionIncomeGrossMethod";
import {TaxRecExemptPensionIncomeNetMethodCalculator} from "./components/TaxRecExemptPensionIncomeNetMethod";
import {DeferredTaxCalculator} from "./components/DefferedTax";
import {IncomeTaxExpenseCalculator} from "./components/IncomeTaxExpenses";
import {useGetCalculatorData} from "./hooks";
import {useJobContext} from "../../JobPortalLayoutPage";
import {InterestCalculator} from "./components/Interest/InterestCalculator";
import {RentalIncomeYieldCalculator} from "./components/RentalIncomeYield";
import {OverallMaterialityCalculator} from "./components/OverallMateriality";
import {ContributionTaxCalculator} from "./components/ContributionTax";
import {ProfitLossAllocationCalculator} from "./components/ProfitLossAllocation";
import {PensionCalculator} from "./components/Pension";
import {useJobRedirects} from "../templates/shared";
import {useSectionContext} from "../Section";

type CalculatorTemplateProps = {
    type: CalculatorType | null;
    openAs?: 'modal' | 'window'
}

function CalculatorContent(props: { type: CalculatorType | null, childRef: React.RefObject<any> }) {
    return null;
}

export const CalculatorTemplate: FunctionComponent<CalculatorTemplateProps> = ({type, openAs }) => {
    const {formatMessage} = useIntl();
    const {section} = useSectionContext();
    
    const { navigateToJobExternal } = useJobRedirects();
    
    const [isCalcModalOpen, setIsCalcModalOpen] = useState<boolean>(false);
    
    
    const childRef = createRef<any>();
    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        keepInBounds: true,
        menu: ContextualMenu,
        dragHandleSelector: '.modal-title-container',
    };
    
    
    const handleOnClick = () => {
        if (openAs ?? 'window' === 'window') {
            navigateToJobExternal(`calculator/${section.id}/${type}`);
        } else {
            handleOpeningModal()
        }
    }
    
    const handleOpeningModal = () => {
        setIsCalcModalOpen(true);
    };

    const handleDismissingModal = () => {
        setIsCalcModalOpen(false);
    };

    const handleSubmit = () => {
        if (childRef?.current?.onSubmit)
            childRef?.current?.onSubmit();
    };

    return (
        <>
            <IconButton onClick={handleOnClick}
                        iconProps={{iconName: 'Calculator', style: {fontSize: FontSizes.small}}}/>
            <ModalWithForm isOpen={isCalcModalOpen}
                           isBlocking={false}
                           dragOptions={dragOptions}
                           isModeless
                           onDismiss={handleDismissingModal}
                           width={'auto'}
                           minWidth={350}
                           onSubmit={handleSubmit}
                           title={formatMessage({id: `calculator_title_${type}`})}>
                {isCalcModalOpen && (<CalculatorContent childRef={childRef} type={type}/>)}
            </ModalWithForm>
        </>
    )
}

