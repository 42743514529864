import {NeutralColors, Stack} from "@fluentui/react";
import {useJobContext} from "../JobPortalLayoutPage";
import {TableType} from "../../../enums";
import {useUpdateItemAnswer} from "../hooks/answers";
import {ColumnInfo} from "../enums";
import {useForm} from "react-hook-form";
import { useCallback, useEffect, useState } from 'react';
import {ControlledCheckbox} from "../../../components";
import { useWorkContext } from "../../../providers";
import { useTabContext } from "../JobPortalPage";

type PermanentCheckBoxProps = {
    checked?: boolean;
    itemId: number;
    tableType: TableType;
    onUpdate?: (newValue: boolean) => void;
    disabled?: boolean;
}

export const PermanentCheckBox = (props: PermanentCheckBoxProps) => {
    const { jobId} = useJobContext();
    const { isTabEnabled} = useTabContext();
    const {control, watch} = useForm<{ value: boolean }>({
        mode: 'onChange',
        defaultValues: {
            value: props.checked,
        },
    });
    const {update, isUpdating} = useUpdateItemAnswer();

    const onSubmit = useCallback((data: any) => {
        if (props.onUpdate) {
            props.onUpdate(data.value);
        } else
            update({
                jobId: jobId,
                tableType: props.tableType,
                itemId: props.itemId,
                text: String(data.value),
                columnInfo: ColumnInfo.Checkbox
            });
    }, []);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        //console.debug("[ANSWER::PERMANENT_CHECK::DISABLE]", isTabEnabled, !!props.disabled);
        setDisabled(!isTabEnabled || !!props.disabled)
    }, [props.disabled, isTabEnabled]);

    return (
        <ControlledCheckbox name={'value'} control={control} disabled={disabled}
                            styles={{checkbox: {borderColor: NeutralColors.gray60}}}
                            />
    );
}