import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useCallback, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { DataTable, SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { getGrouppedReports } from 'utils';
import { ReportTable } from "../Table/ReportTable";
import { GeneratedAnswer } from "../../../GeneratedAnswer";
import { AnswerControlType, TableType } from "../../../../../../enums";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import * as React from "react";
import { isBankRow } from "../ClassSuper";
import { isAgreedAnswer, isAutoAnswer } from "../../answers";
import { ReportTemplateTypeProps } from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { ReportMoneyValueCell } from "../shared/cells";



export const SuperMateInvestmentMovement = ({ items, isFetching }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            let isTotal = i.valueType === 'Total';

            if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                if (!isTotal && i.purchase) {
                    verifiedCoverage += i.purchase;
                }
            }
            if (!isTotal && i.purchase) {
                incomeTotal += i.purchase;
            }

        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'accountNumber',
                name: formatMessage({ id: 'accountNumber' }),
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'accountNumber',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                    isHeadingRow={item?.isHeadingRow || item?.isBold}
                    format={item?.format} />,
            },
            {
                key: 'name',
                name: formatMessage({ id: 'accountDescription' }),
                minWidth: 240,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                    isHeadingRow={item?.isHeadingRow || item?.isBold}
                    format={item?.format} />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== 'Heading' && item?.valueType !== 'Total' && item?.valueType !== 'GrandTotal' &&
                            <GeneratedAnswer itemId={item?.id}
                                tableType={item?.reportType}
                                value={item?.answerText}
                                auto={item?.isAnswerDisabled}
                                disabled={item?.isAnswerDisabled}
                                onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                answerControlType={item?.answerControlType} />}
                    </Stack>
                )
            },
            {
                key: 'openingBalance',
                name: formatMessage({ id: 'openingBalance' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'openingBalance',
                onRender: (item, _, column) => 
                    <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'purchase',
                name: formatMessage({ id: 'purchases' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'purchase',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'sale',
                name: formatMessage({ id: 'sales' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'sale',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'adjustment',
                name: formatMessage({ id: 'adjustments' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'adjustment',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'closingMarketValue',
                name: formatMessage({ id: 'closingMarketValue' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'closingMarketValue',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'realisedMovement',
                name: formatMessage({ id: 'realisedMovement' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'realisedMovement',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'unrealisedMovement',
                name: formatMessage({ id: 'unrealisedMovement' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'unrealisedMovement',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} allowNull={true}/>
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );
    
    const mapItems = useCallback((initialItems: IReport[]) => {
        return (initialItems ?? []).reduce((acc: IReport[], i) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
            }
            acc.push(i)
            return acc;
        }, []);
    }, []);

    const [tableItems, setTableItems] = useState<IReport[]>(mapItems(items));
    
    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }
                
                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }
        
        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(mapItems(tableItems));
        }
    }

    return <ReportTable
        initialColumns={columns}
        openAll={true}
        groupProps={{
            mergedGroupHeader: { propertyKey: 'name' },
            summary: {
                show: true,
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    const summary = group.summary;
                    return <>
                        {
                            <Stack horizontal tokens={{ childrenGap: 16 }}>
                                <Stack.Item>
                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                </Stack.Item>
                            </Stack>
                        }
                    </>
                }
            }
        }}
        items={tableItems}
        isFetching={isFetching} />;
};
