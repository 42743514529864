import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import { IRiskProcedure } from "./interfaces";
import { FontWeights, IconButton, Stack, Text, useTheme } from "@fluentui/react";
import { SanitizedText } from "../../../../../../components";
import { ColumnInfo, Formatting } from "../../../../enums";
import { useIntl } from "react-intl";
import { getFlatProcedures, getOpenOnStartupIds } from "../../../../../../utils";
import { AnswerControlType, SoftwareType } from "../../../../../../enums";
import { WorkDoneSwitchAnswer } from "../../answers";
import { ProceduresDataTable } from "../../../ProceduresDataTable";
import { DefaultFormSettings } from "../../../../../../constants";
import { useForm } from "react-hook-form";
import { useJobContext } from "../../../../JobPortalLayoutPage";
import { useTenantInfo } from "../../../../../../providers";
import {
    useChangeSuggestedTestingProcess,
    useGetDetailedSettings,
    useGetFundRiskRatingDate,
    useGetPerfomanceMaterialitySettings
} from "./hooks";
import { useProcedureContext } from "../../../ProceduresContent";
import { useUpdateItemAnswer } from "../../../../hooks/answers";
import { useSectionContext } from "../../../Section";
import { useBoolean } from "@fluentui/react-hooks";

export const references = {
    relatedUnitTrusts: 'Related Unit Trusts/Companies',
    collectablesAndPersonalUseAssets: 'Collectables and Personal Use Assets',
    loans: 'Loans',
    derivatives: 'Derivatives',
    foreignInvestments: 'Foreign Investments',
    complianceIssuesPriorYearContraventions: 'Compliance Issues (other) & Prior Year Contraventions',
    netAssetPosition8Millions: 'Is the net asset position greater than $8 million',
    limitedRecourseBorrowingArrangements: 'Limited Recourse Borrowing Arrangements',
    realEstateProperty: 'Real Estate Property',
    unlistedInvestments: 'Unlisted Investments',
    creditorsPayableLongTermLiabilities: 'Creditors / Payables / Long-term liabilities',
    netAssetPosition4Millions: 'Is the net asset position greater than $4 million',
    areAllOfInvestmentIdentifiedForCRM: 'Are all of the investments identified above held in a Wrap account? <small>(No non-custodial assets are held and an audit report for the wrap product is obtained for review)</small>',
    areAllOfInvestmentIdentifiedForWL: 'Are all of the investments identified above held in a Wrap account? <small>(No non-custodial assets are held and an audit report for the wrap product is obtained for review)</small>',
    clientRiskRating: 'Client Risk Rating',
    inherentFundRiskRating: 'Inherent Fund Risk Rating',
    overallAuditJobRiskRating: 'Overall audit job risk rating',
    performanceMateriality: 'Performance materiality (financial misstatement)'

}


export const RiskCalculator: FunctionComponent<{ items: IRiskProcedure[] }> = ({ items }) => {
    const { formatMessage } = useIntl();
    const { job, suggestedTestingInfo } = useJobContext();
    const { detailedSettings } = useGetDetailedSettings(job.id);
    const { changeSuggestedTestingProcess } = useChangeSuggestedTestingProcess(job.id);
    const { perfomanceMaterialitySettings } = useGetPerfomanceMaterialitySettings(job.id);
    const { fundRiskRatingDate } = useGetFundRiskRatingDate();
    const { isBlackLabel } = useTenantInfo();
    const [expandedItemIds, setExpandedItemIds] = useState<number[]>(getOpenOnStartupIds(items));
    const [disabled, setDisabled] = useState<boolean>(suggestedTestingInfo?.isApproved ?? false);
    
    const [isCPSoftwareTypeEqualOther] = useBoolean(job.softwareType === SoftwareType.Nothing || job.clientPortalSoftwareType === SoftwareType.Nothing);
    const [isReceivedAfterFeature] = useBoolean(!fundRiskRatingDate?.data.value || new Date(fundRiskRatingDate?.data.value) <= new Date(job.importedDate));
    
    const rows = useMemo<IRiskProcedure[]>(() => getFlatProcedures(items.filter(x => x.reference !== 'Notes'), expandedItemIds), [items, expandedItemIds]);
    const { answers, updateAnswers } = useProcedureContext();
    const { updateSectionAnswer } = useSectionContext();
    const { update } = useUpdateItemAnswer();
    const { setValue, watch } = useForm<boolean[]>({
        ...DefaultFormSettings,
        defaultValues: rows.map(x => x.answerTextIsChanged)
    });

    const [touchedItems, setTouchedItems] = useState<number[]>([]);
    
    const watchingFields = watch();
    const handleOnAnswer = (id: number, index: number) => {
        setValue(`${index}`, true);
        setTouchedItems(prev => [...prev, id]);
    };
    
    const { formatNumber } = useIntl();

    const getAnswerStyles = (item: IRiskProcedure) => {
        return {
            textTransform: (item.format === Formatting.BoldItalic) ? 'uppercase' : 'inherit',
            fontWeight: (Formatting[item.format!].toString().includes('Bold') ? FontWeights.bold : FontWeights.regular),
            wordWrap: "break-word"
        }
    };
    
    const isOneOfProcedureEmpty = useMemo(() => {
        return rows.some(x => x.answerControlType === AnswerControlType.WorkDoneSwitch && answers && answers.some(y => !y.answerText && y.id == x.id));
    }, [rows, answers]);

    const isOneAssetTransactionsIsHigh = useMemo(() => {
        return rows.some(x => x.answerControlType === AnswerControlType.WorkDoneSwitch && answers && answers.some(y => y.answerText === 'Yes' && y.id == x.id) && detailedSettings?.some(d => d.reference.toLowerCase() === x.reference.toLowerCase()  && d.optionValue === 3));
    }, [rows, answers]);

    const isOneAssetTransactionsIsMedium = useMemo(() => {
        return rows.some(x => x.answerControlType === AnswerControlType.WorkDoneSwitch && answers && answers.some(y => y.answerText === 'Yes' && y.id == x.id) && detailedSettings?.some(d => d.reference.toLowerCase() === x.reference.toLowerCase() && d.optionValue === 2));
    }, [rows, answers]);

    const inherentFundRiskRating = useMemo(() => {

        const itemRUT = rows.find(x => x.reference == references.relatedUnitTrusts);
        const itemRUTAnswer = answers?.find(x => x.id == itemRUT?.id)?.answerText;
        const itemCPUA = rows.find(x => x.reference == references.collectablesAndPersonalUseAssets);
        const itemCPUAAnswer = answers?.find(x => x.id == itemCPUA?.id)?.answerText;
        const itemL = rows.find(x => x.reference == references.loans);
        const itemLAnswer = answers?.find(x => x.id == itemL?.id)?.answerText;
        const itemD = rows.find(x => x.reference == references.derivatives);
        const itemDAnswer = answers?.find(x => x.id == itemD?.id)?.answerText;
        const itemFI = rows.find(x => x.reference == references.foreignInvestments);
        const itemFIAnswer = answers?.find(x => x.id == itemFI?.id)?.answerText;
        const itemCIPYC = rows.find(x => x.reference == references.complianceIssuesPriorYearContraventions);
        const itemCIPYCAnswer = answers?.find(x => x.id == itemCIPYC?.id)?.answerText;
        const item8 = rows.find(x => x.reference == references.netAssetPosition8Millions);
        const item8Answer = answers?.find(x => x.id == item8?.id)?.answerText;
        const itemLRBA = rows.find(x => x.reference == references.limitedRecourseBorrowingArrangements);
        const itemLRBAAnswer = answers?.find(x => x.id == itemLRBA?.id)?.answerText;
        const itemP = rows.find(x => x.reference == references.realEstateProperty);
        const itemPAnswer = answers?.find(x => x.id == itemP?.id)?.answerText;
        const itemUI = rows.find(x => x.reference == references.unlistedInvestments);
        const itemUIAnswer = answers?.find(x => x.id == itemUI?.id)?.answerText;
        const itemCPL = rows.find(x => x.reference == references.creditorsPayableLongTermLiabilities);
        const itemCPLAnswer = answers?.find(x => x.id == itemCPL?.id)?.answerText;
        const item4 = rows.find(x => x.reference == references.netAssetPosition4Millions);
        const item4Answer = answers?.find(x => x.id == item4?.id)?.answerText;

        if (!(itemRUT && itemCPUA && itemL && itemD && itemFI && itemCIPYC && item8 && itemLRBA && itemP && itemUI && itemCPL && item4 && !isOneOfProcedureEmpty)) {
            return;
        }

        if (isReceivedAfterFeature || isCPSoftwareTypeEqualOther) {
            if (item4Answer === 'Yes'
                && item8Answer === 'No'
                && !isOneAssetTransactionsIsHigh) {
                return 'Medium';
            } else if (item8Answer === 'Yes') {
                return 'High';
            } else if (isOneAssetTransactionsIsHigh) {
                return 'High';
            } else if (isOneAssetTransactionsIsMedium) {
                return 'Medium';
            } else {
                return 'Low';
            }
        } else {
            if (itemRUTAnswer === 'No' && itemCPUAAnswer === 'No' && itemLAnswer === 'No' &&
                itemDAnswer === 'No' && itemFIAnswer === 'No' && itemCIPYCAnswer === 'No' &&
                item8Answer === 'No' && itemLRBAAnswer === 'No' && itemPAnswer === 'No' &&
                itemUIAnswer === 'No' && itemCPLAnswer === 'No' && item4Answer === 'No') {
                return 'Low';
            }
            if (itemRUTAnswer === 'Yes' || itemCPUAAnswer === 'Yes' || itemLAnswer === 'Yes' ||
                itemDAnswer === 'Yes' || itemFIAnswer === 'Yes' || itemCIPYCAnswer === 'Yes' ||
                item8Answer === 'Yes' || (itemLRBAAnswer === 'Yes')) {
                return 'High';
            }
            if (((itemRUTAnswer === 'No' && itemCPUAAnswer === 'No' && itemLAnswer === 'No' &&
                itemDAnswer === 'No' && itemFIAnswer === 'No' && itemCIPYCAnswer === 'No' &&
                item8Answer === 'No' &&
                (itemLRBAAnswer === 'No' || (itemLRBAAnswer === 'Yes'))) ||
                itemPAnswer === 'Yes' ||
                itemUIAnswer === 'Yes' ||
                itemCPLAnswer === 'Yes' ||
                item4Answer === 'Yes')) {
                return 'Medium';
            }
        }

    }, [rows, isOneAssetTransactionsIsHigh, isOneAssetTransactionsIsMedium, isOneOfProcedureEmpty, answers, isReceivedAfterFeature, isCPSoftwareTypeEqualOther]);

    const overallAuditJobRiskRating = useMemo(() => {
        let reference = references.areAllOfInvestmentIdentifiedForCRM;

        if (!isBlackLabel) {
            reference = references.areAllOfInvestmentIdentifiedForWL;
        }

        const wrapAcc = rows.find(x => x.reference === reference);
        const wrapAnswer = answers?.find(x => x.id == wrapAcc?.id)?.answerText;

        if (wrapAcc && wrapAnswer == 'Yes') {
            return 'Wrap';
        }

        const itemCRR = rows.find(x => x.reference === references.clientRiskRating);
        const itemCRRAnswer = answers?.find(x => x.id == itemCRR?.id)?.answerText;
        const itemIFRR = rows.find(x => x.reference === references.inherentFundRiskRating);
        const itemIFRRAnswer = answers?.find(x => x.id == itemIFRR?.id)?.answerText;

        if (itemCRR && itemIFRR) {
            if (itemIFRRAnswer == 'Low' && itemCRRAnswer == 'Low') {
                updateSectionAnswer(true);
                return 'Low';
            }
            if (itemIFRRAnswer == 'Low' && itemCRRAnswer == 'Medium') {
                updateSectionAnswer(true);
                return 'Medium 1';
            }
            if (itemIFRRAnswer == 'Low' && itemCRRAnswer == 'High') {
                updateSectionAnswer(false);
                return 'Medium 2';
            }
            if (itemIFRRAnswer == 'Medium' && itemCRRAnswer == 'Low') {
                updateSectionAnswer(true);
                return 'Medium 1';
            }
            if (itemIFRRAnswer == 'Medium' && itemCRRAnswer == 'Medium') {
                updateSectionAnswer(false);
                return 'Medium 2';
            }
            if (itemIFRRAnswer == 'Medium' && itemCRRAnswer == 'High') {
                updateSectionAnswer(false);
                return 'High';
            }
            if (itemIFRRAnswer == 'High' && itemCRRAnswer == 'Low') {
                updateSectionAnswer(false);
                return 'Medium 2';
            }
            if (itemIFRRAnswer == 'High' && itemCRRAnswer == 'Medium') {
                updateSectionAnswer(false);
                return 'High';
            }
            if (itemIFRRAnswer == 'High' && itemCRRAnswer == 'High') {
                updateSectionAnswer(false);
                return 'High';
            }
        }
        
        updateSectionAnswer(null);
        return '';
    }, [answers]);

    const performanceMateriality = useMemo(() => {
        const itemOAJRR = rows.find(x => x.reference === references.overallAuditJobRiskRating);

        if (!itemOAJRR) {
            return '';
        }

        const itemOAJRRAnswer = answers?.find(x => x.id == itemOAJRR?.id)?.answerText;

        const itemPM = rows.find(x => x.reference === references.performanceMateriality);
        if (itemPM) {

            // From Performance materiality calc
            // Materiality level - Financial misstatement > 10.00 %
            const valueMat = (itemPM.answerText2) ? parseFloat(itemPM.answerText2) : 0;
            if (isReceivedAfterFeature || isCPSoftwareTypeEqualOther) {

                const settings = perfomanceMaterialitySettings?.data;
                if (settings) {
                    for (var i = 0; i < settings.length; i++) {
                        if (itemOAJRRAnswer && settings[i].overallAuditJobRiskRating.toLowerCase().includes(itemOAJRRAnswer.toLowerCase())) {
                            if (settings[i].applyOverallMateriality) {
                                return 'Apply overall materiality';
                            } else {
                                return formatNumber(valueMat * settings[i].percentageApplied, { style: 'currency', currency: 'USD' });
                            }
                        }
                    }
                }
            } else {
                if (itemOAJRRAnswer == 'Low' || itemOAJRRAnswer == 'Medium 1') {
                    return 'Apply overall materiality';
                }

                if (itemOAJRRAnswer == 'Medium 2') {
                    return formatNumber(valueMat * 0.75, { style: 'currency', currency: 'USD' });
                }
                if (itemOAJRRAnswer == 'High') {
                    return formatNumber(valueMat * 0.5, { style: 'currency', currency: 'USD' });
                }
            }
        }

        return '';
    }, [answers, perfomanceMaterialitySettings, isReceivedAfterFeature, isCPSoftwareTypeEqualOther]);

    const getAnswer = useCallback((item: IRiskProcedure) => {
        if (!touchedItems.length) {
            return item.answerText;
        }
        
        let answer = undefined;
        let callback = () => {}

        switch (item.reference) {
            case references.inherentFundRiskRating:
                answer = inherentFundRiskRating;
                break;
            case references.overallAuditJobRiskRating:
                answer = overallAuditJobRiskRating;
                callback = changeSuggestedTestingProcess;
                break;
            case references.performanceMateriality:
                answer = performanceMateriality;
                break;
            default:
                return item.answerText;
        }

        const itemAnswer = answers?.find(x => x.id == item.id)?.answerText;
        
        if (answer != itemAnswer && answers && touchedItems.length) {
            const index = answers.findIndex((value) => value.id == item.id);
            if (index != -1) {
                const updatedAnswers = [...answers];
                updatedAnswers[index].answerText = answer;
                
                setTimeout(() => {
                    updateAnswers?.(updatedAnswers);
                }, 100)

                console.debug("[GET_ANSWER]", item, touchedItems);
                callback();

                update({
                    jobId: job.id,
                    tableType: item.tableType,
                    itemId: item.id,
                    text: answer ?? null,
                    columnInfo: ColumnInfo.Text
                }, {
                    onSuccess: () => {
                        
                    }
                })
            }
        }

        return answer;

    }, [inherentFundRiskRating, overallAuditJobRiskRating, performanceMateriality, touchedItems]);

    const columns = useMemo(() => {
        return [
            {
                key: 'title',
                name: '',
                fieldName: 'title',
                onRender: (items: IRiskProcedure) => (
                    <>
                        {(items.children?.length ?? 0) > 0 && items.showExpander && (
                            <RiskHeader onExpand={onExpand} onCollapse={onCollapse} item={items} />
                        )}
                        {items.children === null && (<RiskChild item={items} />)}
                    </>

                ),
                minWidth: 150,
            },
            {
                key: 'auditingSTD',
                name: formatMessage({ id: 'auditingSTD' }),
                fieldName: 'auditingSTD',
                onRender: (items: IRiskProcedure) => <SanitizedText data={items?.auditingStnd || ''} />,
                minWidth: 100,
                maxWidth: 100
            },
            {
                key: 'isTheItemRecordedInSMSF',
                name: formatMessage({ id: 'isTheItemRecordedInSMSF' }),
                fieldName: 'isTheItemRecordedInSMSF',
                onRender: (item: IRiskProcedure, index?: number) => (
                    <Stack grow horizontalAlign={"center"}>
                        {item.answerControlType === AnswerControlType.WorkDoneSwitch && (
                            <WorkDoneSwitchAnswer columnInfo={ColumnInfo.Text}
                                iconTypes={'character'}
                                auto={!watchingFields[index!]}
                                tableType={item.tableType}
                                value={item.answerText}
                                disabled={disabled || item.isAnswerDisabled}
                                onUpdate={() => handleOnAnswer(item.id, index!)} 
                                reverse
                                itemId={item.id}
                                ignoreSectionAnswerUpdating={true} />)}
                        {item.answerControlType === AnswerControlType.Label && (<SanitizedText data={getAnswer(item) ?? ''}
                            styles={{
                                root: {
                                    ...getAnswerStyles(item),
                                    textAlign: 'center',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellypsis'
                                }
                            }} />)}
                    </Stack>
                ),
                minWidth: 100,
                maxWidth: 100
            },
        ];
    }, [rows, formatMessage, watchingFields]);

    const onExpand = (id: number) => setExpandedItemIds(prev => [...prev, id]);
    const onCollapse = (id: number) => setExpandedItemIds(prev => prev.filter(expandedItemId => expandedItemId !== id));

    useEffect(() => {
        setDisabled(suggestedTestingInfo?.isApproved ?? false)
    }, [suggestedTestingInfo])

    return (
        <ProceduresDataTable
            items={rows}
            disableDragDrop={true}
            contextMenuOptions={{ disable: true }}
            header={{ horizontalAlign: 'center', rowHeight: 80 }}
            columns={columns}
        />
    );
};

const RiskHeader: FunctionComponent<{ item: IRiskProcedure, onCollapse: (id: number) => void, onExpand: (id: number) => void }> = ({
    item,
    onCollapse,
    onExpand
}) => {
    const theme = useTheme();
    return (
        <Stack horizontal verticalAlign={'center'} horizontalAlign={"space-between"}>
            <Stack.Item styles={{ root: { width: 32 } }}>
                {(item.children?.length ?? 0) > 0 && item?.showExpander &&
                    <IconButton iconProps={{ iconName: item.isOpen ? 'ChevronUp' : 'ChevronDown' }}
                        styles={{
                            icon: {
                                fontSize: "x-small",
                                color: theme.palette.themePrimary
                            }
                        }}
                        onClick={() => item.isOpen ? onCollapse(item.id) : onExpand(item.id)} />}
            </Stack.Item>
            <Stack>
                <SanitizedText format={item.format === Formatting.Bold ? "Bold" : "Normal"}
                    data={item?.title} />
                <Text variant={"small"}>{item.number}</Text>
            </Stack>
        </Stack>
    );
}

const RiskChild: FunctionComponent<{ item: IRiskProcedure }> = ({ item }) => {

    const textStyles: React.CSSProperties = {
        textTransform: (item.reference === 'Notes' || item.format === Formatting.BoldItalic) ? 'uppercase' : 'inherit',
    }

    return (
        <Stack tokens={{ childrenGap: 16 }} grow>
            {!!item.number && (
                <SanitizedText
                    styles={{ root: { fontWeight: FontWeights.bold, fontStyle: 'italic' } }}
                    data={item.number || ''} />
            )}
            <SanitizedText style={textStyles}
                format={Formatting[item.format!].toString().includes('Bold') ? 'Bold' : 'Normal'}
                data={item.title} />

        </Stack>);
};
