import { FunctionComponent } from 'react';
import { Stack, useTheme } from '@fluentui/react';
import { Logo } from 'components';
import { ImportReportContent } from './ImportReportContent';

interface IImportReportProps {
    guid?: number | string;
}

export const ImportReport: FunctionComponent<IImportReportProps> = ({ guid }) => {
    const theme = useTheme();
    return (
        <Stack horizontal tokens={{ childrenGap: 16 }}>
            <Stack verticalAlign='center'>
                <Logo />
            </Stack>
            <Stack styles={{ root: { width: 1, backgroundColor: theme.palette.neutralPrimary } }} />
            <ImportReportContent guid={guid} />
        </Stack>
    );
};
