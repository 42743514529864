import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import {ReviewType} from "../../enums";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    reviewedDate?: Date | null;
    pointRaised?: Date | null;
    reviewType: ReviewType;
}

function useUpdateReviewDate() {
    const context = useContext(Context);

    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobId}/reviewDates`, data));

    return {update, isUpdating}
}

export {useUpdateReviewDate};