import {
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IClientPortalWidgetDate,
    IDashboardConfig,
    WidgetsEnum
} from 'pages/shared/Dashboard';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {AdministrationDashboardContainer} from "../AdministrationDashboardContainer";

export const BlackClientPortalDashboard = () => {
    const {formatMessage} = useIntl();

    const getAdministrationDashboardConfig = useCallback(
        (props: IClientPortalWidgetDate): IDashboardConfig => {
            return {
                id: 'administration',
                rows: [
                    [
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'auditsNotSubmitted',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_AuditsNotSubmitted}],
                            props: {
                                title: formatMessage({ id: 'auditsNotSubmitted' }, { jobYear: props.filterYear }),
                                data: props.auditsNotSubmitted,
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'auditReleased',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_AuditReportReleased}],
                            props: {
                                title: formatMessage({ id: 'auditReleased' }, { jobYear: props.filterYear }),
                                data: props.auditReportReleased,
                            },
                        },

                    ],
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'preAuditIssues',
                            tables: [{ tableType: DashboardTableTypeEnum.CpBlack_PreAuditIssues }],
                            props: {
                                number: props.preAuditIssues,
                                title: formatMessage({ id: 'preAuditIssues' }),
                                variant: "tertiary"
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsInProgress',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_AuditsInProgress}],
                            props: {
                                number: props.auditsInProgressCount,
                                title: formatMessage({id: 'auditsInProgress'}),
                                variant: "default"
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'preArQueriesAwaitingResponse',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_PreArQueriesWaitingForResponse}],
                            props: {
                                number: props.preArQueriesAwaitingResponseCount,
                                title: formatMessage({id: 'preArQueriesAwaitingResponse'}),
                                variant: "tertiary"
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'unsignedArReleased',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_UnsignedArReleased}],
                            props: {
                                number: props.unsignedArReleasedCount,
                                title: formatMessage({id: 'unsignedArReleased'}),
                                variant: "default"
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'preArQueriesWaitingForReview',
                            tables: [{tableType: DashboardTableTypeEnum.CpBlack_PreArQueriesWaitingForReview}],
                            props: {
                                number: props.preArQueriesWaitingForReviewCount,
                                title: formatMessage({id: 'preArQueriesWaitingForReview'}),
                                variant: "tertiary"
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '7',
                            props: {
                                tableType: DashboardTableTypeEnum.CpBlack_PostArQueriesWaitingForResponse,
                            }
                        }
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.CpBlack_PostArQueriesWaitingForReview,
                            }
                        }
                    ]

                ],
            };
        },
        [formatMessage]
    );

    return <AdministrationDashboardContainer dashboardTab={DashboardTabsEnum.ClientPortalBlack}
                                             getDashboardConfig={getAdministrationDashboardConfig as GetDashboardConfig}/>;
};
