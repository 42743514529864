import {Pagination} from 'components';
import {useContext, useEffect, useState} from 'react';
import {Stack} from '@fluentui/react';
import {useBoolean} from "@fluentui/react-hooks";
import {ExportType, useExportData, useGetJobByGuid} from "hooks";
import {useIntl} from "react-intl";
import {ITimesheetPaginationFilter, useGetTimesheetByFilter} from "./hooks";
import {DataTableFilter} from "../shared";
import {TimesheetDataTable, TimesheetModal} from "./components";
import {Context} from "../../../utils";

interface ITimesheetWorkflowPageProps {
    jobId?: number;
}

export const TimesheetWorkflowPage = (props: ITimesheetWorkflowPageProps) => {
    const context = useContext(Context);

    const {formatMessage} = useIntl();
    const [showAddModal, {toggle: toggleAddModal}] = useBoolean(false);
    const [filters, setFilters] = useState<ITimesheetPaginationFilter>({
        pageSize: 10,
        page: 1,
        orderBy: 'Employee.FirstName',
        orderDirection: 'asc',
        jobId: props.jobId
    });
    const [pagination, setPagination] = useState<{ itemsCount: number; total: number }>();
    const {timesheet: timesheetRes, isTimesheetLoading} = useGetTimesheetByFilter(filters);
    const {exportData} = useExportData(`${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets`, filters);
    const {dataJobs: currentJob, isJobLoading} = useGetJobByGuid({guid: props.jobId?.toString() || ''});
    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters(prev => ({...prev, pageSize: pageSize, page: page}));
    };


    useEffect(() => {
        if (timesheetRes?.data) {
            setPagination({
                itemsCount: timesheetRes?.data.shownItemsCount,
                total: timesheetRes?.data.totalItemsCount,
            });
        }
    }, [timesheetRes?.data, setPagination, filters]);

    const onAddClick = () => {
        toggleAddModal();
    };


    return (
        <>
            <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
                <DataTableFilter onAddClick={props.jobId ? onAddClick : undefined} hideSearchField={true}
                                 hideStatusFilter={true}
                                 export={{
                                     onPdfClick: () => exportData(ExportType.Pdf),
                                     onExcelClick: () => exportData(ExportType.Excel)
                                 }}
                />
                <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                    <TimesheetDataTable timesheet={timesheetRes?.data?.items || []}
                                        isLoading={isTimesheetLoading}
                                        setSort={(value) => setFilters(prev => ({
                                            ...prev,
                                            orderBy: value.orderBy,
                                            orderDirection: value.orderDirection
                                        }))}/>
                </Stack.Item>
                <Stack.Item>
                    <Pagination
                        itemsCount={pagination?.itemsCount}
                        total={pagination?.total}
                        page={filters.page}
                        pageSize={filters.pageSize}
                        onChange={onPaginationChange}
                    />
                </Stack.Item>
            </Stack>
            {props.jobId && (<TimesheetModal type={"Create"} job={currentJob?.data} isOpen={showAddModal}
                                             title={formatMessage({id: 'createTimesheet'})}
                                             onDismiss={toggleAddModal}/>)}
        </>
    );
};
