import { FunctionComponent } from 'react';
import { useProcedureContext } from '../../../ProceduresContent';
import { Stack } from '@fluentui/react';
import { IIChecklist } from './components/IIChecklist';
import { RequiredDocuments } from './components/RequiredDocuments';
import { AuditAcceptanceIssues } from './components/AuditAcceptanceIssues';
import { AcceptanceControls } from './components/Acceptance';

type AuditAcceptanceProcedureTemplateProps = {};

export const AuditAcceptanceProcedureTemplate: FunctionComponent<AuditAcceptanceProcedureTemplateProps> = ({
    ...props
}: AuditAcceptanceProcedureTemplateProps) => {
    const { data } = useProcedureContext();

    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <IIChecklist items={data?.iiChecklistItems ?? []} />
                </Stack.Item>
                <Stack.Item>
                    <RequiredDocuments items={data?.iiChecklistItems ?? []} />
                </Stack.Item>
                <Stack.Item>
                    <AuditAcceptanceIssues items={data?.issueItems ?? []} />
                </Stack.Item>
                <Stack.Item>
                    <AcceptanceControls />
                </Stack.Item>
            </Stack>
        </>
    );
};
