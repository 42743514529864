import { commonHeaders, Context } from "../../../../../../utils";
import { useTenantInfo } from "../../../../../../providers";
import { useContext } from 'react';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { ExternalConnection, ExternalConnectionSettings } from "../models";


function useGetExternalConnectionsSettings() {
	const context = useContext(Context);
	const {isWorkpapers} = useTenantInfo();
	
	const {data: connectionSettings, isLoading} = useQuery(
		['getExternalConnectionSettings'],
		() => axios.get<ExternalConnectionSettings>(`${context?.config?.settingsUrl}/api/1.0/Settings/externalConnections`, commonHeaders())
			.then((res: AxiosResponse<ExternalConnectionSettings>) => res.data),
		{
			enabled: !!context?.config?.settingsUrl && !!context.tenantId && !isWorkpapers,
		},
	);

	return {connectionSettings, isLoading};
}

export {useGetExternalConnectionsSettings};
