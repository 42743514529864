import { FunctionComponent, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Checkbox,
    CommandBarButton,
    ContextualMenuItemType,
    DefaultButton,
    DirectionalHint,
    FontIcon,
    IconButton,
    IContextualMenuProps,
    INavLink,
    INavLinkGroup,
    mergeStyles,
    mergeStyleSets,
    Nav,
    Pivot,
    PivotItem,
    Stack,
    Text,
    TextField,
    useTheme,
} from '@fluentui/react';
import { Logo } from 'components';
import { LayoutBreadcrumb } from 'components/LayoutBreadcrumb';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { ActiveIconColorStyles } from 'constants/tableStylesPeset';

const iconStyles = { root: { width: 30, transition: 'transform 0.1s linear 0s' } };
const setIconProps: (link: INavLink) => INavLink = (link: INavLink) => ({
    ...link,
    iconProps: { ...link.iconProps, styles: iconStyles },
    // links: link.links?.map(setIconProps),
});

const navStyles = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        width: 200,
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    groupContent: {
        marginBottom: 0,
    },
    chevronIcon: {
        left: 16,
    },
    chevronButton: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',

        selectors: {
            '.ms-Nav-chevronButton ~ &': {
                paddingLeft: 41,
            },
            '.ms-Nav-navItem .ms-Nav-navItems &': {
                fontSize: 12,
            },
        },
    },
};

export const PreviewPage: FunctionComponent = () => {
    const [links] = useState<INavLink[]>([
        {
            name: 'Test 1',
            url: '',
            key: 'home',
            iconProps: { iconName: 'Help' },
        },
        {
            name: 'Test 2',
            url: '',
            key: 'private',
            iconProps: { iconName: 'Help' },
        },
    ]);

    const [selectedKey, setSelectedKey] = useState('rectangleRed');
    const intl = useIntl();

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setSelectedKey(item.props.itemKey!);
        }
    };

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'helpGuide',
                text: intl.formatMessage({ id: 'helpGuide' }),
                iconProps: { iconName: 'Info' },
            },
            {
                key: 'contactSupport',
                text: intl.formatMessage({ id: 'contactSupport' }),
                iconProps: { iconName: 'Mail' },
            },
        ],
        directionalHint: DirectionalHint.bottomLeftEdge,
    };

    const theme = useTheme();
    const [isCollapsed, { toggle: toggleIsCollapsed }] = useBoolean(false);
    const [isOpen, { setTrue: openPanel }] = useBoolean(false);

    const iconClass = mergeStyles({ color: theme.semanticColors.menuIcon });
    const [menuPropsForUserActions] = useState<IContextualMenuProps>({
        alignTargetEdge: true,
        items: [
            {
                key: 'userName',
                text: 'Olga Buinitskaya',
                itemType: ContextualMenuItemType.Header,
            },
            {
                key: 'adminPortal',
                text: intl.formatMessage({ id: 'adminPortal' }),
                iconProps: { iconName: 'Admin' },
                onClick: () => {},
            },
            {
                key: 'workflowEditor',
                onClick: () => {},
                onRenderContent: () => (
                    <Stack horizontal horizontalAlign='space-between' tokens={{ padding: '0 4px' }} styles={{ root: { width: '100%' } }}>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text variant='mediumPlus'>
                                <FontIcon aria-label='Workflow' iconName='Workflow' className={iconClass} />
                            </Text>
                            <Text variant='medium'>{intl.formatMessage({ id: 'workflowEditor' })}</Text>
                        </Stack>
                        <Text variant='mediumPlus'>
                            <FontIcon aria-label='NavigateExternalInline' iconName='NavigateExternalInline' />
                        </Text>
                    </Stack>
                ),
            },
            { key: 'divider', itemType: ContextualMenuItemType.Divider },
            // {
            //     key: 'changePassword',
            //     text: intl.formatMessage({ id: 'changePassword' }),
            //     iconProps: { iconName: 'AccountManagement' },
            //     onClick: () => navigate('/login/change-password'), // TODO: set real link to reset password page
            // },
            {
                key: 'logout',
                text: intl.formatMessage({ id: 'logout' }),
                iconProps: { iconName: 'SignOut', styles: ActiveIconColorStyles(theme.palette.redDark) },
                onClick: () => {},
            },
        ],
        directionalHint: DirectionalHint.bottomLeftEdge,
    });

    const navLinkGroups: INavLinkGroup[] = useMemo(
        () => [
            {
                links: links.map(setIconProps),
            },
            {
                links: [
                    {
                        key: 'collapse',
                        onClick: toggleIsCollapsed,
                        name: '',
                        url: '',
                        iconProps: {
                            iconName: 'DoubleChevronLeftMed',
                            styles: iconStyles,
                            className: 'toggle-icon',
                        },
                    },
                ],
            },
        ],
        [links, toggleIsCollapsed]
    );

    const { pathname } = useLocation();
    const currentKeyForNavLink = links.find((link) => link.url === pathname);
    const classNamesForNavLink = mergeStyleSets({
        collapsed: {
            maxWidth: 44,
            selectors: {
                '.ms-Nav-linkText': { display: 'none' },
                '.toggle-icon': { transform: 'rotate(180deg)' },
            },
        },
    });

    const buttonViewerProps: IContextualMenuProps = {
        items: [
            {
                key: 'emailMessage',
                text: 'Email message',
                iconProps: { iconName: 'Mail' },
            },
            {
                key: 'calendarEvent',
                text: 'Calendar event',
                iconProps: { iconName: 'Calendar' },
            },
        ],
    };

    return (
        <Stack styles={{ root: { height: '100%', minHeight: 448 } }}>
            <Stack horizontal horizontalAlign='space-between'>
                <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                    <Logo size='small' />
                    <Pivot
                        selectedKey={selectedKey}
                        onLinkClick={handleLinkClick}
                        headersOnly={true}
                        styles={{ root: { height: 48 }, link: { height: 48 } }}>
                        <PivotItem headerText={intl.formatMessage({ id: 'dashboard' })} itemKey='/dashboard' />
                        <PivotItem headerText={intl.formatMessage({ id: 'jobs' })} itemKey='/jobs' />
                        <PivotItem headerText={intl.formatMessage({ id: 'funds' })} itemKey='/funds' />
                    </Pivot>
                </Stack>
                <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                    <CommandBarButton
                        onClick={openPanel}
                        styles={{ root: { height: isOpen ? 48 : 48 } }}
                        iconProps={{ iconName: 'Ringer' }}
                    />
                    <IconButton styles={{ root: { height: 48 } }} menuProps={menuPropsForUserActions} iconProps={{ iconName: 'Contact' }} />
                    <CommandBarButton
                        styles={{ root: { height: 48 } }}
                        text={intl.formatMessage({ id: 'help' })}
                        iconProps={{ iconName: 'Help' }}
                        menuProps={menuProps}
                    />
                </Stack>
            </Stack>
            <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)' } }}>
                <Nav
                    onLinkClick={() => {}}
                    selectedKey={currentKeyForNavLink?.key}
                    className={isCollapsed ? classNamesForNavLink.collapsed : undefined}
                    styles={navStyles}
                    groups={navLinkGroups}
                />
                <Stack
                    styles={{
                        root: {
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                            background: theme.palette.neutralLight,
                            padding: 16,
                            boxSizing: 'border-box',
                        },
                    }}
                    tokens={{ childrenGap: 16 }}>
                    <Stack.Item>
                        <LayoutBreadcrumb />
                    </Stack.Item>
                    <Stack tokens={{ childrenGap: 16 }} horizontal={false} styles={{ root: { minHeight: 448 } }}>
                        <Text variant='xxLarge'>Lorem, ipsum dolor.</Text>
                        <Text>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cumque, molestias.</Text>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Checkbox label='Checked checkbox (uncontrolled)' defaultChecked onChange={() => {}} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { marginTop: 15 } }} horizontal>
                            <Stack.Item
                                styles={{
                                    root: {
                                        background: theme.palette.themePrimary,
                                        color: theme.palette.neutralPrimary,
                                        width: 100,
                                        height: 100,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        fontSize: 16,
                                    },
                                }}>
                                Pre Audit Issues
                            </Stack.Item>
                            <Stack.Item
                                styles={{
                                    root: {
                                        background: theme.palette.neutralPrimary,
                                        color: theme.palette.themePrimary,
                                        width: 100,
                                        height: 100,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        fontSize: 16,
                                    },
                                }}>
                                Audit In Progress
                            </Stack.Item>
                        </Stack>
                        <Stack>
                            <TextField label='Standard' defaultValue='Lorem ipsum' />
                        </Stack>
                        <Stack styles={{ root: { paddingTop: '20px' } }} horizontal wrap tokens={{ childrenGap: 20 }}>
                            <DefaultButton
                                text='Primary'
                                primary
                                split
                                splitButtonAriaLabel='See 2 options'
                                aria-roledescription='split button'
                                menuProps={buttonViewerProps}
                                onClick={() => {}}
                                checked={true}
                            />
                            <DefaultButton
                                text='Standard'
                                split
                                splitButtonAriaLabel='See 2 options'
                                aria-roledescription='split button'
                                menuProps={buttonViewerProps}
                                onClick={() => {}}
                                checked={true}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
