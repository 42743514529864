import React, {FunctionComponent, useCallback} from "react";
import {List, mergeStyles, mergeStyleSets, NeutralColors, Stack, Text, useTheme} from "@fluentui/react";
import { SanitizedText, TimeZoneDateTimeText } from "../../../../../../../components";
import {useIntl} from "react-intl";
import {useIsDarkTheme} from "../../../../../../../hooks";
import {AuditQueryPublishedIcon} from "../AuditQueryPublishedIcon";
import moment from 'moment-timezone'

export interface IAuditQueryDatesProps {
    initialQueriesSentDates?: any[];
    initialQueriesResponseDates?: any[];
    reviewedOSQueriesDates?: any[];
}

export const AuditQueryDates: FunctionComponent<IAuditQueryDatesProps> = ({ 
                                                                              initialQueriesSentDates,
                                                                              initialQueriesResponseDates,
                                                                              reviewedOSQueriesDates,
                                                                          }: IAuditQueryDatesProps) => {
    const {formatMessage} = useIntl();
    const isDark = useIsDarkTheme();

    const borderColor = isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30;
    
    const classNames = mergeStyleSets({
        holder: {
            border: `1px solid ${borderColor}`,
            borderRadius: 4,
            padding: 16
        }
    });
    
    const mapDates = useCallback((dates?: any[]): string[] => {
        
        if (!dates || !dates?.length) {
            return [];
        }
        
        return dates.reduce((acc: string[], i: any): string[] => {
            acc.push(i.toString())
            return acc;
        }, [])
    }, [])
    
    return (
        <Stack className={classNames.holder} horizontal tokens={{childrenGap: 16}}>
            <Stack.Item grow={1}>
                <AuditQueryDatesList showIcon={true} listName={formatMessage({id: 'initialQueriesSent'}) + ':'} dates={mapDates(initialQueriesSentDates)}></AuditQueryDatesList>
            </Stack.Item>
            <Stack.Item grow={1}>
                <AuditQueryDatesList listName={formatMessage({id: 'initialQueriesResponse'}) + ':'} dates={mapDates(initialQueriesResponseDates)}></AuditQueryDatesList>
            </Stack.Item>
            <Stack.Item grow={1}>
                <AuditQueryDatesList listName={formatMessage({id: 'reviewedOSQueries'}) + ':'} dates={mapDates(reviewedOSQueriesDates)}></AuditQueryDatesList>
            </Stack.Item>
        </Stack>
    )
}

export interface IAuditQueryDatesListProps {
    listName: string
    dates: string[];
    showIcon?: boolean;
}

export const AuditQueryDatesList: FunctionComponent<IAuditQueryDatesListProps> = ({ 
                                                                                      listName,
                                                                                      dates, 
                                                                                      showIcon = false
                                                                                  }: IAuditQueryDatesListProps) => {
    const theme = useTheme();
    const isDark = useIsDarkTheme();

    const borderColor = isDark ? 'rgba(180, 180, 180, 0.2)' : NeutralColors.gray30;
    
    const classNames = mergeStyleSets({
        item: {
            padding: 4,
            borderBottom: `1px solid ${borderColor}`,
            
            ':hover': {
                backgroundColor: theme.palette.neutralLighter,
            },
        }
    });
    
    const onRenderCell = (item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
            <Stack className={classNames.item} horizontal tokens={{childrenGap: 8}}>
                <Stack.Item>
                    <TimeZoneDateTimeText dateTime={item} />
                </Stack.Item>
                <Stack.Item>
                    {showIcon && <AuditQueryPublishedIcon publishedCount={(index || 0) + 1}></AuditQueryPublishedIcon>}
                </Stack.Item>
            </Stack>
        );
    };
    
    return (
        <Stack tokens={{childrenGap: 16}}>
            <Stack.Item>
                <SanitizedText theme={theme.schemes?.default} format={'Bold'} data={listName}></SanitizedText>
            </Stack.Item>
            <Stack.Item>
                <List items={dates} onRenderCell={onRenderCell} />
            </Stack.Item>
        </Stack>
    )
}