import {Stack} from '@fluentui/react';
import {AdministrationFilters} from './components/AdministrationFilters';
import {ClientsList} from './components/ClientsList';
import {FundsList} from './components/FundsList';
import {useAdministrationContext} from "./JobsLayoutPage";

export const JobsPage = () => {
    const { filters } = useAdministrationContext();
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item shrink={0}>
                <AdministrationFilters />
            </Stack.Item>
            <Stack.Item grow={1}>
                <ClientsList />
            </Stack.Item>
            {filters?.search && <FundsList />}
        </Stack>
    );
};
