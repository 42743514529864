import {Stack, TooltipHost} from "@fluentui/react";
import React, {ReactNode} from "react";
import {ISearchProps, Search} from "./Search";
import {IconButton} from "@fluentui/react/lib/Button";
import {Card} from "./Card";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {useIntl} from "react-intl";

export interface ISearchRowProps {
    hideSearchField?: boolean;
    showAdditionalFilter?: boolean;
    search?: ISearchProps;
    leftSide?: ReactNode;
    rightSide?: ReactNode;
    children?: ReactNode;

}

export const SearchRow = (props: ISearchRowProps) => {
    const filterToolTipId = useId('filter-tooltip');
    const [showAdditionalFilter, {toggle: toggleAdditionalFilter}] = useBoolean(false);
    const {formatMessage} = useIntl();

    return (
        <Stack verticalAlign={"space-between"} tokens={{childrenGap: 16}} styles={{root: {margin: '10px 0'}}}>
            <Stack grow horizontal horizontalAlign={"space-between"} tokens={{childrenGap: 16}}
                   verticalAlign={"center"}>
                <Stack.Item styles={{root: {width: '100%'}}}>
                    {props.leftSide ?? <></>}
                </Stack.Item>
                <Stack.Item styles={{root: {width: '100%'}}}>
                    <Stack horizontal>
                        {!props.hideSearchField && <Search styles={{root: {minWidth: props.search?.minWidth}}}
                                                           underlined={props.search?.underline || true}
                                                           onSearch={(newValue) => props.search?.onSearch && props.search.onSearch(newValue)}
                                                           onClear={() => props.search?.onClear && props.search.onClear()}
                        />
                        }
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{root: {width: '100%'}}}>
                    <Stack horizontal horizontalAlign={"end"}>
                        {props.showAdditionalFilter && (<TooltipHost
                            content={formatMessage({id: props.showAdditionalFilter ? 'hideAdditionalFilter' : 'showAdditionalFilter'})}
                            id={filterToolTipId}>
                            <IconButton aria-describedby={filterToolTipId}
                                        label='Filter'
                                        iconProps={{iconName: showAdditionalFilter ? 'ClearFilter' : 'Filter'}}
                                        onClick={() => toggleAdditionalFilter()}/>
                        </TooltipHost>)}
                        {props.rightSide ?? <></>}
                    </Stack>
                </Stack.Item>
            </Stack>
            {
                showAdditionalFilter && props.children && (
                    <Card>
                        <Stack>
                            <div>{props.children}</div>
                        </Stack>
                    </Card>
                )
            }
        </Stack>
    );
}
