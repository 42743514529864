import { useGetJobByGuid } from 'hooks';
import { ISectionInfo } from 'pages/JobPortal/interfaces';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApproveFeeMatrix, useGetFeeCalculationSection, useRecalculateFeeMatrix, useSaveFeeCalculationItem } from './hooks';
import { Dropdown, IColumn, PrimaryButton, Spinner, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { DataTable, SanitizedText } from 'components';
import { FeeCalculationAnswer, FeeCalculationItemType, FeeCalculationLineItem, FeematrixType } from './Types';
import { SoftwareType, TableType } from 'enums';
import { useSectionContext } from '../../Section';
import { JobComment } from '../../JobComment';
import { JobOmls } from '../../OmlComponent';
import { PortalRole, useWorkContext } from "../../../../../providers";

const softwareTypeOptions = [
    { key: 1, softwareType: SoftwareType.ClassSuper },
    { key: 3, softwareType: SoftwareType.Supermate },
    { key: 4, softwareType: SoftwareType.BGL },
    { key: 5, softwareType: SoftwareType.BGL360 },
    { key: -1, softwareType: SoftwareType.Nothing },
];

interface Props {
    section: ISectionInfo;
}

export const FeeCalculationContent = forwardRef(({ section }: Props, ref) => {
    const { guid } = useParams();
    const { dataJobs } = useGetJobByGuid({ guid });
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { updateSectionAnswerIcon } = useSectionContext();
    const { isCurrentUserJobManager, isInRoleOrSuperAdministrator } = useWorkContext();

    const { feeCalculationSectionData, isFeeCalculationSectionFetching, isFeeCalculationSectionLoading, refetchFeeCalculationSection } =
        useGetFeeCalculationSection({
            jobId: dataJobs?.data.id ?? 0,
            jobGuid: dataJobs?.data.guid ?? '',
            feeMatrixVersion: 2,
        });

    useImperativeHandle(ref, () => ({
        refresh() {
            refetchFeeCalculationSection();
        },
    }));

    const {updateAsync: approve, isUpdating: isApproveInProgress} = useApproveFeeMatrix();
    const {update: recalculate, isUpdating: isRecalculateInProgress} = useRecalculateFeeMatrix();
    const { update: saveItem, isUpdating: isSaveInProgress } = useSaveFeeCalculationItem();

    const ticksToDateString = useCallback((ticks?: number | null) => {
        if (!ticks) return '-';

        const epochTicks = 621355968000000000;
        const ticksPerMillisecond = 10000;
        const maxDateMilliseconds = 8640000000000000;

        const ticksSinceEpoch = ticks - epochTicks;
        const millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond;
        var date = new Date(millisecondsSinceEpoch);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }, []);

    const lastEditedDate = useMemo(() => {
        return ticksToDateString(feeCalculationSectionData?.data.lastEditedDateTicks);
    }, [feeCalculationSectionData?.data.lastEditedDateTicks]);

    const lastCalculatedDate = useMemo(() => {
        return ticksToDateString(feeCalculationSectionData?.data.lastCalculatedDateTicks);
    }, [feeCalculationSectionData?.data.lastCalculatedDateTicks]);

    const risk1LinkedItem = useMemo(
        () =>
            feeCalculationSectionData?.data.feeCalculationLineItems.find(
                (x) => x.feeCalculationModel.itemType === FeeCalculationItemType.Risk1LinkedItem
            ),
        [feeCalculationSectionData?.data.feeCalculationLineItems]
    );

    const yesNoAnswerOptions = [
        {
            key: '',
            text: '',
        },
        {
            key: FeeCalculationAnswer.Yes,
            text: formatMessage({ id: 'yes' }),
        },
        {
            key: FeeCalculationAnswer.No,
            text: formatMessage({ id: 'no' }),
        },
    ];

    const handleAnswerChange = (item: FeeCalculationLineItem, answerOption: any) => {
        saveItem({
            jobId: dataJobs?.data.id,
            jobGuid: dataJobs?.data.guid,
            feeAmount: item.feeAmount,
            answer: !!answerOption?.key ? answerOption?.key : null,
            manualFeeAmount: item.manualFeeAmount,
            howManyQuantity: item.howManyQuantity,
            feeCalculationId: item.feeCalculationModel.id
        });
    }

    const handleQuantityChange = (item: FeeCalculationLineItem, quantityOption: any) => {
        saveItem({
            jobId: dataJobs?.data.id,
            jobGuid: dataJobs?.data.guid,
            feeAmount: item.feeAmount,
            answer: item.answer,
            manualFeeAmount: item.manualFeeAmount,
            howManyQuantity: !!quantityOption?.key ? quantityOption?.key : null,
            feeCalculationId: item.feeCalculationModel.id
        });
    }

    const handleFeeAmountChange = (item: FeeCalculationLineItem, feeAmount: any) => {
        if(Number(feeAmount) == item.feeAmount){
            return;
        }

        saveItem({
            jobId: dataJobs?.data.id,
            jobGuid: dataJobs?.data.guid,
            feeAmount: !!feeAmount && !isNaN(Number(feeAmount)) ? feeAmount : null,
            answer: item.answer,
            manualFeeAmount: item.manualFeeAmount,
            howManyQuantity: item.howManyQuantity,
            feeCalculationId: item.feeCalculationModel.id
        });
    }

    const handleFeeApplicableChange = (item: FeeCalculationLineItem, feeApplicable: any) => {
        if(Number(feeApplicable) == item.manualFeeAmount || Number(feeApplicable) == item.resultFeeAmount){
            return;
        }

        saveItem({
            jobId: dataJobs?.data.id,
            jobGuid: dataJobs?.data.guid,
            feeAmount: item.feeAmount,
            answer: item.answer,
            manualFeeAmount: !!feeApplicable && !isNaN(Number(feeApplicable)) ? feeApplicable : null,
            howManyQuantity: item.howManyQuantity,
            feeCalculationId: item.feeCalculationModel.id
        });
    }

    const getAnswerContent = useCallback(
        (item: FeeCalculationLineItem) => {
            const isDisabled =
                item.feeCalculationModel.itemType !== FeeCalculationItemType.Risk1LinkedItem &&
                (item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons ||
                    (risk1LinkedItem?.answer === FeeCalculationAnswer.Yes &&
                        item.feeCalculationModel.order - risk1LinkedItem.feeCalculationModel.order <= 10));

            const showValue = !isDisabled || item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons;

            switch (item.feeCalculationModel.itemType) {
                case FeeCalculationItemType.SimpleYesNo:
                case FeeCalculationItemType.Risk1LinkedItem:
                case FeeCalculationItemType.InvestmentTabLinkedItem:
                case FeeCalculationItemType.SimpleHowManyDropdown:
                case FeeCalculationItemType.ContraventionLinked:
                case FeeCalculationItemType.ClassApiCharge:
                case FeeCalculationItemType.ASICSearch:
                case FeeCalculationItemType.TItleSearchOtherStates:
                case FeeCalculationItemType.TitleSearchNSW:
                case FeeCalculationItemType.ARWithheldUntilPaymentReceived:
                    return (
                        <Dropdown
                            onChange={(event, option)=>{handleAnswerChange(item, option)}}
                            disabled={isDisabled || feeCalculationSectionData?.data.isApproved}
                            theme={theme.schemes?.default}
                            styles={{ root: { width: '100%' }, dropdownOptionText: { color: theme.schemes?.default?.semanticColors.bodyText} }}
                            options={yesNoAnswerOptions}
                            defaultSelectedKey={!showValue || !item.answer ? '' : item.answer}
                        />
                    );
                case FeeCalculationItemType.TotalValueOfAssets:
                case FeeCalculationItemType.NumberOfAssets:
                    return (
                        <TextField
                            onBlur={(event)=>{handleFeeAmountChange(item, event.target.value)}}
                            disabled={feeCalculationSectionData?.data.isApproved}
                            style={{
                                color: item.manualFee
                                    ? theme.schemes?.default?.palette.blue
                                    : theme.schemes?.default?.semanticColors.bodyText,
                            }}
                            theme={theme.schemes?.default}
                            type='number'
                            defaultValue={item.feeAmount?.toString()}
                        />
                    );
                default:
                    return <></>;
            }
        },
        [risk1LinkedItem?.answer, risk1LinkedItem?.feeCalculationModel.order, feeCalculationSectionData?.data.isApproved]
    );

    const getQuantityAnswerContent = useCallback(
        (item: FeeCalculationLineItem) => {
            const isDisabled =
                item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons ||
                (risk1LinkedItem?.answer === FeeCalculationAnswer.Yes &&
                    item.feeCalculationModel.order - risk1LinkedItem.feeCalculationModel.order <= 10);

            const showDropdown =
                (!isDisabled || item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons) &&
                item.answer === FeeCalculationAnswer.Yes;

            switch (item.feeCalculationModel.itemType) {
                case FeeCalculationItemType.SimpleHowManyDropdown:
                case FeeCalculationItemType.ContraventionLinked:
                case FeeCalculationItemType.ASICSearch:
                case FeeCalculationItemType.TItleSearchOtherStates:
                case FeeCalculationItemType.TitleSearchNSW:
                    const options =
                        !item.feeCalculationModel.minQuantity || !item.feeCalculationModel.maxQuantity
                            ? null
                            : Array.from(
                                  { length: item.feeCalculationModel.maxQuantity - item.feeCalculationModel.minQuantity + 1 },
                                  (_, i) => ({
                                      key: i + item.feeCalculationModel.minQuantity!,
                                      text: `${i + item.feeCalculationModel.minQuantity!}`,
                                  })
                              );

                    options?.unshift({ key: 0, text: '' });

                    return !options || !showDropdown ? (
                        <></>
                    ) : (
                        <Dropdown
                        onChange={(event, option)=>{handleQuantityChange(item, option)}}
                            disabled={isDisabled || feeCalculationSectionData?.data.isApproved}
                            theme={theme.schemes?.default}
                            styles={{ root: { width: '100%' }, dropdownOptionText: { color: theme.schemes?.default?.semanticColors.bodyText} }}
                            options={options}
                            defaultSelectedKey={item.howManyQuantity}
                        />
                    );
                 default:
                    return <></>;
            }
        },
        [risk1LinkedItem?.answer, risk1LinkedItem?.feeCalculationModel.order, feeCalculationSectionData?.data.isApproved]
    );

    const getQuantityQuestion = useCallback(
        (item: FeeCalculationLineItem) => {
            const isDisabled =
                item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons ||
                (risk1LinkedItem?.answer === FeeCalculationAnswer.Yes &&
                    item.feeCalculationModel.order - risk1LinkedItem.feeCalculationModel.order <= 10);


            if(isDisabled || item.answer !== FeeCalculationAnswer.Yes)
            {
                return '';
            }

            switch (item.feeCalculationModel.itemType) {
                case FeeCalculationItemType.SimpleHowManyDropdown:
                case FeeCalculationItemType.ContraventionLinked:
                case FeeCalculationItemType.ASICSearch:
                case FeeCalculationItemType.TItleSearchOtherStates:
                case FeeCalculationItemType.TitleSearchNSW:
                    return formatMessage({ id: 'howManyQuestion' })
                 default:
                    return '';
            }
        },
        [risk1LinkedItem?.answer, risk1LinkedItem?.feeCalculationModel.order]
    );

      const getFeeApplicableContent = useCallback(
          (item: FeeCalculationLineItem) => {
              const isDisabled =
                  item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons ||
                  item.feeCalculationModel.itemType === FeeCalculationItemType.Label ||
                  (risk1LinkedItem?.answer === FeeCalculationAnswer.Yes &&
                      item.feeCalculationModel.itemType !== FeeCalculationItemType.InvestmentTabLinkedItem &&
                      item.feeCalculationModel.order - risk1LinkedItem.feeCalculationModel.order <= 10);

              const applicableTextFieldtyles = {
                  root: {
                      selectors: {
                          '.ms-TextField-fieldGroup:not(:focus)': {
                              outline: 'none',
                              borderColor: 'transparent',
                          },
                      },
                  },
              };

              const firstYearQuestionTitle = "First Year Audit for Evolv";

              const showTextField =
                  item.feeCalculationModel.itemType !== FeeCalculationItemType.TotalValueOfAssets &&
                  item.feeCalculationModel.itemType !== FeeCalculationItemType.NumberOfAssets &&
                  !item.feeCalculationModel.title.toLowerCase().includes(firstYearQuestionTitle.toLowerCase()) &&
                  (item.answer === FeeCalculationAnswer.Yes 
                    || item.feeCalculationModel.itemType == FeeCalculationItemType.InvestmentTabLinkedItem
                    || (item.feeCalculationModel.itemType === FeeCalculationItemType.Label &&
                               (item.manualFeeAmount !== null || item.resultFeeAmount !== null || item.feeAmount != null))
                    || (item.feeCalculationModel.feeMatrixType === FeematrixType.NonAutoAdditionalChargesWithButtons && 
                        item.feeCalculationModel.itemType !== FeeCalculationItemType.ContraventionLinked &&
                                (!!item.manualFeeAmount || !!item.resultFeeAmount || !!item.feeAmount)));

              return showTextField ? (
                  <TextField
                      onBlur={(event)=>{handleFeeApplicableChange(item, event.target.value)}}
                      disabled={isDisabled}
                      theme={theme.schemes?.default}
                      style={{
                          color: !!item.manualFeeAmount
                              ? theme.schemes?.default?.palette.blue
                              : theme.schemes?.default?.semanticColors.bodyText,
                      }}
                      defaultValue={item.manualFeeAmount?.toFixed(2) ?? item.resultFeeAmount?.toFixed(2) ?? item.feeAmount?.toFixed(2)}
                      styles={applicableTextFieldtyles}
                  />
              ) : (
                  <></>
              );
          },
          [risk1LinkedItem?.answer, risk1LinkedItem?.feeCalculationModel.order]
      );


    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'question',
                name: formatMessage({ id: 'question' }),
                targetWidthProprotion: 5,
                minWidth: 0,
                fieldName: 'question',
                onRender: (item, _, column) =>
                    <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign={'center'}>
                        {item.feeCalculationModel.showCommentsIcon && <Stack.Item>
                            <JobComment itemId={item.id} hasComments={item.feeCalculationModel.hasComments} />
                        </Stack.Item>}
                        {item.feeCalculationModel.showOmlsIcon && <Stack.Item>
                            <JobOmls
                                tableType={TableType.FeeCalculation}
                                itemId={item.feeCalculationModel.id}
                                onAfterSubmit={(_) => {
                                    //sectionCtx?.refresh?.()
                                }}
                                showRaisedItems={false}
                                showNonWitholdingColumn={false}
                                disabled={false}
                                hasOmls={item.feeCalculationModel.hasOmls}
                            />
                        </Stack.Item>}
                        <Stack.Item>
                            <SanitizedText
                                format={item.feeCalculationModel.itemType === FeeCalculationItemType.Label ? 'Bold' : 'Normal'}
                                data={item.feeCalculationModel.title}
                            />
                        </Stack.Item>
                    </Stack>
            },
            {
                key: 'answer',
                name: formatMessage({ id: 'answer' }),
                targetWidthProprotion: 1,
                minWidth: 0,
                fieldName: 'answer',
                onRender: (item, _, column) => {
                    return getAnswerContent(item);
                },
            },
            {
                key: 'quantityQuestion',
                name: formatMessage({ id: 'quantityQuestion' }),
                targetWidthProprotion: 1,
                minWidth: 0,
                fieldName: 'quantityQuestion',
                onRender: (item, _, column) => <SanitizedText data={getQuantityQuestion(item)} />,
            },
            {
                key: 'quantityAnswer',
                name: formatMessage({ id: 'quantityAnswer' }),
                targetWidthProprotion: 1,
                minWidth: 0,
                fieldName: 'quantityAnswer',
                onRender: (item, _, column) => {
                    return getQuantityAnswerContent(item);
                },
            },
            {
                key: 'feeType',
                name: formatMessage({ id: 'feeType' }),
                targetWidthProprotion: 1,
                minWidth: 0,
                fieldName: 'feeType',
                onRender: (item, _, column) => <SanitizedText format='Bold' data={item.feeCalculationModel.feeText} />,
            },
            {
                key: 'feeApplicable',
                name: formatMessage({ id: 'feeApplicable' }),
                targetWidthProprotion: 1,
                minWidth: 0,
                fieldName: 'feeApplicable',
                onRender: (item, _, column) => {
                    return getFeeApplicableContent(item);
                },
            },
        ],
        [formatMessage, getAnswerContent]
    );

    const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);

    useEffect(() => {
        if(!!feeCalculationSectionData?.data && !!risk1LinkedItem)
        {
            if(feeCalculationSectionData?.data.isApproved)
            {
                setIsApproveButtonDisabled(false);
                return;
            }

            if(!feeCalculationSectionData.data.lastCalculatedDateTicks ||
                (!!feeCalculationSectionData.data.lastEditedDateTicks &&
                    (feeCalculationSectionData.data.lastEditedDateTicks > feeCalculationSectionData.data.lastCalculatedDateTicks)))
            {
                setIsApproveButtonDisabled(true);
                return;
            }

            var itemsToCheck = risk1LinkedItem.answer === FeeCalculationAnswer.No ? 
                feeCalculationSectionData.data.feeCalculationLineItems
                    .filter(x=>x.feeCalculationModel.feeMatrixType === FeematrixType.SingleTableWithCheckboxApprovment 
                                && x.id !== risk1LinkedItem.id) 
                : feeCalculationSectionData.data.feeCalculationLineItems
                     .filter(x=>x.feeCalculationModel.feeMatrixType === FeematrixType.SingleTableWithCheckboxApprovment 
                                && (x.feeCalculationModel.order - risk1LinkedItem.feeCalculationModel.order) > 10);

            if(itemsToCheck.some(x => 
                x.feeCalculationModel.itemType !== FeeCalculationItemType.NumberOfAssets &&
                x.feeCalculationModel.itemType !== FeeCalculationItemType.TotalValueOfAssets &&
                x.feeCalculationModel.itemType !== FeeCalculationItemType.Label &&
                !x.answer))
            {
                setIsApproveButtonDisabled(true);
                return;
            }

            if(itemsToCheck.some(x => 
                (x.feeCalculationModel.itemType === FeeCalculationItemType.SimpleHowManyDropdown ||
                x.feeCalculationModel.itemType === FeeCalculationItemType.YesNoWithHowMany) &&
                x.answer === FeeCalculationAnswer.Yes && x.howManyQuantity === null))
            {
                setIsApproveButtonDisabled(true);
                return;
            }

            if(itemsToCheck.some(x => 
                (x.feeCalculationModel.itemType === FeeCalculationItemType.TotalValueOfAssets ||
                x.feeCalculationModel.itemType === FeeCalculationItemType.NumberOfAssets) &&
                !x.feeAmount))
            {
                setIsApproveButtonDisabled(true);
                return;
            }

            setIsApproveButtonDisabled(false);
        }
    }, [feeCalculationSectionData?.data, risk1LinkedItem]);

    useEffect(() => {
        if (feeCalculationSectionData?.data) {
            const paymentTiming = feeCalculationSectionData.data.feeStructure.paymentTiming;
            const item = feeCalculationSectionData?.data.feeCalculationLineItems.find(x => x.feeCalculationModel.itemType === FeeCalculationItemType.ARWithheldUntilPaymentReceived);
            if (item) {
                item.answer = !paymentTiming || paymentTiming === 100000001 ? FeeCalculationAnswer.No : FeeCalculationAnswer.Yes;
                handleAnswerChange(item, yesNoAnswerOptions.find(o => o.key === item.answer))
            }
        }
        
        return () => {}
    }, [feeCalculationSectionData?.data]);

    const approveButtonClickHandler = () => {
        approve({
            jobId: dataJobs?.data.id,
            isApproved: !feeCalculationSectionData?.data.isApproved,
        }).then(x => updateSectionAnswerIcon(!feeCalculationSectionData?.data.isApproved));
    }

    const recalculateButtonClickHandler = () => {
        recalculate({
            jobId: dataJobs?.data.id,
            jobGuid: dataJobs?.data.guid,
            softwareType: softwareTypeOptions.find(o => o.key === dataJobs?.data.softwareType)?.key ?? 0,
            detailedSoftwareType: dataJobs?.data.detailedSoftwareType === 0 ? 100 : dataJobs?.data.detailedSoftwareType,
        });
    }

    const [isApproveButtonEnabled, setIsApproveButtonEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsApproveButtonEnabled(!isApproveButtonDisabled && (isCurrentUserJobManager || isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.ReviewerUser)));
    }, [isApproveButtonDisabled, isCurrentUserJobManager]);

    if (isFeeCalculationSectionLoading || isFeeCalculationSectionFetching
        || isApproveInProgress || isRecalculateInProgress || isSaveInProgress) {
       return <Spinner />;
   }

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item align='end'>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { justifyContent: 'space-between' } }}>
                        <Text theme={theme.schemes?.default}>
                            <b>{formatMessage({ id: 'lastEditedDate' })}</b>
                        </Text>
                        <Text theme={theme.schemes?.default}>{lastEditedDate}</Text>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { justifyContent: 'space-between' } }}>
                        <Text theme={theme.schemes?.default}>
                            <b>{formatMessage({ id: 'lastCalculatedDate' })}</b>
                        </Text>
                        <Text theme={theme.schemes?.default}>{lastCalculatedDate}</Text>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <DataTable
                    initialColumns={columns}
                    columns={columns}
                    items={
                        feeCalculationSectionData?.data.feeCalculationLineItems.filter(
                            (x) => x.feeCalculationModel.feeMatrixType == FeematrixType.SingleTableWithCheckboxApprovment
                        ) ?? []
                    }
                    containerHeight='100%'
                />
            </Stack.Item>
            <Stack.Item align='end'>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <PrimaryButton 
                        onClick={recalculateButtonClickHandler}
                        text={formatMessage({ id: 'recalculate' })} />
                    <PrimaryButton 
                        onClick={approveButtonClickHandler}
                        text={feeCalculationSectionData?.data.isApproved ? formatMessage({ id: 'unlock' }) : formatMessage({ id: 'approve' })}
                        disabled={!isApproveButtonEnabled} />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <DataTable
                    initialColumns={columns}
                    columns={columns}
                    items={
                        feeCalculationSectionData?.data.feeCalculationLineItems.filter(
                            (x) => x.feeCalculationModel.feeMatrixType == FeematrixType.NonAutoAdditionalChargesWithButtons
                        ) ?? []
                    }
                    containerHeight='100%'
                />
            </Stack.Item>
        </Stack>
    );
});
