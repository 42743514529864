import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDateType = {
    id: number;
}

function useUpdateAduitTrail() {
    const context = useContext(Context);
    const host = context?.config?.proceduresUrl;

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDateType) => axios.put(`${host}/api/1.0/procedures/auditTrail/${data.id}`, null, commonHeaders()));

    return {update, updateAsync, isUpdating};
}

export {useUpdateAduitTrail}