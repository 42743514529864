import React, { FunctionComponent, ReactNode } from 'react';
import { Stack } from '@fluentui/react';
import { Logo } from './Logo';
import { UserActions } from './UserActions';
import { LayoutBreadcrumb } from './LayoutBreadcrumb';
import { NotificationPanelButton } from './notifications';

interface Props {
    openPanel: () => void;
    left?: ReactNode | undefined;
    right?: ReactNode | undefined;
}

export const Header: FunctionComponent<Props> = ({ left, right, openPanel }: Props) => {
    return (
        <Stack horizontal horizontalAlign='space-between' verticalAlign={'end'}>
            <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                <Logo size='small' currentLogo={true} linkToMain={true} />
            </Stack>
            <Stack verticalAlign={'center'} horizontalAlign={'start'} styles={{ root: { width: '100%', height: '100%', paddingLeft: 10 } }}>
                {left ?? null}
                <LayoutBreadcrumb />
            </Stack>
            <Stack styles={{ root: { height: '100%' } }} horizontal verticalAlign='end'>
                <NotificationPanelButton onShow={openPanel} />
                <UserActions />
                {right ?? null}
            </Stack>
        </Stack>
    );
};
