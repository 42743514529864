import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import {IPropertyDetailItem} from "../interfaces";
import axios from "axios";


function useCreatePropertyDetail() {
    const context = useContext(Context);
    const host = context?.config?.proceduresUrl;

    const {
        mutate: create,
        isLoading: isCreating
    } = useMutation((data: IPropertyDetailItem) => axios.post<IPropertyDetailItem>(`${host}/api/1.0/procedures/propertyDetails`, data).then(resp => resp.data));

    return {create, isCreating};
}

export {useCreatePropertyDetail};