import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

interface IHideAttachmentProps {
  id: number;
}

function useHideAttachment() {
    const context = React.useContext(Context);

    const { mutate: hide, isLoading, isSuccess } = useMutation(({ id }: IHideAttachmentProps) =>
        axios.put(`${context?.config?.annotationsUrl}/api/1.0/attachments/${id}/hide`, null, commonHeaders()),
        {
            onSuccess: () => { queryClient.invalidateQueries('getAttachments'); queryClient.invalidateQueries('getDisplayDocuments') },
        }
    );

    return { hide, isLoading, isSuccess };
}

export { useHideAttachment };