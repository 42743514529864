import { useContext } from "react";
import { Context } from "../../../utils";
import { useMutation } from "react-query";
import { IJobReviewDetails } from "../interfaces";
import axios from "axios";

type MutateInfo = {
	riskLevelId: number;
	riskLevelOption: number;
}

function useUpdateJobRiskLevel(guid: string) {
	const context = useContext(Context);
	const host = context?.config?.jobUrl;

	const {
		mutate: update,
		mutateAsync: updateAsync,
		isLoading: isUpdating
	} = useMutation((data: MutateInfo) => axios.put(`${host}/api/1.0/job/${guid}/updateRiskLevel`, { ...data, jobGuid: guid }));

	return {update, updateAsync, isUpdating};
}

export {useUpdateJobRiskLevel}