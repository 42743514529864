import {Outlet, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {useAdministrationContext} from "./JobsLayoutPage";

export const FundsListLayoutPage = () => {
    const { formatMessage } = useIntl();
    const { clientId } = useParams();
    const { selectedClient } = useAdministrationContext();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'funds',
                key: RouterPaths.administration.jobs.funds.root(clientId),
                text: selectedClient?.name || formatMessage({ id: 'funds' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};