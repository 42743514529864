import { Link, Stack } from '@fluentui/react';
import { FunctionComponent } from "react";
import { useIntl } from 'react-intl';
import { Card } from "../components";
import { stores } from "../stores";

type ForbiddenProps = {
}

export const Forbidden: FunctionComponent<ForbiddenProps> = ({ ...props }: ForbiddenProps) => {
	const { formatMessage } = useIntl();
	
	const onSignInClick = () => {
		stores.AuthStore.clearToken();
		stores.AuthStore.signIn();
	}
	
	return (
		<Stack styles={{root: {height: '100%'}}} horizontalAlign='center' verticalAlign='center'>
			<Card
				styles={{
					root: {
						maxWidth: 500,
						width: '100%',
					},
				}}>
				<Stack horizontalAlign='center' verticalAlign='center' tokens={{childrenGap: 16}}>
					<Stack.Item>
						{formatMessage({id: 'accessDeniedMessage'})}
					</Stack.Item>
					<Stack.Item>
						<Stack horizontal horizontalAlign='center' verticalAlign='center' tokens={{childrenGap: 16}}>
							<Stack.Item>
								{formatMessage({id: 'pleaseSignIn'})}
							</Stack.Item>
							<Stack.Item>
								<Link underline variant='medium' onClick={onSignInClick} styles={{root: {fontWeight: 500}}}>
									{formatMessage({id: 'signIn'})}
								</Link>
							</Stack.Item>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card>
		</Stack>
	);
};