import { useContext } from 'react';
import { commonHeaders, Context } from '../../../../../utils';
import { useInvoicePortalNotifications } from '../../../hooks';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { _InvoiceControllerName } from "./index";

type MutateDataType = {
    clinetIdentifier: string;
    id: number;
};

function useGenerateInvoice() {
    const context = useContext(Context);
    const { showApiOnErrorNotification, showInfoNotification } = useInvoicePortalNotifications();

    const { mutate: generateInvoice, isLoading: isGenerating } = useMutation(
        (data: MutateDataType) =>
            axios.post(
                `${context?.config?.billingUrl}/api/1.0/${_InvoiceControllerName}/Generate/${data.clinetIdentifier}/${data.id}`,
                commonHeaders()
            ),
        {
            onSuccess: () => {
                showInfoNotification({ id: 'invoiceGeneratingMessage', values: { name: '' } });
            },
            onError: (error: AxiosError) => {
                showApiOnErrorNotification({ values: { ...(error?.response?.data ?? {}) } });
            },
        }
    );

    return { generateInvoice, isGenerating };
}

export { useGenerateInvoice };
