import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { IClientInfo } from 'interfaces';

export enum ItemSelectionMode {
    AddByOne,
    SelectAll
}
export interface IEditUser {
    body: IClientInfo[];
    userId: number;
    mode: ItemSelectionMode
}

export function useEditUserAssignClients() {
    const context = React.useContext(Context);

    const { mutate: editUserAssignClients, isLoading } = useMutation(({ userId, body, mode }: IEditUser) =>
        axios.put(`${context?.config?.userUrl}/api/1.0/User/${userId}/assignClients?mode=${mode}`, body, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
        }
    );

    return { editUserAssignClients, isLoading };
}