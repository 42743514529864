import {useIntl} from "react-intl";
import {useCreateNewTeam} from "../../hooks";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "constants/forms";
import {ControlledTextField, ModalWithForm} from "components";
import {Stack} from "@fluentui/react";

type CreateTeamModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

interface ICreateTeamCommand {
    name: string;
}

export const CreateTeamModal = (props: CreateTeamModalProps) => {
    const {formatMessage} = useIntl();

    const {update: createTeam, isUpdating: isCreating} = useCreateNewTeam();
    const {control, reset, formState, handleSubmit} = useForm<ICreateTeamCommand>({
        ...DefaultFormSettings,
        defaultValues: {name: ''}
    });

    const submitFrom = (data: ICreateTeamCommand) => {
        createTeam({
            name: data.name
        });
    }

    const closeModal = () => {
        props.onDismiss();
        reset();
    };


    return <ModalWithForm isSubmitting={isCreating}
                          width={300}
                          minWidth={'30%'}
                          onDismiss={closeModal}
                          title={props.title || formatMessage({id: 'team'})}
                          closeAfterSubmit={true}
                          isOpen={props.isOpen}
                          onSubmit={handleSubmit(submitFrom)}
                          submitDisabled={!formState.isDirty || !formState.isValid}>
        <Stack styles={{root: {width: '100%'}}}>
            <ControlledTextField name={"name"} control={control} required
                                 label={formatMessage({id: 'name'})}
                                 rules={{required: formatMessage({id: 'requiredField'})}}/>
        </Stack>
    </ModalWithForm>
}