import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import {useProcedureContext} from "../../../ProceduresContent";
import {useIntl} from "react-intl";
import {
    AuditQueriesDataTable,
    AuditQueriesDataTableDateFormat
} from "./AuditQueriesDataTable";
import {IconButton, Stack, Text} from "@fluentui/react";
import {JobComment} from "../../../JobComment";
import {ITableColumn, SanitizedText} from "../../../../../../components";
import {Attachment} from "../../../Attachment";
import {formatDate} from "../../../../../../utils";
import {QueryClientComment, QueryComment} from "./QueryComment";
import {InitialAuditQueryItem} from "./interfaces";
import { AuditQueryRedirectButton } from "./AuditQueryRedirectButton";

export const DeletedAuditQueriesTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {formatMessage} = useIntl();

    const [shouldRefreshItems, setShouldRefreshItems] = useState<number>();
    
    const onQueryCommentUpdate = useCallback((updated: { itemId: number, comment?: string }) => {
        let item = (items || []).find(i => i.id === updated.itemId)
        if (item) {
            item.comment = updated.comment;
            setShouldRefreshItems(_ => Date.now())
        }
    }, [])

    const queryMapFn = (i: InitialAuditQueryItem): InitialAuditQueryItem => {
        return {
            ...i,
        }
    }
    
    const queries = useMemo<InitialAuditQueryItem[]>(() => {
        return [...(items || []).map(queryMapFn)];
    }, [items, shouldRefreshItems])
    
    const columns = useMemo((): ITableColumn[] => [
        {
            key: 'actions',
            name: formatMessage({id: 'actions'}),
            minWidth: 128,
            maxWidth: 170,
            fieldName: 'actions',
            onRender: (item, _, column) => (
                <Stack grow={1} horizontal horizontalAlign='space-between'>
                    <AuditQueryRedirectButton refs={item.procedureRefs} />
                    <QueryClientComment itemId={item?.id} comment={item?.clientComment} />
                    <QueryComment itemId={item?.id} comment={item?.comment} onUpdate={onQueryCommentUpdate} />
                    <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                </Stack>
            )
        },
        {
            key: 'number',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            fieldName: 'number',
            onRender: (item, _, column) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text>{(_ ?? 0) + 1}</Text>
                </Stack>
            )
        },
        {
            key: 'text',
            name: formatMessage({id: 'deletedAuditQueries'}),
            minWidth: 256,
            fieldName: 'text',
            onRender: (item, _, column) => (
                <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                    <SanitizedText data={item?.text} styles={{root: {maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellypsis'}}}/>
                </Stack>
            )
        },
        {
            key: 'deletedBy',
            name: formatMessage({id: 'user'}),
            minWidth: 128,
            fieldName: 'deletedBy',
            onRender: (item, _, column) => (
                <SanitizedText data={item?.deletedBy!}/>
            )
        },
        {
            key: 'deletedDate',
            name: formatMessage({id: 'dateDeleted'}),
            minWidth: 128,
            fieldName: 'deletedDate',
            onRender: (item, _, column) => (
                <SanitizedText data={formatDate(item?.deletedDate!, AuditQueriesDataTableDateFormat)}/>
            )
        },
    ], [formatMessage])
    
    return (
        <AuditQueriesDataTable 
            items={queries}
            isLoading={isLoading}
            columns={columns}
        />
    )
}