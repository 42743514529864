import React, {forwardRef, useRef} from 'react';
import {Section, useProcedureContext} from 'pages/JobPortal/components';


export const AuditReadySummarySection = forwardRef((props, ref) => {
    const {items} = useProcedureContext();
    const childRef = useRef<any>(ref);

    return (
        <>
            {items?.map((section) => (
                <Section ref={childRef} fullScreen={false} secondary section={section}
                         key={`audit-ready-summary-section-${section.id}`}/>
            ))}
        </>
    );
});