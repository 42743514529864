import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Stack} from '@fluentui/react';
import {DashboardTabsEnum, useGetShortClientInfos} from "pages/shared/Dashboard";
import {useCreateNewFund} from "../../hooks";
import {DefaultFormSettings} from "constants/forms";
import {Card, ControlledDropdown, ControlledTextField, ModalWithForm} from "components";
import {useGetActiveClients} from "../../../shared/hooks";
import {useTenantInfo} from "../../../../../providers";
import {useGetClientByGuid, useGetClientContacts} from "../../../Clients";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    clientId?: string;
}

interface ICreateFundCommand {
    name: string;
    trustee: string;
    abn: string;
    clientId: string;
    primaryContactId?: number;
}

export const FundsAddItemModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title, clientId}: Props) => {
    const {control, watch, handleSubmit, formState} = useForm<ICreateFundCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            name: '',
            trustee: '',
            abn: '',
            clientId: clientId || '',
        },
    });
    const {isWorkpapers} = useTenantInfo();
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {response: activeClientsResponse, isLoading: isActiveClientsLoading} = useGetActiveClients();
    const {addNewFund: createFund} = useCreateNewFund(() => onDismiss());
    const clientGuidWatched = watch('clientId');
    const [selectedClientId, setSelectedClientId] = useState<number | null>();
    const {contactsResponse, isContactsLoading} = useGetClientContacts(selectedClientId || null);

    const clientsOptions = useMemo(() => {
        return activeClientsResponse?.data?.map(x => ({key: x.guid, text: x.name})) || [];
    }, [activeClientsResponse?.data])
    const onSubmit = (data: ICreateFundCommand) => {
        createFund({
            name: data.name || '',
            abn: data.trustee || '',
            trustee: data.abn || '',
            primaryContactId: data.primaryContactId,
            clientId: activeClientsResponse?.data.find(x => x.guid === clientGuidWatched)?.id.toString() || '',
        });
    };
    const primaryContactOptions = useMemo(() => {
        return contactsResponse?.data.map(x => ({key: x.id, text: `${x.firstName} ${x.lastName}`})) || [];
    }, [contactsResponse?.data, selectedClientId]);

    useEffect(() => {
        if (clientGuidWatched)
            setSelectedClientId(activeClientsResponse?.data?.find(x => x.guid === clientGuidWatched)?.id);
    }, [clientGuidWatched, setSelectedClientId]);


    return (
        <ModalWithForm isOpen={isOpen} onDismiss={onDismiss} title={title} submitDisabled={!formState.isDirty}
                       onSubmit={handleSubmit(onSubmit)}>
            <Stack tokens={{childrenGap: 16}}>
                <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='name'
                                required
                                label={formatMessage({id: 'name'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDropdown
                                options={clientsOptions || []}
                                name='clientId'
                                required
                                label={formatMessage({id: isWorkpapers ? 'trusteeName' : 'clientName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                }}
                                disabled={isActiveClientsLoading}
                            />
                        </Stack.Item>

                        {!isWorkpapers && (
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name='trustee'
                                    required
                                    label={formatMessage({id: 'trustee'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>
                        )}

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='abn'
                                required
                                label={formatMessage({id: 'abn'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>
                        {isWorkpapers && (
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledDropdown
                                    name='primaryContactId'
                                    required
                                    disabled={isContactsLoading}
                                    options={primaryContactOptions || []}
                                    label={formatMessage({id: 'accountant'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </Card>
            </Stack>
        </ModalWithForm>
    );
});
