import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import {FundType} from "../../enums";
import axios from "axios";

type MutateDataType = {
    clientId: number;
    fundId: number;
    type: FundType | null;
}

function useChangeFundType() {
    const context = useContext(Context);

    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.clientUrl}/api/1.0/clients/${data.clientId}/funds/${data.fundId}/type?type=${data.type}`));

    return {update, isUpdating};
}

export {useChangeFundType};