import {IColumn, Stack, useTheme, mergeStyleSets} from '@fluentui/react';
import {SanitizedText} from 'components';
import {IReport} from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {getGrouppedReports} from 'utils';
import {ReportTable} from "../Table/ReportTable";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {isBankRow, isSystemVerifiedRow} from "./consts";
import {isAgreedAnswer, isAutoAnswer, isYesAnswer} from "../../answers";
import {
    GetSummaryItemColor,
    GroupSummaryItem,
    IGroupSummary,
    IGroupSummaryColors,
    IGroupWithSummary
} from "../Table/GroupSummary";
import {ReportTemplateTypeProps} from "../Types";
import {ColumnInfo} from "../../../../enums";
import { _ExtendedHeaderRowHeight, InvestmentIncomeCrsAnswer, ReportValidationCell } from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { useJobContext } from "../../../../JobPortalLayoutPage";
import { useBoolean } from "@fluentui/react-hooks";


export const InvestmentSummary = ({items, isFetching, allowEmptyGroups}: ReportTemplateTypeProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const { job } = useJobContext()
    
    const [isClassSuperPdf] = useBoolean(job.detailedSoftwareType === 'ClassSuperPDF')
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let manualCoverage = 0;
        let systemCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            let name = i.holdingAccountName;
            let isBankItem = isBankRow(i.marketName);

            if (!!name) {
                if ((!isBankItem && isAutoAnswer(i.answerText)) || (isBankItem && isAutoAnswer(i.answerText2))) {
                    systemCoverage += i.market;
                }
                if ((!isBankItem && isAgreedAnswer(i.answerText)) || (isBankItem && isAgreedAnswer(i.answerText2))) {
                    manualCoverage += i.market;
                }
            }

            if (name === '') {
                incomeTotal += i.market;
            }
        })

        const result = {
            manual: incomeTotal != 0 ? manualCoverage / incomeTotal : 0,
            system: incomeTotal != 0 ? systemCoverage / incomeTotal : 0,
            total: 0,
            verified: 0
        }

        result.total = result.manual + result.system;
        return result;
    }
    
    const calculateSummaryColors = (summary: IGroupSummary, name: string, answerText?: any): IGroupSummaryColors => {
        const isSystemVerified = isSystemVerifiedRow(name);
        const colors: IGroupSummaryColors = {
            manual: isSystemVerified ? 'default' : GetSummaryItemColor(summary.manual, answerText),
            system: 'default',
            total: isSystemVerified ? GetSummaryItemColor(summary.total, answerText) : 'default',
            verified: 'default'
        }
        colors.verified = isSystemVerified ? colors.total : colors.manual;
        return colors;
    }
    
    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    
    const {reports, groups} = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: isClassSuperPdf ? 'marketName' : 'glType'}), [items, isClassSuperPdf]);
    const [groupsWithSummaries, setGroupsWithSummaries] = useState<IGroupWithSummary[]>([...groups] as IGroupWithSummary[]);
    
    useEffect(() => {
        setGroupsWithSummaries(prev => [...prev.reduce((acc: IGroupWithSummary[], g) => {
                let children = reports.slice(g.startIndex, g.startIndex + g.count);
                let summary = calculateAnswersSummary(children);
                acc.push({
                    ...g,
                    isCollapsed: g.isCollapsed,
                    summary: {...summary},
                    colors: calculateSummaryColors(summary, g.name, g.data?.answerText)
                });
                return acc;
            }, [])]
        );
    }, [summaryUpdateTrigger])
    
    const getAnswerControlValue = (text?: string): string => !text ? '' : isAgreedAnswer(text) || isAutoAnswer(text) || isYesAnswer(text) ? 'Yes' : 'No';
    
    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(reports, item.id, field, value);
            setSummaryUpdateTrigger(Date.now())
        }
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({id: 'actions'}),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                        <JobComment itemId={item?.id} hasComments={item?.hasComments}/>
                    </Stack>
                ),
            },
            {
                key: 'holdingAccountName',
                name: formatMessage({id: 'investmentName'}),
                minWidth: 200,
                fieldName: 'holdingAccountName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}/>,
            },
            {
                key: 'asx',
                name: formatMessage({id: 'asx'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'asx',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}/>,
            },
            {
                key: 'reviewed1',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {((!!item.isCsPdf && item.marketName === 'Total') || item.glType === 'Total') || (!item.isTotalRow && !isBankRow(item.marketName)) && <GeneratedAnswer itemId={item?.id}
                            auto={isAutoAnswer(item[column?.fieldName || 0])}
                            disabled={false}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={getAnswerControlValue(item[column?.fieldName || 0])}
                            answerControlType={AnswerControlType.WorkDoneSwitch}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)} />}
                    </Stack>
                )
            },
            {
                key: 'units',
                name: formatMessage({id: 'units'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'averageCost',
                name: formatMessage({id: 'averageCostPrice'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'averageCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'reviewed2',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText1',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {(!item.isTotalRow && !isBankRow(item.marketName)) && <InvestmentIncomeCrsAnswer itemId={item?.id} crsInfo={item?.crsInfo}
                                                                                         tableType={TableType[item?.reportType as keyof typeof TableType]}
                                                                                         value={getAnswerControlValue(item[column?.fieldName || 0])}
                                                                                         auto={item?.isAuto1} disabled={item?.isAuto1} columnInfo={ColumnInfo.AnswerText1}
                                                                                         />}
                    </Stack>
                )
            },
            {
                key: 'marketPrice',
                name: formatMessage({id: 'marketPrice'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketPrice',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'cost',
                name: formatMessage({id: 'cost'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'reviewed3',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText2',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {!!item?.holdingAccountName && item?.glType !== 'Total' && <GeneratedAnswer itemId={item?.id}
                                                                                                    tableType={TableType[item?.reportType as keyof typeof TableType]}
                                                                                                    value={getAnswerControlValue(item[column?.fieldName || 0])}
                                                                                                    auto={item?.isAuto2} disabled={item?.isAuto2} columnInfo={ColumnInfo.AnswerText2}
                                                                                                    answerControlType={AnswerControlType.WorkDoneSwitch}
                                                                                                    onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}/>}
                    </Stack>
                )
            },
            {
                key: 'market',
                name: formatMessage({id: 'marketValue'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'market',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'unrealisedGain',
                name: formatMessage({id: 'unrealisedGain'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unrealisedGain',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'gainPercentage',
                name: formatMessage({id: 'gainPercentage'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'gainPercentage',
                onRender: (item, _, column) => <SanitizedText data={(+item[column?.fieldName || 0] / 100).toString()}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='percentage-fractional'/>,
            },
            {
                key: 'totalPercentage',
                name: formatMessage({id: 'totalPercentage'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalPercentage',
                onRender: (item, _, column) => <SanitizedText data={(+item[column?.fieldName || 0] / 100).toString()}
                                                              isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format}
                                                              textAlign='right' numberFormat='percentage-fractional'/>,
            },
            {
                key: 'agreedToInvestmentStrategy',
                name: formatMessage({id: 'agreedToInvestmentStrategy'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText3',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {!item?.holdingAccountName && <GeneratedAnswer itemId={item?.id}
                                                                       tableType={TableType[item?.reportType as keyof typeof TableType]}
                                                                       value={getAnswerControlValue(item[column?.fieldName || 0])} columnInfo={ColumnInfo.AnswerText3}
                                                                       answerControlType={AnswerControlType.WorkDoneSwitch}/>}
                    </Stack>
                )
            },
            {
                key: 'validation',
                name: formatMessage({id: 'validation'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );
    
    const groupSummaryRenderer = (group: IGroupWithSummary): React.ReactNode => {
        
        const summary = group.summary;
        const colors = group.colors;
        return <>
            {
                group?.name && group?.name?.indexOf("Total") === -1 &&
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <GroupSummaryItem value={summary.manual} color={colors?.manual} type={'manual'}></GroupSummaryItem>
                    </Stack.Item>
                    {isSystemVerifiedRow(group?.name) &&
                        <>
                            <Stack.Item>
                                <GroupSummaryItem value={summary.system} color={colors?.system} type={'system'}></GroupSummaryItem>
                            </Stack.Item>
                            <Stack.Item>
                                <GroupSummaryItem value={summary.total} color={colors?.total} type={'total'}></GroupSummaryItem>
                            </Stack.Item>
                        </>
                    }
                    <Stack.Item>
                        <SanitizedText color={colors?.verified === 'red' ? theme.palette.red : colors?.verified === 'green' ? theme.palette.green : 'inherit'} 
                                       data={'% ' + formatMessage({id: 'verified'})}
                                       format={'Bold'}
                        />
                    </Stack.Item>
                </Stack>
            }
        </>
    }

    return <ReportTable
        initialColumns={columns}
        openAll={true}
        groups={groupsWithSummaries}
        header={{ rowHeight: _ExtendedHeaderRowHeight }}
        groupProps={{ summary: {
            show: true,
            childRenderer: groupSummaryRenderer
        }}}
        items={reports}
        isFetching={isFetching}
    />;
};
