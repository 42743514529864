import {FunctionComponent} from "react";
import {CreditNoteStatusType} from "../enums";
import {Badge, BadgeType} from "../../../../../components";

interface ICreditNotesStatusProps {
    status: CreditNoteStatusType;
}

export const CreditNoteStatus: FunctionComponent<ICreditNotesStatusProps> = ({ status }: ICreditNotesStatusProps) => {
    let statusColor: BadgeType;
    let statusName: string = CreditNoteStatusType[status];

    switch (status) {
        case CreditNoteStatusType.Open:
            statusColor = 'success';
            break;
        case CreditNoteStatusType.Closed:
            statusColor = 'danger';
            break;
        default:
            statusColor = 'neutral';
            statusName = 'Undefined';
            break;
    }

    return (
        <Badge text={statusName} fullWidth type={statusColor}/>
    );
};