import {useIntl} from "react-intl";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {RouterPaths} from "navigation";
import {PageWithBreadcrumb} from "components";

export const AdministrationDashboardsLayoutPage = () => {

    const {formatMessage} = useIntl();
    const location = useLocation();
    const navigation = useNavigate();

    useEffect(() => {
        if (location.pathname === '/administration') {
            navigation(RouterPaths.administration.dashboards);
        }
    }, [location.pathname, navigation]);

    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'dashboards',
                key: RouterPaths.administration.dashboards,
                text: formatMessage({id: 'dashboards'}),
            }}>
            <Outlet/>
        </PageWithBreadcrumb>
    );
}
