import {Stack} from '@fluentui/react';
import {useContext, useEffect, useState} from 'react';
import {Pagination} from 'components';
import {ExportType, useExportData} from 'hooks';
import {Context} from 'utils';
import {useIntl} from "react-intl";
import {IClientPaginationFilter, useGetClientByFilter} from "./hooks";
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {ClientsAddItemModal, ClientsDataTable} from "./components";
import {DataTableFilter} from '../shared';


export const ClientsWorkflowPage = () => {

    const context = useContext(Context);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const {formatMessage} = useIntl();
    const [filters, setFilters] = useState<IClientPaginationFilter>({
        pageSize: 10,
        page: 1,
        orderBy: 'Name',
        orderDirection: 'asc',
        status: EntityStatus.Active,
    });


    const {dataClientsFilter: clientsData, isLoadingClientsFilter} = useGetClientByFilter(filters);
    const {exportData} = useExportData(`${context?.config?.clientUrl}/api/1.0/Clients`, filters);

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters(prev => ({...prev, page: page, pageSize: pageSize}));
    };
    const [pagination, setPagination] = useState<{ itemsCount: number; total: number }>();

    useEffect(() => {
        if (clientsData?.data) {
            setPagination({
                itemsCount: clientsData?.data.shownItemsCount,
                total: clientsData?.data.totalItemsCount,
            });
        }
    }, [clientsData?.data]);

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <DataTableFilter onAddClick={() => setShowAddModal(true)}
                                 search={{
                                     onSearch: (newValue: string) => setFilters(prev => ({...prev, name: newValue})),
                                     onClear: () => setFilters(prev => ({...prev, name: ''}))
                                 }}
                                 status={{
                                     selectedStatus: filters.status || null,
                                     changeStatus: (newStatus: EntityStatus) => setFilters(prev => ({
                                         ...prev,
                                         status: newStatus
                                     }))
                                 }}
                                 export={{
                                     onPdfClick: () => exportData(ExportType.Pdf),
                                     onExcelClick: () => exportData(ExportType.Excel)
                                 }}
                />
                <ClientsAddItemModal
                    isOpen={showAddModal}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({id: 'addingNewItem'})}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <ClientsDataTable clientItems={clientsData?.data.items || []}
                                  isLoading={isLoadingClientsFilter}
                                  setSort={value => setFilters(prev => ({
                                      ...prev,
                                      orderBy: value.orderBy,
                                      orderDirection: value.orderDirection
                                  }))}/>
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Pagination
                    itemsCount={clientsData?.data.shownItemsCount}
                    total={pagination?.total}
                    page={filters.page}
                    pageSize={filters.pageSize}
                    onChange={onPaginationChange}
                />
            </Stack.Item>
        </Stack>
    );
};
