import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Stack } from '@fluentui/react';
import { FeeCalculationProcedureTemplate } from 'pages/JobPortal/components/templates/procedures';


export const FeeCalculationV2ProcedureTemplate: FunctionComponent = ({ ...props }) => {
  const { formatMessage } = useIntl();
  
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack>
        <Stack horizontal styles={{ root: { width: '80%', textAlign: 'right' } }}>
          <Stack.Item styles={{ root: { fontWeight: 500 } }}>{formatMessage({ id: 'lastEditedDate' })}:</Stack.Item>
          <Stack.Item></Stack.Item>
        </Stack>
        <Stack horizontal styles={{ root: { width: '80%', textAlign: 'right' } }}>
          <Stack.Item styles={{ root: { fontWeight: 500 } }}>{formatMessage({ id: 'lastCalculatedDate' })}:</Stack.Item>
          <Stack.Item></Stack.Item>
        </Stack>
      </Stack>
      <FeeCalculationProcedureTemplate {...props} />
    </Stack>
  );
};

