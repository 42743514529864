import {useState} from 'react';
import {ActionButton, IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {BaseDialog, Card, DataTable, ITableColumn} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {IOrderedRequest} from "interfaces";
import {useUpdateFundsStatus} from "../../hooks";
import {EntityStatus, FundInfo} from "pages/WorkflowPortal/shared";
import {useWorkflows} from "pages/WorkflowPortal";
import {useTenantInfo} from "../../../../../providers";

type FundPropsType = {
    fundData: FundInfo[] | [];
    isLoading?: boolean;
    setSort: (value: IOrderedRequest) => void;
};

export const FundsDataTable = ({fundData, isLoading, setSort}: FundPropsType) => {
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const {showClientModal, showFundModal} = useWorkflows();

    //UPDATE FUND STATUS
    const [currentActivatedUser, setCurrentActivatedUser] = useState<{
        clientId: number;
        status: EntityStatus;
        fundId: number;
    } | null>(null);
    const {updateFundStatus, isLoadingUpdateFundStatus} = useUpdateFundsStatus(() => setCurrentActivatedUser(null));
    const handleChangeClientStatus = () => {
        if (currentActivatedUser) {
            updateFundStatus({
                clientId: currentActivatedUser?.clientId,
                status: currentActivatedUser?.status === EntityStatus.Active ? EntityStatus.DeActive : EntityStatus.Active,
                fundId: currentActivatedUser.fundId,
            });
        }
    };

    const theme = useTheme();

    const [columns] = useState<ITableColumn[]>([
        {
            key: 'commands',
            name: formatMessage({ id: 'commands' }),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            onRender: (items: FundInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={{ root: { fontWeight: 500 } }}
                    onClick={() => setCurrentActivatedUser({
                        fundId: items.id,
                        status: items.status,
                        clientId: items.clientId
                    })}
                >
                    {items.status === EntityStatus.Active ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'Name',
            onRender: (items: FundInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    onClick={() =>
                        showFundModal(
                            items.id,
                            items.clientId,
                            items.guid, items.clientGuid)
                    }
                >
                    {items.name}
                </Link>
            ),
        },
        {
            key: 'client',
            name: formatMessage({ id: isWorkpapers ? 'trustee' : 'client' }),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'Client.Name',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue}} onClick={() => {
                    showClientModal(items.clientGuid);
                }}>
                    {items.clientName || ''}
                </Link>
            ),
        },
        {
            key: 'primaryContact',
            name: formatMessage({id: isWorkpapers ? 'accountant' : 'primaryContact'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'Client.AdditionalInfo.PrimaryContact.FirstName',
            onRender: (items) => <Text>{items.primaryContact || ''}</Text>,
        },
        {
            key: 'trustee',
            name: formatMessage({id: 'trustee'}),
            minWidth: 150,
            maxWidth: 300,
            skip: isWorkpapers,
            fieldName: 'AdditionalInfo.Trustee',
            onRender: (items) => <Text>{items.trustee || ''}</Text>,
        },
        {
            key: 'abn',
            name: formatMessage({id: 'abn'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'AdditionalInfo.Abn',
            onRender: (items) => <Text>{items?.abn || ''}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                compact
                sortableColumnKeys={['name', 'client', 'primaryContact', 'trustee', 'abn']}
                initialColumns={columns}
                items={fundData}
                enableShimmer={isLoading}
                setOrder={setSort}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            {!!currentActivatedUser?.clientId && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={handleChangeClientStatus}
                    toggleHidden={() => setCurrentActivatedUser(null)}
                    noDisabled={isLoadingUpdateFundStatus}
                    yesDisabled={isLoadingUpdateFundStatus}
                />
            )}


        </Card>
    );
};
