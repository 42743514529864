"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConnection = exports.isConnectionConnecting = void 0;
const socket_io_client_1 = require("socket.io-client");
function isConnectionConnecting(connection) {
    return connection.connected;
}
exports.isConnectionConnecting = isConnectionConnecting;
function createConnection(url, opts) {
    const connectionBuilder = (0, socket_io_client_1.io)(url, opts);
    const connection = connectionBuilder;
    return connection;
}
exports.createConnection = createConnection;
