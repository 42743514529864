import {Context} from "../../../../utils";
import {useContext} from "react";
import {useMutation} from "react-query";
import axios from "axios";
import {ReviewType} from "../../enums";

type MutateDataType = {
    jobId: number;
    sectionId?: number;
    itemId?: number;
    reviewType: ReviewType;
}

function useCreateReviewItem() {
    const context = useContext(Context);
    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.proceduresUrl}/api/1.0/procedures/jobReview`, data));

    return {create, createAsync, isCreating};
}

export {useCreateReviewItem};