import {
    Callout,
    DirectionalHint, Label, Link,
    mergeStyleSets,
    Spinner, SpinnerSize,
    Stack,
    StackItem,
    Target,
    Text, useTheme, VerticalDivider
} from '@fluentui/react';
import {FunctionComponent, useMemo, useState} from 'react';
import {useGetJobByGuid} from "../../../../hooks/useGetJobByGuid";
import {IAttachment, useGetJobAttachments, useGetJobComments} from "../../hooks";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {SanitizedText} from "../../../../components";
import {useGetFile} from "../../../../hooks";
import {doc} from "prettier";


interface IJobCommentPreviewProps {
    itemId: number;
    target?: Target;
    tableType: number;
    isPointerOver: boolean;
    onPointerEnter?: Function;
    onPointerLeave?: Function;
}

type LinkedDocuments = { forPermanents: IAttachment[], forComments: IAttachment[] }

export const JobCommentPreview: FunctionComponent<IJobCommentPreviewProps> = ({
                                                                                  itemId,
                                                                                  target,
                                                                                  tableType, onPointerEnter, onPointerLeave,
                                                                              }) => {

    const {formatMessage} = useIntl();
    const {guid} = useParams();
    const {dataJobs} = useGetJobByGuid({guid});

    const {commentsData, isCommentsLoading} = useGetJobComments({
        jobId: dataJobs?.data?.id,
        fundId: dataJobs?.data?.fund?.id,
        itemId,
        tableType: tableType,
        year: dataJobs?.data?.year,
    });

    const {attachmentsData, isAttachmentsLoading, isJobAttachmentsFetching} = useGetJobAttachments({
        jobId: dataJobs?.data?.id,
        fundId: dataJobs?.data?.fund?.id,
        year: dataJobs?.data?.year,
    });

    const linkedDocuments: LinkedDocuments = useMemo((): LinkedDocuments => {   
        console.log((commentsData?.data?.comment?.documents ?? []).filter(x => !!x.attachment).map(x => x.attachment!));    
        return {
            forPermanents: (commentsData?.data?.permanentComment?.documents ?? []).filter(x => !!x.attachment).map(x => x.attachment!),
            forComments: (commentsData?.data?.comment?.documents ?? []).filter(x => !!x.attachment || attachmentsData?.data.some(a => a.id == x.attachmentId)).map(x => x.attachment ?? attachmentsData?.data.find(a => a.id == x.attachmentId)!),
        }
        
    }, [commentsData, attachmentsData])

    const styles = mergeStyleSets({
        header: {
        },
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 350,
            maxWidth: 500,
            padding: '20px 24px',
        },
    });

    const theme = useTheme();
    
    return (
        <>
            <Callout
                className={styles.callout}
                target={`#${target}`}
                onPointerEnter={() => onPointerEnter?.()}
                onPointerLeave={() => onPointerLeave?.()}
                onDismiss={() => {}}
                gapSpace={3}
                role='alert'
                directionalHint={DirectionalHint.rightCenter}>
                {isCommentsLoading || isAttachmentsLoading ? (
                    <Spinner />
                ) : (
                    <Stack grow tokens={{ childrenGap: 16 }}>
                        {!!commentsData?.data?.externalPermanentComment && (
                            <>
                                <StackItem className={styles.header}>
                                    <Label theme={theme.schemes?.default}>
                                        {formatMessage({ id: 'permanentCommentForFutureJobsWorkpapers' })}
                                    </Label>
                                </StackItem>
                                <StackItem>
                                    <SanitizedText
                                        theme={theme.schemes?.default}
                                        data={commentsData?.data?.externalPermanentComment?.htmlText ?? ''}
                                    />
                                </StackItem>
                                <StackItem>
                                    <VerticalDivider />
                                </StackItem>
                            </>
                        )}
                        {!!commentsData?.data?.externalComment && (
                            <>
                                <StackItem className={styles.header}>
                                    <Label theme={theme.schemes?.default}>{formatMessage({ id: 'currentYearCommentWorkpapers' })}</Label>
                                </StackItem>
                                <StackItem>
                                    <SanitizedText theme={theme.schemes?.default} data={commentsData?.data?.externalComment?.htmlText ?? ''} />
                                </StackItem>
                                <StackItem>
                                    <VerticalDivider />
                                </StackItem>
                            </>
                        )}
                        <StackItem className={styles.header}>
                            <Label theme={theme.schemes?.default}>{formatMessage({ id: 'permanentCommentForFutureJobs' })}</Label>
                        </StackItem>
                        <StackItem>
                            <SanitizedText theme={theme.schemes?.default} data={commentsData?.data?.permanentComment?.htmlText ?? ''} />
                        </StackItem>
                        {linkedDocuments.forPermanents.length && (
                            <Stack.Item>
                                <JobCommentPreviewDocuments documents={linkedDocuments.forPermanents} />
                            </Stack.Item>
                        )}
                        <StackItem>
                            <VerticalDivider />
                        </StackItem>
                        <StackItem className={styles.header}>
                            <Label theme={theme.schemes?.default}>{formatMessage({ id: 'currentYearComment' })}</Label>
                        </StackItem>
                        <StackItem>
                            <SanitizedText theme={theme.schemes?.default} data={commentsData?.data?.comment?.htmlText ?? ''} />
                        </StackItem>
                        {linkedDocuments.forComments.length && (
                            <Stack.Item>
                                <JobCommentPreviewDocuments documents={linkedDocuments.forComments} />
                            </Stack.Item>
                        )}
                    </Stack>
                )}
            </Callout>
        </>
    );
};


type JobCommentPreviewDocumentsProps = {
    documents: IAttachment[];
}

const JobCommentPreviewDocuments: FunctionComponent<JobCommentPreviewDocumentsProps> = ({ documents, ...props}: JobCommentPreviewDocumentsProps) => {

    const { formatMessage } = useIntl()
    const theme = useTheme();
    
    const {getFile, isExportDownloading} = useGetFile();

    const [downloadingId, setDownloadingId] = useState<number>();
    
    return (
        <>
            <Stack tokens={{ childrenGap: 8 }}>
                {documents.map((s, i) => (
                    <Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Stack.Item>
                                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                                      onClick={() => {
                                          setDownloadingId(s.id)
                                          getFile({url: s.downloadUrl, fileName: s.fileName}, { onSettled: () => setDownloadingId(undefined) })
                                      }}>{s.fileName}</Link>
                            </Stack.Item>
                            <Stack.Item>
                                {isExportDownloading && downloadingId === s.id && (
                                    <Spinner labelPosition={"right"} size={SpinnerSize.small}/>)}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                ))}
            </Stack>
        </>
    )
}