import {ControlledTextField, ModalWithForm} from "components";
import {useGetTeamInfo, useRenameTeam} from "../../hooks";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "constants/forms";
import {useIntl} from "react-intl";
import {useEffect} from "react";
import {Stack} from "@fluentui/react";

type TeamModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    teamId: number;
}

interface IChangeTeamCommand {
    name: string;
}

export const TeamModal = (props: TeamModalProps) => {
    const {formatMessage} = useIntl();

    const {response: teamInfoRes, isLoading} = useGetTeamInfo(props.teamId);
    const {update: renameTeam, isUpdating: isRenaming} = useRenameTeam();
    const {control, reset, formState, setValue, handleSubmit} = useForm<IChangeTeamCommand>({
        ...DefaultFormSettings,
        defaultValues: {name: ''}
    });

    const submitFrom = (data: IChangeTeamCommand) => {
        renameTeam({
            teamId: props.teamId,
            name: data.name
        });
    }

    const closeModal = () => {
        props.onDismiss();
        reset();
    };

    useEffect(() => {
        if (!teamInfoRes?.data)
            return;

        setValue('name', teamInfoRes.data.name);

    }, [teamInfoRes?.data]);


    return <ModalWithForm
        width={300}
        minWidth={'30%'}
        isSubmitting={isRenaming}
        onDismiss={closeModal}
        title={props.title || formatMessage({id: 'team'})}
        isLoading={isLoading}
        closeAfterSubmit={true}
        isOpen={props.isOpen}
        onSubmit={handleSubmit(submitFrom)}
        submitDisabled={!formState.isDirty || !formState.isValid}>
        <Stack styles={{root: {width: '100%'}}}>
            <ControlledTextField name={"name"} control={control} required
                                 label={formatMessage({id: 'name'})}
                                 rules={{required: formatMessage({id: 'requiredField'})}}/>
        </Stack>
    </ModalWithForm>
}
