import {TableType} from "../../../enums";
import {IValidationItem} from "./IValidation";

type ReportAny = {
    [key: string]: unknown,
}

export type IReport = {
    id: number,
    children: IReport[],
} & ReportAny

export interface IReportInfo {
    id: number;
    jobId: number;
    tabSectionId: number;
    managerApproval: boolean;
    partnerApproval: boolean;
    sendEmail: boolean;
    isPositive: boolean | null;
    reports: {
        reportType: TableType;
        report: IReport[]
    }[];
}

export enum ValidationType {
    NotSet,
    PriorYear
}

export interface IReportValidationItem {
    validationId: number;
    validationType: ValidationType;
    isValid: boolean;
    validationItems: IValidationItem[];
    answerText: string;
}