import React, {Component, RefObject} from 'react';
import {
    AtomicBlockUtils,
    CompositeDecorator,
    ContentBlock,
    ContentState,
    DraftBlockType,
    DraftHandleValue,
    DraftStyleMap,
    Editor,
    EditorState,
    RichUtils,
} from 'draft-js';
import {convertFromHTML, convertToHTML} from 'draft-convert';
import 'draft-js/dist/Draft.css';
import {Label, Stack, Theme} from '@fluentui/react';
import {RichTextEditorMenu} from './RichTextEditorMenu';
import {RichTextLink} from './RichTextLink';
import {RichTextImage} from './RichTextImage';
import {RichTextFieldWrap} from './RichTextFieldWrap';

interface Props {
    value: string;
    label?: string;
    placeholder?: string;
    errorMessage?: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    hideMenu?: boolean;
    disable?: boolean;
    theme?: Theme;
}

interface State {
    editorState: EditorState;
}

export class RichTextEditor extends Component<Props, State> {
    private editorRef: RefObject<Editor>;

    constructor(props: Props) {
        super(props);
        this.editorRef = React.createRef();

        const decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: RichTextLink,
            },
            {
                strategy: findImageEntities,
                component: RichTextImage,
            },
        ]);
        // const blockArray = convertFromHTML(props.value);
        const editorState = EditorState.createEmpty(decorator);
        this.state = { editorState: EditorState.push(editorState, convertFromHTML(props.value), 'insert-characters') };
        this.focus = this.focus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setEditor = this.setEditor.bind(this);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.toggleBlockType = this.toggleBlockType.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
        this.undo = this.undo.bind(this);
        this.redo = this.redo.bind(this);
        this.toggleLink = this.toggleLink.bind(this);
        this.pastImage = this.pastImage.bind(this);
    }

    public render() {
        const { editorState } = this.state;

        return (
            <Stack>
                <Label styles={{ root: {color: this.props.theme?.schemes?.default?.semanticColors.bodyText}}} onClick={this.focus}>{this.props.label}</Label>
                <RichTextFieldWrap hasErrors={!!this.props.errorMessage}>
                    {this.props.hideMenu ? null : (
                        <RichTextEditorMenu
                            onRedo={this.redo}
                            onUndo={this.undo}
                            onToggleBlockType={this.toggleBlockType}
                            onToggleInlineStyle={this.toggleInlineStyle}
                            onToggleLink={this.toggleLink}
                            onPastImage={this.pastImage}
                        />
                    )}

                    <Editor
                        ref={this.editorRef}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        placeholder={this.props.placeholder}
                        spellCheck={true}
                        onBlur={this.props.onBlur}
                        readOnly={this.props.disable}
                    />
                </RichTextFieldWrap>
            </Stack>
        );
    }

    private focus() {
        this.editorRef.current?.focus();
    }

    private setEditor(editorState: EditorState) {
        this.setState({ editorState }, () => {
            setTimeout(() => this.focus(), 0);
        });
    }

    private onChange(editorState: EditorState) {
        this.setState({ editorState });
        this.props.onChange(convertToHTML(this.state.editorState.getCurrentContent()));
    }

    private handleKeyCommand(command: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.setEditor(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    private pastImage(data: { src: string; height: number; width: number }) {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('IMAGE', 'MUTABLE', data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        this.setEditor(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    }

    private toggleLink(url?: string, target?: '_blank' | '_self') {
        if (!url) {
            this.removeLink();
        }
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url, target });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        this.setEditor(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    }

    private removeLink() {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            this.setEditor(RichUtils.toggleLink(editorState, selection, null));
        }
    }

    private undo() {
        this.setEditor(EditorState.undo(this.state.editorState));
    }

    private redo() {
        this.setEditor(EditorState.redo(this.state.editorState));
    }

    private toggleBlockType(blockType: DraftBlockType) {
        this.setEditor(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    private toggleInlineStyle(inlineStyle: string) {
        this.setEditor(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    }
}

// Custom overrides for "code" style.
const styleMap: DraftStyleMap = {
    STRIKETHROUGH: {
        textDecoration: 'line-through',
    },
    SUPERSCRIPT: {
        verticalAlign: 'super',
        fontSize: 'smaller',
    },
    SUBSCRIPT: {
        verticalAlign: 'sub',
        fontSize: 'smaller',
    },
    ALIGNMENT_LEFT: {
        textAlign: 'left',
        display: 'inline-block',
        width: '100%',
    },
    ALIGNMENT_CENTER: {
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
    },
    ALIGNMENT_RIGHT: {
        textAlign: 'right',
        display: 'inline-block',
        width: '100%',
    },
    ALIGNMENT_JUSTIFY: {
        textAlign: 'justify',
        display: 'inline-block',
        width: '100%',
    },
};

function findLinkEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
}

function findImageEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
}
