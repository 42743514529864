import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {IDropdownOption, Label, Stack, TextField, useTheme} from "@fluentui/react";
import {useIntl} from "react-intl";
import {FundType} from "../../../enums";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {useChangeFundType, useGetDispatchInfoByJobId} from "../../../hooks";
import {logger} from "../../../../../services";
import {DropdownAnswer} from "../answers";
import {Loading} from "../../../../../components";
import {useGetGeneralSettings} from "../../../../ClientPortal/PortalSettings/hooks/General/useGetGeneralSettings";
import {useGetGeneralTitle} from "../../../../ClientPortal/PortalSettings/hooks/General/useGetTitle";
import {format} from "date-fns";
import {formatDate} from "../../../../../utils";
import {useTabContext} from "../../../JobPortalPage";


export const FundDetailsTemplate = forwardRef((props, ref) => {

    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {generalTitle, isLoading: isLoadingTitle} = useGetGeneralTitle();
    const {isTabEnabled} = useTabContext();
    const {formatMessage} = useIntl();
    const {job, jobId} = useJobContext();
    const {response, isLoading, refetch, isRefetching} = useGetDispatchInfoByJobId(jobId);
    const {update, isUpdating} = useChangeFundType();
    const onChangeFundType = useCallback((data: any) => {
        update({
            clientId: job.client.id,
            fundId: job.fund.id,
            type: data
        });
    }, []);


    const [fundTypeOptions] = useState<IDropdownOption[]>(
        Object
            .keys(FundType)
            .filter((x) => Number.isNaN(Number(x)))
            .map((c => ({
                key: c.toString(),
                text: formatMessage({id: `fundType_${c.toString() || "unknown"}`}), 
            }))));

    useImperativeHandle(ref, () => ({
        refresh() {
            refetch();
        }
    }));

    const theme = useTheme();

    if (isLoading || isLoadingTitle || isRefetching)
        return <Loading/>;

    return (
        <Stack horizontal tokens={{childrenGap: 16}} wrap>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_name'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.fundName} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_trustee'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.fundTrustee} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_abn'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.fundAbn} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_admin-accountant'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.clientName} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_address'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.addressLine1} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_address'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.addressLine2 || ''} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_year'})}</Label>
                <TextField theme={theme.schemes?.default} value={job?.year} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_dateAuditCompleted'})}</Label>
                <TextField theme={theme.schemes?.default} value={formatDate(response?.data?.auditFinalised || '')} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Label theme={theme.schemes?.default}>{formatMessage({id: 'fundDetails_auditingEntity'})}</Label>
                <TextField theme={theme.schemes?.default} value={response?.data?.auditingEntity || generalTitle?.data.companyTitle} disabled readOnly/>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <DropdownAnswer theme={theme.schemes?.default}
                                options={fundTypeOptions}
                                value={response?.data?.fundType}
                                onChange={onChangeFundType}
                                disabled={isLoading || isRefetching || isUpdating}
                                label={formatMessage({id: 'fundDetails_typeOfFund'})}/>
            </Stack.Item>
        </Stack>
    );
});