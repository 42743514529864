import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

type UpdateTitleReq = {
    companyTitle: string;
};

/**Mutation query for updating General Title data in Portal Settings*/

function useUpdateTitle() {
    const context = React.useContext(Context);

    const { mutate: updateTitle, isLoading: updateTitleLoading } = useMutation(
        (data: UpdateTitleReq) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/web`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getGeneralTitle'),
        }
    );

    return { updateTitle, updateTitleLoading };
}

export { useUpdateTitle };
