import {useProcedureContext} from "../../../ProceduresContent";
import {ITableColumn} from "../../../../../../components";
import {List, mergeStyleSets, normalize, Stack, useTheme} from "@fluentui/react";
import {JobComment} from "../../../JobComment";
import {JobOmls} from "../../../OmlComponent";
import {IAuditCompletionLetterItem} from "./interfaces";
import {AuditCompletionLetterPanelTemplate} from "./AuditCompletionLetterPanelTemplate";
import {forwardRef, useEffect, useState} from "react";
import {ProceduresDataTable} from "../../../ProceduresDataTable";
import {Attachment} from "../../../Attachment";
import {useSectionContext} from "../../../Section";

export const AuditCompletionLetterTemplate = forwardRef((props, ref) => {
    const {refresh, section} = useSectionContext()
    const {items, isLoading} = useProcedureContext();
    const theme = useTheme();

    const [completionItems, setCompletionItems] = useState<IAuditCompletionLetterItem[]>();
    const [omlDisabled, setOmlDisabled] = useState<boolean>(false);

    const styles = mergeStyleSets({
        container: {
            overflow: 'auto',
            maxHeight: 400,
            '.ms-List-cell': {
                minHeight: 'fit-content',
                marginBottom: 6
            },
            '.ms-List-page': {
                borderBottom: 'none'
            }
        },
        itemContent: [
            theme.fonts.medium,
            normalize,
            {
                position: 'relative',
                boxSizing: 'border-box',
                display: 'block',
                borderRadius: '0px 2px 2px 0px',
                borderLeft: '2px solid ' + theme.palette.themeTertiary,
                paddingLeft: 16,

            },
        ],
    });

    const renderListItem = (item?: string, index?: number, isScrollabel?: boolean) => {
        return (
            <div key={`audit-completion-${index}`} className={styles.itemContent}>
                {item}
            </div>
        );
    }
    const columns: ITableColumn[] = [
        {
            key: 'actions',
            name: '',
            fieldName: '',
            onRender: (item: IAuditCompletionLetterItem) => {
                return (
                    <Stack horizontal horizontalAlign='center'>
                        {item.showComment && (<JobComment key={`audit-completion-comments-${item.id}`} itemId={item.id}
                                                          hasComments={item.hasComments}/>)}
                        {item.showOML && (<JobOmls key={`audit-completion-oml-${item.id}`}
                                                   disabled={omlDisabled} tableType={item.tableType}
                                                   itemId={item.id}
                                                   onAfterSubmit={_ => {
                                                       refresh?.()
                                                   }}
                                                   showRaisedItems={false}
                                                   showNonWitholdingColumn={false}
                                                   hasOmls={item.hasOml}/>)}
                        {item.showAttachment && (
                            <Attachment key={`audit-completion-attachments-${item.id}`} itemId={item.id}
                                        hasAttachments={item.hasAttachment}/>)}
                    </Stack>
                )
            },
            minWidth: 95,
            maxWidth: 95
        },
        {
            key: 'oml',
            name: '',
            fieldName: 'oml',
            minWidth: 150,
            onRender: (item: IAuditCompletionLetterItem) => {
                return (
                    <Stack grow className={styles.container}>
                        <List onShouldVirtualize={() => false}
                              items={item.omlItems || []}
                              ignoreScrollingState
                              onRenderCell={renderListItem}/>
                    </Stack>
                )
            }
        }
    ];

    useEffect(() => {
        setCompletionItems(items);
        setOmlDisabled(items.every(x => x.answerText === 'No'))
    }, [items]);

    useEffect(() => {
        setOmlDisabled(!!section.sectionAnswer?.managerApproval && !!section.sectionAnswer?.partnerApproval);
    }, [section.sectionAnswer]);


    return (
        <>
            {completionItems?.map((value, index) => {
                return <AuditCompletionLetterPanelTemplate ref={ref} key={`completion-panel-${index}`} item={value}
                                                           onAnswerUpdate={answer => setOmlDisabled(answer === 'No')}/>;
            })}
            <ProceduresDataTable items={completionItems || []}
                                 columns={columns}
                                 isHeaderVisible={false}/>
        </>
    );
});