import React from 'react';
import { PropsWithChildren } from 'react';

type DisabledChildrenProps = {
    disabled: boolean;
};

export const DisabledChildren = ({ disabled, children }: PropsWithChildren<DisabledChildrenProps>) => {
    const disabledChildren = (children: React.ReactNode, disabled: boolean): React.ReactNode => {
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                const { props } = child;
                const newProps = { ...props, disabled: disabled };

                // Type guard for Class Components
                return React.cloneElement(child, newProps, disabledChildren(props.children, true));
            }

            return child;
        });
    };

    return (<>{disabledChildren(children, disabled)}</>);
};
