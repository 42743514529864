import { IconButton, IDropdownOption, mergeStyleSets, Spinner, SpinnerSize, Stack, TextField, useTheme } from '@fluentui/react';
import { DefaultFormSettings } from 'constants/forms';
import { useForm } from 'react-hook-form';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { JobDetailsTemplate } from './components/JobDetails';
import { useGetJobYears } from './hooks';
import { ControlledDropdown } from '../../components';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../navigation';

type PropsType = {
    dataClient?: any;
    dataYears?: { field: string; value: string }[];
    toggleDetails: () => void;
    detailsOpened: boolean;
};

export const UpperMenu = ({ dataClient, toggleDetails, detailsOpened }: PropsType) => {
    const theme = useTheme();

    const classNames = mergeStyleSets({
        stack: [
            {
                minWidth: '75em',
            },
        ],
    });

    const stackItemStyles = { root: { width: '100%' } };

    const textFieldStyles = {
        root: {
            selectors: { '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText } },
        },
        field: {
            '-webkit-box-shadow': `inset 0 0 0 50px ${theme.palette.white}`,
            '-webkit-text-fill-color': theme.schemes?.default?.semanticColors.inputText,
        },
        fieldGroup: {
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            selectors: { '&:hover': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } },
        },
    };

    return (
        <Stack className={classNames.stack} tokens={{ childrenGap: 16 }}>
            <Stack grow horizontal verticalAlign={'center'} horizontalAlign={'start'} tokens={{ childrenGap: 16 }}>
                <IconButton iconProps={{ iconName: 'Info' }} onClick={toggleDetails} />
                <Stack.Item styles={stackItemStyles}>
                    <TextField styles={textFieldStyles} readOnly name={'fundName'} value={dataClient?.data.fund.name} />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <TextField styles={textFieldStyles} readOnly name={'trusteeName'} value={dataClient?.data?.trustee} />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <TextField styles={textFieldStyles} readOnly name={'clientName'} value={dataClient?.data?.client?.name} />
                </Stack.Item>
                <Stack.Item>
                    <JobYearsTemplate id={dataClient?.data.id!} />
                </Stack.Item>
            </Stack>
            {detailsOpened && <JobDetailsTemplate />}
        </Stack>
    );
};

interface IJobYearsProps {
    id: number;
}
const JobYearsTemplate: FunctionComponent<IJobYearsProps> = ({ id }: IJobYearsProps) => {
    const navigate = useNavigate();
    const { jobYears, isLoading } = useGetJobYears(id);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { control, setValue } = useForm<{ current: string }>({
        ...DefaultFormSettings,
        mode: 'onChange',
    });

    const onValueChange = (value: IDropdownOption) => {
        if (value.key) {
            navigate(RouterPaths.job.root(value.key));
        }
    };

    const options = useMemo<IDropdownOption[]>(
        () =>
            (jobYears ?? []).map(
                (x) =>
                    ({
                        key: x.guid,
                        text: x.year.toString(),
                    } as IDropdownOption)
            ),
        [jobYears]
    );

    useEffect(() => {
        const current = (jobYears ?? []).find((x) => x.isCurrent);
        if (current) {
            setValue('current', current.guid);
        }

        setIsDisabled(!jobYears || !jobYears.length);
    }, [jobYears]);

    return (
        <>
            <Stack>
                <Stack.Item>
                    {isLoading && <Spinner size={SpinnerSize.small} />}
                    {!isLoading && (
                        <ControlledDropdown
                            name={'current'}
                            disabled={isDisabled}
                            options={options}
                            onValueChange={onValueChange}
                            control={control}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </>
    );
};
