import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {useInvoicePortalContext} from "../../InvoicePortalLayoutPage";
import {Stack} from "@fluentui/react";
import {OtherClientsContext} from "./interfaces";
import {OtherClientInvoice} from "./interfaces";
import {OtherClientsSelector} from "./components";
import {Card} from "../../../../components";
import {OtherClientsDataTable} from "./components/OtherClientsDataTable";

export const OtherClientsPageTemplate: FunctionComponent = () => {
    const {formatMessage} = useIntl();

    const {clientIdentifier} = useInvoicePortalContext();
    
    const [selectedItems, setSelectedItems] = useState<OtherClientInvoice[]>([])

    return (
        <OtherClientsContext.Provider value={{
            selectedItems: selectedItems,
            setSelectedItems: setSelectedItems
        }}>
            <Card>
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                    <Stack.Item shrink={0}>
                        <OtherClientsSelector></OtherClientsSelector>
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <OtherClientsDataTable />
                    </Stack.Item>
                </Stack>
            </Card>
             
        </OtherClientsContext.Provider>
    )
}