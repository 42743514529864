import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
}

function useDeleteFileNote() {
    const context = useContext(Context);
    const {
        mutate: deleteItem,
        mutateAsync: deleteItemAsync,
        isLoading: isDeleting
    } = useMutation( (data: MutateDataType) =>  axios.delete(`${context?.config?.proceduresUrl}/api/1.0/procedures/fileNotes/${data.id}`), {
    })

    return {deleteItem, deleteItemAsync, isDeleting};
}

export {useDeleteFileNote};