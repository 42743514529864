import React from 'react';
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios from "axios";
import { useNotifications } from '../../../../../components/notifications';
import { MessageBarType } from '@fluentui/react';
import { useIntl } from 'react-intl';


export enum PublishResponseType{
    Ok = 1,
    ValidationError = 2,
    AuditReport = 3,
    Invoice = 4,
}

export interface IPublishResponse {
    type: PublishResponseType,
    message: string | null
}

function usePublishClientResponse() {
    const context = React.useContext(Context);
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: publishResponse, isLoading: isPublishInProgress } = useMutation((jobId: number) =>
        axios.post<IPublishResponse>(
            `${context?.config?.queriesUrl}/api/1.0/queries/publishClientResponse/${jobId}`,
            { ...commonHeaders() }
        ),
        {
            onSuccess: (response) => {
                if (response.data.type === PublishResponseType.Ok) {
                    queryClient.invalidateQueries('getCurrentJobs');
                    queryClient.invalidateQueries(['dispatchAuditInfo'])

                    showNotification({
                        name: formatMessage({ id: 'success' }),
                        type: MessageBarType.success,
                        description: formatMessage({ id: 'clientResponsePublishedSuccessMessage' })
                    });
                }

                if (response.data.type === PublishResponseType.ValidationError) {
                    showNotification({
                        name: formatMessage({ id: 'error' }),
                        type: MessageBarType.error,
                        description: response.data.message ?? ''
                    });
                }
            },
        }
    );

    return {publishResponse, isPublishInProgress};
}

export {usePublishClientResponse};
