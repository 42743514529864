import {PropsWithChildren, useContext} from "react";
import {createSignalRContext} from "react-signalr";
import {ACCESS_TOKEN_KEY, TENANT_ID_HEADER} from "../constants/authorization";
import * as localStorageService from 'services/localStorageService';
import {Context} from "../utils";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../components/notifications";

const SignalRContext = createSignalRContext({shareConnectionBetweenTab: false});

export type NotificationType = {
    type: string;
    target: 'export' | 'import';
    message: string;
    content: any;
};

export const useSignalR = () => {
    return SignalRContext;
}


export const SignalRProvider = (props: PropsWithChildren<any>) => {
    const context = useContext(Context);
    const token: string = localStorageService.getData(ACCESS_TOKEN_KEY);
    const {useSignalREffect} = useSignalR();
    const {showNotification} = useNotifications();

    useSignalREffect(
        'error',
        (notification: NotificationType) => {
            showNotification({
                name: 'Error',
                description: `Error code: ${notification.content?.code}. ${notification.message}`,
                type: MessageBarType.error,
            });
        }, []);

    return (<SignalRContext.Provider
        headers={{TENANT_ID_HEADER: context?.tenantId || ''}}
        connectEnabled={!!token && !!context?.config?.notificationUrl}
        accessTokenFactory={() => localStorageService.getData(ACCESS_TOKEN_KEY) as string}
        dependencies={[token]}
        url={context?.config?.notificationUrl as string}>
        {props.children}
    </SignalRContext.Provider>)
};