import {useContext} from "react";
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";
import {ShortClientInfo} from "../models";

function useGetActiveClients() {
    const context = useContext(Context);
    const {data: response, isLoading} = useQuery(['getActiveClients'],
        () => axios.get<ShortClientInfo[]>(`${context?.config?.clientUrl}/api/1.0/clients/active`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId
        });

    return {response, isLoading};
}

export {useGetActiveClients};