import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    answerText: string;
}

function useUpdateReviewItemAnswer() {
    const context = useContext(Context);

    const {
        mutate: updateAnswer,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/jobReview/${data.id}/answer`, data));

    return {updateAnswer, isUpdating};
}

export {useUpdateReviewItemAnswer};