import {useContext} from "react";
import {Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IAttachment} from "./useGetAttachments";
import {DocumentType} from "../../enums";

function useGetAttachmentByDocumentType(jobId: number, documentType: DocumentType) {
    const context = useContext(Context);
    const host = context?.config?.annotationsUrl;
    const {
        data: response,
        refetch,
        isLoading,
        isFetching
    } = useQuery(['getAttachmentsByDocumentType', jobId, documentType], () => axios.get<IAttachment[]>(`${host}/api/1.0/jobs/${jobId}/attachments`, {
        params: {documentType: documentType}
    }).then(resp => resp.data));

    return {response, refetch, isLoading, isFetching};
}

export {useGetAttachmentByDocumentType};