import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {ICreditNote, ICreditNotesFilter} from "../inderfaces";
import {IPaginationResult} from "../../../interfaces";

function useGetCreditNotes(clientIdentifier: string, filter: ICreditNotesFilter) {
    const context = useContext(Context);
    const {
        data: creditNotes,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['getCreditNotes', clientIdentifier, filter.pageNumber, filter.pageSize],
        () => axios.get<ICreditNote[]>(`${context?.config?.billingUrl}/api/1.0/creditNote/GetAll/${clientIdentifier}`, {
            ...commonHeaders(),
            params: {
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize
            }
        })
            .then(resp => resp.data ?? [])
            .then((creditNotes: ICreditNote[]): IPaginationResult<ICreditNote> => {
                const start = (filter.pageNumber - 1) * filter.pageSize;
                const end = Math.min(start + filter.pageSize, creditNotes.length)
                return {
                    items: creditNotes.slice(start,  end),
                    pageNumber: filter.pageNumber,
                    pageSize: filter.pageSize,
                    totalCount: creditNotes.length
                }
            }), {
            enabled: !!context?.config?.billingUrl
        }
    );

    return {creditNotes, isLoading, isFetching, refetch};
}

export {useGetCreditNotes};