import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import {IYearInfo} from "../interfaces";

function useGetYears() {
    const context = React.useContext(Context);
    const { 
        data: years,
        isLoading: isYearsLoading
    } = useQuery(
        ['getGeneralYear'],
        () => axios.get<IYearInfo[]>(`${context?.config?.settingsUrl}/api/1.0/Settings/jobYears`, {
            ...commonHeaders()
        })
            .then(resp => resp.data),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { years, isYearsLoading };
}

export { useGetYears };
