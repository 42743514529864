import { mergeStyleSets, Stack} from '@fluentui/react';
import { ClientComments } from './ClientComments';
import { DisplayDocuments } from './DisplayDocuments';
import { SoftwareUploads } from './SoftwareUploads';
import { WrapAccounts } from './WrapAccounts';

export const JobFooter = () => {
    const classNames = mergeStyleSets({
        stack: [{
            minWidth: '75em'
        }]
    })

    const stackItemStyles = {root: {width: 'auto'}};

    return (
        <Stack className={classNames.stack} tokens={{childrenGap: 16}}>
            <Stack grow horizontal verticalAlign={'center'} horizontalAlign={'end'} tokens={{childrenGap: 40}}>
                <Stack.Item styles={stackItemStyles}>
                    <SoftwareUploads />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ClientComments />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <DisplayDocuments isPermanentDocs={false}/>
                </Stack.Item>
                <Stack.Item>
                    <DisplayDocuments isPermanentDocs={true} />
                </Stack.Item>
                <Stack.Item>
                    <WrapAccounts />
                </Stack.Item>
            </Stack>
        </Stack>
    );
};