import {FontWeights, Text, useTheme} from '@fluentui/react';
import {GeneratedDatesFilter, GeneratedPeriodsFilter, GeneratedYearsFilter} from './filters';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {ITableFilters} from './GeneratedDataTable';
import {ExportType} from 'hooks/useExportData';
import {SearchRow} from "components";
import {ExportActions} from "pages/WorkflowPortal/shared";

export interface IGeneratedFiltersProps {
    tableName: string;
    searchPlaceholder: string;
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
    isWidget?: boolean;
    hasDateFilter?: boolean;
    hasFilterByPeriod?: boolean;
    hasYearFilter?: boolean;
    exportData: (type: ExportType) => void;
}

export const GeneratedFilters: FunctionComponent<IGeneratedFiltersProps> = ({
                                                                                tableName,
                                                                                searchPlaceholder,
                                                                                setFilters,
                                                                                isWidget,
                                                                                hasDateFilter,
                                                                                hasYearFilter,
                                                                                hasFilterByPeriod,
                                                                                exportData,
                                                                            }) => {
    const theme = useTheme();

    const onSearchHandle = (newValue: string) => {
        setFilters((prev) => ({...prev, filterBy: newValue, page: 1}));
    };

    const iconButtonsStyles = isWidget
        ? {
            root: {
                selectors: {
                    '.ms-Button-icon': {
                        color: theme.palette.white,
                    },
                    '.ms-Button:hover': {
                        backgroundColor: theme.palette.themeSecondary,
                    },
                },
            },
        }
        : {};

    const leftSide = isWidget ?
        <Text styles={{root: {fontWeight: FontWeights.bold}}}>{tableName}</Text> : (hasFilterByPeriod &&
            <GeneratedPeriodsFilter setFilters={setFilters}/>);


    const rightSide = <>
        {hasYearFilter && <GeneratedYearsFilter setFilters={setFilters}/>}
        {hasDateFilter && <GeneratedDatesFilter setFilters={setFilters}/>}
        <ExportActions styles={iconButtonsStyles} onPdfClick={() => exportData(ExportType.Pdf)}
                       onExcelClick={() => exportData(ExportType.Excel)}/>
    </>

    return (
        <>
            <SearchRow search={{onSearch: onSearchHandle, onClear: onSearchHandle, placeholder: searchPlaceholder}}
                       leftSide={leftSide}
                       rightSide={rightSide}/>
        </>
    );
};
