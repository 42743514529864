import { useContext } from 'react';
import { commonHeaders, Context, queryClient } from '../../../../../../../../../utils';
import { useMutation } from 'react-query';
import axios from 'axios';

function useResetAuditAcceptance(jobId: number) {
    const context = useContext(Context);
    
    const { mutate: reset, isLoading: isResetting } = useMutation(
        () =>
            axios
                .post(`${context?.config?.jobUrl}/api/1.0/job/${jobId}/acceptance/reset`, null, {
                    ...commonHeaders(),
                })
                .then((resp) => resp.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getAuditAcceptance');
            }
        }
    );

    return { reset, isResetting };
}

export { useResetAuditAcceptance };
