import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {ICreditNoteDetailsItem} from "../inderfaces";
import {
    DefaultButton,
    FontWeights,
    IColumn,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text, useTheme
} from "@fluentui/react";
import {FormattedMessage, useIntl} from "react-intl";
import {ControlledNumberField, DataTable} from "../../../../../components";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";
import {PaymentData, useApplyCreditNote, useGetCreditNoteDetails} from "../hooks";
import {formatIdNumber} from "../../../../../utils";
import {useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {IInvoice} from "../../Invoices/interfaces";
import {CreditNoteStatusType} from "../enums";

interface ICreditNoteDetailsProps {
    creditNoteId: number;
    onClose: () => void;
    onSubmit?: () => void;
}

type CreditNoteDetailed = ICreditNoteDetailsItem;

type CreditNoteDetailedForm = { formItems: CreditNoteDetailed[] };

export const CreditNoteDetailsForm: FunctionComponent<ICreditNoteDetailsProps> = ({ creditNoteId, onClose, ...props }: ICreditNoteDetailsProps) => {
    const {formatMessage, formatNumber} = useIntl();
    const theme = useTheme();
    
    const { clientIdentifier } = useInvoicePortalContext();
    const { creditNoteDetails, isLoading } = useGetCreditNoteDetails(clientIdentifier, creditNoteId);
    const { apply, isApplying } = useApplyCreditNote(clientIdentifier);
    
    const [ totalApplied, setTotalApplied] = useState<number>(creditNoteDetails?.totalApplied ?? 0)
    const [ isAppliedExceedAmount, setIsAppliedExceedAmount ] = useState<boolean>(totalApplied > (creditNoteDetails?.creditNoteTotal ?? 0));
    
    const items = useMemo<CreditNoteDetailed[]>(() => ([...(creditNoteDetails?.items ?? [])]), [creditNoteDetails])
    
    const {control, handleSubmit, setError, formState: { errors, isDirty }, getValues, setValue} = useForm<CreditNoteDetailedForm>({
        ...DefaultFormSettings,
        defaultValues: {
            formItems: [...items]
        }
    })

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'formItems'
    })
    
    const calculateTotalApplied = (items: CreditNoteDetailed[]): number => {
        return items
            .map(v => +v.creditNoteApplied)
            .filter(v => !isNaN(v))
            .reduce((sum, i) => { sum += i; return sum; }, 0) ?? 0;
    }

    const onAppliedValueChanged = (newValue?: string, index?: number) => {
        setTotalApplied(prev => calculateTotalApplied([...getValues('formItems')]));
    }
    
    const onSubmit = (form: CreditNoteDetailedForm) => {
        const items = form.formItems.filter(i => !!i.creditNoteApplied).map(i => ({ invoiceId: i.invoiceId, amount: +i.creditNoteApplied } as PaymentData))
        
        if (items.length) {
            apply([{ creditNoteId: creditNoteId, items: [...items] }], {
                onSuccess: () => props.onSubmit?.()
            })
        } else {
            props.onSubmit?.();
        }
    }

    useEffect(() => {
        setValue('formItems', [...items])
        setTotalApplied(prev => calculateTotalApplied([...items]))
    }, [items]);

    useEffect(() => {
        setIsAppliedExceedAmount(prev => totalApplied > (creditNoteDetails?.creditNoteTotal ?? 0));
    }, [totalApplied]);
    
    const columns: IColumn[] = [
        {
            key: 'jobFundName',
            name: `${formatMessage({ id: 'jobName' })}/${formatMessage({ id: 'fundName' })}`,
            minWidth: 180,
            onRender: (item: CreditNoteDetailed) => <Text variant='medium' styles={{ root: { fontWeight: FontWeights.bold } }}>{item.isMultipleJobsInvoice ? formatMessage({id: 'multipleJobs'}) : `${item.job!.name} (${item.fund!.name})`}</Text>,
        },
        {
            key: 'invoiceNumber',
            name: formatMessage({ id: 'invoiceNumber' }),
            minWidth: 100,
            maxWidth: 120,
            onRender: (item: CreditNoteDetailed) => <Text>{formatIdNumber(item.invoiceId)}</Text>,
        },
        {
            key: 'invoiceAmount',
            name: formatMessage({ id: 'invoiceAmount' }),
            minWidth: 100,
            maxWidth: 120,
            onRender: (item: CreditNoteDetailed) => <Text>{formatNumber(item.invoiceAmount, {
                style: 'currency',
                currency: 'USD'
            })}</Text>,
        },
        {
            key: 'invoiceRemainingAmount',
            name: formatMessage({ id: 'invoiceRemainingAmount' }),
            minWidth: 150,
            maxWidth: 200,
            onRender: (item: CreditNoteDetailed) => <Text>{formatNumber(item.invoiceRemainingAmount, {
                style: 'currency',
                currency: 'USD'
            })}</Text>,
        },
        {
            key: 'creditNoteApplied',
            name: formatMessage({ id: 'creditNoteApplied' }),
            minWidth: 100,
            maxWidth: 120,
            onRender: (item: CreditNoteDetailed, index?: number) => (
                creditNoteDetails?.status === CreditNoteStatusType.Open
                    ? <ControlledNumberField
                        name={`formItems.${index!}.creditNoteApplied`}
                        control={control}
                        onChanged={(newValue?: string) => onAppliedValueChanged(newValue, index)}
                    />
                    : <Text>{formatNumber(item.creditNoteApplied, {style: 'currency', currency: 'USD'})}</Text>
            )
        }
    ];
    
    const classNames = mergeStyleSets({
        dataTable: {
            '.ms-DetailsRow-cell': {
                'justify-content': 'center',
            }
        },
        errorText: {
            color: theme.semanticColors.errorText
        },
        blackText: {
            color: theme.schemes?.default?.semanticColors.bodyText
        }
    })
    
    if (isLoading) {
        return (
            <Stack horizontal horizontalAlign={'center'}>
                <Stack.Item>
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>
            </Stack>
        )
    }
    
    return (
        <Stack tokens={{childrenGap: 8}}>
            <Stack.Item align={'end'}>
                <Text className={classNames.blackText}>
                    <FormattedMessage
                        id='creditNoteTotal{value}'
                        values={{ value: formatNumber(creditNoteDetails?.creditNoteTotal ?? 0, { style: 'currency', currency: 'USD' }) }}
                    />
                </Text>
            </Stack.Item>
            <Stack.Item>
                <DataTable
                    className={classNames.dataTable}
                    initialColumns={columns}
                    items={fields ?? []}
                    enableShimmer={false}
                    selectionMode={SelectionMode.none}
                    setKey='none'
                    isHeaderVisible
                    containerHeight='calc(100% - 32px - 16px)'
                    disableDragDrop={true}
                />
            </Stack.Item>
            
            <Stack.Item>
                <Stack horizontal horizontalAlign={'space-between'}>
                    <Stack.Item>
                        <Text variant='small' className={classNames.blackText}>
                            <FormattedMessage
                                id='youHaveInOutstandingCreditsForSelectedInvoice'
                                values={{ value: formatNumber(0, { style: 'currency', currency: 'USD' }) }}
                            />
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal horizontalAlign={'end'} tokens={{childrenGap: 8}}>
                            <Stack.Item className={classNames.errorText}>
                                {isAppliedExceedAmount && <FormattedMessage
                                    id='creditNoteTotalExceed{value}'
                                    values={{value: formatNumber(creditNoteDetails?.creditNoteTotal ?? 0, { style: 'currency', currency: 'USD' }) }}
                                />}
                            </Stack.Item>
                            <Stack.Item>
                                <Text className={classNames.blackText}>
                                    <FormattedMessage
                                        id='total{value}'
                                        values={{ value: formatNumber(totalApplied ?? 0, { style: 'currency', currency: 'USD' }) }}
                                    />
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                    <Stack.Item>
                        {creditNoteDetails?.status === CreditNoteStatusType.Open && <PrimaryButton disabled={!isDirty || isApplying || isAppliedExceedAmount}
                                       onClick={handleSubmit(onSubmit)} >
                            {isApplying
                                ? <Spinner size={SpinnerSize.small}></Spinner>
                                : formatMessage({id: 'save'})}
                        </PrimaryButton>}
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isApplying} text={formatMessage({id: 'close'})} onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}