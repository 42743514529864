import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {commonHeaders, Context} from "utils";
import React from "react";
import {FilteredResult, IPaginationFilter, TimesheetInfo} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";

export interface ITimesheetPaginationFilter extends IPaginationFilter {

    search?: string;
    jobId?: number;
}


function useGetTimesheetByFilter(params: ITimesheetPaginationFilter) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    
    const {data: timesheet, isLoading: isTimesheetLoading} = useQuery(
        ['getTimesheetByFilter', params],
        () => axios.get<FilteredResult<TimesheetInfo>>(`${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets/filter`, {
            ...commonHeaders(),
            params: params
        }),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0] || formatMessage({id:'somethingWentWrong'})}`
                })
            }
        }
    );

    return {timesheet, isTimesheetLoading};
}

export {useGetTimesheetByFilter}