import {DefaultButton, mergeStyleSets, Stack, Text, useTheme} from '@fluentui/react';
import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {getTheme} from '@fluentui/react/lib/Styling';
import {useNavigate} from 'react-router-dom';

const theme = getTheme();
const {fonts} = theme;

export const NotFoundPage: FunctionComponent = () => {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const {palette} = useTheme();

    const classNames = mergeStyleSets({
        container: {
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontSize: 144,
            marginBottom: 16,
            color: palette.orange
        },
        subtitle: {
            color: palette.orangeLight,
            ...fonts.xLarge,
        },
    });
    const onBackClick = () => {
        navigate('/')
    };
    return (
        <Stack styles={{root: {height: '100%', overflow: 'hidden'}}}>
            <Stack className={classNames.container} tokens={{childrenGap: 16}}>
                <Text as='h2' className={classNames.title}>
                    404
                </Text>
                <Text className={classNames.subtitle}>
                    {formatMessage({id: 'pageNotFound'})}
                </Text>
                <Text>
                    {formatMessage({id: 'thePageYouAreLookingForDoesntExist'})}
                </Text>
                <DefaultButton onClick={onBackClick} text={formatMessage({id: 'openHomepage'})}/>
            </Stack>
        </Stack>
    )
};