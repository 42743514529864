import {Outlet} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {PageWithBreadcrumb} from 'components';
import {RouterPaths} from 'navigation';

export const RulesetsLayoutPage = () => {
    const {formatMessage} = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'rulesets',
                text: formatMessage({id: 'rulesets'}),
                key: RouterPaths.admin.rulesets.root,
            }}>
            <Outlet/>
        </PageWithBreadcrumb>
    );
};
