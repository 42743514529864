import {FunctionComponent} from 'react';
import {FontWeights, IStyle, mergeStyleSets, Stack, useTheme} from '@fluentui/react';
import {Card} from 'components';

// !TODO add click handler on widget to open modals with tables

type CountWidgetVariant = 'default' | 'tertiary' | 'white';
type CounterAlign = 'center' | 'bottom';

export interface ICountWidget {
    number: number;
    title: string;
    variant?: CountWidgetVariant;
    counterAlign?: CounterAlign;
}

export const CountWidget: FunctionComponent<ICountWidget> = ({
                                                                 number = 0,
                                                                 title,
                                                                 variant = 'default',
                                                                 counterAlign = 'bottom'
                                                             }) => {
    const theme = useTheme();

    const getBg = (variant: CountWidgetVariant): string => {
        switch (variant) {
            case 'tertiary':
                return theme.palette.themeTertiary;
            case 'white':
                return theme.palette.white;
            default:
                return theme.palette.themePrimary;
        }
    };

    const getCounterStyles = (variant: CounterAlign): IStyle => {
        switch (variant) {
            case 'center':
                return {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                };
            default:
                return {
                    paddingTop: 32,
                };
        }
    };

    const cardStyles = {
        root: {
            backgroundColor: getBg(variant),
            height: '100%',
            boxSizing: 'border-box',
            transition: 'all 0.2s linear',
            border: variant === 'white' ? `2px solid ${theme.palette.themePrimary}` : 'none',
            '&:hover': {
                opacity: 0.8,
                transform: 'scale(1.02)'
            },
        },
        content:{
            height: '100%',
            boxSizing: 'border-box',
        },
        title: {
            color: `${theme.palette.neutralPrimary}`
        }
    };

    const classNames = mergeStyleSets({
        container: {
            position: 'relative',
            height: '100%',
            textAlign: 'center',
        },
        title: [
            {color: variant === 'white' ? theme.schemes?.default?.semanticColors.bodyText : theme.palette.neutralPrimary},
            theme.fonts.xLarge,
            {
                fontWeight: FontWeights.regular
            },

        ],
        count: [
            {color: variant === 'white' ? theme.schemes?.default?.semanticColors.bodyText : theme.palette.neutralPrimary},
            theme.fonts.xxLarge,
            {
                fontWeight: FontWeights.regular
            },
            getCounterStyles(counterAlign),
        ],
    });

    return (
        <Card styles={cardStyles}>
            <Stack
                horizontalAlign='center'
                verticalAlign='space-between'
                className={classNames.container}>
                <Stack.Item className={classNames.title}>
                    {title}
                </Stack.Item>
                <Stack.Item className={classNames.count}>
                    {number}
                </Stack.Item>
            </Stack>
        </Card>
    );
};
