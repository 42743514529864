import axios, {AxiosError} from 'axios';
import {DetailedSoftwareType, SoftwareType} from 'enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface IGetJobImportDataParams {
    guid?: number | string;
}

export interface IImportStatement {
    maxAvailableUploads: number;
    name: string;
    usedUploads: number;
    value: string;
}

export interface IJobImportData {
    isApi: boolean;
    isApiCalled: boolean;
    isJobLocked: boolean;
    jobId: number;
    jobTemplateId: number;
    softwareType: SoftwareType;
    detailedSoftwareType: DetailedSoftwareType;
    allowLockJob: boolean;
    allowRemoveData: boolean;
    isImportCalled: boolean;
    statements: IImportStatement[];
}

function useGetJobImportData({guid}: IGetJobImportDataParams) {
    const context = React.useContext(Context);

    const {
        data: jobImportData,
        isLoading: isJobImportDataLoading,
        refetch: refetchJobImportData,
        isFetching: isJobImportDataFetching,
        isRefetching: isJobImportDataRefetching
    } = useQuery(
        ['getJobImportData', guid],
        () => axios.get<IJobImportData>(`${context?.config?.jobUrl}/api/1.0/job/${guid}/importData`, commonHeaders()),
        {
            enabled: !!context?.config?.jobUrl && !!context.tenantId && !!guid,

            retry: false,
        }
    );

    return {jobImportData, isJobImportDataLoading, refetchJobImportData, isJobImportDataFetching, isJobImportDataRefetching};
}

export {useGetJobImportData};
