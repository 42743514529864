import {Stack} from '@fluentui/react';
import {Card, Loading} from 'components';
import {useEffect, useState} from 'react';

import {DashboardTabsEnum, DashboardTabsMenu, useGetCurrentUserDashboards} from "../../shared/Dashboard";
import {
    AdministrationAuditPortalDashboard,
    AuditorAuditPortalDashboard,
    ContraventionAuditPortalDashboard,
    ManagerAuditPortalDashboard,
    PartnerAuditPortalDashboard
} from "./dashboards/auditPortal";
import {
    AccountantWorkpapersDashboard,
    AdministrationWorkpapersDashboard,
    ManagerWorkpapersDashboard,
    PartnerWorkpapersDashboard
} from "./dashboards";
import {useTenantInfo} from "../../../providers";
import {NotFoundPage} from "../../shared";

export const DashboardWorkflowPage = () => {
    const {isWorkpapers} = useTenantInfo();
    const {response: userDashboardResponse, isLoading} = useGetCurrentUserDashboards();


    const [selectedKey, setSelectedKey] = useState<DashboardTabsEnum>();

    const getTabContent = (selectedTab?: DashboardTabsEnum): JSX.Element => {
        if (!selectedTab)
            return <NotFoundPage/>

        switch (selectedTab) {
            case DashboardTabsEnum.AP_Administrator:
                return <AdministrationAuditPortalDashboard/>;
            case DashboardTabsEnum.WP_Administrator:
                return <AdministrationWorkpapersDashboard/>
            case DashboardTabsEnum.AP_Auditor:
                return <AuditorAuditPortalDashboard/>;
            case DashboardTabsEnum.WP_Accountant:
                return <AccountantWorkpapersDashboard/>
            case DashboardTabsEnum.AP_Contravention:
                return <ContraventionAuditPortalDashboard/>;
            case DashboardTabsEnum.AP_Manager:
                return <ManagerAuditPortalDashboard/>;
            case DashboardTabsEnum.WP_Manager:
                return <ManagerWorkpapersDashboard/>
            case DashboardTabsEnum.AP_Partner:
                return <PartnerAuditPortalDashboard/>;
            case DashboardTabsEnum.WP_Partner:
                return <PartnerWorkpapersDashboard/>
            default:
                return <Loading/>
        }
    };

    useEffect(() => {
        if (userDashboardResponse?.data?.length)
            setSelectedKey(isWorkpapers ? DashboardTabsEnum.WP_Administrator : DashboardTabsEnum.AP_Administrator);

    }, userDashboardResponse?.data)

    if (isLoading)
        return <Loading/>

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <Card styles={{root: {paddingTop: 0, paddingBottom: 0}}}>
                    <DashboardTabsMenu dashboards={userDashboardResponse?.data || []}
                                       selectedKey={selectedKey}
                                       setSelectedKey={setSelectedKey}/>
                </Card>
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <Card styles={{
                    root: {
                        height: '100%',
                        overflow: 'auto',
                        boxSizing: 'border-box'
                    }
                }}>{getTabContent(selectedKey)}</Card>
            </Stack.Item>
        </Stack>
    );
};
