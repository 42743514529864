import { useContext } from 'react';
import { commonHeaders, Context } from '../../../../../../../../../utils';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { AuditAcceptanceInfo } from "../inderfaces";

function useGetAuditAcceptance(jobId: number) {
    const context = useContext(Context);

    const { data: auditAcceptance, isLoading, isRefetching } = useQuery(
        ['getAuditAcceptance', jobId],
        () =>
            axios
                .get<AuditAcceptanceInfo>(`${context?.config?.jobUrl}/api/1.0/job/${jobId}/acceptance`, {
                    ...commonHeaders(),
                })
                .then((resp) => resp.data),
        {
            enabled: !!context?.config?.jobUrl && !!jobId,
        }
    );

    return { auditAcceptance, isLoading, isRefetching };
}

export { useGetAuditAcceptance };
