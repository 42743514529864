import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const BGL360TaxableIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const rebuildReports = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.format = i.valueType === 2 ? 'Bold' : 'Normal'
            if (i.children?.length > 0) {
                i.children = rebuildReports(i.children);
            }

            acc.push(i);
            return acc;
        }, []);
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 500,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'to',
                name: formatMessage({ id: 'thisYear' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'to',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[1]} isSumChecked={item.isSumChecked} />,
            },
            {
                key: 'validation2',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation2',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[2, 3, 4]} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={rebuildReports(items)} isFetching={isFetching} />;
};
