import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {MessageBarType} from "@fluentui/react";


type MutateDataType = {
    status: EntityStatus;
    clientId: number;
    fundId: number;
};

function useUpdateFundsStatus(closeModal: () => void) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: updateFundStatus, isLoading: isLoadingUpdateFundStatus} = useMutation(
        (data: MutateDataType) =>
            axios.put(
                `${context?.config?.clientUrl}/api/1.0/clients/${data.clientId}/funds/${data.fundId}/status?status=${data.status}`,
                {},
                commonHeaders()
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getFundsFiltersData');
                closeModal();
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Fund status'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateFundStatus, isLoadingUpdateFundStatus};
}

export {useUpdateFundsStatus};
