export enum CalculatorType {
    Materiality = "Materiality",
    ChangeInMV = 'ChangeInMV',
    IncomeTaxExpense = 'IncomeTaxExpense',
    DeferredTax = 'DeferredTax',
    TaxRecExemptPensionIncomeGrossMethod = 'TaxRecExemptPensionIncomeGrossMethod',
    TaxRecExemptPensionIncomeNetMethod = 'TaxRecExemptPensionIncomeNetMethod',
    Interest = 'Interest',
    RentalIncomeYield = 'RentalIncomeYield',
    ProfitLossAllocation = 'ProfitLossAllocation',
    Contribution = 'Contribution',
    Pension = 'Pension',
    OverallMateriality = 'OverallMateriality'
}

export const getTypeByLink = (link: string): CalculatorType | null => {
    const types = Object.values(CalculatorType).filter((v) => isNaN(Number(v)));

    const necessaryType = types.find(x => link.includes(x.toString()));
    if (necessaryType) {

        return necessaryType == CalculatorType.Materiality ? CalculatorType.OverallMateriality : CalculatorType[necessaryType];
    }

    return null;
}