import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders} from 'utils';
import {useApplyStyleTheme} from './useApplyStyleTheme';
import {useIntl} from 'react-intl';
import {MessageBarType} from '@fluentui/react';
import {useNotifications} from "../../../../components/notifications";

type ChangeThemeColorsReq = {
    themePrimary: string;
    neutralPrimary: string;
};

function useChangeTheme(url: string) {
    const {applyTheme, isLoading: applyThemeLoading} = useApplyStyleTheme(url);
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {mutate: changeTheme, isLoading: changeThemeLoading} = useMutation(
            (data: ChangeThemeColorsReq) => axios.put(`${url}/api/1.0/Customisation/changeTheme`, data, commonHeaders()),
            {
                onSuccess: () => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successCustomizationUpdateMessage'}),
                    });
                    applyTheme()
                },
            }
        )
    ;

    return {changeTheme, applyThemeLoading, changeThemeLoading};
}

export {useChangeTheme};
