import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import { useNotifications } from '../../../../../components/notifications';
import { useIntl } from 'react-intl';
import { MessageBarType } from '@fluentui/react';

export interface IUpdateFund {
    clientId: string;
    fundId: string;
    abn: string;
    contactId: string;
}

function useUpdateFund() {
    const context = React.useContext(Context);

    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: updateFund, isLoading, isSuccess } = useMutation((data: IUpdateFund) =>
        axios.put(`${context?.config?.clientUrl}/api/1.0/clients/${data.clientId}/funds/${data.fundId}/clientPortalEdit`, data, commonHeaders()),
        {
            onSuccess: () => {
                //queryClient.invalidateQueries('getClientFunds');

                showNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'fundUpdated' })
                });
            }
        }
    );

    return { updateFund, isLoading, isSuccess };
}

export { useUpdateFund };