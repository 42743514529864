import {IconButton, PrimaryButton, Spinner, SpinnerSize, Stack, Text, useTheme} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {ModalFooter} from 'components';
import {useIsDarkTheme} from 'hooks/useIsDarkTheme';
import React from 'react';
import Modal from 'react-modal';

type VariableModalPropsType = {
    isOpen: boolean;
    title: string;
    onDismiss: () => void;
    children: React.ReactElement<any, any>;
    onSubmit: () => void;
    btnTitle: string;
    disabled?: boolean;
    loading?: boolean;
};

Modal.setAppElement('body');

export const VariableModal = (props: VariableModalPropsType) => {
    const titleId = useId('modal-title');
    const theme = useTheme();
    const isUserPreferDarkTheme = useIsDarkTheme();

    return (
        <Modal
            isOpen={props.isOpen}
            ariaHideApp={false}
            onRequestClose={props.onDismiss}
            style={{
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgba(0,0,0,0.5)',
                },
            }}>
            <div style={{maxWidth: 1000, width: 1000}}>
                <Stack>
                    <Stack
                        horizontal
                        horizontalAlign='space-between'
                        verticalAlign='center'
                        tokens={{childrenGap: 8, padding: '8px 16px'}}
                        styles={{root: {background: theme.palette.white}}}>
                        <Text variant='large' id={titleId}>
                            {props.title}
                        </Text>

                        <IconButton iconProps={{iconName: 'ChromeClose'}} onClick={props.onDismiss}/>
                    </Stack>

                    <Stack.Item styles={{
                        root: {
                            padding: '0 16px 16px',
                            background: isUserPreferDarkTheme ? '#3f3e3d' : '#eaeaea'
                        }
                    }}>
                        <div style={{padding: '10px 0 20px 0'}}>{props.children}</div>
                        <ModalFooter horizontalAlign={'end'}>
                            <PrimaryButton
                                disabled={props.disabled || props.loading}
                                onClick={props.onSubmit}
                                onRenderChildren={() => (props.loading ?
                                    <Spinner size={SpinnerSize.small}/> : <>{props.btnTitle}</>)}
                            />
                        </ModalFooter>
                    </Stack.Item>
                </Stack>
            </div>
        </Modal>
    );
};
