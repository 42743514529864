import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, ReportValueType, TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGL360OperatingStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const isOpenedItem = (item: any): boolean => [0, 1].indexOf(item.depth) !== -1;
    
    const rebuildReports = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.format = i.valueType === ReportValueType.Value || i.valueType === ReportValueType.SubHeading ? 'Normal' : 'Bold'
            if (i.children?.length > 0) {
                i.children = rebuildReports(i.children);
            }

            acc.push(i);
            return acc;
        }, []);
    }
    
    const reportItems = useMemo(() => rebuildReports(items), [items]);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'description' }),
                minWidth: 500,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} minWidth={'auto'} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== ReportValueType.Heading &&
                            <GeneratedAnswer itemId={item?.id} tableType={item?.reportType}
                                value={item?.answerText} answerControlType={item?.answerControlType}
                                auto={item?.isAnswerDisabled} disabled={item?.isAnswerDisabled}/>}
                    </Stack>
                ),
            },
            {
                key: 'to',
                name: formatMessage({ id: 'thisYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'to',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'from',
                name: formatMessage({ id: 'lastYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'from',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'change',
                name: formatMessage({ id: 'moneyChange' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'change',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'percentChange',
                name: formatMessage({ id: 'percentChange' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percentChange',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='percentage-fractional' />,
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[1]} isSumChecked={item.isSumChecked} />,
            },
            {
                key: 'validation2',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation2',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[2, 3, 4]}/>,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns}
                        isOpenedItem={isOpenedItem}
                        items={reportItems}
                        isFetching={isFetching} />
};
