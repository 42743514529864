import {MessageBarType} from '@fluentui/react';
import axios, {AxiosError} from 'axios';
import React from 'react';
import {useIntl} from 'react-intl';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {NotificationAction, useNotifications} from "../../../../components/notifications";
import {useWorkflows} from "../../WorkflowLayoutPage";
import {TimesheetInfo} from "../../shared";

type MutateDataType = {
    clientId: number;
    fundId: number;
    jobId: number;
    employeeLocalId: number;
    timesheetDate: Date;
    durationMin: number;
    rate: number;
};

function useCreateTimesheetInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {showTimesheetModal} = useWorkflows();
    const {formatMessage} = useIntl();

    const {mutate: createTimesheet, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.post<TimesheetInfo>(
                `${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets`,
                data,
                commonHeaders()
            ),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('getTimesheetByFilter');

                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'createdSuccessfully'}),
                    description: formatMessage({id: "entityWasCreated"}, {name: formatMessage({id: 'timesheet'})}),
                    actions: (
                        <NotificationAction
                            onClick={() => {
                                console.debug('Created timesheet id: ' + data.data.id);
                                showTimesheetModal(data.data.id);
                            }}>{formatMessage({id: 'view'})}</NotificationAction>
                    )
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0] || formatMessage({id: 'somethingWentWrong'})}`
                })
            }
        }
    );

    return {createTimesheet, isUpdating};
}

export {useCreateTimesheetInfo};
