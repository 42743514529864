import {useIntl} from "react-intl";
import {useCallback} from "react";
import {
    DashboardContainer,
    DashboardTableTypeEnum, DashboardTabsEnum, GetDashboardConfig,
    IAdministrationWPWidgetData,
    IDashboardConfig,
    WidgetsEnum
} from "../../../../shared/Dashboard";

export const AdministrationWorkpapersDashboard = () => {
    const {formatMessage} = useIntl();

    const getConfig = useCallback(
        (props: IAdministrationWPWidgetData): IDashboardConfig => {
            return {
                id: 'administration',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsAwaitingAccountantAllocation',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_JobsAwaitingAccountantAllocation}],
                            props: {
                                number: props.jobsAwaitingAccountantAllocation,
                                title: formatMessage({id: 'jobsAwaitingAccountantAllocation'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsInProgressWithAccountant',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_JobsInProgressWithAccountant}],
                            props: {
                                variant: "tertiary",
                                number: props.jobsInProgressWithAccountant,
                                title: formatMessage({id: 'jobsInProgressWithAccountant'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsAwaitingManagerReview',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_JobsAwaitingManagerReview}],
                            props: {
                                number: props.jobsAwaitingManagerReview,
                                title: formatMessage({id: 'jobsAwaitingManagerReview'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsAwaitingPartnerReview',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_JobsAwaitingPartnerReview}],
                            props: {
                                variant: "tertiary",
                                number: props.jobsAwaitingPartnerReview,
                                title: formatMessage({id: 'jobsAwaitingPartnerReview'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsInProgressWithAuditor',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_JobsInProgressWithAuditor}],
                            props: {
                                number: props.jobsInProgressWithAuditor,
                                title: formatMessage({id: 'jobsInProgressWithAuditor'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'workpapersFinalisedThisWeek',
                            tables: [{tableType: DashboardTableTypeEnum.Administrator_WorkpapersFinalisedThisWeek}],
                            props: {
                                variant: "tertiary",
                                number: props.workpapersFinalisedThisWeek,
                                title: formatMessage({id: 'workpapersFinalisedThisWeek'})
                            }
                        }
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '7',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_JobsReadyForAudit,
                            }
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_DraftAuditReportReleased,
                            }
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '9',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_AuditReportReleased,
                            }
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '10',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_Invoicing,
                            }
                        },
                    ]
                ]
            }
        }, [formatMessage]);

    return <DashboardContainer
        dashboardTab={DashboardTabsEnum.WP_Administrator}
        getDashboardConfig={getConfig as GetDashboardConfig}/>;
}