import axios from 'axios';
import {commonHeaders} from 'utils';
import {useMutation} from 'react-query';
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../components/notifications";
import {useIntl} from "react-intl";

export enum ExportType {
    Pdf = 'Pdf',
    Excel = 'Excel'
}

export function useExportData(host: string, data?: any) {
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    const {mutate: exportData} = useMutation((type: ExportType) => {
            return axios.post(`${host}/export?exportFileType=${type}`, data, commonHeaders())
        }, {
            onMutate: variables => {
                showNotification({
                    name: 'Exporting',
                    description: formatMessage({id: 'exporting'}),
                    type: MessageBarType.info,
                    promise:true
                });
            }
        }
    );

    return {exportData};
}
