import { FunctionComponent } from 'react';
import { CommandBarButton, DirectionalHint, IContextualMenuProps, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';

export const HelpMenu: FunctionComponent = () => {
    const intl = useIntl();
    const theme = useTheme();

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'helpGuide',
                style: { color: theme.schemes?.default?.semanticColors.bodyText },
                text: intl.formatMessage({ id: 'helpGuide' }),
                iconProps: { iconName: 'Info' },
            },

            {
                key: 'contactSupport',
                style: { color: theme.schemes?.default?.semanticColors.bodyText },
                text: intl.formatMessage({ id: 'contactSupport' }),
                iconProps: { iconName: 'Mail' },
            },
        ],
        directionalHint: DirectionalHint.bottomLeftEdge,
    };

    return (
        <CommandBarButton
            styles={{ root: { height: 48, paddingLeft: 10, paddingRight: 10, color: theme.schemes?.default?.semanticColors.bodyText }, label: { color: theme.schemes?.default?.semanticColors.bodyText } }}
            text={intl.formatMessage({ id: 'help' })}
            iconProps={{ iconName: 'Help' }}
            onRenderMenuIcon={() => <div />}
            menuProps={menuProps}
        />
    );
};
