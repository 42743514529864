import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    scheduleId: number;
}

function useStartScheduledExport() {
    const context = useContext(Context);
    const host = context?.config?.exportUrl;

    const {
        mutate:start,
        isLoading
    } = useMutation((data: MutateDataType) => axios.put(`${host}/api/1.0/jobs/${data.jobId}/export/scheduled/${data.scheduleId}/start`));

    return {start, isLoading};
}

export {useStartScheduledExport};