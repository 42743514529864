import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {FundInfo} from "pages/WorkflowPortal/shared";
import {useNotifications} from "../../../../components/notifications";
import {MessageBarType} from "@fluentui/react";

type PropsType = {
    clientGuid: string;
    fundGuid: string;
};

function useGetFundByGuid({clientGuid, fundGuid}: PropsType) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {data: currentFundData, isLoading: isLoadingCurrentFundData} = useQuery(
        ['getFundDataByGuid', clientGuid, fundGuid],
        () => axios.get<FundInfo>(`${context?.config?.clientUrl}/api/1.0/clients/${clientGuid || ''}/funds/${fundGuid}`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientGuid && !!fundGuid,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {currentFundData, isLoadingCurrentFundData};
}

export {useGetFundByGuid};
