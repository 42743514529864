import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {IInterestCalculatorData} from "./IInterestCalculatorData";
import {useIntl} from "react-intl";
import {useUpdateCalculatorData} from "../../hooks";
import {useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {CalculatorType} from "../../CalculatorType";
import {IDropdownOption, Stack} from "@fluentui/react";
import {ITableColumn, SanitizedText} from "../../../../../../components";
import {CalculatorTemplateProps} from "../../types";
import {DropdownAnswer} from "../../../templates/answers";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {CalculatorDataTable} from "../shared";

type InterestCalculatorProps = CalculatorTemplateProps

export const InterestCalculator = forwardRef(({data}: InterestCalculatorProps, ref) => {

    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const {update, isUpdating} = useUpdateCalculatorData();
    const [interestData, setInterestData] = useState<IInterestCalculatorData[]>(data?.listData as IInterestCalculatorData[]);
    const {handleSubmit, setValue, control, watch} = useForm<{ items: IInterestCalculatorData[] }>({
        ...DefaultFormSettings,
        defaultValues: {items: interestData}
    });

    const {fields} = useFieldArray({
        control,
        name: 'items'
    })
    const reviewOptions: IDropdownOption[] = [
        {key: '', text: '---'},
        {key: 'Accept As Reasonable', text: 'Accept As Reasonable'},
        {key: 'Unacceptable', text: 'Unacceptable'}
    ];
    const updateCalcData = (calculatorData: any) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.Interest,
            isCustom: false,
            answer: undefined,
            answers: calculatorData.items,
            jobId: jobId
        });
    };

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'bankAccount',
                name: formatMessage({id: 'calculator_interest_title_bankAccount'}),
                fieldName: 'bankAccount',
                minWidth: 120,
                //maxWidth: 120,
                flexGrow: 1,
                onRender: (item) => (
                    <SanitizedText data={item.bankAccount} textAlign={'left'}/>
                )
            },
            {
                key: 'cashAtBankAccount',
                name: formatMessage({id: 'calculator_interest_title_cashAtBankAccount'}),
                fieldName: 'cashAtBankAccount',
                minWidth: 120,
                //maxWidth: 120,
                flexGrow: 1,
                onRender: (item) => (
                    <SanitizedText data={item.cashAtBankAccount.toString()} textAlign={'right'}
                                   numberFormat={"fractional"}/>
                )
            },
            {
                key: 'interestReceivingDuringTheYear',
                name: formatMessage({id: 'calculator_interest_title_interestReceivingDuringTheYear'}),
                fieldName: 'interestReceivingDuringTheYear',
                minWidth: 120,
                //maxWidth: 120,
                flexGrow: 1,
                onRender: (item) => (
                    <SanitizedText data={item.interestReceivingDuringTheYear.toString()} textAlign={'right'}
                                   numberFormat={"fractional"}/>
                )
            },
            {
                key: 'interestReceivedComparedToCashOnHand',
                name: formatMessage({id: 'calculator_interest_title_interestReceivedComparedToCashOnHand'}),
                fieldName: 'interestReceivedComparedToCashOnHand',
                minWidth: 120,
                //maxWidth: 120,
                flexGrow: 1,
                onRender: (item) => (
                    <SanitizedText
                        data={(item.interestReceivedComparedToCashOnHand ? item.interestReceivedComparedToCashOnHand / 100 : 0).toString()}
                        textAlign={'right'}
                        numberFormat={"percentage-fractional"}/>
                )

            },
            {
                key: 'reviewed',
                name: formatMessage({id: 'calculator_interest_title_reviewed'}),
                fieldName: 'reviewed',
                minWidth: 180,
                //maxWidth: 180,
                flexGrow: 1,
                onRender: (item, index) => (
                    index !== undefined ? <DropdownAnswer options={reviewOptions}
                                                          onChange={(answer) => setValue(`items.${index}.reviewed`, answer)}
                                                          value={item.reviewed || ''}/> : null
                )
            }
        ]
    }, [interestData]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        }
    }));

    useEffect(() => {
        setInterestData(data?.listData as IInterestCalculatorData[]);
    }, [data?.listData])

    return (
        <Stack tokens={{childrenGap: 16}}>
            <CalculatorDataTable
                initialColumns={columns}
                enableShimmer={isUpdating}
                shimmerLines={fields.length}
                columns={columns}
                header={{
                    rowHeight: 80,
                    horizontalAlign: 'center'
                }}
                items={fields || []}/>
        </Stack>
    );
});