import * as React from 'react';
import { FunctionComponent } from 'react';
import { Stack } from '@fluentui/react';
import { MaterialityCalculatorSettings } from './components/MaterialityCalculatorSettings';

/**
 * Don't know why we need use this wrapper...
 * TODO: Remove this component and use MaterialityCalculatorSettingsOnly...
 */

export const MaterialityCalculatorSettingsPage: FunctionComponent = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <MaterialityCalculatorSettings />
        </Stack>
    );
};
