import {Shimmer, ShimmerElementType, Stack} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {ContactsAddItemModal} from "../../ContactsAddItemModal";
import {useIntl} from "react-intl";
import {ClientContactInfo, DataTableFilter} from "../../../../shared";
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {ContactsDataTable} from "../../ContactsTable";

type ContactTabPropsType = {
    contacts: ClientContactInfo[];
    clientId: number;
};

export const ContactTab = ({contacts, clientId}: ContactTabPropsType) => {
    const [filteredContacts, setFilteredContacts] = useState<ClientContactInfo[] | null>(null);
    const [activeStatusFilter, setActiveStatusFilter] = useState<EntityStatus | null>(null);
    const [searchString, setSearchString] = useState('');
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const {formatMessage} = useIntl();

    useEffect(() => {
        if (contacts) {
            const filteredByStatus = activeStatusFilter ? contacts?.filter((contact) => contact.status === activeStatusFilter) : contacts;
            const filteredBySearch = filteredByStatus.filter((contact) =>
                `${contact?.firstName || ''} ${contact?.lastName || ''}`.includes(searchString)
            );
            setFilteredContacts(filteredBySearch);
        }
    }, [activeStatusFilter, contacts, searchString]);

    const onSearch = (newValue: string) => {
        setSearchString(newValue);
    };

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <DataTableFilter onAddClick={() => setShowAddModal(true)}
                                 search={{
                                     onSearch: onSearch,
                                     onClear: () => setSearchString('')
                                 }}
                                 status={{selectedStatus: activeStatusFilter, changeStatus: setActiveStatusFilter}}
                />
                <ContactsAddItemModal
                    isOpen={showAddModal}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({id: 'addingNewItem'})}
                    clientId={clientId}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                {filteredContacts ? (
                    <ContactsDataTable clientId={clientId} contacts={filteredContacts}/>
                ) : (
                    <Shimmer shimmerElements={[{type: ShimmerElementType.line, height: 600}]}/>
                )}
            </Stack.Item>
        </Stack>
    );
};
