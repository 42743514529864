import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import {INavLink, Stack} from '@fluentui/react';
import {ModalWithForm, VerticalMenu} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useBoolean} from '@fluentui/react-hooks';
import {ContactDetailsTab, GeneralContactTab} from './tabs';
import {useUpdateContactDetailsInfo, useUpdateContactInfo} from "../../hooks";
import {useGetClientContactById} from "../../hooks/useGetClientContactById";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    clientId: number;
    contactId: number;
}

export enum ContactModalTabs {
    GENERAL_CONTACT,
    CONTACT_DETAILS,
    GENERAL_TRUSTEE_CONTACT,
}

export interface IChangeContactInfoCommand {
    firstName: string;
    lastName: string;
    companyName?: string;
    jobTitle?: string | null;
    directPhone?: string;
    mobilePhone?: string;
    email?: string;
}

export const ContactModal: FunctionComponent<Props> = observer(({
                                                                    onDismiss,
                                                                    isOpen,
                                                                    title,
                                                                    clientId,
                                                                    contactId
                                                                }: Props) => {

    const {contact: contactResponse, isContactLoading} = useGetClientContactById(contactId, clientId);
    const {control, handleSubmit, formState, reset, setValue} = useForm<IChangeContactInfoCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            companyName: contactResponse?.data?.companyName || '',
            jobTitle: contactResponse?.data?.jobTitle || '',
            directPhone: contactResponse?.data?.directPhone || '',
            mobilePhone: contactResponse?.data?.mobilePhone || '',
            email: contactResponse?.data?.email || '',
            firstName: contactResponse?.data?.firstName || '',
            lastName: contactResponse?.data?.lastName || '',
        },
    });
    const {updateClientContact, isUpdating: isMainInfoUpdating} = useUpdateContactInfo();
    const {updateClientContactDetails, isUpdating: isDetailsUpdating} = useUpdateContactDetailsInfo();

    const isUpdating = useMemo(() => {
        return isMainInfoUpdating || isDetailsUpdating;
    }, [isMainInfoUpdating, isDetailsUpdating])

    useEffect(() => {
        if (contactResponse?.data) {
            setValue('companyName', contactResponse?.data?.companyName || '');
            setValue('jobTitle', contactResponse?.data?.jobTitle || null);
            setValue('directPhone', contactResponse?.data?.directPhone);
            setValue('mobilePhone', contactResponse?.data?.mobilePhone);
            setValue('email', contactResponse?.data?.email);
            setValue('firstName', contactResponse?.data?.firstName || '');
            setValue('lastName', contactResponse?.data?.lastName || '');
        }
    }, [contactResponse?.data, setValue]);

    const onSubmit = (data: IChangeContactInfoCommand) => {
        switch (activeTab) {
            case ContactModalTabs.GENERAL_CONTACT:
                updateClientContact({
                    contactId: contactResponse?.data?.id || 0,
                    clientId: clientId || 0,
                    jobTitle: data.jobTitle || null,
                    lastName: data.lastName || '',
                    firstName: data.firstName || ''
                })
                return;
            case ContactModalTabs.CONTACT_DETAILS:
                updateClientContactDetails({
                    contactId: contactResponse?.data?.id || 0,
                    clientId: clientId || 0,
                    directPhone: data.directPhone,
                    email: data.email,
                    mobilePhone: data.mobilePhone
                });
                return;
        }

        onDismiss();
    };

    const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);
    const [activeTab, setActiveTab] = useState<ContactModalTabs>(ContactModalTabs.GENERAL_CONTACT);

    const [links] = useState<INavLink[]>([
        {
            name: 'General',
            url: '',
            key: ContactModalTabs[ContactModalTabs.GENERAL_CONTACT],
            iconProps: {iconName: 'Settings'},
            onClick: () => {
                setActiveTab(ContactModalTabs.GENERAL_CONTACT);
            },
        },
        {
            name: 'Contact Details',
            url: '',
            key: ContactModalTabs[ContactModalTabs.CONTACT_DETAILS],
            iconProps: {iconName: 'ComplianceAudit'},
            onClick: () => {
                setActiveTab(ContactModalTabs.CONTACT_DETAILS);
            },
        },
    ]);

    return (
        <ModalWithForm isOpen={isOpen}
                       onDismiss={()=>{reset();onDismiss();setActiveTab(ContactModalTabs.GENERAL_CONTACT);}}
                       title={title}
                       minHeight={true}
                       submitDisabled={!formState.isDirty}
                       onSubmit={handleSubmit(onSubmit)}
                       isSubmitting={isUpdating}
                       closeAfterSubmit
        >
            <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -16px'}}}>
                <VerticalMenu selectedKey={ContactModalTabs[activeTab || ContactModalTabs.GENERAL_CONTACT]}
                              links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>

                <Stack
                    styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`, height: '100%'}}}
                    tokens={{childrenGap: 16}}>
                    <Stack.Item styles={{root: {height: 'calc(100% - 50px)'}}}>
                        {activeTab === ContactModalTabs.GENERAL_CONTACT ? (
                            <GeneralContactTab control={control}/>
                        ) : (
                            <ContactDetailsTab control={control}/>
                        )}
                    </Stack.Item>
                </Stack>
            </Stack>
        </ModalWithForm>
    );
});
