import {useContext} from "react";
import {Context} from "../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IJobReviewDetails} from "../interfaces";

function useGetJobReviewDetails(jobGuid: string) {
    const context = useContext(Context);
    const host = context?.config?.jobUrl;

    const {
        data: jobReviewDetails,
        isLoading,
        isFetching
    } = useQuery(['getJobReviewDetails', jobGuid], () => axios.get<IJobReviewDetails>(`${host}/api/1.0/jobs/${jobGuid}/reviewDetails`).then(resp => resp.data))
    return {jobReviewDetails, isLoading, isFetching};
}

export {useGetJobReviewDetails};