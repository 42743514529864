import { DashboardFilter, IDashboardFilter } from './DashboardFilter';
import { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Stack } from '@fluentui/react';
import { Widgets } from './Widget';
import { useGetJobYears, useGetShortClientInfos, useGetShortFundInfos, useGetWidgetData, WidgetData } from '..';
import { useForm } from 'react-hook-form';
import { DashboardContent } from './DashboardContent';
import { debounce } from 'lodash';
import { DashboardTabsEnum } from '../enums';

export type GetDashboardConfig = (widgetData: WidgetData) => IDashboardConfig;

export interface IDashboardConfig {
	id: string | number;
	rows: Widgets[][];
}

export interface IDashboardContainer {
	dashboardTab: DashboardTabsEnum;
	getDashboardConfig: GetDashboardConfig;
	isWorkflow?: boolean;
}

export interface IDashboardContext {
	dashboardTab: DashboardTabsEnum;
	filterData: IDashboardFilter;
}

const defaultSearchValues: IDashboardFilter = {
	clientGuid: '',
	fundGuid: '',
	filterByYear: '',
};

const defaultContextValues: IDashboardContext = {
	dashboardTab: DashboardTabsEnum.AP_Administrator,
	filterData: defaultSearchValues,
}

const WorkflowDashboardContext = createContext<IDashboardContext | undefined>(undefined);

export const useWorkflowDashboard = () => useContext(WorkflowDashboardContext);

export const DashboardContainer: FunctionComponent<IDashboardContainer> = ({ dashboardTab, getDashboardConfig, isWorkflow = false }) => {
	const { control, watch, reset, resetField } = useForm<IDashboardFilter>({
		mode: 'onChange',
		defaultValues: defaultSearchValues,
	});
	const [filterData, setFilterData] = useState<IDashboardFilter>(defaultSearchValues);

	const { shortClientInfos, isShortClientInfosLoading } = useGetShortClientInfos({ dashboardTab });
	const { shortFundInfos, isShortFundInfosLoading } = useGetShortFundInfos({ clientGuid: filterData.clientGuid, dashboardTab });
	const { jobYears, isJobYearsLoading } = useGetJobYears({ clientGuid: filterData.clientGuid, fundGuid: filterData.fundGuid, dashboardTab });
	const { widgetData, isWidgetDataLoading } = useGetWidgetData({ dashboardTab, isWorkflow, ...filterData });

	const isFilterLoading = isJobYearsLoading || isShortClientInfosLoading || isShortFundInfosLoading;
	const dashboardConfig = useMemo(() => (!isWidgetDataLoading && widgetData?.data) ? getDashboardConfig(widgetData.data) : null, [getDashboardConfig, isWidgetDataLoading, widgetData]);
	const onSubmit = (newFilterData: IDashboardFilter) => setFilterData(newFilterData);
	const clientGuid = watch('clientGuid');
	const fundGuid = watch('fundGuid');

	useEffect(() => {
			const subscription = watch(debounce(onSubmit, 100));
			return () => subscription.unsubscribe();
	}, [watch]);
	
	useEffect(() => {
		resetField('fundGuid');
		resetField('filterByYear');
	}, [clientGuid, resetField]);
	
	useEffect(() => {
		resetField('filterByYear');
	}, [fundGuid, resetField]);

	return (
		<WorkflowDashboardContext.Provider value={{ filterData, dashboardTab }}>
			<Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
				<DashboardFilter
					isLoading={isFilterLoading}
					jobYears={jobYears}
					shortClientInfos={shortClientInfos}
					shortFundInfos={shortFundInfos}
					control={control}
					reset={reset}/>
				<Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
					<DashboardContent dashboardConfig={dashboardConfig} isLoading={isWidgetDataLoading} />
				</Stack>
			</Stack>
		</WorkflowDashboardContext.Provider>
	);
};