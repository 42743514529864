import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

interface IGetAttachmentsParams {
  fundId: number;
  jobId: number;
  itemId: number;
  tableType: number;
  year: number;
}

export interface IAttachment {
  id: number;
  itemId: number;
  fileName: string;
  jobId: number;
  fundId: number;
  isPermanent: boolean;
  tableType: number;
  documentType: number;
  downloadUrl: string;
  isEdited: true;
  originalFileName: string;
}

function useGetAttachments({ fundId, jobId, itemId, tableType, year }: IGetAttachmentsParams) {
  const context = React.useContext(Context);

  const { data: attachmentsData, isLoading: isAttachmentsLoading, refetch: refetchAttachments, isFetching: isAttachmentsFetching } = useQuery(
    ['getAttachments', fundId, jobId, itemId, tableType, year],
    () => axios.get<IAttachment[]>(
      `${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/items/${itemId}/attachments?tableType=${tableType}&year=${year}`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
    
      retry: false,
    }
  );

  return { attachmentsData, isAttachmentsLoading, refetchAttachments, isAttachmentsFetching };
}

export { useGetAttachments };