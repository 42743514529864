import React, {FunctionComponent, useEffect, useState} from 'react';
import {ComboBox, IComboBoxOption, IIconProps, Toggle, TooltipHost} from '@fluentui/react';
import {observer} from 'mobx-react';
import {useIntl} from 'react-intl';
import {useRulesetsStore} from '../RulesetsPage';
import {SearchRow} from "../../../../components/SearchRow";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {RulesetsNew} from "./RulesetsNew";
import {IconButton} from "@fluentui/react/lib/Button";

export const RulesetsFilters: FunctionComponent = observer(() => {
    const [includeDeleted, {toggle: toggleIncludeDeleted}] = useBoolean(false);
    const [currentCategory, setCurrentCategory] = useState<string>('');
    const [searchString, setSearchString] = useState('');
    const [isAddModalOpen, {toggle: toggleIsAddModalOpen}] = useBoolean(false);
    const add: IIconProps = {iconName: 'add'};
    const addBtnToolTipId = useId('add-tooltip');

    const {
        rulesetsFilters,
        rulesetsLoading,
        setFilters,
        loadRulesets,
        getCategories,
        categories,
        categoriesLoading
    } = useRulesetsStore();
    const {formatMessage} = useIntl();

    const comboBoxOptions: IComboBoxOption[] = categories.map((category) => ({
        id: category.name,
        key: category.name!,
        text: category.name!,
    }));

    const onSearch = (newValue: string) => setSearchString(newValue);

    const onClear = () => setSearchString('');

    useEffect(() => {
        if (!categories.length && !categoriesLoading) {
            getCategories();
        }
    }, [categories.length, categoriesLoading, getCategories]);

    useEffect(() => {
        setFilters({
            ...rulesetsFilters,
            workflowName: searchString,
            includeDeleted: includeDeleted,
            category: currentCategory
        });
        loadRulesets();

    }, [includeDeleted, currentCategory, searchString]);

    const leftSide = <ComboBox
        styles={{root: {maxWidth: 250}}}
        placeholder={formatMessage({id: 'category'})}
        dropdownWidth={250}
        options={comboBoxOptions}
        autoComplete='on'
        onChange={(event, option, index, value) => {
            setCurrentCategory(option?.text || '');
        }}
        allowFreeform
    />

    const rightSide = (<>
            <TooltipHost
                content={formatMessage({id: 'add'})}
                id={addBtnToolTipId}>
                <IconButton aria-describedby={addBtnToolTipId} iconProps={add}
                            onClick={toggleIsAddModalOpen} label='Add'/>
            </TooltipHost>
        </>
    )
    return (
        <>
            <SearchRow leftSide={leftSide} rightSide={rightSide} showAdditionalFilter={true} search={{
                onSearch: onSearch, onClear: onClear
            }}>
                <Toggle onChange={() => {
                    toggleIncludeDeleted();
                }}
                        checked={includeDeleted}
                        label={formatMessage({id: 'includeDeleted'})}/>
            </SearchRow>
            <RulesetsNew isModalOpen={isAddModalOpen} toggleIsModalOpen={toggleIsAddModalOpen}/>

        </>

    );
});
