import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {IJobRiskMatrixDetailedSetting} from "../interfaces";

function useGetDetailedSettings(jobId: number) {
    const context = useContext(Context);
    const host = context?.config?.riskMatrixUrl;
    const {
        data: detailedSettings,
        isLoading
    } = useQuery(["getJobRIskMatrixDetailed", jobId], () => axios.get<{detailedSettings:IJobRiskMatrixDetailedSetting[]}>(`${host}/api/1.0/jobs/${jobId}/riskMatrix/detailed`).then(resp => resp.data.detailedSettings));

    return {detailedSettings, isLoading};
}

export {useGetDetailedSettings};