import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { ControlledDropdown, ControlledTextField } from 'components';
import {useAdministrationContext} from "../../JobsLayoutPage";
import { useGetJobYears } from '../../hooks/useGetJobYears';
import { useCreateJob } from '../../hooks/useCreateJob';

interface Props {
    open: boolean;
    toggle: () => void;
    title?: string;
    useSelectedFund?: boolean;
    useSelectedClient?: boolean;
}

interface IClientForm {
    clientName: string;
    fundName: string;
    jobYear: number;
    fundId: number;
    fundGuid: string;
}

export const AddJobModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    const { selectedClient, targetClient, targetFund, selectedFund } = useAdministrationContext();
    const fund = props.useSelectedFund ? selectedFund : targetFund;
    const client = props.useSelectedClient ? selectedClient : targetClient;

    const clientName = client?.name || '';
    const fundName = fund?.name || '';

    const { jobYears } = useGetJobYears();
    const { createJob, isLoading } = useCreateJob();

    const yearsOptions = useMemo(() => {
        return jobYears?.data?.filter(x => x.isSelected).sort((x, y) => x.year > y.year ? -1 : 1).map((year) => ({
            key: year.year,
            text: year.year.toString(),
        })) ?? [];
    }, [jobYears?.data]);

    const { control, handleSubmit, setValue, formState } = useForm<IClientForm>({
        ...DefaultFormSettings,
        defaultValues: {
            clientName,
            fundName,
            jobYear: 0,
        },
    });

    useEffect(() => {
        setValue('clientName', client?.name || '');
        setValue('fundName', fund?.name || '');
        setValue('fundId', fund?.id || 0);
        setValue('fundGuid', fund?.guid || '');
    }, [client, fund, setValue]);

    const onSubmit = (data: IClientForm) => {
        createJob({
            fundId: data.fundId,
            year: data.jobYear,
            fundGuid: data.fundGuid,
            fundName: fundName,
            clientId: client?.id,
        },
            {
                onSuccess: () => props.toggle()
            }
        );
    }

    return (
        <Modal title={props?.title || formatMessage({ id: 'addJob' })} isOpen={props.open} onDismiss={props.toggle}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Card>
                    <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                        <Stack.Item>
                            <ControlledTextField name='clientName' control={control} label={formatMessage({ id: 'clientName' })} disabled />
                        </Stack.Item>
                        <Stack.Item>
                            <ControlledTextField name='fundName' control={control} label={formatMessage({ id: 'fundName' })} disabled />
                        </Stack.Item>
                        <Stack.Item>
                            <ControlledDropdown
                                name='jobYear'
                                control={control}
                                label={formatMessage({ id: 'jobYear' })}
                                options={yearsOptions}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>

                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty || isLoading}
                        onClick={handleSubmit((data) => onSubmit(data))}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
};
