import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';


function useGetPriorJob(id: number) {
	const context = React.useContext(Context);

	const {data: priorJobData, isFetching: isPriorJobLoading, refetch: refetchPriorJob, isRefetching: isPriorJobRefetching} = useQuery(['getPriorJob', id], () =>
			axios.get(`${context?.config?.jobUrl}/api/1.0/Job/${id}/prior`, commonHeaders()), {
			staleTime: Infinity,
			enabled: !!context?.config?.jobUrl && !!id,
		}
	);

	return {priorJobData, isPriorJobLoading, refetchPriorJob, isPriorJobRefetching};
}

export {useGetPriorJob};
