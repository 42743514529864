import * as React from 'react';
import { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { INavLink, MessageBarType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Layout, PageWithBreadcrumb } from 'components';
import { RouterPaths } from '../../navigation';
import { IInvoicePortalContext } from './interfaces';
import { useGetClientFunds, useGetClientInfo, useGetStatus, useGetYears } from './hooks';
import { NotificationType, useSignalR } from '../../providers';
import { useNotifications } from '../../components/notifications';
import { NotFoundPage } from 'pages/shared';

const InvoicePortalContext = createContext<IInvoicePortalContext>({
    clientIdentifier: '',
    clientId: 0,
    funds: [],
    years: [],
});

export const useInvoicePortalContext = (): IInvoicePortalContext => {
    return useContext(InvoicePortalContext);
};

export const InvoicePortalLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const { search } = useLocation();

    const { useSignalREffect } = useSignalR();
    const { showNotification } = useNotifications();

    const clientIdentifier = useMemo(() => new URLSearchParams(search).get('clientId')!, [search]);
    const { clientInfo, isClientInfoLoading } = useGetClientInfo(clientIdentifier);
    const { clientFunds, isClientFundsLoading } = useGetClientFunds(clientIdentifier);
    const { years, isYearsLoading } = useGetYears();
    const { statusInfo, isStatusInfoLoading } = useGetStatus(clientIdentifier);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({ id: 'invoices' }),
            url: `/invoice-portal/invoices${search ?? ''}`,
            key: 'invoices',
            iconProps: { iconName: 'M365InvoicingLogo' },
        },
        {
            name: formatMessage({ id: 'creditNotes' }),
            url: `/invoice-portal/creditnotes${search ?? ''}`,
            key: 'creditNotes',
            iconProps: { iconName: 'NoteReply' },
        },
        {
            name: formatMessage({ id: 'otherClients' }),
            url: `/invoice-portal/otherclients${search ?? ''}`,
            key: 'otherclients',
            iconProps: { iconName: 'NoteReply' },
        },
    ]);

    useSignalREffect(
        'invoice_generated',
        (notification: NotificationType) => {
            const binaryString = window.atob(notification.content.FileContent);
            const binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            let FileSaver = require('file-saver');
            let blob = new Blob([bytes], { type: 'application/pdf' });
            FileSaver.saveAs(blob, notification.content.FileName);

            showNotification({
                type: MessageBarType.success,
                name: formatMessage({ id: 'success' }),
                description: formatMessage({ id: notification.message }),
            });
        },
        [formatMessage]
    );

    if (!clientIdentifier) {
        return <NotFoundPage/>;
    }

    return (
        <Layout links={links}>
            {!clientInfo || isClientInfoLoading || isClientFundsLoading || isYearsLoading || isStatusInfoLoading ? (
                <Stack horizontalAlign={'center'} tokens={{ childrenGap: 16 }}>
                    <Stack.Item>
                        <Spinner size={SpinnerSize.medium} label={formatMessage({ id: 'loading' })} />
                    </Stack.Item>
                </Stack>
            ) : (
                <PageWithBreadcrumb
                    breadcrumb={{
                        id: 'root',
                        key: RouterPaths.invoices.root(clientInfo?.guid),
                        text: clientInfo?.name ?? formatMessage({ id: 'invoices' }),
                    }}>
                    <InvoicePortalContext.Provider
                        value={{
                            clientIdentifier: clientIdentifier,
                            clientId: clientInfo?.id,
                            funds: clientFunds ?? [],
                            years: years ?? [],
                        }}>
                        <Outlet />
                    </InvoicePortalContext.Provider>
                </PageWithBreadcrumb>
            )}
        </Layout>
    );
};
