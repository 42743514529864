import React from 'react';
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";


interface IGetUserClientsParams {

}

export interface IClientInfo {
    id: number;
    guid: string;
    name: string;
}

function useGetUserClients({}: IGetUserClientsParams) {
    const context = React.useContext(Context);


    const {
        data: clientsData,
        isLoading: isClientsLoading,
        refetch: refetchClients,
        isFetching: isClientsFetching
    } = useQuery(
        ['getUserClients'],
        () => axios.get<IClientInfo[]>(
            `${context?.config?.clientUrl}/api/1.0/clients/mine`,
            {...commonHeaders()}
        ),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId,

            retry: false,
        }
    );

    return {clientsData, isClientsLoading, refetchClients, isClientsFetching};
}

export {useGetUserClients};
