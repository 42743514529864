import {useIntl} from "react-intl";
import {CalculatorRow} from "../shared";
import {Spinner, Stack} from "@fluentui/react";
import {useUpdateCalculatorData} from "../../hooks";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {CalculatorType} from "../../CalculatorType";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {ITaxRecExemptPensionIncomeNetMethodData} from "./ITaxRecExemptPensionIncomeNetMethodData";
import {CalculatorTemplateProps} from "../../types";
import {useJobContext} from "../../../../JobPortalLayoutPage";

type TaxRecExemptPensionIncomeNetMethodCalculatorProps = CalculatorTemplateProps
export const TaxRecExemptPensionIncomeNetMethodCalculator = forwardRef(({
                                                                            data
                                                                        }: TaxRecExemptPensionIncomeNetMethodCalculatorProps, ref) => {
    const {formatMessage} = useIntl();
    const{jobId}=useJobContext();
    const {update, isUpdating} = useUpdateCalculatorData();
    const [netData, setNetData] = useState<ITaxRecExemptPensionIncomeNetMethodData>(data?.data as ITaxRecExemptPensionIncomeNetMethodData);
    const {watch, handleSubmit, setValue} = useForm<ITaxRecExemptPensionIncomeNetMethodData>({
        ...DefaultFormSettings,
        defaultValues: {
            ...data?.data,
        }
    });
    const watchFields = watch()
    const updateCalcData = (netMethodData: ITaxRecExemptPensionIncomeNetMethodData) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.TaxRecExemptPensionIncomeNetMethod,
            isCustom: false,
            answer: netMethodData,
            jobId: jobId
        });
    };

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        }
    }));


    const netIncomeExemption = useMemo(() => {

        return (watchFields.taxableIncome || 0) + (watchFields.exemptPensionIncome || 0) - (watchFields.taxableContributions || 0);

    }, [watchFields]);


    const exemptPensionIncomeTotal = useMemo(() => {

        return netIncomeExemption * (watchFields.exemptPortionPerActuarial || 0) / 100;

    }, [watchFields]);

    useEffect(() => {
        setNetData(data?.data as ITaxRecExemptPensionIncomeNetMethodData);
    }, [data?.data]);

    const isTaxableIncomeChanged = useMemo(() => {
        return netData?.isTaxableIncomeChanged || watchFields.taxableIncome != netData?.taxableIncome;
    }, [watchFields]);

    const isExemptPensionIncomeChanged = useMemo(() => {
        return netData?.isExemptPensionIncomeChanged || watchFields.exemptPensionIncome != netData?.exemptPensionIncome;
    }, [watchFields]);

    const isTaxableContributionsChanged = useMemo(() => {
        return netData?.isTaxableContributionsChanged || watchFields.taxableContributions != netData?.taxableContributions;
    }, [watchFields]);

    const isExemptPortionPerActuarialChanged = useMemo(() => {
        return netData?.isExemptPortionPerActuarialChanged || watchFields.exemptPortionPerActuarial != netData?.exemptPortionPerActuarial;
    }, [watchFields]);

    const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

    const handleClickOutside = (event: any) => {
        if((event.srcElement.id as string).includes('TextField'))
            return;

        setEditingRow(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    if(isUpdating)
        return <Spinner />;

    return (
        <Stack tokens={{childrenGap: 16}}>
            <CalculatorRow
                iconType={'plus'}
                changed={isTaxableIncomeChanged}
                onChangeValue={newValue => {
                    setValue('taxableIncome', newValue);
                    setValue('isTaxableIncomeChanged', true);
                }}
                label={formatMessage({id: 'calculator_taxRecNet_Assessable_Income'})}
                value={netData?.taxableIncome}
                onClick={()=> setEditingRow('taxableIncome')}
                editing={editingRow === 'taxableIncome'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'plus'}
                changed={isExemptPensionIncomeChanged}
                onChangeValue={newValue => {
                    setValue('exemptPensionIncome', newValue);
                    setValue('isExemptPensionIncomeChanged', true);
                }}
                label={formatMessage({id: 'calculator_taxRecNet_Exempt_Pension_Income'})}
                value={netData?.exemptPensionIncome}
                onClick={()=> setEditingRow('exemptPensionIncome')}
                editing={editingRow === 'exemptPensionIncome'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'minus'}
                changed={isTaxableContributionsChanged}
                onChangeValue={newValue => {
                    setValue('taxableContributions', newValue);
                    setValue('isTaxableContributionsChanged', true);
                }}
                label={formatMessage({id: 'calculator_taxRecNet_Taxable_Contributions'})}
                value={netData?.taxableContributions}
                onClick={()=> setEditingRow('taxableContributions')}
                editing={editingRow === 'taxableContributions'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({id: 'calculator_taxRecNet_Net_Income_Subject_To_Exemption'})}
                value={netIncomeExemption}/>
            <CalculatorRow
                suffix='%'
                iconType={'percentage'}
                changed={isExemptPortionPerActuarialChanged}
                onChangeValue={newValue => {
                    setValue('exemptPortionPerActuarial', newValue);
                    setValue('isExemptPortionPerActuarialChanged', true);
                }}
                label={formatMessage({id: 'calculator_taxRecNet_Exempt_Portion_Per_Actuarial_Certificate_Sighted'})}
                value={netData?.exemptPortionPerActuarial}
                onClick={()=> setEditingRow('exemptPortionPerActuarial')}
                editing={editingRow === 'exemptPortionPerActuarial'}/>
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({id: 'calculator_taxRecNet_Exempt_Pension_Income'})}
                value={exemptPensionIncomeTotal}/>
        </Stack>
    );
});