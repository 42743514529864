import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {useCommonErrorNotification, useNotifications} from "../../../../components/notifications";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";

type MutateType = {
    jobGuid: string;
    invoiceDate?: Date;
    invoiceAmount?: string;
    invoiceNumber?: string;
};

function useUpdateJobInvoiceInfo() {
    const context = React.useContext(Context);
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();

    const {mutate: updateJobInvoiceInfo, isLoading: isJobInvoiceInfoUpdating} = useMutation(
        (data: MutateType) =>
            axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobGuid}/invoice`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getJobsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Job invoice info'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateJobInvoiceInfo, isJobInvoiceInfoUpdating};
}


export {useUpdateJobInvoiceInfo};