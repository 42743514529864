import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type RiskMatrixDetailedRes = {
    id: number;
    name: string;
    reference: string;
    valueOfTotalAssets: number | null;
    optionValue: number;
}[];

/**
 * Query for get Risk Matrix Detaied data in Portal Settings.
 * Second table in page Risk Matrix Settings
 */

function useGetRiskMatrixDetailed() {
    const context = React.useContext(Context);
    const { data: riskMatrixDetailed, isLoading: getRiskMatrixDetailedLoading } = useQuery(
        ['getRiskMatrixDetailed'],
        () => axios.get<RiskMatrixDetailedRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/detailedRiskMatrix`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { riskMatrixDetailed, getRiskMatrixDetailedLoading };
}

export { useGetRiskMatrixDetailed };
