import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { IDropdownOption, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { IJobImportData, useResetImport, useUploadImport } from '../hooks';
import { DeleteAllImports } from './DeleteAllImports';
import { ControlledDropdown, ControlledDropzone, Modal } from 'components';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { useBoolean } from '@fluentui/react-hooks';
import { ImportReportError } from './ImportReportError';
import { DetailedSoftwareType, TableType } from '../../../enums';

interface IImportReportFormProps {
    jobImportData: IJobImportData;
    disabledButtons: boolean;
    onImport?: (reportTypeKey: string) => void;
    onDelete?: () => void;
    selectedKey?: string | null;
}

interface IImportForm {
    statement: string;
    files: File[];
}

export const ImportReportForm: FunctionComponent<IImportReportFormProps> = ({
    jobImportData,
    disabledButtons,
    onImport,
    onDelete,
    selectedKey,
}) => {
    const { formatMessage } = useIntl();
    const [isOpenModal, { setTrue: setOpenModal, setFalse: setCloseModal }] = useBoolean(false);

    const { data: uploadData, uploadImport, isLoading: isUploadLoading } = useUploadImport();
    const { deleteAllReports, isLoading: isDeleteAllReportsLoading } = useResetImport();

    const { control, handleSubmit, watch, setValue, reset } = useForm<IImportForm>({
        ...DefaultFormSettings,
        defaultValues: { statement: '', files: [] },
    });

    const selectedStatementKey = watch('statement');
    const selectedFiles = watch('files');

    const [isCombinedReportRequired] = useBoolean(
        jobImportData.detailedSoftwareType === DetailedSoftwareType.ClassSuperPDF && !jobImportData.isImportCalled
    );
    const selectionFormAvailable = !isCombinedReportRequired;

    const { descriptionMessage, availableUploads, disableUpload } = useMemo(() => {
        const validationDetails = { descriptionMessage: '', disableUpload: false, availableUploads: 0 };
        const selectedStatement = jobImportData.statements.find((statement) => statement.value === selectedStatementKey);

        if (isCombinedReportRequired) {
            validationDetails.availableUploads = 1;
            return validationDetails;
        }

        if (selectedStatementKey && selectedStatement) {
            const { maxAvailableUploads, usedUploads } = selectedStatement;
            validationDetails.availableUploads = maxAvailableUploads - usedUploads;
            validationDetails.disableUpload = validationDetails.availableUploads <= 0;
            if (validationDetails.disableUpload) {
                validationDetails.descriptionMessage = formatMessage({ id: 'theReportHasAlreadyBeenUploaded' });
            } else {
                validationDetails.descriptionMessage =
                    maxAvailableUploads === 1
                        ? formatMessage({ id: 'youCanUpload1Report' })
                        : formatMessage({ id: 'uploadedReportsYouCanUpload' }, { maxAvailableUploads, usedUploads });
            }
        }

        return validationDetails;
    }, [selectedStatementKey, jobImportData.statements, formatMessage]);

    useEffect(() => {
        if (isCombinedReportRequired && selectedFiles.length > 0) {
            handleSubmit(() => {
                onSubmit({ files: selectedFiles, statement: 'MultipleReportsTableType' });
            })();
        }
    }, [selectedFiles, isCombinedReportRequired]);

    useEffect(() => {
        if (selectedKey) {
            setValue('statement', selectedKey);
        }
    }, [selectedKey]);

    const onSubmit = ({ files, statement }: IImportForm) => {
        const formData: any = new FormData();
        if (!files && !statement) return;
        files.forEach((file) => {
            formData.append('Files', file, file.name);
        });
        formData.append('ReportType', statement);
        uploadImport({ data: formData, jobId: jobImportData.jobId });
        onImport?.(statement);
    };

    const onDeleteAllImports = () => {
        deleteAllReports(jobImportData.jobId);
        onDelete?.();
        reset();
    };

    const options: IDropdownOption[] = useMemo(
        () =>
            jobImportData.statements.map((statement) => ({
                text: statement.name,
                key: statement.value,
            })),
        [jobImportData]
    );
    const isLoading = disabledButtons || isDeleteAllReportsLoading || isUploadLoading;

    useEffect(() => {
        if (uploadData?.status >= 400) setOpenModal();
    }, [setOpenModal, uploadData?.status]);

    return (
        <Stack>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 16 }}>
                    <Stack.Item styles={{ root: { width: '200px' } }}>
                        <ControlledDropzone
                            label={formatMessage({ id: 'chooseFile' })}
                            name='files'
                            control={control}
                            preview
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                                validate: {
                                    tooMuchFiles: (value) =>
                                        (value as File[])?.length <= availableUploads ||
                                        formatMessage({ id: 'tooMuchFiles' }, { availableUploads }),
                                },
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        {selectionFormAvailable && (
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Stack.Item styles={{ root: { width: 300 } }}>
                                    <ControlledDropdown
                                        placeholder={formatMessage({ id: 'chooseAStatementType' })}
                                        rules={{ required: formatMessage({ id: 'requiredField' }) }}
                                        label={formatMessage({ id: 'statementType' })}
                                        name='statement'
                                        options={options}
                                        control={control}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    {descriptionMessage && (
                                        <Stack>
                                            <Text>{descriptionMessage}</Text>
                                        </Stack>
                                    )}
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                                        <PrimaryButton
                                            text={formatMessage({ id: 'upload' })}
                                            disabled={isLoading || disableUpload}
                                            onClick={handleSubmit(onSubmit)}
                                        />
                                        {jobImportData.allowRemoveData && (
                                            <DeleteAllImports onDelete={onDeleteAllImports} isLoading={isLoading} />
                                        )}
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        )}
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            <Stack.Item>
                <Modal isOpen={isOpenModal} onDismiss={setCloseModal} width={400} minWidth={400}>
                    <ImportReportError uploadData={uploadData} />
                </Modal>
            </Stack.Item>
        </Stack>
    );
};
