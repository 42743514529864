import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { SmtpSettings } from './useGetEmailSettings';

type EmailSettingsReq = {
    smtpSettings: SmtpSettings | null;
    isTestMode: boolean;
    testEmail: string;
    crmAdminEmail: string;
};

/**Mutation query for updating email settings data in Portal Settings*/

function useUpdateEmailSettings() {
    const context = React.useContext(Context);
    const { mutate: updateEmailSettings, isLoading } = useMutation(
        (data: EmailSettingsReq) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/email`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getEmailSettings'),
        }
    );

    return { updateEmailSettings, isLoading };
}

export { useUpdateEmailSettings };
