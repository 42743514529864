import {useIntl} from "react-intl";
import {DefaultButton, mergeStyleSets, Stack, useTheme} from "@fluentui/react";
import {EntityStatus} from "../enums";

export interface IStatusFilterParams {
    default?: EntityStatus | null;
    selectedStatus: EntityStatus | null;
    changeStatus: Function;
}

export const StatusFilter = ({selectedStatus, changeStatus}: IStatusFilterParams) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const styles = mergeStyleSets({
        status: [
            {
                marginLeft: 0,
            }],
        activeStatus: [
            {
                width: 80,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
            }],
        inactiveStatus: {
            width: 80,
            borderRadius: 0,
            borderRight: 0,
            borderLeft: 0
        },
        allStatus: {
            width: 80,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0
        }
    });

    return (
        <Stack horizontal className={styles.status}>
            <DefaultButton
                theme={selectedStatus === EntityStatus.Active ? theme : theme.schemes?.default}
                toggle
                className={styles.activeStatus}
                primary={selectedStatus === EntityStatus.Active}
                checked={selectedStatus === EntityStatus.Active}
                text={formatMessage({id: 'active'})}
                id='3'
                onClick={() => changeStatus(EntityStatus.Active)}
                 
            />
            <DefaultButton
                theme={selectedStatus === EntityStatus.DeActive ? theme : theme.schemes?.default}
                className={styles.inactiveStatus}
                toggle
                primary={selectedStatus === EntityStatus.DeActive}
                checked={selectedStatus === EntityStatus.DeActive}
                text={formatMessage({id: 'inactive'})}
                id='4'
                onClick={() => changeStatus(EntityStatus.DeActive)}
                 
            />
            <DefaultButton
                theme={!selectedStatus ? theme : theme.schemes?.default}
                className={styles.allStatus}
                toggle
                checked={!selectedStatus}
                primary={!selectedStatus}
                text={formatMessage({ id: 'all' })}
                id='5'
                onClick={() => changeStatus(null)}

            />
        </Stack>)
}