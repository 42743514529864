export enum ValidationState {
    Invalid = 0,
    Valid = 100,
    ManuallyValid = 101
}

export enum ManuallyValidValidationItemAnswers {
    AcceptAsReasonable = "Accept as reasonable",
    Agreed = "Agreed",
    ClearlyTrivial = "Clearly Trivial"
}

export interface IValidationErrors {
    validationErrors: IValidationError[],
    answerOptions: any[]
}

export interface IValidationError {
    id: number;
    sNo?: number;
    options: { text: string, key: string }[],
    comments: any[],
    permanentComments: any[],
    hasComments: boolean,
    validationState: ValidationState,
    answerText: string;
    order: number;
    validationErrorItems: IValidationItem[];
    isValidItem: boolean;
    validationType: number;
    answerControlType: number;
}

export interface IValidationItem {
    id: number;
    tableType: number;
    itemId: number;
    tabId: number;
    sectionId: number;
    validationTitle?: string;
    validationItemId: number;
}