import { Stack, useTheme, Text, TextField, ActionButton, PrimaryButton, MessageBarType } from '@fluentui/react';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useNotifications } from '../../../../../../components/notifications';
import { SoftwareType } from '../../../../../../enums';
import { useUploadSoftwareFile } from '../../hooks/useUploadSoftwareFile';

interface IFileUploadBlockProps {
    job: any;
    bucketName: string;
    selectedSoftwareType: SoftwareType;
}

const getAcceptableFileExtensions = (softwareType: SoftwareType): string[] => {
    switch (softwareType) {
        case SoftwareType.BGL:
            return ['.zip',
                '.rar',
                '.7z',
                '.zipx',
                '.z01',
                '.z02',
                '.z03',
                '.z04',
                '.xlsx',
                '.xls',
                '.xlsm',
                '.xlt',
                '.xlsb',
                '.numbers',
                '.xltx',
                '.dif',
                '.csv',
                '.ods',]
        case SoftwareType.Supermate:
        case SoftwareType.ClassPdfInCp:
            return ['.pdf',
                '.zip',
                '.rar',
                '.7z',
                '.zipx',
                '.z01',
                '.z02',
                '.z03',
                '.z04',]
        default:
            return [];
    }
}

const getFileExtension = (fileName: string) => {
    return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 1).toLowerCase();
}

export const FileUploadBlock: FunctionComponent<IFileUploadBlockProps> = ({ job, bucketName, selectedSoftwareType }) => {
    const { formatMessage, formatDate } = useIntl();
    const theme = useTheme();
    const { uploadSoftwareFile, isLoading } = useUploadSoftwareFile();

    const { showNotification } = useNotifications();

    const acceptableFileExtensions = useMemo(() => {
        return getAcceptableFileExtensions(selectedSoftwareType);
    }, [selectedSoftwareType]);

    const upload = useCallback((selectedFile) => {
        const fileExtension = getFileExtension(selectedFile.name);

        if (!acceptableFileExtensions.some(x => x === fileExtension)) {
            showNotification({
                name: formatMessage({ id: 'unacceptableFileExtensionTitle' }),
                type: MessageBarType.error,
                description: fileExtension + formatMessage({ id: 'unacceptableFileExtesionMessage' }) + acceptableFileExtensions.join(', ')
            });

            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        uploadSoftwareFile({
            fundGuid: job?.fund?.guid,
            clientGuid: job?.client?.guid,
            jobGuid: job?.guid,
            softwareType: selectedSoftwareType === 'ClassPdfInCp' ? SoftwareType.ClassSuper.toString() : selectedSoftwareType,
            data: formData,
            bucketName: bucketName,
        });
    }, [bucketName, job, selectedSoftwareType])

    const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList?.length) return;

        upload(fileList[0]);
    }, []);

    const onDrop = useCallback(files => {
        if (!files?.length) return;

        upload(files[0])
    }, [])

    return (
        <Dropzone onDrop={onDrop} noDragEventsBubbling={true}>
            {({ getRootProps, getInputProps, inputRef }) => (
                <div {...getRootProps()}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, borderStyle: 'dashed' } }}>
                        <Stack.Item align={'center'} styles={{ root: {margin: '5%'}}}>
                            <Text theme={theme?.schemes?.default}>{formatMessage({ id: 'dragAndDropOr' })}</Text>
                        </Stack.Item>
                        <Stack.Item align={'center'}>
                            <PrimaryButton
                                disabled={isLoading}
                                iconProps={{ iconName: 'Upload' }}
                                width='100%'
                                text={formatMessage({ id: 'upload' })}
                                onClick={() => inputRef?.current?.click()}
                            >
                            </PrimaryButton>
                            <input
                                name='attachmentBlob'
                                ref={inputRef}
                                style={{ display: 'none' }}
                                type='file'
                                onChange={onUpload}
                                disabled={true}
                                {...getInputProps()}
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            )}
        </Dropzone>
    );
}