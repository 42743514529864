import {FunctionComponent, useCallback, useMemo, useState} from "react";
import {FontIcon, FontWeights, mergeStyles, useTheme} from "@fluentui/react";

export interface AuditQueryPublishedIconProps {
    publishedCount: number;
}

type AuditQueryIcon = { name: string, className: string }

export const AuditQueryPublishedIcon: FunctionComponent<AuditQueryPublishedIconProps> = ({ 
                                                                                             publishedCount
                                                                                         }: AuditQueryPublishedIconProps) => {
    const theme = useTheme();
    
    const [show, setShow] = useState<boolean>(false);

    const getAuditQueryIcon = useCallback((): (num: number) => AuditQueryIcon => {
        const colorPositive = theme.palette.blue;
        const colorNegative = theme.palette.green;
        
        const iconMap: { [key: number]: { name: string, styles?: {} } } = {
            1: { name: 'CircleFill' },
            2: { name: 'ChromeClose' },
            3: { name: 'SquareShapeSolid' },
            4: { name: 'TriangleShapeSolid' },
            5: { name: 'HalfCircle', styles: { transform: 'rotate(180deg)' } },
            6: { name: 'FavoriteStarFill' },
            7: { name: 'Stop', styles: { fontWeight: FontWeights.bold } },
            8: { name: 'CircleRing', styles: { fontWeight: FontWeights.bold } },
            9: { name: 'SquareShapeSolid', styles: { transform: 'rotate(45deg)' } },
            // means 10 since 10 % 10 = 0
            0: { name: 'Add', styles: { fontWeight: FontWeights.bold } }
        }

        return (num: number): AuditQueryIcon => {
            const { name, styles } = iconMap[Math.abs(num) % 10]
            return {
                name: name,
                className: mergeStyles({
                    color: num >= 0 ? colorPositive : colorNegative,
                    ...(styles ?? {})
                })
            }
        };
    }, []);
    
    const { name, className } = useMemo(() => {
        switch(publishedCount) {
            case 0: {
                setShow(false);
                return {} as AuditQueryIcon
            }
            default: {
                setShow(true);
                return getAuditQueryIcon()(publishedCount);
            }
        }
    }, [publishedCount])

    if (show) {
        return <FontIcon iconName={name} className={className}></FontIcon>
    }
    else {
        return <></>
    }
}