import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {Navigate, Outlet} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {useIntl} from 'react-intl';
import {PageWithBreadcrumb} from 'components';
import {useRulesetStore} from '../RulesetLayoutPage';

export const RulesetNewRuleLayoutPage: FunctionComponent = observer(() => {
    const {formatMessage} = useIntl();
    const {ruleset} = useRulesetStore();

    if (ruleset?.isDeleted) {
        return <Navigate replace to={RouterPaths.admin.rulesets.ruleset.root(ruleset?.id)}/>;
    }

    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'new-rule',
                text: formatMessage({id: 'new'}),
                key: RouterPaths.admin.rulesets.ruleset.rules.new(ruleset?.id),
            }}>
            <Outlet/>
        </PageWithBreadcrumb>
    );
});
