import {useContext} from "react";
import {Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IRiskNote} from "../interfaces";

interface RiskNoteResponse {
    jobId: number;
    notes: IRiskNote[];
}

function useGetRiskNotes(jobId: number) {
    const context = useContext(Context);
    const host = context?.config?.riskMatrixUrl;

    const {
        data,
        isLoading,
        refetch,
        isRefetching
    } = useQuery(['getJobRiskNotes', jobId], () => axios.get<RiskNoteResponse>(`${host}/api/1.0/jobs/${jobId}/riskMatrix/notes`).then(resp => resp.data.notes));

    return {data, isLoading, isRefetching, refetch};
}

export {useGetRiskNotes};