import {Icon, IconButton, IDropdownProps, ITextFieldProps, mergeStyleSets, Stack, useTheme} from '@fluentui/react';
import {ControlledTextField, SanitizedText} from 'components';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from "../../../../../constants";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {useUpdateItemAnswer} from "../../../hooks/answers";
import {TableType} from "../../../../../enums";
import {ColumnInfo} from "../../../enums";
import { useWorkContext } from "../../../../../providers";
import { useTabContext } from "../../../JobPortalPage";

interface TextBoxAnswerProps extends ITextFieldProps {
    value?: any;
    tableType?: TableType;
    itemId?: number;
    columnInfo?: ColumnInfo;
    onChange?: (answer: any) => void;
    onUpdate?: (answer: any) => void;
}

export const TextBoxAnswer = ({value, onChange, onUpdate, itemId, columnInfo, tableType, ...otherProps}: TextBoxAnswerProps) => {
    const {jobId} = useJobContext();
    const { isTabEnabled} = useTabContext();
    const {update, isUpdating} = useUpdateItemAnswer();
    const {palette} = useTheme();
    const [editMode, setEditMode] = useState<boolean>(false);
    const {control, handleSubmit, setValue, getValues} = useForm<{ textAnswer: string }>({
        ...DefaultFormSettings,
        defaultValues: {
            textAnswer: value ?? '',
        },
    });

    const [lastValue, setLastValue] = useState(value);

    const onSubmit = useCallback((data: any) => {
        const newValue = data.textAnswer;

        if(newValue === lastValue || (!newValue && !lastValue))
        {
            return;
        }

        if (!data.textAnswer) {
            setValue('textAnswer', '')
        }
        setEditMode(false);
        
        if (onChange) {
            onChange(data.textAnswer);
            return;
        }

        update({
            jobId: jobId,
            tableType: tableType!,
            itemId: itemId!,
            text: newValue,
            columnInfo: columnInfo!
        }, {
            onSuccess: () => {
                setLastValue(newValue);
                onUpdate?.(newValue)
            }
        });
    }, [lastValue]);

    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        //console.debug("[ANSWER::TEXTBOX::DISABLE]", isTabEnabled, isUpdating, !!otherProps.disabled);
        setDisabled(!isTabEnabled || isUpdating || !!otherProps.disabled)
    }, [isUpdating, otherProps.disabled, isTabEnabled]);

    return (
        <Stack style={{width: '100%', height: '100%'}} verticalAlign={'center'}>
            {(<ControlledTextField
                control={control}
                name='textAnswer'
                onFocus={() => {
                    setEditMode(true)
                }}
                onBlur={handleSubmit(onSubmit)}
                disabled={disabled}
            />)}
        </Stack>
    );
};