import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {INavLink, Stack} from '@fluentui/react';
import {ModalWithForm, VerticalMenu} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useBoolean} from '@fluentui/react-hooks';
import {
    BillingInformationTab,
    DashboardsTab,
    DigitalSignatureTab,
    GeneralTab,
    MailingAddressTab,
    OtherInfoTab
} from './tabs';
import {DashboardCodes, EmployeePosition, EmployeeRole} from "pages/WorkflowPortal/shared";
import {
    useGetEmployeeById,
    useUpdateEmployeeBillingInfo,
    useUpdateEmployeesAddresses,
    useUpdateEmployeesDashboards,
    useUpdateEmployeesGeneralInfo,
    useUpdateEmployeeSignature,
    useUpdateEmployeesOtherInfo
} from "../../hooks";
import {tr} from "date-fns/locale";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    id?: number | null;
}

enum EmployeeModalTabs {
    GENERAL,
    DASHBOARDS,
    MAILING_ADDRESS,
    BILLING_INFORMATION,
    OTHER_INFO,
    DIGITAL_SIGNATURE,
}

export interface IChangeEmployeeCommand {
    userName?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    position?: EmployeePosition,
    teamId?: number | null,
    securityLevel?: EmployeeRole,
    country?: string,
    postalCode?: string,
    state?: string,
    city?: string,
    street?: string,
    street2?: string,
    rate?: string,
    nameOfAuditingFirm?: string,
    SMSFAuditorNumber?: string,
    postNominals?: string,
    dashboards?: DashboardCodes[];
    signature?: File[];
}


export const EmployeeModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title, id}: Props) => {
        const {formatMessage} = useIntl();
        const {currentEmployeesData, isCurrentEmployeesLoading} = useGetEmployeeById(id);

        const {updateEmployeesGeneralInfo, isLoadingEmployeesUpdateGeneralInfo} = useUpdateEmployeesGeneralInfo();
        const {updateEmployeesAddresses, isUpdatingEmployeesAddresses} = useUpdateEmployeesAddresses();
        const {updateEmployeeBillingInfo, isUpdatingEmployeeBillingInfo} = useUpdateEmployeeBillingInfo();
        const {updateEmployeesDashboards, isUpdatingEmployeesDashboards} = useUpdateEmployeesDashboards();
        const {updateEmployeesOtherInfo, isUpdatingEmployeesOtherInfo} = useUpdateEmployeesOtherInfo();
        const {updateEmployeeSignature, isUpdatingEmployeeSignature} = useUpdateEmployeeSignature();

        const isFormUpdating = useMemo(() => {
            return (
                isLoadingEmployeesUpdateGeneralInfo ||
                isUpdatingEmployeesAddresses ||
                isUpdatingEmployeeBillingInfo ||
                isUpdatingEmployeesDashboards ||
                isUpdatingEmployeesOtherInfo ||
                isUpdatingEmployeeSignature
            );
        }, [
            isLoadingEmployeesUpdateGeneralInfo,
            isUpdatingEmployeesAddresses,
            isUpdatingEmployeeBillingInfo,
            isUpdatingEmployeesDashboards,
            isUpdatingEmployeesOtherInfo,
            isUpdatingEmployeeSignature
        ]);

        const globalLoading = useMemo(() => {
            return (
                isLoadingEmployeesUpdateGeneralInfo ||
                isUpdatingEmployeesAddresses ||
                isCurrentEmployeesLoading ||
                isUpdatingEmployeeBillingInfo ||
                isUpdatingEmployeesDashboards ||
                isUpdatingEmployeesOtherInfo
            );
        }, [
            isCurrentEmployeesLoading,
            isLoadingEmployeesUpdateGeneralInfo,
            isUpdatingEmployeesAddresses,
            isUpdatingEmployeeBillingInfo,
            isUpdatingEmployeesDashboards,
            isUpdatingEmployeesOtherInfo,
        ]);
        const {control, handleSubmit, formState, reset, setValue} = useForm<IChangeEmployeeCommand>({
            ...DefaultFormSettings,
            defaultValues: {
                userName: '',
                firstName: '',
                lastName: '',
                email: '',
                position: undefined,
                teamId: undefined,
                securityLevel: undefined,
                country: '',
                postalCode: '',
                state: '',
                city: '',
                street: '',
                street2: '',
                rate: '',
                nameOfAuditingFirm: '',
                SMSFAuditorNumber: '',
                postNominals: '',
                dashboards: [],
            },
        });
        useEffect(() => {
            if (!currentEmployeesData?.data) {
                return;
            }

            //general tab
            setValue('userName', currentEmployeesData.data.lastName || '');
            setValue('firstName', currentEmployeesData.data.firstName || '');
            setValue('lastName', currentEmployeesData.data?.lastName || '');
            setValue('email', currentEmployeesData.data?.email || '');
            setValue('teamId', currentEmployeesData.data?.team?.id || null);
            setValue('securityLevel', currentEmployeesData.data?.role || undefined);
            setValue('position', currentEmployeesData.data?.position || undefined);

            //dashboards tab
            setValue('dashboards', currentEmployeesData.data.dashboardCodes);

            //mailing tab
            const currentAddress = currentEmployeesData.data.address;
            setValue('state', currentAddress?.state || '');
            setValue('street', currentAddress?.street1 || '');
            setValue('street2', currentAddress?.street2 || '');
            setValue('city', currentAddress?.city || '');
            setValue('country', currentAddress?.country || '');
            setValue('postalCode', currentAddress?.post || '');
            //billing tab
            setValue('rate', String(currentEmployeesData.data.rate) || '');
            //other info tab
            setValue('nameOfAuditingFirm', currentEmployeesData.data.auditingFirm);
            setValue('SMSFAuditorNumber', currentEmployeesData.data.smsfNumber);
            setValue('postNominals', currentEmployeesData.data.postNominals);

        }, [currentEmployeesData?.data, setValue]);

        const onClose = () => {
            onDismiss();
            reset();
            setActiveTab(EmployeeModalTabs.GENERAL);
        }

        const onSubmit = (data: IChangeEmployeeCommand) => {
            if (currentEmployeesData?.data) {
                switch (activeTab) {
                    case EmployeeModalTabs.GENERAL:
                        updateEmployeesGeneralInfo({
                            employeeId: currentEmployeesData.data.id,
                            firstName: data.firstName || '',
                            lastName: data.lastName || '',
                            email: data.email || '',
                            position: data.position!,
                            role: data.securityLevel!,
                            teamId: data.teamId || null
                        });
                        return;
                    case EmployeeModalTabs.MAILING_ADDRESS:
                        updateEmployeesAddresses({
                            employeeId: currentEmployeesData.data.id,
                            city: data.city || '',
                            country: data.country || '',
                            post: data.postalCode || '',
                            state: data.state || '',
                            street1: data.street || '',
                            street2: data.street2 || '',
                        });
                        return;

                    case EmployeeModalTabs.BILLING_INFORMATION:
                        updateEmployeeBillingInfo({
                            employeeId: currentEmployeesData.data.id,
                            rate: Number(data.rate),
                        });
                        return;
                    case EmployeeModalTabs.DASHBOARDS:
                        updateEmployeesDashboards({
                            employeeId: currentEmployeesData.data.id,
                            dashboards: data.dashboards || [],
                        });
                        return;
                    case EmployeeModalTabs.OTHER_INFO:
                        updateEmployeesOtherInfo({
                            employeeId: currentEmployeesData.data.id,
                            auditingFirm: data.nameOfAuditingFirm || '',
                            smsfNumber: data.SMSFAuditorNumber || '',
                            postNominals: data.postNominals || '',
                        });
                        return;
                    case EmployeeModalTabs.DIGITAL_SIGNATURE:
                        const formData: any = new FormData();
                        if (!data.signature) return;
                        formData.append('File', data.signature[0], data.signature[0].name);
                        updateEmployeeSignature({data: formData, id: currentEmployeesData.data.id});
                        return;
                    default:
                        return;
                }
            }
        };

        const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);
        const [activeTab, setActiveTab] = useState<EmployeeModalTabs>(EmployeeModalTabs.GENERAL);

        const [links] = useState<INavLink[]>([
            {
                name: formatMessage({id: 'general'}),
                url: '',
                iconProps: {iconName: 'Settings'},
                links: [
                    {
                        name: formatMessage({id: 'general'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.GENERAL],
                        iconProps: {iconName: 'ComplianceAudit'},
                        isExpanded: activeTab === EmployeeModalTabs.GENERAL,
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.GENERAL);
                        },
                    },
                    {
                        name: formatMessage({id: 'dashboards'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.DASHBOARDS],
                        iconProps: {iconName: 'ViewDashboard'},
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.DASHBOARDS);
                        },
                    },
                    {
                        name: formatMessage({id: 'mailingAddress'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.MAILING_ADDRESS],
                        iconProps: {iconName: 'Street'},
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.MAILING_ADDRESS);
                        },
                    },
                    {
                        name: formatMessage({id: 'billingInformation'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.BILLING_INFORMATION],
                        iconProps: {iconName: 'Calculator'},
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.BILLING_INFORMATION);
                        },
                    },
                ],
                isExpanded: true,
            },
            {
                name: formatMessage({id: 'otherInformation'}),
                url: '',
                iconProps: {iconName: 'Settings'},
                isExpanded: true,
                links: [
                    {
                        name: formatMessage({id: 'otherInformation'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.OTHER_INFO],
                        iconProps: {iconName: 'ComplianceAudit'},
                        title: '',
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.OTHER_INFO);
                        },
                    },
                    {
                        name: formatMessage({id: 'digitalSignature'}),
                        url: '',
                        key: EmployeeModalTabs[EmployeeModalTabs.DIGITAL_SIGNATURE],
                        iconProps: {iconName: 'InsertSignatureLine'},
                        onClick: () => {
                            setActiveTab(EmployeeModalTabs.DIGITAL_SIGNATURE);
                        },
                    },
                ],
            },
        ]);

        return (
            <ModalWithForm minHeight={true}
                           isSubmitting={isFormUpdating}
                           submitDisabled={globalLoading || !formState.isDirty}
                           onSubmit={handleSubmit(onSubmit)}
                           isLoading={isCurrentEmployeesLoading}
                           closeAfterSubmit={true}
                           isOpen={isOpen}
                           onDismiss={onClose}
                           title={title}>
                <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -16px'}}}>
                    <VerticalMenu selectedKey={EmployeeModalTabs[activeTab || EmployeeModalTabs.GENERAL]} links={links}
                                  isCollapsed={isCollapsed}
                                  toggleIsCollapsed={toggleIsCollapsed}/>
                    <Stack
                        styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`}}}
                        tokens={{childrenGap: 16}}>
                        <Stack.Item styles={{root: {height: 'calc(100% - 50px)'}}}>
                            {activeTab === EmployeeModalTabs.GENERAL ? (
                                <GeneralTab
                                    role={currentEmployeesData?.data.role?.toString() || ''}
                                    position={currentEmployeesData?.data.position?.toString() || ''}
                                    control={control}
                                    teamId={currentEmployeesData?.data.team?.id}
                                />
                            ) : activeTab === EmployeeModalTabs.DASHBOARDS ? (
                                <DashboardsTab
                                    onSet={(codes) => setValue('dashboards', codes, {shouldTouch: currentEmployeesData?.data.dashboardCodes !== codes})}
                                    control={control}
                                    dashboards={currentEmployeesData?.data.dashboardCodes}/>
                            ) : activeTab === EmployeeModalTabs.MAILING_ADDRESS ? (
                                <MailingAddressTab control={control}/>
                            ) : activeTab === EmployeeModalTabs.BILLING_INFORMATION ? (
                                <BillingInformationTab control={control}/>
                            ) : activeTab === EmployeeModalTabs.OTHER_INFO ? (
                                <OtherInfoTab control={control}/>
                            ) : activeTab === EmployeeModalTabs.DIGITAL_SIGNATURE ? (
                                <DigitalSignatureTab signatureImage={currentEmployeesData?.data.signatureImage || ''}
                                                     control={control}/>
                            ) : (
                                ''
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack>

            </ModalWithForm>
        );
    })
;
