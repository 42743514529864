import {FunctionComponent, useEffect, useMemo} from 'react';
import {getFlatProcedures, getOpenOnStartupIds} from 'utils';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {mergeStyles, Stack, useTheme} from "@fluentui/react";

export const PropertyDetailsTextProcedureTemplate: FunctionComponent = () => {
    const { items, isLoading } = useProcedureContext();
    const theme = useTheme();
    const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items)), [items]);
    const styles = mergeStyles({
        '.body': {
            display: 'flex',
            flexGrow: 1
        },
        '.body a':{
            paddingRight: 16
        }
    })


    if (!rows?.length) return <></>;

    return (
        <Stack>
            <SanitizedText theme={theme.schemes?.default} className={styles} data={rows[0].text}/>
        </Stack>
    );
};