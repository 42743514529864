import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
    Checkbox,
    DialogType,
    FontSizes,
    FontWeights,
    IColumn,
    Icon,
    IconButton,
    IDropdownOption,
    IGroup,
    IGroupHeaderProps,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { DialogMessage, Loading } from 'components';
import { useBoolean } from '@fluentui/react-hooks';
import {
    IJobSuggestedTestingGroup,
    IJobSuggestedTestingItem,
    useCreateJobSuggestedTestingItem,
    useDeleteJobSuggestedTestingItem,
    useGetJobSuggestedTesting,
    useUpdateSuggestedTesting,
} from '../../../../hooks';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useSectionContext } from '../../../Section';
import { Attachment } from '../../../Attachment';
import { JobComment } from '../../../JobComment';
import { ProceduresDataTable } from '../../../ProceduresDataTable';
import { ColumnInfo, SuggestedTestingType } from '../../../../enums';
import { logger } from '../../../../../../services';
import { PermanentCheckBox } from '../../../PermanentCheckBox';
import { WorkDoneSwitchAnswer } from '../../answers';
import { SoftwareType } from '../../../../../../enums';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { useUpdateSectionAnswer } from '../../../../hooks/answers';
import { SuggestedTestingNumericCell } from './SuggestedTestingNumericCell';
import { SuggestedTestingStringCell } from './SuggestedTestingStringCell';
import { SuggestedTestingActionCell } from './SuggestedTestingActionCell';
import { useTabContext } from 'pages/JobPortal';

const getGroups = (items: IJobSuggestedTestingGroup[]): IGroup[] => {
    return (
        items?.map(
            (x, index, array) =>
                ({
                    name: x.type.toString(),
                    key: x.type.toString(),
                    data: x.items,
                    startIndex: !index ? index : array[index - 1].items.length,
                    count: x.items.length,
                } as IGroup)
        ) || []
    );
};

const getInvestmentOptions = (items: IJobSuggestedTestingGroup[]) => {
    let investmentGroup = items?.find((x) => x.type === SuggestedTestingType.Investments);

    return (
        investmentGroup?.options.map((x) => {
            return {
                key: x.value,
                text: x.field,
            } as IDropdownOption;
        }) || []
    );
};

export const SuggestedTestingProcedureTemplate = forwardRef((props, ref) => {
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const theme = useTheme();
    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    const { section, enableWarningMessage, refresh } = useSectionContext();
    const { job, jobId, suggestedTestingInfo } = useJobContext();

    const [isAccepted, setAsAccepted] = useState(section.sectionAnswer?.managerApproval || false);
    const [isTableDisabled, setIsTableDisabled] = useState(isAccepted || !isTabEnabled);
    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);
    const [investmentsOptions, setInvestmentOptions] = useState<IDropdownOption[]>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);
    const [expandHeaders, { toggle: toggleExpandHeaders }] = useBoolean(job.softwareType === SoftwareType.ClassSuper);

    const { data: suggestedTestingsResponse, isLoading, refetch, isRefetching } = useGetJobSuggestedTesting(jobId);
    const { create, isCreating } = useCreateJobSuggestedTestingItem();
    const { update, isUpdating } = useUpdateSuggestedTesting();
    const { deleteItem, isDeleting } = useDeleteJobSuggestedTestingItem();
    const { update: updateSectionAnswer, isUpdating: isUpdatingSectionAnswer } = useUpdateSectionAnswer();

    const { setValue, reset, handleSubmit, control, getValues } =
        useForm<{ suggestedTestingItems: IJobSuggestedTestingItem[] }>(DefaultFormSettings);
    const [tableGroups, setTableGroups] = useState<IGroup[]>();

    const [shouldRefresh, setShouldRefresh] = useState<number>(Date.now());
    const [suggestedTestingItems, setSuggestedTestingItems] = useState<IJobSuggestedTestingItem[]>([]);

    const { fields, append, remove } = useFieldArray({
        name: 'suggestedTestingItems',
        control: control,
    });

    useEffect(() => {
        const items =
            suggestedTestingsResponse?.data?.groups
                ?.map((x) => x.items)
                .flat(1)
                .map((x) => ({
                    ...x,
                    entityId: x.id,
                })) || [];
        setSuggestedTestingItems(items);
        setValue('suggestedTestingItems', items || []);
    }, [suggestedTestingsResponse?.data?.groups]);

    const switchToEditMode = useCallback((index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    }, []);

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    const deleteNote = useCallback(
        (index: number) => {
            const noteId = getValues(`suggestedTestingItems.${index}.id`);
            setLastUpdatedIndex(index);

            deleteItem(
                {
                    itemId: noteId,
                    jobId: jobId,
                },
                {
                    onSuccess: () => {
                        setSuggestedTestingItems(suggestedTestingItems.splice(index, 1));
                        remove(index);
                        setEditingIndex(undefined);
                    },
                }
            );
        },
        [getValues, remove, suggestedTestingItems, jobId]
    );

    const handleReducedTestingChange = useCallback(
        (newValue: boolean, index?: number) => {
            if (index === undefined) {
                return;
            }

            setValue(`suggestedTestingItems.${index}.useReducedTesting`, newValue);
            const suggestedTestingItem = getValues(`suggestedTestingItems.${index}`);
            const item = suggestedTestingItems.find((x) => x.id === suggestedTestingItem.id);

            setLastUpdatedIndex(index);

            update(
                {
                    id: suggestedTestingItem!.id,
                    jobId: jobId,
                    type: suggestedTestingItem!.type,
                    investment: suggestedTestingItem.investment,
                    value: suggestedTestingItem.value,
                    nonDataFedSampleSize: suggestedTestingItem.nonDataFedSampleSize,
                    dataFedSampleSize: suggestedTestingItem.dataFedSampleSize,
                    feed: suggestedTestingItem.feed,
                    balRec: suggestedTestingItem.balRec,
                    glatr: suggestedTestingItem.glatr,
                    fundSuspense: suggestedTestingItem.fundSuspense,
                    useReducedTesting: suggestedTestingItem.useReducedTesting,
                },
                {
                    onSuccess: (result) => {
                        setValue(`suggestedTestingItems.${index}.dataFedSampleSize`, result.data?.dataFedSampleSize);
                        setSuggestedTestingItems([
                            ...suggestedTestingItems.slice(0, index),
                            {
                                ...suggestedTestingItem,
                                dataFedSampleSize: result.data?.dataFedSampleSize,
                                nonDataFedSampleSize: result.data?.nonDataFedSampleSize,
                            },
                            ...suggestedTestingItems.slice(index + 1),
                        ]);
                    },
                }
            );
        },
        [getValues, setValue, suggestedTestingItems, jobId]
    );

    const handleOnAccept = useCallback(
        (index?: number) => {
            const workingIndex = editingIndex ?? index;
             
            if (workingIndex === undefined || workingIndex === null) {
                return;
            }

            const suggestedTestingItem = getValues(`suggestedTestingItems.${workingIndex}`);
            const item = suggestedTestingItems.find((x) => x.id === suggestedTestingItem.id);

            if (suggestedTestingItem.nonDataFedSampleSize !== item?.nonDataFedSampleSize) {
                suggestedTestingItem.isNonDataFedSampleSizeChanged = true;
                setValue(`suggestedTestingItems.${workingIndex}.isNonDataFedSampleSizeChanged`, true);
            }

            if (suggestedTestingItem.dataFedSampleSize !== item?.dataFedSampleSize) {
                suggestedTestingItem.isDataFedSampleSizeChanged = true;
                setValue(`suggestedTestingItems.${workingIndex}.isDataFedSampleSizeChanged`, true);
            }

            if (suggestedTestingItem.value !== item?.value) {
                suggestedTestingItem.isValueChanged = true;
                setValue(`suggestedTestingItems.${workingIndex}.isValueChanged`, true);
            }

            if (
                (suggestedTestingItem.value === item?.value || (!suggestedTestingItem.value && !item?.value)) &&
                (suggestedTestingItem.type === item?.type || (!suggestedTestingItem.type && !item?.type)) &&
                (suggestedTestingItem.investment === item?.investment || (!suggestedTestingItem.investment && !item?.investment)) &&
                (suggestedTestingItem.nonDataFedSampleSize === item?.nonDataFedSampleSize ||
                    (!suggestedTestingItem.nonDataFedSampleSize && !item?.nonDataFedSampleSize)) &&
                (suggestedTestingItem.dataFedSampleSize === item?.dataFedSampleSize ||
                    (!suggestedTestingItem.dataFedSampleSize && !item?.dataFedSampleSize)) &&
                (suggestedTestingItem.feed === item?.feed || (!suggestedTestingItem.feed && !item?.feed)) &&
                (suggestedTestingItem.balRec === item?.balRec || (!suggestedTestingItem.balRec && !item?.balRec)) &&
                (suggestedTestingItem.glatr === item?.glatr || (!suggestedTestingItem.glatr && !item?.glatr)) &&
                (suggestedTestingItem.fundSuspense === item?.fundSuspense || (!suggestedTestingItem.fundSuspense && !item?.fundSuspense))
            ) {
                return;
            }

            setLastUpdatedIndex(workingIndex);

            update(
                {
                    id: suggestedTestingItem!.id,
                    jobId: jobId,
                    type: suggestedTestingItem!.type,
                    investment: suggestedTestingItem.investment,
                    value: suggestedTestingItem.value,
                    nonDataFedSampleSize: suggestedTestingItem.nonDataFedSampleSize,
                    dataFedSampleSize: suggestedTestingItem.dataFedSampleSize,
                    feed: suggestedTestingItem.feed,
                    balRec: suggestedTestingItem.balRec,
                    glatr: suggestedTestingItem.glatr,
                    fundSuspense: suggestedTestingItem.fundSuspense,
                    useReducedTesting: suggestedTestingItem.useReducedTesting,
                },
                {
                    onSuccess: () => {
                        setSuggestedTestingItems([
                            ...suggestedTestingItems.slice(0, workingIndex),
                            { ...suggestedTestingItem },
                            ...suggestedTestingItems.slice((workingIndex) + 1),
                        ]);
                    },
                }
            );
        },
        [editingIndex, getValues, suggestedTestingItems, jobId]
    );

    const renderGroupHeader = (
        props?: IGroupHeaderProps,
        render?: ((props?: IGroupHeaderProps | undefined) => JSX.Element | null) | undefined
    ) => {
        if (!props || !render) return null;

        return (
            <Stack
                horizontal
                tokens={{ childrenGap: 16 }}
                horizontalAlign={'space-between'}
                style={{ paddingLeft: 16, paddingRight: 16, width: '100%' }}
                verticalAlign={'center'}>
                <Stack.Item>
                    <Text
                        styles={{
                            root: {
                                fontWeight: FontWeights.semibold,
                            },
                        }}>
                        {formatMessage({ id: `suggestedTestingType_${props.group?.name}` })}
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        disabled={isTableDisabled}
                        onClick={() => onAddNewItem(props?.group?.key! as SuggestedTestingType)}
                        iconProps={{ iconName: 'add' }}
                    />
                </Stack.Item>
            </Stack>
        );
    };

    const globalLoading = useMemo<boolean>(() => isLoading || isRefetching || isCreating, [isLoading, isRefetching, isCreating]);

    const columns = useMemo<(IColumn & { skip?: boolean })[]>(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                fieldName: 'actions',
                onRender: (items: IJobSuggestedTestingItem & { entityId: number }) => (
                    <Stack horizontal>
                        <Attachment itemId={items?.entityId} hasAttachments={items?.hasAttachments} />
                        <JobComment itemId={items?.entityId} hasComments={items?.hasComments} />
                    </Stack>
                ),
                minWidth: 70,
                maxWidth: 70,
            },
            {
                key: 'investment',
                name: '',
                fieldName: 'investment',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index, column) => (
                    <SuggestedTestingStringCell
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, 'investment')) {
                                handleOnAccept();
                                switchToEditMode(index, 'investment');
                            }
                        }}
                        control={control}
                        fieldName={'investment'}
                        item={item}
                        value={index !== undefined ? getValues(`suggestedTestingItems.${index}.investment`) : item.investment}
                        isEditable={item.isCustom}
                        mode={isInEditMode(index ?? -1, 'investment') ? 'edit' : 'view'}
                        options={investmentsOptions}
                        index={index}
                    />
                ),
                minWidth: 200,
                maxWidth: 300,
            },
            {
                key: 'value',
                name: formatMessage({ id: 'value' }),
                fieldName: 'value',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index, column) => (
                    <SuggestedTestingNumericCell
                        item={item}
                        value={index !== undefined ? getValues(`suggestedTestingItems.${index}.value`) : item.value}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, 'value') && !isTableDisabled) {
                                handleOnAccept();
                                switchToEditMode(index, 'value');
                            }
                        }}
                        control={control}
                        fieldName={'value'}
                        index={index}
                        isEditable={!item.deep || item.isCustom}
                        disabled={isTableDisabled}
                        isValueChanged={
                            index !== undefined ? getValues(`suggestedTestingItems.${index}.isValueChanged`) : item.isValueChanged
                        }
                        fractionDigits={2}
                        mode={isInEditMode(index ?? -1, 'value') ? 'edit' : 'view'}
                    />
                ),
                minWidth: 100,
                maxWidth: 150,
            },
            {
                key: 'nonDataFedSampleSize',
                name: formatMessage({ id: 'nonDataFedSampleSize' }),
                fieldName: 'nonDataFedSampleSize',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index, column) => (
                    <SuggestedTestingNumericCell
                        item={index !== undefined ? getValues(`suggestedTestingItems.${index}`) : item}
                        value={
                            index !== undefined
                                ? getValues(`suggestedTestingItems.${index}.nonDataFedSampleSize`)
                                : item.nonDataFedSampleSize
                        }
                        showReviewRequired={index !== undefined && getValues(`suggestedTestingItems.${index}.nonDataFedSampleSize`) == -1}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, 'nonDataFedSampleSize') && !isTableDisabled) {
                                handleOnAccept();
                                switchToEditMode(index, 'nonDataFedSampleSize');
                            }
                        }}
                        control={control}
                        fieldName={'nonDataFedSampleSize'}
                        index={index}
                        isEditable={!item.deep || item.isCustom}
                        useReducedTesting={index !== undefined && getValues(`suggestedTestingItems.${index}.useReducedTesting`)}
                        usedOnReduceTesting={false}
                        disabled={isTableDisabled}
                        suffix={'%'}
                        isValueChanged={
                            index !== undefined
                                ? getValues(`suggestedTestingItems.${index}.isNonDataFedSampleSizeChanged`)
                                : item.isNonDataFedSampleSizeChanged
                        }
                        mode={isInEditMode(index ?? -1, 'nonDataFedSampleSize') ? 'edit' : 'view'}
                    />
                ),
                onColumnClick: toggleExpandHeaders,
                onRenderHeader: () => {
                    return (
                        <Stack
                            horizontal
                            verticalAlign={'center'}
                            horizontalAlign={'space-between'}
                            tokens={{ childrenGap: 16 }}
                            style={{ minWidth: '100%' }}
                            grow>
                            <Stack.Item>
                                <Text style={{ fontWeight: FontWeights.semibold }}>{formatMessage({ id: 'nonDataFedSampleSize' })}</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Icon
                                    iconName={expandHeaders ? 'ChevronLeftSmall' : 'ChevronRightSmall'}
                                    style={{ fontSize: FontSizes.size10 }}
                                />
                            </Stack.Item>
                        </Stack>
                    );
                },
                minWidth: 100,
                maxWidth: expandHeaders ? 250 : 200,
            },
            {
                key: 'feed',
                name: formatMessage({ id: 'feed' }),
                fieldName: 'feed',
                isResizable: true,
                skip: !expandHeaders,
                onRender: (items: IJobSuggestedTestingItem, index) =>
                    items.showFeed && (
                        <WorkDoneSwitchAnswer
                            ignoreAnswerUpdating
                            itemId={items.id}
                            auto={index !== undefined ? getValues(`suggestedTestingItems.${index}.isFeedAuto`) : items.isFeedAuto}
                            tableType={items.tableType}
                            disabled={isTableDisabled}
                            iconTypes={
                                (index !== undefined ? getValues(`suggestedTestingItems.${index}.isFeedAuto`) : items.isFeedAuto)
                                    ? 'character'
                                    : 'icons'
                            }
                            onUpdate={(answer) => {
                                if (index !== undefined) {
                                    setValue(`suggestedTestingItems.${index}.feed`, answer);
                                    setEditingFieldName(undefined);
                                    setEditingIndex(undefined);
                                    handleOnAccept(index);
                                }
                            }}
                            columnInfo={ColumnInfo.AnswerText}
                            value={index !== undefined ? getValues(`suggestedTestingItems.${index}.feed`) || '' : items.feed || ''}
                        />
                    ),
                minWidth: 100,
                maxWidth: 150,
            },
            {
                key: 'balRec',
                name: formatMessage({ id: 'balRec' }),
                fieldName: 'balRec',
                isResizable: true,
                skip: !expandHeaders,
                onRender: (items: IJobSuggestedTestingItem, index, column) =>
                    items.showBalRec && (
                        <WorkDoneSwitchAnswer
                            ignoreAnswerUpdating
                            itemId={items.id}
                            auto={index !== undefined ? getValues(`suggestedTestingItems.${index}.isBalRecAuto`) : items.isBalRecAuto}
                            tableType={items.tableType}
                            disabled={isTableDisabled}
                            iconTypes={
                                (index !== undefined ? getValues(`suggestedTestingItems.${index}.isBalRecAuto`) : items.isBalRecAuto)
                                    ? 'character'
                                    : 'icons'
                            }
                            columnInfo={ColumnInfo.AnswerText}
                            onUpdate={(answer) => {
                                if (index !== undefined) {
                                    setValue(`suggestedTestingItems.${index}.balRec`, answer);
                                    setEditingFieldName(undefined);
                                    setEditingIndex(index);
                                    handleOnAccept(index);
                                }
                            }}
                            value={index !== undefined ? getValues(`suggestedTestingItems.${index}.balRec`) || '' : items.balRec || ''}
                        />
                    ),
                minWidth: 100,
                maxWidth: 150,
            },
            {
                key: 'fundSuspense',
                name: formatMessage({ id: 'fundSuspense' }),
                fieldName: 'fundSuspense',
                isResizable: true,
                skip: !expandHeaders,
                onRender: (items: IJobSuggestedTestingItem, index, column) =>
                    items.showFundSuspense && (
                        <WorkDoneSwitchAnswer
                            ignoreAnswerUpdating
                            itemId={items.id}
                            auto={
                                index !== undefined
                                    ? getValues(`suggestedTestingItems.${index}.isFundSuspenseAuto`)
                                    : items.isFundSuspenseAuto
                            }
                            tableType={items.tableType}
                            disabled={isTableDisabled}
                            iconTypes={
                                (
                                    index !== undefined
                                        ? getValues(`suggestedTestingItems.${index}.isFundSuspenseAuto`)
                                        : items.isFundSuspenseAuto
                                )
                                    ? 'character'
                                    : 'icons'
                            }
                            columnInfo={ColumnInfo.AnswerText}
                            onUpdate={(answer) => {
                                if (index !== undefined) {
                                    setValue(`suggestedTestingItems.${index}.fundSuspense`, answer);
                                    setEditingFieldName(undefined);
                                    setEditingIndex(index);
                                    handleOnAccept(index);
                                }
                            }}
                            value={
                                index !== undefined
                                    ? getValues(`suggestedTestingItems.${index}.fundSuspense`) || ''
                                    : items.fundSuspense || ''
                            }
                        />
                    ),
                minWidth: 100,
                maxWidth: 150,
            },
            {
                key: 'glatr',
                name: formatMessage({ id: 'glatr' }),
                fieldName: 'glatr',
                isResizable: true,
                skip: !expandHeaders,
                onRender: (items: IJobSuggestedTestingItem, index, column) =>
                    items.showGlatr && (
                        <WorkDoneSwitchAnswer
                            ignoreAnswerUpdating
                            itemId={items.id}
                            auto={index !== undefined ? getValues(`suggestedTestingItems.${index}.isGlatrAuto`) : items.isGlatrAuto}
                            tableType={items.tableType}
                            disabled={isTableDisabled}
                            iconTypes={
                                (index !== undefined ? getValues(`suggestedTestingItems.${index}.isGlatrAuto`) : items.isGlatrAuto)
                                    ? 'character'
                                    : 'icons'
                            }
                            columnInfo={ColumnInfo.AnswerText}
                            onUpdate={(answer) => {
                                if (index !== undefined) {
                                    setValue(`suggestedTestingItems.${index}.glatr`, answer);
                                    setEditingFieldName(undefined);
                                    setEditingIndex(index);
                                    handleOnAccept(index);
                                }
                            }}
                            value={index !== undefined ? getValues(`suggestedTestingItems.${index}.glatr`) || '' : items.glatr || ''}
                        />
                    ),
                minWidth: 100,
                maxWidth: 150,
            },
            {
                key: 'dataFedSampleSize',
                name: formatMessage({ id: 'dataFedSampleSize' }),
                fieldName: 'dataFedSampleSize',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index, column) => (
                    <SuggestedTestingNumericCell
                        item={item}
                        value={index !== undefined ? getValues(`suggestedTestingItems.${index}.dataFedSampleSize`) : item.dataFedSampleSize}
                        showReviewRequired={index !== undefined && getValues(`suggestedTestingItems.${index}.nonDataFedSampleSize`) == -1}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, 'dataFedSampleSize') && !isTableDisabled) {
                                handleOnAccept();
                                switchToEditMode(index, 'dataFedSampleSize');
                            }
                        }}
                        control={control}
                        fieldName={'dataFedSampleSize'}
                        index={index}
                        isEditable={
                            index !== undefined
                                ? !getValues(`suggestedTestingItems.${index}.deep`) || getValues(`suggestedTestingItems.${index}.isCustom`)
                                : !item.deep || item.isCustom
                        }
                        disabled={isTableDisabled}
                        usedOnReduceTesting
                        useReducedTesting={index !== undefined && getValues(`suggestedTestingItems.${index}.useReducedTesting`)}
                        suffix={'%'}
                        isValueChanged={
                            index !== undefined
                                ? getValues(`suggestedTestingItems.${index}.isDataFedSampleSizeChanged`)
                                : item.isDataFedSampleSizeChanged
                        }
                        mode={isInEditMode(index ?? 0, 'dataFedSampleSize') ? 'edit' : 'view'}
                    />
                ),
                minWidth: 100,
                maxWidth: expandHeaders ? 150 : undefined,
            },
            {
                key: 'useReducedTesting',
                name: formatMessage({ id: 'useReducedTesting' }),
                fieldName: 'useReducedTesting',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index, column) =>
                    item.deep == 0 && (
                        <Stack horizontalAlign={'center'} grow>
                            <PermanentCheckBox
                                checked={
                                    index !== undefined
                                        ? getValues(`suggestedTestingItems.${index}.useReducedTesting`)
                                        : item.useReducedTesting
                                }
                                itemId={index !== undefined ? getValues(`suggestedTestingItems.${index}.id`) : item.id}
                                disabled={isUpdating || isTableDisabled}
                                tableType={item.tableType}
                                onUpdate={(newValue) => {
                                    setEditingFieldName(undefined);
                                    setEditingIndex(undefined);
                                    handleReducedTestingChange(newValue, index);
                                }}
                            />
                        </Stack>
                    ),
                minWidth: 70,
                maxWidth: 70,
            },
            {
                key: 'delete',
                name: '',
                fieldName: '',
                isResizable: true,
                onRender: (item: IJobSuggestedTestingItem, index) => (
                    <SuggestedTestingActionCell
                        item={item}
                        onDelete={(item) => {
                            setEditingIndex(index);
                            toggleDeleteDialog();
                        }}
                        disabled={isTableDisabled}
                        isChanging={lastUpdatedIndex === index && (isUpdating || isDeleting)}
                        showDelete={!(isUpdating || isDeleting) && item.isCustom && editingIndex !== index && !showDeleteDialog}
                    />
                ),
                minWidth: 70,
                maxWidth: 70,
            },
        ],
        [
            editingIndex,
            editingFieldName,
            handleOnAccept,
            isInEditMode,
            expandHeaders,
            lastUpdatedIndex,
            isUpdating,
            isDeleting,
            isTableDisabled,
        ]
    );

    const onAddNewItem = (type: SuggestedTestingType) => {
        logger.debug('Adding new suggested testing item to type: ' + type);
        create(
            {
                jobId: jobId,
                type: type,
                isCustom: true,
            },
            {
                onSuccess: (data) => {
                    // suggestedTestingsResponse?.data?.groups?.find((x) => x.type == type)?.items.push(data.data);
                    // let groups = getGroups(suggestedTestingsResponse?.data?.groups || []);
                    // setTableGroups(groups);
                    // setSuggestedTestingItems()
                    // append(data.data);
                    refetch();
                },
            }
        );
    };

    useImperativeHandle(ref, () => ({
        refresh() {
            setEditingIndex(undefined);
            setEditingIndex(undefined);
            refetch();
        },
    }));

    useEffect(() => {
        enableWarningMessage(false);

        if (!suggestedTestingsResponse?.data) {
            return;
        }

        setTableGroups(getGroups(suggestedTestingsResponse.data.groups || []));
        if (!investmentsOptions?.length) {
            let options = getInvestmentOptions(suggestedTestingsResponse.data.groups);
            setInvestmentOptions(options);
        }
    }, [suggestedTestingsResponse?.data]);

    useEffect(() => {
        setIsTableDisabled(isAccepted || !isTabEnabled);
    }, [isAccepted, !isTabEnabled]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('SuggestedTesting')) return;
            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const onAccept = (isAccept: boolean) => {
        updateSectionAnswer(
            {
                jobId: jobId,
                sectionId: section.id,
                managerApproval: isAccept,
                partnerApproval: section.sectionAnswer?.partnerApproval,
                sendEmail: section.sectionAnswer?.sendEmail,
            },
            {
                onSuccess: () => {
                    setAsAccepted(isAccept);
                    suggestedTestingInfo?.setIsApproved(isAccept);
                },
            }
        );
    };

    if (isLoading) return <Loading />;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Checkbox
                styles={{
                    root: {
                        ':hover .ms-Checkbox-text': {
                            color: theme.schemes?.default?.semanticColors.bodyText,
                        },
                    },
                    text: {
                        color: theme.schemes?.default?.semanticColors.bodyText,
                    },
                }}
                checked={isAccepted}
                onChange={(ev, checked) => onAccept(checked || false)}
                label={formatMessage({ id: 'reviewAndAccepted' })}
            />
            <ProceduresDataTable
                hideIfEmpty={false}
                groups={tableGroups}
                items={fields}
                isLoading={globalLoading}
                columns={columns}
                onShouldVirtualize={() => true}
                navigationKeySelector={'entityId'}
                header={{ horizontalAlign: 'center', rowHeight: 78 }}
                groupProps={{
                    showEmptyGroups: true,
                    onRenderHeader: (groupProps, defaultRender) => {
                        if (!groupProps || !defaultRender) return null;

                        return defaultRender({ ...groupProps, onRenderTitle: renderGroupHeader });
                    },
                }}
            />
            <DialogMessage
                onClick={() => {
                    if (editingIndex !== undefined) {
                        toggleDeleteDialog();
                        deleteNote(editingIndex);
                    }
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setEditingIndex(undefined);
                }}
            />
        </Stack>
    );
});
