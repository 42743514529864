import { IColumn, Stack } from '@fluentui/react';
import { SanitizedText } from 'components';
import { Attachment, GeneratedAnswer, JobComment } from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useCallback, useMemo } from "react";
import { useIntl } from 'react-intl';
import { AnswerControlType } from '../../../../../../enums';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { ReportTemplateTypeProps } from '../Types';
import { ReportMoneyValueCell } from '../shared/cells';
import { ReportValidationCell } from '../shared';

export const OperatingStatement = ({ items, isFetching }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const getGroupInfo = (item: any): { isGroup: boolean, isLastGroup: boolean } => {
        return {
            isGroup: item.children.length > 0,
            isLastGroup: !(item.children ?? []).some((c: any) => getGroupInfo(c).isGroup)
        }
    }
    
    const isOpenedItem = useCallback((item: any): boolean => {
        const { isGroup, isLastGroup } = getGroupInfo(item);
        return isGroup && !isLastGroup;
    }, []);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 300,
                fieldName: 'description',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        styles={getDepthPaddingStyles(item?.depth)}
                        isHeadingRow={item?.isTotal || item?.format === 'Bold'}
                        format={item?.format} minWidth={'auto'}
                    />
                ),
            },
            {
                key: 'answerText',
                name: formatMessage({ id: 'answerText' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.depth >= 2 &&
                            item?.balance !== null &&
                            AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType] ===
                                AnswerControlType.WorkDoneSwitch && (
                                <GeneratedAnswer
                                    itemId={item?.id}
                                    tableType={item?.reportType}
                                    value={item?.answerText}
                                    auto={item?.isAuto}
                                    disabled={item?.isAnswerDisabled}
                                    answerControlType={AnswerControlType.WorkDoneSwitch}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'thisYear',
                name: formatMessage({ id: 'thisYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balance',
                onRender: (item, _, column) => item?.balance !== null && <ReportMoneyValueCell value={item['balance' || 0]} isBold={item?.isTotal || item?.format === 'Bold'} allowNull={true}/>,
            },
            {
                key: 'previousYearBalance',
                name: formatMessage({ id: 'previousYearBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'previousYearBalance',
                onRender: (item, _, column) => item?.previousYearBalance !== null &&
                    <ReportMoneyValueCell value={item['previousYearBalance' || 0]} isBold={item?.isTotal || item?.format === 'Bold'} allowNull={true} />,
            },
            {
                key: 'change',
                name: formatMessage({ id: 'change' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'change',
                onRender: (item, _, column) => <SanitizedText
                    data={item[column?.fieldName || 0]}
                    isHeadingRow={item?.isTotal || item?.format === 'Bold'}
                    format={item?.format}
                    textAlign='right'
                    numberFormat='fractional' numberFormatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
                />,
            },
            {
                key: 'pChange',
                name: formatMessage({ id: 'pChange' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'pChange',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isTotal || item?.format === 'Bold'}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='percentage'
                    />
                ),
            },
            {
                key: 'isSumChecked',
                name: formatMessage({ id: 'isSumChecked' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'isSumChecked',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isOpenedItem={isOpenedItem} isFetching={isFetching} />;
};
