import {Card, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {IChangeContactInfoCommand} from "../index";
import {useContext} from "react";
import {Context} from "utils";
import {InstanceType} from "interfaces";

type AccountTabType = {
    control: Control<IChangeContactInfoCommand>;
};

export const GeneralContactTab = ({control}: AccountTabType) => {
    const context = useContext(Context);
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        required
                        name='firstName'
                        label={formatMessage({id: 'firstName'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        required
                        name='lastName'
                        label={formatMessage({id: 'lastName'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                {context?.tenantInfo?.TypeDescription == InstanceType.AuditPortal ? (
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField readonly name='companyName' label={formatMessage({id: 'companyName'})}
                                             control={control}/>
                    </Stack.Item>
                ) : (
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField readonly name='companyName' label={formatMessage({id: 'trustee'})}
                                             control={control}/>
                    </Stack.Item>
                )}

                {context?.tenantInfo?.TypeDescription == InstanceType.AuditPortal && (
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField required name='jobTitle' label={formatMessage({id: 'jobTitle'})}
                                             control={control}
                                             rules={{
                                                 required: formatMessage({id: 'requiredField'}),
                                                 minLength: {
                                                     value: 3,
                                                     message: formatMessage({id: 'minLength'}, {min: 3})
                                                 },
                                             }}/>
                    </Stack.Item>
                )}
            </Stack>
        </Card>
    );
};
