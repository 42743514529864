import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

interface IRemoveSoftwareUploadProps {
    fileName: string,
    storageFolder: string,
    path: string
}

function useRemoveSoftwareUpload() {
    const context = React.useContext(Context);

    const { mutate: deleteSoftwareUpload, isLoading, isSuccess } = useMutation(({ fileName, storageFolder, path}: IRemoveSoftwareUploadProps) =>
        axios.delete(`${context?.config?.storageUrl}/api/1.0/storage/files`, { headers: commonHeaders().headers, data: {fileName: fileName, storageFolder: storageFolder, filePath: path, providerType: 1} }),
        {
            onSuccess: () => { queryClient.invalidateQueries('getSoftwareUploads'); },
        }
    );

    return { deleteSoftwareUpload, isLoading, isSuccess };
}

export { useRemoveSoftwareUpload };