export enum DashboardTabsEnum {
  // Audit Portal tabs
  AP_Administrator = "AP_Administrator",
  AP_Auditor ='AP_Auditor',
  AP_Contravention = 'AP_Contravention',
  AP_Manager = 'AP_Manager',
  AP_Partner = 'AP_Partner',
  ClientPortalWhite = 'ClientPortalWhite',
  ClientPortalBlack = 'ClientPortalBlack',

  // Workpapers tabs
  WP_Administrator = 'WP_Administrator',
  WP_Accountant = 'WP_Accountant',
  WP_Manager = 'WP_Manager',
  WP_Partner = 'WP_Partner'
}