import {useContext} from "react";
import {Context} from "../../../utils";
import {useMutation} from "react-query";
import {IJobReviewDetails} from "../interfaces";
import axios from "axios";

function useUpdateJobReviewDetails() {
    const context = useContext(Context);
    const host = context?.config?.jobUrl;

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: IJobReviewDetails) => axios.put(`${host}/api/1.0/jobs/${data.guid}/reviewDetails`, data));

    return {update, updateAsync, isUpdating};
}

export {useUpdateJobReviewDetails}