"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providerNativeFactory = void 0;
const react_1 = require("react");
const utils_1 = require("../../utils");
const utils_2 = require("../utils");
function providerNativeFactory(context) {
    const Provider = ({ url, connectEnabled = true, children, dependencies = [], onError, onOpen, onClose, logger = console, }) => {
        const onErrorRef = (0, utils_1.usePropRef)(onError);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const clear = (0, react_1.useRef)(() => { });
        function refreshConnection() {
            if (!connectEnabled) {
                return;
            }
            async function checkForStart() {
                var _a;
                if (!(0, utils_2.isConnectionConnecting)(context.connection)) {
                    try {
                        (0, utils_2.createConnection)(context, {
                            onClose,
                            onOpen,
                            logger,
                            url,
                            onErrorRef,
                        });
                    }
                    catch (err) {
                        console.log(err);
                        (_a = onErrorRef.current) === null || _a === void 0 ? void 0 : _a.call(onErrorRef, err);
                    }
                }
            }
            checkForStart();
            const checkInterval = setInterval(checkForStart, 6000);
            clear.current = () => {
                var _a;
                clearInterval(checkInterval);
                (_a = context.connection) === null || _a === void 0 ? void 0 : _a.close();
            };
        }
        (0, react_1.useState)(() => {
            refreshConnection();
        });
        const isMounted = (0, react_1.useRef)(false);
        (0, react_1.useEffect)(() => {
            if (isMounted.current) {
                refreshConnection();
            }
            isMounted.current = true;
            return () => {
                clear.current();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connectEnabled, url, ...dependencies]);
        return children;
    };
    return Provider;
}
exports.providerNativeFactory = providerNativeFactory;
