import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

type MutateDataType = {
    id: number;
    jobId: number;
    item?: string;
    value?: string;
    comment?: string;
}
function useUpdateUOCItem() {
    const context = useContext(Context);
    const {
        mutate: updateUOC,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/uoc/${data.jobId}`, data))

    return {updateUOC, isUpdating};
}

export {useUpdateUOCItem};