import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {ISectionInfo} from '../interfaces';

interface IGetTabByIdParams {
    tabId: number;
    templateId: number;
    jobId: number;
}

function useGetTabById({tabId, templateId, jobId}: IGetTabByIdParams) {
    const context = React.useContext(Context);
    const host = context?.config?.tabsUrl;
    const {data: tabData, isLoading: isTabDataLoading, refetch} = useQuery(
        ['getTabById', tabId, templateId, jobId],
        () => axios.get<ISectionInfo[]>(`${host}/api/1.0/Tabs/${tabId}`, {
            ...commonHeaders(),
            params: {templateId, jobId}
        }),
        {
            enabled: !!context?.config?.tabsUrl && !!context.tenantId && !!jobId && !!templateId,
            staleTime: 100,
            cacheTime: 100,
            retry: false,
        }
    );

    return {tabData, isTabDataLoading, refetch};
}

export {useGetTabById};