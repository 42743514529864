import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import { SoftwareType } from "../../../../../enums";

interface OAuthFundModel {
    fundCode: string;
    fundName: string;
    fundId: string;
    firmName?: string;
    abn?: string;
    fundEmail?: string;
    sfCode: string;
}

interface OAuthClientModel {
    softwareType: SoftwareType;
    isClientAuthenticated: boolean;
    isBusinessCodeAuthenticated: boolean;
    businessCode?: string;
    businessCodes?: string[];
    funds?: OAuthFundModel[];
    hasRequestError: boolean;
    requestErrorMessage?: string;
}

function useGetOauthInfo(jobId: number, businessCode?: string) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getOAuthInfo', jobId, businessCode], () => axios.get<OAuthClientModel>(`${context?.config?.importUrl}/api/1.0/import/oauthInfo/${jobId}${businessCode ? '?businessCode='+businessCode : ''}`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.annotationsUrl && !!jobId
    });

    return {response, isLoading, isFetching};
}

export {useGetOauthInfo};