import {useIntl} from "react-intl";
import {useCallback} from "react";
import {
    DashboardContainer,
    DashboardTableTypeEnum, DashboardTabsEnum,
    FilterByPeriod, GetDashboardConfig,
    IAccountantWPWidgetData,
    IDashboardConfig,
    WidgetsEnum
} from "../../../../shared/Dashboard";

export const AccountantWorkpapersDashboard = () => {
    const {formatMessage} = useIntl();

    const getConfig = useCallback(
        (props: IAccountantWPWidgetData): IDashboardConfig => {
            return {
                id: 'accountant',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'workpaperPrepCompletedToday',
                            tables: [{
                                tableType: DashboardTableTypeEnum.Accountant_WorkpapersPrepCompletedByPeriod,
                                filterByPeriod: FilterByPeriod.Day
                            }],
                            props: {
                                variant: "white",
                                number: props.workpaperPrepCompletedToday,
                                title: formatMessage({id: 'workpaperPrepCompletedToday'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'workpaperPrepCompletedYesterday',
                            tables: [{
                                tableType: DashboardTableTypeEnum.Accountant_WorkpapersPrepCompletedByPeriod,
                                filterByPeriod: FilterByPeriod.Yesterday
                            }],
                            props: {
                                variant: "white",
                                number: props.workpaperPrepCompletedYesterday,
                                title: formatMessage({id: 'workpaperPrepCompletedYesterday'})
                            }
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'workpaperPrepCompletedThisWeek',
                            tables: [{
                                tableType: DashboardTableTypeEnum.Accountant_WorkpapersPrepCompletedByPeriod,
                                filterByPeriod: FilterByPeriod.Week
                            }],
                            props: {
                                variant: "white",
                                number: props.workpaperPrepCompletedThisWeek,
                                title: formatMessage({id: 'workpaperPrepCompletedThisWeek'})
                            }
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'jobsAllocated',
                            tables: [{tableType: DashboardTableTypeEnum.Accountant_JobsNotStarted}, {tableType: DashboardTableTypeEnum.Accountant_JobsInProgress}],
                            props: {
                                data: props.jobsAllocated,
                                title: formatMessage({id: 'jobsAllocated'})
                            }
                        },
                        {
                            type: WidgetsEnum.BAR_CHART_WIDGET,
                            id: 'timesheets',
                            tables: [{tableType: DashboardTableTypeEnum.Accountant_Timesheet}],
                            props: {
                                data: props.timesheets,
                                title: formatMessage({id: 'timesheet'})
                            }
                        }
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id:'10',
                            props:{
                                tableType: DashboardTableTypeEnum.Accountant_ReviewPointsToBeCleared
                            }
                        }
                    ]
                ]
            }
        }, [formatMessage]);


    return <DashboardContainer
        dashboardTab={DashboardTabsEnum.WP_Accountant}
        getDashboardConfig={getConfig as GetDashboardConfig}/>;
}