import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import {EmailTemplate} from "../../hooks/Email/useEmailTemplateGet";


interface Props {
    control: Control<EmailTemplate>;
}

export const ContraventionEmail: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='contraventionEmail' />}>
            <>
                <ControlledRichTextField
                    name='contraventionEventEmail'
                    control={control}
                    label={formatMessage({ id: 'contraventionEmailBody' })}
                />

                <ControlledRichTextField
                    name='contraventionManagerReviewEmail'
                    control={control}
                    label={formatMessage({ id: 'contraventionManagerReviewEmailBody' })}
                />

                <ControlledRichTextField
                    name='contraventionPartnerReviewEmail'
                    control={control}
                    label={formatMessage({ id: 'contraventionPartnerReviewEmailBody' })}
                />
            </>
        </EmailTemplatePanel>
    );
};
