import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    date: Date;
}

function useAddReviewDate() {
    const context = useContext(Context);
    const {
        mutate: add,
        mutateAsync: addAsync,
        isLoading: isAdding
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/addReviewDate/${data.id}`, { date: data.date }))

    return {add, addAsync, isAdding};
}

export {useAddReviewDate};