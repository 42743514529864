import { DefaultButton, IColumn, Link, SelectionMode, Spinner, Stack, useTheme } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useJobContext } from ".";
import { DataTable, Modal, Search } from "../../components";
import { AttachmentInfo } from "./components";
import { useGetWrapAccounts } from "./hooks"


const WrapAccountContent: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const { job } = useJobContext();

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetWrapAccounts({
        year: job.year
    });

    const [searchString, setSearchString] = useState('');

    const searchHandler = (searchString: any) => {
        setSearchString(searchString);
    };

    const clearHandler = (searchString: any) => {
        setSearchString('');
    };

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) =>
                <AttachmentInfo id={item.id} isPermanent={item.isPermanent} attachmentJobId={item.jobId} fileName={item.fileName} originalFileName={item.originalFileName}
                    isEdited={item.isEdited} downloadUrl={item.downloadUrl} jobId={job.id} editingDisabled={true} deletingDisabled={true}/>
        }
    ]);

    if (isAttachmentsLoading) return <Stack horizontalAlign='center'><Spinner /></Stack>;
    if (!attachmentsData?.data) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack horizontal horizontalAlign='end'>
                <Search onSearch={searchHandler} onClear={clearHandler} placeholder={formatMessage({ id: 'searchFiles' })} />
            </Stack>
            <DataTable
                initialColumns={columns}
                columns={columns}
                items={searchString ? attachmentsData.data.filter(x => x.fileName.toLowerCase().includes(searchString.toLowerCase())) : attachmentsData.data}
                selectionMode={SelectionMode.none}
                enableShimmer={isAttachmentsFetching}
                containerHeight='100%'
            />
        </Stack>
    );
}

export const WrapAccounts: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    

    const linkClickHandler = () => {
        if (!isOpenModal) {
            toggleOpenModal();
        }
    }

    return (
        <>
            <Link style={{ color: theme.schemes?.default?.semanticColors.bodyText }} onClick={linkClickHandler}>
                {formatMessage({ id: 'library' })}
            </Link>
            <Modal
                title={formatMessage({ id: 'library' })}
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal()
                }}>
                <WrapAccountContent/>
            </Modal>
        </>);
}