import React from 'react';
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";


interface IGetClientFundsParams {
    clientGuid?: string;
}

export interface IFundInfo {
    id: number;
    guid: string;
    name: string;
    clientGuid: string;
}

function useGetClientFunds({clientGuid}: IGetClientFundsParams) {
    const context = React.useContext(Context);

    const {
        data: fundsData,
        isLoading: isFundsLoading,
        refetch: refetchFunds,
        isFetching: isFundsFetching
    } = useQuery(
        ['getClientFunds'],
        () => axios.get<IFundInfo[]>(
            `${context?.config?.clientUrl}/api/1.0/clients/${clientGuid}/funds`,
            {...commonHeaders()}
        ),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientGuid,
            retry: false,
        }
    );

    return {fundsData, isFundsLoading, refetchFunds, isFundsFetching};
}

export {useGetClientFunds};
