import {mergeStyleSets, Text, useTheme} from "@fluentui/react";
import {IStyle} from "@fluentui/merge-styles/lib/IStyle";
import {PropsWithChildren} from "react";

export type BadgeType = 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'neutral';

type BadgeProps = {
    type: BadgeType,
    styles?: { root?: IStyle };
    text?: string;
    fullWidth?: boolean;
}
export const Badge = ({type, text, fullWidth, styles: additionalStyles, children}: PropsWithChildren<BadgeProps>) => {
    const theme = useTheme();
    const getBadgeColor = (type: BadgeType) => {
        switch (type) {
            case "primary":
                return theme.palette.themePrimary;
            case "warning":
                return theme.palette.orangeLighter;
            case "danger":
                return theme.palette.red;
            case "info":
                return theme.palette.blue;
            case "success":
                return theme.palette.green;
            case 'neutral':
            default:
                return theme.palette.neutralQuaternary;
        }
    };

    const className = mergeStyleSets({
            root: {
                backgroundColor: getBadgeColor(type),
                color: 'white !important',
                padding: '4px 8px',
                borderRadius: 2,
                textAlign: 'center',
                width: fullWidth ? '100%' : ''
            },
        },
        additionalStyles);

    return (
        <Text
            variant='medium'
            className={className.root}>
            {text || children}
        </Text>
    );
}