import {observer} from 'mobx-react';
import {stores} from 'stores/MainStore';
import {Breadcrumb, IBreadcrumbItem, useTheme} from '@fluentui/react';
import {cloneDeep} from 'lodash';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';

export const LayoutBreadcrumb = observer(() => {
    const navigate = useNavigate();
    const onBreadcrumbItemClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => navigate(item?.key!);
    const breadcrumbs = cloneDeep(stores.BreadcrumbsStore.breadcrumbs).map((b) => ({
        ...b,
        onClick: b.disabled ? undefined : onBreadcrumbItemClicked,
    }));

    const theme = useTheme();

    return breadcrumbs.length ?
        <div style={{display: 'inline-block', width: '100%'}}>
            <Breadcrumb styles={{
                root: { marginTop: 0 },
                chevron: { color: theme.schemes?.default?.semanticColors.bodyText },
                itemLink: {
                    color: theme.schemes?.default?.palette.blackTranslucent40, selectors: {
                        '&:hover': { color: theme.schemes?.default?.palette.blackTranslucent40 },
                        '&:focus': { color: theme.schemes?.default?.palette.blackTranslucent40 },
                        '&:active:hover': { color: theme.schemes?.default?.semanticColors.bodyText},
                        '&:last-child': { color: theme.schemes?.default?.semanticColors.bodyText },
                        '&:hover:last-child': { color: theme.schemes?.default?.semanticColors.bodyText }
                    }
                }, listItem: {
                    selectors: {
                        '&:last-child .ms-Breadcrumb-itemLink': { color: theme.schemes?.default?.semanticColors.bodyText }
                    }
                }
            }} items={breadcrumbs} maxDisplayedItems={5} />
        </div>
        : null;
});
