import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { DataTable, SanitizedText } from 'components';
import {Attachment, GeneratedAnswer, JobComment, useSectionContext} from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatDate, getGrouppedReports } from 'utils';
import { AnswerControlType, TableType } from '../../../../../../enums';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";



export const ClassSuperBalanceReconciliation = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups,
        reports: items,
        groupKey: 'group',
        formatGroupName: (item) => item?.group ? `${item.group} ${formatDate(item?.startDate)} ${formatDate(item?.endDate)}` : 'Unknown group',
    }), [items]);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'ledgerQuantity',
                name: formatMessage({ id: 'ledgerQuantity' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'ledgerQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'ledgerBalance',
                name: formatMessage({ id: 'ledgerBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'ledgerBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'feedQuantity',
                name: formatMessage({ id: 'feedQuantity' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'feedQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'feedBalance',
                name: formatMessage({ id: 'feedBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'feedBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'difference',
                name: formatMessage({ id: 'difference' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'difference',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'review',
                name: formatMessage({ id: 'review' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'review',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText} auto={item?.isAnswerDisabled} disabled={item?.isAnswerDisabled}
                            answerControlType={AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType]} />
                    </Stack>
                ),
            },
            {
                key: 'type',
                name: formatMessage({ id: 'type' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'type',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'subType',
                name: formatMessage({ id: 'subtype' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'subType',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={reports}
        groups={groups}
        enableShimmer={isFetching}
    />
};
