import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { DataTable } from 'components';
import { RevokeConnectionButton } from './RevokeConnectionButton';
import { FunctionComponent } from "react";
import { useGetExternalConnectionsSettings } from "./hooks";
import { ExternalConnection } from "./models";

type ConnectionsProps = {
    
}

export const Connections: FunctionComponent<ConnectionsProps> = ({ ...props }: ConnectionsProps) => {
    const { formatMessage, formatDate } = useIntl();
    const { connectionSettings, isLoading } = useGetExternalConnectionsSettings();

    const [tableItems, setTableItems] = useState<ExternalConnection[]>([]);

    useEffect(() => {
        setTableItems(connectionSettings?.connections ?? [])
    }, [connectionSettings]);
    
    const columns: IColumn[] = [
        { 
            key: 'firm',
            fieldName: 'firm',
            name: formatMessage({id: 'accountingFirm'}),
            minWidth: 100,
            onRender: (item: ExternalConnection) => 
                <Text>{item.firm}</Text>
        },
        {
            key: 'date',
            fieldName: 'date',
            name: formatMessage({ id: 'approvedDate' }),
            minWidth: 100,
            onRender: (item) => (item.date ? `${(new Date(item.date)).toLocaleDateString('en-AU')}` : null),
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 87,
            maxWidth: 87,
            onRender: (item: ExternalConnection) => (
                <RevokeConnectionButton connectionId={item.connectionId} />
            ),
        },
    ];
    
    return (
        <>
            <DataTable initialColumns={columns}
                       items={tableItems}
                       enableShimmer={isLoading}
                       selectionMode={SelectionMode.none} />
        </>
    );
};