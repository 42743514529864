import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IScheduledExportInfo} from "../interfaces";

function useGetJobSchedules(jobId: number) {
    const context = useContext(Context);

    const {
        data: response,
        refetch,
        isLoading,
        isFetching
    } = useQuery(['getJobSchedules', jobId], () => axios.get<IScheduledExportInfo[]>(`${context?.config?.exportUrl}/api/1.0/jobs/${jobId}/export/scheduled`).then(resp => resp.data));
    
    return {response, refetch, isLoading, isFetching};
}

export {useGetJobSchedules};