import {useContext} from "react";
import {Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {SuggestedTestingType} from "../../enums";
import {IOption} from "../../interfaces";
import {TableType} from "../../../../enums";

export interface IJobSuggesting{
    answerOptions: IOption[];
    groups: IJobSuggestedTestingGroup[];
}

export interface IJobSuggestedTestingGroup{
    options: IOption[];
    type: SuggestedTestingType;
    items: IJobSuggestedTestingItem[];
}
export interface IJobSuggestedTestingItem {
    id: number;
    jobId: number;
    tableType: TableType;
    type: SuggestedTestingType;
    investment: string | null;
    value: number | null;
    isValueChanged: boolean;
    nonDataFedSampleSize: number | null;
    isNonDataFedSampleSizeChanged: boolean;
    feed: string | null;
    isFeedAuto: boolean;
    isFeedLocked: boolean;
    showFeed: boolean;
    balRec: string | null;
    isBalRecAuto: boolean;
    isBalRecLocked: boolean;
    showBalRec: boolean;
    fundSuspense: string | null;
    isFundSuspenseAuto: boolean;
    isFundSuspenseLocked: boolean;
    showFundSuspense: boolean;
    glatr: string | null;
    isGlatrAuto: boolean;
    isGlatrLocked: boolean;
    showGlatr: boolean;
    dataFedSampleSize: number | null;
    isDataFedSampleSizeChanged: boolean;
    useReducedTesting: boolean;
    isAddRow: boolean;
    isCustom: boolean;
    deep: number;
    hasAttachments: boolean;
    hasComments: boolean;
}

function useGetJobSuggestedTesting(jobId: number) {
    const context = useContext(Context);

    const {
        data,
        isLoading,
        refetch, 
        isRefetching
    } = useQuery(['jobSuggestedTesting', jobId], () => axios.get<IJobSuggesting>(`${context?.config?.suggestedTestingUrl}/api/1.0/jobs/${jobId}/suggestedTesting`));

    return {data, isLoading, refetch, isRefetching};
}

export {useGetJobSuggestedTesting};