import React from 'react';
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";
import { IFundInfo } from './useGetClientFunds';


interface IGetClientFundsParams {
    clientGuid?: string;
    name?: string;
}

export interface IPaginatedFundsInfo {
    totalItemsCount: number,
    shownItemsCount: number,
    items: IFundInfo[],
}

function useGetFilteredFunds(params: IGetClientFundsParams) {
    const context = React.useContext(Context);

    const {
        data: fundsData,
        isLoading: isFundsLoading,
        refetch: refetchFunds,
        isFetching: isFundsFetching
    } = useQuery(
        ['getFilteredFunds', params],
        () => axios.get<IPaginatedFundsInfo>(
            `${context?.config?.clientUrl}/api/1.0/funds/filter`,
            {...commonHeaders(), params: params}
        ),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && (!!params.clientGuid || !!params.name),
            retry: false,
        }
    );

    return {fundsData, isFundsLoading, refetchFunds, isFundsFetching};
}

export {useGetFilteredFunds};
