import { AnimationStyles, DefaultEffects, IComboBoxOption, mergeStyles, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useJobContext } from '../../JobPortalLayoutPage';
import { useForm } from 'react-hook-form';
import { IJobDetails } from './interfaces';
import { DefaultFormSettings } from '../../../../constants';
import { ControlledAutoComplete, ControlledDatePicker, ControlledSelect } from '../../../../components';
import { useIntl } from 'react-intl';
import { _GetWorkContextQueryKey, PortalRole, useTenantInfo, useWorkContext } from '../../../../providers';
import { useGetAllActiveEmployees, useUpdateJobDetails } from './hooks';
import { queryClient } from '../../../../utils';
import { Depths } from '@fluentui/theme';

export const JobDetailsTemplate = () => {
    const { formatMessage } = useIntl();
    const { isWorkpapers } = useTenantInfo();
    const { job, refetch } = useJobContext();
    const { isCurrentUserJobPartner, isCurrentUserJobAuditor, isCurrentUserJobManager, isInRoleOrSuperAdministrator, workContext } =
        useWorkContext();

    const { employees: employees, isLoading: isEmployeesLoading } = useGetAllActiveEmployees();
    const { update, isUpdating } = useUpdateJobDetails();

    const theme = useTheme();

    const { control, watch, setValue, reset } = useForm<IJobDetails>({
        ...DefaultFormSettings,
        mode: 'onBlur',
        defaultValues: {
            id: job.id,
            guid: job.guid,
            auditorId: job.auditor?.guid,
            auditorDate: job.auditor?.reviewed,
            managerId: job.manager?.guid,
            managerDate: job.manager?.reviewed,
            partnerId: job.partner?.guid,
            partnerDate: job.partner?.reviewed,
        },
    });

    useEffect(() => {
        reset({
            id: job.id,
            guid: job.guid,
            auditorId: job.auditor?.guid,
            auditorDate: job.auditor?.reviewed,
            managerId: job.manager?.guid,
            managerDate: job.manager?.reviewed,
            partnerId: job.partner?.guid,
            partnerDate: job.partner?.reviewed,
          });
    }, [job.id, setValue]);

    const contentStyles = mergeStyles({
        position: 'absolute',
        left: 200,
        top: 30,
        backgroundColor: theme.palette.white,
        width: 'calc(100% - 200px)',
        zIndex: 2,
        padding: theme.spacing.l1,
        ...AnimationStyles.slideDownIn20,
    });

    const managerWatch = watch('managerId');
    const partnerWatch = watch('partnerId');

    const managerOptions = useMemo<IComboBoxOption[]>(() => {
        const isAdministrator = isInRoleOrSuperAdministrator(PortalRole.Administrator);

        return (employees ?? [])
            .filter((x) =>
                isAdministrator ? true : managerWatch ? x.guid === managerWatch : x.guid === workContext?.user?.identityUser?.id
            )
            .map((x) => ({ key: x.guid, text: x.fullName }));
    }, [employees, managerWatch, workContext]);

    const partnerOptions = useMemo<IComboBoxOption[]>(() => {
        const isAdministrator = isInRoleOrSuperAdministrator(PortalRole.Administrator);

        return (employees ?? [])
            .filter((x) =>
                isAdministrator ? true : partnerWatch ? x.guid === partnerWatch : x.guid === workContext?.user?.identityUser?.id
            )
            .map((x) => ({ key: x.guid, text: x.fullName }));
    }, [employees, partnerWatch, workContext]);

    const onSubmit = useCallback((props: any) => {
        if (
            props.partnerId == job.partner?.guid &&
            props.partnerDate == job.partner?.reviewed &&
            props.managerId == job.manager?.guid &&
            props.managerDate == job.manager?.reviewed &&
            props.auditorDate == job.auditor?.reviewed
        ) {
            return;
        }

        update(
            {
                id: props.id,
                guid: props.guid,
                partnerId: props.partnerId,
                partnerDate: props.partnerDate,
                managerId: props.managerId,
                managerDate: props.managerDate,
                auditorDate: props.auditorDate,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(_GetWorkContextQueryKey);
                    refetch?.();
                },
            }
        );
    }, []);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    const textFieldStyles = {
        root: {
            width: '45%',
            selectors: { '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText } },
        },
        field: {
            '-webkit-box-shadow': `inset 0 0 0 50px ${theme.palette.white}`,
            '-webkit-text-fill-color': theme.schemes?.default?.semanticColors.inputText,
        },
        fieldGroup: {
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            selectors: { '&:hover': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } },
        },
    };

    const [isAuditorControlEnabled, setIsAuditorControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsAuditorControlEnabled((!!isCurrentUserJobAuditor || isInRoleOrSuperAdministrator(PortalRole.ReviewerUser)) && !isUpdating);
    }, [isCurrentUserJobAuditor, isUpdating]);

    const [isManagerControlEnabled, setIsManagerControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsManagerControlEnabled((!!isCurrentUserJobManager || isInRoleOrSuperAdministrator()) && !isUpdating);
    }, [isCurrentUserJobManager, isUpdating]);

    const [isPartnerControlEnabled, setIsPartnerControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsPartnerControlEnabled((!!isCurrentUserJobPartner || isInRoleOrSuperAdministrator()) && !isUpdating);
    }, [isCurrentUserJobPartner, isUpdating]);

    const [isManagerSelectorEnabled, setIsManagerSelectorEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsManagerSelectorEnabled(
            (isInRoleOrSuperAdministrator(PortalRole.Administrator) || !managerWatch) && !isUpdating && !isEmployeesLoading
        );
    }, [managerWatch, isUpdating, isEmployeesLoading]);

    const [isPartnerSelectorEnabled, setIsPartnerSelectorEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsPartnerSelectorEnabled(
            (isInRoleOrSuperAdministrator(PortalRole.Administrator) || !partnerWatch) && !isUpdating && !isEmployeesLoading
        );
    }, [partnerWatch, isUpdating, isEmployeesLoading]);

    return (
        <Stack className={contentStyles} tokens={{ childrenGap: 16 }} verticalAlign='center'>
            <Stack horizontal tokens={{ childrenGap: 16 }} style={{ width: '50%', position: 'relative', left: 38 }}>
                <TextField
                    styles={{ ...textFieldStyles, root: { ...textFieldStyles.root, width: '45%' } }}
                    readOnly
                    disabled
                    value={
                        employees?.find((x) => x.guid === job.auditor?.guid)?.fullName ??
                        formatMessage({ id: isWorkpapers ? 'jobDetails_label_accountant' : 'jobDetails_label_auditor' })
                    }
                    placeholder={formatMessage({ id: isWorkpapers ? 'jobDetails_label_accountant' : 'jobDetails_label_auditor' })}
                />
                <ControlledSelect
                    styles={{ container: { width: '45%' } }}
                    options={managerOptions}
                    placeholder={formatMessage({ id: 'jobDetails_label_manager' })}
                    control={control}
                    disabled={!isManagerSelectorEnabled}
                    name='managerId'
                    allowFreeform
                    autoComplete
                />
                <ControlledSelect
                    options={partnerOptions}
                    styles={{ container: { width: '45%' } }}
                    placeholder={formatMessage({ id: 'jobDetails_label_partner' })}
                    control={control}
                    disabled={!isPartnerSelectorEnabled}
                    name='partnerId'
                    allowFreeform
                    autoComplete
                />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 16 }} style={{ width: '50%', position: 'relative', left: 38 }}>
                <ControlledDatePicker
                    styles={{ root: { width: '45%' } }}
                    control={control}
                    disabled={!isAuditorControlEnabled}
                    name={'auditorDate'}
                />
                <ControlledDatePicker
                    styles={{ root: { width: '45%' } }}
                    control={control}
                    disabled={!isManagerControlEnabled}
                    name={'managerDate'}
                />
                <ControlledDatePicker
                    styles={{ root: { width: '45%' } }}
                    disabled={!isPartnerControlEnabled}
                    control={control}
                    name={'partnerDate'}
                />
            </Stack>
        </Stack>
    );
};
