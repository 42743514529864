import {FunctionComponent} from 'react';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {Stack, TextField} from '@fluentui/react';
import {ColumnInfo} from "../../../enums";
import {TextBoxAnswer, WorkDoneSwitchAnswer} from "../answers";
import {FullWidth} from "../../../../../constants";


export const MandatoryQuestionProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    return (
        <ProceduresDataTable
            items={items}
            isLoading={isLoading}
            isHeaderVisible={false}
            columns={[
                {
                    key: 'question',
                    name: '',
                    minWidth: 500,
                    fieldName: 'question',
                    onRender: (items) =>
                        <Stack tokens={{childrenGap: 16}} styles={FullWidth}>
                            <Stack grow={2} horizontal tokens={{childrenGap: 16}} verticalAlign={"center"}
                                   horizontalAlign={"space-between"}>
                                <Stack.Item>
                                    <SanitizedText data={items?.title}/>
                                </Stack.Item>
                                <Stack.Item>
                                    <WorkDoneSwitchAnswer reverse
                                                          itemId={items.id}
                                                          tableType={items.tableType}
                                                          value={items.answerText}
                                                          iconTypes={'character'}
                                                          columnInfo={ColumnInfo.Text}/>
                                </Stack.Item>
                            </Stack>
                            <Stack.Item>
                                <TextBoxAnswer value={items.answerText2}
                                               itemId={items.id}
                                               tableType={items.tableType}
                                               columnInfo={ColumnInfo.Text2}/>
                            </Stack.Item>
                        </Stack>
                },
            ]}
        />
    );
};