import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

type SingleMateriality = {
    id: number;
    overallAuditJobRiskRating: string;
    applyOverallMateriality: boolean;
    percentageApplied: number;
};

export type PerfomanceMaterially = SingleMateriality[];

/**
 * Query for get Job Risk Settings data in Portal Settings.
 * First table in page Risk Matrix Settings
 */

function useGetPerfomanceMaterialitySettings(jobId: number) {
    const context = React.useContext(Context);
    const { data: perfomanceMaterialitySettings, isLoading: getPerfomanceMaterialitySettingsLoading } = useQuery(
        ['getJobPerfomanceMaterialitySettings'],
        () => axios.get<PerfomanceMaterially>(`${context?.config?.riskMatrixUrl}/api/1.0/jobs/${jobId}/performanceMateriality`, commonHeaders()),
        {
            enabled: !!context?.config?.riskMatrixUrl && !!context.tenantId,
            onError: (err: AxiosError) => {
                queryClient.invalidateQueries('getJobPerfomanceMaterialitySettings')
            },
        }
    );

    return { perfomanceMaterialitySettings, getPerfomanceMaterialitySettingsLoading };
}

export { useGetPerfomanceMaterialitySettings };
