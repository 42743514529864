import {IInvoiceExtraAmount} from "./interfaces";
import {IDropdownOption} from "@fluentui/react";
import {InvoiceParagraphType} from "./enums";
import {BillingEntityType} from "../../enums";
import { OsTypes, osName, isMacOs } from 'react-device-detect'

export const _InitialExtraAmountSumValue: number = 0;

export const calculateExtraAmountSum = (items: IInvoiceExtraAmount[]): number => {
    return (items ?? []).reduce((acc, item) => (+acc) + (+item.amount), _InitialExtraAmountSumValue);
}

export const calculateTotal = (amount: number, extraAmount: number): number => {
    const value = +amount + calculateExtraAmountSum([{ amount: extraAmount } as IInvoiceExtraAmount]);
    return isNaN(value) ? 0 : value;
}

export const _ParagraphDropdownOptions: IDropdownOption[] = [
    {key: InvoiceParagraphType.DD, text: InvoiceParagraphType[InvoiceParagraphType.DD]},
    {key: InvoiceParagraphType.Standard, text: InvoiceParagraphType[InvoiceParagraphType.Standard]}
];

export const _BilllingEntityDropdownOptions: IDropdownOption[] = [
    {key: BillingEntityType.Client, text: BillingEntityType[BillingEntityType.Client]},
    {key: BillingEntityType.Fund, text: BillingEntityType[BillingEntityType.Fund]}
];

export const getOS = (): string => osName;

export const isMacOS = (): boolean => getOS() === OsTypes.MAC_OS;