import { IColumn, mergeStyleSets, SelectionMode, Stack } from "@fluentui/react";
import { DataTable, SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getGrouppedReports } from 'utils';
import { GeneratedAnswer, useReportValidationsContext, useSectionContext } from '../../..';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { ReportTemplateTypeProps } from '../Types';
import { TableType } from '../../../../../../enums';
import { ColumnInfo } from '../../../../enums';
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";

export const ClassSuperRealisedCapitalGainsReport = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { section } = useSectionContext();

    const addGroupKey = (keys: string[], reports?: any[]): any[] => {
        const combinedKey = keys.join('_');
        return (reports ?? []).reduce((acc, a) => {
            const item = {
                ...a,
                children: addGroupKey(keys, a.children),
            };
            item[combinedKey] = keys
                .reduce((acc_keys, k) => {
                    acc_keys.push(item[k].toString());
                    return acc_keys;
                }, [] as string[])
                .join('_');
            acc.push(item);
            return acc;
        }, []);
    };

    const { reports: detailReports, groups: detailGroups } = useMemo(
        () =>
            getGrouppedReports({
                pushEmptyGroup: !!allowEmptyGroups,
                reports: addGroupKey(['marketName', 'id'], items[0]?.details as any[]),
                groupKey: 'marketName_id',
                formatGroupName: (item: { marketName: string }) => item.marketName,
            }),
        [items]
    );

    const { reports: summaryReports, groups: summaryGroups } = useMemo(
        () =>
            getGrouppedReports({
                pushEmptyGroup: !!allowEmptyGroups,
                reports: items[0]?.summary as any[],
                groupKey: 'group',
            }),
        [items]
    );

    const classNames = mergeStyleSets({
        headerCellFirst: {
            '&.ms-DetailsRow-cell': {
                overflow: 'unset'
            }
        },
        headerCell: {
            '&.ms-DetailsRow-cell:not(:has(span))': {
                borderLeft: 'none',
            }
        }
    })

    const detailColumns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'date',
                className: classNames.headerCellFirst,
                onRender: (item, _, column) => {
                    if (item.marketName === 'Total' && item.isTotalRow) {
                        return (
                            <SanitizedText
                                data={item['holdingAccountName']}
                                styles={getDepthPaddingStyles(item?.depth)}
                                isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                                format={item?.format}
                                textWhiteSpace={'nowrap'}
                            />
                        )
                    }

                    return (
                        <SanitizedText
                            data={item[column?.fieldName || 0]}
                            styles={getDepthPaddingStyles(item?.depth)}
                            isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                            format={item?.format}
                        />
                    )
                },
            },
            {
                key: 'capitalEventType',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                className: classNames.headerCell,
                fieldName: 'capitalEventType',
                onRender: (item, _, column) => {
                    if (item.marketName === 'Total' && item.isTotalRow) {
                        return <></>
                    }
                    
                    return (
                        <SanitizedText data={item[column?.fieldName || 0]}
                                       isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} />
                    )
                }
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {!item?.isHeadingRow && !item?.isTotalRow && (
                            <GeneratedAnswer
                                itemId={item?.id}
                                tableType={TableType.ClassSuperRealisedCapitalGainsReport}
                                value={item?.answerText}
                                auto={item?.isAuto}
                                disabled={item?.isAuto}
                                answerControlType={item?.answerControlType}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'quantity',
                name: formatMessage({ id: 'quantity' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'quantity',
                onRender: (item, _, column) => (
                    <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} />
                ),
            },
            {
                key: 'proceeds',
                name: formatMessage({ id: 'atProceeds' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'proceeds',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'bookCost',
                name: formatMessage({ id: 'atOriginalCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'bookCost',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'profitLoss',
                name: formatMessage({ id: 'atAccountingProfitLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'profitLoss',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'adjustedCostBase',
                name: formatMessage({ id: 'adjCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'adjustedCostBase',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'reducedCostBase',
                name: formatMessage({ id: 'reducedCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reducedCostBase',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'indexedCostBase',
                name: formatMessage({ id: 'indexedCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'indexedCostBase',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'indexedGain',
                name: formatMessage({ id: 'capitalGainIndexation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'indexedGain',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'discountableGain',
                name: formatMessage({ id: 'capitalDiscountableGain' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'discountableGain',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'otherGain',
                name: formatMessage({ id: 'capitalOtherGain' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'otherGain',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'capitalLoss',
                name: formatMessage({ id: 'capitalLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalLoss',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const summaryColumns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <></>
                ),
            },
            {
                key: 'categoryName',
                name: '',
                minWidth: 300,
                fieldName: 'categoryName',
                onRender: (item, _, column) => (
                    <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} />
                ),
            },
            {
                key: 'indexation',
                name: formatMessage({ id: 'indexation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'indexation',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'discount',
                name: formatMessage({ id: 'discount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'discount',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'other',
                name: formatMessage({ id: 'other' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'other',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'totalCapitalGains',
                name: formatMessage({ id: 'totalCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalCapitalGains',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'capitalLosses',
                name: formatMessage({ id: 'capitalLosses' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalLosses',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return (
        <>
            <ReportTable
                initialColumns={detailColumns}
                columns={detailColumns}
                header={{ rowHeight: _ExtendedHeaderRowHeight }}
                items={detailReports}
                groups={detailGroups}
                enableShimmer={isFetching}
                openAll={true}
            />
            <ReportTable
                initialColumns={summaryColumns}
                columns={summaryColumns}
                items={summaryReports}
                groups={summaryGroups}
                enableShimmer={isFetching}
                openAll={true}
            />
        </>
    );
};
