import { useContext } from 'react';
import { Context } from '../../../utils';
import { useQuery } from 'react-query';
import axios from 'axios';
import { IJobYearsInfo } from '../interfaces';

function useGetJobYears(id?: number) {
    const context = useContext(Context);
    const host = context?.config?.jobUrl;

    const {
        data: jobYears,
        isLoading,
        isFetching,
    } = useQuery(['getJobYears', id], () => axios.get<IJobYearsInfo[]>(`${host}/api/1.0/job/${id}/jobYears`).then((resp) => resp.data), {
        enabled: !!id,
    });
    return { jobYears, isLoading, isFetching };
}

export { useGetJobYears };
