import { FunctionComponent, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useIntl } from 'react-intl';

export const Menu: FunctionComponent = () => {
    const [selectedKey, setSelectedKey] = useState('rectangleRed');
    const intl = useIntl();

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setSelectedKey(item.props.itemKey!);
        }
    };

    return (
        <>
            <Pivot
                selectedKey={selectedKey}
                onLinkClick={handleLinkClick}
                headersOnly={true}
                styles={{ root: { height: 48 }, link: { height: 48 } }}>
                <PivotItem headerText={intl.formatMessage({ id: 'dashboard' })} itemKey='/dashboard' />
                <PivotItem headerText={intl.formatMessage({ id: 'jobs' })} itemKey='/jobs' />
                <PivotItem headerText={intl.formatMessage({ id: 'funds' })} itemKey='/funds' />
            </Pivot>
        </>
    );
};
