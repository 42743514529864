import React, {FunctionComponent, useCallback, useState} from "react";
import {
    DialogType,
    mergeStyleSets,
    NeutralColors,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {useIntl} from "react-intl";
import {ControlledDatePicker, DialogMessage} from "../../../../../../components";
import {useForm} from "react-hook-form";
import {IJobDetails} from "../../../JobDetails/interfaces";
import {DefaultFormSettings} from "../../../../../../constants";
import {useIsDarkTheme} from "../../../../../../hooks";
import {useAddReviewDate} from "./hooks/useAddReviewDate";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useBoolean} from "@fluentui/react-hooks";
import {useSectionContext} from "../../../Section";

interface IReviewedOSQueryDateFormProps {
    
}

interface IReviewedOSQueryDateForm {
    date?: Date;
}

enum AddReviewDateState {
    Ok = 0,
    NoResponseAvailable = 10,
    AllQueriesReviewed = 20
}

interface IAddReviewDateResponse {
    state: AddReviewDateState
}

export const ReviewdOSQueryDateForm: FunctionComponent<IReviewedOSQueryDateFormProps> = ({
    
                                                                                        }: IReviewedOSQueryDateFormProps) => {
    const {formatMessage} = useIntl();
    const jobContext = useJobContext();
    const {refresh} = useSectionContext();
    const {add, isAdding} = useAddReviewDate();
    
    const isDark = useIsDarkTheme();
    const theme = useTheme();
    const borderColor = isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30;

    const classNames = mergeStyleSets({
        holder: {
            border: `1px solid ${borderColor}`,
            borderRadius: 4,
            padding: 16
        }
    });
    
    const {control, watch} = useForm<IReviewedOSQueryDateForm>({
        ...DefaultFormSettings,
        mode: 'onChange',
        defaultValues: {
            date: undefined
        }
    });

    const [showDialog, {toggle: toggleDialog}] = useBoolean(false);
    const [dialogMessage, setDialogMessage] = useState<string>();
    
    const onUpdate = useCallback(() => {
        const date = control._formValues['date'];
        if (date) {
            add({ id: jobContext.jobId, date: date }, {
                onSuccess: (res) => {
                    let message: string;
                    const state = (res?.data as IAddReviewDateResponse).state;
                    switch (state) {
                        case AddReviewDateState.AllQueriesReviewed:
                            message = formatMessage({id: 'allQueriesReviewedMessage'})
                            break;
                        case AddReviewDateState.NoResponseAvailable:
                            message = formatMessage({id: 'noQueryResponseAvailable'})
                            break;
                        default:
                            message = formatMessage({id: 'queriesAdded'})
                            break;
                    }

                    setDialogMessage(message)
                    toggleDialog()
                }
            });
        }
    }, [])
    
    return (
        <Stack className={classNames.holder} tokens={{childrenGap: 16}}>
            <Stack.Item>
                <Text theme={theme.schemes?.default}>{formatMessage({id: 'addReviewedOSQueriesDate'})}</Text>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{childrenGap: 16}} horizontal>
                    <Stack.Item grow={1}>
                        <ControlledDatePicker
                            control={control}
                            disabled={isAdding}
                            name={'date'}/>
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <PrimaryButton disabled={isAdding} onClick={onUpdate}>
                            {isAdding 
                                ? <Spinner size={SpinnerSize.small}/> 
                                : formatMessage({id: 'update'})}
                        </PrimaryButton>
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            <DialogMessage onClick={() => { toggleDialog(); refresh?.() }}
                           dialogContentProps={{
                               theme: theme.schemes?.default,
                               type: DialogType.normal,
                               title: formatMessage({id: 'information'}),
                               closeButtonAriaLabel: 'Close',
                               subText: dialogMessage,
                           }}
                           showCancel={false}
                           hidden={!showDialog}
                           onDismis={() => { toggleDialog(); refresh?.() }}/>
        </Stack>
    )
}