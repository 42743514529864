export enum TableType {
  // Reports 
  ClassSuperStatmentofFP = 1,
  ClassSuperOperatingStatement = 2,
  ClassSuperInvestmentMovement = 3,
  ClassSuperGeneralLedger = 4,
  ClassSuperInvestmentIncome = 5,
  ClassSuperInvestmentSummary = 6,
  ClassSuperMemberStatement = 7,
  ClassSuperRealisedCapitalGainsReport = 8,
  ClassSuperTaxableIncomeReport = 9,
  ClassSuperBankAccountMovementReport = 10,
  ClassSuperTaxAccountingReconciliation = 11,
  ClassSuperInvestmentIncomeComparision = 12,
  ClassSuperGeneralLedgerAuditTrail = 56,
  ClassSuperTransferBalanceDeclaration = 57,
  ClassSuperDetailedFinancialPosition = 58,

  BGLFinancialPosition = 13,
  BGLOperating = 14,
  BGLTaxableIncome = 15,
  BGLMemberStatement = 16,
  BGLInvestmentSummary = 17,
  BGLInvestmentSummaryWithYield = 18,
  BGLInvestmentIncome = 19,
  BGLInvestmentDisposal = 20,
  BGLInvestmentMovement = 21,
  BGLDeferredTax = 22,
  DesktopSuperGeneralLedger = 23,
  DesktopSuperTrialBalance = 24,
  DesktopSuperUnreconciledCashflow = 25,
  DesktopSuperFinancialPosition = 26,
  DesktopSuperOperating = 27,
  DesktopSuperInvestmentSummary = 28,
  DesktopSuperInvestmentIncome = 29,
  DesktopSuperInvestmentMovement = 30,
  DesktopSuperRealisedCapitalGains = 31,
  DesktopSuperUnrealisedCapitalGains = 32,
  DesktopSuperTaxReconciliation = 33,
  DesktopSuperMemberStatement = 34,
  SupermateGeneralLedger = 35,
  SupermateFinancialPosition = 36,
  SupermateOperating = 37,
  SupermateInvestmentSummary = 38,
  SupermateInvestmentIncome = 39,
  SupermateInvestmentMovement = 40,
  SupermateRealisedCapitalGains = 41,
  SupermateTaxReconciliation = 42,
  SupermateMemberStatement = 43,
  BGL360InvestmentIncome = 44,
  BGL360Trialbalance = 45,
  BGL360GeneralLedger = 46,
  BGL360InvestmentSummary = 47,
  BGL360FinancialPosition = 48,
  BGL360OperatingStatement = 49,
  BGL360MemberStatement = 50,
  BGL360RealisedCapitalGains = 51,
  BGL360InvestmentMovement = 52,
  BGL360TaxAccounting = 53,
  BGL360TaxableIncome = 54,

  ClassSuperBalanceReconciliation = 55,
  ClassSuperConsolidatedInvestmentIncome = 60,
  ClassSuperFeedsConsole = 61,
  ClassSuperHoldingsBalanceReconciliation = 62,

  SupermateMemberStatementAccountItem = 57,
  SupermateMemberStatementMoneyItem = 58,
  BGLMemberStatementDetail = 59,

  //=========================
  // 100-199 FOR CLASS
  //-------------------------
  //PROCEDURES 100-149

  //REPORTS 150-199

  //-------------------------
  //200-299 FOR BGL 
  //-------------------------
  //PROCEDURES 200-249

  //REPORTS 250-299

  //-------------------------
  //300-399 FOR BGL360
  //-------------------------
  //PROCEDURES 300-349

  //REPORTS 350-399
  BGL360BankStatement = 350,
  BGL360FinancialPositionAPI = 351,

  BGL360OperatingStatementAPI = 352,
  BGL360MemberStatementAPI = 353,
  BGL360InvestmentMovementAPI = 354,

  //-------------------------
  //400-499 FOR SUPERMATE
  //-------------------------
  //PROCEDURES 400-449

  //REPORTS 450-499

  //=========================

  SuggestedTesting = 200,
  AuditMethodology = 201,

  //templated tables
  FundDetailsForm = 501,
  ReviewItem = 502,
  ReviewQualificationItem = 503,
  AuditProcedure = 1000,
  ComplianceSummaryItem = 1001,
  GeneralQuestion = 1002,
  MandatoryQuestion = 1003,
  TestSummary = 1004,
  RiskCategorization = 1005,
  JobTestSummaryReview = 1007,
  AuditQualification = 1008,
  Administration = 1009,
  CompletionLetter = 1010,
  ComparativeInformation = 1012,

  ClientComments = 1013,
  FileNotes = 1014,
  QuerySummary = 1015,
  AuditReadySummary = 1016,
  AuditReadySummarySection = 1017,

  // job specific items

  ValidationError = 1101,
  UndersAndOversCalculation = 1102,
  AssessmentIdentifiedRisk = 1103,
  InitialAudit = 1104,
  DispatchAudit = 1105,
  ClientFunds = 1501,

  AuditAcceptanceIssuesTable = 1108,
  IIChecklistTable = 1109,
  HINTable = 1110,

  JobTabAttachment = 1601,
  JobTabComments = 1602,
  JobAttachment = 1603,
  AuditQueriesForClients = 1604,
  WrapAccounts = 1605,
  SoftwareUploads = 1606,
  PropertyDetails = 1607,

  RiskMatrixNote = 1620,

  // New conclusion tab queries
  ConclusionClientAssessment = 1701,
  ConclusionPlanning = 1702,
  ConclusionRegulatoryReview = 1703,
  ConclusionStatementOfFP = 1704,
  ConclusionOperatingStatement = 1705,
  ConclusionInvestments = 1706,
  ConclusionIncome = 1707,
  ConclusionMembers = 1708,
  SubsequentEvents = 1709,
  ConclusionOpinion = 1710,

  // Fee Calculation
  FeeCalculation = 1801,

  // PDf Report
  PdfReport = 2000,
  AdditionalInclusions = 2001,
  Zip = 2002,
  ScheduledPDFAnswers = 2003,
  ZipBulk = 2004,
  
  ClassSuperDetailedOperatingStatement = 88001, 
  ClassSuperConsolidatedMemberBenefitTotals = 88010, 
  ClassSuperMemberBenefitStatement = 88020, 
  
  MultipleReportsTableType = 99000, 
  UndefinedTableType = 99999
}