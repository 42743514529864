import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useUserManagement} from '../UserManagementPage';
import {IIconProps, Stack, Toggle, TooltipHost} from "@fluentui/react";
import {IconButton} from "@fluentui/react/lib/Button";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {Modal, SearchRow} from "components";
import {UserManagementAddUserForm} from "./UserManagementAddUserForm";

interface Props {
    isLoading: boolean;
}

export const UserManagementFilters: FunctionComponent<Props> = ({isLoading}) => {
    const [onlySuperUsers, {toggle: toggleOnlySuperUsers}] = useBoolean(false);
    const [includeDeleted, {toggle: toggleIncludeDeleted}] = useBoolean(false);
    const [searchString, setSearchString] = useState('');

    const {filters, setFilters} = useUserManagement();
    const {formatMessage} = useIntl();

    const onSearch = (newValue: string) => setSearchString(newValue);

    const onClear = () => {

        setSearchString('');
    };
    const add: IIconProps = {iconName: 'add'};
    const addBtnToolTipId = useId('add-tooltip');

    const [isAddModalOpen, {toggle: toggleIsAddModalOpen}] = useBoolean(false);


    const rightSide = (<>
            <TooltipHost
                content={formatMessage({id: 'add'})}
                id={addBtnToolTipId}>
                <IconButton aria-describedby={addBtnToolTipId} iconProps={add}
                            onClick={toggleIsAddModalOpen} label='Add'/>
            </TooltipHost>
        </>
    )

    useEffect(() => {
        setFilters({
            onlySuperUsers: onlySuperUsers,
            includeDeleted: includeDeleted,
            searchString: searchString,
            page: 1,
            pageSize: filters.pageSize
        });
    }, [onlySuperUsers, includeDeleted, searchString])

    return (
        <>
            <SearchRow rightSide={rightSide} showAdditionalFilter={true}
                       search={{onSearch: onSearch, onClear: onClear}}>
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <Toggle onChange={() => {
                            toggleOnlySuperUsers();
                        }}
                                checked={onlySuperUsers}
                                label={formatMessage({id: 'onlySuperUser'})}/>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle checked={includeDeleted}
                                onChange={() => {
                                    toggleIncludeDeleted();
                                }}
                                label={formatMessage({id: 'includeDeleted'})}/>
                    </Stack.Item>
                </Stack>
            </SearchRow>

            <Modal isOpen={isAddModalOpen} onDismiss={toggleIsAddModalOpen} title={formatMessage({id: 'addNewUser'})}>
                <UserManagementAddUserForm onDismiss={toggleIsAddModalOpen}/>
            </Modal>
        </>
    )
};
