import axios from 'axios';
import {DashboardTabsEnum} from '../enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface Props {
    dashboardTab: DashboardTabsEnum;
    clientId?: string;
    fundId?: string;
    filterByYear?: string;
    isWorkflow?: boolean;
    clientGuid?: string;
    fundGuid?: string;
    contactGuid?: string;
}

export interface IDoughnutItem {
    name: string;
    count: number;
}

interface IWidgetData {
    dashboardTab: DashboardTabsEnum;
}

//WP 
export interface IAdministrationWPWidgetData extends IWidgetData {
    jobsAwaitingAccountantAllocation: number;
    jobsAwaitingManagerReview: number;
    jobsAwaitingPartnerReview: number;
    jobsInProgressWithAccountant: number;
    jobsInProgressWithAuditor: number;
    workpapersFinalisedThisWeek: number;
}

export interface IAccountantWPWidgetData extends IWidgetData {
    workpaperPrepCompletedToday: number;
    workpaperPrepCompletedYesterday: number;
    workpaperPrepCompletedThisWeek: number;
    jobsAllocated: IDoughnutItem[];
    timesheets: IDoughnutItem[];
}

export interface IManagerWPWidgetData extends IWidgetData {
    reviewedToday: number;
    reviewedYesterday: number;
    reviewedThisWeek: number;
    myJobsInProgress: number;
    teamWorkpapersPrepByStatus: IDoughnutItem[];
    teamWorkpapersPrepByAccountant: IDoughnutItem[];
    myJobsWithReviewPoints: number;
}

export interface IPartnerWPWidgetData extends IWidgetData {
    jobsReadyForReview: number;
    reviewPointsToBeCleared: IDoughnutItem[];
    activeClients: number;
    workpapersFinalisedThisWeek: number;
}

//AP
export interface IAdministrationAPWidgetData extends IWidgetData {
    arSentThisWeekCount: number;
    auditsAwaitingAuditorAllocationCount: number;
    auditsAwaitingManagerReviewCount: number;
    auditsAwaitingPartnerReviewCount: number;
    auditsInProgressWithAuditorCount: number;
    postArQueriesReadyForDispatchCount: number;
    preArQueriesWaitingToBeReviewed: IDoughnutItem[];
    postArQueriesWaitingToBeReviewed: IDoughnutItem[];
}

export interface IManagerAPWidgetData extends IWidgetData {
    reviewedToday: number;
    reviewedYesterday: number;
    reviewedThisWeek: number;
    myAuditsInProgress: number;
    myAuditsWithReviewPoints: number;
    teamAuditsByStatus: IDoughnutItem[];
    teamAuditsByAuditor: IDoughnutItem[];
}

export interface IAuditorAPWidgetData extends IWidgetData {
    auditedThisWeek: number;
    auditedToday: number;
    auditedYesterday: number;
    postArQueriesToBeReviewed: number;
    preArQueriesToBeReviewed: number;
    allocatedAudits: IDoughnutItem[];
    timesheets: IDoughnutItem[];
}

export interface IContraventionAPWidgetData extends IWidgetData {
    acrLodgedThisMonth: number;
    acrLodgedThisYear: number;
    acrReadyForLodgement: number;
}

export interface IPartnerAPWidgetData extends IWidgetData {
    activeClients: number;
    arSentThisWeek: number;
    auditsReadyForReview: number;
    reviewPointsToBeCleared: IDoughnutItem[];
}

export interface IClientPortalWidgetDate extends IWidgetData {
    auditsNotSubmitted: IDoughnutItem[];
    auditReportReleased: IDoughnutItem[];
    auditsInProgressCount: number;
    preArQueriesAwaitingResponseCount: number;
    preArQueriesWaitingForReviewCount: number;
    unsignedArReleasedCount: number;
    filterYear: number;
    preAuditIssues: number;
}

// add here new widget data interfaces via '|'
export type WidgetData =
    | IAdministrationAPWidgetData
    | IAuditorAPWidgetData
    | IContraventionAPWidgetData
    | IPartnerAPWidgetData
    | IManagerAPWidgetData
    | IClientPortalWidgetDate
    | IAdministrationWPWidgetData
    | IAccountantWPWidgetData
    | IPartnerWPWidgetData
    | IManagerWPWidgetData;

function useGetWidgetData({dashboardTab, isWorkflow, clientId, fundId, filterByYear, clientGuid, fundGuid, contactGuid}: Props) {
    const context = React.useContext(Context);

    const {
        data: widgetData,
        isLoading: isWidgetDataLoading,
        isFetching,
        refetch,
    } = useQuery(
        ['getUsersFilter', dashboardTab, clientId, fundId, filterByYear, fundGuid, clientGuid, contactGuid],
        () =>
            axios.get<WidgetData>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/widgetData`, {
                ...commonHeaders(),
                params: {
                    dashboardTab,
                    clientId,
                    fundId,
                    filterByYear,
                    isWorkflow,
                    fundGuid,
                    clientGuid,
                    contactGuid
                },
            }),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {widgetData, isWidgetDataLoading, isFetching, refetch};
}

export {useGetWidgetData};
