import React, {FunctionComponent} from "react";
import {ConclusionReportItem} from "./interfaces/ConclusionReportItem";
import {TableType} from "../../../../../../enums";
import {ConclusionReportTemplate} from "./ConclusionReportTemplate";
import {Stack} from "@fluentui/react";

type ConclusionReportsProps = {
    reports: { [key: string]: ConclusionReportItem[] }
}

export const ConclusionReportsTemplate: FunctionComponent<ConclusionReportsProps> = ({ reports}: ConclusionReportsProps) => {

    return (
        <Stack tokens={{childrenGap: 16}}>
            {Object.keys(reports).map(k => {
                const tableType = TableType[k as keyof typeof TableType];
                
                const items = reports[k] ?? [];
                
                const key = `conclusionReport_${tableType}_${Date.now()}`;
                
                if (items.length) {
                    return (
                        <Stack.Item key={key}>
                            <ConclusionReportTemplate tableType={tableType} items={items}/>
                        </Stack.Item>
                    )
                }
                
                return <Stack.Item key={key}></Stack.Item>
            })}
        </Stack>
    )
}



