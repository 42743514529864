import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import {IEventSummaryItem} from "../interfaces/IEventSummaryItem";
import axios from "axios";

function useChangeEventSummary() {
    const context = useContext(Context);
    const {
        mutate: change,
        isLoading: isUpdating
    } = useMutation((data: IEventSummaryItem) => axios.post<IEventSummaryItem>(`${context?.config?.proceduresUrl}/api/1.0/jobs/${data.jobId}/eventSummary`, data));

    return {change, isUpdating};
}

export {useChangeEventSummary};