import {useIntl} from "react-intl";
import {useCallback} from "react";
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IDashboardConfig,
    IManagerWPWidgetData,
    WidgetsEnum
} from "../../../../shared/Dashboard";

export const ManagerWorkpapersDashboard = () => {
    const {formatMessage} = useIntl();

    const getManagerDashboardConfig = useCallback(
        (props: IManagerWPWidgetData): IDashboardConfig => {
            return {
                id: 'manager',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedToday',
                            props: {
                                number: props.reviewedToday,
                                title: formatMessage({id: 'reviewedToday'}),
                                variant: 'white',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedYesterday',
                            props: {
                                number: props.reviewedYesterday,
                                title: formatMessage({id: 'reviewedYesterday'}),
                                variant: 'white',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'reviewedThisWeek',
                            props: {
                                number: props.reviewedThisWeek,
                                title: formatMessage({id: 'reviewedThisWeek'}),
                                variant: 'white',
                            },
                        },
                    ],

                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'myJobsInProgress',
                            tables: [{tableType: DashboardTableTypeEnum.Manager_MyJobsInProgress}],
                            props: {
                                counterAlign: "center",
                                variant: "tertiary",
                                number: props.myJobsInProgress,
                                title: formatMessage({id: 'myJobsInProgress'}),
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'teamWorkpapersPrepByStatus',
                            size: 2,
                            tables: [{tableType: DashboardTableTypeEnum.Manager_TeamAuditsAssignedNotStarted}],
                            props: {
                                title: formatMessage({id: 'teamWorkpapersPrepByStatus'}),
                                data: props.teamWorkpapersPrepByStatus,
                            },
                        },

                        {
                            type: WidgetsEnum.BAR_CHART_WIDGET,
                            id: 'teamWorkpapersPrepByAccountant',
                            size: 2,
                            tables: [{tableType: DashboardTableTypeEnum.Manager_TeamAuditsInProgress}],
                            props: {
                                title: formatMessage({id: 'teamWorkpapersPrepByAccountant'}),
                                data: props.teamWorkpapersPrepByAccountant,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'myJobsWithReviewPoints',
                            tables: [{tableType: DashboardTableTypeEnum.Manager_MyAuditsWithReviewPoints}],
                            props: {
                                counterAlign: "center",
                                variant: "tertiary",
                                number: props.myJobsWithReviewPoints,
                                title: formatMessage({id: 'myJobsWithReviewPoints'}),
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '9',
                            props: {
                                tableType: DashboardTableTypeEnum.Manager_JobsReadyForReview,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '10',
                            props: {
                                tableType: DashboardTableTypeEnum.Manager_ReviewPointsToBeCleared,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return (
        <DashboardContainer dashboardTab={DashboardTabsEnum.WP_Manager}
                            getDashboardConfig={getManagerDashboardConfig as GetDashboardConfig}/>
    );
}