export enum FeeCalculationItemType {
    SimpleYesNo = "SimpleYesNo",
    YesNoWithHowMany = "YesNoWithHowMany",
    Risk1LinkedItem = "Risk1LinkedItem",
    InvestmentTabLinkedItem = "InvestmentTabLinkedItem",
    SimpleHowManyDropdown = "SimpleHowManyDropdown",
    SimpleHowManyField = "SimpleHowManyField",
    Label = "Label",
    LabeWithTextField = "LabeWithTextField",
    TotalValueOfAssets = "TotalValueOfAssets",
    NumberOfAssets = "NumberOfAssets",
    ContraventionLinked = "ContraventionLinked",
    ManagerReviewCheckbox = "ManagerReviewCheckbox",
    ASICSearch = "ASICSearch",
    TitleSearchNSW = "TitleSearchNSW",
    TItleSearchOtherStates = "TItleSearchOtherStates",
    ARWithheldUntilPaymentReceived = "ARWithheldUntilPaymentReceived",
    ClassApiCharge = "ClassApiCharge",
}

export enum FeeCalculationAnswer{
    Yes = 1,
    No = 2,
}

export enum FeematrixType {
    SingleTableWithCheckboxApprovment = "SingleTableWithCheckboxApprovment",
    NonAutoAdditionalChargesWithButtons = "NonAutoAdditionalChargesWithButtons",
    AutoAdditionalCharges = "AutoAdditionalCharges",
}

export type FeeCalculation = {
    id: number;
    itemType: FeeCalculationItemType;
    title: string;
    order: number;
    minQuantity: number | null;
    maxQuantity: number | null;
    feeText: string;
    feeEditEnabled: boolean;
    additionalCharge: boolean;
    commonDataId: number | null;
    version: number;
    feeMatrixType: FeematrixType;
    
    showCommentsIcon: boolean;
    hasComments: boolean;
    
    shoOmlsIcon: boolean;
    hasOmls: boolean;
}

export type FeeCalculationLineItem = {
    id: number | null;
    existsForJob: boolean;
    answer: FeeCalculationAnswer | null;
    howManyQuantity: number | null;
    feeAmount: number | null;
    manualFee: boolean;
    manualFeeAmount: number | null;
    jobId: number;
    resultFeeAmount: number | null;
    feeCalculationId: number;
    feeCalculationModel: FeeCalculation;
}

export enum FeeCalculationNonStandartFeeStructure {
    No = 100000000,
    Yes = 100000001,
    TwoTier = 100000004,
    ThreeTier = 100000002,
    ThreeTierA = 100000005,
    FourTier = 100000003,
    FourTierA = 100000006,
    ThreeTierNew = 100000007,
}

export type FeeStructureModel = {
    nonStandardFeeStructure: FeeCalculationNonStandartFeeStructure;
    cash: number | null;
    wrapPlatform: number | null;
    onMarketBase: number | null;
    otherFunds: number | null;
    bGLFee: number | null;
    property: number | null;
    installmentWarrant: number | null;
    relatedUT: number | null;
    contraventionReport: number | null;
    amendedFS: number | null;
    classApiCharge: number | null;
    aSICSearch: number | null;
    titleSearchNSV: number | null;
    tItleSearchOtherStates: number | null;
    paymentTiming: number | null;
    chargeForSegregatedAssets: string | null;
}

export type FeeCalculationSection = {
    feeCalculationLineItems: FeeCalculationLineItem[];
    feeStructure: FeeStructureModel;
    isApproved: boolean;
    lastEditedDateTicks: number | null;
    lastCalculatedDateTicks: number | null;
};
