import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {IAuditQueriesInfo} from "./useGetDispatchAuditInfo";

export interface IJobQueryRating {
    rating?: number;
}

function useGetQueryRating(jobId: number) {
    const context = useContext(Context);

    const {
        data: data,
        isLoading,
        refetch, isRefetching
    } = useQuery(['getQueryRating', jobId], () => axios.get<IJobQueryRating>(`${context?.config?.queriesUrl}/api/1.0/queries/getQueryRating/${jobId}`));

    return {jobQueryRating: data?.data || { rating: undefined } as IJobQueryRating, isLoading, refetch, isRefetching};
}

export {useGetQueryRating};