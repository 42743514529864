import {Card, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack, TextField} from '@fluentui/react';
import {Control} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import {ClientContactInfo} from "pages/WorkflowPortal/shared";
import {IChangeClientInfoCommand} from "../../../../Clients";

type AccountTabType = {
    control: Control<IChangeClientInfoCommand>;
    contacts?: ClientContactInfo[];
    selectedContactGuid?: string;
};

export const TrusteeInfoTab = ({control, contacts, selectedContactGuid}: AccountTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const [selectedContact, setSelectedContact] = useState<ClientContactInfo | null>(contacts?.find(x => x.guid == selectedContactGuid) || null);

    const options = useMemo(() => {
        return contacts?.map((contact) => {
            return {
                text: `${contact.firstName} ${contact.lastName}`,
                key: contact.guid,
            };
        }) || [];
    }, [contacts]);

    useEffect(() => {
        setSelectedContact(contacts?.find(x => x.guid === selectedContactGuid) || null);
        console.log(selectedContact);
    }, [selectedContactGuid])

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='clientName'
                        label={formatMessage({id: 'trusteeName'})}
                        required
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown options={[
                        {key: "Corporate", text: 'Corporate'},
                        {key: 'Individual', text: 'Individual'}
                    ]}
                                        placeholder={formatMessage({id: 'selectTrusteeType'})}
                                        name='type'
                                        required
                                        rules={{
                                            required: formatMessage({id: 'requiredField'}),
                                            minLength: {
                                                value: 3,
                                                message: formatMessage({id: 'minLength'}, {min: 3})
                                            },
                                        }}
                                        label={formatMessage({id: 'trusteeType'})}
                                        control={control}/>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='primaryContact'
                        required
                        label={formatMessage({id: 'accountant'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                        options={options}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <TextField
                        label={formatMessage({id: 'trusteePhone'})}
                        disabled
                        value={selectedContact?.directPhone || ''}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
