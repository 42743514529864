import { ICurrentUserInfo, useGetCurrentUserInfo } from '../hooks';
import { createContext, PropsWithChildren, useContext } from 'react';
import { stores } from '../stores';

interface IUserContext {
    isAuthorized: boolean;
    user?: ICurrentUserInfo;
}

const AuthContext = createContext<IUserContext | undefined>(undefined);

export const useAuthContext = () => {
    let context = useContext(AuthContext);

    if (!context) throw new Error('No AuthContext provided');

    return context;
};

export const AuthProvider = (props: PropsWithChildren<{}>) => {
    const { user } = useGetCurrentUserInfo();

    return (
        <AuthContext.Provider
            value={{
                isAuthorized: stores.AuthStore.isAuthorised,
                user: user,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
};
