import {useContext} from "react";
import {Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {CalculatorType} from "../CalculatorType";
import {ICalculatorAnswerData} from "../interfaces";

type MutateDataType = {
    jobId: number;
    isCustom: boolean;
    answer?: ICalculatorAnswerData;
    answers?: ICalculatorAnswerData[];
    saveComments: boolean;
    calculatorType: CalculatorType;
}

function useUpdateCalculatorData() {
    const context = useContext(Context);
    const host = context?.config?.calculationUrl;
    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${host}/api/1.0/jobs/${data.jobId}/calculators/${data.calculatorType}`, data),
    {
        onSuccess: () => { queryClient.invalidateQueries('getCalculatorData'); }
    });

    return {update, isUpdating};
}

export {useUpdateCalculatorData};