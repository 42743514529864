import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
}

function useDeleteReviewItem() {
    const context = useContext(Context);

    const {
        mutate: deleteItem,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.proceduresUrl}/api/1.0/procedures/jobReview/${data.id}`));

    return {deleteItem, isDeleting};
}

export {useDeleteReviewItem};