import { map, Observable, timer } from 'rxjs';
import {
    IClientInfo,
    ICreateUserCommand,
    IOrderedRequest,
    IPaginatedResult,
    IPaginationRequest,
    IUpdateUserCommand,
    IUserInfo,
    IUserManagementFilters,
} from 'interfaces';
import { MockedUsers } from '../constants/MockedUsers';
import { BaseService } from './BaseService';
import { ConfigurationService, configurationService } from './ConfigurationService';

class UserService extends BaseService {
    constructor(configurationService: ConfigurationService) {
        super('userUrl', configurationService);
    }

    public filterUsers(filters: IUserManagementFilters & IPaginationRequest & IOrderedRequest): Observable<IPaginatedResult<IUserInfo>> {
        return timer(500).pipe(
            map(() => ({
                totalItemsCount: 100,
                shownItemsCount: filters.pageSize ?? 20,
                items: MockedUsers,
            }))
        );
        // return this.httpClient
        //     .get<IPaginatedResult<IUserInfo>>('/api/User/filter', { params: filters })
        //     .pipe(map((response) => response.data));
    }

    public getUser(id: IUserInfo['id']): Observable<IUserInfo> {
        return timer(500).pipe(map(() => MockedUsers.find((u) => u.id === id)!));
        // return this.httpClient.get<IUserInfo>(`/api/User/${id}`).pipe(map((response) => response.data));
    }

    public getUserByName(userName: IUserInfo['username']): Observable<IUserInfo> {
        return timer(500).pipe(map(() => MockedUsers.find((u) => u.username === userName)!));
        // return this.httpClient.get<IUserInfo>(`/api/User/getByName`, { params: { userName } }).pipe(map((response) => response.data));
    }

    public getUserByEmail(email: IUserInfo['email']): Observable<IUserInfo> {
        return timer(500).pipe(map(() => MockedUsers.find((u) => u.email === email)!));
        // return this.httpClient.get<IUserInfo>(`/api/User/getByEmail`, { params: { email } }).pipe(map((response) => response.data));
    }

    public createUser(userCreateInfo: ICreateUserCommand): Observable<IUserInfo> {
        return timer(500).pipe(map(() => MockedUsers[0]));
        // return this.httpClient.post<IUserInfo>(`/api/User`, userUpdateInfo).pipe(map((response) => response.data));
    }

    public updateUser(userUpdateInfo: IUpdateUserCommand): Observable<any> {
        return timer(500).pipe(map(() => null));
        // return this.httpClient.put<any>(`/api/User`, userUpdateInfo).pipe(map((response) => response.data));
    }

    public deleteUser(id: IUserInfo['id']): Observable<any> {
        return timer(500).pipe(map(() => null));
        // return this.httpClient.delete(`/api/User/${id}`).pipe(map((response) => response.data));
    }

    public assignClientsToUser(userId: IUserInfo['id'], clients: IClientInfo[]): Observable<any> {
        return timer(500).pipe(map(() => null));
        // return this.httpClient.put<any>(`/api/User/${userId}/assignClients`, clients).pipe(map((response) => response.data));
    }

    public restoreUser(userId: IUserInfo['id']): Observable<any> {
        return timer(500).pipe(map(() => null));
        // return this.httpClient.put<any>(`/api/User/${userId}/restore`).pipe(map((response) => response.data));
    }
}

export const userService = new UserService(configurationService);
