import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { JobYear } from './useGeneralYearGet';

type CreateGeneralYearReq = {
    jobYear: JobYear;
};

/** Mutation query for creating Years for Portal Settings*/
function useCreateGeneralYear() {
    const context = React.useContext(Context);

    const { mutate: createGeneralYear, isLoading: createGeneralYearLoading } = useMutation(
        (data: CreateGeneralYearReq) => axios.post(`${context?.config?.settingsUrl}/api/1.0/Settings/jobYears`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getGeneralYear'),
        }
    );

    return { createGeneralYear, createGeneralYearLoading };
}

export { useCreateGeneralYear };
