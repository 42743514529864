import {FunctionComponent, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {SignInLoader} from './SignInLoader';

interface Props {
    getToken: (code: string, userId: string) => void;
    signIn: () => void;
    isSigningIn: boolean;
    isSigningOut: boolean;
}

export const SignIn: FunctionComponent<Props> = ({ getToken, signIn, isSigningIn, isSigningOut }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has('Code') && searchParams.has('userId')) {
            let code = searchParams.get('Code')!;
            let userId = searchParams.get('userId')!;

            getToken(code, userId);

            searchParams.delete('Code');
            searchParams.delete('userId');
            setSearchParams(searchParams);
        } else if (!isSigningIn && !isSigningOut) {
            signIn();
        }
    }, []);

    return <SignInLoader />;
};
