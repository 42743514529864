import React from "react";
import {commonHeaders, Context, queryClient} from "../../../../utils";
import {NotificationAction, useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";
import {useWorkflows} from "../../WorkflowLayoutPage";

type MutateDataType = {
    name: string;
}

function useCreateNewTeam() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {showTeamModal} = useWorkflows();
    const {formatMessage} = useIntl();

    const {mutate: update, isLoading: isUpdating} = useMutation(
            (data: MutateDataType) =>
                axios.post(`${context?.config?.userUrl}/api/1.0/teams`, data, commonHeaders()),
            {
                onSuccess: (data, variables, context) => {
                    queryClient.invalidateQueries('getAllTeams');
                    showNotification({
                        type: MessageBarType.success,
                        name: formatMessage({id: 'created'}),
                        description: formatMessage({id: "entityWasCreated"}, {name: 'Team'}),
                        actions:
                            (
                                <NotificationAction onClick={() => {
                                    console.debug('Create team id: ' + data.data.id);
                                    showTeamModal(data.data.id);
                                }}>
                                    {
                                        formatMessage({id: 'view'})
                                    }
                                </NotificationAction>
                            )
                    });
                },
                onError: (error: AxiosError) => {
                    showNotification({
                        type: MessageBarType.error,
                        name: 'Error',
                        description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                    })
                }
            }
        )
    ;

    return {update, isUpdating};
}

export {useCreateNewTeam};