import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";

export interface IWizardQuestion {
    answerId: number;
    questionId: number;
    order: number;
    text?: string;
    description?: string;
    successText?: string;
    failText?: string;
    type: ClientAuditQuestionType;
    answer?: ClientAuditQuestionAnswerValue;
}

export enum ClientAuditQuestionAnswerValue
{
    Unknown = 0,
    Yes = 1,
    No = 2
}

export enum ClientAuditQuestionType {
    FirstAnswer = 0,
    YesJob = 1,
    NoJob = 2
}

function useGetWizardQuestions(jobId: number) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getWizardQuestions', jobId], () => axios.get<IWizardQuestion[]>(`${context?.config?.answersUrl}/api/1.0/jobs/${jobId}/clientAuditQuestions`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.answersUrl && !!jobId
    });

    return {response, isLoading, isFetching};
}

export {useGetWizardQuestions};