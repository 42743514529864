import {FunctionComponent} from "react";
import {DataTable, IDataTableProps} from "../../../../../../components";

interface ICalculatorDataTableProps extends IDataTableProps {
}

export const CalculatorDataTable: FunctionComponent<ICalculatorDataTableProps> = ({
                                                                                      ...props
                                                                                  }: ICalculatorDataTableProps) => {

    return (
        <>
            <DataTable 
                       disableDragDrop={true}
                       {...props}
            />
        </>
    )
}