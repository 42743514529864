import {IButtonStyles, IIconProps, MessageBarType, Stack, TooltipHost} from "@fluentui/react";
import {IconButton} from "@fluentui/react/lib/Button";
import React, { useState } from "react";
import {useId} from "@fluentui/react-hooks";
import {useIntl} from "react-intl";
import {NotificationType, useSignalR} from "../../../../providers";
import {NotificationAction, useNotifications} from "../../../../components/notifications";
import {useGetFile} from "../../../../hooks";
import {logger} from "../../../../services";
import {not} from "rxjs/internal/util/not";

export interface IExportProps {
    styles?: IButtonStyles;
    onPdfClick?: Function;
    onExcelClick?: Function;
}

export const ExportActions = (props: IExportProps) => {
    const {formatMessage} = useIntl();
    const {useSignalREffect} = useSignalR();
    const {getFile} = useGetFile();
    const {showNotification} = useNotifications();

    const excelDoc: IIconProps = {iconName: 'ExcelDocument'};
    const pdf: IIconProps = {iconName: 'PDF'};
    const excelTooltipId = useId('tooltip');
    const pdfToolTipId = useId('tooltip');

    const [isDownloadAwaited, setIsDownloadAwaited] = useState<boolean>(false);

    useSignalREffect(
        'export',
        (notification: NotificationType) => {
            const download = (notification: NotificationType) => {
                logger.log(`Downloading file: ${notification.content.Name}`);
                console.log(notification);
                if (notification.content?.DownloadUrl) {
                    const file = notification.content;
                    getFile({fileName: file.Name, url: file.DownloadUrl});
                }
            };
            showNotification({
                name: 'Export Info',
                description: notification.message,
                type: MessageBarType.success,
                actions: <NotificationAction
                    onClick={() => download(notification)}>{formatMessage({id: 'download'})}</NotificationAction>
            });

            if (isDownloadAwaited) {
                download(notification);
                setIsDownloadAwaited(false)
            }
        },
        [isDownloadAwaited]
    );

    useSignalREffect(
        'fileUploaded',
        (notification: NotificationType) => {


            showNotification({
                name: 'File Uploaded',
                description: notification.message,
                type: MessageBarType.success,
            })
        }, []);

    return (
        <>
            <Stack horizontal styles={props.styles}>
                <TooltipHost content={formatMessage({ id: 'exportExcel' })} id={excelTooltipId}>
                    <IconButton
                        aria-describedby={excelTooltipId}
                        iconProps={excelDoc}
                        onClick={() => {
                            if (props.onExcelClick) {
                                props.onExcelClick();
                                setIsDownloadAwaited(true);
                            }
                        }}
                        aria-label='Emoji'
                    />
                </TooltipHost>
                <TooltipHost content={formatMessage({ id: 'exportPdf' })} id={pdfToolTipId}>
                    <IconButton
                        aria-describedby={pdfToolTipId}
                        iconProps={pdf}
                        onClick={() => {
                            if (props.onPdfClick) {
                                props.onPdfClick();
                                setIsDownloadAwaited(true);
                            }
                        }}
                        aria-label='Emoji'
                    />
                </TooltipHost>
            </Stack>
        </>
    );
}