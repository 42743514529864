import { FunctionComponent, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    IconButton,
    MessageBarType,
    PrimaryButton,
    Spinner,
    Stack,
    StackItem,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Card, DataTable, Modal } from 'components';
import { IYear } from '../hooks/useGetWrapAccounts';
import { useGetWrapAccountsByYear } from '../hooks/useGetWrapAccountsByYear';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { cloneDeep } from 'lodash';
import { useGetFile } from 'hooks/useGetFile';
import React from 'react';
import { useRemoveWrapAccount } from '../hooks/useRemoveWrapAccount';
import { useNotifications } from 'components/notifications';

interface Props {
    item: IYear;
}

interface IAttachmentDownloadProps {
    downloadUrl: string;
    fileName: string;
}

interface IAttachmentsProps {
    itemId: number;
    downloadUrl: string;
    fileName: string;
}

export const WrapAccountAttachments: FunctionComponent<Props> = ({ item }) => {
    const { formatMessage } = useIntl();
    const [show, { toggle: toggleShow }] = useBoolean(false);
    return (
        <>
            <IconButton iconProps={{ iconName: 'RedEye' }} onClick={toggleShow} />
            <Modal title={String(item.year)} isOpen={show} onDismiss={toggleShow} children={<WrapAccountAttachmentsModal item={item} />} />
        </>
    );
};

const AttachmentDownload: FunctionComponent<IAttachmentDownloadProps> = ({ downloadUrl, fileName }) => {
    const { getFile } = useGetFile();
    const tooltipId = useId('tooltip');
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    return (
        <StackItem align='center'>
            <TooltipHost content='Download' id={tooltipId}>
                <IconButton
                    iconProps={{ iconName: 'Download' }}
                    onClick={() => {
                        getFile({
                            url: downloadUrl,
                            fileName: fileName,
                        });
                        showNotification({
                            name: formatMessage({ id: 'downloadStarted' }),
                            type: MessageBarType.info,
                            description: formatMessage({ id: 'downloadStarted' }),
                        });
                    }}
                />
            </TooltipHost>
        </StackItem>
    );
};

const AttachmentDelete: FunctionComponent<IAttachmentsProps> = ({ itemId }) => {
    const tooltipId = useId('tooltip');
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { deleteAttachment } = useRemoveWrapAccount();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId]
    );
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Deleting file',
        closeButtonAriaLabel: 'Close',
        subText: 'Do you want to delete this file?',
        theme: theme.schemes?.default,
    };
    return (
        <StackItem align='center'>
            <TooltipHost content={formatMessage({ id: 'delete' })} id={tooltipId}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                    onClick={toggleHideDialog}
                />
            </TooltipHost>
            <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            deleteAttachment(itemId);
                            toggleHideDialog();
                        }}
                        text='Yes'
                    />
                    <DefaultButton onClick={toggleHideDialog} text='No' />
                </DialogFooter>
            </Dialog>
        </StackItem>
    );
};

const AttachmentActions: FunctionComponent<IAttachmentsProps> = ({ itemId, fileName, downloadUrl }) => {
    return (
        <Stack horizontal grow verticalAlign='center' horizontalAlign='space-between'>
            <StackItem styles={{ root: { width: '75%' } }}>{fileName} </StackItem>
            <Stack horizontal horizontalAlign='space-between'>
                <AttachmentDownload downloadUrl={downloadUrl} fileName={fileName} />
                <AttachmentDelete downloadUrl={downloadUrl} fileName={fileName} itemId={itemId} />
            </Stack>
        </Stack>
    );
};

export const WrapAccountAttachmentsModal: FunctionComponent<Props> = ({ item }) => {
    const { wrapAccountsData, isLoading } = useGetWrapAccountsByYear({ year: item.year });
    const { formatMessage } = useIntl();
    const [libraryColumns] = useState<IColumn[]>([
        {
            key: 'fileName',
            name: formatMessage({ id: 'fileName' }),
            minWidth: 200,
            fieldName: 'fileName',
            onRender: (item) => <AttachmentActions itemId={item.id} fileName={item.fileName} downloadUrl={item.downloadUrl} />,
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={libraryColumns}
                items={cloneDeep(wrapAccountsData?.data || [])}
                enableShimmer={isLoading}
                containerHeight='calc(100% - 32px)'
                disableDragDrop={true}
            />
        </Card>
    );
};
