import {IDropdownOption, MessageBarType, PrimaryButton, Spinner, Stack} from "@fluentui/react";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {useIntl} from "react-intl";
import {useCallback} from "react";
import {IPrintToPdfOption} from "./interfaces";
import {Card} from "components";
import {useTenantInfo} from "providers";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {SoftwareType} from "enums";
import {Path, useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {useUpdateItemAnswer} from "../../../../hooks/answers";
import {ColumnInfo} from "../../../../enums";
import {useScheduleExport} from "./hooks";
import { useNotifications } from "components/notifications";

const getSoftwareType = (reference: string): SoftwareType[] => {
    const regex = /(?<=\()[^()]+(?=\))/g;

    const searchResult = regex.exec(reference);

    if (!searchResult) {
        return [];
    }
    const softwareTypeNames = Object.values(SoftwareType);
    return softwareTypeNames.filter(x => searchResult[0].split(' or ').some(s => x.toLowerCase().includes(s.toLowerCase())));
}


type PrintToPdfOptionsProps = {
    items: IPrintToPdfOption[] | null;
    title: string;
}
export const PrintToPdfGeneralOptions = ({items, title}: PrintToPdfOptionsProps) => {
    const {isBlackLabel} = useTenantInfo();
    const {job, jobId} = useJobContext();
    const {formatMessage} = useIntl();
    const {update, isUpdating} = useUpdateItemAnswer();
    const {create, isCreating} = useScheduleExport();
    const { showNotification } = useNotifications();
    const {control, handleSubmit, watch, setValue} = useForm<{ pdfOptions: IPrintToPdfOption[] }>({
        ...DefaultFormSettings,
        defaultValues: {pdfOptions: items || []}
    });
    const {} = useFieldArray({
        control: control,
        name: 'pdfOptions'
    });

    const watchFields = watch('pdfOptions');
    const brandingOptions: IDropdownOption[] = [
        {key: 'Internal Use', text: formatMessage({id: 'printPdf_label_Internal_Use'})},
        {key: 'Outsource Client', text: formatMessage({id: 'printPdf_label_Outsource_Client'})}
    ];

    const disabledItem = useCallback((item: IPrintToPdfOption) => {

        const userType = watchFields.find(x => x.reference === 'User Type')?.answerText || '';
        console.log("isBlackLabel:", isBlackLabel);
        if (isBlackLabel && item.reference === 'Evolv branded' && userType === 'Internal Use') {
            return true;
        }
        if (item.reference === 'Include ACL / Audit Report' && userType !== 'Internal Use') {
            return true;
        }

        const softwareTypeSpecific = getSoftwareType(item.reference);

        return !!(softwareTypeSpecific.length && softwareTypeSpecific.every(x => x !== job.softwareType));


    }, [watchFields, isBlackLabel, job.softwareType]);

    const getItemAnswer = useCallback((item: IPrintToPdfOption) => {

        const userType = watchFields.find(x => x.reference === 'User Type')?.answerText || '';
        if (isBlackLabel && item.reference === 'Evolv branded' && userType === 'Internal Use') {
            return true;
        }

        return item.permanentChecked;
    }, [watchFields, isBlackLabel]);
    
    const handleOnChangeAnswer = (item: IPrintToPdfOption, answerTextFieldName: Path<{ pdfOptions: IPrintToPdfOption[] }>,
            permanentCheckedFieldName: Path<{ pdfOptions: IPrintToPdfOption[] }>, value: string | boolean) => {
        setValue(permanentCheckedFieldName, !item.permanentChecked);
        setValue(answerTextFieldName, value);
        const isUserType = item.reference === 'User Type';
        update({
            jobId: jobId,
            columnInfo: isUserType ? ColumnInfo.Text : ColumnInfo.Checkbox,
            text: value.toString(),
            itemId: item.id,
            tableType: item.tableType
        });
    };

    const handleOnSchedule = (data: { pdfOptions: IPrintToPdfOption[] }) => {
        if(!job.manager.reviewed)
        {
            showNotification({
                name: formatMessage({ id: 'needCompleteReview' }),
                type: MessageBarType.blocked,
                description: formatMessage({ id: 'needCompleteReview' }),
            });
            return;
        }
        create({
            jobId: jobId,
            options: {
                isAclAuditContraventionIncluded: data.pdfOptions.find(x => x.reference === 'Include ACL / Audit Report')?.permanentChecked || false,
                isBalanceReconciliationReportIncluded: data.pdfOptions.find(x => x.reference === 'Include Cash Balance Reconciliation (Class)')?.permanentChecked || false,
                isBankAccountMovementReportIncluded: data.pdfOptions.find(x => x.reference === 'Include Bank Account Movement Report (CLASS)')?.permanentChecked || false,
                isBankStatementIncluded: data.pdfOptions.find(x => x.reference === 'Include Bank Statement (BGL360)')?.permanentChecked || false,
                isClientPortalDocsIncluded: data.pdfOptions.find(x => x.reference === 'Include Client Portal documents')?.permanentChecked || false,
                isCurrentYearDocsIncluded: data.pdfOptions.find(x => x.reference === 'Include current year documents')?.permanentChecked || false,
                isEngagementRepresentationLettersIncluded: false,
                isEvolvBranded: data.pdfOptions.find(x => x.reference === 'Evolv branded')?.permanentChecked || false,
                isGeneralLedgerAuditTrailIncluded: data.pdfOptions.find(x => x.reference === 'Include GL Audit Trail Report (Class)')?.permanentChecked || false,
                isGeneralLedgerIncluded: data.pdfOptions.find(x => x.reference === 'Include General Ledger')?.permanentChecked || false,
                isInternalUse: data.pdfOptions.find(x => x.reference === 'User Type')?.answerText === 'Internal Use' || false,
                isPermanentDocsIncluded: data.pdfOptions.find(x => x.reference === 'Include permanent documents')?.permanentChecked || false,
                isTrialBalanceIncluded: data.pdfOptions.find(x => x.reference === 'Include Trial Balance (Desktop or BGL360)')?.permanentChecked || false,
                id: 0,
                scheduledExportId: 0
            },
            sentEmail: false
        });
    }
    return (
        <Card title={title}>
            <Stack tokens={{childrenGap: 16}}>
                {items?.map((answer: IPrintToPdfOption, idx) => <GeneratedAnswer
                    key={`print-to-pdf-answer-${idx}-${title}`}
                    disabled={disabledItem(answer) || isUpdating}
                    value={answer.answerText || getItemAnswer(answer)}
                    options={brandingOptions}
                    answerControlType={answer.answerControlType}
                    onChange={newValue => handleOnChangeAnswer(answer, `pdfOptions.${idx}.answerText`, `pdfOptions.${idx}.permanentChecked`, newValue)}
                    label={answer?.title}/>)}
                {!isCreating && (
                    <PrimaryButton onClick={handleSubmit(handleOnSchedule)} disabled={isUpdating || isCreating}
                                   text={formatMessage({id: 'createPDFOutput'})}/>)}
                {isCreating && (<Spinner/>)}
            </Stack>
        </Card>
    )
}