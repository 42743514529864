import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {DetailedSoftwareType, SoftwareType} from "enums";

import {ArAclGenerationType, AuditReportDateType, ClientRiskRating} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";

type MutateDataType = {
    softwareType: SoftwareType | null;
    detailedSoftwareType?: DetailedSoftwareType | null;
    turnAroundTime: number;
    riskRatingType: ClientRiskRating | null;
    arAclGenerationType: ArAclGenerationType | null;
    auditReportDateType: AuditReportDateType | null;
    isDraftAuditReport: boolean;
    titleSearchConduct: boolean;
    asicSearchConduct: boolean;
    clientId: number;
};

function useUpdateClientsAuditInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    const {mutate: updateClientAuditInfo, isLoading: isUpdatingClientAuditInfo} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/auditInfo`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getClientsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Client Audit information'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateClientAuditInfo, isUpdatingClientAuditInfo};
}

export {useUpdateClientsAuditInfo};
