import { Stack } from '@fluentui/react';
import { ReportsDataTable } from './components';

export const ReportsWorkflowPage = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <ReportsDataTable />
            </Stack.Item>
        </Stack>
    );
};