import { MessageBarType } from '@fluentui/react';
import axios from 'axios';
import { AxiosError } from 'axios';
import { useCommonErrorNotification, useNotifications } from 'components/notifications';
import {useMutation} from 'react-query';

type FileUrl = {
    url: string;
    fileName: string;
};

/**Mutation query for downloading file*/

function useGetFile() {
    const {showCommonError} = useCommonErrorNotification();
    const {mutate: getFile, isLoading: isExportDownloading} = useMutation(
        (data: FileUrl) => axios.get(`${data.url}`, {
            responseType: 'blob'
        }),
        {
            onSuccess: ({data}, {fileName}) => {
                let FileSaver = require('file-saver');
                let blob = new Blob([data], {type: 'application/octet-stream'});
                FileSaver.saveAs(blob, fileName);
            },
            onError: (error: AxiosError) => {
                showCommonError();
            }
        }
    );

    return {getFile, isExportDownloading};
}

export {useGetFile};
