import {FunctionComponent} from 'react';
import {PrimaryButton, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useCreateImport} from '../hooks';

interface IUncalledFormProps {
  jobId: number;
  disabledButtons: boolean;
  onImport?: Function;
}

export const UncalledForm: FunctionComponent<IUncalledFormProps> = ({ jobId, disabledButtons, onImport }) => {
  const { formatMessage } = useIntl();
  const { createImport } = useCreateImport();
  const disabled = disabledButtons;
  
  const onClick = () => {
    onImport?.();
    createImport({jobId})
  }
  
  return (
    <Stack>
      <Stack.Item>
        <PrimaryButton onClick={onClick} text={formatMessage({ id: 'upload' })} disabled={disabled} />
      </Stack.Item>
    </Stack>
  );
};