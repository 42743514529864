import { IJobSuggestedTestingItem } from '../../../../hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { mergeStyleSets, Stack, StackItem, TextField, useTheme } from '@fluentui/react';
import { calculateSuggestedTestingLabelColor } from './helpers';
import { Control } from 'react-hook-form';
import { ControlledTextField } from 'components';
import { FullWidth } from 'constants/styles';
import { useIntl } from 'react-intl';

export type SuggestedTestingEditableCellMode = 'edit' | 'view';

type SuggestedTestingNumericCellProps = {
    item: IJobSuggestedTestingItem;
    value: number | null;
    isValueChanged?: boolean;
    mode: SuggestedTestingEditableCellMode;
    onClick: () => void;
    isEditable?: boolean;
    usedOnReduceTesting?: boolean;
    useReducedTesting?: boolean;
    suffix?: string;
    fractionDigits?: number;
    showReviewRequired?: boolean;
    disabled: boolean;
    control: Control<{ suggestedTestingItems: IJobSuggestedTestingItem[] }>;
    fieldName: 'value' | 'nonDataFedSampleSize' | 'dataFedSampleSize';
    index?: number;
};

export const SuggestedTestingNumericCell: FunctionComponent<SuggestedTestingNumericCellProps> = ({
    item,
    mode,
    value,
    onClick,
    control,
    fieldName,
    index,
    suffix,
    fractionDigits = 2,
    isEditable = true,
    disabled,
    isValueChanged,
    showReviewRequired,
    usedOnReduceTesting,
    useReducedTesting = false
}) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const labelColor =
        disabled || (usedOnReduceTesting !== undefined && ((useReducedTesting && !usedOnReduceTesting) ||
        (!useReducedTesting && usedOnReduceTesting))) 
            ? 'rgba(120,120,120,0.6)'
            : isValueChanged
            ? theme.schemes?.default?.palette.blue ?? theme.palette.blue
            : calculateSuggestedTestingLabelColor(item, theme.schemes?.default?.palette ?? theme.palette);
    const cellStyle = mergeStyleSets({
        cell: {
            minHeight: '100%',
            minWidth: '100%',
            cursor: isEditable && !disabled ? 'pointer' : 'default',
        },
        label: {
            color: labelColor,
            textAlign: 'center',
        },
    });

    const formatNumberValue = useCallback(
        (value: number): string => {
            return (
                (+value).toLocaleString('au-US', {
                    minimumFractionDigits: fractionDigits,
                    maximumFractionDigits: fractionDigits,
                }) + (suffix ? ` ${suffix}` : '')
            );
        },
        [fractionDigits, suffix]
    );

    return (
        <Stack verticalAlign={'center'} grow tokens={{ childrenGap: 16 }} className={cellStyle.cell}>
            {(mode === 'view' || !isEditable) && (
                <StackItem align={'center'}>
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                zIndex: 100,
                            }}
                            onClick={(event) => {
                                onClick();
                                event.preventDefault();
                            }}></div>
                        <TextField
                            styles={FullWidth}
                            className={cellStyle.label}
                            style={{ color: labelColor }}
                            disabled
                            value={showReviewRequired ? formatMessage({ id: 'reviewRequired' }) : !!value ? formatNumberValue(value) : ''}
                        />
                    </>
                </StackItem>
            )}

            {mode === 'edit' && isEditable && index !== undefined && (
                <ControlledTextField
                    id={`SuggestedTesting-${item.id}-TextField`}
                    autoFocus
                    control={control}
                    name={`suggestedTestingItems.${index}.${fieldName}`}
                />
            )}
        </Stack>
    );
};
