import {IAppTenantIdResponse, InstanceType} from "../interfaces";
import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useConfiguration} from "../hooks";

export interface ITenantContext {
    tenant: IAppTenantIdResponse | null,
    type: InstanceType | null;
    isBlackLabel: boolean;
    isWorkpapers: boolean;
    isAuditPortal: boolean;
}

const TenantContext = createContext<ITenantContext>({
    isBlackLabel: false,
    type: null,
    isWorkpapers: false,
    isAuditPortal: false,
    tenant: null
});

export const useTenantInfo = () => {
    return useContext(TenantContext);
}

export const TenantProvider = (props: PropsWithChildren<{}>) => {

    const {tenant} = useConfiguration();

    const [currentTenant, setCurrentTenant] = useState<IAppTenantIdResponse>();

    useEffect(() => {
        if (tenant?.data) {
            const value = {
                ...tenant?.data,
                // IsWhiteLabel: false
            }
            
            setCurrentTenant(value);
        }
            
    }, [tenant?.data, setCurrentTenant]);

    return (
        <TenantContext.Provider
            value={{
                tenant: currentTenant!,
                type: currentTenant?.TypeDescription || null,
                isBlackLabel: !currentTenant?.IsWhiteLabel,
                isWorkpapers: currentTenant?.TypeDescription === InstanceType.AuditReadyPortal,
                isAuditPortal: currentTenant?.TypeDescription === InstanceType.AuditPortal
            }}>
            {props.children}
        </TenantContext.Provider>
    )

}
