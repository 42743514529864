import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {EmployeeInfo} from "pages/WorkflowPortal/shared";


function useGetEmployeeById(id?: number | null) {
    const context = React.useContext(Context);

    const {data: currentEmployeesData, isFetching: isCurrentEmployeesLoading} = useQuery(
        ['getEmployeeById', id],
        () => axios.get<EmployeeInfo>(`${context?.config?.userUrl}/api/1.0/Employee/${id || ''}`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!id,
          
        }
    );

    return {currentEmployeesData, isCurrentEmployeesLoading};
}

export {useGetEmployeeById};
