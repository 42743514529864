import { Card, ControlledNumberField } from 'components';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Control } from 'react-hook-form';
import { IChangeEmployeeCommand } from '..';

type AccountTabType = {
    control: Control<IChangeEmployeeCommand>;
};

export const BillingInformationTab = ({ control }: AccountTabType) => {
    const { formatMessage } = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                <Stack.Item styles={{ root: { width: '100%' } }}>
                    <ControlledNumberField name='rate' label={formatMessage({ id: 'rate' })} control={control} />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
