import {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {PageWithBreadcrumb} from "../../components";
import {RouterPaths} from "../../navigation";
import {Outlet} from "react-router-dom";
import * as React from "react";

export const OtherClientsLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'otherClients',
                key: RouterPaths.invoices.otherClients,
                text: formatMessage({ id: 'otherClients' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};