import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useCallback, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import * as React from "react";
import {isAgreedAnswer, isAutoAnswer} from "../../answers";
import {ReportTemplateTypeProps} from "../Types";
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const SuperMateInvestmentIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            let isTotal = i.valueType === 'Total';

            if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                if (!i.isTotalRow && i.name !== 'Total' && i.totalRevenue) {
                    verifiedCoverage += i.totalRevenue;
                }
            }
            if (i.name !== 'Total' && i.totalRevenue) {
                incomeTotal += i.totalRevenue;
            }

        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }

    const rebuildItems = (items: any[]): any[] => {
        if (items?.length === 1) {
            delete items[0].children;
        }
        
        return items;
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'title' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === 'Value' && <GeneratedAnswer
                            itemId={item?.id}
                            tableType={item?.reportType}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            value={item?.answerText}
                            auto={item?.isAnswerDisabled}
                            disabled={item?.isAnswerDisabled}
                            answerControlType={item?.answerControlType} />}
                    </Stack>
                )
            },
            {
                key: 'totalRevenue',
                name: formatMessage({ id: 'totalRevenue' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalRevenue',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'interest',
                name: formatMessage({ id: 'interest' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'interest',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'unfrankedInclCfi',
                name: formatMessage({ id: 'unfrankedInclCFI' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unfrankedInclCfi',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'franked',
                name: formatMessage({ id: 'franked' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'franked',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'imputationCredits',
                name: formatMessage({ id: 'imputationCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'imputationCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'otherIncome',
                name: formatMessage({ id: 'otherIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'otherIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cgtDiscount',
                name: formatMessage({ id: 'cgtDiscount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cgtDiscount',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cgtIndexation',
                name: formatMessage({ id: 'cgtIndexation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cgtIndexation',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cgtOther',
                name: formatMessage({ id: 'cgtOther' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cgtOther',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cgConcession',
                name: formatMessage({ id: 'cgConcession' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cgConcession',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cgCredits',
                name: formatMessage({ id: 'cgCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cgCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreignIncome',
                name: formatMessage({ id: 'foreignIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreignIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreignTaxCredits',
                name: formatMessage({ id: 'foreignTaxCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreignTaxCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'nonAssessableTaxFree',
                name: formatMessage({ id: 'nonAssessableTaxFree' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'nonAssessableTaxFree',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxDeferred',
                name: formatMessage({ id: 'taxDeferred' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxDeferred',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxExempted',
                name: formatMessage({ id: 'taxExempted' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxExempted',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'nonAssessableWithGTax',
                name: formatMessage({ id: 'nonAssessableWithGTax' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'nonAssessableWithGTax',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0] || 0} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'otherCredits',
                name: formatMessage({ id: 'otherCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'otherCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'unfranked',
                name: formatMessage({ id: 'unfranked' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unfranked',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreign',
                name: formatMessage({ id: 'foreign' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreign',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'tfnAbnWithholdTax',
                name: formatMessage({ id: 'tfnabnWithholdTax' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'tfnAbnWithholdTax',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'nonResWithholdTax',
                name: formatMessage({ id: 'nonResWithholdTax' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'nonResWithholdTax',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'specialIncome',
                name: formatMessage({ id: 'specialIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'specialIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'otherTaxCredits',
                name: formatMessage({ id: 'otherTaxCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'otherTaxCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold}  format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const mapItems = useCallback((initialItems: IReport[]) => {
        return rebuildItems(initialItems ?? []).reduce((acc: IReport[], i) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
            }
            acc.push(i)
            return acc;
        }, []);
    }, []);

    const [tableItems, setTableItems] = useState<IReport[]>(mapItems(items));

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(mapItems(tableItems));
        }
    }

    return <ReportTable
        initialColumns={columns} 
        items={tableItems}
        header={{ rowHeight: _ExtendedHeaderRowHeight }}
        openAll={true}
        groupProps={{
            mergedGroupHeader: {propertyKey: 'name'},
            summary: {
                show: true,
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    const summary = group.summary;
                    return <>
                        {
                            <Stack horizontal tokens={{childrenGap: 16}}>
                                <Stack.Item>
                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                </Stack.Item>
                            </Stack>
                        }
                    </>
                }
            }
        }}
        isFetching={isFetching} 
    />;
};
