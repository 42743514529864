import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import {IAttachment} from "./useGetAttachments";

function useGetAttachment() {
    const context = React.useContext(Context);

    const { mutate: getAttachment, isLoading, isSuccess } = useMutation((id: number) =>
        axios.get<IAttachment>(`${context?.config?.annotationsUrl}/api/1.0/attachments/${id}`, commonHeaders())
    );

    return { getAttachment, isLoading, isSuccess };
}

export { useGetAttachment };