import * as React from 'react';
import {FunctionComponent} from 'react';
import {CommandBar, ICommandBarItemProps} from '@fluentui/react/lib/CommandBar';
import {DraftBlockType} from 'draft-js';
import {IContextualMenuItem, useTheme} from '@fluentui/react';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {RichTextLinkEditForm} from './RichTextLinkEditForm';
import {RichTextImageEditForm} from './RichTextImageEditForm';

interface Props {
    onToggleBlockType: (blockType: DraftBlockType) => void;
    onToggleInlineStyle: (inlineStyle: string) => void;
    onToggleLink: (url?: string, target?: '_blank' | '_self') => void;
    onPastImage: (data: { src: string; height: number; width: number }) => void;
    onUndo: () => void;
    onRedo: () => void;
}

export const RichTextEditorMenu: FunctionComponent<Props> = (props: Props) => {
    const linkButtonId = useId('link-button');
    const imageButtonId = useId('image-button');
    const [isLinkEditFormVisible, { toggle: toggleIsEditFormVisible }] = useBoolean(false);
    const [isImageEditFormVisible, { toggle: toggleIsImageFormVisible }] = useBoolean(false);
    const theme = useTheme();

    const toggleBlockType = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
        item && props.onToggleBlockType(item['data-style-id'] as any);

    const toggleInlineStyle = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
        item && props.onToggleInlineStyle(item['data-style-id'] as any);

    const items: ICommandBarItemProps[] = [
        {
            key: 'undo',
            iconProps: { iconName: 'Undo' },
            onClick: props.onUndo,
        },
        {
            key: 'redo',
            iconProps: { iconName: 'Redo' },
            onClick: props.onRedo,
        },
        {
            key: 'formats',
            text: 'Formats',
            theme: theme.schemes?.default,
            subMenuProps: {
                theme: theme.schemes?.default,
                items: [
                    {
                        key: 'headings',
                        text: 'Headings',
                        subMenuProps: {
                            theme: theme.schemes?.default,
                            items: [
                                {
                                    key: 'heading-1',
                                    text: 'Heading 1',
                                    ['data-style-id']: 'header-one',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'heading-2',
                                    text: 'Heading 2',
                                    ['data-style-id']: 'header-two',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'heading-3',
                                    text: 'Heading 3',
                                    ['data-style-id']: 'header-three',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'heading-4',
                                    text: 'Heading 4',
                                    ['data-style-id']: 'header-four',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'heading-5',
                                    text: 'Heading 5',
                                    ['data-style-id']: 'header-five',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'heading-6',
                                    text: 'Heading 6',
                                    ['data-style-id']: 'header-six',
                                    onClick: toggleBlockType,
                                },
                            ],
                        },
                    },
                    {
                        key: 'inline',
                        text: 'Inline',
                        subMenuProps: {
                            theme: theme.schemes?.default,
                            items: [
                                {
                                    key: 'bold',
                                    text: 'Bold',
                                    ['data-style-id']: 'BOLD',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Bold' },
                                },
                                {
                                    key: 'italic',
                                    text: 'Italic',
                                    ['data-style-id']: 'ITALIC',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Italic' },
                                },
                                {
                                    key: 'underline',
                                    text: 'Underline',
                                    ['data-style-id']: 'UNDERLINE',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Underline' },
                                },
                                {
                                    key: 'strikethrough',
                                    text: 'Strikethrough',
                                    ['data-style-id']: 'STRIKETHROUGH',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Strikethrough' },
                                },
                                {
                                    key: 'superscript',
                                    text: 'Superscript',
                                    ['data-style-id']: 'SUPERSCRIPT',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Superscript' },
                                },
                                {
                                    key: 'subscript',
                                    text: 'Subscript',
                                    ['data-style-id']: 'SUBSCRIPT',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Subscript' },
                                },
                                {
                                    key: 'code',
                                    text: 'Code',
                                    ['data-style-id']: 'CODE',
                                    onClick: toggleInlineStyle,
                                    iconProps: { iconName: 'Code' },
                                },
                            ],
                        },
                    },
                    {
                        key: 'blocks',
                        text: 'Blocks',
                        subMenuProps: {
                            theme: theme.schemes?.default,
                            items: [
                                {
                                    key: 'paragraph',
                                    text: 'Paragraph',
                                    ['data-style-id']: 'paragraph',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'blockquote',
                                    text: 'Blockquote',
                                    ['data-style-id']: 'blockquote',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'div',
                                    text: 'div',
                                    ['data-style-id']: 'unstyled',
                                    onClick: toggleBlockType,
                                },
                                {
                                    key: 'pre',
                                    text: 'pre',
                                    ['data-style-id']: 'code-block',
                                    onClick: toggleBlockType,
                                },
                            ],
                        },
                    },
                    {
                        key: 'alignment',
                        text: 'Alignment',
                        subMenuProps: {
                            theme: theme.schemes?.default,
                            items: [
                                {
                                    key: 'left',
                                    text: 'Left',
                                    ['data-style-id']: 'ALIGNMENT_LEFT',
                                    onClick: toggleInlineStyle,
                                },
                                {
                                    key: 'center',
                                    text: 'Center',
                                    ['data-style-id']: 'ALIGNMENT_CENTER',
                                    onClick: toggleInlineStyle,
                                },
                                {
                                    key: 'right',
                                    text: 'Right',
                                    ['data-style-id']: 'ALIGNMENT_RIGHT',
                                    onClick: toggleInlineStyle,
                                },
                                {
                                    key: 'justify',
                                    text: 'Justify',
                                    ['data-style-id']: 'ALIGNMENT_JUSTIFY',
                                    onClick: toggleInlineStyle,
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            key: 'bold',
            iconProps: { iconName: 'Bold' },
            ['data-style-id']: 'BOLD',
            onClick: toggleInlineStyle,
        },
        {
            key: 'italic',
            iconProps: { iconName: 'Italic' },
            ['data-style-id']: 'ITALIC',
            onClick: toggleInlineStyle,
        },
        {
            key: 'left',
            iconProps: { iconName: 'AlignLeft' },
            ['data-style-id']: 'ALIGNMENT_LEFT',
            onClick: toggleInlineStyle,
        },
        {
            key: 'center',
            iconProps: { iconName: 'AlignCenter' },
            ['data-style-id']: 'ALIGNMENT_CENTER',
            onClick: toggleInlineStyle,
        },
        {
            key: 'right',
            iconProps: { iconName: 'AlignRight' },
            ['data-style-id']: 'ALIGNMENT_RIGHT',
            onClick: toggleInlineStyle,
        },
        {
            key: 'justify',
            iconProps: { iconName: 'AlignJustify' },
            ['data-style-id']: 'ALIGNMENT_JUSTIFY',
            onClick: toggleInlineStyle,
        },
        {
            key: 'unordered-list-item',
            iconProps: { iconName: 'BulletedList' },
            ['data-style-id']: 'unordered-list-item',
            onClick: toggleBlockType,
        },
        {
            key: 'ordered-list-item',
            iconProps: { iconName: 'NumberedList' },
            ['data-style-id']: 'ordered-list-item',
            onClick: toggleBlockType,
        },
    ];

    const farItems: ICommandBarItemProps[] = [
        {
            key: 'link',
            iconProps: { iconName: 'Link' },
            id: linkButtonId,
            onClick: toggleIsEditFormVisible,
        },
        {
            key: 'remove-link',
            iconProps: { iconName: 'RemoveLink' },
            onClick: () => props.onToggleLink(),
        },
        {
            key: 'image',
            id: imageButtonId,
            iconProps: { iconName: 'PictureFill' },
            onClick: toggleIsImageFormVisible,
        },
    ];

    return (
        <>
            <CommandBar
                items={items}
                //farItems={farItems}
                ariaLabel='Inbox actions'
                primaryGroupAriaLabel='Email actions'
                //farItemsGroupAriaLabel='More actions'
            />
            {/* {isLinkEditFormVisible ? (
                <RichTextLinkEditForm
                    buttonId={linkButtonId}
                    onDismiss={toggleIsEditFormVisible}
                    onSubmit={(url: string, target: '_blank' | '_self') => {
                        props.onToggleLink(url, target);
                        toggleIsEditFormVisible();
                    }}
                />
            ) : null}

            <RichTextImageEditForm
                open={isImageEditFormVisible}
                toggle={toggleIsImageFormVisible}
                onSubmit={(data: { src: string; height: number; width: number }) => {
                    props.onPastImage(data);
                    toggleIsImageFormVisible();
                }}
            /> */}
        </>
    );
};
