import React, {FunctionComponent} from "react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {
    Callout,
    DirectionalHint,
    Label,
    mergeStyleSets,
    Spinner,
    Stack,
    StackItem, Target,
    VerticalDivider
} from "@fluentui/react";
import {WorkDoneSwitchAnswer, WorkDoneSwitchAnswerProps} from "../../answers";
import {useIntl} from "react-intl";

interface IInvestmentIncomeCrsAnswer {
    crsInfo?: string;
}

export const InvestmentIncomeCrsAnswer: FunctionComponent<IInvestmentIncomeCrsAnswer & WorkDoneSwitchAnswerProps> = ({
                                                                                                                         crsInfo,
                                                                                                                         itemId,
                                                                                                                         tableType,
                                                                                                                         value,
                                                                                                                         auto,
                                                                                                                         disabled,
                                                                                                                         columnInfo
                                                                                                                     }: IInvestmentIncomeCrsAnswer & WorkDoneSwitchAnswerProps) => {

    const [isInfoVisible, {toggle: toggleIsInfoVisible}] = useBoolean(false);
    const elEd = useId('callout-element');

    return (
        <>
            {isInfoVisible && crsInfo && <CrsInfoTemplate crsInfo={crsInfo} target={elEd}></CrsInfoTemplate>}
            <Stack id={elEd}
                onPointerEnter={toggleIsInfoVisible}
                onPointerLeave={toggleIsInfoVisible}
            >
                <WorkDoneSwitchAnswer itemId={itemId}
                                      tableType={tableType}
                                      value={value}
                                      auto={auto} disabled={disabled} columnInfo={columnInfo}
                />
            </Stack>
        </>
    )
}


interface ICrsInfoProps {
    crsInfo: string;
    target?: Target;
}

const CrsInfoTemplate: FunctionComponent<ICrsInfoProps> = ({crsInfo, target}: ICrsInfoProps) => {

    const {formatMessage} = useIntl();

    const styles = mergeStyleSets({
        header: {},
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 'auto',
            maxWidth: 500,
            padding: '20px 24px',
        },
    });

    return (
        <>
            <Callout className={styles.callout}
                     target={`#${target}`}
                     gapSpace={3}
                     role="alert"
                     directionalHint={DirectionalHint.rightCenter}>
                {
                    <Stack>
                        <StackItem className={styles.header}>
                            <Label>{`${formatMessage({id: 'crsMatchedTo'})} ${crsInfo}`}</Label>
                        </StackItem>
                    </Stack>
                }
            </Callout>
        </>
    )
}