import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";


export interface IAuditQueriesInfo {
    initialQueriesSent: Date[];
    initialQueriesResponse: Date[];
    reviewedOsQueries: Date[];
    reviewedDispatchQueries: Date[];
    dispatchQueriesSent: Date[];
    dispatchQueriesResponse: Date[];
    arSentDate?: Date;
    nextJobAuditorDate?: Date;
    sendCount: number;
    responseCount: number;
    reviewCount: number;
    canPublish: boolean;
    canPostDispatch: boolean
}

function useGetDispatchAuditInfo(jobId: number) {
    const context = useContext(Context);

    const {
        data: auditQueriesInfo,
        isLoading: isLoading,
        refetch, isRefetching
    } = useQuery(['dispatchAuditInfo', jobId], () => axios.get<IAuditQueriesInfo>(`${context?.config?.queriesUrl}/api/1.0/queries/getDispatchAuditItems/${jobId}`), { enabled: !!jobId});


    return {auditQueriesInfo, isLoading, refetch, isRefetching};
}

export {useGetDispatchAuditInfo};