import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Shimmer, ShimmerElementType, Stack } from '@fluentui/react';
import { DefaultFormSettings } from 'constants/forms';
import { Card, ControlledTextField } from 'components';
import { useGetGeneralTitle } from '../hooks/General/useGetTitle';
import { useUpdateTitle } from '../hooks/General/useUpdateTitle';

interface IWebSettingsForm {
    companyTitle: string;
}

export const WebSettings: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const { generalTitle, isLoading: isGetTitleLoading } = useGetGeneralTitle();
    const { updateTitle, updateTitleLoading } = useUpdateTitle();
    const { control, handleSubmit, setValue, formState } = useForm<IWebSettingsForm>({
        ...DefaultFormSettings,
        defaultValues: { companyTitle: '' },
    });

    useEffect(() => {
        generalTitle?.data && setValue('companyTitle', generalTitle.data.companyTitle);
    }, [generalTitle?.data, setValue]);

    const onSubmit = (form: IWebSettingsForm) => {
        updateTitle({ companyTitle: form.companyTitle });
    };

    return (
        <Card>
            {isGetTitleLoading || updateTitleLoading || !generalTitle?.data ? (
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 61 }]} />
            ) : (
                <Stack horizontal verticalAlign='end' horizontalAlign='space-between' tokens={{ childrenGap: 16 }}>
                    <Stack.Item styles={{ root: { width: '50%' } }}>
                        <ControlledTextField
                            label={formatMessage({ id: 'companyTitle' })}
                            name='companyTitle'
                            control={control}
                            rules={{ required: formatMessage({ id: 'requiredField' }) }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton
                            disabled={!formState.isDirty}
                            text={formatMessage({ id: 'save' })}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack.Item>
                </Stack>
            )}
        </Card>
    );
};
