import { useTheme,  Icon, Spinner, Stack, Text } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SoftwareType } from '../../../../../../enums';
import { useGetSoftwareUploads } from '../../hooks/useGetSoftwareUploads';
import { FileUploadBlock } from './FileUploadBlock';

interface IFileUploadBlockProps {
    job: any;
    bucketName: string;
    selectedSoftwareType?: SoftwareType;
    updateSoftwareFilesState: (state: boolean) => void;
}

export const SoftwareUploadsBlock: FunctionComponent<IFileUploadBlockProps> = ({ job, bucketName, selectedSoftwareType, updateSoftwareFilesState }) => {
    const theme = useTheme();
    const { formatMessage} = useIntl();

    const { softwareUploadsData, isSoftwareUploadsLoading } = useGetSoftwareUploads({
        jobGuid: job?.guid,
        bucketName: bucketName,
        clientGuid: job?.client?.guid,
        fundGuid: job?.fund?.guid,
        softwareType: selectedSoftwareType == SoftwareType.Supermate ? SoftwareType.Supermate.toString()
            : selectedSoftwareType == SoftwareType.ClassPdfInCp ? SoftwareType.ClassSuper.toString() : SoftwareType.BGL.toString()
    });

    const [hasSoftwareFiles, setHasSoftwareFiles] = useState(false);

    useEffect(() => {
        setHasSoftwareFiles(softwareUploadsData?.data !== undefined && softwareUploadsData.data.length > 0);
    }, [softwareUploadsData?.data]);

    useEffect(() => {
        if (hasSoftwareFiles || selectedSoftwareType === SoftwareType.ClassPdfInCp) {
            updateSoftwareFilesState(true);
        }
        else {
            updateSoftwareFilesState(false);
        }
    }, [hasSoftwareFiles, selectedSoftwareType]);

    return (
        <>
            <Stack styles={{ root: { width: '100%' } }} horizontal tokens={{ childrenGap: 0 }} horizontalAlign={'end'}>
                <Stack.Item styles={{ root: { width: '50%' } }} align={'center'}>
                    <FileUploadBlock job={job} bucketName={bucketName} selectedSoftwareType={selectedSoftwareType ?? job?.clientPortalSoftwareType}/>
                </Stack.Item>
                <Stack.Item styles={{ root: { width: '10%' } }} align={'center'}>
                    {
                        isSoftwareUploadsLoading ? <Spinner /> :
                            hasSoftwareFiles &&
                            <Icon styles={{ root: { color: theme?.schemes?.default?.palette.green, marginLeft: '10%' } }} iconName={'accept'} />
                    }
                </Stack.Item>
            </Stack>
            {(!isSoftwareUploadsLoading && selectedSoftwareType === SoftwareType.BGL && !hasSoftwareFiles) && <Text style={{ color: theme?.schemes?.default?.palette.red, marginRight: '10%' }}>{formatMessage({ id: "pleaseUploadBglZippedFile" })}</Text>}
            {(!isSoftwareUploadsLoading && selectedSoftwareType === SoftwareType.Supermate && !hasSoftwareFiles) && <Text style={{ color: theme?.schemes?.default?.palette.red, marginRight: '10%' }}>{formatMessage({ id: "pleaseUploadFS" })}</Text>}
        </>
    );
}