import {
    Callout,
    Text,
    DelayedRender,
    IconButton,
    mergeStyleSets,
    useTheme,
    DirectionalHint,
    mergeStyles, IButtonStyles, FontSizes
} from '@fluentui/react';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {Modal} from 'components';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {JobCommentContent} from './JobCommentContent';
import {JobCommentPreview} from "./JobCommentPreview";
import {useIntl} from "react-intl";
import {useSectionContext} from "../Section";

interface IJobCommentProps {
    itemId: number;
    hasComments: boolean
    tableType?: number;
}

export const JobComment: FunctionComponent<IJobCommentProps> = ({itemId, hasComments, tableType}) => {
    const sectionCtx = useSectionContext();
    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const [isPreviewVisible, {toggle: toggleIsPreviewVisible}] = useBoolean(false);
    const buttonId = useId('callout-button');

    const [isPointerOverPreview, setIsPointerOverPreview] = useState<boolean>(false);

    tableType = tableType || sectionCtx?.section.tableType || 0;

    const styles: IButtonStyles = {
        root: {
            color: hasComments ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: hasComments ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color: hasComments ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    const handlePointerEnter = () => {
        setIsPointerOverPreview(true);
    };

    const handlePointerLeave = () => {
        setIsPointerOverPreview(false);
    };
    
    useEffect(() => {
        if (!isPointerOverPreview && isPreviewVisible) {
            toggleIsPreviewVisible()
        }
    }, [isPointerOverPreview])
    
    return (
        <>
            <IconButton id={buttonId}
                        iconProps={{iconName: 'Comment', style: {fontSize: FontSizes.size14}}}
                        onPointerEnter={toggleIsPreviewVisible}
                        onPointerLeave={() => {
                            setTimeout(() => {
                                if (!isPointerOverPreview) {
                                    toggleIsPreviewVisible()
                                }
                            }, 800);
                        }}
                        styles={styles}
                        onClick={toggleOpenModal}/>
            {isPreviewVisible && hasComments && (<JobCommentPreview isPointerOver={isPointerOverPreview}
                                                                    itemId={itemId}
                                                                    target={buttonId}
                                                                    tableType={tableType}
                                                                    onPointerEnter={handlePointerEnter}
                                                                    onPointerLeave={handlePointerLeave}
                                                                    />)}
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} title={formatMessage({id: 'comments'})}>
                <JobCommentContent 
                    itemId={itemId}
                    onCancel={toggleOpenModal}
                    onSuccessUpdate={() => {
                        if (sectionCtx?.refresh) {
                            sectionCtx?.refresh();
                        }
                        toggleOpenModal()
                    }} 
                    tableType={tableType}/>
            </Modal>
        </>
    );
};