import {FunctionComponent, useEffect, useMemo} from "react";
import {IAuditQualificationItem} from "./interfaces";
import {Card} from "../../../../../../components";
import {Stack, Text, useTheme} from "@fluentui/react";
import {WorkDoneSwitchAnswer} from "../../answers";
import {ColumnInfo} from "../../../../enums";
import {useTabContext} from "../../../../JobPortalPage";
import {_QualificationProcedureRef} from "./AuditQualificationTemplate";

type AuditQualificationPanelProps = {
    item: IAuditQualificationItem;
    onAnswer?: (answer: string | null) => void,
    gridHasOmls: boolean,
    disabled: boolean
}

const cardStyles = {
    root: {
        width: '100%'
    }
};

export const AuditQualificationPanelTemplate: FunctionComponent<AuditQualificationPanelProps> = ({item, onAnswer, gridHasOmls, disabled}) => {
    const {disableSection} = useTabContext();

    const handleOnContraventionAnswer = (answer: string | null, itemReference: string) => {
        if (itemReference === 'Is a contravention report required?') {
            disableSection('Contravention Report', !answer || answer === 'No');
        }

    };

    useEffect(() => {
        handleOnContraventionAnswer(item.answerText, item.title);
    },[]);
    
    const disabledPartialy: any = useMemo(() => {
        if (item.title === _QualificationProcedureRef && gridHasOmls) {
            return 'invalid'
        }
        
        return null;
    }, [item, gridHasOmls])

    const theme = useTheme();

    return (
        <Card styles={cardStyles}>
            <Stack horizontal verticalAlign="center" horizontalAlign='space-between' grow>
                <Stack.Item>
                    <Text theme={theme.schemes?.default}>{item.title}</Text>
                </Stack.Item>
                <Stack.Item>
                    <WorkDoneSwitchAnswer itemId={item.id}
                                          tableType={item.tableType!}
                                          iconTypes="character"
                                          reverse
                                          disabled={disabled}
                                          onUpdate={(answer) => {
                                              handleOnContraventionAnswer(answer, item.title);
                                              onAnswer?.(answer);
                                          }}
                                          disabledPartialy={disabledPartialy}
                                          value={item.answerText || ''}
                                          columnInfo={ColumnInfo.Text}/>
                </Stack.Item>
            </Stack>
        </Card>
    );
}