import React from 'react';
import { commonHeaders, Context } from '../../../../../utils';
import { useQuery } from 'react-query';
import axios from 'axios';
import { SoftwareType } from "../../../../../enums";

export type ExternalClientInfo = {
    isAdminAuthentication: boolean;
    businessAuthInfo: BusinessAuthInfo[]
}

export type BusinessAuthInfo = {
    softwareType: SoftwareType;
    softwareTypeName: string;
    businessCode: string;
    link: string;
    isAuthenticated: boolean;
}

function useGetExternalClientInfo(guid: string) {
    const context = React.useContext(Context);

    const { data: info, isLoading } = useQuery(
        ['getExternalClientInfo', guid],
        () => axios.get<ExternalClientInfo>(`${context?.config?.importUrl}/api/1.0/Import/external/status/${guid}`, commonHeaders())
            .then(resp => resp.data),
        {
            enabled: !!context?.config?.importUrl && !!context.tenantId && !!guid,
        }
    );

    return { info, isLoading };
}

export { useGetExternalClientInfo };
