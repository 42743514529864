import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

function useDeleteUOCItem() {
    const context = useContext(Context);
    const {
        mutate: deleteUOC,
        isLoading: isDeleting
    } = useMutation((id: number) => axios.delete(`${context?.config?.proceduresUrl}/api/1.0/procedures/uoc/${id}`))

    return {deleteUOC, isDeleting};
}

export {useDeleteUOCItem};