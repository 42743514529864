import {Axios} from 'axios-observable';
import {AxiosError} from 'axios';
import {firstValueFrom} from 'rxjs';
import {stores} from 'stores';
import {IAuthorizationToken} from 'interfaces';
import {BaseService} from './BaseService';
import {MessageBarType} from '@fluentui/react';
import {useNotifications} from "../components/notifications";
import {useIntl} from "react-intl";

let tokenRequest: Promise<IAuthorizationToken> | undefined;

export const httpClientInstanceFactory = (baseURL: string) => {
    const instance = Axios.create({
        timeout: 15000,
        baseURL,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    });

    instance.interceptors.response.use(
        (response) => response,
        function (error: AxiosError) {
            if (error.response?.status && error.response?.status >= 500 && error.response?.status < 600 || error.response?.status == 400) {
                // showNotification({
                //     name: 'Error',
                //     description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages ? error.response?.data?.messages[0] : formatMessage({id: 'somethingWentWrong'})}`,
                //     type: MessageBarType.error,
                // });
            }

            if (error.response?.status !== 401) {
                return Promise.reject(error);
            }
            const refreshToken = stores.AuthStore.refreshToken;

            if (!refreshToken) {
                stores.AuthStore.signOut();
                return Promise.reject(error);
            }

            if (!tokenRequest) {
                tokenRequest = firstValueFrom(stores.AuthStore.getTokenByRefreshToken());
            }

            return tokenRequest
                .then(() => {
                    tokenRequest = undefined;
                    return firstValueFrom(Axios.request(BaseService.setAuthorizationHeaders(error.config) as any));
                })
                .catch((nextError: AxiosError) => {
                    if (error.response?.status === 401) {
                        stores.AuthStore.signOut();
                    }
                    return Promise.reject(nextError);
                });
        }
    );

    return instance;
};
