import React, {FunctionComponent, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {ControlledDropdown, ControlledTextField, SearchPanel} from "../../../../../components";
import {useInvoicesContext} from "../interfaces";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";
import {IDropdownOption, Stack} from "@fluentui/react";
import {InvoiceStatusType} from "../enums";

interface IInvoicesSearchPanelProps {
    
}

interface IInvoiceSearchForm {
    status: string;
    fund: string;
    year: string;
    invoiceNumber: string | undefined;
}

const _defaultInvoiceStatusType: InvoiceStatusType = InvoiceStatusType.Open;

const _defaultFormValues: IInvoiceSearchForm = {
    status: _defaultInvoiceStatusType.toString(),
    fund: '',
    year: '',
    invoiceNumber: ''
}

export const InvoicesSearchPanel: FunctionComponent<IInvoicesSearchPanelProps> = ({}: IInvoicesSearchPanelProps) => {
    const { formatMessage } = useIntl();
    
    const { funds, years } = useInvoicePortalContext();
    const { filter, setFilter } = useInvoicesContext();
    
    const { control, handleSubmit, reset, setValue } = useForm<IInvoiceSearchForm>({
        ...DefaultFormSettings,
        defaultValues: _defaultFormValues
    });

    const [isInvoiceNumberEnabled, setIsInvoiceNumberEnabled] = useState<boolean>(false);
    
    const statusOptions: IDropdownOption[] = useMemo(() => {
        return [
            { key: InvoiceStatusType.NotCreated.toString(), text: formatMessage({ id: 'invoicesFilters.notCreatedOnly' }) },
            { key: InvoiceStatusType.Open.toString(), text: formatMessage({ id: 'invoicesFilters.open' }) },
            { key: InvoiceStatusType.Closed.toString(), text: formatMessage({ id: 'invoicesFilters.closed' }) },
            { key: InvoiceStatusType.CreatedOnly.toString(), text: formatMessage({ id: 'invoicesFilters.createdOnly' }) },
            { key: InvoiceStatusType.NotCreatedDraftArSent.toString(), text: formatMessage({ id: 'invoicesFilters.notCreatedDraftArSent' }) }
        ]
    }, [formatMessage])
    
    const fundsOptions: IDropdownOption[] = useMemo(() => {
        return [
            { key: '', text: formatMessage({ id: 'all' }) },
            ...funds.map(f => ({ key: f.id, text: f.name }))
        ]
    }, [funds])
    
    const yearsOptions: IDropdownOption[] = useMemo(() => {
        return [
            { key: '', text: formatMessage({ id: 'all' }) },
            ...years
                .filter(y => y.isSelected)
                .map(y => y.year)
                .sort().reverse()
                .map(y => ({ key: y, text: y.toString() }))
        ]
    }, [years])
    
    const onPanelSubmit = (data: IInvoiceSearchForm) => {
        setFilter({
            ...filter,
            status: +data.status as InvoiceStatusType,
            fundName: data.fund ? funds.find(f => f.id === +data.fund)?.name : undefined,
            year: +data.year || undefined,
            invoiceNumber: data.invoiceNumber ? +data.invoiceNumber : undefined
        })
    }
    
    const onPanelReset = () => {
        // for some reason text field should be set manually to default
        setValue('invoiceNumber', '')
        reset();
        setFilter({
            ...filter,
            status: _defaultInvoiceStatusType,
            fundName: undefined,
            year: undefined,
            invoiceNumber: undefined
        })
    }
    
    const onStatusValueChanged = (option: IDropdownOption) => {
        setIsInvoiceNumberEnabled(!!option.id && [InvoiceStatusType.Open, InvoiceStatusType.Closed].includes(+option.id))
    }

    const fieldWidth = 'calc(50% - 16px)';
    
    return (
        <>
            <SearchPanel
                onSearch={() => handleSubmit(onPanelSubmit)() }
                onClear={onPanelReset}
            >
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <Stack.Item styles={{ root: { width: fieldWidth } }} grow={2}>
                        <ControlledDropdown
                            label={formatMessage({ id: 'status' })}
                            name='status'
                            control={control}
                            options={statusOptions}
                            onValueChange={onStatusValueChanged}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: fieldWidth } }} grow={5}>
                        <ControlledDropdown
                            label={formatMessage({ id: 'fund' })}
                            name='fund'
                            control={control}
                            options={fundsOptions}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: fieldWidth } }} grow={1}>
                        <ControlledDropdown
                            label={formatMessage({ id: 'year' })}
                            name='year'
                            control={control}
                            options={yearsOptions}
                        />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: fieldWidth } }} grow={2}>
                        <ControlledTextField 
                            label={formatMessage({ id: 'invoiceNumber' })}
                            name='invoiceNumber'
                            control={control}
                            disabled={isInvoiceNumberEnabled}
                        />
                    </Stack.Item>
                </Stack>
            </SearchPanel>
        </>
    )
}