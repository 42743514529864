export const RouterPathsRulesetIdKey = 'rulesetId';
export const RouterPathsRuleIdKey = 'ruleId';
export const RouterPathsVariableIdKey = 'variableId';
export const RouterPathsClientIdKey = 'clientId';
export const RouterPathsFundIdKey = 'fundId';
export const RouterPathsJobGuidKey = 'guid';
export const RouterPathsJobTabKey = 'tabId';
export const RouterPathsJobCalculatorTypeKey = 'calculatorType';
export const RouterPathsJobSectionIdKey = 'sectionId';

export const RouterPaths = {
    job: {
        root: (guid: number | string = ':' + RouterPathsJobGuidKey) => `/job-portal/${guid}`,
        tab: (guid: number | string = ':' + RouterPathsJobGuidKey, tabId: number | string = ':' + RouterPathsJobTabKey) =>
            `/job-portal/${guid}/${tabId}`,

        external: {
            root: (guid: number | string = ':' + RouterPathsJobGuidKey) => `/job-portal-ext/${guid}`,
            calculator: (
                guid: number | string = ':' + RouterPathsJobGuidKey,
                sectionId: string = ':' + RouterPathsJobSectionIdKey,
                type: string = ':' + RouterPathsJobCalculatorTypeKey
            ) => `/job-portal-ext/${guid}/calculator/${sectionId}/${type}`,
            section: (guid: number | string = ':' + RouterPathsJobGuidKey, sectionId: string = ':' + RouterPathsJobSectionIdKey) =>
                `/job-portal-ext/${guid}/section/${sectionId}`,
        },
        rootLegacy: () => `/Home/Index`,
    },
    invoices: {
        root: (clientId?: string) => (!!clientId ? `/invoice-portal/invoices?clientId=${clientId}` : '/invoice-portal'),
        invoices: '/invoice-portal/invoices',
        creditNotes: '/invoice-portal/creditnotes',
        otherClients: '/invoice-portal/otherclients',
    },
    portalSettings: {
        root: '/portal-settings',
        auditReadyConnections: '/portal-settings/audit-ready-connections',
        generalSettings: '/portal-settings/general-settings',
        riskMatrixSettings: '/portal-settings/risk-matrix-settings',
        materialityCalculatorSettings: '/portal-settings/materiality-calculator-settings',
        testingAndMethodologySettings: '/portal-settings/testing-and-methodology-settings',
        emailTemplates: '/portal-settings/email-templates',
        emailSettings: '/portal-settings/email-settings',
    },
    userManagement: '/user-management',
    library: '/library',
    customization: '/customization',
    admin: {
        root: '/admin',
        rulesets: {
            root: '/admin/rulesets',
            ruleset: {
                root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                rules: {
                    root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                    new: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}/rules/new`,
                    rule: (
                        rulesetId: number | string = ':' + RouterPathsRulesetIdKey,
                        ruleId: number | string = ':' + RouterPathsRuleIdKey
                    ) => `/admin/rulesets/${rulesetId}/rules/${ruleId}`,
                },
                variables: {
                    root: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}`,
                    new: (rulesetId: number | string = ':' + RouterPathsRulesetIdKey) => `/admin/rulesets/${rulesetId}/variables/new`,
                    variable: (
                        rulesetId: number | string = ':' + RouterPathsRulesetIdKey,
                        variableId: number | string = ':' + RouterPathsVariableIdKey
                    ) => `/admin/rulesets/${rulesetId}/variables/${variableId}`,
                },
            },
        },
    },
    login: {
        root: '/login',
        authenticator: '/login/authenticator',
        verifyEmail: '/login/verify-email',
        resetPassword: '/login/reset-password',
        changePassword: '/login/change-password',
        final: '/login/final',
    },
    workflow: {
        root: '/workflow-portal',
        dashboard: '/workflow-portal/dashboard',
        clients: '/workflow-portal/clients',
        funds: '/workflow-portal/funds',
        employees: '/workflow-portal/employees',
        jobs: '/workflow-portal/jobs',
        timesheet: '/workflow-portal/timesheet',
        reports: '/workflow-portal/reports',
        trustees: '/workflow-portal/trustees',
    },
    administration: {
        root: '/administration',
        dashboards: '/administration/dashboards',
        jobs: {
            root: '/administration/jobs',
            funds: {
                root: (clientId: number | string = ':' + RouterPathsClientIdKey) => `/administration/jobs/${clientId}`,
                jobList: {
                    root: (
                        clientId: number | string = ':' + RouterPathsClientIdKey,
                        fundId: number | string = ':' + RouterPathsFundIdKey
                    ) => `/administration/jobs/${clientId}/${fundId}`,
                },
            },
        },
    },
    importReport: {
        root: (jobGuid: number | string = ':' + RouterPathsJobGuidKey) => `/import/report/${jobGuid}`,
    },
    oauth: {
        index: '/oauth/index',
    },
};
