import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

type CustomizationResponse = {
    isDefault: boolean;
    logoImageUrl: string;
    themeViewModel: {
        neutralPrimary: string;
        themePrimary: string;
    };
};

function useGetCustomizationColors(url: string, isApplied: boolean, tenantId: string) {
    const context = React.useContext(Context);

    const {data: colors, isLoading} = useQuery(
        ['getCustomizationColors', isApplied],
        () => axios.get<CustomizationResponse>(`${url}/api/1.0/Customisation?isApplied=${isApplied}`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
            retry: false,
        }
    );

    return {colors, isLoading};
}

export {useGetCustomizationColors};
