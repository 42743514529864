import {SoftwareType, TableType} from "../../../enums";
import {ReportTemplateTypeProps} from "../components/templates/reports/Types";
import {
    BankAccountMovementReport,
    BGL360BankStatement,
    BGL360FinancialPosition,
    BGL360GeneralLedger,
    BGL360InvestmentMovement,
    BGL360InvestmentSummary,
    BGL360MemberStatement,
    BGL360OperatingStatement,
    BGL360RealisedCapitalGains,
    BGL360TaxableIncome,
    BGL360TaxAccouting,
    BGL360Trialbalance,
    BGLDeferredTax, BGLFinancialPosition,
    BGLI360InvestmentIncome,
    BGLInvestmentDisposal,
    BGLInvestmentIncome,
    BGLInvestmentMovement,
    BGLInvestmentSummary,
    BGLInvestmentSummaryWithYield,
    BGLMemberStatement,
    BGLOperating,
    BGLTaxableIncome,
    ClassSuperBalanceReconciliation, ClassSuperConsolidatedInvestmentIncome,
    ClassSuperFeedsConsole,
    ClassSuperHoldingsBalanceReconciliation,
    ClassSuperInvestmentMovement, ClassSuperRealisedCapitalGainsReport, ClassSuperTaxAccountingReconciliation,
    GeneralLedger,
    GeneralLedgerAuditTrail,
    InvestmentIncome,
    InvestmentIncomeComparision,
    InvestmentSummary,
    MemberStatement,
    OperatingStatement,
    StatmentofFP,
    SuperMateFinancialPosition,
    SuperMateGeneralLedger,
    SuperMateInvestmentIncome,
    SuperMateInvestmentMovement,
    SuperMateInvestmentSummary,
    SuperMateMemberStatement,
    SuperMateOperating,
    SuperMateRealisedCapitalGains,
    SuperMateTaxReconciliation,
    TaxableIncomeReport
} from "../components/templates/reports";

export const getReportTemplate = (softwareType: SoftwareType, reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (softwareType) {
        case SoftwareType.ClassSuper:
            return getClassSuperReportTemplate(reportType);
        case SoftwareType.DesktopSuper:
            return getDesktopReportTemplate(reportType);
        case SoftwareType.Supermate:
            return getSupermateReportTemplate(reportType);
        case SoftwareType.BGL:
            return getBglReportTemplate(reportType);
        case SoftwareType.BGL360:
            return getBgl360ReportTemplate(reportType);
        default:
            return null;

    }
};

const getBglReportTemplate = (reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (reportType) {
        case TableType.BGLOperating:
            return BGLOperating;
        case TableType.BGLFinancialPosition:
            return BGLFinancialPosition;
        case TableType.BGLTaxableIncome:
            return BGLTaxableIncome;
        case TableType.BGLMemberStatement:
            return BGLMemberStatement;
        case TableType.BGLInvestmentSummary:
            return BGLInvestmentSummary;
        case TableType.BGLInvestmentSummaryWithYield:
            return BGLInvestmentSummaryWithYield;
        case TableType.BGLInvestmentIncome:
            return BGLInvestmentIncome;
        case TableType.BGLInvestmentDisposal:
            return BGLInvestmentDisposal;
        case TableType.BGLInvestmentMovement:
            return BGLInvestmentMovement;
        case TableType.BGLDeferredTax:
            return BGLDeferredTax;
        default:
            return null;
    }
};

const getBgl360ReportTemplate = (reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (reportType) {
        case TableType.BGL360InvestmentIncome:
            return BGLI360InvestmentIncome;
        case TableType.BGL360Trialbalance:
            return BGL360Trialbalance;
        case TableType.BGL360GeneralLedger:
            return BGL360GeneralLedger;
        case TableType.BGL360InvestmentSummary:
            return BGL360InvestmentSummary;
        case TableType.BGL360FinancialPositionAPI:
        case TableType.BGL360FinancialPosition:
            return BGL360FinancialPosition;
        case TableType.BGL360OperatingStatementAPI:
        case TableType.BGL360OperatingStatement:
            return BGL360OperatingStatement;
        case TableType.BGL360MemberStatement:
            return BGL360MemberStatement;
        case TableType.BGL360InvestmentMovement:
            return BGL360InvestmentMovement;
        case TableType.BGL360RealisedCapitalGains:
            return BGL360RealisedCapitalGains;
        case TableType.BGL360TaxAccounting:
            return BGL360TaxAccouting;
        case TableType.BGL360TaxableIncome:
            return BGL360TaxableIncome;
        case TableType.BGL360BankStatement:
            return BGL360BankStatement;
        default:
            return null;
    }
};

const getClassSuperReportTemplate = (reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (reportType) {
        case TableType.ClassSuperStatmentofFP:
            return StatmentofFP;
        case TableType.ClassSuperOperatingStatement:
            return OperatingStatement;
        case TableType.ClassSuperGeneralLedger:
            return GeneralLedger;
        case TableType.ClassSuperInvestmentIncome:
            return InvestmentIncome;
        case TableType.ClassSuperInvestmentSummary:
            return InvestmentSummary;
        case TableType.ClassSuperMemberStatement:
            return MemberStatement;
        case TableType.ClassSuperBankAccountMovementReport:
            return BankAccountMovementReport;
        case TableType.ClassSuperTaxableIncomeReport:
            return TaxableIncomeReport;
        case TableType.ClassSuperInvestmentIncomeComparision:
            return InvestmentIncomeComparision;
        case TableType.ClassSuperGeneralLedgerAuditTrail:
            return GeneralLedgerAuditTrail;
        case TableType.ClassSuperBalanceReconciliation:
            return ClassSuperBalanceReconciliation;
        case TableType.ClassSuperFeedsConsole:
            return ClassSuperFeedsConsole;
        case TableType.ClassSuperHoldingsBalanceReconciliation:
            return ClassSuperHoldingsBalanceReconciliation;
        case TableType.ClassSuperInvestmentMovement:
            return ClassSuperInvestmentMovement;
        case TableType.ClassSuperRealisedCapitalGainsReport:
            return ClassSuperRealisedCapitalGainsReport;
        case TableType.ClassSuperTaxAccountingReconciliation:
            return ClassSuperTaxAccountingReconciliation;
        case TableType.ClassSuperConsolidatedInvestmentIncome:
            return ClassSuperConsolidatedInvestmentIncome;
        default:
            return null;
    }
};

const getSupermateReportTemplate = (reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (reportType) {
        case TableType.SupermateGeneralLedger:
            return SuperMateGeneralLedger;
        case TableType.SupermateFinancialPosition:
            return SuperMateFinancialPosition;
        case TableType.SupermateOperating:
            return SuperMateOperating;
        case TableType.SupermateInvestmentIncome:
            return SuperMateInvestmentIncome;
        case TableType.SupermateInvestmentSummary:
            return SuperMateInvestmentSummary;
        case TableType.SupermateInvestmentMovement:
            return SuperMateInvestmentMovement;
        case TableType.SupermateRealisedCapitalGains:
            return SuperMateRealisedCapitalGains;
        case TableType.SupermateTaxReconciliation:
            return SuperMateTaxReconciliation;
        case TableType.SupermateMemberStatement:
            return SuperMateMemberStatement;
        default:
            return null;
    }
};
const getDesktopReportTemplate = (reportType: TableType): React.FC<ReportTemplateTypeProps> | null => {
    switch (reportType) {
        default:
            return null;
    }
};