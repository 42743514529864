import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import { useNotifications } from 'components/notifications';
import { useIntl } from 'react-intl';
import { MessageBarType } from '@fluentui/react';

interface IUploadSoftwareFile {
    clientGuid: string;
    fundGuid: string;
    jobGuid: string;
    softwareType: string;
    bucketName: string;
    data: FormData;
}

function useUploadSoftwareFile() {
    const context = React.useContext(Context);
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: uploadSoftwareFile, isLoading, isSuccess } = useMutation(({ clientGuid, fundGuid, jobGuid, softwareType, bucketName, data }: IUploadSoftwareFile) =>
        {
            const file = data.get('file') as File;

            if(file.size === 0)
            {
                showNotification({
                    name: formatMessage({ id: 'fileSizeIsZero' }),
                    type: MessageBarType.error,
                    description: formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }),
                });

                return Promise.reject(formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }));
            }

            return axios.post(`${context?.config?.storageUrl}/api/1.0/storage/files/uploadSoftware?storageFolder=${bucketName}&filesPath=${clientGuid}/${fundGuid}/${jobGuid}/${softwareType}`, data, commonHeaders())
        },
        {
            onSuccess: () => queryClient.invalidateQueries('getSoftwareUploads')
        }
    );

    return { uploadSoftwareFile, isLoading, isSuccess };
}

export { useUploadSoftwareFile };