import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from 'constants/forms';
import {IAdministrationContext} from 'interfaces';
import {SearchBox, Stack, StackItem, useTheme} from "@fluentui/react";
import {useAdministrationContext} from "../JobsLayoutPage";

export const AdministrationFilters: FunctionComponent = observer(() => {
    const {isLoading, setLoading, setFilters} = useAdministrationContext();
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const onClear = () => {
        onSearch("");
    };

    const onSearch = (searchText: string | undefined) => {
        setLoading(true);
        setFilters((prev: IAdministrationContext['filters']) => ({...prev, page: 1, search: searchText}));
        setLoading(false);
    }
    return (
        <Stack horizontal horizontalAlign={"center"} styles={{root: {margin: 10}}}>
            <StackItem>
                <SearchBox placeholder={formatMessage({id: 'search'})}
                    styles={{
                        root: { minWidth: 480 },
                        field: {
                            color: theme.schemes?.default?.semanticColors.inputText,
                            selectors: {
                                '&::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
                            }
                        },
                        clearButton: {
                            selectors: {
                                '.ms-Button-icon': { color: theme.schemes?.default?.semanticColors.bodyText },
                                '&:hover .ms-Button-icon': { color: theme.schemes?.default?.semanticColors.bodyText }
                            }
                        }
                    }}
                           underlined={true}
                           disabled={isLoading}
                           onSearch={(newValue) => {
                               onSearch(newValue);
                           }}
                           onClear={() => onClear()}
                />
            </StackItem>

        </Stack>

    );
});