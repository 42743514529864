import {DatePicker, Icon, mergeStyleSets, Stack, useTheme} from '@fluentui/react';
import * as React from 'react';
import {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {ITableFilters} from '../GeneratedDataTable';
import {format, parseJSON} from "date-fns";
import {useIntl} from "react-intl";

export interface IGeneratedDateFilterProps {
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
}

export const GeneratedDatesFilter: FunctionComponent<IGeneratedDateFilterProps> = ({setFilters}) => {

    const styles = mergeStyleSets({
        control: {
            minWidth: 165,
            maxWidth: 300,
        }
    });

    const theme = useTheme();

    const pickerStyles = {
        icon: { color: theme.palette.themePrimary, left: "9px", right: "unset" },
        callout: {
            selectors:
            {
                'span, i, button, th': { color: theme.schemes?.default?.semanticColors.bodyText },
                'span:hover, i:hover, button:hover': { color: theme.palette.themePrimary },
            }
        },
        root: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            selectors: {
                '.ms-TextField-fieldGroup': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 },
                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
            }
        },
        readOnlyPlaceholder: { color: theme.schemes?.default?.semanticColors.disabledBodyText }
    }

    const { formatMessage } = useIntl();

    const [fromDate, setFromDate] = useState<Date | null>();
    const [toDate, setToDate] = useState<Date | null>();

    const onFormatDate = React.useCallback((date?: Date): string => {
        if (!date)
            return '';

        return format(parseJSON(date), 'dd/MM/yyyy');
    }, []);
    const onStartDateChange = (newDate: Date | null | undefined) => {
        setFromDate(newDate);
    };
    const onEndDateChange = (newDate: Date | null | undefined) => setToDate(newDate);
    const clearDate = (type: "from" | "to") => {
        if (type == "from") setFromDate(undefined);
        if (type == "to") setToDate(undefined);
    };
    const fieldProps = (type: "from" | "to") => {
        return {
            onRenderSuffix: () => (
                <Icon iconName={'clear'} onClick={() => clearDate(type)}/>),
            styles: {
                field: {
                    paddingLeft: 40
                },
                suffix: {
                    padding: "0 8px",
                    fontSize: "x-small",
                    cursor: "pointer",
                    background: 'transparent'
                }
            }
        }
    };

    useEffect(() => {

        setFilters(prev => ({
            ...prev,
            startDate: fromDate?.toISOString() || ''
        }));
        setFilters(prev => ({
            ...prev,
            endDate: toDate?.toISOString() || ''
        }));
    }, [fromDate, toDate]);

    return (
        <Stack horizontal tokens={{childrenGap: 16}}>
            <DatePicker placeholder={formatMessage({ id: 'from' })}
                        formatDate={onFormatDate}
                        onSelectDate={onStartDateChange}
                        value={fromDate as Date}
                        textField={fieldProps("from")}
                        styles={pickerStyles}
                        underlined
                        className={styles.control}/>
            <DatePicker placeholder={formatMessage({id: 'to'})}
                        formatDate={onFormatDate}
                        textField={fieldProps("to")}
                        styles={pickerStyles}
                        value={toDate as Date}
                        onSelectDate={onEndDateChange}
                        underlined
                        className={styles.control}/>
        </Stack>
    );
};