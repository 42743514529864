import React, {FunctionComponent, ReactNode} from 'react';
import {Stack} from '@fluentui/react';

interface Props {
    left?: ReactNode | undefined;
    right?: ReactNode | undefined;
}

export const Footer: FunctionComponent<Props> = ({left, right}: Props) => {

    if (left || right) {
        return (
            <footer>
                <Stack horizontal horizontalAlign='space-between'
                       styles={{root: {position: 'absolute', bottom: 0, right: 0, height: 49}}}>
                    <Stack verticalAlign={"center"} horizontal horizontalAlign={"start"}
                           styles={{root: {width: '100%', height: 'auto', paddingLeft: 10}}}>
                        {left ?? null}
                    </Stack>
                    <Stack styles={{root: {width: '100%', height: 'auto', paddingRight: 20}}} horizontal
                           horizontalAlign='end' verticalAlign='center'>
                        {right ?? null}
                    </Stack>
                </Stack>
            </footer>
        );
    }

    return null;
};
