import { IAvailableAnswerOptions } from "../../../hooks/answers";

export const isPositiveAnswer = (text?: string, positiveOptions?: string[]) => !!text && !!positiveOptions && positiveOptions.some(x => x.toLowerCase() == text.toLowerCase());
export const isNegativeAnswer = (text?: string, negativeOptions?: string[]) => !!text && !!negativeOptions && negativeOptions.some(x => x.toLowerCase() == text.toLowerCase());
export const isAutoAnswer = (text?: string) => !!text && text === 'Auto'
export const isAgreedAnswer = (text?: string) => !!text && text === 'Agreed'
export const isYesAnswer = (text?: string) => !!text && text === 'Yes'
export const isNoAnswer = (text?: string) => !!text && text === 'No'


type UpdatedAnswer =  { id: any, answerText: any } | { id: any, answerText: any, isDisabled?: boolean | undefined } | any;

export type Expression = (newValue: boolean | null) => boolean | null;

export const calculateSectionAnswer = (updatedAnswers: UpdatedAnswer[], answerOptions: IAvailableAnswerOptions | undefined, isPositiveSectionAnswer: boolean | null, expression?: Expression): boolean | null => {
	
	const _isValueAnswer = (answerText: string | null, value: 'positive' | 'negative') => {
		const _callback = value === 'positive'
			? (a: string) => isPositiveAnswer(a, answerOptions?.valid)
			: (a: string) => isNegativeAnswer(a, answerOptions?.invalid);

		return answerText && _callback(answerText);
	}

	// if there are no manual answers, we should check if there are auto answers
	const autoAnswers = (updatedAnswers as any[]).filter(a => a.autoIndexes && a.autoIndexes.length > 0);
	// if there are auto answers, set section positivity value based on auto answers value:
	// negative - if at least one negative auto answer
	// positive - if there are not negative auto answers
	const containsAutoNegative = !!autoAnswers.length && autoAnswers
		.some(aa => aa.autoIndexes
			.map((aai: any) => _isValueAnswer(aa['answerText' + aai], 'negative'))
			.some((aan: boolean) => aan));
	const containsAutoPositive = !!autoAnswers.length && autoAnswers
		.some(aa => aa.autoIndexes
			.map((aai: any) => _isValueAnswer(aa['answerText' + aai], 'positive'))
			.some((aan: boolean) => aan));

	const containsNegative = updatedAnswers.some(x => _isValueAnswer(x.answerText, 'negative')) || containsAutoNegative;
	const containsPositive = updatedAnswers.some(x => _isValueAnswer(x.answerText, 'positive')) || containsAutoPositive;

	const customExpression = expression == null ? (newValue: boolean | null) => newValue : expression;
	
	// console.debug("[CALCULATION]", { hasPositive: containsPositive, hasNegative: containsNegative, section: isPositiveSectionAnswer});
	// if (!containsNegative && !containsPositive && isPositiveSectionAnswer != null) {
	// 	return expression ? expression(null) : null;
	// }
	//
	// if (containsNegative && !containsPositive && !!isPositiveSectionAnswer) {
	// 	return expression ? expression(false) : false;
	// }
	//
	// if (!containsNegative && containsPositive && !isPositiveSectionAnswer) {
	// 	return expression ? expression(true) : true;
	// }
	
	if (!containsNegative && !containsPositive) return customExpression(null);
	if (containsNegative) return customExpression(false);
	if (containsPositive) return customExpression(true);
	
	return isPositiveSectionAnswer;
}