import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import {IParamInfo} from 'interfaces';
import {observer} from 'mobx-react';
import {IContextualMenuProps} from '@fluentui/react/lib/ContextualMenu';
import {ActiveIconColorStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {IconButton, MessageBarType, useTheme} from '@fluentui/react';

import {takeUntil} from 'rxjs';
import {useRulesetStore} from '../../RulesetLayoutPage';
import {useIsDestroy} from 'utils';
import {useBoolean} from '@fluentui/react-hooks';
import {BaseDialog} from 'components';
import {VariableForm} from './VariableForm';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from 'constants/forms';
import {VariableModal} from '../../Rules/ScopedVariables/components/VariableModal';
import {useNotifications} from "../../../../../components/notifications";

interface Props {
    variable: IParamInfo;
}

export const VariablesActions: FunctionComponent<Props> = observer(({variable}: Props) => {
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {deleteGlobalVariable, editGlobalVariable, ruleset, loadRuleset} = useRulesetStore();
    const [isDeleteModalOpen, {toggle: toggleIsDeleteModalOpen}] = useBoolean(true);
    const [isEditModalOpen, {toggle: toggleIsEditModalOpen}] = useBoolean(false);
    const {control, handleSubmit, formState, setValue, reset, clearErrors} = useForm<IParamInfo>({
        ...DefaultFormSettings,
        defaultValues: {name: variable.name || '', expression: variable.expression || ''},
    });
    const [loading, setLoading] = React.useState<boolean>(false);

    const isDestroy = useIsDestroy();
    const theme = useTheme();
    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'edit',
                text: formatMessage({id: 'edit'}),
                iconProps: {iconName: 'Edit'},
                onClick: toggleIsEditModalOpen,
            },
            {
                key: 'delete',
                text: formatMessage({id: 'delete'}),
                iconProps: {
                    iconName: 'Delete',
                    styles: ActiveIconColorStyles(theme.palette.red),
                },
                onClick: toggleIsDeleteModalOpen,
            },
        ],
    };

    const onDelete = () => {
        deleteGlobalVariable(ruleset?.id!, variable)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                showNotification({
                    name: formatMessage({id: 'success'}),
                    type: MessageBarType.success,
                    description: formatMessage({id: 'successVariableDeleteMessage'}, {name: variable.name}),
                });
                clearErrors('expression');
                clearErrors('name');
                toggleIsDeleteModalOpen();
                loadRuleset();
            });
    };

    const onEditVariable = (updated: IParamInfo) => {
        setLoading(true);
        editGlobalVariable(ruleset?.id!, updated)
            .pipe(takeUntil(isDestroy))
            .subscribe(
                () => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successVariableUpdatedMessage'}, {name: variable.name}),
                    });
                    clearErrors('expression');
                    clearErrors('name');
                    toggleIsEditModalOpen();
                    loadRuleset();
                    setLoading(false);
                },
                (error) => {
                    const nameExist = error?.response?.data?.code === 125030;
                    showNotification({
                        name: formatMessage({id: 'error'}),
                        type: MessageBarType.error,
                        description: formatMessage({id: nameExist ? 'variableNameAlreadyExist' : 'somethingWentWrong'}),
                    });
                    setLoading(false);
                    reset();
                    setValue('id', variable.id);
                    setValue('deleted', variable.deleted);
                }
            );
    };

    useEffect(() => {
        if (variable) {
            setValue('id', variable.id);
            setValue('name', variable.name);
            setValue('expression', variable.expression);
            setValue('deleted', variable.deleted);
        }
    }, [variable, setValue]);

    return (
        <>
            <IconButton onRenderMenuIcon={() => <div/>} menuProps={menuProps} iconProps={{iconName: 'More'}}/>

            <BaseDialog
                title={formatMessage({id: 'deleteVariableDialogTitle'})}
                subText={formatMessage({id: 'deleteVariableDialogSubText'})}
                toggleHidden={toggleIsDeleteModalOpen}
                hidden={isDeleteModalOpen}
                onAgree={onDelete}
            />

            {isEditModalOpen && (
                <VariableModal
                    loading={loading}
                    isOpen={isEditModalOpen}
                    disabled={!formState.isDirty}
                    title={formatMessage({id: 'addNewGlobalVariable'})}
                    onDismiss={toggleIsEditModalOpen}
                    onSubmit={handleSubmit(onEditVariable)}
                    btnTitle={formatMessage({id: 'save'})}>
                    <VariableForm control={control} onSubmit={handleSubmit(onEditVariable)} formState={formState}/>
                </VariableModal>
            )}

            {/* <Modal isOpen={isEditModalOpen} onDismiss={toggleIsEditModalOpen} title={formatMessage({ id: 'addNewGlobalVariable' })}>
                <VariableForm control={control} onSubmit={handleSubmit(onEditVariable)} formState={formState} />
                <ModalFooter horizontalAlign={'end'}>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit(onEditVariable)}
                        text={formatMessage({ id: 'save' })}
                    />
                </ModalFooter>
            </Modal> */}
        </>
    );
});
