import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {mergeStyleSets, Spinner, SpinnerSize, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {
    AdministrationAnswerCheckbox,
    AdministrationPostDispatchButton,
    AdministrationResetDispatchButton,
    AdministrationUploadButton
} from "./AdministrationButtons";
import { _GetWorkContextQueryKey, PortalRole, useTenantInfo, useWorkContext } from "../../../../../../providers";
import {InstanceType} from "../../../../../../interfaces";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useGetDispatchAuditInfo} from "../AuditQueries/hooks/useGetDispatchAuditInfo";
import {useProcedureContext} from "../../../ProceduresContent";
import {AdministrationDispatchButton, AdministrationPublishInitialButton} from "./components";
import { queryClient } from "../../../../../../utils";

export const AdministrationProcedureTemplate: FunctionComponent = () => {
    const {formatMessage} = useIntl();

    const {tenant} = useTenantInfo();
    const {
        setIsJobPortalEnabled,
        setIsContraventionReportSectionEnabled,
        isInRoleOrSuperAdministrator,
        workContext
    } = useWorkContext();
    const {jobId} = useJobContext();
    const {items, refresh: refreshProcedures} = useProcedureContext();

    const {auditQueriesInfo, refetch, isLoading, isRefetching} = useGetDispatchAuditInfo(jobId);
    const [disabled, setDisabled] = useState<boolean>(true);

    const refreshSection = useCallback(async () => {
        await refetch();
        refreshProcedures();
    }, []);

    const classNames = mergeStyleSets({
        stack: {
            alignItems: 'first baseline',
            '> .ms-StackItem, button': {
                minWidth: '250px'
            }
        }
    })

    const onEnableContraventionTabAnswerChange = (newValue: boolean) => {
        console.debug("[ADMIN_TMPL::CONTRAVENTION_ANSWER::CHANGE]", newValue);
        setIsContraventionReportSectionEnabled(newValue)
    }

    const onEnableAllTabsAnswerChange = (newValue: boolean) => {
        console.debug("[ADMIN_TMPL::ALL_TABS::CHANGE]", newValue);
        setIsJobPortalEnabled(newValue)
    }

    useEffect(() => {
        setDisabled(!workContext?.job?.isDispatched || !isInRoleOrSuperAdministrator(PortalRole.PowerUser, PortalRole.Administrator))
    }, [workContext?.job?.isDispatched]);

    if (isLoading || isRefetching) {
        return (
            <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                <Spinner size={SpinnerSize.large} label={"Loading"}/>
            </Stack>
        );
    }

    if (tenant?.TypeDescription === InstanceType.AuditReadyPortal) {
        return (
            <>to be implemented</>
        );
    }

    return (
        <Stack tokens={{childrenGap: 16}} horizontal>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <AdministrationAnswerCheckbox itemId={items[1].id}
                                                      label={formatMessage({id: 'enableAllTab'})}
                                                      checked={items[1].isAnswerChecked}
                                                      disabled={disabled}
                                                      onChange={onEnableAllTabsAnswerChange}/>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{childrenGap: 16}} className={classNames.stack}>
                    <Stack.Item>
                        <Stack tokens={{childrenGap: 16}} horizontal className={classNames.stack}>
                            <Stack.Item>
                                <AdministrationPublishInitialButton/>
                            </Stack.Item>
                            {auditQueriesInfo?.data?.arSentDate && <Stack.Item>
                                <AdministrationResetDispatchButton onSuccess={async () => {
                                    refreshSection()
                                    await queryClient.invalidateQueries(_GetWorkContextQueryKey);
                                }}/>
                            </Stack.Item>}
                            <Stack.Item>
                                <AdministrationPostDispatchButton
                                    canPostDispatch={auditQueriesInfo?.data?.canPostDispatch ?? false}/>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{childrenGap: 16}} horizontal className={classNames.stack}>
                            <Stack.Item>
                                <AdministrationDispatchButton/>
                            </Stack.Item>
                            <Stack.Item>
                                <AdministrationUploadButton/>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};