import React, {FunctionComponent, useMemo, useState} from 'react';
import {IconButton, Stack, Text} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {getFlatProcedures, getOpenOnStartupIds} from 'utils';
import {ProceduresDataTable, Attachment, useProcedureContext, JobComment} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {CalculatorTemplate, getTypeByLink} from "../../Calculators";


export const GridWithoutAnswersProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {formatMessage} = useIntl();

    const [expandedItemIds, setExpandedItemIds] = useState<number[]>(getOpenOnStartupIds(items));
    const rows = useMemo<any[]>(() => getFlatProcedures(items, expandedItemIds), [items, expandedItemIds]);

    const onExpand = (id: number) => setExpandedItemIds(prev => [...prev, id]);
    const onCollapse = (id: number) => setExpandedItemIds(prev => prev.filter(expandedItemId => expandedItemId !== id));

    return (
        <ProceduresDataTable
            items={rows}
            isLoading={isLoading}
            columns={[
                {
                    key: 'actions',
                    name: formatMessage({id: 'actions'}),
                    minWidth: 100,
                    maxWidth: 128,
                    fieldName: 'actions',
                    onRender: (items) => (
                        <Stack horizontal>
                            <Stack.Item styles={{root: {width: 32}}}>
                                {items?.showExpander &&
                                    <IconButton iconProps={{iconName: items.isOpen ? 'Remove' : 'Add'}}
                                                onClick={() => items.isOpen ? onCollapse(items.id) : onExpand(items.id)}/>}
                            </Stack.Item>
                            {items?.showAttachment &&
                                <Attachment itemId={items?.id} hasAttachments={items?.hasAttachments}/>}
                            {items?.showComment && <JobComment itemId={items?.id} hasComments={items?.hasComments}/>}
                            {items?.isIconCalc && <CalculatorTemplate type={getTypeByLink(items.link)}/>}

                        </Stack>
                    ),
                },
                {
                    key: 'auditProcedure',
                    name: formatMessage({id: 'auditProcedure'}),
                    minWidth: 200,
                    fieldName: 'auditProcedure',
                    onRender: (items) => (
                        <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                            <Text>{items.number}</Text>
                            <SanitizedText data={items.title} styles={{
                                root: {
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellypsis'
                                }
                            }}/>
                        </Stack>
                    ),
                },
            ]}
        />
    );
};