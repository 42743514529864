import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IJobTimesheetItem} from "../interfaces";

function useGetJobTimesheet(jobGuid: string) {
    const context = useContext(Context);

    const {
        data: response,
        isLoading,
        refetch,
        isRefetching
    } = useQuery(["getJobTimesheet", jobGuid], () => axios.get<IJobTimesheetItem[]>(`${context?.config?.timesheetUrl}/api/1.0/job/${jobGuid}/timesheets`));

    return {response, isLoading, refetch, isRefetching};
}

export {useGetJobTimesheet};