import {Card, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {Control} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useMemo} from 'react';
import {IChangeClientInfoCommand} from "..";
import {ClientContactInfo} from "../../../../shared";

type AccountTabType = {
    control: Control<IChangeClientInfoCommand>;
    contacts?: ClientContactInfo[];
};

export const AccountInfoTab = ({control, contacts}: AccountTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();

    const options = useMemo(() => {
        if (contacts) {
            return contacts.map((contact) => {
                return {
                    text: `${contact.firstName} ${contact.lastName}`,
                    key: contact.guid,
                };
            });
        }
        return [];
    }, [contacts]);

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        required
                        name='clientName'
                        label={formatMessage({id: 'clientName'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        required
                        name='mainPhone'
                        label={formatMessage({id: 'mainPhone'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        required
                        name='primaryContact'
                        label={formatMessage({id: 'primaryContact'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                        options={options}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={options}
                        name='secondaryContact'
                        label={formatMessage({id: 'secondaryContact'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
