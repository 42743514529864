import { FunctionComponent } from 'react';
import {observer} from 'mobx-react';
import {stores} from 'stores';
import {SignIn} from './SignIn';
import { useAuthContext } from "../providers";

interface Props {
    children: JSX.Element;
}

export const RequireAuth: FunctionComponent<Props> = observer(({ children }: Props) => {
    const { isAuthorized } = useAuthContext();
    
    if (isAuthorized) {
        return children;
    }

    return (
        <SignIn
            getToken={stores.AuthStore.getToken}
            signIn={stores.AuthStore.signIn}
            isSigningIn={stores.AuthStore.isSigningIn}
            isSigningOut={stores.AuthStore.isSigningOut}
        />
    );
});
