import {Stack} from '@fluentui/react';
import {useContext, useEffect, useState} from 'react';
import {Pagination} from 'components';
import {ExportType, useExportData} from 'hooks/useExportData';
import {Context} from 'utils';
import {useIntl} from "react-intl";
import {IFundPaginationFilter, useGetFundsByFilter} from "./hooks";
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {FundsAddItemModal, FundsDataTable} from "./components";
import {DataTableFilter} from "../shared";

interface IFundsProps {
    clientGuid?: string | null;
}

export const FundsWorkflowPage = (props: IFundsProps) => {
    const {formatMessage} = useIntl();
    const [filters, setFilters] = useState<IFundPaginationFilter>({
        pageSize: 10,
        page: 1,
        clientGuid: props.clientGuid,
        orderBy: "Name",
        orderDirection: 'asc',
        status: EntityStatus.Active,
    });
    const context = useContext(Context);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const {dataFundsFilter: fundsData, isLoadingFundsFilter} = useGetFundsByFilter(filters);
    const {exportData} = useExportData(`${context?.config?.clientUrl}/api/1.0/funds`, filters);

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters(prev => ({...prev, pageSize: pageSize, page: page}));
    };
    const onSearch = (newValue: string) => {
        setFilters(prev => ({...prev, name: newValue}));
    };

    const onChangeStatus = (newStatus: EntityStatus | null) => {
        setFilters(prev => ({...prev, status: newStatus}));
    };

    const [pagination, setPagination] = useState<{ itemsCount: number; total: number }>();

    useEffect(() => {
        if (fundsData?.data) {
            setPagination({
                itemsCount: fundsData?.data.shownItemsCount * filters.pageSize,
                total: fundsData?.data.totalItemsCount,
            });
        }
    }, [fundsData?.data]);

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item>
                <DataTableFilter onAddClick={() => setShowAddModal(true)}
                                 search={{
                                     onSearch: onSearch,
                                     onClear: () => onSearch('')
                                 }}
                                 status={{selectedStatus: filters.status || null, changeStatus: onChangeStatus}}
                                 export={{
                                     onPdfClick: () => exportData(ExportType.Pdf),
                                     onExcelClick: () => exportData(ExportType.Excel)
                                 }}/>
                <FundsAddItemModal
                    isOpen={showAddModal}
                    clientId={props.clientGuid || ''}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({id: 'addingNewItem'})}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <FundsDataTable fundData={fundsData?.data.items || []}
                                setSort={(value) => setFilters(prev => ({
                                    ...prev,
                                    orderBy: value.orderBy,
                                    orderDirection: value.orderDirection
                                }))}
                                isLoading={isLoadingFundsFilter}/>
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Pagination
                    itemsCount={pagination?.itemsCount}
                    total={pagination?.total}
                    page={filters.page}
                    pageSize={filters.pageSize}
                    onChange={onPaginationChange}
                />
            </Stack.Item>
        </Stack>
    );
};
