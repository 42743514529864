import { IColumn, IContextualMenuItem, mergeStyleSets, MessageBarType, SelectionMode, Stack } from "@fluentui/react";
import { DataTable, SanitizedText, useContextMenu } from 'components';
import { Attachment, GeneratedAnswer, JobComment, useSectionContext } from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatDate, getGrouppedReports } from 'utils';
import { AnswerControlType, TableType } from '../../../../../../enums';
import { ReportTemplateTypeProps } from '../Types';
import { ReviewType } from '../../../../enums';
import { logger } from '../../../../../../services';
import { useCreateReviewItem } from '../../../../hooks';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useNotifications } from '../../../../../../components/notifications';
import { useJobRedirects } from '../../shared';
import { getDepthPaddingStyles, ReportTable } from "../Table/ReportTable";
import { _ExtendedHeaderRowHeight } from "../shared";

export const InvestmentIncomeComparision = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const buildReportItems = (items: IReport[]): IReport[] => {
        let _tick = -Date.now();
        return (items ?? []).reduce((acc: IReport[], current: IReport, currentIndex: number) => {
            acc.push({
                ...current,
                id: current.id || _tick--,
                children: buildReportItems(current.children ?? []),
            });
            return acc;
        }, []);
    };

    const reportItems = useMemo(() => buildReportItems(items), [items]);
    
    const classNames = mergeStyleSets({
        headerCellFirst: {
            '&.ms-DetailsRow-cell': {
                overflow: 'unset'
            }
        },
        headerCell: {
            '&.ms-DetailsRow-cell:not(:has(span))': {
                borderLeft: 'none',
            }
        }
    })

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                className: classNames.headerCellFirst,
                onRender: (item) => {
                    if (item.isHeadingRow) {
                        return (
                            <SanitizedText data={item.groupName} isHeadingRow={item.isHeadingRow} styles={getDepthPaddingStyles(item?.depth)} textWhiteSpace={'nowrap'} />
                        );
                    }

                    return (
                        <Stack horizontal>
                            <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                            <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                        </Stack>
                    );
                },
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                className: classNames.headerCell,
                onRender: (item, _, column) => {
                    if (item.isHeadingRow) {
                        return <></>
                    }
                    
                    return (
                        <SanitizedText
                            data={formatDate(item[column?.fieldName || 0])}
                            isHeadingRow={item?.isHeadingRow}
                            format={item?.format}
                        />
                    )
                }
            },
            {
                key: 'incomeType',
                name: formatMessage({ id: 'incomeType' }),
                minWidth: 230,
                maxWidth: 280,
                fieldName: 'incomeType',
                className: classNames.headerCell,
                onRender: (item, _, column) => {
                    if (item.isHeadingRow) {
                        return <></>
                    }

                    return (
                        <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />
                    )
                }
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed',
                className: classNames.headerCell,
                onRender: (item) => {
                    if (item.isHeadingRow) {
                        return <></>
                    }

                    return (
                        <Stack verticalAlign='center' grow>
                            <GeneratedAnswer
                                itemId={item?.id}
                                tableType={TableType[item?.reportType as keyof typeof TableType]}
                                value={item?.answerText}
                                auto={item?.isAuto}
                                disabled={item?.isAuto}
                                answerControlType={item?.answerControlType}
                            />
                        </Stack>
                    )
                }
            },
            {
                key: 'transactionDetailIncomeDetailTotalIncome',
                name: formatMessage({ id: 'transactionDetailIncomeDetailTotalIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'transactionDetailIncomeDetailTotalIncome',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'transactionDetailIncomeDetailFrankingCredit',
                name: formatMessage({ id: 'transactionDetailIncomeDetailFrankingCredit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'transactionDetailIncomeDetailFrankingCredit',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'announcementDetailEligibleUnits',
                name: formatMessage({ id: 'announcementDetailEligibleUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'announcementDetailEligibleUnits',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='round'
                    />
                ),
            },
            {
                key: 'announcementDetailAmountPerUnit',
                name: formatMessage({ id: 'announcementDetailAmountPerUnit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'announcementDetailAmountPerUnit',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'announcementDetailEstimatedIncomeTotalIncome',
                name: formatMessage({ id: 'announcementDetailEstimatedIncomeTotalIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'announcementDetailEstimatedIncomeTotalIncome',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'announcementDetailEstimatedIncomeFrankingCredit',
                name: formatMessage({ id: 'announcementDetailEstimatedIncomeFrankingCredit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'announcementDetailEstimatedIncomeFrankingCredit',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isTotalRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} header={{ rowHeight: _ExtendedHeaderRowHeight }} items={reportItems} enableShimmer={isFetching} openAll={true} />;
};
