import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card, ControlledDropdown, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';

interface Props {
    onDismiss: () => void;
}

interface IModalCommand {
  client: string;
  year: string;
}

export const QuerySummaryModal: FunctionComponent<Props> = observer(({ onDismiss }: Props) => {
    const { control, handleSubmit } = useForm<IModalCommand>(DefaultFormSettings);
    const { formatMessage } = useIntl();
    const stackItemStyles = { root: { width: 'calc(50% - 16px)' } };

    const onSubmit = (data: IModalCommand) => {
        console.log('created new Item');
        onDismiss();
    };
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={[]}
                            name='client'
                            label={formatMessage({ id: 'client' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={[]}
                            name='year'
                            label={formatMessage({ id: 'year' })}
                            control={control}
                            rules={{
                                required: formatMessage({ id: 'requiredField' }),
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Card>
            <ModalFooter horizontalAlign='end'>
                <PrimaryButton onClick={handleSubmit(onSubmit)} text={formatMessage({ id: 'generate' })} />
            </ModalFooter>
        </Stack>
    );
});