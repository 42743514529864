import {Stack} from '@fluentui/react';
import {Card} from 'components';
import {useContext} from 'react';
import {BlackClientPortalDashboard, WhiteClientPortalDashboard} from "./components";
import {Context} from "utils";
import {DashboardTabsEnum} from "../../../shared/Dashboard";

export const AdministrationDashboardsPage = () => {
    const context = useContext(Context);

    const getTabContent = (): JSX.Element => {
        const selectedTab = context?.tenantInfo?.IsWhiteLabel ? DashboardTabsEnum.ClientPortalWhite : DashboardTabsEnum.ClientPortalBlack;
        switch (selectedTab) {
            case DashboardTabsEnum.ClientPortalBlack:
                return <BlackClientPortalDashboard/>;
            case DashboardTabsEnum.ClientPortalWhite:
                return <WhiteClientPortalDashboard/>
            default:
                return <></>;
        }
    };

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <Card styles={{
                    root: {
                        height: '100%',
                        overflow: 'auto',
                        boxSizing: 'border-box'
                    }
                }}>{getTabContent()}</Card>
            </Stack.Item>
        </Stack>
    );
};
