import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { PerfomanceMaterially } from './useGetPerfomanceMaterially';

export type PerfomanceMaterialityReq = {
    performanceMaterialitySettings: PerfomanceMaterially;
};

/**
 * Mutation query for update Job Risk Settings data in Portal Settings.
 * First table in page Risk Matrix Settings
 */

function useUpdatePerfomanceMateriality() {
    const context = React.useContext(Context);

    const { mutate: updatePerfomanceMateriality, isLoading: updatePerfomanceMaterialityLoading } = useMutation(
        (data: PerfomanceMaterialityReq) =>
            axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/performanceMateriality`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getPerfomanceMaterially'),
        }
    );

    return { updatePerfomanceMateriality, updatePerfomanceMaterialityLoading };
}

export { useUpdatePerfomanceMateriality };
