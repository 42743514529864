/** Signature of a logging function */
export interface ILogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface ILogger {
    log: ILogFn;
    warn: ILogFn;
    error: ILogFn;
}

/** Logger which outputs to the browser console */
class ConsoleLogger implements ILogger {
    log(message?: any, ...optionalParams: any[]): void {
        console.log(`${new Date().toISOString()} [INFO] ${message}`, ...optionalParams);
    }

    warn(message?: any, ...optionalParams: any[]): void {
        console.warn(`${new Date().toISOString()} [WARNING] ${message}`, ...optionalParams);
    }

    debug(message?: any, ...optionalParams: any[]): void {
        console.debug(`${new Date().toISOString()} [DEBUG] ${message}`, ...optionalParams);
    }

    error(message?: any, ...optionalParams: any[]): void {
        console.error(`${new Date().toISOString()} [ERROR] ${message}`, ...optionalParams);
    }
}

export const logger = new ConsoleLogger();