export enum PortalRole {
	None,
	BasicUser,
	Standard,
	ReviewerUser,
	PowerUser,
	Administrator,
	CPUser,
	CPAdministrator,
	SuperAdministrator,
	SuperUser,
	AuditorCPUser,
	ExternalAccess,

	TestUserJunctionRole
}