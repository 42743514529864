import { FunctionComponent, useEffect, useState } from 'react';
import {
    DialogType,
    IDialogContentProps,
    mergeStyleSets,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useAcceptAudit, useAcceptAuditIssues, useGetAuditAcceptance } from './hooks';
import { useJobContext } from '../../../../../../JobPortalLayoutPage';
import { formatDate } from '../../../../../../../../utils';
import { useResetAuditAcceptance } from './hooks/useResetAuditAcceptance';
import { DialogMessage } from '../../../../../../../../components';
import { useBoolean } from '@fluentui/react-hooks';
import { useTabContext } from 'pages/JobPortal';

type AcceptanceControlsProps = {};

type DialogConfig = { props: IDialogContentProps; onClick: Function };

export const AcceptanceControls: FunctionComponent<AcceptanceControlsProps> = ({ ...props }: AcceptanceControlsProps) => {
    const { formatMessage } = useIntl();
    const { schemes } = useTheme();
    const { isTabEnabled } = useTabContext();
    const { jobId } = useJobContext();

    const { auditAcceptance, isLoading, isRefetching } = useGetAuditAcceptance(jobId);
    const { reset, isResetting } = useResetAuditAcceptance(jobId);
    const { accept, isAccepting } = useAcceptAudit(jobId);
    const { acceptIssues, isAcceptingIssues } = useAcceptAuditIssues(jobId);

    const [issueAcceptButtonDisabled, setIssueAcceptButtonDisabled] = useState<boolean>(false);
    const [acceptButtonDisabled, setAcceptButtonDisabled] = useState<boolean>(false);

    const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
        onClick: () => {},
        props: {
            theme: schemes?.default,
            type: DialogType.normal,
            title: formatMessage({ id: 'warning' }),
            closeButtonAriaLabel: 'Close',
            subText: '',
        },
    });
    const [isDialogShown, { toggle: toggleDialog, setTrue: showDialog }] = useBoolean(false);

    useEffect(() => {
        const isInAction = isResetting || isAccepting || isAcceptingIssues;

        setIssueAcceptButtonDisabled(!!auditAcceptance?.isAuditAccepted || !!auditAcceptance?.isAuditIssuesAccepted || isInAction);
        setAcceptButtonDisabled(!!auditAcceptance?.isAuditAccepted || isInAction);
    }, [auditAcceptance, isResetting, isAccepting, isAcceptingIssues]);

    const onResetClicked = () => {
        if (!isTabEnabled) return;

        setDialogConfig((prev) => ({
            onClick: reset,
            props: {
                ...prev.props,
                subText: formatMessage({ id: 'auditAcceptanceResetMessage' }),
            },
        }));
        showDialog();
    };

    const onAcceptClicked = () => {
        if (!isTabEnabled) return;

        setDialogConfig((prev) => ({
            onClick: accept,
            props: {
                ...prev.props,
                subText: formatMessage({ id: 'auditAcceptanceAcceptMessage' }),
            },
        }));
        showDialog();
    };

    const onAcceptIssuesClicked = () => {
        if (!isTabEnabled) return;

        setDialogConfig((prev) => ({
            onClick: acceptIssues,
            props: {
                ...prev.props,
                subText: formatMessage({ id: 'auditAcceptanceAcceptIssuesMessage' }),
            },
        }));
        showDialog();
    };

    const classNames = mergeStyleSets({
        button: {
            minWidth: 200,
        },
    });

    if (isLoading || isRefetching) {
        return <Spinner></Spinner>;
    }

    return (
        <>
            <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    {auditAcceptance!.isAuditAccepted && (
                        <PrimaryButton className={classNames.button} disabled={isResetting || !isTabEnabled} onClick={onResetClicked}>
                            {isResetting ? <Spinner size={SpinnerSize.small} /> : formatMessage({ id: 'auditAcceptanceReset' })}
                        </PrimaryButton>
                    )}
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontalAlign={'center'} tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <PrimaryButton
                                className={classNames.button}
                                disabled={issueAcceptButtonDisabled || !isTabEnabled}
                                onClick={onAcceptIssuesClicked}>
                                {isAcceptingIssues ? (
                                    <Spinner size={SpinnerSize.small} />
                                ) : (
                                    formatMessage({ id: 'auditAcceptanceAcceptIssues' })
                                )}
                            </PrimaryButton>
                        </Stack.Item>
                        {auditAcceptance?.auditIssuesAccepted && (
                            <Stack.Item>
                                <Text>{formatDate(auditAcceptance!.auditIssuesAccepted.toString(), 'dd/MM/yyyy')}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontalAlign={'center'} tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <PrimaryButton
                                className={classNames.button}
                                disabled={acceptButtonDisabled || !isTabEnabled}
                                onClick={onAcceptClicked}>
                                {isAccepting ? <Spinner size={SpinnerSize.small} /> : formatMessage({ id: 'auditAcceptanceAcceptAudit' })}
                            </PrimaryButton>
                        </Stack.Item>
                        {auditAcceptance?.auditAccepted && (
                            <Stack.Item>
                                <Text>{formatDate(auditAcceptance!.auditAccepted.toString(), 'dd/MM/yyyy')}</Text>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            </Stack>

            <DialogMessage
                onClick={() => {
                    dialogConfig.onClick();
                    toggleDialog();
                }}
                dialogContentProps={dialogConfig.props}
                hidden={!isDialogShown}
                onDismis={toggleDialog}
            />
        </>
    );
};
