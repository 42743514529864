import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {JobInfo} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";
import {NotificationAction, useNotifications} from "../../../../components/notifications";
import {useWorkflows} from "../../WorkflowLayoutPage";

type MutateDataType = {
    clientId: number;
    fundId: number;
    year: number;
};

function useCreateNewJob(onSuccess: () => void) {
    const context = React.useContext(Context);
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {showJobModal} = useWorkflows();
    const {mutate: addNewJob, isLoading} = useMutation(
        (data: MutateDataType) => axios.post<JobInfo>(`${context?.config?.jobUrl}/api/1.0/job`, data, commonHeaders()),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('getJobsByFilter');
                onSuccess();

                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'createdSuccessfully'}),
                    description: formatMessage({id: "entityWasCreated"}, {name: variables.year}),
                    actions: (
                        <NotificationAction
                            onClick={() => {
                                console.debug('Create job id: ' + data.data.id);
                                showJobModal(data.data.id);
                            }}>{formatMessage({id: 'view'})}</NotificationAction>
                    )
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {addNewJob, isLoading};
}

export {useCreateNewJob};
