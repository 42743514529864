import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

function useDeleteUser() {
    const context = React.useContext(Context);

    const { mutate: deleteUser, isLoading, isSuccess } = useMutation((userId: number) =>
        axios.delete(`${context?.config?.userUrl}/api/1.0/User/${userId}`, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
        }
    );

    return { deleteUser, isLoading, isSuccess };
}

export { useDeleteUser };