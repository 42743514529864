import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Spinner, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { VerticalSubmenu } from 'components/VerticalSubmenu';
import { mockedContacts } from 'constants/MockedContacts';
import { ContactTab } from '../ContactTab';
import { useGetClientByGuid } from '../../hooks/useGetClientByGuid';
import { useUpdateClientContact } from '../../hooks/useUpdateClientContact';
import { ClientAuthorizationTemplate } from "./ClientAuthorizationTemplate";

interface Props {
    open: boolean;
    toggle: () => void;
    onSubmit: (data: any) => void;
    clientGuid: string;
}

interface IClientForm {
    contactGuid: string;
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    businessPhone: string;
}

interface IEditClientFormProps {
    clientGuid: string;
    toggle: () => void;
}

const EditClientForm: FunctionComponent<IEditClientFormProps> = ({ clientGuid, toggle }) => {
    const { currentClientData, isCurrentClientLoading } = useGetClientByGuid(clientGuid);

    const { control, handleSubmit, watch, setValue, formState } = useForm<IClientForm>({
        ...DefaultFormSettings,
        defaultValues: {
            contactGuid: '',
            contactId: 0,
            firstName: '',
            lastName: '',
            email: '',
            businessPhone: '',
        },
    });

    useEffect(() => {
        if (!!currentClientData?.data.primaryContact) {
            setValue('contactGuid', currentClientData.data.primaryContact.guid);
            setValue('contactId', currentClientData.data.primaryContact.id);
        }
    }, [currentClientData?.data.primaryContact]);

    const [selectedTab, setSelectedTab] = useState<'contact' | 'apiAuthorisation'>('contact');

    const links = [
        {
            name: 'Contact',
            key: 'contact',
            url: '',
        },
        {
            name: 'API Authorisation',
            key: 'apiAuthorisation',
            url: '',
        },
    ];

    const { formatMessage } = useIntl();
    const { update, isUpdating } = useUpdateClientContact();

    const onSubmit = (data: IClientForm) => {
        update({
            clientGuid: currentClientData?.data.guid ?? '',
            contactGuid: data.contactGuid,
            isPrimaryContact: true
        }, {
            onSuccess: () => {
                toggle();
            }
        });
    }

    if(isCurrentClientLoading || isUpdating){
        return <Spinner />;
    }

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <VerticalSubmenu setSelected={setSelectedTab} defaultValue={selectedTab} links={links} />
                    <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                        {selectedTab === 'contact' && (
                            <ContactTab control={control} watch={watch} setValue={setValue} contacts={currentClientData?.data.contacts ?? []} primaryContact={currentClientData?.data.primaryContact} />
                        )}
                        {selectedTab === 'apiAuthorisation' && (
                            <Stack.Item>
                                <ClientAuthorizationTemplate clientGuid={clientGuid}></ClientAuthorizationTemplate>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                <PrimaryButton
                    disabled={!formState.isDirty}
                    onClick={handleSubmit((data) => onSubmit(data))}
                    text={formatMessage({ id: 'save' })}
                />
            </ModalFooter>
        </Stack>
        );
}

export const EditClientModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Modal title={formatMessage({ id: 'editClient' })} isOpen={props.open} onDismiss={props.toggle}>
            <EditClientForm clientGuid={props.clientGuid} toggle={props.toggle} />
        </Modal>
    );
};
