import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IRiskNote } from './interfaces';
import { useIntl } from 'react-intl';
import { ActionButton, DialogType, IconButton, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import { useChangeRiskNotePermState, useChangeRiskNoteText, useCreateRiskNote, useDeleteRiskNote, useGetRiskNotes } from './hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { useJobContext } from '../../../../../JobPortalLayoutPage';
import { DefaultFormSettings } from '../../../../../../../constants';
import { ControlledTextField, DialogMessage, ITableColumn } from 'components';
import { PermanentCheckBox } from '../../../../PermanentCheckBox';
import { ProceduresDataTable } from '../../../../ProceduresDataTable';
import { TableType } from 'enums';
import { IRiskProcedure } from '../interfaces';
import { useBoolean } from '@fluentui/react-hooks';
import { useTabContext } from 'pages/JobPortal';

type RiskNotesProps = {
    note: IRiskProcedure;
};
export const RiskNotes = ({ note }: RiskNotesProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { jobId } = useJobContext();
    const { isTabEnabled } = useTabContext();
    const { createAsync, isCreating } = useCreateRiskNote();
    const { update, isUpdating } = useChangeRiskNoteText();
    const { deleteNote, isDeleting } = useDeleteRiskNote();
    const { updateAsync: changePermAsync, isUpdating: isUpdatingPerm } = useChangeRiskNotePermState();
    const { data } = useGetRiskNotes(jobId);
    const [notes, setNotes] = useState<IRiskNote[]>([]);
    const [editingIndex, setEditingIndex] = useState<number>();
    const { control, getValues, setValue } = useForm<{ notes: IRiskNote[] }>(DefaultFormSettings);
    const { fields, append, remove } = useFieldArray({
        name: 'notes',
        control: control,
    });

    const [currentItem, setCurrentItem] = useState<number | null>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const handleOnAddClick = async () => {
        const newNote = await createAsync({
            jobId: jobId,
            itemId: note.id,
        }).then((x) => x.data);

        setNotes([...notes, newNote]);
        append(newNote);
    };
    const handleOnDeleteClick = useCallback(
        (index?: number) => {
            if (index === undefined) return;

            setLastUpdatedIndex(index);
            const note = getValues(`notes.${index}`);
            deleteNote(note, {
                onSuccess: () => {
                    setNotes(notes.filter((n) => n.id !== note.id));
                    remove(index);
                },
            });
        },
        [notes]
    );

    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const note = getValues(`notes.${editingIndex}`);

        if (note.text === notes.find((n) => n.id === note.id)?.text) {
            return;
        }

        setLastUpdatedIndex(editingIndex);
        update(
            {
                id: note.id,
                jobId: note.jobId,
                text: note.text,
            },
            {
                onSuccess: () => {
                    setNotes([
                        ...notes.slice(0, editingIndex),
                        { ...notes[editingIndex], text: note.text },
                        ...notes.slice(editingIndex + 1),
                    ]);
                },
            }
        );
    }, [editingIndex, getValues, notes]);

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
            if (!isTabEnabled) return;

            handleOnAccept();
            setEditingIndex(index);
            event.preventDefault();
        },
        [isTabEnabled]
    );

    const handleOnPermChange = async (index: number, isPerm: boolean) => {
        const note = getValues(`notes.${index}`);
        await changePermAsync({
            id: note.id,
            jobId: note.jobId,
            isPermanent: isPerm,
        });
        setValue(`notes.${index}.isPerm`, isPerm);
    };

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'actions',
                name: '',
                minWidth: 80,
                maxWidth: 80,
                onRender: (item: IRiskNote, index) => (
                    <>
                        {index === undefined && <></>}

                        <Stack grow horizontal horizontalAlign={'center'}>
                            {lastUpdatedIndex === index && (isUpdating || isDeleting) && <Spinner />}
                            {editingIndex !== index && !((lastUpdatedIndex === index && (isUpdating || isDeleting)) || !isTabEnabled) && (
                                <IconButton
                                    iconProps={{ iconName: 'delete' }}
                                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                                    onClick={() => {
                                        setCurrentItem(index!);
                                        toggleDeleteDialog();
                                    }}
                                />
                            )}
                        </Stack>
                    </>
                ),
            },
            {
                key: 'note',
                name: 'Notes',
                minWidth: 150,
                onRender: (item: IRiskNote, index) => (
                    <Stack grow>
                        {index !== undefined && editingIndex === index && !isDeleting && (
                            <ControlledTextField
                                id={`RiskNotes-${item.id}-text`}
                                autoFocus
                                disabled={isUpdating && index === lastUpdatedIndex}
                                name={`notes.${index}.text`}
                                control={control}
                            />
                        )}
                        {index !== undefined && editingIndex !== index && (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        zIndex: 100,
                                    }}
                                    onClick={(event) => {
                                        onEditClick(event, index);
                                    }}></div>
                                <TextField
                                    styles={{
                                        field: { color: theme.schemes?.default?.semanticColors.bodyText },
                                    }}
                                    disabled
                                    value={getValues(`notes.${index}.text`) || ''}
                                />
                            </>
                        )}
                    </Stack>
                ),
            },
            {
                key: 'perm',
                name: 'Perm',
                minWidth: 100,
                maxWidth: 100,
                onRender: (item: IRiskNote, index) => (
                    <Stack horizontalAlign={'center'} grow>
                        {index !== undefined && (
                            <PermanentCheckBox
                                itemId={item.id}
                                disabled={isUpdatingPerm}
                                checked={getValues(`notes.${index}.isPerm`)}
                                onUpdate={(newValue) => handleOnPermChange(index, newValue)}
                                tableType={TableType.RiskMatrixNote}
                            />
                        )}
                    </Stack>
                ),
            },
        ];
    }, [notes, editingIndex, isDeleting, isUpdating]);

    useEffect(() => {
        setNotes(data || []);
        setValue('notes', data || []);
    }, [data]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('RiskNotes')) return;

            handleOnAccept();
            setEditingIndex(undefined);
        },
        [editingIndex]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <Stack horizontalAlign={'center'} grow tokens={{ childrenGap: 8 }}>
            <ProceduresDataTable
                header={{ horizontalAlign: 'start' }}
                contextMenuOptions={{ disable: true }}
                items={fields}
                columns={columns}
                hideIfEmpty={false}
            />
            <Stack.Item>
                {!isCreating && (
                    <ActionButton
                        styles={{ textContainer: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                        disabled={isCreating || !isTabEnabled}
                        text={formatMessage({ id: 'clickToAddRow' })}
                        iconProps={{ iconName: 'add' }}
                        onClick={handleOnAddClick}
                    />
                )}
                {isCreating && <Spinner />}
            </Stack.Item>

            <DialogMessage
                onClick={() => {
                    toggleDeleteDialog();
                    handleOnDeleteClick(currentItem!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}
            />
        </Stack>
    );
};
