import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from '../../../../../../utils';

type RemoveApprovevrData = {
    email: string;
};

function useRemoveApprover() {
    const context = useContext(Context);

    const { mutate: remove, isLoading: isRemoving } = useMutation(
        (data: RemoveApprovevrData) =>
            axios.put(`${context?.config?.userUrl}/api/1.0/user/approver`, data, {
                ...commonHeaders()
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getExternalConnectionSettings');
            },
        }
    );

    return { remove, isRemoving };
}

export { useRemoveApprover };
