import { Stack } from '@fluentui/react';
import { AuthoriseBGL360API, AuthoriseClassSuperAPI, GeneralSettingsForm } from './components/GeneralSettings';
import { WebSettings } from './components/WebSettings';
import { JobsYearsSettings } from './components/JobsYearsSettings';

/**
 * UI component for General Settings in Portal Settings.
 * all logic in inner components
 */

export const GeneralSettingsPage = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <WebSettings />
            <GeneralSettingsForm />
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <AuthoriseClassSuperAPI />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: '50%' } }}>
                    <AuthoriseBGL360API />
                </Stack.Item>
            </Stack>

            <JobsYearsSettings />
        </Stack>
    );
};
