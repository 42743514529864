import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import { Context, commonHeaders } from 'utils';


type Props = {
    url: string;
};

export type DownloadUrlModel = {
    downloadUrl: string;
}

/**Mutation query for downloading file*/

function useGetDownloadUrl({
    url
}: Props) {
    const getUrl = url.replace('download', 'getDownloadUrl'); 
    const context = React.useContext(Context);

    const {data: downloadUrl, refetch, remove} = useQuery(
        ['getDownloadUrl', url],
        () => axios.get<DownloadUrlModel>(`${getUrl}`, {
            ...commonHeaders(),
        }),
        {
            enabled: false,
        });

    return {downloadUrl, refetch, remove};
}

export {useGetDownloadUrl};
