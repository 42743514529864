import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

interface IGetClientCommentsParams {
  jobId: number;
  tabId: number;
}

export interface IClientComment {
  id?: number;
  tabCommentId?: number;
  description?: string;
  comment?: string;
}

function useGetClientComments({ jobId, tabId }: IGetClientCommentsParams) {
  const context = React.useContext(Context);

  const { data: commentsData, isLoading: isCommentsLoading, refetch: refetchComments, isFetching: isCommentsFetching } = useQuery(
    ['getClientComments', jobId, tabId],
    () => axios.get<IClientComment[]>(
      `${context?.config?.annotationsUrl}/api/1.0/jobs/${jobId}/tabComments/${tabId}`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
     
      retry: false,
    }
  );

  return { commentsData, isCommentsLoading, refetchComments, isCommentsFetching };
}

export { useGetClientComments };