import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {ReviewType} from "../../enums";

type MutateDataType = {
    id: number;
    jobId: number;
    reviewType: ReviewType;
    refNo: string;
    item: string;
    staffComments: string;
    initials: string;

}

function useUpdateReviewItem() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/jobReview/${data.id}`, data));

    return {update, updateAsync, isUpdating};
}

export {useUpdateReviewItem};