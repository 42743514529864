import { MessageBarType } from '@fluentui/react';
import axios, {AxiosError} from 'axios';
import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { stores } from 'stores';
import { commonHeaders, Context, queryClient } from 'utils';
import {useNotifications} from "../../../../components/notifications";

type MutateDataType = {
   timesheetId:number;
   timesheetDate: Date;
   durationMin: number;
};

function useUpdateTimesheetInfo() {
    const context = React.useContext(Context);
    const { formatMessage } = useIntl();
    const {showNotification} = useNotifications();

    const { mutate: updateTimesheet, isLoading: isUpdating } = useMutation(
        (data: MutateDataType) =>
            axios.put(
                `${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets/${data.timesheetId}`,
                data,
                commonHeaders()
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getTimesheetByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Timesheet'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0] || formatMessage({id:'somethingWentWrong'})}`
                })
            }
        }
    );

    return { updateTimesheet, isUpdating };
}

export { useUpdateTimesheetInfo };
