import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';

export const CustomizationLayoutPage = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'customization',
                key: RouterPaths.customization,
                text: formatMessage({ id: 'customization' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
