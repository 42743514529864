export enum ColumnInfo {
    Text = 1,
    Text2 = 2,
    Text3 = 3,
    Checkbox = 4,
    TempText = 5,
    TempCheckbox = 6,
    ActionTaken = 7,
    AnswerText = 8,
    AnswerText1 = 9,
    AnswerText2 = 10,
    AnswerText3 = 11,
    ClearedText = 12,
    
    // Queries Specific
    ContraventionReportRequired = 20,
    QueryCheck = 30
}