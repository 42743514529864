import {IColumn, SelectionMode, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {getDepthPaddingStyles, ReportTable,} from '../Table/ReportTable';
import { SanitizedText, DataTable } from 'components';
import {formatDate, getGrouppedReports, getNestedGroupsByKeys} from 'utils';
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";

export const BGL360GeneralLedger = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const rebuildReport = (items: IReport[] | any[], depth: number = 0): IReport[] => {
        let roots = items.filter(i => i.depth === depth);
        
        return roots.reduce((acc, r, idx, array) => {
            let startIndex = items.indexOf(r) + 1;
            let endIndex = idx === array.length - 1 ? items.length : items.indexOf(array[idx + 1]) + 1;
            let children = startIndex === endIndex ? [] : items.slice(startIndex, endIndex);
            r.children = children.length ? rebuildReport(children, depth + 1) : [];
            acc.push(r);
            return acc;
        }, []);
    }

    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: rebuildReport(items), groupKey: 'accountName' }), [items]);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.accountType && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.accountType && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 128,
                maxWidth: 200,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 300,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'debit',
                name: formatMessage({ id: 'debit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'debit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'credit',
                name: formatMessage({ id: 'credit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'credit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'balance',
                name: formatMessage({ id: 'balance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'balanceIndicator',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'accountType',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign={'center'} />,
            },
        ],
        [formatMessage]
    );


    return <ReportTable initialColumns={columns} 
                        items={reports}
                        groups={groups} 
                        isFetching={isFetching} 
                        expandProps={{ showExpander: false }}
                        openAll={true} />
};
