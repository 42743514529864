import {Bar, ChartProps} from 'react-chartjs-2';
import 'chart.js/auto';
import {useTheme} from '@fluentui/react';

type PropsChartsWidget = {
    dataNumbers: number[];
    groupedLabels: string[];
};

export const BarChart = ({ dataNumbers, groupedLabels }: PropsChartsWidget) => {
    const theme = useTheme();
    const data = {
        labels: groupedLabels,

        datasets: [
            {
                data: dataNumbers,
                backgroundColor: [
                    theme.palette.themeDarker,
                    theme.palette.themeSecondary,
                    theme.palette.blackTranslucent40,
                    theme.palette.neutralTertiaryAlt,
                ],
                hoverOffset: 4,
            },
        ],
    };

    const config: ChartProps<'bar'> = {
        type: 'bar',
        data,
        options: {
            scales: {
                x: {
                    ticks: {
                        color: theme.palette.black,
                    }
                },
                y: {
                    ticks: {
                        color: theme.palette.black,
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            color: theme.palette.black,
        }
    };

    return (
        <div>
            <Bar {...config} />
        </div>
    );
};
