import {IColumn, Stack} from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGLDeferredTax = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'accountCode' }),
                minWidth: 200,
                maxWidth: 256,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'account',
                name: formatMessage({ id: 'account' }),
                minWidth: 200,
                maxWidth: 256,
                fieldName: 'account',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'revaluationTax',
                name: formatMessage({ id: 'revaluationTaxDeferredAmount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'revaluationTax',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'permanentDifference',
                name: formatMessage({ id: 'permanentDifference' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'permanentDifference',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'amount',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
