import {IAppConfiguration, IAppTenantIdResponse} from 'interfaces';
import React from 'react';

interface IContext {
    config: IAppConfiguration | null;
    tenantId: string;
    tenantType: number | null;
    tenantInfo: IAppTenantIdResponse | null;
    logo: string;
}

export const Context = React.createContext<IContext | null>(null);
