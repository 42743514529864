import { IOrderedRequest } from 'interfaces';
import { ITableHeadersColumn } from 'pages/shared/Dashboard';
import { formatDate } from './dates';

export function getColumnKey(column: ITableHeadersColumn): string {
    return (column.dateArrayName && column.dateArrayIndex) ? `${column.dateArrayName}-${column.dateArrayIndex}` : column.columnName;
}

export function getContent(column: ITableHeadersColumn, items: any): string {
    const content = column.dateArrayName && column.dateArrayIndex != null ? items[column.dateArrayName][column.dateArrayIndex] : items[column.columnName];
    const procContent = column.isDateTimeColumn ? formatDate(content) : content;
    return procContent;
}

export function getItemTextColor(item: any): any {
    if (item.metaData?.textColor == 'Black') {
        return null;
    }

    return item.metaData?.textColor;
}

export function getSort(orderdRequest: IOrderedRequest | undefined, columns: ITableHeadersColumn[]): IOrderedRequest | undefined {
    const newSort: IOrderedRequest | undefined = orderdRequest?.orderBy
        ? {
            orderBy: columns.find(column => getColumnKey(column) === orderdRequest.orderBy)?.ambientName,
            orderDirection: orderdRequest?.orderDirection,
        }
        : undefined;
    return newSort;
}
