import * as React from 'react';
import {FunctionComponent} from 'react';
import {PrimaryButton, ResponsiveMode, Spinner, SpinnerSize, Stack, useTheme} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {IModalProps} from '@fluentui/react/lib/components/Modal/Modal.types';
import {Modal, ModalFooter} from "./Modal";
import {useIntl} from "react-intl";

interface Props extends IModalProps {
    title?: string;
    submitText?: string;
    isLoading?: boolean;
    onDismiss?: () => any;
    submitDisabled?: boolean;
    onSubmit?: () => any;
    closeAfterSubmit?: boolean;
    isSubmitting?: boolean;
    fullWidth?: boolean;
    minHeight?: boolean;
    minWidth?: number | string;
    width?: number | string;
}

export const ModalWithForm: FunctionComponent<Props> = ({
                                                            title,
                                                            children,
                                                            isLoading,
                                                            minHeight,
                                                            ...props
                                                        }: Props) => {
    const titleId = useId('modal-title');
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const handleSubmit = () => {
        if (props.onSubmit) {
            let result = props.onSubmit();

            if (typeof result === 'object' && typeof result.then === 'function') {
                result.then(() => {
                    
                    if (props.closeAfterSubmit && props.onDismiss)
                        props.onDismiss();
                });
            } else {
                if (props.closeAfterSubmit && props.onDismiss)
                    props.onDismiss();
            }
        }
    }


    return (
        <Modal
            theme={theme.schemes?.default}
            {...props}
            title={title}
            titleAriaId={titleId}
            responsiveMode={ResponsiveMode.large}
            isBlocking={false}
            styles={{
                main: {
                    minWidth: props.minWidth || '55vw',
                    maxWidth: '95vw',
                    minHeight: minHeight ? '90vw' : 'auto',
                    width: props.width || (props.fullWidth ? '96vw' : 1000),
                    background: theme.palette.neutralLight,
                },
                scrollableContent: {height: 'calc(100% - 50px)'},
            }}>
            {isLoading ?
                (
                    <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                        <Spinner label={formatMessage({id: 'loading'})} size={SpinnerSize.large}/>
                    </Stack>
                )
                : (
                    <Stack tokens={{childrenGap: 16}}>
                        <Stack.Item>
                            {children}
                        </Stack.Item>
                        <ModalFooter horizontalAlign={"end"}>
                            <PrimaryButton disabled={props.submitDisabled || props.isSubmitting}
                                           onClick={handleSubmit}>
                                {props.isSubmitting ? (<Spinner
                                    size={SpinnerSize.small}/>) : (props.submitText || formatMessage({id: 'save'}))}
                            </PrimaryButton>
                        </ModalFooter>

                    </Stack>
                )}

        </Modal>
    );
};