import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { DataTable, SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useEffect, useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import { getGrouppedReports } from 'utils';
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {ReportTable} from "../Table/ReportTable";
import {ReportTemplateTypeProps} from "../Types";
import {GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {isBankRow, isSystemVerifiedRow} from "./consts";
import {isAgreedAnswer, isAutoAnswer, isYesAnswer} from "../../answers";
import {useReportValidationsContext} from "../../../ReportContent";
import {ReportValidationCell} from "../shared";



export const ClassSuperInvestmentMovement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
        const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let manualCoverage = 0;
        let systemCoverage = 0;
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            if (i.hasData) {
                if (isAutoAnswer(i.answerText1) || isAgreedAnswer(i.answerText1)) {
                    if (!!i.investmentName) {
                        if (i.incomeTotal) {
                            verifiedCoverage += i.incomeTotal;
                        } else {
                            verifiedCoverage += i.totalAcquisitionCost;
                        }
                    }
                }
                if (!i.investmentName) {
                    if (i.incomeTotal) {
                        incomeTotal += i.incomeTotal;
                    } else {
                        incomeTotal += i.totalAcquisitionCost;
                    }
                }
            } else {
                if (isAutoAnswer(i.answerText) || isAgreedAnswer(i.answerText)) {
                    if (!!i.investmentName) {
                        if (i.incomeTotal) {
                            verifiedCoverage += i.incomeTotal;
                        } else {
                            verifiedCoverage += i.totalAcquisitionCost;
                        }
                    }
                }
                if (!i.investmentName) {
                    if (i.incomeTotal) {
                        incomeTotal += i.incomeTotal;
                    } else {
                        incomeTotal += i.totalAcquisitionCost;
                    }
                }
            }

        })

        const result = {
            manual: 0,
            system: 0,
            total: 0,
            verified: incomeTotal != 0 ? verifiedCoverage / incomeTotal : 0
        }

        result.total = result.manual + result.system;
        return result;
    }
    
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'marketName' }), [items]);

    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    const [groupsWithSummaries, setGroupsWithSummaries] = useState<IGroupWithSummary[]>([...groups] as IGroupWithSummary[]);
    
    useEffect(() => {
        setGroupsWithSummaries(prev => [...prev.reduce((acc: IGroupWithSummary[], g) => {
                let children = reports.slice(g.startIndex, g.startIndex + g.count);
                acc.push({
                    ...g,
                    isCollapsed: g.isCollapsed,
                    summary: calculateAnswersSummary(children)
                });
                return acc;
            }, [])]
        );
    }, [summaryUpdateTrigger])

    const getAnswerControlValue = (text?: string): string => !text ? '' : isAgreedAnswer(text) || isAutoAnswer(text) || isYesAnswer(text) ? 'Yes' : 'No';
    
    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(reports, item.id, field, value);
            setSummaryUpdateTrigger(Date.now())
        }
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'investmentName',
                name: formatMessage({ id: 'description' }),
                minWidth: 400,
                fieldName: 'investmentName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {!item?.isTotalRow && <GeneratedAnswer 
                            itemId={item?.id} 
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={getAnswerControlValue(item[column?.fieldName || 0])}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            answerControlType={AnswerControlType.WorkDoneSwitch} />}
                    </Stack>
                )
            },
            {
                key: 'openingQuantity',
                name: formatMessage({ id: 'obQty' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'openingQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' numberFormatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }} />,
            },
            {
                key: 'openingBookCost',
                name: formatMessage({ id: 'obCost' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'openingBookCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'totalAcquisitionQuantity',
                name: formatMessage({ id: 'acquiQty' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'totalAcquisitionQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' numberFormatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }} />,
            },
            {
                key: 'totalAcquisitionCost',
                name: formatMessage({ id: 'acquiCost' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'totalAcquisitionCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'totalDisposalQuantity',
                name: formatMessage({ id: 'dispQty' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'totalDisposalQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' numberFormatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }} />,
            },
            {
                key: 'totalDisposalProceeds',
                name: formatMessage({ id: 'dispProceeds' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'totalDisposalProceeds',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'profitOrLoss',
                name: formatMessage({ id: 'dispProfitLoss' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'profitOrLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'closingQuantity',
                name: formatMessage({ id: 'cbQty' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'closingQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' numberFormatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }} />,
            },
            {
                key: 'closingBookCost',
                name: formatMessage({ id: 'cbCost' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'closingBookCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'closingMarketValue',
                name: formatMessage({ id: 'cbMarketValue' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'closingMarketValue',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 120,
                maxWidth: 180,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const groupSummaryRenderer = (group: IGroupWithSummary): React.ReactNode => {
        const summary = group.summary;
        return <>
            {
                group?.name && group?.name?.indexOf("Total") === -1 &&
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                    </Stack.Item>
                </Stack>
            }
        </>
    }

    return <ReportTable 
        initialColumns={columns}
        openAll={true} 
        groups={groupsWithSummaries.map(g => {g.isCollapsed = false; return g})}
        groupProps={{ summary: {
                show: true,
                childRenderer: groupSummaryRenderer
            }}}
        items={reports} 
        isFetching={isFetching} />;
};
