import {Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect} from 'react';
import {ITableFilters} from '../GeneratedDataTable';
import {Stack} from '@fluentui/react';
import {ControlledDropdown} from 'components';
import {useForm} from 'react-hook-form';
import {useWorkflowDashboard} from "../../DashboardContainer";
import {useGetJobYears} from "../../../hooks";
import { useAdministrationDashboard } from 'pages/ClientPortal/Administration';

export interface IGeneratedFiltersForm {
    filterByYear?: string;
}

export interface IGeneratedYearFilterProps {
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
}

export const GeneratedYearsFilter: FunctionComponent<IGeneratedYearFilterProps> = ({setFilters}) => {
    const workflowDashboardContext = useWorkflowDashboard();
    const administrationDashboardContext = useAdministrationDashboard();
    const dashboardContext = (workflowDashboardContext ?? administrationDashboardContext)!;
    
    const {dashboardTab} = dashboardContext;
    const {jobYears, isJobYearsLoading} = useGetJobYears({dashboardTab});
    const {control, watch} = useForm<IGeneratedFiltersForm>({
        mode: 'onChange',
        defaultValues: {
            filterByYear: '',
        },
    });

    const onSubmit = useCallback((newFilterData: IGeneratedFiltersForm) => setFilters((prev) => ({
        ...prev, ...newFilterData,
        page: 1
    })), [setFilters]);

    const sortedYears = jobYears?.sort((x, y) => x.key < y.key ? 1 : -1);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    return (
        <Stack styles={{root: {width: 80}}}>
            <ControlledDropdown name='filterByYear' control={control} options={sortedYears || []}
                                disabled={isJobYearsLoading}/>
        </Stack>
    );
};