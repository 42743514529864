import {Stack} from '@fluentui/react';
import {Pagination} from 'components';
import {ExportType, useExportData} from 'hooks/useExportData';
import {useContext, useEffect, useState} from 'react';
import {Context} from 'utils';
import {useIntl} from "react-intl";
import {IEmployeePaginationFilter, useGetEmployeesByFilter} from "./hooks";
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {EmployeesAddItemModal, EmployeesDataTable} from "./components";
import {DataTableFilter} from "../shared";

export const EmployeesWorkflowPage = () => {
    const [filters, setFilters] = useState<IEmployeePaginationFilter>({
        pageSize: 10,
        page: 1,
        orderBy: 'FirstName',
        orderDirection: 'asc',
        status: EntityStatus.Active,
    });
    const context = useContext(Context);
    const {formatMessage} = useIntl();
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const {dataEmployeesFilter: employeesData, isLoadingEmployeesFilter} = useGetEmployeesByFilter(filters);
    const {exportData} = useExportData(`${context?.config?.userUrl}/api/1.0/Employee`, filters);

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters(prevState => ({...prevState, page: page, pageSize: pageSize}));
    };
    const onSearch = (newValue: string) => {
        setFilters(prev => ({...prev, searchString: newValue}));
    };

    const onChangeStatus = (newStatus: EntityStatus | null) => {
        setFilters(prev => ({...prev, status: newStatus}));
    };
    const [pagination, setPagination] = useState<{ itemsCount: number; total: number }>();

    useEffect(() => {
        if (employeesData?.data) {
            setPagination({
                itemsCount: employeesData?.data.shownItemsCount,
                total: employeesData?.data.totalItemsCount,
            });
        }
    }, [employeesData?.data]);

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <DataTableFilter onAddClick={() => setShowAddModal(true)}
                                 search={{
                                     onSearch: onSearch,
                                     onClear: () => onSearch('')
                                 }}
                                 status={{selectedStatus: filters.status || null, changeStatus: onChangeStatus}}
                                 export={{
                                     onPdfClick: () => exportData(ExportType.Pdf),
                                     onExcelClick: () => exportData(ExportType.Excel)
                                 }}/>
                <EmployeesAddItemModal isOpen={showAddModal} onDismiss={() => setShowAddModal(false)}
                                       title={formatMessage({id: 'createEmployee'})}/>
            </Stack.Item>
            <Stack.Item grow={1}>
                <EmployeesDataTable employeesItems={employeesData?.data.items || []}
                                    isLoading={isLoadingEmployeesFilter}
                                    setSort={(value) => setFilters(prev => ({
                                        ...prev,
                                        orderBy: value.orderBy,
                                        orderDirection: value.orderDirection
                                    }))}/>
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Pagination
                    itemsCount={pagination?.itemsCount}
                    total={pagination?.total}
                    page={filters.page}
                    pageSize={filters.pageSize}
                    onChange={onPaginationChange}
                />
            </Stack.Item>
        </Stack>
    );
};
