import {IGroup} from "@fluentui/react";

export const getFlatProcedures = (procedures: any[], expandedItemIds: number[]) => procedures
    .reduce((acc: any[], procedure) => {
        procedure.isOpen = expandedItemIds.some(id => procedure?.id === id)
        acc.push(procedure);
        if (procedure.children?.length && (!procedure.showExpander || procedure.isOpen)) {
            const childrenAcc = getFlatProcedures(procedure.children, expandedItemIds);
            acc.push(...childrenAcc);
        }
        return acc;
    }, []);

export const getOpenOnStartupIds = (procedures: any[]): number[] => procedures
    .reduce((acc: number[], procedure) => {
        let newAcc = [...acc]
        if (procedure?.openOnStartup) {
            newAcc.push(procedure.id);
        }
        if (procedure?.children?.length) {
            const childrenIds = getOpenOnStartupIds(procedure.children);
            newAcc = newAcc.concat(childrenIds);
        }
        return newAcc;
    }, []);

export const getGrouppedProcedures = (procedures: any[]): { procedures: any[], groups: IGroup[] } => {
    const groups: IGroup[] = [];
    let index = 0;
    const getFlatProceduresForGroups = (procedures: any[]) => procedures.reduce((acc: any[], procedure) => {
        if (procedure.children?.length) {
            const childrenAcc = getFlatProceduresForGroups(procedure.children);
            acc.push(...childrenAcc);
            groups.push({
                startIndex: index - childrenAcc.length,
                count: childrenAcc.length,
                key: procedure.reference,
                name: procedure.title,
            });
        } else {
            acc.push(procedure);
            index++;
        }
        return acc;
    }, []);
    const newProcedures = getFlatProceduresForGroups(procedures);
    return {procedures: newProcedures, groups};
};

interface IGetGrouppedReportsProps {
    reports: any[];
    groupKey: string;
    groupName?: string;
    formatGroupName?: (report: any) => string;
    pushEmptyGroup?: boolean
}

export const getGrouppedReports = ({
                                       reports,
                                       groupKey,
                                       groupName,
                                       formatGroupName,
                                       pushEmptyGroup = false
                                   }: IGetGrouppedReportsProps): { reports: any[], groups: IGroup[] } => {
    const acc: any[] = [];

    const runInReportsTree = (items: any[], level = 0) => {
        const children: IGroup[] = [];
        let count = 0;
        items.forEach((item,idx) => {
            if (item?.children?.length) {
                const startIndex = acc.length;
                const nameKey = groupName || groupKey;
                const name = formatGroupName ? formatGroupName(item) : item[nameKey];
                const x = runInReportsTree(item?.children, level + 1);
                const childrenCount = x.children.reduce((acc, c) => acc + c?.count, 0);
                children.push({
                    children: x?.children,
                    key: item[groupKey],
                    name: name || '',
                    count: x.count + childrenCount,
                    startIndex,
                    level,
                    isCollapsed: !item?.openOnStartUp,
                    data: item
                })
            } else {
                if (pushEmptyGroup) {
                    children.push({
                        key: item[groupKey] || `${idx}`,
                        name:  '',
                        count: 1,
                        startIndex:0,
                        level,
                        isCollapsed: false,
                    })
                }
                acc.push(item);
                count++;
            }
        });
        return {children, count};
    };

    const {children} = runInReportsTree(reports);

    return {reports: acc, groups: children};
};

export const getNestedGroupsByKeys = (items: any[], keys: string[]): IGroup[] => {
    const groups: IGroup[] = [];

    const createGroupChildren = (groupKeys: string[], startIndex: number, level: number): IGroup[] | undefined => {
        const isLastkey = level >= groupKeys?.length - 1;
        if (isLastkey) return undefined;

        return [
            {
                key: groupKeys[level],
                name: groupKeys[level],
                startIndex,
                level,
                count: 1,
                children: createGroupChildren(groupKeys, startIndex, level + 1),
            }
        ];
    };

    const treeIteration = (currentGroups: IGroup[], groupKeys: string[], startIndex: number, level: number) => {
        const groupKey = groupKeys[level];
        const isLastkey = level >= groupKeys?.length - 1;
        const lastCurrentLevelGroup = currentGroups?.length ? currentGroups[currentGroups.length - 1] : null;
        const isSameCurrentLevelGroup = lastCurrentLevelGroup && lastCurrentLevelGroup.key === groupKeys[level];

        if (isSameCurrentLevelGroup) {
            lastCurrentLevelGroup.count++;

            if (!isLastkey) {
                if (!lastCurrentLevelGroup?.children) {
                    lastCurrentLevelGroup.children = [];
                }

                treeIteration(lastCurrentLevelGroup.children, groupKeys, startIndex, level + 1);
            }

        } else {
            currentGroups.push({
                key: groupKey,
                name: groupKey,
                count: 1,
                startIndex,
                level,
                children: createGroupChildren(groupKeys, startIndex, level + 1),
            });
        }
    };

    items.forEach((item, idx) => {
        const groupKeys = keys.map(key => item[key]);

        treeIteration(groups, groupKeys, idx, 0);
    });

    return groups;
};