import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {

}

function useResolvePostDispatch() {
    const context = useContext(Context);
    const {
        mutate: resolve,
        mutateAsync: resolveAsync,
        isLoading: isResolving
    } = useMutation((jobId: MutateDataType, data?: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/resolvePostDispatch/${jobId}`, data))

    return {resolve, resolveAsync, isResolving};
}

export {useResolvePostDispatch};