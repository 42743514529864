import React from 'react';
import axios, { AxiosResponse } from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import { useNotifications } from 'components/notifications';
import { useIntl } from 'react-intl';
import { MessageBarType } from '@fluentui/react';

export interface ICreateWrapAccount {
  data: FormData;
  year: number;
}

function useCreateWrapAccount() {
    const context = React.useContext(Context);
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: createWrapAccount, isLoading, isSuccess } = useMutation(({ year, data }: ICreateWrapAccount) =>
        {
            const file = data.get('file') as File;

            if(file.size === 0)
            {
                showNotification({
                    name: formatMessage({ id: 'fileSizeIsZero' }),
                    type: MessageBarType.error,
                    description: formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }),
                });

                return Promise.reject(formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }));
            }

            return axios.post(`${context?.config?.annotationsUrl}/api/1.0/library/addWrapAccount/${year}`,  data, commonHeaders())
        },
        {
            onSuccess: () => queryClient.invalidateQueries('getWrapAccounts')
        }
    );

    return { createWrapAccount, isLoading, isSuccess };
}

export { useCreateWrapAccount };