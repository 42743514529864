import {FunctionComponent, useMemo} from 'react';
import {Stack, Text} from '@fluentui/react';
import {getFlatProcedures, getOpenOnStartupIds} from 'utils';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {Formatting} from "../../../enums";


export const AuditAssertionsProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items))
        .filter(x => x.format != Formatting.Italic), [items]);

    if(!items?.length)
        return <></>;
    
    return (
        <ProceduresDataTable
            items={rows}
            isLoading={isLoading}
            contextMenuOptions={{ disable: true }}
            isHeaderVisible={false}
            columns={[
                {
                    key: 'auditProcedure',
                    name: '',
                    minWidth: 500,
                    fieldName: 'auditProcedure',
                    onRender: (items) => {

                        return (
                            <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                                <Text>{items.number}</Text>
                                <SanitizedText data={items?.title} isHeadingRow={items?.children?.length}/>
                            </Stack>
                        )
                    },
                },
                {
                    key: 'abbreviation',
                    name: '',
                    minWidth: 300,
                    maxWidth: 500,
                    fieldName: 'abbreviation',
                    onRender: (items) => <SanitizedText data={items?.auditingStnd}
                                                        isHeadingRow={items?.children?.length} textAlign='center'/>,
                },
            ]}
        />
    );
};