import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

/**
 * Query for get Fund Risk Rating date in Portal Settings.
 */

type Setting = {
    value: string | null;
}

function useGetFundRiskRatingDate() {
    const settingSystemName = "ReleaseData.FundRiskRatingDate";
    const context = React.useContext(Context);
    const { data: fundRiskRatingDate } = useQuery(
        ['getFundRiskRatingDate'],
        () => axios.get<Setting>(`${context?.config?.settingsUrl}/api/1.0/Settings/portalSettings/${settingSystemName}`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
            onError: (err: AxiosError) => {
                    queryClient.invalidateQueries('getFundRiskRatingDate')
            },
        }
    );

    return { fundRiskRatingDate};
}

export { useGetFundRiskRatingDate };
