import {Spinner, Stack} from "@fluentui/react";
import {useIntl} from "react-intl";
import {CalculatorRow} from "../shared";
import {IChangeInMVData} from "./IChangeInMVData";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {useUpdateCalculatorData} from "../../hooks";
import {CalculatorType} from "../../CalculatorType";
import {CalculatorTemplateProps} from "../../types";
import { useJobContext } from "pages/JobPortal/JobPortalLayoutPage";

type ChangeInMVCalculatorProps = CalculatorTemplateProps;

export const ChangeInMVCalculator = forwardRef(({data}: ChangeInMVCalculatorProps, ref) => {
    const {formatMessage} = useIntl();
    const{ jobId } = useJobContext();
    const {update, isUpdating} = useUpdateCalculatorData();
    const [changeInMVData, setChangeInMVData] = useState<IChangeInMVData>(data?.data as IChangeInMVData);
    const {handleSubmit, setValue, watch} = useForm<IChangeInMVData>({
        ...DefaultFormSettings,
        defaultValues: {
            ...data?.data
        }
    });
    const watchFields = watch();
    const updateCalcData = (updatedData: IChangeInMVData) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.ChangeInMV,
            isCustom: false,
            answer: updatedData,
            jobId: jobId
        });
    };

    const total = useMemo(() => {
        return (watchFields.mvcb || 0) -
                (watchFields.mvob || 0) -
                (watchFields.coa || 0) +
                (watchFields.cod || 0) +
                (watchFields.pod || 0) -
                (watchFields.lod || 0);
    }, [watchFields]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        }
    }));

    useEffect(() => {
        setChangeInMVData(data?.data as IChangeInMVData);
    }, [data?.data]);

    const isMVCBChanged = useMemo(() => {
        return changeInMVData?.isMVCBChanged || watchFields.mvcb != changeInMVData?.mvcb;
    }, [watchFields]);

    const isMVOBChanged = useMemo(() => {
        return changeInMVData?.isMVOBChanged || watchFields.mvob != changeInMVData?.mvob;
    }, [watchFields]);

    const isCODChanged = useMemo(() => {
        return changeInMVData?.isCODChanged || watchFields.cod != changeInMVData?.cod;
    }, [watchFields]);

    const isPODChanged = useMemo(() => {
        return changeInMVData?.isPODChanged || watchFields.pod != changeInMVData?.pod;
    }, [watchFields]);

    const isLODChanged = useMemo(() => {
        return changeInMVData?.isLODChanged || watchFields.lod != changeInMVData?.lod;
    }, [watchFields]);

    const isCOAChanged = useMemo(() => {
        return changeInMVData?.isCOAChanged || watchFields.coa!= changeInMVData?.coa;
    }, [watchFields]);

    const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

    const handleClickOutside = (event: any) => {
        if((event.srcElement.id as string).includes('TextField'))
            return;

        setEditingRow(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    if(isUpdating)
      return <Spinner />;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <CalculatorRow
                iconType={'plus'}
                changed={isMVCBChanged}
                onChangeValue={(newValue) => {
                    setValue('mvcb', newValue);
                    setValue('isMVCBChanged', true);
                }}
                label={formatMessage({ id: 'calculator_changeInMV_Market_Value_Closing_Balance' })}
                value={changeInMVData?.mvcb}
                onClick={() => setEditingRow('mvcb')}
                editing={editingRow === 'mvcb'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                onChangeValue={(newValue) => {
                    setValue('mvob', newValue);
                    setValue('isMVOBChanged', true);
                }}
                label={formatMessage({ id: 'calculator_changeInMV_Market_Value_Opening_Balance' })}
                changed={isMVOBChanged}
                value={changeInMVData?.mvob}
                onClick={() => setEditingRow('mvob')}
                editing={editingRow === 'mvob'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                changed={isCOAChanged}
                label={formatMessage({ id: 'calculator_changeInMV_Cost_of_Additions' })}
                onChangeValue={(newValue) => {
                    setValue('coa', newValue);
                    setValue('isCOAChanged', true);
                }}
                value={changeInMVData?.coa}
                onClick={() => setEditingRow('coa')}
                editing={editingRow === 'coa'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'plus'}
                changed={isCODChanged}
                label={formatMessage({ id: 'calculator_changeInMV_Cost_of_Disposals' })}
                onChangeValue={(newValue) => {
                    setValue('cod', newValue);
                    setValue('isCODChanged', true);
                }}
                value={changeInMVData?.cod}
                onClick={() => setEditingRow('cod')}
                editing={editingRow === 'cod'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'plus'}
                changed={isPODChanged}
                label={formatMessage({ id: 'calculator_changeInMV_Profit_on_Disposals' })}
                onChangeValue={(newValue) => {
                    setValue('pod', newValue);
                    setValue('isPODChanged', true);
                }}
                value={changeInMVData?.pod}
                onClick={() => setEditingRow('pod')}
                editing={editingRow === 'pod'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                changed={isLODChanged}
                label={formatMessage({ id: 'calculator_changeInMV_Loss_on_Disposals' })}
                onChangeValue={(newValue) => {
                    setValue('lod', newValue);
                    setValue('isLODChanged', true);
                }}
                value={changeInMVData?.lod}
                onClick={() => setEditingRow('lod')}
                editing={editingRow === 'lod'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'equal'}
                label={formatMessage({ id: 'calculator_changeInMV_Change_Market_Value' })}
                editable={false}
                value={total}
            />
        </Stack>
    );
});