import {
    IStyleFunctionOrObject,
    ITextFieldProps,
    ITextFieldStyleProps,
    ITextFieldStyles,
    TextField,
    useTheme
} from '@fluentui/react';
import {Controller, Path, useForm} from 'react-hook-form';
import * as React from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {IIconProps} from '@fluentui/react/lib/Icon';
import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> extends ITextFieldProps{
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    multiline?: boolean;
    placeholder?: string;
    underlined?: boolean;
    borderless?: boolean;
    iconProps?: IIconProps;
    isPassword?: boolean;
    disabled?: boolean;
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
    rows?: number;
    readonly?: boolean;
    required?: boolean;
    suffix?: string;
}

export function ControlledTextField<FormValues>({
                                                    name,
                                                    control,
                                                    rules,
                                                    label,
                                                    multiline,
                                                    placeholder,
                                                    underlined,
                                                    borderless,
                                                    iconProps,
                                                    isPassword,
                                                    disabled,
                                                    styles,
                                                    rows,
                                                    readonly,
                                                    required,
                                                    ...otherProps

                                                }: Props<FormValues>) {
    const theme = useTheme();

    const defaultStyles = {
        root: {
            selectors: {
                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
            }
        },
        field: {
            '-webkit-box-shadow': `inset 0 0 0 50px ${theme.palette.white}`,
            color: theme.schemes?.default?.semanticColors.inputText,
            '::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
        },
        fieldGroup: {
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            selectors: {
                '&:hover': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 }
            }
        }
    };

    const mergedStyles = deepMerge(defaultStyles, styles);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                <TextField
                    label={label}
                    name={fieldName}
                    required={required}
                    value={value as string}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    underlined={underlined}
                    borderless={borderless}
                    iconProps={iconProps}
                    type={isPassword ? 'password' : undefined}
                    disabled={disabled}
                    readOnly={readonly}
                    {...otherProps}
                    styles={mergedStyles}
                    onChange={(event) => {onChange(event); otherProps.onChange?.(event); }}
                />
            )}
        />
    );
}
