import {Checkbox} from '@fluentui/react/lib/Checkbox';
import {Controller, Path} from 'react-hook-form';
import * as React from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {ICheckboxProps} from "@fluentui/react/lib-amd";
import { useTheme } from '@fluentui/react';
import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> extends ICheckboxProps {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    addHiddenTopLabel?: boolean;
    adjustInputHeight?: boolean;
    extraValue?: boolean;
    onValueChange?: (newValue: boolean) => void;
}

export function ControlledCheckbox<FormValues>({
                                                   name,
                                                   control,
                                                   rules,
                                                   label,
                                                   addHiddenTopLabel,
                                                   adjustInputHeight,
                                                   extraValue,
                                                   ...otherProps
}: Props<FormValues>) {
    const theme = useTheme();

    const defaultStyles = addHiddenTopLabel || adjustInputHeight
                            ? {
                                root: {
                                    marginTop: addHiddenTopLabel ? 29 : undefined,
                                    height: adjustInputHeight ? 32 : undefined,
                                    alignItems: adjustInputHeight ? 'center' : undefined,
                                    ':hover .ms-Checkbox-text': {
                                        color: theme.schemes?.default?.semanticColors.bodyText
                                    },
                                },
                                text: {
                                    color: theme.schemes?.default?.semanticColors.bodyText,
                                }
                            }
                            : 
                            {
                                root: {
                                    ':hover .ms-Checkbox-text': {
                                        color: theme.schemes?.default?.semanticColors.bodyText
                                    },
                                },
                                text: {
                                    color: theme.schemes?.default?.semanticColors.bodyText,
                                }
                            };

   const mergedStyles = deepMerge(defaultStyles, otherProps.styles);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {onChange, value}}) => (
                <Checkbox
                    theme={value ? theme : theme.schemes?.default}
                    label={label}
                    checked={!!value}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        onChange(!!checked)
                        otherProps.onValueChange?.(!!checked)
                    }}
                    {...otherProps}
                    styles={mergedStyles}
                />
            )}
        />
    );
}
