import {createContext, useContext} from "react";
import {OtherClientInvoice} from "./OtherClientInvoice";

export interface IOtherClientsContext {
    selectedItems: OtherClientInvoice[],
    setSelectedItems: (items: OtherClientInvoice[] | ((prev: any) => OtherClientInvoice[])) => void;
}

export const OtherClientsContext = createContext<IOtherClientsContext>({
    selectedItems: [],
    setSelectedItems: () => {}
});

export const useOtherClientsContext = () => useContext<IOtherClientsContext>(OtherClientsContext);