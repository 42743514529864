import { FunctionComponent, ReactNode } from 'react';
import { Stack, Text, useTheme } from '@fluentui/react';
import { Card } from 'components';

interface Props {
    header: ReactNode;
    children: ReactNode;
}

export const EmailTemplatePanel: FunctionComponent<Props> = ({ header, children }: Props) => {
    const theme = useTheme();


    return <Card>
        <Stack tokens={{ childrenGap: 16 }}>
            <Text theme={theme.schemes?.default} variant='xLarge'>{header}</Text>
            {children}
        </Stack>
    </Card>
};
