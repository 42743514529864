import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {getGrouppedReports} from "../../../../../../utils";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, ReportValueType, TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import { isAgreedAnswer, isAutoAnswer } from "../../answers";


export const BGLI360InvestmentIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            const isTotal = i.valueType === 2;
            
            if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                if (!isTotal) {
                    verifiedCoverage += i.totalIncome ?? 0;
                }
            }

            if (isTotal) {
                incomeTotal += i.totalIncome ?? 0;
            }
        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const rebuildReports = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.format = i.valueType !== ReportValueType.Value || i.isBold ? 'Bold' : 'Normal'
            i.showAttachmentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            i.showCommentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
                i.children = rebuildReports(i.children);
            }

            acc.push(i);
            return acc;
        }, []);
    }

    const [tableItems, setTableItems] = useState<IReport[]>(rebuildReports(items));

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(rebuildReports(tableItems));
        }
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'investment',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 400,
                maxWidth: 400,
                fieldName: 'investment',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType == ReportValueType.Value &&
                            <GeneratedAnswer itemId={item?.id} 
                                             tableType={item?.reportType} 
                                             value={item?.answerText} 
                                             answerControlType={item?.answerControlType}
                                             auto={item?.isAnswerDisabled}
                                             onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                             disabled={item?.isAnswerDisabled} 
                            />}
                    </Stack>
                ),
            },
            {
                key: 'totalIncome',
                name: formatMessage({ id: 'totalIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'franked',
                name: formatMessage({ id: 'Franked' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'franked',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'unfranked',
                name: formatMessage({ id: 'Unfranked' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unfranked',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'interest',
                name: formatMessage({ id: 'Interest' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'interest',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'frankingCredits',
                name: formatMessage({ id: 'frankingCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'frankingCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreignIncome',
                name: formatMessage({ id: 'foreignIncome' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreignIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreignCredits',
                name: formatMessage({ id: 'foreignCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreignCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'assessableIncome',
                name: formatMessage({ id: 'assessableIncomeExclCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'assessableIncome',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'tfn',
                name: formatMessage({ id: 'tfnCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'tfn',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'deductions',
                name: formatMessage({ id: 'otherDeductions' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'deductions',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'distributedCapitalGains',
                name: formatMessage({ id: 'distributedCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'distributedCapitalGains',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'nonAssessablePayments',
                name: formatMessage({ id: 'nonAssessablePayments' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'nonAssessablePayments',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns}
                        items={tableItems}
                        header={{ rowHeight: _ExtendedHeaderRowHeight }}
                        groupProps={{
                            mergedGroupHeader: {propertyKey: 'investment'},
                            summary: {
                                show: true,
                                style: { paddingLeft: 0 },
                                childRenderer(group: IGroupWithSummary): React.ReactNode {
                                    const summary = group.summary;
                                    return <>
                                        {
                                            <Stack horizontal>
                                                <Stack.Item>
                                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    </>
                                }
                            }
                        }}
                        isFetching={isFetching} 
                        expandProps={{ showExpander: false }}
                        openAll={true}
    />
};
