import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {NotificationAction, useNotifications} from "components/notifications";
import {useWorkflows} from "../../WorkflowLayoutPage";
import {useIntl} from "react-intl";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {ClientInfo} from "../../shared";
import {MessageBarType} from "@fluentui/react";
import {DetailedSoftwareType, SoftwareType} from "enums";

type CreateTrusteeType = {
    name: string;
    type: "Corporate" | "Individual";
    softwareType: SoftwareType;
    detailedSoftwareType: DetailedSoftwareType;
}

function useCreateTrustee() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {showClientModal} = useWorkflows();
    const {formatMessage} = useIntl();

    const {mutate: createTrustee, isLoading: isCreating} = useMutation(
        (data: CreateTrusteeType) => axios.post<ClientInfo>(`${context?.config?.clientUrl}/api/1.0/trustees`, data, commonHeaders()),
        {
            onSuccess: (data, variables) => {
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'createdSuccessfully'}),
                    description: formatMessage({id: "entityWasCreated"}, {name: variables.name}),
                    actions: (
                        <NotificationAction
                            onClick={() => {
                                console.debug('Create trustee guid: ' + data.data.guid);
                                showClientModal(data.data.guid);
                            }}>{formatMessage({id: 'view'})}</NotificationAction>
                    )
                });
                queryClient.invalidateQueries('getClientsByFilter');
                queryClient.invalidateQueries('getFundsByFilter');
                queryClient.invalidateQueries('getJobsByFilter');
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {createTrustee, isCreating};
}

export {useCreateTrustee};