import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

type ValueForMethodologySettings = {
    wrap: string;
    wrapDataFed: string;
    low: string;
    lowDataFed: string;
    medium1: string;
    medium1DataFed: string;
    medium2: string;
    medium2DataFed: string;
    high: string;
    highDataFed: string;
    nonDataFedText: string;
    dataFedExceptionsText: string;
    dataFedNoExceptionsText: string;
};

export type MethodologySettingsRes = {
    cash: ValueForMethodologySettings;
    managedFunds: ValueForMethodologySettings;
    offMarket: ValueForMethodologySettings;
    listedSecurities: ValueForMethodologySettings;
    termDeposits: ValueForMethodologySettings;
    realProperty: ValueForMethodologySettings;
    unlistedInvestments: ValueForMethodologySettings;
    contributions: ValueForMethodologySettings;
    transfersInRolloversIn: ValueForMethodologySettings;
    pensionsPaid: ValueForMethodologySettings;
    transfersOutRolloversOut: ValueForMethodologySettings;
    lumpSumPayments: ValueForMethodologySettings;
    wrap: ValueForMethodologySettings;
    otherAssets: ValueForMethodologySettings;
    reducedTestingApproachEnabled: boolean;
};

/**
 * Query for get Methodology Settings data in Portal Settings
 * Second table in page Testing and Methodology Settings
 */

function useGetMethodologySettings() {
    const context = React.useContext(Context);

    const { data: methodologySettings, isLoading } = useQuery(
        ['getMethodologySettings'],
        () => axios.get<MethodologySettingsRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/riskMatrix`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { methodologySettings, isLoading };
}

export { useGetMethodologySettings };
