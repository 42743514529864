import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import React from "react";
import {EmployeeInfo, EmployeePosition} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";

const useGetEmployeesByPosition = (position: EmployeePosition) => {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();

    const {data: employeesByPosition, isFetching: isEmployeesLoading} = useQuery(
        ['getEmployeesByPosition', position],
        () => axios.get<EmployeeInfo[]>(`${context?.config?.userUrl}/api/1.0/Employee`, {
            ...commonHeaders(),
            params: {position}
        }),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!position,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {employeesByPosition, isEmployeesLoading};
}

export {useGetEmployeesByPosition};
