import {DatePicker, defaultDatePickerStrings, IDatePicker, IDatePickerProps, Text, useTheme} from '@fluentui/react';
import {Controller, Path} from 'react-hook-form';
import * as React from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {DateTime} from 'luxon';
import {format, parseJSON,} from "date-fns";
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {useIntl} from "react-intl";
import {logger} from "../../services";
import { createRef, useEffect } from 'react';
import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> extends IDatePickerProps {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    required?: boolean;
    label?: string;
    placeholder?: string;
    onDateSelected?: (date?: Date | null) => void;
}

const _DateFormat: string = 'dd/MM/yyyy';

export function ControlledDatePicker<FormValues>({
                                                     name,
                                                     control,
                                                     rules,
                                                     label,
                                                     required,
                                                     placeholder, onDateSelected,
                                                     ...otherProps
                                                 }: Props<FormValues>) {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const ref = createRef<IDatePicker>();

    useEffect(() => {
        if (ref?.current?.reset && required) {
            ref.current.reset();
        }
    }, []);

    const onFormatDate = React.useCallback((date?: Date): string => !date ? '' : format(parseJSON(date), _DateFormat), [control]);

    const onParseDateFromString = React.useCallback((newValue: string): Date => {
        return DateTime.fromFormat(newValue, _DateFormat).toJSDate();
    }, [control]);

    const defaultStyles = {
        icon: { color: theme.palette.themePrimary },
        callout: {
            selectors:
            {
                'span, i, button, th': { color: theme.schemes?.default?.semanticColors.bodyText },
                'span:hover, i:hover, button:hover': { color: theme.palette.themePrimary },
            }
        },
        root: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            selectors: {
                '.ms-TextField-fieldGroup': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 },
                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input': { color: theme.schemes?.default?.semanticColors.bodyText },
                'input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
            }
        },
        readOnlyPlaceholder: { color: theme.schemes?.default?.semanticColors.disabledBodyText }
    };

    const mergedStyles = deepMerge(defaultStyles, otherProps.styles);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
                const preDatePickerValue: Date | undefined = typeof value === 'string'
                    ? ((dateStr: string) => {
                        const date = DateTime.fromFormat(dateStr, _DateFormat).toJSDate()
                        return !isNaN(date.getTime()) ? date : new Date(dateStr as string)
                    })(value as string)
                    : value instanceof Date
                        ? value
                        : undefined;
                const datePickerValue = (preDatePickerValue && !isNaN(preDatePickerValue.getTime()))
                    ? preDatePickerValue
                    : undefined;

                return (
                    <>
                        <DatePicker
                            componentRef={ref}
                            isRequired={required}
                            label={label}
                            allowTextInput={true}
                            highlightCurrentMonth={true}
                            showMonthPickerAsOverlay={true}
                            showCloseButton={true}
                            onSelectDate={(v) => {
                                v?.setHours(12, 0, 0, 0);
                                onChange(v)
                                onDateSelected?.(v);
                            }}
                            value={datePickerValue}
                            onBlur={onBlur}
                            formatDate={onFormatDate}
                            placeholder={placeholder || formatMessage({ id: 'selectDate' })}
                            parseDateFromString={onParseDateFromString}
                            strings={defaultDatePickerStrings}
                            {...otherProps}
                            styles={mergedStyles}
                        />

                        {error?.message ? (
                            <Text variant='small' styles={{root: {color: theme.semanticColors.errorText}}}>
                                {error.message}
                            </Text>
                        ) : null}
                    </>
                )
            }}
        />
    );
}
