import {IPaginationFilter} from "../../../interfaces";
import {createContext, useContext} from "react";
import {DefaultPaginationPageSize} from "../../../../../constants/paginationSettings";

export interface ICreditNotesFilter extends IPaginationFilter {
    
}

export interface ICreditNotesContext {
    filter: ICreditNotesFilter;
    setFilter: (filter: IPaginationFilter) => void;
}

export const CreditNotesContext = createContext<ICreditNotesContext>({
    filter: {
        pageNumber: 1,
        pageSize: DefaultPaginationPageSize
    },
    setFilter: () => {}
});

export const useCreditNoteContext = () => useContext<ICreditNotesContext>(CreditNotesContext);