import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export interface IClient {
    clientGuid: string;
    name: string;
}

export interface IUser {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    displayName: string;
    employeeId: number;
    b2CObjectId: string;
    accessToDashboard: boolean;
    isSuperUser: boolean;
    isDeleted: boolean;
    clients: Client[];
}

export interface IUsersFilterData {
    shownItemsCount: number;
    totalItemsCount: number;
    items: IUser[];
}

interface Props {
    includeDeleted?: boolean;
    isPaginationEnabled?: boolean;
    onlySuperUsers?: boolean;
    searchString?: string;
    orderBy?: string;
    orderDirection?: string;
    pageSize?: number;
    page?: number;
}

function useGetUsersFilter({
    includeDeleted,
    isPaginationEnabled = true,
    onlySuperUsers,
    searchString,
    orderBy,
    orderDirection,
    pageSize,
    page
}: Props) {
    const context = React.useContext(Context);

    const { data: usersFilterData, isLoading, isFetching, refetch } = useQuery(
        ['getUsersFilter', includeDeleted, isPaginationEnabled, onlySuperUsers, searchString, orderBy, orderDirection, pageSize, page],
        () => axios.get<IUsersFilterData>(`${context?.config?.userUrl}/api/1.0/User/filter`, {
            ...commonHeaders(),
            params: {
                includeDeleted,
                isPaginationEnabled,
                onlySuperUsers,
                searchString,
                orderBy,
                orderDirection,
                pageSize,
                page,
            },
        }),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId,
        }
    );

    return { usersFilterData, isLoading, isFetching, refetch };
}

export { useGetUsersFilter };