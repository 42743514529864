import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {SuggestedTestingType} from "../../enums";
import {TableType} from "../../../../enums";

type MutateDataType = {
    id: number;
    jobId: number;
    type: SuggestedTestingType;
    investment: string | null;
    value: number | null;
    nonDataFedSampleSize: number | null;
    dataFedSampleSize: number | null;
    useReducedTesting: boolean;
    glatr: string | null;
    fundSuspense: string | null;
    balRec: string | null;
    feed: string | null;
}

function useUpdateSuggestedTesting() {
    const context = useContext(Context);
    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put<MutateDataType>(`${context?.config?.suggestedTestingUrl}/api/1.0/jobs/${data.jobId}/suggestedTesting/${data.id}`, data));

    return {update, isUpdating};
}

export {useUpdateSuggestedTesting};