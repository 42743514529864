import {Dropdown, IDropdownOption, useTheme} from '@fluentui/react';
import {Controller, Path} from 'react-hook-form';
import * as React from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    options: IDropdownOption[];
    multiSelect?: boolean;
    dropdownWidth?: number | 'auto';
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    color?: string;
    onValueChange?: (value: any | any[]) => void;
}

export function ControlledDropdown<FormValues>({
                                                   name,
                                                   control,
                                                   rules,
                                                   label,
                                                   options,
                                                   multiSelect,
                                                   dropdownWidth,
                                                   placeholder,
                                                   disabled,
                                                   required,
                                                   ...otherProps
}: Props<FormValues>) {
    const theme = useTheme();

    const defaultStyles = {
        caretDown: {
            color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText
        },
        label: { color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
        dropdown: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            selectors: {
                '.ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: (otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText) + ' !important' },
                '&:hover .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:focus .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:active .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:hover': { color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:hover .ms-Dropdown-caretDown': { color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:active .ms-Dropdown-caretDown': { color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '&:focus .ms-Dropdown-caretDown': { color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText },
                '.ms-Dropdown-title.ms-Dropdown-titleIsPlaceHolder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
            }
        },
        dropdownOptionText: {
            color: otherProps.color ?? theme.schemes?.default?.semanticColors.bodyText
        },
        // title: {
        //     backgroundColor: otherProps.color
        // }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                <Dropdown
                    label={label}
                    options={options}
                    required={required}
                    onChange={(_e, option) => {
                        if (multiSelect) {
                            let newValue = [...(value as string[])];
                            if (newValue.includes(option?.key as string)) {
                                newValue = newValue.filter((v) => v !== option?.key);
                            } else {
                                newValue.push(option?.key as string);
                            }

                            onChange(newValue);
                            otherProps.onValueChange?.(newValue);
                        } else {
                            onChange(option?.key);
                            otherProps.onValueChange?.(option)
                        }
                    }}
                    selectedKey={multiSelect ? undefined : (value as string)}
                    selectedKeys={multiSelect ? (value as string[]) : undefined}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    multiSelect={multiSelect}
                    dropdownWidth={dropdownWidth}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...otherProps}
                    styles={defaultStyles}
                />
            )}
        />
    );
}
