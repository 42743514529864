import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {EntityStatus, FilteredResult, FundInfo, IPaginationFilter} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";

export interface IFundPaginationFilter extends IPaginationFilter {
    status?: EntityStatus | null;
    name?: string | null;
    clientGuid?: string | null;
    clientName?: string;
}


function useGetFundsByFilter(props: IFundPaginationFilter) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();

    const {data: dataFundsFilter, isLoading: isLoadingFundsFilter} = useQuery(
        ['getFundsByFilter', props],
        () =>
            axios.get<FilteredResult<FundInfo>>(
                `${context?.config?.clientUrl}/api/1.0/funds/filter`,
                {
                    ...commonHeaders(),
                    params: props
                }
            ),
        {
            enabled: !!context?.config?.clientUrl && !!context?.tenantId,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {dataFundsFilter, isLoadingFundsFilter};
}

export {useGetFundsByFilter};
