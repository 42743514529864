import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {
    Control,
    FieldPath,
    FieldPathValue, SetValueConfig,
    UnpackNestedValue,
    useFieldArray,
    useForm,
    UseFormGetValues
} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {
    DefaultButton,
    IColumn,
    IconButton,
    IDropdownOption, mergeStyleSets,
    PrimaryButton,
    Spinner, SpinnerSize,
    Stack,
    TextField, useTheme
} from "@fluentui/react";
import {
    Card,
    ControlledDatePicker,
    ControlledDropdown,
    ControlledNumberField,
    DataTable,
    Modal
} from "../../../../../components";
import {IInvoice, IInvoiceExtraAmount, NewInvoice, useInvoicesContext} from "../interfaces";
import {BillingEntityType} from "../../../enums";
import {InvoiceParagraphType} from "../enums";
import {useBoolean} from "@fluentui/react-hooks";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {
    _BilllingEntityDropdownOptions,
    _InitialExtraAmountSumValue,
    _ParagraphDropdownOptions,
    calculateExtraAmountSum, calculateTotal
} from "../helpers";
import {useCreateInvoice} from "../hooks";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";

interface IInvoiceAddFormPros {
    item: IInvoice;
    onClose: () => void;
    onSubmit?: (item: IInvoice) => void;
}

interface IInvoiceAddFormItem {
    amount: number;
    invoiceDate: string;
    paragraph: number;
    billingEntity: number;
    extraAmounts: IInvoiceExtraAmount[];
}

export const InvoiceAddForm: FunctionComponent<IInvoiceAddFormPros> = ({ item, onClose, ...props }: IInvoiceAddFormPros) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();
    
    const { clientIdentifier } = useInvoicePortalContext();
    const { create, isCreating } = useCreateInvoice(clientIdentifier);
    
    const [totalAmount, setTotalAmount] = useState<number>(0);
    
    const { control, watch, handleSubmit, formState, getValues, setValue } = useForm<IInvoiceAddFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            paragraph: InvoiceParagraphType.DD,
            billingEntity: BillingEntityType.Client,
            extraAmounts: []
        }
    });

    const amountsWatcher = watch(['extraAmounts', 'amount']);
    
    const onSubmit = (formItem: IInvoiceAddFormItem) => {
        create({
            requests: [{
                billingEntityType: formItem.billingEntity,
                paragraph: formItem.paragraph,
                gst: item.gst,
                amount: formItem.amount,
                extraAmounts: [...formItem.extraAmounts],
                jobId: item.invoiceJobs[0].jobId,
                creationDate: new Date(formItem.invoiceDate)
            } as NewInvoice],
            applyCreditNotes: false
        }, {
            onSuccess: (resp: any) => {
                props.onSubmit?.(resp.data);
            }
        })
    }

    useEffect(() => {
        setTotalAmount(calculateTotal((+getValues('amount') ?? 0), calculateExtraAmountSum(getValues('extraAmounts'))));
    }, [amountsWatcher]);
    
    const fieldWidth = 'calc(33% - 16px)';

    const classNames = mergeStyleSets({
        field_disabled: {
            'label': {
                color: theme.schemes?.default?.semanticColors.bodyText
            },
            'input': {
                color: theme.palette.blackTranslucent40
            }
        }
    })
    
    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                        <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledNumberField
                                    name='amount'
                                    control={control}
                                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                                    label={formatMessage({ id: 'amount' })}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ExtraAmountControl control={control as any} disabled={isCreating} getValues={getValues} setValue={setValue as any}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <TextField
                                    className={classNames.field_disabled}
                                    label={formatMessage({ id: 'total' })}
                                    disabled
                                    value={formatNumber(totalAmount, { style: 'currency', currency: 'USD' })}
                                />
                            </Stack.Item>

                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDatePicker
                                    control={control}
                                    name='invoiceDate'
                                    rules={{ required: formatMessage({id: 'creationDateRequired'}) }}
                                    label={formatMessage({ id: 'creationDate' })}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDropdown
                                    name='paragraph'
                                    control={control}
                                    label={formatMessage({ id: 'invoiceParagraph' })}
                                    options={_ParagraphDropdownOptions}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDropdown
                                    name='billingEntity'
                                    control={control}
                                    label={formatMessage({ id: 'billingEntity' })}
                                    options={_BilllingEntityDropdownOptions}
                                />
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                        <Stack.Item>
                            <PrimaryButton disabled={!formState.isDirty || !formState.isValid || isCreating}
                                           onClick={handleSubmit(onSubmit)} >
                                {isCreating
                                    ? <Spinner size={SpinnerSize.small}></Spinner>
                                    : formatMessage({id: 'save'})}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton disabled={isCreating} text={formatMessage({id: 'close'})} onClick={onClose} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    )
}

interface IExtraAmountControlProps {
    control: Control<Pick<IInvoiceAddFormItem, 'extraAmounts'>>
    getValues: UseFormGetValues<Pick<IInvoiceAddFormItem, 'extraAmounts'>>;
    setValue: UseFormSetValue<Pick<IInvoiceAddFormItem, 'extraAmounts'>>;
    
    disabled?: boolean
}

const ExtraAmountControl: FunctionComponent<IExtraAmountControlProps> = ({ control, getValues, setValue, disabled }: IExtraAmountControlProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();

    const [extraAmountModalOpen, { toggle: toggleExtraAmountModalOpen }] = useBoolean(false);
    
    const [extraAmountSum, setExtraAmountSum] = useState<number>(_InitialExtraAmountSumValue);
    
    const onSubmit = (items: IInvoiceExtraAmount[]) => {
        setValue('extraAmounts', items ?? []);
        setExtraAmountSum(calculateExtraAmountSum(items))
        toggleExtraAmountModalOpen()
    }

    useEffect(() => {
        setExtraAmountSum(calculateExtraAmountSum(getValues('extraAmounts')))
        return () => {}
    }, []);
    
    const classNames = mergeStyleSets({
        field_disabled: {
            'label': {
                color: 'rgb(0, 0, 0)'
            },
            'input': {
                color: theme.palette.blackTranslucent40
            }
        }
    })
    
    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='end'>
                <Stack.Item grow={10}>
                    <TextField className={classNames.field_disabled}
                        label={formatMessage({ id: 'extraAmount' })}
                        disabled
                        value={formatNumber(extraAmountSum, { style: 'currency', currency: 'USD' })}
                    />
                </Stack.Item>
                <Stack.Item>
                    <IconButton iconProps={{iconName: 'Add'}}
                                disabled={!!disabled}
                                text={formatMessage({ id: 'add' })}
                                onClick={toggleExtraAmountModalOpen} />
                </Stack.Item>
            </Stack>

            <Modal title={formatMessage({id: 'extraAmount'})}
                   width={300}
                   isOpen={extraAmountModalOpen}
                   onDismiss={toggleExtraAmountModalOpen}
            >
                <Card>
                    <ExtraAmountAddForm items={getValues('extraAmounts')} 
                                        onClose={toggleExtraAmountModalOpen}
                                        onSubmit={onSubmit}
                    />
                </Card>
            </Modal>
        </>
    )
}

interface IExtraAmountAddFormProps {
    items: IInvoiceExtraAmount[],
    onSubmit: (items: IInvoiceExtraAmount[]) => void,
    onClose: () => void;
}

interface IExtraAmountAddFormItem {
    extraAmounts: IInvoiceExtraAmount[];
}

const ExtraAmountAddForm: FunctionComponent<IExtraAmountAddFormProps> = ({ items, onClose, ...props }: IExtraAmountAddFormProps) => {
    const { formatMessage } = useIntl();
    const { extraAmountParagraphs } = useInvoicesContext();

    const _defaultExtraAmountItem: IInvoiceExtraAmount = { paragraph: 1 } as IInvoiceExtraAmount;
    
    const { control, handleSubmit, formState } = useForm<IExtraAmountAddFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            extraAmounts: (items ?? []).length ? [...items] : [{..._defaultExtraAmountItem}]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'extraAmounts'
    })
    
    const options: IDropdownOption[] = useMemo(() => {
        return [
            ...Object.keys(extraAmountParagraphs).filter(k => !isNaN(+k)).map(k => ({ key: extraAmountParagraphs[+k].id, text: extraAmountParagraphs[+k].title }))
        ]
    }, [formatMessage, extraAmountParagraphs])
    
    const onAddClick = () => {
        append({..._defaultExtraAmountItem})
    }
    
    const onSubmit = (formItems: IExtraAmountAddFormItem) => {
        props.onSubmit(formItems.extraAmounts ?? [])
    }
    
    const classNames = mergeStyleSets({
        dropdown: {
            width: '100%',
            '.ms-Dropdown-container': {
                width: '100%'
            }
        }
    })
    
    const columns: IColumn[] = useMemo(() => [
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: IInvoiceExtraAmount, index?: number) => (
                <ControlledNumberField
                    name={`extraAmounts.${index!}.amount`}
                    control={control}
                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                />
            ),
        },
        {
            key: 'paragraphName',
            name: formatMessage({ id: 'invoiceParagraph' }),
            fieldName: 'paragraphName',
            minWidth: 140,
            onRender: (item: IInvoiceExtraAmount, index?: number) => (
                <Stack className={classNames.dropdown} grow>
                    <Stack.Item>
                        <ControlledDropdown
                            name={`extraAmounts.${index!}.paragraph`}
                            control={control}
                            options={options}
                            rules={{ required: formatMessage({id: 'paragraphRequired'}) }}
                        />
                    </Stack.Item>
                </Stack>
                
            ),
        },
        {
            key: 'action',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IInvoiceExtraAmount, index?: number) => (
                <IconButton iconProps={{iconName: 'Remove'}}
                            text={formatMessage({ id: 'remove' })}
                            onClick={() => remove(index)}
                />
            ),
        },
    ], [formatMessage]);
    
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <DataTable initialColumns={columns}
                           hideIfEmpty={false}
                           items={fields || []}
                />
            </Stack.Item>
            <Stack.Item>
                <IconButton iconProps={{iconName: 'Add'}}
                            disabled={!formState.isValid}
                            text={formatMessage({ id: 'add' })}
                            onClick={() => onAddClick()} />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty || !formState.isValid}
                                       onClick={handleSubmit(onSubmit)}>
                            {formatMessage({id: 'save'})}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton text={formatMessage({id: 'close'})} onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}