import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

export type EmailTemplate = {
    queriesEmailSubject: string;
    queriesEmailBody1: string;
    queriesEmailBody2: string;
    auditDispatchEmailSubject: string;
    auditDispatchEmailBody: string;
    passwordRecoveryEmailSubject: string;
    passwordRecoveryEmailBody: string;
    setPasswordEmailSubject: string;
    setPasswordEmailBody: string;
    detailedAuditWorkflowReportEmailSubject: string;
    detailedAuditWorkflowReportEmailBody: string;
    summarisedWorkflowReportEmailSubject: string;
    summarisedWorkflowReportEmailBody: string;
    outstandingQueriesReportEmailSubject: string;
    outstandingQueriesReportEmailBody: string;
    debtorsStatementEmailSubject: string;
    debtorsStatementEmailBody: string;
    jobLetterEmailSubject: string;
    jobLetterEmailBody: string;
    evolvGreyDispatchSubject: string;
    evolvGreyDispatchBody: string;
    monthlyAgedBalanceReportSubject: string;
    monthlyAgedBalanceReportBody: string;
    oAuthLinkEmailSubject: string;
    oAuthLinkEmailBody: string;
    contraventionEventEmail: string;
    contraventionPartnerReviewEmail: string;
    contraventionManagerReviewEmail: string;
    draftAuditReportEmailSubject: string;
    draftAuditReportEmailBody: string;
};

/**
 * Query for get Email Template for Portal Settings.
 * Page: Email Templates
 */

function useEmailTemplateGet() {
    const context = React.useContext(Context);

    const {data: emailTemplate, isLoading} = useQuery(
        ['getEmailTemplate'],
        () => axios.get<EmailTemplate>(`${context?.config?.settingsUrl}/api/1.0/Settings/emailTemplates`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return {emailTemplate, isLoading};
}

export {useEmailTemplateGet};
