import {IAdministrationListItem, IButtonConfig} from 'interfaces';
import {useNavigate} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {FunctionComponent, useEffect, useMemo} from 'react';
import {AdministrationList} from './AdministrationList';
import {useIntl} from 'react-intl';
import {useBoolean} from '@fluentui/react-hooks';
import {EditClientModal} from './modals/EditClientModal';
import {AddFundModal} from './modals/AddFundModal';
import {useGetUserClients} from "../hooks/useGetUserClients";
import {useAdministrationContext} from "../JobsLayoutPage";

export const ClientsList: FunctionComponent = () => {
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const {filters, setSelectedClient, setTargetClient, targetClient} = useAdministrationContext();
    const {clientsData, isClientsLoading: isClientLoading} = useGetUserClients({});
    const [openAddFundModal, {toggle: toggleOpenAddFundModal}] = useBoolean(false);
    const [openEditClientModal, {toggle: toggleOpenEditClientModal}] = useBoolean(false);

    const buttonsConfig: IButtonConfig[] = [
        {
            text: formatMessage({id: 'addFund'}),
            type: 'primary',
            onClick: (data) => {
                setTargetClient(data);
                toggleOpenAddFundModal();
            },
        },
        {
            text: formatMessage({id: 'editClient'}),
            type: 'primary',
            onClick: (data) => {
                setTargetClient(data);
                toggleOpenEditClientModal();
            },
        },
    ];

    const onSubmitEditClientModal = (data: any) => {
        console.log(data, 'submit edit client data');
    };

    const onCardClick = (_: React.MouseEvent<HTMLElement>, id: string, data: IAdministrationListItem) => {
        setSelectedClient(data);
        navigate(RouterPaths.administration.jobs.funds.root(data.guid));
    };

    const clients = useMemo(() => {
        const clients = clientsData?.data?.map(({id, name, guid}) => ({
            id,
            name,
            guid: guid
        })) as IAdministrationListItem[];
        const searchClients = clients?.filter((client) => client.name.toLowerCase().includes(filters.search?.toLowerCase() as string));
        return searchClients || [];
    }, [filters, clientsData?.data])

    return (
        <>
            <AdministrationList
                isLoading={isClientLoading}
                onCardClick={onCardClick}
                items={clients}
                title={filters.search ? formatMessage({id: 'clients'}) : ''}
                buttonsConfig={buttonsConfig}
            />
            <EditClientModal open={openEditClientModal} toggle={toggleOpenEditClientModal}
                onSubmit={onSubmitEditClientModal} clientGuid={targetClient?.guid ?? ''}/>
            <AddFundModal open={openAddFundModal} toggle={toggleOpenAddFundModal} clientGuid={targetClient?.guid ?? ''} clientId={targetClient?.id ?? 0} />
        </>
    );
};
