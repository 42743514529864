import {useContext} from "react";
import {Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";


export interface IJobDispatchInfo {
    id: number;
    guid: string;
    fundName: string;
    fundAbn: string;
    fundTrustee: string;
    clientName: string;
    addressLine1: string;
    addressLine2: string | null;
    auditingEntity: string;
    fundType: string | null;
    auditFinalised:string;
}

function useGetDispatchInfoByJobId(jobId: number) {
    const context = useContext(Context);

    const {
        data: response,
        isLoading,
        refetch, isRefetching
    } = useQuery(['jobDispatchInfo', jobId], () => axios.get<IJobDispatchInfo>(`${context?.config?.jobUrl}/api/1.0/job/${jobId}/dispatch`));


    return {response, isLoading, refetch, isRefetching};
}

export {useGetDispatchInfoByJobId};