import { IColumn } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import { dateFortmatString, formatDate, formatStringDate } from "utils";
import {ReportTemplateTypeProps} from "../Types";
import { ReportMoneyValueCell } from "../shared/cells";



export const SuperMateGeneralLedger = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'accountNumber',
                name: formatMessage({ id: 'accountNumber' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountNumber',
                onRender: (item, _, column) => {
                    return (
                        <SanitizedText
                            data={item[column?.fieldName || 0]}
                            isHeadingRow={item?.isHeadingRow || item?.isBold}
                            format={item?.format}
                            numberFormat={'text'}
                        />
                    )
                },
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={formatStringDate(item[column?.fieldName || 0], 'DD/MM/yyyy')}
                        isHeadingRow={item?.isHeadingRow || item?.isBold}
                        format={item?.format}
                    />
                ),
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => (
                    <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} />
                ),
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amount',
                onRender: (item, _, column) => (
                    <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} />
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'description' }),
                minWidth: 400,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isBold}
                        format={item?.format}
                    />
                ),
            },
            {
                key: 'opening',
                name: formatMessage({ id: 'opening' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'opening',
                onRender: (item, _, column) => (
                    <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} />
                ),
            },
            {
                key: 'closing',
                name: formatMessage({ id: 'closing' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closing',
                onRender: (item, _, column) => (
                    <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} />
                ),
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} openAll={true} items={items} isFetching={isFetching} />;
};
