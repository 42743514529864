import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {JobInfo} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useNotifications} from "../../../../components/notifications";


function useGetJobByGuid(jobGuid: string) {
    const context = React.useContext(Context);
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {data: job, refetch, isRefetching, isLoading: isJobLoading} = useQuery(
        ['getJobByGuid', jobGuid],
        () => axios.get<JobInfo>(`${context?.config?.jobUrl}/api/1.0/job/${jobGuid}`, commonHeaders()),
        {
            enabled: !!context?.config?.jobUrl && !!context.tenantId && !!jobGuid,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {job, refetch, isRefetching, isJobLoading};
}

export {useGetJobByGuid};
