import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {commonHeaders, Context} from "utils";
import React from "react";
import {EntityStatus, FilteredResult, IPaginationFilter, JobInfo} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useNotifications} from "../../../../components/notifications";

export interface IJobPaginationFilter extends IPaginationFilter {
    status?: EntityStatus | null;
    fundName?: string;
    fundId?: number;
    dateArSentFrom?: Date | null;
    dateArSentTo?: Date | null;
    year?: number;
}


function useGetJobsByFilter(params: IJobPaginationFilter) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {data: jobs, isLoading: isJobLoading, isFetching, isRefetching} = useQuery(
        ['getJobsByFilter', params],
        () => axios.get<FilteredResult<JobInfo>>(`${context?.config?.jobUrl}/api/1.0/job/filter`, {
            ...commonHeaders(),
            params: params
        }),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {jobs, isJobLoading, isFetching, isRefetching};
}

export {useGetJobsByFilter}