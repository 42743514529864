import React, {FunctionComponent} from "react";
import {IconButton} from "@fluentui/react/lib/Button";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {FontSizes, IButtonStyles, TooltipHost, useTheme} from "@fluentui/react";
import {useIntl} from "react-intl";
import {Modal} from 'components';
import {QueryCommentForm} from "./QueryCommentForm";
import {useUpdateAuditQueryComment} from "../hooks/useUpdateAuditQueryComment";
import {useJobContext} from "../../../../../JobPortalLayoutPage";

interface IQueryCommentProps {
    itemId: number;
    comment?: string;
}

export const QueryComment: FunctionComponent<IQueryCommentProps & { onUpdate?: (updated: IQueryCommentProps) => void }> = ({
                                                                          ...props
                                                                      }: IQueryCommentProps & { onUpdate?: (updated: IQueryCommentProps) => void }) => {

    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const theme = useTheme();
    
    const {update, isUpdating} = useUpdateAuditQueryComment()
    
    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    const buttonId = useId('callout-button');
    const tooltipId = useId('comment-tooltip-' + props.itemId);

    const calloutProps = { gapSpace: 0 };

    const styles: IButtonStyles = {
        root: {
            color: props.comment ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color:  props.comment ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color:  props.comment ? theme.palette.red : theme.palette.themePrimary,
        }
    }
    
    const onSave = (newComment?: string) => {
        if (newComment === props.comment) {
            return;
        }
        
        update({
            jobId: jobId,
            id: props.itemId,
            comment: newComment
        }, {
            onSuccess: () => {
                toggleOpenModal()
                props.onUpdate?.({ itemId: props.itemId, comment: newComment })
            }
        })
    }
    
    return (
        <>
            <TooltipHost
                content={props.comment}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <IconButton id={buttonId}
                            iconProps={{ iconName: 'Comment', style: {fontSize: FontSizes.size14}}}
                            styles={styles}
                            onClick={toggleOpenModal}
                />
            </TooltipHost>
            
            
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} title={formatMessage({id: 'comments'})}>
                <QueryCommentForm
                    data={props.comment}
                    itemId={props.itemId}
                    onCancel={toggleOpenModal}
                    disabled={false}
                    isUpdating={isUpdating}
                    onSave={onSave}>
                </QueryCommentForm>
            </Modal>
        </>
    )
}

interface IQueryClientCommentProps extends IQueryCommentProps {
    disabled?: boolean
}

export const QueryClientComment: FunctionComponent<IQueryClientCommentProps> = ({ 
                                                                                    disabled = true,
                                                                                    ...props
                                                                                }: IQueryClientCommentProps) => {

    const {formatMessage} = useIntl();
    const theme = useTheme();

    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    const buttonId = useId('callout-button');
    const tooltipId = useId('client-comment-tooltip-' + props.itemId);

    const calloutProps = { gapSpace: 0 };
    
    const styles: IButtonStyles = {
        root: {
            color: props.comment ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color:  props.comment ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color:  props.comment ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    return (
        <>
            <TooltipHost
                content={props.comment}
                id={tooltipId}
                calloutProps={calloutProps}
            >
                <IconButton id={buttonId} aria-describedby={tooltipId}
                            iconProps={{ iconName: 'Contact', style: {fontSize: FontSizes.size14}}}
                            styles={styles}
                            onClick={toggleOpenModal} />
            </TooltipHost>

            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} title={formatMessage({id: 'clientComments'})}>
                <QueryCommentForm
                    data={props.comment}
                    itemId={props.itemId}
                    onCancel={toggleOpenModal}
                    onSave={() => {}}
                    isUpdating={false}
                    disabled={disabled}>
                </QueryCommentForm>
            </Modal>
        </>
    )
}

