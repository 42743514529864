import {FunctionComponent} from 'react';
import {Stack, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useProcedureContext} from 'pages/JobPortal/components';
import {PrintToPdfGeneralOptions} from "./PrintToPdfGeneralOptions";
import {PrintToPdfDocuments} from "./PrintToPdfDocuments";
import {PrintToPdfSchedules} from "./PrintToPdfSchedules";


export const PrintToPDFProcedureTemplate: FunctionComponent = () => {
    const {items} = useProcedureContext();

    return (
        <Stack grow tokens={{childrenGap: 16}}>
            <Stack grow horizontal tokens={{childrenGap: 16}}>
                <Stack.Item grow={1}>
                    <PrintToPdfGeneralOptions title={items && items?.length ? items[0].title : ''}
                                              items={items && items?.length ? items[0].children : null}/>
                </Stack.Item>
                <Stack.Item grow={2}>
                    <PrintToPdfDocuments title={items && items?.length ? items[1].title : ''}/>
                </Stack.Item>
            </Stack>
            <PrintToPdfSchedules/>
        </Stack>
    );
};