import {FunctionComponent} from "react";
import {InvoiceStatusType} from "../enums";
import {Badge, BadgeType} from "../../../../../components";

interface IInvoiceStatusProps {
    status: InvoiceStatusType;
}

export const InvoiceStatus: FunctionComponent<IInvoiceStatusProps> = ({status}: IInvoiceStatusProps) => {

    let statusColor: BadgeType;
    let statusName: string;

    switch (status) {
        case InvoiceStatusType.NotCreated:
            statusColor = "info";
            statusName = 'Not Created';
            break;
        case InvoiceStatusType.Closed:
            statusColor = 'danger';
            statusName = 'Closed';
            break;
        case InvoiceStatusType.Open:
            statusColor = 'success';
            statusName = 'Open';
            break;
        default:
            statusColor = 'neutral';
            statusName = 'Undefined';
            break;
    }

    return (
        <Badge type={statusColor} fullWidth text={statusName}/>
    );
};
