import {EmployeePosition} from "../../../shared";
import {DetailedSoftwareType, SoftwareType} from "../../../../../enums";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants/forms";
import {useIntl} from "react-intl";
import {useGetEmployeesByPosition} from "../../../Employees";
import {useMemo} from "react";
import {IDropdownOption, Stack} from "@fluentui/react";
import {Card, ControlledDropdown, ControlledTextField, ModalWithForm} from "../../../../../components";
import {useCreateTrustee} from "../../hooks";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export interface ICreateTrusteeCommand {
    name: string;
    type: "Corporate" | "Individual"|null;
    accountingSoftware: SoftwareType | DetailedSoftwareType;
}

export const CreateTrusteeModal = ({onDismiss, isOpen, title}: Props) => {

    const defaultFormValues = {
        name: '',
        type:null,
        accountingSoftware: SoftwareType.BGL360
    };
    const {control, handleSubmit, formState, reset} = useForm<ICreateTrusteeCommand>({
        ...DefaultFormSettings,
        defaultValues: defaultFormValues,
    });
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {createTrustee, isCreating} = useCreateTrustee();

    const onSubmit = (data: ICreateTrusteeCommand) => {
        createTrustee({
            name: data.name || '',
            type: data.type!,
            detailedSoftwareType: data.accountingSoftware as DetailedSoftwareType || null,
            softwareType: data.accountingSoftware?.includes('ClassSuper') ? SoftwareType.ClassSuper :
                data.accountingSoftware as SoftwareType || null
        })
        ;
    };


    const optionsSoftware: IDropdownOption[] = [
        {key: SoftwareType.BGL360, text: formatMessage({id: 'bgl360'})},
        {key: SoftwareType.Nothing, text: formatMessage({id: 'other'})},
        {key: DetailedSoftwareType.ClassSuperAPI, text: formatMessage({id: 'classSuperApi'})}
    ];

    return (
        <ModalWithForm isOpen={isOpen}
                       onDismiss={() => {
                           reset();
                           onDismiss();
                       }}
                       title={title}
                       isSubmitting={isCreating}
                       submitDisabled={!formState.isDirty || !formState.isValid || isCreating}
                       onSubmit={handleSubmit(onSubmit)}>
            <Stack tokens={{childrenGap: 16}}>
                <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='name'
                                required
                                label={formatMessage({id: 'trusteeName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDropdown options={[
                                {key: "Corporate", text: 'Corporate'},
                                {key: 'Individual', text: 'Individual'}
                            ]} 
                                                placeholder={formatMessage({id: 'selectTrusteeType'})}
                                                name='type'
                                                required
                                                rules={{
                                                    required: formatMessage({id: 'requiredField'}),
                                                    minLength: {
                                                        value: 3,
                                                        message: formatMessage({id: 'minLength'}, {min: 3})
                                                    },
                                                }}
                                                label={formatMessage({id: 'trusteeType'})}
                                                control={control}/>
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDropdown
                                options={optionsSoftware}
                                name='accountingSoftware'
                                label={formatMessage({id: 'accountingSoftware'})}
                                control={control}
                                required
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
            </Stack>
        </ModalWithForm>
    );
}
