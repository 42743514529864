import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { IJobComment, IPermanentJobComment } from './useGetJobComments';
export interface IJobCommentUpdateBody {
    fundId: number;
    jobId: number;
    itemId: number;
    comment: IJobComment | null;
    permanentComment: IPermanentJobComment | null;
    externalComment: IJobComment | null;
    externalPermanentComment: IPermanentJobComment | null;
    isExternalHidden?: boolean | null;
}


interface IUpdateJobComment {
    fundId: number;
    jobId: number;
    itemId: number;
    body: IJobCommentUpdateBody;
}
    
function useUpdateJobComments() {
    const context = React.useContext(Context);

    const { mutate: updateJobComment, isLoading, isSuccess } = useMutation(({ fundId, jobId, itemId, body }: IUpdateJobComment) =>
        axios.put(`${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/items/${itemId}/comments`, body, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getJobComments'),
        }
    );

    return { updateJobComment, isLoading, isSuccess };
}

export { useUpdateJobComments };