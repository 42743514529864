import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Stack } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { VerticalSubmenu } from 'components/VerticalSubmenu';
import { ContactTab } from '../ContactTab';
import { ControlledTextField } from 'components';
import { useCreateFund } from '../../hooks/useCreateFund';
import { useGetClientByGuid } from '../../hooks/useGetClientByGuid';

interface Props {
    open: boolean;
    toggle: () => void;
    title?: string;
    clientGuid: string;
    clientId: number;
}

interface IFundForm {
    contactId: number;
    contactGuid: string;
    firstName: string;
    lastName: string;
    email: string;
    businessPhone: string;
    fundName: string;
    trusteeName: string;
    abn: string;
}

interface IFundFormProps {
    clientGuid: string;
    clientId: number;
    close: () => void;
}

const FundModalForm: FunctionComponent<IFundFormProps> = ({ clientGuid, clientId, close }) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, watch, setValue, formState, getValues } = useForm<IFundForm>({
        ...DefaultFormSettings,
        defaultValues: {
            contactId: 0,
            contactGuid: '',
            firstName: '',
            lastName: '',
            email: '',
            businessPhone: '',
            fundName: '',
            trusteeName: '',
            abn: '',
        },
        reValidateMode: "onChange",
    });

    const [selectedTab, setSelectedTab] = useState<'contact' | 'fund'>('fund');

    const links = [
        {
            name: 'Fund',
            key: 'fund',
            url: '',
        },
        {
            name: 'Contact',
            key: 'contact',
            url: '',
        },
    ];

    const { currentClientData } = useGetClientByGuid(clientGuid);
    const { createFund, isLoading } = useCreateFund();

    const onSubmit = (data: IFundForm) => {
        createFund({
            abn: data.abn,
            contactId: data.contactGuid,
            clientId: clientId,
            name: data.fundName,
            primaryContactId: data.contactId === 0 ? null : data.contactId,
            trustee: data.trusteeName
        },
            {
                onSuccess: () => close()
            });
    }

    useEffect(() => {
        if (!!currentClientData?.data.primaryContact) {
            setValue('contactGuid', currentClientData.data.primaryContact.guid);
            setValue('contactId', currentClientData.data.primaryContact.id);
        }
    }, [currentClientData?.data.primaryContact]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        setIsButtonDisabled(isLoading || !getValues().fundName || !getValues().contactGuid);
    }, [isLoading, formState.dirtyFields.fundName, formState.dirtyFields.contactGuid]);

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <VerticalSubmenu setSelected={setSelectedTab} defaultValue={selectedTab} links={links} />
                    <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                        {selectedTab === 'fund' && (
                            <>
                                <Stack.Item>
                                    <ControlledTextField required name='fundName' control={control} label={formatMessage({ id: 'fundName' })} />
                                </Stack.Item>
                                <Stack.Item>
                                    <ControlledTextField
                                        name='trusteeName'
                                        control={control}
                                        label={formatMessage({ id: 'trusteeName' })}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ControlledTextField name='abn' control={control} label={formatMessage({ id: 'abn' })} />
                                </Stack.Item>
                            </>
                        )}
                        {selectedTab === 'contact' && (
                            <ContactTab control={control} watch={watch} setValue={setValue} contacts={currentClientData?.data.contacts ?? []} primaryContact={currentClientData?.data.primaryContact} />
                        )}
                    </Stack>
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                <PrimaryButton
                    disabled={isButtonDisabled}
                    onClick={handleSubmit((data) => onSubmit(data))}
                    text={formatMessage({ id: 'save' })}
                />
            </ModalFooter>
        </Stack>
        );
}

export const AddFundModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Modal title={props?.title || formatMessage({ id: 'addFund' })} isOpen={props.open} onDismiss={props.toggle}>
            <FundModalForm clientGuid={props.clientGuid} clientId={props.clientId} close={props.toggle} />
        </Modal>
    );
};
