import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";

export interface IRequiredDocumentsInfo {
    description: string;
    items: string[];
}

function useGetTabRequiredDocuments(tabCommentId: number) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getTabRequiredDocuments', tabCommentId], () => axios.get<IRequiredDocumentsInfo[]>(`${context?.config?.annotationsUrl}/api/1.0/tabCommentsRequiredDocuments/${tabCommentId}`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.annotationsUrl && !!tabCommentId
    });

    return {response, isLoading, isFetching};
}

export {useGetTabRequiredDocuments};