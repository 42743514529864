import {FunctionComponent} from "react";
import {IconButton, useTheme} from "@fluentui/react";
import {Modal} from "../../../../../../../components";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {useIntl} from "react-intl";
import {IAuditQueryItem} from "../interfaces";
import {QueryEditForm} from "./QueryEditForm";
import {useSectionContext} from "../../../../Section";

interface IQueryEditProps {
    query: IAuditQueryItem;
    disabled: boolean;
}

export const QueryEdit: FunctionComponent<IQueryEditProps> = ({ 
                                                                  query,
                                                                  disabled
                                                              }: IQueryEditProps) => {
    const buttonId = useId('callout-button');
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const {refresh} = useSectionContext();
    
    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    
    const onModalClose = () => {
        toggleOpenModal();
        refresh?.();
    }
    
    return (
        <>
            <IconButton id={buttonId}
                        disabled={disabled}
                        onClick={toggleOpenModal}
                        iconProps={{iconName: 'Edit'}}
            />
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} title={formatMessage({id: 'standardPoints'})}>
                {isOpenModal && <QueryEditForm query={query} onAfterSave={onModalClose} onCancel={() => toggleOpenModal()} />}
            </Modal>
        </>
    )
}