import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, useTheme } from '@fluentui/react';
import { useRevokeConnection } from "./hooks/useRevokeConnection";
import { useRemoveApprover } from "./hooks";

interface Props {
    email: string;
}

export const RemoveApproevrButton: FunctionComponent<Props> = ({ email }: Props) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const {remove} = useRemoveApprover();
    
    const [open, setOpen] = useState(false);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'warning' }),
        subText: formatMessage({ id: 'doYouWishToRemoveApprover' }),
    };
    
    const onRemoveClick = (email: string) => {
        remove({ email: email });
        setOpen(false);
    }

    return (
        <>
            <ActionButton
                styles={{
                    root: { height: 32 },
                    icon: { color: theme.palette.red },
                    iconHovered: { color: theme.palette.red },
                    label: { color: theme.palette.blue, textDecoration: 'underline' },
                }}
                text={formatMessage({ id: 'remove' })}
                iconProps={{ iconName: 'Trash' }}
                onClick={() => setOpen(true)}
            />
            <Dialog hidden={!open} onDismiss={() => setOpen(false)} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <DefaultButton onClick={() => setOpen(false)} text={formatMessage({ id: 'cancel' })} />
                    <PrimaryButton
                        onClick={() => {
                            onRemoveClick(email)
                        }}
                        text={formatMessage({ id: 'ok' })}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};
