import { BaseSlots, getColorFromString, IThemeRules, ThemeGenerator, themeRulesStandardCreator } from '@fluentui/react';

export function generateTheme({
    primaryColor,
    textColor,
    backgroundColor,
    isDark,
}: {
    primaryColor: string;
    textColor: string;
    backgroundColor: string;
    isDark?: boolean;
}) {
    const themeRules = themeRulesStandardCreator();
    const colors = {
        primaryColor: getColorFromString(primaryColor)!,
        textColor: getColorFromString(textColor)!,
        backgroundColor: getColorFromString(backgroundColor)!,
    };

    const isCustomization = false;
    const overwriteCustomColor = true;
    

    ThemeGenerator.setSlot(
        themeRules[BaseSlots[BaseSlots.backgroundColor]],
        colors.backgroundColor,
        undefined,
        isCustomization,
        overwriteCustomColor
    );

    // const currentIsDark = isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!);
    const currentIsDark = isDark;

    ThemeGenerator.setSlot(
        themeRules[BaseSlots[BaseSlots.primaryColor]],
        colors.primaryColor,
        currentIsDark,
        isCustomization,
        overwriteCustomColor
    );
    ThemeGenerator.setSlot(
        themeRules[BaseSlots[BaseSlots.foregroundColor]],
        colors.textColor,
        currentIsDark,
        isCustomization,
        overwriteCustomColor
    );

    // strip out the unnecessary shade slots from the final output theme
    const abridgedTheme: IThemeRules = Object.entries(themeRules).reduce(
        (acc, [ruleName, ruleValue]) =>
            ruleName.indexOf('ColorShade') === -1 &&
            ruleName !== 'primaryColor' &&
            ruleName !== 'backgroundColor' &&
            ruleName !== 'foregroundColor' &&
            ruleName.indexOf('body') === -1
                ? {
                      ...acc,
                      [ruleName]: ruleValue,
                  }
                : acc,
        {} as IThemeRules
    );

    return ThemeGenerator.getThemeAsJson(abridgedTheme);
}

export function colorLuminance(hex: string, lum: number) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

const pad = function (num: string, totalChars: number) {
    var pad = '0';
    num = num + '';
    while (num.length < totalChars) {
        num = pad + num;
    }
    return num;
};

// Ratio is between 0 and 1
const changeColor = function (color: string, ratio: number, darker: boolean, opacity: number) {
    // Trim trailing/leading whitespace
    color = color.replace(/^\s*|\s*$/, '');

    // Expand three-digit hex
    color = color.replace(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i, '#$1$1$2$2$3$3');

    // Calculate ratio
    let difference = Math.round(ratio * 256) * (darker ? -1 : 1),
        // Determine if input is RGB(A)
        rgb = color.match(
            new RegExp(
                '^rgba?\\(\\s*' +
                    '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
                    '\\s*,\\s*' +
                    '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
                    '\\s*,\\s*' +
                    '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
                    '(?:\\s*,\\s*' +
                    '(0|1|0?\\.\\d+))?' +
                    '\\s*\\)$',
                'i'
            )
        ),
        alpha = opacity || null,
        // Convert hex to decimal
        decimal = !!rgb
            ? [rgb[1], rgb[2], rgb[3]]
            : color
                  .replace(/^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i, function () {
                      return parseInt(arguments[1], 16) + ',' + parseInt(arguments[2], 16) + ',' + parseInt(arguments[3], 16);
                  })
                  .split(/,/),
        returnValue;

    // Return RGB(A)
    return !!rgb
        ? 'rgba' +
              '(' +
              Math[darker ? 'max' : 'min'](parseInt(decimal[0], 10) + difference, darker ? 0 : 255) +
              ', ' +
              Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255) +
              ', ' +
              Math[darker ? 'max' : 'min'](parseInt(decimal[2], 10) + difference, darker ? 0 : 255) +
              ', ' +
              alpha +
              ')'
        : // Return hex
          [
              '#',
              pad(Math[darker ? 'max' : 'min'](parseInt(decimal[0], 10) + difference, darker ? 0 : 255).toString(16), 2),
              pad(Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255).toString(16), 2),
              pad(Math[darker ? 'max' : 'min'](parseInt(decimal[2], 10) + difference, darker ? 0 : 255).toString(16), 2),
          ].join('');
};

export const lighterColor = function (color: string, ratio: number, opacity: number) {
    return changeColor(color, ratio, false, opacity);
};
export const darkerColor = function (color: string, ratio: number, opacity: number) {
    return changeColor(color, ratio, true, opacity);
};

export function hex2rgb(c: string) {
    var bigint = parseInt(c.split('#')[1], 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return 'rgb(' + r + ',' + g + ',' + b + ')';
}
