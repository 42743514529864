import {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Card, ControlledCheckbox} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Checkbox, Stack, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control, useFieldArray} from 'react-hook-form';
import {DashboardCodes} from "pages/WorkflowPortal/shared";
import {IChangeEmployeeCommand} from '..';
import {Context} from "utils";
import {useTenantInfo} from "../../../../../../providers";

type GeneralTabType = {
    onSet: (dashboards: DashboardCodes[]) => void;
    control: Control<IChangeEmployeeCommand>;
    dashboards?: DashboardCodes[];
};

export const DashboardsTab: FunctionComponent<GeneralTabType> = (props: GeneralTabType) => {
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const [selectedDashboards, setSelectedDashboards] = useState<DashboardCodes[]>(props.dashboards || []);
    const onCheck = (checked: boolean, dashboard: DashboardCodes) => {
        setSelectedDashboards(prev => checked ? [...prev, dashboard] : prev.filter(s => s !== dashboard))
    }


    useEffect(() => {
        props.onSet(selectedDashboards);
    }, [props.dashboards, props.onSet, selectedDashboards]);

    const theme = useTheme();

    const checkBoxStyles = {
        root: {
            ':hover .ms-Checkbox-text': {
                color: theme.schemes?.default?.semanticColors.bodyText
            }
        },
        text: {
            color: theme.schemes?.default?.semanticColors.bodyText,
        }
    };

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 30}} wrap horizontalAlign='start'>
                <Stack.Item styles={{root: {width: 'calc(33% - 28px)'}}}>
                    <Checkbox styles={checkBoxStyles}
                              onChange={(_, checked) => onCheck(!!checked, DashboardCodes.Administrator)}
                              checked={selectedDashboards?.some((x: DashboardCodes) => x == DashboardCodes.Administrator) || false}
                              label={formatMessage({id: 'administrator'})}/>
                </Stack.Item>
                {!isWorkpapers && (
                    <Stack.Item styles={{root: {width: 'calc(33% - 28px)'}}}>
                        <Checkbox styles={checkBoxStyles} onChange={(_, checked) => onCheck(!!checked, DashboardCodes.Contravention)}
                                  checked={selectedDashboards?.some((x: DashboardCodes) => x == DashboardCodes.Contravention) || false}
                                  label={formatMessage({id: 'contravention'})}/>
                    </Stack.Item>
                )}
                <Stack.Item styles={{root: {width: 'calc(33% - 28px)'}}}>
                    <Checkbox styles={checkBoxStyles} onChange={(_, checked) => onCheck(!!checked, DashboardCodes.Partner)}
                              checked={selectedDashboards?.some((x: DashboardCodes) => x == DashboardCodes.Partner) || false}
                              label={formatMessage({id: 'partner'})}/>
                </Stack.Item>
                <Stack.Item styles={{root: {width: 'calc(33% - 28px)'}}}>
                    <Checkbox styles={checkBoxStyles} onChange={(_, checked) => onCheck(!!checked, DashboardCodes.Auditor)}
                              checked={selectedDashboards?.some((x: DashboardCodes) => x == DashboardCodes.Auditor) || false}
                              label={formatMessage({id: isWorkpapers ? 'accountant' : 'auditor'})}/>
                </Stack.Item>
                <Stack.Item styles={{root: {width: 'calc(33% - 28px)'}}}>
                    <Checkbox styles={checkBoxStyles} onChange={(_, checked) => onCheck(!!checked, DashboardCodes.Manager)}
                              checked={selectedDashboards?.some((x: DashboardCodes) => x == DashboardCodes.Manager) || false}
                              label={formatMessage({id: 'manager'})}/>
                </Stack.Item>
            </Stack>
        </Card>
    );
};
