import React, {FunctionComponent, useMemo} from "react";
import {IRedirectButtonProps, RedirectButton, useJobRedirects} from "../../shared";
import {IAuditQueryProcedureRef} from "./interfaces";
import {
    FontSizes,
    HoverCard,
    HoverCardType,
    IconButton,
    IHoverCard, IIconProps,
    IPlainCardProps,
    List,
    mergeStyleSets,
    useTheme
} from "@fluentui/react";
import {SanitizedText} from "../../../../../../components";
import {useTabContext} from "../../../../JobPortalPage";

interface IAuditQueryRedirectButtonProps {
    refs: IAuditQueryProcedureRef[];
}

export const AuditQueryRedirectButton: FunctionComponent<IAuditQueryRedirectButtonProps> = ({ refs }: IAuditQueryRedirectButtonProps) => {
    const theme = useTheme();
    const {tabId, refreshSection} = useTabContext();
    const {navigateToTableRow} = useJobRedirects();
    const hoverRef = React.useRef<IHoverCard>(null);

    const iconProps: IIconProps = {
        iconName: 'RedEye',
        style: {
            fontSize: FontSizes.size14,
        }
    }
    
    const validationHoverStyles = mergeStyleSets({
        plainCard: {
            padding: '1em',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        item: {
            padding: '2px 5px',
            border: '1px solid transparent',
            selectors: {
                ':hover': {
                    cursor: 'pointer',
                    borderColor: theme.palette.themePrimary
                }
            }
        }
    });
    
    const isSingle: boolean = useMemo<boolean>(() => (refs ?? []).length <= 1, [refs]);

    const onItemClicked = (item?: IAuditQueryProcedureRef): void => {
        if (item) {
            navigateToTableRow({tabId: tabId, sectionId: item.conclusionSectionId, itemId: item.procedureId})
            refreshSection(`Conclusion_${item.tabReference.replace(' ', '_')}`);
        }
    };

    const onRenderCell = (item?: IAuditQueryProcedureRef, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
            <div className={validationHoverStyles.item} onClick={() => onItemClicked(item)}>
                <SanitizedText theme={theme.schemes?.default} data={`${(index || 0) + 1}. ${item?.tabReference || ''} ${item?.procedureNumber}`} />
            </div>
        );
    };
    
    const onRenderHover = (): JSX.Element => {
        return (
            <div className={validationHoverStyles.plainCard}>
                <List items={refs} onRenderCell={onRenderCell}></List>
            </div>
        );
    };
    
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderHover,
    };
    
    return (
        <>
            {isSingle
                ? <IconButton iconProps={iconProps} onClick={() => onItemClicked(refs[0])}/>
                : <HoverCard type={HoverCardType.plain}
                             componentRef={hoverRef}
                             cardOpenDelay={1000}
                             instantOpenOnClick={true}
                             plainCardProps={plainCardProps}>
                    <IconButton iconProps={iconProps}/>
                </HoverCard>
            }
        </>
    )
}