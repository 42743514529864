import {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {
    DashboardTableTypeEnum,
    FilterByPeriod,
    GeneratedDataTable,
    GeneratedFilters,
    ITableFilters,
    useWorkflowDashboard
} from '..';
import {Spinner, SpinnerSize, Stack} from '@fluentui/react';
import {getSort} from 'utils/dashboardHelpers';
import {IOrderedRequest} from 'interfaces';
import {Context} from 'utils';
import {useExportData} from 'hooks';
import {useGetTableHeaders, useGetTableItems} from "../../hooks";
import {useIntl} from "react-intl";
import { useAdministrationDashboard } from 'pages/ClientPortal/Administration';

interface Props {
    tableType: DashboardTableTypeEnum;
    isWorkflow?: boolean;
    filterByPeriod?: FilterByPeriod;
    setTableName: Function;
}

export const Table: FunctionComponent<Props> = ({tableType, isWorkflow = false, filterByPeriod, setTableName}) => {
    const {formatMessage} = useIntl();
    
    const workflowDashboardContext = useWorkflowDashboard();
    const administrationDashboardContext = useAdministrationDashboard();
    const dashboardContext = (workflowDashboardContext ?? administrationDashboardContext)!;

    const {
        filterData: {filterByYear, clientGuid, fundGuid, contactGuid},
    } = dashboardContext;

    const initialFilters: ITableFilters = {
        page: 1,
        pageSize: 10,
    };
    const context = useContext(Context);

    const [filters, setFilters] = useState<ITableFilters>(initialFilters);
    const [sort, setSort] = useState<IOrderedRequest | undefined>();

    const {tableHeaders, isTableHeadersLoading} = useGetTableHeaders(tableType);
    // don't use global filters if table has date filter
    const globalFilters = useMemo(
        () => (tableHeaders?.data.hasDateFilter ? {} : {clientGuid, fundGuid, filterByYear, contactGuid}),
        [clientGuid, fundGuid, filterByYear, tableHeaders, contactGuid]
    );
    const {tableItems, isTableItemsLoading} = useGetTableItems({
        tableType,
        isWorkflow,
        filterByPeriod, ...globalFilters, ...filters, ...sort
    });
    const {exportData} = useExportData(`${context?.config?.dashboardUrl}/api/1.0/Dashboard`, {tableType, ...globalFilters, ...filters, ...sort});

    const handleSort = (orderdRequest: IOrderedRequest | undefined): void => {
        const newSort = getSort(orderdRequest, tableHeaders?.data.columns || []);
        setSort(newSort);
    };

    useEffect(() => {
        if (tableHeaders?.data)
            setTableName(tableHeaders?.data.tableName);
    }, [tableHeaders?.data]);

    return isTableHeadersLoading || !tableHeaders?.data ? (
        <Stack verticalAlign={"center"} styles={{root: {padding: '16px 0x'}}}
               tokens={{childrenGap: 16}}>
            <Spinner size={SpinnerSize.large} label={formatMessage({id: 'loading'})}/>
        </Stack>
    ) : (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <GeneratedFilters
                    tableName={tableHeaders.data.tableName}
                    searchPlaceholder={tableHeaders.data.searchPlaceholder}
                    setFilters={setFilters}
                    exportData={exportData}
                    hasDateFilter={tableHeaders.data.hasDateFilter}
                    hasFilterByPeriod={tableHeaders.data.hasFilterByPeriod}
                    hasYearFilter={tableHeaders.data.hasYearFilter}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <GeneratedDataTable
                    setFilters={setFilters}
                    setSort={handleSort}
                    itemsData={tableItems?.data}
                    columnsData={tableHeaders.data}
                    isItemsLoading={isTableItemsLoading}
                    containerProps={{
                        tokens: {childrenGap: 16},
                        styles: {root: {height: '100%'}},
                    }}
                />
            </Stack.Item>
        </Stack>
    );
};
