import {AnswerControlType} from 'enums';
import {useRef} from 'react';
import {
    CalendarAnswer,
    CheckboxWorkDoneAnswer,
    DropdownAnswer,
    DropdownNotColoredAnswer,
    DropdownNotColoredDispabledOptionAnswer,
    LabelAnswer,
    TextBoxAnswer,
    WorkDoneSwitchAnswer,
} from './templates/answers';
import {TableType} from "../../../enums/TableType";
import {ColumnInfo} from "../enums";
import {IDropdownOption, IStyleFunctionOrObject} from "@fluentui/react";

export type ThirdOptionProps = {
    answerOptions: string[];
    answerOptionOnSet: string;
    label?: string;
    icon?: string;
    color: string;
}

interface GeneratedAnswerProps {
    answerControlType: AnswerControlType | null;
    label?: string;
    value?: string | boolean;//TODO:make required
    itemId?: number;//TODO:make required
    tableType?: TableType;//TODO:make required
    columnInfo?: ColumnInfo;
    disabled?: boolean;
    options?: IDropdownOption[];
    auto?: boolean;
    onChange?: (newValue: any) => void;
    onUpdate?: (newValue: any) => void;
    styles?: IStyleFunctionOrObject<any, any>;
    thirdOption?: ThirdOptionProps;

}

export const GeneratedAnswer = ({
                                    answerControlType,
                                    label,
                                    value,
                                    itemId,
                                    tableType,
                                    columnInfo,
                                    onChange,
                                    options = [],
                                    onUpdate,
                                    ...otherProps
                                }: GeneratedAnswerProps) => {
    const childRef = useRef();

    switch (answerControlType) {
        case AnswerControlType.Dropdown:
            return <DropdownAnswer itemId={itemId} value={value} onChange={onChange} ref={childRef}
                                   options={options} {...otherProps}/>;
        case AnswerControlType.Label:
            return <LabelAnswer/>;
        case AnswerControlType.TextBox:
            return <TextBoxAnswer value={value}
                                  onChange={onChange}
                                  onUpdate={onUpdate}
                                  itemId={itemId!}
                                  tableType={tableType!}
                                  columnInfo={columnInfo || ColumnInfo.AnswerText}
                                  {...otherProps}/>;
        case AnswerControlType.DropdownNotColored:
            return <DropdownNotColoredAnswer/>;
        case AnswerControlType.DropdownNotColoredDispabledOption:
            return <DropdownNotColoredDispabledOptionAnswer/>;
        case AnswerControlType.CheckboxWorkDone:
            return <CheckboxWorkDoneAnswer checked={value as boolean} onChange={onChange}
                                           label={label}  {...otherProps} />;
        case AnswerControlType.WorkDoneSwitch:
            return <WorkDoneSwitchAnswer itemId={itemId!} tableType={tableType!}
                                         columnInfo={columnInfo || ColumnInfo.AnswerText} onUpdate={onUpdate}
                                         value={value as string} {...otherProps}/>;
        case AnswerControlType.Calendar:
            return <CalendarAnswer value={value}
                                   onChange={onChange}
                                   onUpdate={onUpdate}
                                   itemId={itemId!}
                                   tableType={tableType!}
                                   columnInfo={columnInfo || ColumnInfo.AnswerText}
                                   {...otherProps} />;
        default:
            return <></>;
    }
};