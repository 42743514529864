import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export interface IYearsData {
    years: number[];
}

export interface IYearsTableData{
    items: IYear[];
}

export interface IYear{
    year: number;
}

function mapYearsDataToYearsTableData(data?: IYearsData): IYearsTableData | undefined {
    if (data === undefined) {
        return undefined;
    }

    const yearsTableData: IYearsTableData = {
        items: data.years.map((year) => ({
            year: year
        }))
    };
    return yearsTableData;
}

function useGetWrapAccounts() {
    const context = React.useContext(Context);

    const { data: wrapAccountsData, isLoading, isFetching, refetch } = useQuery(
        ['getWrapAccount'],
        () => axios.get<IYearsData>(`${context?.config?.tabsUrl}/api/1.0/library/getWrapAccount`, {
            ...commonHeaders(),
        }),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId,
        }
    );

    const wrapAccountsTableData = mapYearsDataToYearsTableData(wrapAccountsData?.data);

    return { wrapAccountsTableData, isLoading, isFetching, refetch };
}

export { useGetWrapAccounts };