import { IComboBoxOption, SelectableOptionMenuItemType, useTheme, VirtualizedComboBox } from '@fluentui/react';
import { Controller, Path } from 'react-hook-form';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useIntl } from 'react-intl';
import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    placeholder?: string;
    options: IComboBoxOption[];
    selectAll?: boolean;
    dropdownWidth?: number;
    dropdownMaxWidth?: number;
    allowFreeform?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    styles?: any;
}

export function ControlledSelect<FormValues>({
    name,
    control,
    rules,
    label,
    placeholder,
    disabled,
    options,
    selectAll,
    dropdownWidth = 200,
    dropdownMaxWidth,
    allowFreeform,
    autoComplete,
    styles,
}: Props<FormValues>) {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const [selectedKeys, setSelectedKeys] = useState<any[]>();
    const selectAllOption = {
        key: 'selectAll',
        text: formatMessage({ id: 'selectAll' }),
        itemType: SelectableOptionMenuItemType.SelectAll,
    };
    const selectableOptions = options.filter(
        (option) => (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
    );

    useEffect(() => {
        !selectedKeys?.length && setSelectedKeys(control._formValues[name] as any);
    }, [control._formValues, name, selectedKeys?.length]);

    const defaultStyles = {
        label: { color: theme.schemes?.default?.semanticColors.bodyText },
        root: {
            '.ms-Icon': { color: theme.schemes?.default?.semanticColors.bodyText },
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            ':after': { border: '1px ' + theme.schemes?.default?.palette.blackTranslucent40 },
            '.ms-ComboBox .ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
        },
        input: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            '::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        callout: {
            '.ms-ComboBox-optionText': { color: theme.schemes?.default?.semanticColors.bodyText },
        },
        rootHovered: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootDisabled: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootPressed: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        rootFocused: {
            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
    };

    const [filterText, setFilterText] = useState<string | undefined>(''); // State to store the filter text
    const [filteredOptions, setFilteredOptions] = useState(options); // State to store filtered options

    const filterOptions = React.useCallback((inputText?: string) => {
        const filtered = !!inputText && inputText !== '' ? options.filter(option =>
          option.text.toLowerCase().includes(inputText.toLowerCase())
        ) : options;
    
        // Include the "Select All" option if necessary
        if (selectAll) {
          return [selectAllOption, ...filtered];
        }
    
        return filtered;
      }, [options]);

    const handleInputChange = React.useCallback(
        (newValue?: string) => {
            setFilterText(newValue);
            setFilteredOptions(filterOptions(newValue));
        },
        [filterOptions]
      );

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <VirtualizedComboBox
                    openOnKeyboardFocus
                    styles={{...styles, ...defaultStyles}}
                    label={label}
                    disabled={disabled}
                    placeholder={placeholder}
                    options={
                        !!filterText ? selectAll ?  [selectAllOption, ...filteredOptions] : filteredOptions
                        : selectAll ?  [selectAllOption, ...options] : options
                    }
                    dropdownWidth={dropdownWidth}
                    dropdownMaxWidth={dropdownMaxWidth}
                    onChange={(_e, option) => {
                        if(option?.key == value || (!option?.key && !value)){
                            return;
                        }
                        onChange(option?.key);
                    }}
                    selectedKey={value as string}
                    onBlur={() => {setFilteredOptions(options); onBlur();}}
                    errorMessage={error?.message}
                    allowFreeform={allowFreeform}
                    autoComplete={autoComplete ? 'on' : 'off'}
                    onInputValueChange={handleInputChange}
                />
            )}
        />
    );
}
