import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";

type MutateDataType = {
    auditingFirm: string;
    employeeId: number;
    smsfNumber: string;
    postNominals: string;
};

function useUpdateEmployeesOtherInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    
    const {mutate: updateEmployeesOtherInfo, isLoading: isUpdatingEmployeesOtherInfo} = useMutation(
        (data: MutateDataType) => axios.put(`${context?.config?.userUrl}/api/1.0/Employee/${data.employeeId}/updateOtherInfo`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getEmployeesByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Employee other info'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateEmployeesOtherInfo, isUpdatingEmployeesOtherInfo};
}

export {useUpdateEmployeesOtherInfo};
