import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import {Context} from "../../../../utils";

type MutateDataType = {
    id: number;
    jobId: number;
    staffComments: string;
    initials: string;
    managerPartnerComments: string;
}

function useUpdateAuditQuery() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/auditQueries/${data.id}`, data))
    
    return {update, updateAsync, isUpdating};
}

export {useUpdateAuditQuery};