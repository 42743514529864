import {useIntl} from 'react-intl';
import {PrimaryButton, Shimmer, ShimmerElementType, Stack} from '@fluentui/react';
import {DefaultFormSettings} from 'constants/forms';
import {Card, ControlledNumberField, ControlledTextField} from 'components';
import {FunctionComponent, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useGetGeneralSettings} from "../../hooks/General/useGetGeneralSettings";
import {useUpdateGeneralSettings} from "../../hooks/General/useUpdateGeneralSettings";

interface IGeneralSettingsForm {
    engagementLetterName: string;
    representationLetterName: string;
    infoTrackUsername: string;
    infotrackPassword: string;
    passwordRecoveryLinkValidHours: number;
}

export const GeneralSettingsForm: FunctionComponent = () => {
    const {formatMessage} = useIntl();
    const {generalSettings, isLoading: getSettingsLoading} = useGetGeneralSettings();
    const {updateGeneralSettings, isLoading: updateSettingsLoading} = useUpdateGeneralSettings();

    const {control, setValue, handleSubmit, formState} = useForm<IGeneralSettingsForm>({
        ...DefaultFormSettings,
        defaultValues: {
            engagementLetterName: generalSettings?.data.engagementLetterName,
            representationLetterName: generalSettings?.data.representationLetterName,
            infoTrackUsername: generalSettings?.data.infoTrackUsername,
            infotrackPassword: generalSettings?.data.infotrackPassword,
            passwordRecoveryLinkValidHours: generalSettings?.data.passwordRecoveryLinkValidHours,
        },
    });

    useEffect(() => {
        if (generalSettings?.data) {
            setValue('engagementLetterName', generalSettings?.data.engagementLetterName);
            setValue('representationLetterName', generalSettings?.data.representationLetterName);
            setValue('infoTrackUsername', generalSettings?.data.infoTrackUsername);
            setValue('infotrackPassword', generalSettings?.data.infotrackPassword);
            setValue('passwordRecoveryLinkValidHours', generalSettings?.data.passwordRecoveryLinkValidHours);
        }
    }, [generalSettings?.data, setValue]);

    const onSubmit = (form: IGeneralSettingsForm) => {
        updateGeneralSettings({...form, apiAccess: null});
    };

    return (
        <Card>
            {!generalSettings?.data || getSettingsLoading || updateSettingsLoading ? (
                <Shimmer shimmerElements={[{type: ShimmerElementType.line, height: 247}]}/>
            ) : (
                <>
                    <Stack horizontal tokens={{childrenGap: 16}}>
                        <Stack.Item styles={{root: {width: '50%'}}}>
                            <Stack tokens={{childrenGap: 16}}>
                                <ControlledTextField
                                    name='engagementLetterName'
                                    control={control}
                                    label={formatMessage({id: 'engagementLetterName'})}
                                    rules={{required: formatMessage({id: 'requiredField'})}}
                                />
                                <ControlledTextField
                                    name='representationLetterName'
                                    control={control}
                                    label={formatMessage({id: 'representationLetterName'})}
                                    rules={{required: formatMessage({id: 'requiredField'})}}
                                />
                            </Stack>
                        </Stack.Item>
                        <Stack.Item styles={{root: {width: '50%'}}}>
                            <Stack tokens={{childrenGap: 16}}>
                                <ControlledTextField
                                    name='infoTrackUsername'
                                    control={control}
                                    label={formatMessage({id: 'infoTrackAPIUsername'})}
                                    // rules={{
                                    //     required: formatMessage({ id: 'requiredField' }),
                                    //     pattern: {
                                    //         value: emailValidatorRegexp,
                                    //         message: formatMessage({ id: 'patternEmail' }),
                                    //     },
                                    // }}
                                />
                                <ControlledTextField
                                    name='infotrackPassword'
                                    control={control}
                                    label={formatMessage({id: 'infotrackApiPassword'})}
                                    isPassword
                                    // rules={{
                                    //     minLength: { value: 8, message: formatMessage({ id: 'passwordMinLength' }) },
                                    //     pattern: { value: passwordRegex, message: formatMessage({ id: 'patternPassword' }) },
                                    // }}
                                />
                                <ControlledNumberField
                                    name='passwordRecoveryLinkValidHours'
                                    control={control}
                                    label={formatMessage({id: 'passwordRecoveryLinkValidPeriodHours'})}
                                    rules={{required: formatMessage({id: 'requiredField'})}}
                                />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontalAlign='end' styles={{root: {marginTop: '16px'}}}>
                        <PrimaryButton
                            disabled={!formState.isDirty}
                            onClick={handleSubmit(onSubmit)}
                            text={formatMessage({id: 'save'})}
                        />
                    </Stack>
                </>
            )}
        </Card>
    );
};
