import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type JobYear = {
    isSelected: boolean;
    year: number;
};

/**Query for getting job Years*/

function useGetJobYears() {
    const context = React.useContext(Context);

    const { data: jobYears, isLoading } = useQuery(
        ['getGeneralYear'],
        () => axios.get<JobYear[]>(`${context?.config?.settingsUrl}/api/1.0/Settings/jobYears`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { jobYears, isLoading };
}

export { useGetJobYears };
