import {CalculatorType} from "../CalculatorType";
import {SoftwareType} from "enums";
import {useContext} from "react";
import {Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";
import {ICalculatorData} from "../interfaces";

function useGetCalculatorData(jobId: number, calculatorType: CalculatorType, softwareType: SoftwareType, jobYear: number) {
    const context = useContext(Context);
    const host = context?.config?.calculationUrl;
    const {
        data: calculationData,
        isLoading,
        isFetching
    } = useQuery(['getCalculatorData', jobId, calculatorType], () => axios.get<ICalculatorData>(`${host}/api/1.0/jobs/${jobId}/calculators/${calculatorType}`, {
        params: {
            jobYear: jobYear,
            softwareType: softwareType
        }
    }).then(resp => resp.data));

    return {calculationData, isLoading, isFetching};
}

export {useGetCalculatorData};