import React, {
    forwardRef,
    useCallback,
    useEffect, useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {useIntl} from 'react-intl';
import {Attachment, JobComment, ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {ControlledTextField, DialogMessage, ITableColumn, SanitizedText} from 'components';
import {
    DialogType,
    IColumn,
    IconButton,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme
} from "@fluentui/react";
import {TableType} from "../../../../../enums";
import {Control, useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings, FullWidth} from "../../../../../constants";
import {useCreateReviewItem, useDeleteReviewItem} from "../../../hooks";
import {useUpdateSubsequentEventItem} from "../../../hooks/subsequentEvents";
import {useBoolean} from "@fluentui/react-hooks";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {ReviewType} from "../../../enums";

interface ISubsequentEvent {
    id: number;
    jobId: number;
    hasComments: boolean;
    hasAttachments: boolean;
    tableType: TableType;
    managerPartnerComments?: string;
    initials?: string;
    staffComments?: string;
}

export const SubsequentEventsProcedureTemplate = forwardRef((props, ref) => {
    const { items, refresh, isLoading } = useProcedureContext();
    const { jobId } = useJobContext();

    const { formatMessage } = useIntl();
    const theme = useTheme();

    const childRef = useRef<any>(ref);
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);
    const [events, setEvents] = useState<ISubsequentEvent[]>(items);

    const { control, setValue, handleSubmit, getValues } = useForm<{events: ISubsequentEvent[]}>(DefaultFormSettings);
    const {fields, append, remove} = useFieldArray({
        name: 'events',
        control: control
    });

    const { create, isCreating } = useCreateReviewItem();
    const { update, isUpdating } = useUpdateSubsequentEventItem();
    const { deleteItem, isDeleting } = useDeleteReviewItem();

    const switchToEditMode = (index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    };

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    const deleteEvent = useCallback((index: number) => {
        const eventId = getValues(`events.${index}.id`);
        setLastUpdatedIndex(index);

        deleteItem(
            {
                id: eventId,
            },
            {
                onSuccess: () => {
                    setEvents(events.splice(index, 1));
                    remove(index);
                    setEditingIndex(undefined);
                },
            }
        );
    }, [getValues, remove]);

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const event = getValues(`events.${editingIndex}`);
        const item = events.find((x) => x.id === event.id);

        if (
            (event.initials === item?.initials || (!event.initials && !item?.initials)) &&
            (event.staffComments === item?.staffComments || (!event.staffComments && !item?.staffComments)) &&
            (event?.managerPartnerComments === item?.managerPartnerComments ||
            (!event.managerPartnerComments &&
            !item?.managerPartnerComments))
        ) {
            return;
        }

        setLastUpdatedIndex(editingIndex);

        update(
            {
                id: event.id,
                jobId: event.jobId,
                reviewType: ReviewType.SubsequentEvents,
                staffComments: event?.staffComments,
                initials: event?.initials,
                managerPartnerComments: event?.managerPartnerComments,
            },
            {
                onSuccess: () => {
                    setEvents([...events.slice(0, editingIndex), { ...event }, ...events.slice(editingIndex + 1)]);
                },
            }
        );
    }, [editingIndex, getValues, events]);

    const columns = useMemo<ITableColumn[]>(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item: ISubsequentEvent, index?: number) => {
                    const id = getValues(`events.${index!}.id`);
                    return (
                        <Stack horizontal>
                            <Attachment itemId={id} hasAttachments={item?.hasAttachments} />
                            <JobComment itemId={id} hasComments={item?.hasComments} />
                        </Stack>
                    )
                },
            },
            {
                key: 'staffComments',
                name: formatMessage({ id: 'auditorComments' }),
                minWidth: 400,
                fieldName: 'staffComments',
                onRender: (item: ISubsequentEvent, index, column) => (
                    <ItemCell
                        itemId={item.id}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, column?.name)) {
                                handleOnAccept();
                                switchToEditMode(index, column?.name);
                            }
                        }}
                        editMode={index !== undefined && isInEditMode(index, column?.name)}
                        label={index !== undefined ? getValues(`events.${index}.staffComments`) : ''}
                        control={control}
                        disabled={isUpdating}
                        fieldName={'staffComments'}
                        index={index}
                    />
                ),
            },
            {
                key: 'initials',
                name: formatMessage({ id: 'initials' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'initials',
                onRender: (item: ISubsequentEvent, index, column) => (
                    <ItemCell
                        itemId={item.id}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, column?.name)) {
                                handleOnAccept();
                                switchToEditMode(index, column?.name);
                            }
                        }}
                        editMode={index !== undefined && isInEditMode(index, column?.name)}
                        label={index !== undefined ? getValues(`events.${index}.initials`) : ''}
                        control={control}
                        disabled={isUpdating}
                        fieldName={'initials'}
                        index={index}
                    />
                ),
            },
            {
                key: 'managerPartnerComments',
                name: formatMessage({ id: 'managerPartnerComments' }),
                minWidth: 256,
                maxWidth: 256,
                fieldName: 'managerPartnerComments',
                onRender: (item: ISubsequentEvent, index, column) => (
                    <ItemCell
                        itemId={item.id}
                        onClick={() => {
                            if (index !== undefined && !isInEditMode(index, column?.name)) {
                                handleOnAccept();
                                switchToEditMode(index, column?.name);
                            }
                        }}
                        editMode={index !== undefined && isInEditMode(index, column?.name)}
                        label={index !== undefined ? getValues(`events.${index}.managerPartnerComments`) : ''}
                        control={control}
                        disabled={isUpdating}
                        fieldName={'managerPartnerComments'}
                        index={index}
                    />
                ),
            },
            {
                key: 'delete',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                onRender: (item: ISubsequentEvent, index) => (
                    <Stack grow={1} horizontal verticalAlign={'center'} horizontalAlign={'center'}>
                        {(isDeleting || isUpdating) && lastUpdatedIndex === index && (
                            <Stack.Item>
                                <Spinner size={SpinnerSize.small} />
                            </Stack.Item>
                        )}
                        {!isDeleting && editingIndex !== index && !showDeleteDialog && (
                            <Stack.Item>
                                <IconButton
                                    onClick={() => {
                                        setEditingIndex(index);
                                        toggleDeleteDialog();
                                    }}
                                    iconProps={{ iconName: 'delete' }}
                                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}

                                />
                            </Stack.Item>
                        )}
                    </Stack>
                ),
            },
        ],
        [editingFieldName, editingIndex, isInEditMode, handleOnAccept, switchToEditMode, isDeleting, isUpdating, events]
    );

    useImperativeHandle(ref, () => ({
        onAddClick() {
            create(
                {
                    jobId: jobId,
                    reviewType: ReviewType.SubsequentEvents,
                },
                {
                    onSuccess: (data) => {
                        setEvents([...events, data.data]);
                        append(data.data);
                    },
                }
            );
        },
    }), [events]);

    useEffect(() => {
        setEvents(items);
        setValue('events', items || []);
    }, [items]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('SubsequentEvents')) return;

            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={childRef}>
            <ProceduresDataTable 
                items={fields}
                isLoading={isLoading}
                columns={columns}
                enableShimmer={isCreating} 
                shimmerLines={fields.length}/>
            <DialogMessage
                onClick={() => {
                    if (editingIndex !== undefined) {
                        toggleDeleteDialog();
                        deleteEvent(editingIndex);
                    }
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: formatMessage({ id: 'removingSubsequentEventDialogTitle' }),
                    closeButtonAriaLabel: 'Close',
                    subText: formatMessage({ id: 'removingSubsequentEventDialogMessage' }),
                }}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setEditingIndex(undefined);
                }}
            />
        </div>
    );
});

type CellProps = {
    editMode: boolean;
    label: string | undefined;
    control: Control<{events: ISubsequentEvent[]}>;
    fieldName: 'staffComments' | 'initials' | 'managerPartnerComments';
    onClick: () => void;
    disabled?: boolean;
    itemId: number;
    index?: number;
};
const ItemCell = (props: CellProps) => {
    const theme = useTheme();

    const classNames = mergeStyleSets({
        textFieldDisabled: {
            'input[disabled].ms-TextField-field': {
                color: 'inherit'
            }
        }
    })
    
    return (
        <Stack
            horizontal
            verticalAlign={'center'}
            onClick={props.onClick}
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: '100%', width: '100%' } }}>
            {!props.editMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100,
                        }}
                        onClick={(event) => {
                            props.onClick();
                            event.preventDefault();
                        }}></div>
                    <TextField styles={FullWidth} className={classNames.textFieldDisabled} disabled value={props.label || ''} />
                </>
            )}
            {props.editMode && props.index !== undefined && (
                <ControlledTextField
                    id={`SubsequentEvents-${props.itemId}-${props.fieldName}`}
                    autoFocus
                    control={props.control}
                    name={`events.${props.index}.${props.fieldName}`}
                    disabled={props.disabled}
                    styles={FullWidth}
                />
            )}
        </Stack>
    );
};