import { useContext } from 'react';
import { Context } from '../../../utils';
import { useQuery } from 'react-query';
import axios from 'axios';
import { IJobYearsInfo } from '../interfaces';

function useGetPriorYearJob(id: number) {
    const context = useContext(Context);
    const host = context?.config?.jobUrl;

    const {
        data: priorYearJob,
        isLoading,
        isFetching,
    } = useQuery(['getPriorYearJob', id], () => axios.get<any>(`${host}/api/1.0/job/${id}/prior`).then((resp) => resp.data), {
        enabled: !!id,
    });
    return { priorYearJob, isLoading, isFetching };
}

export { useGetPriorYearJob };
