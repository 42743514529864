import {
    DefaultButton,
    FontWeights,
    IColumn,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    Spinner,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAdministrationContext } from "../..";
import { DataTable, Modal } from "../../../../../../components";
import { useGetJobByGuid } from "../../../../../../hooks";
import { useGenerateJobLetters } from "../../hooks/useGenerateJobLetters";
import { useGetJobDocuments } from "../../hooks/useGetJobDocuments";
import { AttachmentInfo } from "../attachments/Attachment";
import { useBoolean } from "@fluentui/react-hooks";
import { PortalRole, useWorkContext } from "../../../../../../providers";


const engagementLetterName = 'engagementltr';
const representationLetterName = 'representationltr';

interface IDocumentsProps {
    jobGuid: string;
}

const DocumentContent: FunctionComponent<IDocumentsProps> = ({jobGuid}) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    
    const { isInRole } = useWorkContext();
    
    const { dataJobs, isJobLoading, isJobRefetching } = useGetJobByGuid({guid: jobGuid});
    const { response, isLoading, isFetching } = useGetJobDocuments(dataJobs?.data?.id);
    const { selectedClient } = useAdministrationContext();

    const generateEngagement = !!response && !response?.some(x => x.fileName.replaceAll(' ', '').toLowerCase().includes(engagementLetterName));
    const generateRepresentation = !!response && !response?.some(x => x.fileName.replaceAll(' ', '').toLowerCase().includes(representationLetterName));
    const containsLetters = !generateEngagement && !generateRepresentation;
    
    const [showAclWarningMessage, setShowAclWarningMessage] = useState<boolean>(false)

    useEffect(() => {
        setShowAclWarningMessage(dataJobs?.data.hasCompletionLetterOmls && (dataJobs?.data.dateARSent || dataJobs?.data.dateDraftARSent) && (response ?? []).some(d => {
            return d.fileName.toLowerCase().indexOf("Audit_Completion_Letter".trim().toLowerCase()) !== -1 || d.fileName.toLowerCase().indexOf("Unsigned_Audit_Completion_Letter".trim().toLowerCase()) !== -1
        }))
    }, [showAclWarningMessage, dataJobs?.data, response]);
    
    const { update, isUpdating } = useGenerateJobLetters();

    const generateLetters = () => {
        update({
            generateEngagement: generateEngagement,
            generateRepresentation: generateRepresentation,
            jobInformation: {
                jobGuid: dataJobs?.data.guid,
                clientGuid: dataJobs?.data.client.guid,
                fundGuid: dataJobs?.data.fund.guid,
                jobId: dataJobs?.data.id,
                clientId: dataJobs?.data.client.id,
                fundId: dataJobs?.data.fund.id,
                clientName: selectedClient?.name ?? dataJobs?.data.client.name,
                fundName: dataJobs?.data.fund.name,
                jobYear: dataJobs?.data.year
            }
        });
    }

    const [isDocumentDeleteEnabled, setIsDocumentDeleteEnabled] = useState<boolean>(!isInRole(PortalRole.CPUser));

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) =>
                <AttachmentInfo id={item.id} fileName={item.fileName} isDeletingEnabled={isDocumentDeleteEnabled}
                     downloadUrl={item.downloadUrl} jobId={dataJobs?.data?.id}/>
        }
    ]);
    
    const classNames = mergeStyleSets({
        warningMessage: {
            color: theme.semanticColors.errorText,
            fontWeight: FontWeights.bold
        }
    })

    if (isLoading || isUpdating || isJobLoading || isJobRefetching) return <Stack horizontalAlign='center'><Spinner /></Stack>;
    if (!response) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {showAclWarningMessage && <Stack.Item>
                <Text className={classNames.warningMessage}>{formatMessage({ id: 'aclWarningMessage'})}</Text>
            </Stack.Item>}
            <Stack.Item>
            <DataTable
                initialColumns={columns}
                columns={columns}
                items={response}
                selectionMode={SelectionMode.none}
                enableShimmer={isFetching}
                containerHeight='100%'
                />
            </Stack.Item>
            {!containsLetters && 
                <Stack.Item align='end' styles={{ root: { width: '20%' } }}>
                    <PrimaryButton onClick={generateLetters} styles={{ root: { width: '100%' } }} text={formatMessage({ id: 'generateLetters' })} />
                </Stack.Item>
            }
        </Stack>
    );
}

interface IDocumentsModalProps {
    jobGuid: string;
    open: boolean;
    toggle: () => void;
}

export const DocumentsModal: FunctionComponent<IDocumentsModalProps> = ({jobGuid, open, toggle}) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <Modal
                title={formatMessage({ id: 'documents' })}
                isOpen={open}
                onDismiss={() => {
                    toggle()
                }}>
                <DocumentContent jobGuid={jobGuid} />
            </Modal>
        </>);
}