import {CustomProcedureRowRender} from "../../CustomProcedureTemplates";
import {IDetailsRowProps, mergeStyleSets, Stack} from "@fluentui/react";
import React, {createElement, FunctionComponent, ReactElement, useCallback} from "react";
import {ControlledTextField, ITableColumn, SanitizedText} from "../../../../../../../components";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../../constants";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {useUpdateItemAnswer} from "../../../../../hooks/answers";
import {ColumnInfo} from "../../../../../enums";
import {getFlatProcedures} from "../../../../../../../utils";
import {GeneratedAnswer} from "../../../../GeneratedAnswer";
import {PermanentCheckBox} from "../../../../PermanentCheckBox";

interface ITrusteeNameProcedureProps {
    procedure: any,
    disabled: boolean;
}

interface ITrusteeNameForm {
    name?: string;
}

const TrusteeNameProcedureNameTemplate: FunctionComponent<ITrusteeNameProcedureProps & { onUpdateName?: (newName: string) => void }> = ({
                                                                                                                                            procedure,
                                                                                                                                            disabled,
                                                                                                                                            onUpdateName
                                                                                                                                        }: ITrusteeNameProcedureProps & { onUpdateName?: (newName: string) => void }) => {
    const {jobId} = useJobContext();
    const {update, isUpdating} = useUpdateItemAnswer();

    const {control} = useForm<ITrusteeNameForm>({
        ...DefaultFormSettings,
        defaultValues: {
            name: procedure.answerText2
        }
    });

    const onSubmit = useCallback(() => {
        const newName = control._formValues['name'];
        update({
            jobId: jobId,
            tableType: procedure.tableType,
            itemId: procedure.id,
            text: newName,
            columnInfo: ColumnInfo.Text2
        }, {
            onSuccess: () => {
                onUpdateName?.(newName)
            }
        })
    }, []);

    const handleBlur = useCallback(() => {
        onSubmit()
    }, [])

    const classNames = mergeStyleSets({
        text: {
            '> span': {
                display: 'flex',
                justifyContent: 'space-between',
            }
        }
    })

    return (
        <Stack horizontal tokens={{childrenGap: 16}}
               styles={{root: {width: '100%', alignItems: 'center', paddingLeft: (procedure.level || 0) * 16}}}>
            <Stack.Item style={{flexGrow: 1}}>
                <SanitizedText className={classNames.text} textWhiteSpace={'nowrap'} data={procedure?.title || ''}/>
            </Stack.Item>
            <Stack.Item style={{flexGrow: 1}}>
                <ControlledTextField onBlur={handleBlur} name='name' label={''} control={control}
                                     disabled={isUpdating || disabled}/>
            </Stack.Item>
        </Stack>
    )
}


const TrusteeNameProcedureResultTemplate: FunctionComponent<ITrusteeNameProcedureProps> = ({
                                                                                               procedure, disabled,
                                                                                           }: ITrusteeNameProcedureProps) => {
    return (
        <Stack verticalAlign='center' grow>
            <GeneratedAnswer columnInfo={ColumnInfo.Text} itemId={procedure.id} tableType={procedure.tableType}
                             value={procedure.answerText} auto={procedure.isAnswerDisabled}
                             disabled={procedure.isAnswerDisabled || disabled}
                             answerControlType={procedure?.answerControlType}/>
        </Stack>
    )
}

const TrusteeNameProcedurePermanentTemplate: FunctionComponent<ITrusteeNameProcedureProps> = ({
                                                                                                  procedure,
                                                                                                  disabled,
                                                                                              }: ITrusteeNameProcedureProps) => {
    return (
        <Stack verticalAlign='center' horizontalAlign='center' grow>
            {procedure?.showCheckBox &&
                (<PermanentCheckBox checked={procedure.permanentChecked}
                                    tableType={procedure.tableType}
                                    itemId={procedure.id}
                                    disabled={disabled}
                />)}
        </Stack>
    )
}

export const trusteeNameProcedureRowRender: CustomProcedureRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const {isCorporateTrusteeType} = ((items?: any): { isCorporateTrusteeType: boolean } => {
        const flatted = getFlatProcedures(items ?? [], []);

        const isCorporateTrusteeType = flatted.find(x => x.reference === 'Trustee Type')?.answerText == 'Corporate';
        return {isCorporateTrusteeType}
    })(rows)

    const auditProcedureColumn = (props?.columns ?? []).find(x => x.key === 'auditProcedure');
    if (auditProcedureColumn) {
        // custom auditProcedure column render
        const trusteeNameAuditProcedureColumn: ITableColumn = {
            ...auditProcedureColumn,
            key: 'auditProcedure_trusteeName',
            onRender: (item, index, column) => {
                return createElement(TrusteeNameProcedureNameTemplate, {
                    procedure: item,
                    disabled: !isCorporateTrusteeType,
                    onUpdateName: (newName: string) => {
                        item.answerText2 = newName;
                        onRowItemUpdate?.(item);
                    }
                })
            }
        }

        const index = props.columns.indexOf(auditProcedureColumn);

        const columns = [...props.columns];
        columns[index] = {...trusteeNameAuditProcedureColumn}

        props.columns = [...columns]
    }

    const resultColumn = (props?.columns ?? []).find(x => x.key === 'result');
    if (resultColumn) {
        // custom result column render
        const trusteeNameResultColumn: ITableColumn = {
            ...resultColumn,
            key: 'result_trusteeName',
            onRender: (item, index, column) => {
                return createElement(TrusteeNameProcedureResultTemplate, {
                    procedure: item,
                    disabled: !isCorporateTrusteeType
                })
            }
        }

        const index = props.columns.indexOf(resultColumn);

        const columns = [...props.columns];
        columns[index] = {...trusteeNameResultColumn}

        props.columns = [...columns]
    }

    const permanentColumn = (props?.columns ?? []).find(x => x.key === 'permanent');
    if (permanentColumn) {
        // custom permanent column render
        const trusteeNamePermanentColumn: ITableColumn = {
            ...permanentColumn,
            key: 'permanent_trusteeName',
            onRender: (item, index, column) => {
                return createElement(TrusteeNameProcedurePermanentTemplate, {
                    procedure: item,
                    disabled: !isCorporateTrusteeType
                })
            }
        }

        const index = props.columns.indexOf(permanentColumn);

        const columns = [...props.columns];
        columns[index] = {...trusteeNamePermanentColumn}

        props.columns = [...columns]
    }

    return defaultRender(props);
}