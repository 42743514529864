import axios from 'axios';
import {DashboardTabsEnum} from '../enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface Props {
    dashboardTab: DashboardTabsEnum;
    clientId?: string;
    fundId?: string;
    clientGuid?: string;
    fundGuid?: string;
}

function useGetJobYears({clientId, fundId, dashboardTab, clientGuid, fundGuid}: Props) {
    const context = React.useContext(Context);

    const {data: jobYears, isLoading: isJobYearsLoading} = useQuery(
        ['getJobYears', clientId, fundId, dashboardTab],
        () => axios.get<number[]>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/jobYears`, {
            ...commonHeaders(),
            params: {clientId, fundId, dashboardTab, clientGuid, fundGuid}
        })
            .then(response => response.data.map(d => ({key: d, text: String(d)}))),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {jobYears, isJobYearsLoading};
}

export {useGetJobYears};