export enum DocumentType{
    LineItems = 1,
    OMLItems = 2,
    Item3 = 3,
    DispatchItems = 4,
    ReportItems = 100,
    TabAttachment = 200,
    JobAttachment = 300,
    AuditQueriesForClients = 400,
    PdfPublish = 500,
    BulkPdf = 501
}