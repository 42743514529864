import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {useProcedureContext} from "../../../ProceduresContent";
import {useIntl} from "react-intl";
import {AuditQueriesDataTable} from "./AuditQueriesDataTable";
import {DialogMessage, ITableColumn, SanitizedText} from "../../../../../../components";
import {DialogType, IconButton, IDialogContentProps, Stack, Text, useTheme} from "@fluentui/react";
import {Attachment} from "../../../Attachment";
import {PortalRole, useTenantInfo, useWorkContext} from "../../../../../../providers";
import {AuditQueryProcedureRefCleared, InitialAuditQueryItem} from "./interfaces";
import {AuditQueryPublishedIcon} from "./AuditQueryPublishedIcon";
import {TableType} from "../../../../../../enums";
import {QueryClientComment, QueryComment} from "./QueryComment";
import {useBoolean} from "@fluentui/react-hooks";
import {useDeleteAuditQuery} from "./hooks/useDeleteAuditQuery";
import {useGetDispatchAuditInfo} from "./hooks/useGetDispatchAuditInfo";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {AuditQueryRating} from "./QueryRating";
import {AuditQueryDates} from "./QueryDates";
import {ReviewdOSQueryDateForm} from "./ReviewdOSQueryDateForm";
import {ColumnInfo} from "../../../../enums";
import {QueryEdit} from "./QueryEdit/QueryEdit";
import {useTabContext} from "../../../../JobPortalPage";
import {isYesAnswer, WorkDoneSwitchAnswer} from "../../answers";
import {useSectionContext} from "../../../Section";
import {AuditQueryRedirectButton} from "./AuditQueryRedirectButton";
import {AuditQueryCopyButton} from "./AuditQueryCopyButton";

interface IDeleteQueryDialogContext {
    showOk: boolean;
    onOk: (item?: InitialAuditQueryItem) => void;
    props: IDialogContentProps;
}

export const InitialAuditQueriesTemplate: FunctionComponent = () => {
    const {enableWarningMessage, updateSectionAnswer} = useSectionContext();
    const {items, isLoading} = useProcedureContext();
    const {isCurrentUserJobPartner, isCurrentUserJobManager, isInRoleOrSuperAdministrator} = useWorkContext();
    const {jobId} = useJobContext();
    const {refreshSectionById, refreshSection} = useTabContext();
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const theme = useTheme();

    const {deleteQuery, isDeleting} = useDeleteAuditQuery();
    const {auditQueriesInfo, isLoading: isAuditInfoLoading} = useGetDispatchAuditInfo(jobId);


    const [shouldRefreshItems, setShouldRefreshItems] = useState<number>();
    const [deleteDialogContext, setDeleteDialogContext] = useState<IDeleteQueryDialogContext>({
        showOk: false,
        onOk: () => {
        },
        props: {} as IDialogContentProps
    });
    const [currentItem, setCurrentItem] = useState<InitialAuditQueryItem | null>();

    const [showDeleteDialog, {toggle: toggleDeleteDialog}] = useBoolean(false);

    const [witholdingQueries, setWitholdingQueries] = useState<InitialAuditQueryItem[]>();
    const [nonWitholdingQueries, setNonWitholdingQueries] = useState<InitialAuditQueryItem[]>();

    useEffect(() => {
        enableWarningMessage(false)
    }, [])

    const queryMapFn = (i: InitialAuditQueryItem): InitialAuditQueryItem => {
        return {
            ...i,
            isCleared: i.cleared === 'Yes'
        }
    }

    const queries = useMemo<InitialAuditQueryItem[]>(() => {
        return [...(items || []).map(queryMapFn)];
    }, [items, shouldRefreshItems])

    const onQueryCommentUpdate = useCallback((updated: { itemId: number, comment?: string }) => {
        let item = (items || []).find(i => i.id === updated.itemId)
        if (item) {
            item.comment = updated.comment;
            setShouldRefreshItems(_ => Date.now())
        }
    }, [])

    const onDeleteQuery = (query?: InitialAuditQueryItem, force?: boolean) => {
        if (!query) return;
        deleteQuery({
            id: query.id,
            jobId: query.jobId,
            force: force ?? false
        }, {
            onSuccess: () => {
                let deletingItem = items.find(x => x.id === query.id);
                if (deletingItem) {
                    items.splice(items.indexOf(deletingItem), 1);
                    setShouldRefreshItems(_ => Date.now());
                    refreshSection('Deleted Audit Queries')
                }
            }
        })
    }

    const onDeleteQueryClick = useCallback((item?: InitialAuditQueryItem) => {
        if (!item) {
            return;
        }

        let context: IDeleteQueryDialogContext;
        if (!item.pushCount) {
            // delete
            context = {
                showOk: true,
                onOk: (i?: InitialAuditQueryItem) => {
                    onDeleteQuery(i);
                },
                props: {
                    theme: theme.schemes?.default,
                    type: DialogType.normal,
                    title: formatMessage({id: 'warning'}),
                    closeButtonAriaLabel: 'Close',
                    subText: formatMessage({id: 'queryDeleteConfirmation'}),
                },
            }
        } else {
            // unable to delete published query
            const canBeDeleted = isInRoleOrSuperAdministrator(PortalRole.Administrator);
            context = {
                showOk: canBeDeleted,
                onOk: (i?: InitialAuditQueryItem) => {
                    onDeleteQuery(i, true);
                },
                props: {
                    type: DialogType.normal,
                    title: formatMessage({id: 'warning'}),
                    closeButtonAriaLabel: 'Close',
                    subText: formatMessage({id: canBeDeleted ? 'queryPublishedAndCantBeDeletedOverride' : 'queryPublishedAndCantBeDeleted'}),
                },
            }
        }

        setDeleteDialogContext(context);
        setCurrentItem(item);
        toggleDeleteDialog();
    }, [])

    const onContraventionReportRequiredChanged = (item: InitialAuditQueryItem, answer: string | null) => {
        // no action required. handled on backend
        // if (answer === 'Yes') {
        //     item.procedureRefs.forEach((ref, index) => {
        //         update({
        //             jobId: jobId,
        //             tableType: 1000,
        //             itemId: ref.procedureId,
        //             text: 'Qualification',
        //             columnInfo: ColumnInfo.ActionTaken
        //         }, {
        //             onSuccess: () => {
        //                 refreshSection(`Conclusion_${ref.tabReference.replace(' ', '_')}`)
        //             }
        //         });
        //     })
        // }
    }

    const onClearedAnswerUpdate = useCallback((answer: string | null, item: InitialAuditQueryItem, index: number | undefined) => {
        const _answerSetterFn = (queries: InitialAuditQueryItem[], idx: number, answerValue: string | null) => {
            queries[idx!].cleared = answerValue ?? undefined;
            queries[idx!].isCleared = answerValue === 'Yes';
        }

        if (item.isNonFundamental) {
            const _queries = [...nonWitholdingQueries ?? []];
            if (_queries.length) {
                _answerSetterFn(_queries, index!, answer);
                setNonWitholdingQueries(prev => [..._queries])
            }

            updateConclusionSections([...witholdingQueries ?? [], ..._queries])
        } else {
            const _queries = [...witholdingQueries ?? []];
            if (_queries.length) {
                _answerSetterFn(_queries, index!, answer);
                setWitholdingQueries(prev => [..._queries])
            }

            updateConclusionSections([..._queries, ...nonWitholdingQueries ?? []])
        }
    }, [witholdingQueries, nonWitholdingQueries])

    const updateConclusionSections = (queryItems: InitialAuditQueryItem[]) => {
        const refs = queryItems
            .map(q => ([...q.procedureRefs.map(ref => ({...ref, isCleared: q.isCleared}))]))
            .flat()
            .reduce((acc: { [key: number]: boolean[] }, i: AuditQueryProcedureRefCleared): {
                [key: number]: boolean[]
            } => {
                if (!acc[i.conclusionSectionId]?.length) {
                    acc[i.conclusionSectionId] = [];
                }

                acc[i.conclusionSectionId].push(i.isCleared);
                return acc;
            }, {});

        for (const sectionId of Object.keys(refs)) {
            refreshSectionById(+sectionId);
            // if (refs[+sectionId].every(r => r)) {
            //     closeSectionById(+sectionId);
            //     updateSectionAnswerById(+sectionId, true);
            //     continue;
            // }
            //
            // if (refs[+sectionId].some(r => !r)) {
            //     updateSectionAnswerById(+sectionId, false);
            // }
        }
    }

    useEffect(() => {
        setWitholdingQueries(prev => [...(queries).filter(x => !x.isNonFundamental)])
        setNonWitholdingQueries(prev => [...(queries).filter(x => x.isNonFundamental)])
    }, [queries]);

    useEffect(() => {
        if (!witholdingQueries) {
            return;
        }

        // updateConclusionSections(witholdingQueries);

        if (!witholdingQueries.length) {
            updateSectionAnswer(null);
        } else if (witholdingQueries.some(q => !q.cleared || q.cleared === 'No')) {
            updateSectionAnswer(false);
        } else if (witholdingQueries.every(q => isYesAnswer(q.cleared))) {
            updateSectionAnswer(true);
            return;
        }

    }, [witholdingQueries]);

    const [isQueryCheckControlEnabled, setIsQueryCheckControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsQueryCheckControlEnabled(isCurrentUserJobPartner || isCurrentUserJobManager || isInRoleOrSuperAdministrator(PortalRole.ReviewerUser, PortalRole.Administrator))
    }, [isCurrentUserJobManager, isCurrentUserJobPartner]);

    const columns = useMemo((): ITableColumn[] => [
        {
            key: 'actions',
            name: formatMessage({id: 'actions'}),
            minWidth: 200,
            maxWidth: 200,
            fieldName: 'actions',
            onRender: (item, _, column) => (
                <Stack grow={1} horizontal horizontalAlign='space-between' tokens={{childrenGap: -4}}>
                    <AuditQueryRedirectButton refs={item.procedureRefs}/>
                    <QueryEdit query={item} disabled={item?.isPublished ?? false}/>
                    <QueryClientComment itemId={item?.id} comment={item?.clientComment}/>
                    <QueryComment itemId={item?.id} comment={item?.comment} onUpdate={onQueryCommentUpdate}/>
                    <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                    <AuditQueryCopyButton query={item}/>
                    <IconButton iconProps={{iconName: 'Delete'}}
                                styles={{icon: {color: theme.palette.red}, iconHovered: {color: theme.palette.redDark}}}
                                onClick={() => onDeleteQueryClick(item)}/>
                </Stack>
            )
        },
        {
            key: 'number',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            fieldName: 'number',
            onRender: (item, idx, column) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text>{item.isNonFundamental ? String.fromCharCode((idx ?? 0) + 97) : (idx ?? 0) + 1}</Text>
                </Stack>
            )
        },
        {
            key: 'text',
            name: formatMessage({id: 'withholdingQueries'}),
            minWidth: 256,
            fieldName: 'text',
            onRender: (item, _, column) => (
                <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                    <SanitizedText data={item?.text}
                                   styles={{
                                       root: {
                                           maxWidth: '100%',
                                           overflow: 'hidden',
                                           textOverflow: 'ellypsis',
                                           ...((isCleared) => {
                                               return isCleared ? {
                                                   textDecoration: 'line-through',
                                                   color: theme.palette.themeTertiary
                                               } : {}
                                           })(item?.isCleared)
                                       }
                                   }}/>
                </Stack>
            )
        },
        {
            key: 'assertion',
            name: formatMessage({id: 'assertion'}),
            fieldName: 'assertion',
            skip: isWorkpapers,
            minWidth: 128,
            maxWidth: 256,
            onRender: (item, _, column) => item?.showAssertion ? (
                <Stack>
                    <Text>
                        <span
                            style={{color: theme.palette.themePrimary}}>{formatMessage({id: 'financial'}) + ': '}</span>
                        <SanitizedText data={item?.assertionFinancial || ''}/>
                    </Text>
                    <Text>
                        <span
                            style={{color: theme.palette.themePrimary}}>{formatMessage({id: 'compliance'}) + ': '}</span>
                        <SanitizedText data={item?.assertionCompilance || ''}/>
                    </Text>
                </Stack>
            ) : null,
        },
        {
            key: 'published',
            name: formatMessage({id: 'published'}),
            minWidth: 64,
            fieldName: 'published',
            onRender: (item, _, column) => item?.isPublished && (
                <Stack horizontal horizontalAlign='center' grow={1}>
                    <AuditQueryPublishedIcon publishedCount={(item?.pushCount || 0)}></AuditQueryPublishedIcon>
                </Stack>
            )
        },
        {
            key: 'cleared',
            name: formatMessage({id: 'cleared'}),
            minWidth: 128,
            fieldName: 'cleared',
            onRender: (item, _, column) => (
                <Stack horizontal horizontalAlign={'center'} grow={1}>
                    <WorkDoneSwitchAnswer
                        value={item?.cleared}
                        tableType={TableType.InitialAudit}
                        itemId={item?.id}
                        columnInfo={ColumnInfo.ClearedText}
                        ignoreSectionAnswerUpdating={true}
                        onUpdate={(newValue: string | null) => onClearedAnswerUpdate(newValue, item, _)}
                    />
                </Stack>
            )
        },
        {
            key: 'acr',
            name: formatMessage({id: 'ACRQual'}),
            minWidth: 128,
            fieldName: 'acr',
            onRender: (item, _, column) => (
                <Stack horizontal horizontalAlign={'center'} grow={1}>
                    <WorkDoneSwitchAnswer
                        value={item?.contraventionReportRequired}
                        tableType={TableType.InitialAudit}
                        itemId={item?.id}
                        columnInfo={ColumnInfo.ContraventionReportRequired}
                        iconTypes={'character'} reverse
                        onUpdate={(answer: string | null) => onContraventionReportRequiredChanged(item, answer)}
                        thirdOption={{
                            answerOptions: ['Maybe'],
                            answerOptionOnSet: 'Maybe',
                            label: '?',
                            color: theme.palette.orangeLighter
                        }}
                    />
                </Stack>
            )
        },
        {
            key: 'check',
            name: formatMessage({id: 'queryCheck'}),
            minWidth: 128,
            fieldName: 'check',
            onRender: (item, _, column) => (
                <Stack horizontal horizontalAlign={'center'} grow={1}>
                    <WorkDoneSwitchAnswer
                        value={item?.queryCheck}
                        tableType={TableType.InitialAudit}
                        itemId={item?.id}
                        disabled={!isQueryCheckControlEnabled}
                        columnInfo={ColumnInfo.QueryCheck}
                        iconTypes={'character'} reverse
                        thirdOption={{
                            answerOptions: ['Checked'],
                            answerOptionOnSet: 'Checked',
                            label: 'C',
                            color: theme.palette.teal
                        }}
                    />
                </Stack>
            )
        },
    ], [formatMessage, (witholdingQueries ?? []).length])

    return (
        <>
            <Stack grow={1} tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <AuditQueriesDataTable
                        items={witholdingQueries ?? []}
                        isLoading={isLoading}
                        columns={columns}
                    />
                </Stack.Item>
                <Stack.Item>
                    <AuditQueriesDataTable
                        items={nonWitholdingQueries ?? []}
                        isLoading={isLoading}
                        columns={((cols): ITableColumn[] =>
                            cols.reduce((acc: ITableColumn[], c) => {
                                acc.push({
                                    ...c,
                                    name: c.key === 'text' ? formatMessage({id: 'nonWithholdingQueries'}) : c.name
                                });
                                return acc;
                            }, []))(columns)}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 16}}>
                        <Stack.Item grow={1}>
                            <Stack tokens={{childrenGap: 16}}>
                                <Stack.Item>
                                    <AuditQueryRating></AuditQueryRating>
                                </Stack.Item>
                                <Stack.Item>
                                    <ReviewdOSQueryDateForm></ReviewdOSQueryDateForm>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item grow={5}>
                            <AuditQueryDates
                                initialQueriesSentDates={auditQueriesInfo?.data?.initialQueriesSent}
                                initialQueriesResponseDates={auditQueriesInfo?.data?.initialQueriesResponse}
                                reviewedOSQueriesDates={auditQueriesInfo?.data?.reviewedOsQueries}
                            >
                            </AuditQueryDates>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <DialogMessage
                showOk={deleteDialogContext.showOk}
                onClick={() => {
                    toggleDeleteDialog();
                    deleteDialogContext.onOk(currentItem!);
                }}
                dialogContentProps={deleteDialogContext.props}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}/>
        </>
    )
}