import { Stack } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { Card } from 'components';
import { Approvers, Connections } from './components/AuditReadyConnections';

export const AuditReadyConnectionsPage: FunctionComponent = () => (
    <Stack tokens={{ childrenGap: 16 }}>
        <Card>
            <Approvers />
        </Card>
        <Card>
            <Connections />
        </Card>
    </Stack>
);
