import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

function useRemoveScheduledExportAttachment(jobId: number) {
    const context = React.useContext(Context);

    const { mutate: deleteAttachment, isLoading, isSuccess } = useMutation((attachmentId: number) =>
        axios.put(`${context?.config?.exportUrl}/api/1.0/jobs/${jobId}/export/scheduled/deleteScheduledReportAttachment/${attachmentId}`, commonHeaders()),
        {
            onSuccess: () => { queryClient.invalidateQueries('getAttachmentsByDocumentType');
                queryClient.invalidateQueries(['getJobSchedules', jobId]);
             },
        }
    );

    return { deleteAttachment, isLoading, isSuccess };
}

export { useRemoveScheduledExportAttachment };