import {INotification} from "./Notification";
import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Toast} from "./Toast";
import {Subscription, timer} from "rxjs";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";

export interface INotificationContext {
    notifications: INotification[];
    showNotification: (notification: INotification) => void;
    dismissNotification: (notification: INotification) => void;
    clearAll: () => void;

}

const NotificationsContext = createContext<INotificationContext>({
    notifications: [],
    showNotification: () => {
    },
    dismissNotification: () => {
    },
    clearAll: () => {
    }
});


export const useNotifications = () => {
    return useContext(NotificationsContext);
}

export function useCommonErrorNotification() {
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const showCommonError = () => showNotification({
        name: formatMessage({id: 'error'}),
        description: formatMessage({id: 'somethingWentWrong'}),
        type: MessageBarType.error
    });

    return {showCommonError};
}

export const NotificationProvider = (props: PropsWithChildren<{}>) => {

    const [notifications, setNotification] = useState<INotification[]>([]);

    const [currentToast, setCurrentToast] = useState<INotification | null>(null);
    const [timerSubscription, setTimerSubscription] = useState<Subscription | null>(null);
    const addNotification = (notification: INotification) => {
        if (!notification.dateEvent)
            notification.dateEvent = new Date();
        setNotification(prev => ([...prev, notification]));
    }

    const dismissNotification = (notification: INotification) => {
        setNotification(prev => prev.filter(x => x != notification));
    }

    const clearAll = () => {
        setNotification([]);
    }

    const hideToast = () => {
        timerSubscription?.unsubscribe();
        setCurrentToast(null);
        setTimerSubscription(null);
    }

    useEffect(() => {
        if (!notifications.length)
            return;

        const toast = notifications[notifications.length - 1];
        setCurrentToast(toast);
        setTimerSubscription(
            timer(toast.type === MessageBarType.error ? 15000 : 3000).subscribe(() => setCurrentToast(null))
        );
    }, [notifications]);


    return <NotificationsContext.Provider
        value={{notifications: notifications, showNotification: addNotification, dismissNotification: dismissNotification, clearAll: clearAll}}>
        {currentToast && <Toast name={currentToast.name}
                                description={currentToast.description}
                                type={currentToast.type}
                                actions={currentToast.actions}
                                onDismiss={hideToast}
                                promise={currentToast.promise}
                                isMultiline
        />}

        {props.children}
    </NotificationsContext.Provider>
}