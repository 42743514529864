import {IColumn, IContextualMenuItem, MessageBarType, SelectionMode, Stack} from '@fluentui/react';
import {DataTable, SanitizedText, useContextMenu} from 'components';
import {Attachment, JobComment, useSectionContext} from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useEffect, useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import { getGrouppedReports } from 'utils';
import {ReportTemplateTypeProps} from "../Types";
import {useCreateReviewItem} from "../../../../hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useNotifications} from "../../../../../../components/notifications";
import {useJobRedirects} from "../../shared";
import {ReviewType} from "../../../../enums";
import {logger} from "../../../../../../services";
import { ReportTable } from "../Table/ReportTable";
import { ReportMoneyValueCell } from "../shared/cells";
import { ReportValidationCell } from "../shared";



export const MemberStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'groupName' }), [items]);
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'nameDescription',
                name: formatMessage({ id: 'nameDescription' }),
                minWidth: 250,
                fieldName: 'nameDescription',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isCategory || item?.isTotalRow || (item['nameDescription'] ?? '').indexOf('Total') !== -1} format={item?.format} />,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amount',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isCategory || item?.isTotalRow || (item['nameDescription'] || '').indexOf('Total') !== -1} />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={reports}
        groups={groups}
        enableShimmer={isFetching}
        expandProps={{showExpander: false}}
        openAll={true}
    />
};
