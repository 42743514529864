import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context } from "utils";

type MutateDataType = {
    id: number;
    jobId: number;
    text?: string;
    comment?: string;
    isNonFundamental: boolean;
    isSelected: boolean;
}

function useUpdateAuditQuery() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.queriesUrl}/api/1.0/queries/update/${data.id}`, data))
    
    return {update, updateAsync, isUpdating};
}

export {useUpdateAuditQuery};