import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

export type GeneralApiAccess = {
    isAuthenticated: boolean;
    authorizeUrl: string;
    businessAuthLinks: {
        businessCode: string;
        authenticationLink: string;
        isAuthenticated: boolean;
    }[];
    softwareTypeVal: number;
    adminAuthentication: boolean;
    businessCodes: string[];
    businessCode: string;
    lockedBusinessCode: boolean;
    multipleBusinessCodeModel: boolean;
    funds: {
        fundCode: string;
        fundId: string;
        fundName: string;
        sfCode: string;
        abn: string;
        firmName: string;
        firm: string;
        fundEmail: string;
    }[];

    errorMessage: string;
}[];

type GeneralSettingsReq = {
    passwordRecoveryLinkValidHours: number;
    infoTrackUsername: string;
    infotrackPassword: string;
    engagementLetterName: string;
    representationLetterName: string;
    apiAccess: string | null;
};

/**Mutation query for updating general settings data in Portal Settings*/

function useUpdateGeneralSettings() {
    const context = React.useContext(Context);
    const { mutate: updateGeneralSettings, isLoading } = useMutation(
        (data: GeneralSettingsReq) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/general`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getGeneralSettings'),
        }
    );

    return { updateGeneralSettings, isLoading };
}

export { useUpdateGeneralSettings };
