import {useState} from 'react';
import {IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {BaseDialog, Card, DataTable} from 'components';
import {ActionButtonStyles, CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {IOrderedRequest} from "interfaces";
import {useUpdateEmployeesStatus} from "../../hooks";
import {EmployeeInfo, EntityStatus} from "pages/WorkflowPortal/shared";
import {useWorkflows} from "pages/WorkflowPortal";

type EmployeesPropsType = {
    isLoading?: boolean;
    employeesItems: EmployeeInfo[];
    setSort: (value: IOrderedRequest) => void;
};
export const EmployeesDataTable = ({employeesItems, setSort, isLoading}: EmployeesPropsType) => {
    const {formatMessage} = useIntl();
    const {showEmployeeModal} = useWorkflows();
    const [currentActivatedUser, setCurrentActivatedUser] = useState<{ userId: number; status: EntityStatus | null } | null>(null);
    const theme = useTheme();

    const {
        updateEmployeesStatus,
        isLoadingEmployeesUpdateStatus
    } = useUpdateEmployeesStatus(() => setCurrentActivatedUser(null));

    const handleChangeClientStatus = () => {
        if (currentActivatedUser) {
            updateEmployeesStatus({
                employeeId: currentActivatedUser?.userId,
                status: currentActivatedUser?.status === EntityStatus.Active ? EntityStatus.DeActive : EntityStatus.Active,
            });
        }
    };

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 80,
            maxWidth: 100,
            fieldName: 'commands',
            onRender: (items: EmployeeInfo) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    onClick={() => setCurrentActivatedUser({userId: items.id, status: items.status})}
                >
                    {items.status === EntityStatus.Active ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'name',
            name: formatMessage({id: 'name'}),
            minWidth: 200,
            maxWidth: 600,
            fieldName: 'FirstName',
            onRender: (items: EmployeeInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles} onClick={() => showEmployeeModal(items.id)}>
                    {items.firstName} {items.lastName}
                </Link>
            ),
        },
        {
            key: 'userName',
            name: formatMessage({id: 'userName'}),
            minWidth: 150,
            maxWidth: 250,
            fieldName: 'User.Username',
            onRender: (items: EmployeeInfo) => <Text>{items.userName}</Text>,
        },
        {
            key: 'securityLevel',
            name: formatMessage({id: 'securityLevel'}),
            minWidth: 150,
            maxWidth: 250,
            fieldName: 'RoleCode',
            onRender: (items: EmployeeInfo) => <Text>{items.role}</Text>,
        },
        {
            key: 'team',
            name: formatMessage({id: 'team'}),
            minWidth: 200,
            maxWidth: 450,
            fieldName: 'Team.Name',
            onRender: (items: EmployeeInfo) => <Text>{items.team?.name}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['name', 'userName', 'securityLevel', 'team']}
                initialColumns={columns}
                items={employeesItems}
                selectionMode={SelectionMode.none}
                setOrder={setSort}
                enableShimmer={isLoading}
                containerHeight='100%'
            />

            {!!currentActivatedUser?.userId && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={handleChangeClientStatus}
                    toggleHidden={() => setCurrentActivatedUser(null)}
                    noDisabled={isLoadingEmployeesUpdateStatus}
                    yesDisabled={isLoadingEmployeesUpdateStatus}
                />
            )}
        </Card>
    );
};
