import { useContext } from 'react';
import { commonHeaders, Context, queryClient } from '../../../../../utils';
import { useMutation } from 'react-query';
import axios from 'axios';

type MutateDataType = {
    softwareType: number;
    businessCode: string;
    name: string;
    email: string;
};

function useSendAuth(guid: string) {
    const context = useContext(Context);
    const {
        mutate: send,
        isLoading: isSending,
    } = useMutation(
        (data: MutateDataType) =>
            axios.post(`${context?.config?.importUrl}/api/1.0/import/external/send/${guid}`, data, {
                ...commonHeaders(),
            })
    );

    return { send, isSending };
}

export { useSendAuth };
