import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    GeneratedAnswer,
    JobComment, JobOmls,
    ProceduresDataTable,
    useProcedureContext,
    useSectionContext
} from 'pages/JobPortal/components';
import {ITableColumn, SanitizedText} from 'components';
import {useIntl} from 'react-intl';
import {Icon, IconButton, IDropdownOption, MessageBarType, Stack, StackItem, Text, useTheme} from "@fluentui/react";
import {AnswerControlType, TableType} from "../../../../../enums";
import {NotificationType, useSignalR, useTenantInfo} from "../../../../../providers";
import {ColumnInfo} from "../../../enums";
import {ConclusionStatement} from "./ConclusionOpinion/enums";
import {IConclusionOpinionItem} from "./ConclusionOpinion/interfaces";
import {calculateSectionAnswer, DropdownAnswer, isPositiveAnswer} from "../answers";
import {useUpdateItemAnswer} from "../../../hooks/answers";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {RedirectButton} from "../shared";
import {ConclusionReportItem, ConclusionReportsTemplate, getNewActionTakenValue} from "./ConclusionReports";
import {IJobOmlItem} from "../../OmlComponent/interfaces/IJobOmlItem";
import {queryClient} from "../../../../../utils";
import {useTabContext} from "../../../JobPortalPage";

interface IConclusionSection {
    items: ConclusionProcedureItem[],
    conclusionStatement: number,
    positiveAnswer: string,
    negativeAnswer: string,
    conditionalAnswer: string,
    noOpinionAnswer: string,
    tableType: TableType
    reports: { [key: string]: ConclusionReportItem[] }
}

type ConclusionProcedureItem = IConclusionOpinionItem & { dropdownOptions: IDropdownOption[] } & any;

export const ConclusionProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading, answers: procedureAnswers} = useProcedureContext();
    const {
        hide,
        section: tabSection,
        isPositiveSectionAnswer,
        updateSectionAnswer,
        forcePositiveSectionAnswer
    } = useSectionContext();
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const theme = useTheme();
    const {jobId, answerOptions} = useJobContext();
    const {useSignalREffect} = useSignalR()
    const {isTabEnabled} = useTabContext();

    const actionTakenAnswerRefs = useRef<any>([]);

    const {update, isUpdating} = useUpdateItemAnswer();

    const section = useMemo<IConclusionSection | undefined>(() => {
        let item = items.length > 0 ? items[0] as IConclusionSection : undefined;

        if (item && item.items?.length > 0) {
            item.items = item.items.map(i => {
                const refValues = (i.auditProcedureReference ?? '').replace('#', '').replace('//', '/').split('/');
                return {
                    ...i,
                    dropdownOptions: [...i.options.reduce((acc: any, o: any) => {
                        acc.push({key: o.field, text: o.value} as IDropdownOption)
                        return acc;
                    }, [])],
                    location: {
                        tabId: refValues.at(0),
                        sectionId: refValues.at(1),
                        itemId: refValues.at(2)
                    },
                    id: i.procedureId
                }
            });
        }

        return item;
    }, [items])

    const actionTakenOptions = useMemo<IDropdownOption[]>(() => {
        const values = ['', 'Cleared', 'W Query Raised', 'NW Query Raised', 'Query Cleared', 'Contravention', 'Qualification', 'Resolved', 'ACL'];

        return values.map(x => ({key: x, text: x}));
    }, [])

    useEffect(() => {
        const hasProcedures = !!section?.items?.length;
        const hasReports = !!Object.keys(section?.reports ?? {}).length && Object.keys(section!.reports).reduce((acc: boolean[], k) => {
            acc.push(!!section!.reports[k]?.length);
            return acc;
        }, []).some(x => x);

        if (isLoading || (!hasProcedures && !hasReports)) {
            hide?.();
        }

        if ((hasProcedures || hasReports) && tabSection?.reference === 'Conclusion_Members') {
            const sectionAnswer = calculateSectionAnswer(procedureAnswers ?? [], answerOptions, isPositiveSectionAnswer);
            updateSectionAnswer(sectionAnswer)
        }

        if (section?.conclusionStatement != null) {
            forcePositiveSectionAnswer(section.conclusionStatement === ConclusionStatement.Positive);
        }

    }, [section, tabSection, isPositiveSectionAnswer, procedureAnswers])

    useEffect(() => {
        actionTakenAnswerRefs.current = actionTakenAnswerRefs.current.slice(0, items.length);
    }, [items])

    useSignalREffect("answer_actiontaken_set", (notification: NotificationType) => {
        const {itemId, change, jobId: notificationJobId} = notification.content;

        const index = (section?.items ?? []).findIndex(i => i.id === itemId);

        if (index !== -1 && notificationJobId === jobId) {
            actionTakenAnswerRefs.current[index].setNewValue(change.NewValue);
            // setShouldRefresh(Date.now())
            queryClient.invalidateQueries('getProcedures_' + tabSection.id)
        }
    }, [formatMessage, tabSection.id]);

    const getColor = useCallback((conclusionStatement: ConclusionStatement) => {
        switch (conclusionStatement) {
            case ConclusionStatement.Positive:
            case ConclusionStatement.Conditional:
                return theme.palette.black;
            case ConclusionStatement.NoOpinion:
            case ConclusionStatement.Negative:
                return theme.palette.red;
        }
    }, [theme.palette]);

    const getSectionColor = useCallback((conclusionStatement: ConclusionStatement) => {
        switch (conclusionStatement) {
            case ConclusionStatement.Positive:
                return theme.palette.green;
            case ConclusionStatement.Conditional:
            case ConclusionStatement.NoOpinion:
            case ConclusionStatement.Negative:
                return theme.palette.red;
        }
    }, [theme.palette]);

    const getAnswerText = useCallback((item: any & { conclusionStatement: ConclusionStatement }) => {
        switch (item.conclusionStatement) {
            case ConclusionStatement.Positive:
                return item.positiveAnswer;
            case ConclusionStatement.Negative:
                return item.negativeAnswer;

            case ConclusionStatement.Conditional:
                return item.conditionalAnswer;

            case ConclusionStatement.NoOpinion:
                return item.noOpinionAnswer;
        }
    }, [items]);

    const handleOnResultChange = (newValue: string | null, item: any, index: number) => {
        update({
            jobId: jobId,
            itemId: item.procedureId,
            tableType: item.tableType,
            columnInfo: ColumnInfo.AnswerText,
            text: newValue
        })
    }

    const columns: ITableColumn[] = useMemo<ITableColumn[]>(() =>
        [
            {
                key: 'actions',
                name: formatMessage({id: 'actions'}),
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item, _, column) => (
                    <Stack grow={1} horizontal horizontalAlign='end'>
                        {!item.isTitle &&
                            <RedirectButton tabId={item.location?.tabId} sectionId={item.location?.sectionId}
                                            itemId={item.location?.itemId}/>}
                        {!item.isTitle && <JobOmls tableType={TableType.AuditProcedure!}
                                                   itemId={item.procedureId}
                                                   hasOmls={item.hasOmls}
                        />}
                        <JobComment itemId={item?.procedureId} hasComments={item?.hasComments}
                                    tableType={TableType.AuditProcedure}/>
                    </Stack>
                ),

            },
            {
                key: 'auditProcedureNumber',
                name: '',
                fieldName: 'number',
                minWidth: 20,
                maxWidth: 20,
                onRender: (item, _, column) => (
                    <Stack horizontalAlign={"center"} grow>
                        <Text>{item?.auditProcedureNumber}</Text>
                    </Stack>
                )
            },
            {
                key: 'auditProcedure',
                name: formatMessage({id: 'auditProcedure'}),
                minWidth: 256,
                fieldName: 'text',
                onRender: (item, _, column) => (
                    <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                        <SanitizedText data={item?.text} styles={{
                            root: {
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellypsis'
                            }
                        }}/>
                    </Stack>
                ),
            },
            {
                key: 'assertion',
                name: formatMessage({id: 'assertion'}),
                fieldName: 'assertion',
                skip: isWorkpapers,
                minWidth: 256,
                maxWidth: 384,
                onRender: (item, _, column) => item?.showAssertion ? (
                    <Stack>
                        <Text>
                            <span
                                style={{color: theme.palette.themePrimary}}>{formatMessage({id: 'financial'}) + ': '}</span>
                            <SanitizedText data={item?.assertionFinancial || ''}/>
                        </Text>
                        <Text>
                            <span
                                style={{color: theme.palette.themePrimary}}>{formatMessage({id: 'compliance'}) + ': '}</span>
                            <SanitizedText data={item?.assertionCompliance || ''}/>
                        </Text>
                    </Stack>
                ) : null,
            },
            {
                key: 'auditingSTD',
                name: formatMessage({id: 'auditingSTD'}),
                fieldName: 'auditingSTD',
                minWidth: 96,
                maxWidth: 128,
                skip: isWorkpapers,
                onRender: (item, _, column) => item?.showAssertion && <SanitizedText data={item?.auditingStd}/>,
            },
            {
                key: 'result',
                name: formatMessage({id: 'result'}),
                fieldName: 'result',
                minWidth: 180,
                maxWidth: 180,
                onRender: (item, _, column) => !item?.isTitle && (
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer columnInfo={ColumnInfo.AnswerText}
                                         itemId={item.procedureId}
                                         tableType={item.tableType}
                                         options={item?.dropdownOptions}
                                         value={item.answerText}
                                         answerControlType={AnswerControlType.Dropdown}
                                         onChange={(newValue) => handleOnResultChange(newValue, item, _!)}
                                         styles={{root: {maxWidth: column?.minWidth}}}
                        />
                    </Stack>
                ),
            },
            {
                key: 'actionTaken',
                name: formatMessage({id: 'actionTaken'}),
                fieldName: 'actionTaken',
                minWidth: 180,
                maxWidth: 180,
                onRender: (item, _, column) => !item?.isTitle && (
                    <Stack verticalAlign='center' grow>
                        <DropdownAnswer ref={el => actionTakenAnswerRefs.current[_!] = el}
                                        itemId={item.procedureId}
                                        options={actionTakenOptions}
                                        value={item?.actionTaken}
                                        disabled={true}
                                        ignoreSectionAnswerUpdating={true}
                                        styles={{root: {maxWidth: column?.minWidth}}}
                        />
                    </Stack>
                ),
            }
        ], [formatMessage])

    return (
        <>
            {((section?.items?.length ?? 0) > 0 || !!section?.reports) && <Stack tokens={{childrenGap: 8}}>
                <Stack.Item>
                    <Stack>
                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 8}}>
                                <Stack.Item>
                                    {section!.conclusionStatement === ConclusionStatement.NoOpinion && (
                                        <Icon iconName='AlertSolid'
                                              styles={{root: {color: getColor(section!.conclusionStatement)}}}/>)}
                                </Stack.Item>
                                <Stack.Item>
                                    <SanitizedText data={getAnswerText(section)}
                                                   color={getColor(section!.conclusionStatement)}/>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>

                {(section?.items?.length ?? 0) > 0 && <Stack.Item>
                    <Stack tokens={{childrenGap: 8}}>

                        <Stack.Item>
                            <ProceduresDataTable
                                items={section?.items as any[]}
                                isLoading={isLoading}
                                columns={columns}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>}

                {!!section?.reports &&
                    <Stack.Item>
                        <ConclusionReportsTemplate reports={section?.reports}></ConclusionReportsTemplate>
                    </Stack.Item>
                }
            </Stack>}
        </>
    );
};