import React, {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {Attachment, JobComment, ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {Stack} from "@fluentui/react";
import {TableType} from "../../../../../enums";

interface IClientComment {
    id: number;
    jobId: number;
    tableType: TableType;
    description: string;
    comment: string;
    tabCommentId: number;
    order: number;
    hasAttachments: boolean;
    hasComments: boolean;
}

export const ClientCommentsProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {formatMessage} = useIntl();

    return (
        <ProceduresDataTable
            items={items}
            emptyProps={{
                iconName: 'CommentUrgent'
            }}
            isLoading={isLoading}
            columns={[
                {
                    key: 'actions',
                    name: formatMessage({id: 'actions'}),
                    fieldName: 'actions',
                    onRender: (item: IClientComment) => <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                        <JobComment itemId={item?.id} hasComments={item?.hasComments}/>
                    </Stack>,
                    minWidth: 70,
                    maxWidth: 70
                },
                {
                    key: 'commentDescription',
                    name: formatMessage({id: 'commentDescription'}),
                    fieldName: 'commentDescription',
                    onRender: (item: IClientComment) => <SanitizedText data={item?.description}/>,
                    minWidth: 250,
                    maxWidth: 250
                },
                {
                    key: 'comments',
                    name: formatMessage({id: 'comments'}),
                    fieldName: 'comments',
                    onRender: (item: IClientComment) => <SanitizedText data={item?.comment}/>,
                    minWidth: 150
                },
            ]}
        />
    );
};