import {FunctionComponent, useState} from 'react';
import {Modal} from 'components';
import {DashboardTableTypeEnum, FilterByPeriod} from '..';
import {Stack} from '@fluentui/react';
import {Table} from './Table';

export interface ITableModal {
    tableType: DashboardTableTypeEnum;
    isWorkflow?: boolean;
    filterByPeriod?: FilterByPeriod;
}

interface Props {
    tables: ITableModal[];
    isOpen: boolean;
    onDismiss: () => any;
}


export const GeneratedTableModal: FunctionComponent<Props> = ({tables, isOpen, onDismiss}) => {
    const [tableName, setTableName] = useState<string>();
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={tableName} minWidth={'70vw'}>
            <Stack tokens={{childrenGap: 16}}>
                {tables.map((table, idx) => (
                    <Table isWorkflow={table.isWorkflow} setTableName={setTableName} tableType={table.tableType} filterByPeriod={table.filterByPeriod}
                           key={`modal-table-${table.tableType}-${idx}`}/>
                ))}
            </Stack>
        </Modal>
    );
};