import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {INavLink, Stack} from '@fluentui/react';
import {IMenuLink, ModalWithForm, VerticalMenu} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useBoolean} from '@fluentui/react-hooks';
import {AuditParticularsTab, FoundInfoTab, JobsTab} from './tabs';
import {useGetFundByGuid, useUpdateFundsAuditInfo, useUpdateFundsMainInfo} from "../../hooks";
import {SoftwareType} from "enums";
import {useTenantInfo} from "../../../../../providers";
import {useGetAuditFirms} from "../../../../ClientPortal/PortalSettings/hooks/Audit/useGetAuditFirms";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    fundGuid?: string;
    clientGuid?: string;
    fundId?: number;
    clientId?: number;
}

enum FundModalTabs {
    FUND_INFO,
    AUDIT_PARTICULARS,
    JOB,
}

export interface IChangeFundInfoCommand {
    accountingSoftware?: SoftwareType | null;
    auditingCode?: string;
    name?: string;
    trustee?: string;
    abn?: string;
    contactId?: number;
    clientId?: number;
    clientName?: string;
    auditFirmId?: number;
}

export const FundModal: FunctionComponent<Props> = observer(
    ({onDismiss, isOpen, title, fundGuid, clientGuid, fundId, clientId}: Props) => {
        const {formatMessage} = useIntl();
        const {isWorkpapers} = useTenantInfo();
        const [activeTab, setActiveTab] = useState<FundModalTabs>(FundModalTabs.FUND_INFO);

        const {currentFundData, isLoadingCurrentFundData} = useGetFundByGuid({
            clientGuid: clientGuid || '',
            fundGuid: fundGuid || '',
        });

        const {updateFundsMainInfo, isUpdatingFundsMainInfo} = useUpdateFundsMainInfo();

        const {updateFundsAuditInfo, isUpdatingFundsAuditInfo} = useUpdateFundsAuditInfo();

        const isGlobalUpdating = useMemo(() => {
            return isUpdatingFundsMainInfo || isUpdatingFundsAuditInfo;
        }, [isUpdatingFundsMainInfo, isUpdatingFundsAuditInfo]);
        const globalLoading = useMemo(() => {
            return isLoadingCurrentFundData || isUpdatingFundsMainInfo || isUpdatingFundsAuditInfo;
        }, [isLoadingCurrentFundData, isUpdatingFundsMainInfo, isUpdatingFundsAuditInfo]);

        const links = useMemo<IMenuLink[]>(() => [
            {
                name: formatMessage({id: 'general'}),
                url: '',
                key: 'General',
                iconProps: {iconName: 'Settings'},
                isExpanded: true,
                links: [
                    {
                        name: formatMessage({id: 'fundInformation'}),
                        url: '',
                        key: FundModalTabs[FundModalTabs.FUND_INFO],
                        iconProps: {iconName: 'ComplianceAudit'},
                        onClick: () => {
                            setActiveTab(FundModalTabs.FUND_INFO);
                        },
                        isExpanded: true,
                    },
                    {
                        name: formatMessage({id: 'auditParticulars'}),
                        url: '',
                        hidden: isWorkpapers,
                        key: FundModalTabs[FundModalTabs.AUDIT_PARTICULARS],
                        iconProps: {iconName: 'MapPin'},
                        onClick: () => {
                            setActiveTab(FundModalTabs.AUDIT_PARTICULARS);
                        },
                    },
                ],
            },
            {
                name: formatMessage({id: 'jobs'}),
                url: '',
                key: FundModalTabs[FundModalTabs.JOB],
                iconProps: {iconName: 'MapLayers'},
                onClick: () => {
                    setActiveTab(FundModalTabs.JOB);
                },
            },
        ], [isWorkpapers]);

        const {control, handleSubmit, formState, reset, setValue} = useForm<IChangeFundInfoCommand>({
            ...DefaultFormSettings,
            defaultValues: {
                abn: '',
                trustee: '',
                auditingCode: '',
                accountingSoftware: null,
                name: '',
                contactId: 0,
                clientId: 0
            },
        });

        useEffect(() => {
            if (currentFundData?.data) {
                setValue('abn', currentFundData.data?.abn || '');
                setValue('trustee', currentFundData.data?.trustee || '');
                setValue('auditingCode', currentFundData.data?.auditingCode);
                setValue('name', currentFundData.data?.name);
                setValue('accountingSoftware', currentFundData?.data.accountingSoftware || null);
                setValue('contactId', currentFundData?.data.primaryContactId || undefined);
                setValue('clientName', currentFundData.data.clientName || '');
            }
        }, [currentFundData?.data, setValue]);

        const onSave = () => {
            handleSubmit(onSubmit)().then(() => onDismiss());
        };

        const onSubmit = (data: IChangeFundInfoCommand) => {
            if (currentFundData?.data) {
                switch (activeTab) {
                    case FundModalTabs.FUND_INFO:
                        updateFundsMainInfo({
                            clientId: clientId || 0,
                            fundId: fundId || 0,
                            abn: data.abn || '',
                            name: data.name || '',
                            trustee: data.trustee || '',
                            primaryContactId: data.contactId || 0,
                            auditFirmId: data.auditFirmId
                        });
                        return;
                    case FundModalTabs.AUDIT_PARTICULARS:
                        updateFundsAuditInfo({
                            clientId: clientId || 0,
                            fundId: fundId || 0,
                            code: data.auditingCode || '',
                        });
                        return;

                    default:
                        return;
                }
            }
        };

        const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);

        return (
            <ModalWithForm isSubmitting={isGlobalUpdating}
                           isOpen={isOpen}
                           onDismiss={()=>{reset();onDismiss(); setActiveTab(FundModalTabs.FUND_INFO)}}
                           isLoading={isLoadingCurrentFundData}
                           title={title}
                           width={'60vw'}
                           submitDisabled={globalLoading || (activeTab === FundModalTabs.JOB ? false : !formState.isDirty)}
                           onSubmit={onSave}>
                <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -16px'}}}>

                    <VerticalMenu selectedKey={FundModalTabs[activeTab || FundModalTabs.FUND_INFO]} links={links}
                                  isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>

                    <Stack
                        styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`}}}
                        tokens={{childrenGap: 16}}>
                        <Stack.Item styles={{root: {height: 'calc(100% - 50px)'}}}>
                            <>
                                <Stack.Item>
                                    {activeTab === FundModalTabs.FUND_INFO ? (
                                        <FoundInfoTab clientName={currentFundData?.data.clientName}
                                                      clientGuid={currentFundData?.data.clientGuid} control={control}/>
                                    ) : activeTab === FundModalTabs.AUDIT_PARTICULARS ? (
                                        <AuditParticularsTab control={control}/>
                                    ) : activeTab === FundModalTabs.JOB ? (
                                        <JobsTab fundId={currentFundData?.data.id}/>
                                    ) : ('')}
                                </Stack.Item>
                            </>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </ModalWithForm>
        );
    }
);
