import {FunctionComponent} from "react";
import {IProceduresDataTableProps, ProceduresDataTable} from "../../../ProceduresDataTable";

export const AuditQueriesDataTableDateFormat = 'dd/MM/yyyy HH:mm:ss'

export interface IAuditQueriesDataTableProps extends IProceduresDataTableProps {
    
}

export const AuditQueriesDataTable: FunctionComponent<IAuditQueriesDataTableProps> = ({ 
                                                                                          items,
                                                                                          columns,
                                                                                          isLoading,
                                                                                          ...props
                                                                                      }: IProceduresDataTableProps) => {
    
    return (
        <ProceduresDataTable 
            items={items} 
            isLoading={isLoading}
            columns={columns}
            hideIfEmpty={false}
        />
    )
}