import {Card, ControlledCheckbox, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {ICreateUserCommand} from 'interfaces';
import {Control} from 'react-hook-form';
import {IChangeClientInfoCommand} from "../../../../Clients";
import {DetailedSoftwareType, SoftwareType} from "../../../../../../enums";
import {useGetEmployeesByPosition} from "../../../../Employees";
import {EmployeePosition} from "../../../../shared";
import {useMemo} from "react";

type AuditTabType = {
    control: Control<IChangeClientInfoCommand>;
};

export const AuditTrusteeTab = ({control}: AuditTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {employeesByPosition, isEmployeesLoading} = useGetEmployeesByPosition(EmployeePosition.Partner);

    const optionsSoftware: IDropdownOption[] = [
        {key: SoftwareType.BGL360, text: formatMessage({id: 'bgl360'})},
        {key: SoftwareType.Nothing, text: formatMessage({id: 'other'})},
        {key: DetailedSoftwareType.ClassSuperAPI, text: formatMessage({id: 'classSuperApi'})}
    ];

    const partnerOptions = useMemo<IDropdownOption[]>(() => {
        return employeesByPosition?.data.map(x => ({key: x.id, text: `${x.firstName} ${x.lastName}`})) || [];
    }, [employeesByPosition?.data]);

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={optionsSoftware}
                        name='accountingSoftware'
                        label={formatMessage({id: 'accountingSoftware'})}
                        control={control}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={partnerOptions}
                        disabled={isEmployeesLoading}
                        name='partnerId'
                        label={formatMessage({id: 'partner'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='turnAroundTime' label={formatMessage({id: 'turnAroundTime'})}
                                         control={control}/>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Stack tokens={{childrenGap: 16}} verticalAlign={"center"}>
                        <ControlledCheckbox label={formatMessage({id: 'titleSearchConduct'})} control={control}
                                            name='titleSearchConduct'/>
                        <ControlledCheckbox name='asicSearchConduct' control={control}
                                            label={formatMessage({id: 'ASICSearchConduct'})}/>
                    </Stack>

                </Stack.Item>
            </Stack>
        </Card>
    );
};
