import {FunctionComponent} from 'react';
import {Pagination} from 'components';
import {IUserFilters, useUserManagement} from '../UserManagementPage';

interface Props {
    shownItemsCount: number;
    totalItemsCount: number;
}

export const UserManagementPagination: FunctionComponent<Props> = ({shownItemsCount, totalItemsCount}) => {
    const {filters, setFilters} = useUserManagement();

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters((prev: IUserFilters) => ({...prev, pageSize, page}));
    };

    return (
        <Pagination
            itemsCount={shownItemsCount}
            total={totalItemsCount}
            page={filters.page}
            pageSize={filters.pageSize}
            onChange={onPaginationChange}
        />
    );
};
