import { Shimmer, ShimmerElementType } from '@fluentui/react';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { TestingAndMethodologySettings } from './components/TestingAndMetodologySettings';
import { useGetMethodologySettings } from './hooks/Testing/useGetMethodologySettings';
import { useUpdateMethodologySettings } from './hooks/Testing/useUpdateMethodologySettings';

/**
 * Testing and Methodology Settings Wrapper component.
 * Only for getting API data.
 * Consist from table
 */

export const TestingAndMethodologySettingsPage: FunctionComponent = () => {
    const { methodologySettings, isLoading } = useGetMethodologySettings();
    const { updateMethodologySettings, isLoading: updateMethodologyLoading } = useUpdateMethodologySettings();

    return (
        <>
            {!methodologySettings?.data || isLoading || updateMethodologyLoading ? (
                <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 791 }]} />
            ) : (
                <TestingAndMethodologySettings
                    methodologySettings={methodologySettings?.data}
                    updateMethodologySettings={updateMethodologySettings}
                />
            )}
        </>
    );
};
