import {useContext} from "react";
import {Context} from "../../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {IRiskNote} from "../interfaces";

type MutateDataType = {
    id: number;
    jobId: number;
    isPermanent: boolean;
}
function useChangeRiskNotePermState(){
    const context = useContext(Context);
    const host = context?.config?.riskMatrixUrl;

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${host}/api/1.0/jobs/${data.jobId}/riskMatrix/notes/${data.id}/permanent`, data));

    return {update, updateAsync, isUpdating};
}
export {useChangeRiskNotePermState};