import React, {FunctionComponent} from 'react';
import {useProcedureContext} from 'pages/JobPortal/components';
import {RiskNotes} from "./RiskNotes";
import {RiskCalculator} from "./RiskCalculator";


export const RiskMatrixProcedureTemplate: FunctionComponent = () => {
    const {items} = useProcedureContext();

    return (
        <>
            <RiskCalculator items={items}/>
            <RiskNotes note={items.find(x => x.reference === 'Notes')}/>
        </>
    );
};



