import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

type PropsType = {
    guid?: string | undefined;
};

function useGetJobByGuid({guid}: PropsType) {
    const context = React.useContext(Context);
    
    const {data: dataJobs, isFetching: isJobLoading, refetch: refetchJob, isRefetching: isJobRefetching} = useQuery(['getCurrentJobs', guid], () =>
            axios.get(`${context?.config?.jobUrl}/api/1.0/Job/${guid}`, commonHeaders()), {
            staleTime: Infinity,
            enabled: !!context?.config?.jobUrl && !!guid,
        }
    );

    return {dataJobs, isJobLoading, refetchJob, isJobRefetching};
}

export {useGetJobByGuid};
