import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    id: number;
    jobId: number;
    isClientComment: boolean;
} & { comment?: string }

function useUpdateAuditQueryComment() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.patch(`${context?.config?.queriesUrl}/api/1.0/queries/updateComment/${data.id}`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getCPJobOmlItems'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useUpdateAuditQueryComment};