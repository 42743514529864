import {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {PageWithBreadcrumb} from "../../components";
import {RouterPaths} from "../../navigation";
import {Outlet} from "react-router-dom";

export const InvoicesLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'invoices',
                key: RouterPaths.invoices.invoices,
                text: formatMessage({ id: 'invoices' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};