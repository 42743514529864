import { FunctionComponent, useState } from 'react';
import { Stack } from '@fluentui/react';
import {useInvoicePortalContext} from '../../InvoicePortalLayoutPage';
import {useIntl} from "react-intl";
import {useGetCreditNotes} from "./hooks";
import {CreditNotesContext, ICreditNotesFilter} from "./inderfaces";
import {DefaultPaginationPageSize} from "../../../../constants/paginationSettings";
import {CreditNotesDataTable, CreditNotesPaginator} from "./components";

export const CreditNotesPageTemplate: FunctionComponent = () => {
    const {formatMessage} = useIntl();
    
    const {clientIdentifier} = useInvoicePortalContext();
    
    const [filter, setFilter] = useState<ICreditNotesFilter>({ pageNumber: 1, pageSize: DefaultPaginationPageSize })
    
    const {creditNotes, isLoading, isFetching} = useGetCreditNotes(clientIdentifier, filter);

    const onPagination = (pageSize: number, page: number) => {
        setFilter({ pageNumber: page, pageSize: pageSize });
    }
    
    return (
        <CreditNotesContext.Provider value={{
            filter: filter,
            setFilter: setFilter
        }}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item grow={1}>
                    <CreditNotesDataTable items={creditNotes?.items ?? []} isLoading={isLoading} isFetching={isFetching} />
                </Stack.Item>
                <Stack.Item shrink={0}>
                    <CreditNotesPaginator 
                        pageCount={creditNotes?.items.length ?? 0}
                        pageNumber={filter.pageNumber} 
                        pageSize={filter.pageSize} 
                        totalCount={creditNotes?.totalCount ?? 0} 
                        onChange={onPagination} />
                </Stack.Item>
            </Stack>
        </CreditNotesContext.Provider>
    )
}