import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import { useInvoicePortalNotifications } from "../../../hooks";

export type PaymentData = { invoiceId: number, amount: number }

export type ApplyCreditNoteData = {
    creditNoteId: number;
    items: PaymentData[]
}

function useApplyCreditNote(clientIdentifier: string) {
    const context = useContext(Context);
    const { showApiOnErrorNotification, showApiOnSuccessNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: apply,
        mutateAsync: applyAsync,
        isLoading: isApplying
    } = useMutation((data: ApplyCreditNoteData[]) => axios.post(`${context?.config?.billingUrl}/api/1.0/CreditNote/Apply/${clientIdentifier}`, { data: data }, {
        ...commonHeaders()
    }), {
        onSuccess: () => { 
            queryClient.invalidateQueries('getCreditNotes');
            showApiOnSuccessNotification({ id: 'creditNoteApplied', values: {name: ''}})
        },
        onError: (error: AxiosError) => {
            showApiOnErrorNotification({ values: { ...error?.response?.data ?? {} }})
        }
    });

    return {apply, applyAsync, isApplying};
}

export {useApplyCreditNote};