import {useContext} from "react";
import {Context} from "../../../../../utils";
import {useMutation} from "react-query";
import {IJobOmlItem} from "../interfaces/IJobOmlItem";
import axios from "axios";
import {TableType} from "../../../../../enums";

type MutateDataType = {
    jobId: number;
    omls: IJobOmlItem[];
}

function useUpdateJobOmlItems() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.queriesUrl}/api/1.0/queries/updateBatch/${data.jobId}`, data.omls))

    return {update, updateAsync, isUpdating};
}

export {useUpdateJobOmlItems};