import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';

export interface ICreateImport {
  jobId: number;
}

function useCreateImport() {
    const context = React.useContext(Context);

    const { mutate: createImport, isLoading, isSuccess } = useMutation(({ jobId }: ICreateImport) =>
        axios.post(`${context?.config?.importUrl}/api/1.0/import/import/${jobId}`, null, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getJobImportData'),
        }
    );

    return { createImport, isLoading, isSuccess };
}

export { useCreateImport };