import {Card, ControlledDropdown, ControlledTextField, Modal} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IconButton, IDropdownOption, Stack, TooltipHost} from '@fluentui/react';
import {Control} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useMemo, useState} from 'react';
import {IChangeEmployeeCommand} from '..';
import {useGetAllTeams} from "../../../hooks";
import {EmployeePosition, EmployeeRole} from "pages/WorkflowPortal/shared";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {TeamWorkflowPage} from "../../../../Team";

type GeneralTabType = {
    control: Control<IChangeEmployeeCommand>;
    position?: string;
    role?: string;
    teamId?: number;
};

export const GeneralTab = ({control, position, role, teamId}: GeneralTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const editBtnId = useId("edit-btn");

    const {formatMessage} = useIntl();
    const {teams, isLoading: isTeamsLoading} = useGetAllTeams();
    const [showTeamModal, {setTrue: setTeamModalAsShown, setFalse: setTeamModalAsHidden}] = useBoolean(false);

    const optionsPosition: IDropdownOption[] = useMemo(() => {
        return Object.keys(EmployeePosition).filter((x) => Number.isNaN(Number(x))).map((c => ({
            key: c.toString(),
            text: c.toString(),
            selected: c == position
        })));
    }, [position]);
    const optionsRole: IDropdownOption[] = useMemo(() => {
        return Object.keys(EmployeeRole).filter((x) => Number.isNaN(Number(x))).map(c => ({
            key: c.toString(),
            text: c.toString(),
            selected: c == role
        }));
    }, [role]);
    const optionsTeam: IDropdownOption[] = useMemo(() => {
        return teams?.data.map(x => ({key: x.id, text: x.name, selected: x.id == teamId})) || [];
    }, [teams, teamId]);


    return (
        <>
            <Card styles={CardTableContainerStyles}>
                <Stack horizontal tokens={{childrenGap: 16}} wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            required
                            name='userName'
                            label={formatMessage({id: 'userName'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                                minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            required
                            name='email'
                            label={formatMessage({id: 'email'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                                minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='firstName'
                            required
                            label={formatMessage({id: 'firstName'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                                minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField
                            name='lastName'
                            required
                            label={formatMessage({id: 'lastName'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                                minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            name='position'
                            required
                            label={formatMessage({id: 'position'})}
                            control={control}
                            options={optionsPosition}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={optionsRole}
                            name='securityLevel'
                            required
                            label={formatMessage({id: 'securitylevel'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                                minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                            }}
                        />
                    </Stack.Item>

                    <Stack horizontal verticalAlign={"center"} horizontalAlign={"space-between"}
                           tokens={{childrenGap: 16}} styles={{root: {width: '100%'}}}>
                        <Stack.Item styles={{root: {width: '90%'}}}>
                            <ControlledDropdown options={optionsTeam} name='teamId' label={formatMessage({id: 'team'})}
                                                control={control}/>
                        </Stack.Item>
                        <Stack.Item styles={{root: {width: '5%', paddingTop: 25}}}>
                            <TooltipHost
                                content={formatMessage({id: 'edit'})}
                                id={editBtnId}>
                                <IconButton aria-describedby={editBtnId} onClick={setTeamModalAsShown}
                                            iconProps={{iconName: 'edit'}}/>
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>

                </Stack>
            </Card>

            <Modal isOpen={showTeamModal} onDismiss={setTeamModalAsHidden}
                   title={formatMessage({id: 'teams'})}>
                <TeamWorkflowPage/>
            </Modal>
        </>
    );
};
