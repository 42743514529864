import { FontSizes, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';

type ImportReportProgressProps = {
    stage?: string | null;
};
export const ImportReportProgress = ({ stage }: ImportReportProgressProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    return (
        <Stack grow={1}>
            <Spinner
                size={SpinnerSize.large}
                styles={{ label: { fontSize: FontSizes.mediumPlus, color: theme.semanticColors.bodyText } }}
                label={formatMessage({ id: 'importInProgress' }, { stage: stage })}
                labelPosition='right'
            />
        </Stack>
    );
};
