import { useIntl } from "react-intl";
import { MessageBarType } from "@fluentui/react";
import { useNotifications } from "../../../components/notifications";

type ShowNotificationFn = (config?: MessageConfig) => void;

type NotificationFacade = {
	success: ShowNotificationFn,
	error: ShowNotificationFn
}

export type MessageConfig = {
	id?: string;
	values: { name: string } & any
}

export function useInvoicePortalNotifications() {
	const {showNotification} = useNotifications();
	const {formatMessage} = useIntl();
	
	const showApiOnSuccessNotification = (config?: MessageConfig) => {
		const _config = {
			...((c?: MessageConfig): MessageConfig => (c ?? {} as MessageConfig))(config)
		}
		
        showNotification({
            type: MessageBarType.success,
            name: formatMessage({ id: 'success' }),
            description: formatMessage({ id: _config.id }, {..._config.values}),
        });
    }

	const showInfoNotification = (config?: MessageConfig) => {
		const _config = {
			...((c?: MessageConfig): MessageConfig => (c ?? {} as MessageConfig))(config)
		}

		showNotification({
			type: MessageBarType.info,
			name: formatMessage({ id: 'info' }),
			description: formatMessage({ id: _config.id }, {..._config.values}),
		});
	}

	const showWarningNotification = (config?: MessageConfig) => {
		const _config = {
			...((c?: MessageConfig): MessageConfig => (c ?? {} as MessageConfig))(config)
		}

		showNotification({
			type: MessageBarType.warning,
			name: formatMessage({ id: 'warning' }),
			description: formatMessage({ id: _config.id }, {..._config.values}),
		});
	}
	
	const showApiOnErrorNotification = (config?: MessageConfig) => {
		const _config = {
			...{ id: 'errorDefaultMessage', values: {} },
			...((c?: MessageConfig): MessageConfig => (c ?? {} as MessageConfig))(config)
		}

		showNotification({
			type: MessageBarType.error,
			name: formatMessage({ id: 'error' }),
			description: _config.values.message ?? formatMessage({ id: _config.id }, {..._config.values}),
		});
	}
	
	const showOnCreateNotification: NotificationFacade = {
        success: (config?: MessageConfig) => showApiOnSuccessNotification({ id: 'entityWasCreated', values: { name: 'Entity' }, ...config ?? {}}),
        error: (config?: MessageConfig) => showApiOnErrorNotification(config),
    };

	const showOnDeleteNotification: NotificationFacade = {
		success: (config?: MessageConfig) => showApiOnSuccessNotification({ id: 'entityWasDeleted', values: { name: 'Entity'}, ...config ?? {}}),
		error: (config?: MessageConfig) => showApiOnErrorNotification(config)
	}

	return {
		showApiOnSuccessNotification,
		showApiOnErrorNotification,
		
		showOnCreateNotification,
		showOnDeleteNotification,
		
		showInfoNotification,
		showWarningNotification
	};
}