import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    clientGuid: string;
    contactGuid: string;
    isPrimaryContact: boolean;
}

function useUpdateClientContact() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.clientUrl}/api/1.0/clients/${data.clientGuid}/contact`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getClientByGuid'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useUpdateClientContact};