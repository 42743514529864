import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

interface IShowAttachmentProps {
  id: number;
}

function useShowAttachment() {
    const context = React.useContext(Context);

    const { mutate: show, isLoading, isSuccess } = useMutation(({ id }: IShowAttachmentProps) =>
        axios.put(`${context?.config?.annotationsUrl}/api/1.0/attachments/${id}/show`, null, commonHeaders()),
        {
            onSuccess: () => { queryClient.invalidateQueries('getAttachments'); queryClient.invalidateQueries('getDisplayDocuments') },
        }
    );

    return { show, isLoading, isSuccess };
}

export { useShowAttachment };