import {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import {Badge} from "./Badge";

interface Props {
    item: { isDeleted: boolean };
}

export const ItemDeletedStatus: FunctionComponent<Props> = ({item}: Props) => {
    return (
        <Badge type={item.isDeleted ? 'danger' : 'success'} fullWidth>
            {item.isDeleted ? <FormattedMessage id='deleted'/> : <FormattedMessage id='active'/>}
        </Badge>

    );
};
