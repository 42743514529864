import {FunctionComponent} from 'react';
import {Card, ControlledDropzone} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Image, ImageFit, Stack, useTheme} from '@fluentui/react';
import {useIntl} from "react-intl";
import {Control} from "react-hook-form/dist/types/form";
import {IChangeEmployeeCommand} from '..';

type DigitalSignatureTabProps = {
    control: Control<IChangeEmployeeCommand>;
    signatureImage: string;
}
export const DigitalSignatureTab: FunctionComponent<DigitalSignatureTabProps> = (props: DigitalSignatureTabProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const styles = ({root: {border: '1px solid ' + theme.palette.neutralSecondary}});

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack tokens={{childrenGap: 16}} wrap styles={{root: {width: '50%'}}}>
                <Image imageFit={ImageFit.contain}
                       src={`data:image/jpeg;base64,${props.signatureImage}`}
                       styles={styles}/>
                <ControlledDropzone preview label={formatMessage({id: 'chooseFile'})} accept={"image/*"}
                                    name={'signature'}
                                    control={props.control}/>
            </Stack>
        </Card>
    );
};
