import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {ICreditNoteDetails} from "../inderfaces";

function useGetCreditNoteDetails(clientIdentifier: string, creditNoteId: number) {
    const context = useContext(Context);
    const {
        data: creditNoteDetails,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['getCreditNoteDetails', clientIdentifier, creditNoteId],
        () => axios.get<ICreditNoteDetails>(`${context?.config?.billingUrl}/api/1.0/creditNote/Get/${clientIdentifier}/${creditNoteId}/details`, {
            ...commonHeaders()
        })
            .then(resp => resp.data),
        {
            enabled: !!context?.config?.billingUrl
        }
    );

    return {creditNoteDetails, isLoading, isFetching, refetch};
}

export {useGetCreditNoteDetails};