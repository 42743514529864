import {useIntl} from "react-intl";
import {useCallback} from "react";
import {
    DashboardContainer,
    DashboardTableTypeEnum, DashboardTabsEnum, GetDashboardConfig,
    IDashboardConfig,
    IPartnerAPWidgetData, IPartnerWPWidgetData,
    WidgetsEnum
} from "../../../../shared/Dashboard";

export const PartnerWorkpapersDashboard = ()=>{
    const {formatMessage} = useIntl();

    const getPartnerDashboardConfig = useCallback(
        (props: IPartnerWPWidgetData): IDashboardConfig => {
            return {
                id: 'partner',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'jobsReadyForReview',
                            tables: [{tableType: DashboardTableTypeEnum.Partner_JobsReadyForReview}],
                            props: {
                                number: props.jobsReadyForReview,
                                title: formatMessage({id: 'jobsReadyForReview'}),
                                variant: 'tertiary',
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'reviewPointsToBeCleared',
                            size: 3,
                            tables: [{tableType: DashboardTableTypeEnum.Partner_WP_ReviewPointsToBeCleared}],
                            props: {
                                title: formatMessage({id: 'reviewPointsToBeCleared'}),
                                data: props.reviewPointsToBeCleared,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'activeClients',
                            tables: [{tableType: DashboardTableTypeEnum.Partner_WP_ActiveClients}],
                            props: {
                                number: props.activeClients,
                                title: formatMessage({id: 'activeClients'}),
                                variant: 'tertiary',
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'workpapersFinalisedThisWeek',
                            tables: [{tableType: DashboardTableTypeEnum.Partner_WorkpapersFinalisedThisWeek}],
                            props: {
                                number: props.workpapersFinalisedThisWeek,
                                title: formatMessage({id: 'workpapersFinalisedThisWeek'}),
                                counterAlign: 'center',
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Partner_AllYearEndPrepInProgress,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return <DashboardContainer dashboardTab={DashboardTabsEnum.WP_Partner}
                               getDashboardConfig={getPartnerDashboardConfig as GetDashboardConfig}/>;
}