import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import {ISectionInfo} from "../../../interfaces";

interface IGetSectionByIdParams {
  sectionId: number;
  jobTemplateId: number;
  jobId: number;
  includeSectionAnswer?: boolean;
}

function useGetSectionById(params: IGetSectionByIdParams) {
    const context = React.useContext(Context);

    const { data: section, isLoading: isSectionLoading, refetch, isRefetching } = useQuery(
        ['getSectionById', params],
        () => axios.get<ISectionInfo>(`${context?.config?.tabsUrl}/api/1.0/Tabs/sectionById`, {
            ...commonHeaders(),
            params
        })
            .then(resp => resp.data ?? null),
        {
            enabled: !!context?.config?.tabsUrl && !!context.tenantId
        }
    );

    return { section, isSectionLoading, refetch, isRefetching };
}

export { useGetSectionById };