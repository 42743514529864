import * as React from 'react';
import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {MessageBarType} from '@fluentui/react';
import {takeUntil} from 'rxjs';
import {useNavigate} from 'react-router-dom';
import {ICreateRulesetCommand} from 'interfaces';
import {RouterPaths} from 'navigation';
import {Modal} from 'components';
import {useIsDestroy} from 'utils';
import {RulesetNewForm} from './RulesetNewForm';
import {useRulesetsStore} from '../RulesetsPage';
import {useNotifications} from "../../../../components/notifications";

interface Props {
    isModalOpen: boolean;
    toggleIsModalOpen: () => void;
}

export const RulesetsNew: FunctionComponent<Props> = observer(({isModalOpen, toggleIsModalOpen}: Props) => {
    const {formatMessage} = useIntl();
    const {createRuleset} = useRulesetsStore();
    const {showNotification} = useNotifications();
    const navigate = useNavigate();
    const isDestroy = useIsDestroy();

    const onSubmit = (data: ICreateRulesetCommand) => {
        createRuleset(data)
            .pipe(takeUntil(isDestroy))
            .subscribe(
                (ruleset) => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successRulesetCreateMessage'}),
                    });
                    navigate(RouterPaths.admin.rulesets.ruleset.root(ruleset.id));
                },
                (error) => {
                    const nameExist = error?.response?.data?.code === 125020;
                    showNotification({
                        name: formatMessage({id: 'error'}),
                        type: MessageBarType.error,
                        description: formatMessage({id: nameExist ? 'rulesetNameAlreadyExist' : 'somethingWentWrong'}),
                    });
                }
            );
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onDismiss={toggleIsModalOpen}
            width={'30vw'}
            minWidth={'30vw'}
            title={formatMessage({id: 'addNewRuleset'})}>
            <RulesetNewForm onDismiss={toggleIsModalOpen} onSubmit={onSubmit}/>
        </Modal>
    );
});
