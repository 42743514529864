import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";

function useGetOmlItems(jobId: number) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getCPJobOmlItems', jobId], () => axios.get<any>(`${context?.config?.queriesUrl}/api/1.0/queries/get/${jobId}?isPublished=true`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.queriesUrl && !!jobId
    });

    return {response, isLoading, isFetching};
}

export {useGetOmlItems};