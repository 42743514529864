import {Card, ControlledDatePicker} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, useImperativeHandle} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";
import {useUpdateJobContraventionInfo} from "../../../hooks";

interface IUpdateJobContraventionInfoCommand {
    contraventionEventReadyForReview?: Date;
    mgrContraventionEventReviewed?: Date;
    contraventionSent?: Date;
    pnrContraventionEventReviewed?: Date;
    contraventionLodged?: Date;
}

export const ContraventionTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {control, handleSubmit} = useForm<IUpdateJobContraventionInfoCommand>({
        ...DefaultFormSettings, defaultValues: {
            contraventionEventReadyForReview: props.job?.contravention?.eventReadyForReview,
            contraventionLodged: props.job?.contravention?.lodged,
            contraventionSent: props.job?.contravention?.sent,
            mgrContraventionEventReviewed: props.job?.contravention?.managerEventReviewed,
            pnrContraventionEventReviewed: props.job?.contravention?.partnerEventReviewed
        }
    });
    const {updateJobContraventionInfo, isJobContraventionInfoUpdating} = useUpdateJobContraventionInfo();
    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: IUpdateJobContraventionInfoCommand) => {
        updateJobContraventionInfo({
            jobGuid: props.job?.guid || '',
            contraventionLodged: props.job?.contravention?.lodged,
            contraventionSent: props.job?.contravention?.sent,
            mgrContraventionEventReviewed: props.job?.contravention?.managerEventReviewed,
            pnrContraventionEventReviewed: props.job?.contravention?.partnerEventReviewed,
            contraventionEventReadyForReview: props.job?.contravention?.eventReadyForReview
        }, {
            onSuccess: () => {
                props.onSubmitted();
            }
        });
    };

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionEventReadyForReview'
                        label={formatMessage({id: 'contraventionEventReadyForReview'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='mgrContraventionEventReviewed'
                        label={formatMessage({id: 'mgrContraventionEventReviewed'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionSent'
                        label={formatMessage({id: 'contraventionSent'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='pnrContraventionEventReviewed'
                        label={formatMessage({id: 'pnrContraventionEventReviewed'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='contraventionLodged'
                        label={formatMessage({id: 'contraventionLodged'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});