import React, {forwardRef, FunctionComponent, useRef} from 'react';
import {ProceduresContent, ReportContent, Section, useProcedureContext} from 'pages/JobPortal/components';
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {ISectionInfo} from "../../../../interfaces";
import {AuditReadySummaryReportContent} from "./AuditReadySummaryReportContent";
import {Stack} from "@fluentui/react";

interface Props {
    section: ISectionInfo;
}

export const AuditReadySummarySubSection = forwardRef(({section}: Props, ref) => {

    return (
        <Stack tokens={{childrenGap: 16}}>
            <ProceduresContent section={section} ref={ref}/>
            <AuditReadySummaryReportContent section={section} ref={ref}/>
        </Stack>
    );
});