import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {
    ColumnActionsMode,
    DialogType,
    IconButton,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme
} from "@fluentui/react";
import {useIntl} from 'react-intl';
import {
    Attachment,
    JobComment,
    PermanentCheckBox,
    ProceduresDataTable,
    useProcedureContext, useSectionContext
} from "pages/JobPortal/components";
import {ControlledTextField, DialogMessage, SanitizedText} from 'components';
import {TableType} from "enums";
import {useCreateFileNote, useDeleteFileNote, useTogglePermFileNote, useUpdateFileNote} from "../../../hooks";
import {useBoolean} from "@fluentui/react-hooks";
import {Control, useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "constants/forms";
import {FullWidth} from 'constants/styles';
import {useJobContext} from "../../../JobPortalLayoutPage";
import {ColumnWithSize} from "../../../interfaces";
import { AxiosError } from "axios";

interface IFileNote {
    id: number;
    jobId: number;
    hasAttachments: boolean;
    hasComments: boolean;
    checked: boolean;
    note: string;
    initials: string;
    tableType: TableType;
}

export const FileNotesProcedureTemplate = forwardRef((props, ref) => {
    const {items, refresh, isLoading} = useProcedureContext();
    const {jobId} = useJobContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const childRef = useRef<any>(ref);
    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({id: 'removingFileNoteDialogTitle'}),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({id: 'removingFileNoteDialogMessage'}),
    };

    const {deleteItem, isDeleting} = useDeleteFileNote();
    const {update: updatePermState, isUpdating: isUpdatingPermState} = useTogglePermFileNote();
    const [updatePermanentIndex, setUpdatePermanentIndex] = useState<number | null>(null);
    const {update, isUpdating} = useUpdateFileNote();
    const {create, isCreating} = useCreateFileNote();

    const [fileNotes, setFileNotes] = useState<IFileNote[]>(items);
    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);
    const [showDeleteDialog, {toggle: toggleDeleteDialog}] = useBoolean(false);
    const {control, setValue, handleSubmit, getValues} = useForm<{notes: IFileNote[]}>(DefaultFormSettings);
    const {fields, append, remove} = useFieldArray({
        name: 'notes',
        control: control
    });

    const switchToEditMode = (index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    };

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    const deleteNote = useCallback((index: number) => {
        const noteId = getValues(`notes.${index}.id`);
        setLastUpdatedIndex(index);

        deleteItem(
            {
                id: noteId,
            },
            {
                onSuccess: () => {
                    setFileNotes(fileNotes.splice(index, 1));
                    remove(index);
                    setEditingIndex(undefined);
                },
            }
        );
    }, [getValues, remove, fileNotes]);

    const changePermState = useCallback((id: number, isPerm: boolean, index: number) => {
        const note = getValues(`notes.${index}`);
        setUpdatePermanentIndex(prevState => id)
        update({
            id: note!.id,
            jobId: jobId,
            initials: note.initials,
            note: note.note,
            checked: isPerm
        }, {
            onSettled: () => {
                setUpdatePermanentIndex((prevState) => null);
            }
        });
    }, [fileNotes]);

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const note = getValues(`notes.${editingIndex}`);
        const item = fileNotes.find((x) => x.id === note.id);

        if (
            (note.note === item?.note || (!note.note && !item?.note)) &&
            (note.initials === item?.initials || (!note.initials && !item?.initials))
        ) {
            return;
        }

        setLastUpdatedIndex(editingIndex);

        update(
            {
                id: note!.id,
                jobId: jobId,
                initials: note.initials,
                note: note.note,
                checked: note.checked
            },
            {
                onSuccess: () => {
                    setFileNotes([...fileNotes.slice(0, editingIndex), { ...note }, ...fileNotes.slice(editingIndex + 1)]);
                },
                onError: () => {
                    refresh?.()
                }
            }
        );
    }, [editingIndex, getValues, fileNotes]);

    useEffect(() => {
        setFileNotes(items);
        setValue('notes', items || []);
    }, [items]);
    
    const columns: ColumnWithSize[] = [
        {
            key: 'annotations',
            name: formatMessage({ id: 'actions' }),
            fieldName: 'annotations',
            onRender: (item: IFileNote, index?: number) => {
                const id = getValues(`notes.${index!}.id`);
                return (
                    <Stack horizontal>
                        <Attachment itemId={id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={id} hasComments={item?.hasComments} />
                    </Stack>
                );
            },
            minWidth: 70,
            maxWidth: 70,
        },
        {
            key: 'notes',
            name: formatMessage({ id: 'notes' }),
            minWidth: 300,
            fieldName: 'note',
            onRender: (item: IFileNote, index, column) => (
                <FileNoteCell
                    onClick={() => {
                        if (index !== undefined && !isInEditMode(index, column?.name)) {
                            handleOnAccept();
                            switchToEditMode(index, column?.name);
                        }
                    }}
                    editMode={index !== undefined && isInEditMode(index, column?.name)}
                    index={index}
                    fieldName={'note'}
                    control={control}
                    disabled={isUpdating}
                    label={index !== undefined ? getValues(`notes.${index}.note`) : ''}
                />
            ),
        },
        {
            key: 'initials',
            name: formatMessage({ id: 'initials' }),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'initials',
            onRender: (item: IFileNote, index, column) => (
                <FileNoteCell
                    onClick={() => {
                        if (index !== undefined && !isInEditMode(index, column?.name)) {
                            handleOnAccept();
                            switchToEditMode(index, column?.name);
                        }
                    }}
                    editMode={index !== undefined && isInEditMode(index, column?.name)}
                    index={index}
                    fieldName={'initials'}
                    control={control}
                    disabled={isUpdating}
                    label={index !== undefined ? getValues(`notes.${index}.initials`) : ''}
                />
            ),
        },
        {
            key: 'permanent',
            name: formatMessage({ id: 'permanent' }),
            fieldName: 'permanent',
            onRender: (item: IFileNote, index?: number) => {
                const id = getValues(`notes.${index!}.id`);
                return (
                    <Stack verticalAlign='center' horizontalAlign='center' grow={1}>
                        {updatePermanentIndex === id && <Spinner size={SpinnerSize.small} />}
                        {updatePermanentIndex !== id && (
                            <PermanentCheckBox
                                checked={item.checked}
                                disabled={isUpdatingPermState || isUpdating || isDeleting}
                                onUpdate={(newValue) => {
                                    changePermState(id, newValue, index!);
                                    item.checked = !item.checked
                                }}
                                tableType={item.tableType}
                                itemId={id}
                            />
                        )}
                    </Stack>
                );
            },
            minWidth: 70,
            maxWidth: 70,
        },
        {
            key: 'actions',
            name: '',
            fieldName: '',
            onRender: (item: IFileNote, index) => (
                <Stack grow={1} horizontal verticalAlign={'center'} horizontalAlign={'center'}>
                    {(isDeleting || isUpdating) && lastUpdatedIndex === index && (
                        <Stack.Item>
                            <Spinner size={SpinnerSize.small} />
                        </Stack.Item>
                    )}
                    {!isDeleting && index !== editingIndex && !showDeleteDialog && (
                        <Stack.Item>
                            <IconButton
                                onClick={() => {
                                    setEditingIndex(index);
                                    toggleDeleteDialog();
                                }}
                                iconProps={{ iconName: 'delete' }}
                                styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                            />
                        </Stack.Item>
                    )}
                </Stack>
            ),
            minWidth: 70,
            maxWidth: 70,
        },
    ];

    useImperativeHandle(ref, () => ({
        onAddClick() {
            create({
                jobId: jobId
            }, {
                onSuccess: (data) => {
                    setFileNotes([...fileNotes, data.data]);
                    append(data.data);
                }
            });
        }
    }), [fileNotes]);


    useEffect(() => {
        setFileNotes(items);
    }, [items]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('FileNotes')) return;

            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={childRef}>
            <ProceduresDataTable items={fields} isLoading={isLoading || isCreating} columns={columns} />
            <DialogMessage
                onClick={() => {
                    if (editingIndex !== undefined) {
                        toggleDeleteDialog();
                        deleteNote(editingIndex);
                    }
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setEditingIndex(undefined);
                }}
            />
        </div>
    );
});


type FileNoteCellProps = {
    editMode: boolean;
    label: string | null;
    control: Control<{notes: IFileNote[]}>;
    fieldName: "initials" | "note";
    onClick: () => void;
    disabled?: boolean;
    index?: number;
}
const FileNoteCell = (props: FileNoteCellProps) => {
    const theme = useTheme();
    
    const classNames = mergeStyleSets({
        textFieldDisabled: {
            'input[disabled].ms-TextField-field': {
                color: 'inherit'
            }
        }
    })
    
    return (
        <Stack
            horizontal
            verticalAlign={'center'}
            onClick={props.onClick}
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: '100%', width: '100%' } }}>
            {!props.editMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100
                        }}
                        onClick={(event) => {
                            props.onClick();
                            event.preventDefault();
                        }}></div>
                    <TextField styles={FullWidth} className={classNames.textFieldDisabled} disabled value={props.label || ''} />
                </>
            )}
            {props.editMode && props.index !== undefined && (
                <ControlledTextField
                    id={`FileNotes-${props.index}-${props.fieldName}`}
                    autoFocus
                    control={props.control}
                    name={`notes.${props.index}.${props.fieldName}`}
                    disabled={props.disabled}
                    styles={FullWidth}
                />
            )}
        </Stack>
    );
};