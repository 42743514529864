import {commonHeaders, Context} from "utils";
import {useContext} from "react";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {TeamInfo} from "../../shared";

function useGetTeamInfo(teamId: number) {
    const context = useContext(Context);

    const {data: response, isFetching: isLoading} = useQuery(
        ['getTeamById', teamId],
        () => axios.get<TeamInfo>(`${context?.config?.userUrl}/api/1.0/teams/${teamId || ''}`, commonHeaders()),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId && !!teamId,
          
        }
    );

    return {response, isLoading};
}

export {useGetTeamInfo};