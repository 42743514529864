import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Control } from 'react-hook-form/dist/types/form';
import { ControlledRichTextField, ControlledTextField } from 'components';
import { EmailTemplatePanel } from './EmailTemplatePanel';
import {EmailTemplate} from "../../hooks/Email/useEmailTemplateGet";

interface Props {
    control: Control<EmailTemplate>;
}

export const SummarisedWorkflowReportEmail: FunctionComponent<Props> = ({ control }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <EmailTemplatePanel header={<FormattedMessage id='summarisedWorkflowReportEmail' />}>
            <>
                <ControlledTextField
                    name='summarisedWorkflowReportEmailSubject'
                    control={control}
                    label={formatMessage({ id: 'summarisedWorkflowReportEmailSubject' })}
                />

                <ControlledRichTextField
                    name='summarisedWorkflowReportEmailBody'
                    control={control}
                    label={formatMessage({ id: 'summarisedWorkflowReportEmailBody' })}
                />
            </>
        </EmailTemplatePanel>
    );
};
