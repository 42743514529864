import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import {observer} from 'mobx-react';
import {MessageBarType, Stack} from '@fluentui/react';
import {useForm} from 'react-hook-form';
import {map, switchMap, takeUntil} from 'rxjs';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {AxiosError} from 'axios';
import {RouterPaths} from 'navigation';
import {ICreateRuleCommand} from 'interfaces';
import {useIsDestroy} from 'utils';
import {DefaultFormSettings} from 'constants/forms';
import {useRulesetStore} from '../RulesetLayoutPage';
import {RulesetRuleEditForm} from './components/RulesetRuleEditForm';
import {useNotifications} from "../../../../components/notifications";

export const RulesetRulePage: FunctionComponent = observer(() => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const {showNotification} = useNotifications();
    const {ruleset, rule, addRule, editRule, loadRulesetObservable} = useRulesetStore();
    const {control, handleSubmit, formState, setValue, reset} = useForm<ICreateRuleCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            name: rule?.ruleName || '',
            expression: rule?.expression || '',
            executionOrder: rule?.executionOrder,
            onSuccess: rule?.onSuccess || '',
            onFailed: rule?.onFailed || '',
        },
    });
    const navigate = useNavigate();
    const isDestroy = useIsDestroy();
    const {formatMessage} = useIntl();

    useEffect(() => {
        if (rule) {
            setValue('name', rule.ruleName);
            setValue('expression', rule.expression!);
            setValue('executionOrder', rule.executionOrder);
            setValue('onSuccess', rule.onSuccess!);
            setValue('onFailed', rule.onFailed!);
        }
    }, [rule, setValue]);

    const onSubmit = (data: ICreateRuleCommand) => {
        setIsEditing(true);
        data.variables = rule?.localParams!;

        const ruleMethod = rule ? editRule.bind(this, rule.id, data) : addRule.bind(this, data);
        ruleMethod()
            .pipe(
                takeUntil(isDestroy),
                switchMap((createdRule) => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: rule ? 'successRuleUpdateMessage' : 'successRuleCreateMessage'}),
                    });

                    return loadRulesetObservable().pipe(
                        takeUntil(isDestroy),
                        map(() => createdRule?.id ?? rule?.id)
                    );
                })
            )
            .subscribe({
                next: (ruleId: number) => {
                    navigate(RouterPaths.admin.rulesets.ruleset.rules.rule(ruleset!.id, ruleId), {replace: true});
                    reset();
                },
                error: (error: AxiosError) => {
                    if (error.response?.status === 400) {
                        showNotification({
                            name: formatMessage({id: 'error'}),
                            type: MessageBarType.error,
                            description: error.response?.data?.messages?.join(', ') ?? formatMessage({id: 'errorDefaultMessage'}),
                        });
                    }
                },
            })
            .add(() => setIsEditing(false));
    };

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <RulesetRuleEditForm isEditing={isEditing} control={control} onSubmit={handleSubmit(onSubmit)}
                                     formState={formState}/>
            </Stack.Item>
        </Stack>
    );
});
