import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

type CalcMateriality = {
    materialityLevelValue: string;
    clearlyTrivialValue: string;
};

export const useGetMaterialityCalculator = () => {
    const context = React.useContext(Context);
    const { data: calcMateriality, isLoading: getMaterialityCalculatorLoading } = useQuery(['getMaterialityCalculator'], () =>
        axios.get<CalcMateriality>(`${context?.config?.settingsUrl}/api/1.0/Settings/materialityCalcSettings`, commonHeaders())
    );

    return { calcMateriality, getMaterialityCalculatorLoading };
};
