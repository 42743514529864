import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';
import { FunctionComponent } from 'react';

export const AuditReadyConnectionsLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'auditReadyConnections',
                key: RouterPaths.portalSettings.auditReadyConnections,
                text: formatMessage({ id: 'auditReadyConnections' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
