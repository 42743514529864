import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";


type MutateDataType = {
    clientId: number;
    contactId: number;
    email?: string;
    directPhone?: string;
    mobilePhone?: string;
}

function useUpdateContactDetailsInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    
    const {mutate: updateClientContactDetails, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) => axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/contact/${data.contactId}/details`, data, commonHeaders()),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(['getClientContactById', variables.contactId, variables.clientId]);

                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Client contact details'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateClientContactDetails, isUpdating};
}

export {useUpdateContactDetailsInfo};