import { FunctionComponent } from 'react';
import { DefaultButton, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { ControlledTextField, Modal } from 'components';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';

interface IDeleteAllImportsProps {
    onDelete: Function;
    isLoading: boolean;
}

interface IDeleteAllModalContentProps extends IDeleteAllImportsProps {
    onCancel: Function;
}

export const DeleteAllImports: FunctionComponent<IDeleteAllImportsProps> = ({ onDelete, isLoading }) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    return (
        <Stack>
            <DefaultButton
                styles={{
                    root: { height: 32 },
                    icon: { color: theme.palette.red },
                    iconHovered: { color: theme.palette.red },
                }}
                iconProps={{ iconName: 'Delete' }}
                text={formatMessage({ id: 'removeAllData' })}
                onClick={toggleOpenModal}
                disabled={isLoading}
            />
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} width={400} minWidth={400}>
                <DeleteAllModalContent
                    onDelete={() => {
                        toggleOpenModal();
                        onDelete();
                    }}
                    onCancel={toggleOpenModal}
                    isLoading={isLoading}
                />
            </Modal>
        </Stack>
    );
};

const DeleteAllModalContent: FunctionComponent<IDeleteAllModalContentProps> = ({ isLoading, onDelete, onCancel }) => {
    const { formatMessage } = useIntl();
    const { control, formState } = useForm<{ removeAll: string }>({
        ...DefaultFormSettings,
        defaultValues: { removeAll: '' },
    });
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Text>
                {formatMessage({ id: 'toRemoveAllTypeTo' }) + ' '}
                <strong style={{ textTransform: 'uppercase' }}>{formatMessage({ id: 'remove' })}</strong>.
            </Text>
            <ControlledTextField
                name='removeAll'
                control={control}
                rules={{
                    required: formatMessage({ id: 'requiredField' }),
                    pattern: { value: /^remove$/i, message: formatMessage({ id: 'patternCorrectWord' }) },
                }}
            />
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                <DefaultButton text={formatMessage({ id: 'cancel' })} disabled={isLoading} onClick={() => onCancel()} />
                <PrimaryButton
                    text={formatMessage({ id: 'remove' })}
                    disabled={isLoading || !formState.isValid}
                    onClick={() => onDelete()}
                />
            </Stack>
        </Stack>
    );
};
