import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Label, Link, Stack, useTheme} from '@fluentui/react';
import {Card, ControlledDatePicker, ControlledTextField, ModalWithForm} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {JobInfo} from "pages/WorkflowPortal/shared";
import {useGetAllEmployees} from "pages/WorkflowPortal/Employees";
import {useCreateTimesheetInfo, useUpdateTimesheetInfo} from "../../hooks";
import {useGetTimesheetById} from "../../hooks/useGetTimesheetById";
import {useWorkflows} from "../../../WorkflowLayoutPage";
import { useAuthContext } from "../../../../../providers";

interface IUpdateTimesheetCommand {
    timesheetDate: Date;
    durationMin: number;
}


interface Props {
    type: "Create" | "Update";
    timesheetId?: number;
    job?: JobInfo;
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export const TimesheetModal: FunctionComponent<Props> = observer(({
                                                                      onDismiss,
                                                                      isOpen,
                                                                      title,
                                                                      timesheetId,
                                                                      job,
                                                                      type
                                                                  }: Props) => {
    const {showJobModal, showClientModal, showEmployeeModal, showFundModal} = useWorkflows();

    const {timesheet, isTimesheetLoading} = useGetTimesheetById(timesheetId);

    const {control, handleSubmit, setValue, reset, formState} = useForm<IUpdateTimesheetCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            timesheetDate: undefined,
            durationMin: undefined,
        },
    });


    const { user } = useAuthContext();
    const {allEmployees, isAllEmployeesLoading} = useGetAllEmployees();
    const {updateTimesheet, isUpdating} = useUpdateTimesheetInfo();
    const {createTimesheet, isUpdating: isCreating} = useCreateTimesheetInfo();

    const theme = useTheme();
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const isFormUpdating = useMemo(() => {
        return isUpdating || isCreating;
    }, [isCreating, isUpdating]);

    const onSubmit = (data: IUpdateTimesheetCommand) => {
        type === "Create" ? createTimesheet({
                employeeLocalId: employee?.id!,
                timesheetDate: data.timesheetDate,
                durationMin: data.durationMin,
                jobId: job?.id!,
                fundId: job?.fund.id!,
                clientId: job?.client.id!,
                rate: employee?.rate!
            }) :
            updateTimesheet({
                timesheetDate: data.timesheetDate,
                durationMin: data.durationMin,
                timesheetId: timesheetId!,
            });
        onClose();
    };
    const onClose = () => {
        onDismiss();
        reset();
    };

    const employee = useMemo(() => {
        if (allEmployees && user) {

            return allEmployees.data.items.find(x => x.id === user.employeeId);
        }
    }, [allEmployees, user]);


    useEffect(() => {
        if (timesheet?.data) {
            setValue('durationMin', timesheet?.data.durationMin);
            setValue('timesheetDate', timesheet?.data.timesheetDate);
        }

    }, [timesheet?.data, setValue]);


    return (
        <ModalWithForm isSubmitting={isFormUpdating}
                       submitDisabled={!formState.isDirty || isFormUpdating}
                       onSubmit={handleSubmit(onSubmit)}
                       isOpen={isOpen}
                       isLoading={isTimesheetLoading}
                       onDismiss={onClose} title={title}>
            <Stack tokens={{childrenGap: 16}}>
                <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDatePicker
                                name='timesheetDate'
                                required
                                label={formatMessage({ id: 'date' })}
                                control={control}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='durationMin'
                                required
                                label={formatMessage({id: 'durationMin'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: 'job'})}</Label>
                            <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                                onClick={() => showJobModal(type === "Create" ? job?.id! : timesheet?.data.job.id!)}>{type === "Create" ? job?.year : timesheet?.data?.job?.year} Audit</Link>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: 'employee'})}</Label>
                            <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                                  disabled={type === "Create" ? isAllEmployeesLoading : false}
                                  onClick={() => showEmployeeModal(type === "Create" ? employee?.id! : timesheet?.data.employee?.id!)}>
                                {type === "Create" ? employee?.firstName : timesheet?.data.employee?.firstName} {type === "Create" ? employee?.lastName : timesheet?.data?.employee?.lastName}
                            </Link>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: 'fundName'})}</Label>
                            <Link underline style={{ color: theme.schemes?.default?.palette.blue }} onClick={() => {
                                const data = type === "Create" ? {
                                    fundId: job?.fund.id!,
                                    clientId: job?.client.id!,
                                    fundGuid: job?.fund.guid!,
                                    clientGuid: job?.client.guid!
                                } : {
                                    fundId: timesheet?.data.fund?.id!,
                                    clientId: timesheet?.data.client.id!,
                                    fundGuid: timesheet?.data.fund?.guid!,
                                    clientGuid: timesheet?.data.client.guid!
                                };

                                showFundModal(data.fundId, data.clientId, data.fundGuid, data.clientGuid);
                            }}>
                                {type === "Create" ? job?.fund.name : timesheet?.data.fund?.name}
                            </Link>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: 'client'})}</Label>
                            <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                                onClick={() => showClientModal(type === "Create" ? job?.client.guid || '' : timesheet?.data.client?.guid || '')}>
                                {type === "Create" ? job?.client.name : timesheet?.data.client?.name}
                            </Link>
                        </Stack.Item>
                    </Stack>
                </Card>
            </Stack>
        </ModalWithForm>
    );
});
