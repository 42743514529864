import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {InitialAuditQueryItem} from "./interfaces";
import {IconButton, mergeStyleSets, Spinner, SpinnerSize, useTheme} from "@fluentui/react";
import {useCopyToAcl} from "./hooks/useCopyToAcl";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useSectionContext} from "../../../Section";
import {useGetAclSectionAnswer} from "./hooks/useGetAclSectionAnswer";

type AuditQueryCopyButtonProps = {
    query: InitialAuditQueryItem
}

export const AuditQueryCopyButton: FunctionComponent<AuditQueryCopyButtonProps> = ({ query, ...props }: AuditQueryCopyButtonProps) => {
    const theme = useTheme();
    
    const {section} = useSectionContext();
    const {jobId, jobTabs} = useJobContext();
    
    const {approval: aclSectionAnswer, isLoading} = useGetAclSectionAnswer();
    const {copyToAcl, isCopying} = useCopyToAcl(jobId);
    
    const [isRef, setIsRef] = useState<boolean>(query.isRef);
    const [isDisabled, setIsDisabled] = useState<boolean>(section.sectionAnswer?.partnerApproval ?? false);

    const onCopyToAclClick = useCallback(() => {
        if (isRef) {
            return;
        }
        
        copyToAcl({
            text: query.text ?? '',
            isNonFundamental: query.isNonFundamental,
            refId: query.id
        }, {
            onSuccess: (result) => {
                setIsRef(prev => true);
            }
        })
    }, [query])

    useEffect(() => {
        setIsDisabled(prev => !!aclSectionAnswer?.partnerApproval || !section.sectionAnswer?.partnerApproval)
    }, [section.sectionAnswer?.partnerApproval, aclSectionAnswer?.partnerApproval]);
    
    const classNames = mergeStyleSets({
        icon: {
            color: isRef ? theme.palette.red : theme.palette.themePrimary,
            ':hover': {
                color: isRef ? theme.palette.red : theme.palette.themePrimary,
            }
        }
    })
    
    return (
        <>
            {isCopying 
            ? <Spinner size={SpinnerSize.small}></Spinner>
            : <IconButton iconProps={{iconName: 'Copy' }}
                          className={classNames.icon}
                          disabled={isDisabled}
                          onClick={() => onCopyToAclClick()}
                />}
        </>
    )
}