import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { PrimaryButton, Spinner, Stack, TextField, useTheme } from '@fluentui/react';
import { Card } from 'components/Card';
import { Modal, ModalFooter } from 'components/Modal';
import { DefaultFormSettings } from 'constants/forms';
import { VerticalSubmenu } from 'components/VerticalSubmenu';
import { ContactTab } from '../ContactTab';
import { ControlledTextField } from 'components';
import { useCreateFund } from '../../hooks/useCreateFund';
import { useGetClientByGuid } from '../../hooks/useGetClientByGuid';
import { useGetFundByGuid } from '../../hooks/useGetFundByGuid';
import { useUpdateFund } from '../../hooks/useUpdateFund';

interface Props {
    open: boolean;
    toggle: () => void;
    title?: string;
    clientGuid: string;
    fundGuid: string;
    clientId: number;
}

interface IFundForm {
    contactId: number;
    contactGuid: string;
    firstName: string;
    lastName: string;
    email: string;
    businessPhone: string;
    fundName: string;
    trusteeName: string;
    abn: string;
}

interface IFundFormProps {
    clientGuid: string;
    fundGuid: string;
    clientId: number;
    close: () => void;
}

const FundModalForm: FunctionComponent<IFundFormProps> = ({ clientGuid, clientId, fundGuid, close }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { control, handleSubmit, watch, setValue, formState, getValues } = useForm<IFundForm>({
        ...DefaultFormSettings,
        defaultValues: {
            contactId: 0,
            contactGuid: '',
            firstName: '',
            lastName: '',
            email: '',
            businessPhone: '',
            fundName: '',
            trusteeName: '',
            abn: '',
        },
        reValidateMode: "onChange",
    });

    const [selectedTab, setSelectedTab] = useState<'contact' | 'fund'>('fund');

    const links = [
        {
            name: 'Fund',
            key: 'fund',
            url: '',
        },
        {
            name: 'Contact',
            key: 'contact',
            url: '',
        },
    ];

    const { currentClientData, isCurrentClientLoading } = useGetClientByGuid(clientGuid);
    const { currentFundData, isLoadingCurrentFundData } = useGetFundByGuid({clientGuid: clientGuid, fundGuid: fundGuid});
    const { updateFund, isLoading } = useUpdateFund();

    const onSubmit = (data: IFundForm) => {
        updateFund({
            abn: data.abn,
            contactId: data.contactGuid,
            clientId: clientGuid,
            fundId: fundGuid
        },
            {
                onSuccess: () => close()
            });
    }

    useEffect(() => {
        if (!!currentFundData?.data) {
            setValue('contactGuid', currentFundData.data.mainContactGuid);
            setValue('abn', currentFundData.data.abn);
        }
    }, [currentFundData?.data]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const contactGuid = watch('contactGuid');
    const abn = watch('abn');

    useEffect(() => {
        setIsButtonDisabled(isLoading || !getValues().abn || !getValues().contactGuid
            || (getValues().contactGuid === currentFundData?.data.mainContactGuid && getValues().abn === currentFundData?.data.abn));
    }, [isLoading, abn, contactGuid, currentFundData?.data]);

    if(isCurrentClientLoading || isLoadingCurrentFundData || isLoading){
        return <Spinner />;
    }

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card>
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <VerticalSubmenu setSelected={setSelectedTab} defaultValue={selectedTab} links={links} />
                    <Stack grow={1} tokens={{ childrenGap: 16 }} styles={{ root: { minHeight: '440px' } }}>
                        {selectedTab === 'fund' && (
                            <>
                                <Stack.Item>
                                    <TextField 
                                        disabled 
                                        name='fundName' 
                                        value={currentFundData?.data.name} 
                                        label={formatMessage({ id: 'fundName' })}
                                        theme={theme.schemes?.default} 
                                        styles={{root: {
                                            selectors: {
                                                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
                                            }
                                        },}}/>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextField
                                        theme={theme.schemes?.default}
                                        disabled
                                        name='trusteeName'
                                        value={currentFundData?.data.trustee}
                                        label={formatMessage({ id: 'trusteeName' })}
                                        styles={{root: {
                                            selectors: {
                                                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
                                            }
                                        },}}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <ControlledTextField name='abn' control={control} label={formatMessage({ id: 'abn' })} />
                                </Stack.Item>
                            </>
                        )}
                        {selectedTab === 'contact' && (
                            <ContactTab control={control} watch={watch} setValue={setValue} contacts={currentClientData?.data.contacts ?? []} primaryContact={currentClientData?.data.contacts.find(x => x.guid === currentFundData?.data.mainContactGuid)} />
                        )}
                    </Stack>
                </Stack>
            </Card>

            <ModalFooter horizontalAlign='end'>
                <PrimaryButton
                    disabled={isButtonDisabled}
                    onClick={handleSubmit((data) => onSubmit(data))}
                    text={formatMessage({ id: 'save' })}
                />
            </ModalFooter>
        </Stack>
        );
}

export const EditFundModal: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Modal title={props?.title || formatMessage({ id: 'editFund' })} isOpen={props.open} onDismiss={props.toggle}>
            <FundModalForm clientGuid={props.clientGuid} fundGuid={props.fundGuid} clientId={props.clientId} close={props.toggle} />
        </Modal>
    );
};
