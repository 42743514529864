import {Card} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Label, Stack, Text, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {ImportReport} from "../../../../../ImportReport/components";
import React, {ForwardedRef, forwardRef, useImperativeHandle} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";

export const AuditProgramTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const { formatMessage } = useIntl();
    const theme = useTheme();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};


    useImperativeHandle(ref, () => ({
        submitForm() {
            props.onSubmitted();
        }
    }));

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack tokens={{childrenGap: 48}}>
                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <Label theme={theme.schemes?.default}>{formatMessage({id: 'accSoftwareFundCode'})}</Label>
                            <Text theme={theme.schemes?.default}>
                                {String(props.job?.fundCode || '')}
                            </Text>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <Label theme={theme.schemes?.default}>{formatMessage({id: 'accSoftwareBusinessCode'})}</Label>
                            <Text theme={theme.schemes?.default}>
                                {String(props.job?.businessCode || '')}
                            </Text>
                        </Stack.Item>
                    </Stack>

                </Stack.Item>
                <Stack.Item styles={{root: {width: "100%"}}}>
                    <ImportReport guid={props.job?.guid}/>
                </Stack.Item>
            </Stack>

        </Card>
    );
});
