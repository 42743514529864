import {Dropdown, IDropdownOption, Label, Stack} from '@fluentui/react';
import {Pagination} from 'components';
import React, {useContext, useEffect, useState} from 'react';
import {IJobPaginationFilter, useGetJobsByFilter} from "./hooks";
import {IJobsSearch, JobARDateFilter, JobsAddItemModal, JobsDataTable} from "./components";
import {ExportType, useExportData} from "hooks";
import {Context} from "utils";
import {useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "constants/forms";
import {useGeneralYearGet} from 'pages/ClientPortal/PortalSettings/hooks/General/useGeneralYearGet';
import {DataTableFilter} from "../shared";
import {EntityStatus} from "pages/WorkflowPortal//shared";

type JobsWorkflowPageProps = {
    fundId?: number;
}
export const JobsWorkflowPage = (props: JobsWorkflowPageProps) => {
    const context = useContext(Context);
    const {formatMessage} = useIntl();

    const [filters, setFilters] = useState<IJobPaginationFilter>({
        pageSize: 10,
        page: 1,
        orderBy: 'Year',
        orderDirection: 'asc',
        status: EntityStatus.Active,
    });
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [jobYearOptions, setOptions] = useState<IDropdownOption[]>([]);

    const {generalYears, isLoading} = useGeneralYearGet();
    const {jobs, isJobLoading, isFetching} = useGetJobsByFilter(filters);
    const {exportData} = useExportData(`${context?.config?.jobUrl}/api/1.0/Job`, filters);

    const onPaginationChange = (pageSize: number, page: number) => {
        setFilters(prev => ({...prev, pageSize: pageSize, page: page}));
    };

    const onSearch = (newValue: string) => {
        setFilters(prev => ({...prev, fundName: newValue}));
    };

    const onChangeStatus = (newStatus: EntityStatus | null) => {
        setFilters(prev => ({...prev, status: newStatus}));
    };

    useEffect(() => {
        const options: IDropdownOption[] = generalYears?.data?.map((year) => ({
            key: year.year.toString(),
            text: year.year.toString()
        })) || Array.of<IDropdownOption>();
        const defaultOptions = [{
            key: '',
            text: 'All', selected: true
        } as IDropdownOption]
        setOptions(defaultOptions.concat(options));
    }, [generalYears?.data]);

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <DataTableFilter additionalFilter={true} onAddClick={() => setShowAddModal(true)}
                                 search={{
                                     onSearch: onSearch,
                                     onClear: () => onSearch('')
                                 }}
                                 status={{selectedStatus: filters.status || null, changeStatus: onChangeStatus}}
                                 export={{
                                     onPdfClick: () => exportData(ExportType.Pdf),
                                     onExcelClick: () => exportData(ExportType.Excel)
                                 }}
                >
                    <Stack horizontal tokens={{childrenGap: 32}} styles={{root: {width: '100%'}}}
                           horizontalAlign={"start"} verticalAlign={"center"}>
                        <Stack.Item>
                            <Stack horizontal tokens={{childrenGap: 16}}>
                                <Label>{formatMessage({id: 'year'})}:</Label>
                                <Dropdown
                                    styles={{root: {minWidth: 165}}}
                                    dropdownWidth={165}
                                    options={jobYearOptions}
                                    disabled={isLoading}
                                    onChange={(event, option) => setFilters(prev => ({
                                        ...prev,
                                        year: option?.key as number
                                    }))}
                                    placeholder={formatMessage({id: 'selectYear'})}
                                />
                            </Stack>
                        </Stack.Item>
                        <JobARDateFilter onFromSet={date => setFilters(prev => ({...prev, dateArSentFrom: date}))}
                                         onToSet={date => setFilters(prev => ({...prev, dateArSentTo: date}))}
                                         arDateTo={filters.dateArSentTo || undefined}
                                         arDateFrom={filters.dateArSentFrom || undefined}/>

                    </Stack>
                </DataTableFilter>
                <JobsAddItemModal
                    isOpen={showAddModal}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({id: 'addingNewItem'})}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <JobsDataTable isLoading={isJobLoading || isFetching} jobs={jobs?.data.items}
                               setSort={value => setFilters(prev => ({
                                   ...prev,
                                   orderBy: value.orderBy,
                                   orderDirection: value.orderDirection
                               }))}/>
            </Stack.Item>
            <Stack.Item shrink={0}>
                <Pagination
                    itemsCount={filters.page * (jobs?.data.shownItemsCount || 0)}
                    total={jobs?.data?.totalItemsCount || 0}
                    page={filters.page}
                    pageSize={filters.pageSize}
                    onChange={onPaginationChange}
                />
            </Stack.Item>
        </Stack>
    );
};