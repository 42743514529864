import { useState } from "react";
import { useGetWrapAccounts } from "./hooks/useGetWrapAccounts";
import { IColumn, StackItem } from "@fluentui/react";
import { useIntl } from "react-intl";
import { Card } from "reactstrap";
import { CardTableContainerStyles } from "constants/tableStylesPeset";
import { DataTable, SanitizedText } from "components";
import { cloneDeep } from "lodash";
import { useGetPdfWorkpapers } from "./hooks/useGetPdfWorkpapers";
import { Stack } from '@fluentui/react';
import { WrapAccountAttachments } from "./components/WrapAccountAttachments";
import { PdfWorkpaperAttachments } from "./components/PdfWorkpapersAttachments";
import { UploadWrapAccount } from "./components/UploadWrapAccount";

export const LibraryPage = () =>{
    const { wrapAccountsTableData, isLoading, isFetching, refetch } = useGetWrapAccounts();
    const { pdfWorkpapersTableData } = useGetPdfWorkpapers();
    const {formatMessage} = useIntl();
    const libraryTitle = formatMessage({id: 'library'});
    const pdfWorkpapersTitle = formatMessage({id: 'pdfWorkpapers'});

    const [libraryColumns] = useState<IColumn[]>([
        {
            key: 'year',
            name: formatMessage({id: 'year'}),
            minWidth: 200,
            fieldName: 'year',
        },
        {
            key: 'Attachments',
            name: formatMessage({id: 'attachments'}),
            minWidth: 100,
            maxWidth: 100,
            className: 'centred',
            onRender: (item) => (<Stack grow horizontalAlign={"center"}><WrapAccountAttachments item={item}/></Stack>),
        }
    ]);

    const [pdfWorkpapersColumns] = useState<IColumn[]>([
        {
            key: 'year',
            name: formatMessage({id: 'year'}),
            minWidth: 200,
            fieldName: 'year',
        },
        {
            key: 'Attachments',
            name: formatMessage({id: 'attachments'}),
            minWidth: 100,
            maxWidth: 100,
            className: 'centred',
            onRender: (item) => (<Stack grow horizontalAlign={"center"}><PdfWorkpaperAttachments item={item}/></Stack>),
        },
    ]);

    

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <SanitizedText data={libraryTitle}
            style={{ fontSize: '2em', fontWeight: 'bold' }}/>
            <UploadWrapAccount></UploadWrapAccount>
            <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={libraryColumns}
                items={cloneDeep(wrapAccountsTableData?.items || [])}
                enableShimmer={isLoading}
                containerHeight='calc(100% - 32px)'
                disableDragDrop={true}
            />
            </Card>
            <StackItem>
                <SanitizedText data={pdfWorkpapersTitle}
                style={{ fontSize: '2em', fontWeight: 'bold' }}/>
            </StackItem>
            <Card styles={CardTableContainerStyles}>
            <DataTable
                initialColumns={pdfWorkpapersColumns}
                items={cloneDeep(pdfWorkpapersTableData?.items || [])}
                enableShimmer={isLoading}
                containerHeight='calc(100% - 32px)'
                disableDragDrop={true}
            />
            </Card>
        </Stack>
    )
}