import Editor from '@monaco-editor/react';
import {useRef} from 'react';
import {Controller, Path} from 'react-hook-form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {Control} from 'react-hook-form/dist/types/form';
import * as monaco from 'monaco-editor';
import {Label, mergeStyleSets, Text, useTheme} from '@fluentui/react';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    height?: number;
}

export function ControlledMonacoEditor<FormValues>({ name, control, rules, label, height }: Props<FormValues>) {
    const theme = useTheme();
    const monacoRef = useRef<monaco.editor.IStandaloneCodeEditor>(null);

    const styles = mergeStyleSets({
        root: {
            selectors: {
                '.monaco-editor': {
                    border: '1px solid ' + theme.semanticColors.inputBorder,
                    borderRadius: 2,
                },
                // THIS STYLES NEED IF WE WANT COLORS FOR MONACO EDITOR FROM COLORS PALLETE IN APP
                // '.mtk1': {
                //     color: theme.palette.neutralPrimary,
                // },
                // '.monaco-editor .line-numbers.active-line-number': {
                //     color: theme.palette.neutralPrimary,
                // },
                // '.monaco-editor .margin': {
                //     backgroundColor: theme.palette.white,
                // },
                // '.monaco-editor .view-lines': {
                //     backgroundColor: theme.palette.white,
                // },

                // '.monaco-editor .minimap-decorations-layer': {
                //     backgroundColor: theme.palette.white,
                // },

                // '.monaco-editor.focused:after': {
                //     pointerEvents: 'none',
                //     content: '""',
                //     position: 'absolute',
                //     display: 'block',
                //     inset: '-1px',
                //     border: '2px solid',
                //     borderRadius: '2px',
                // },

                '&:not(.invalid) .monaco-editor:hover': {
                    borderColor: theme.semanticColors.inputBorderHovered,
                },
                '&:not(.invalid) .monaco-editor.focused': {
                    borderColor: theme.semanticColors.inputFocusBorderAlt,
                },
                '&:not(.invalid) .monaco-editor.focused:after': {
                    borderColor: theme.semanticColors.inputFocusBorderAlt,
                },

                '&.invalid .monaco-editor': {
                    borderColor: theme.palette.redDark,
                },
                '&.invalid .monaco-editor.focused:after': {
                    borderColor: theme.palette.redDark,
                },
            },
        },
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <div className={styles.root + (error?.message ? ' invalid' : '')} onBlur={onBlur}>
                    {label ? <Label onClick={() => monacoRef.current?.focus()}>{label}</Label> : null}
                    <Editor
                        height={height || '500px'}
                        defaultLanguage='csharp'
                        value={value as string}
                        onChange={(value: string | undefined) => onChange(value)}
                        onMount={(editor: monaco.editor.IStandaloneCodeEditor) => {
                            // @ts-ignore
                            monacoRef.current = editor;
                        }}
                        theme={window.matchMedia('(prefers-color-scheme: dark)').matches ? 'vs-dark' : ''}
                    />
                    {error?.message ? (
                        <Text variant='small' styles={{ root: { color: theme.palette.redDark } }}>
                            {error?.message}
                        </Text>
                    ) : null}
                </div>
            )}
        />
    );
}
