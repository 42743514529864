import {FunctionComponent} from 'react';
import {TextBoxAnswer, WorkDoneSwitchAnswer} from '../answers';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {ColumnInfo, Formatting} from "../../../enums";
import {FontWeights, Text} from "@fluentui/react";


export const GeneralQuestionProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    return (
        <ProceduresDataTable
            items={items}
            isLoading={isLoading}
            isHeaderVisible={false}
            columns={[
                {
                    key: 'heading',
                    name: '',
                    minWidth: 200,
                    maxWidth: 220,
                    fieldName: 'heading',
                    onRender: (items) => <Text style={{fontWeight: FontWeights.bold}}>{items.heading}</Text>,
                },
                {
                    key: 'description',
                    name: '',
                    minWidth: 500,
                    fieldName: 'description',
                    onRender: (items) => <Text
                        style={{fontWeight: items.format == Formatting.Bold ? FontWeights.bold : FontWeights.regular}}>{items.description}</Text>,
                },
                {
                    key: 'answer',
                    name: '',
                    minWidth: 100,
                    maxWidth: 120,
                    fieldName: 'answer',
                    onRender: (items) => items.answerControlType && (
                        <WorkDoneSwitchAnswer reverse
                                              value={items.answerText}
                                              itemId={items.id}
                                              tableType={items.tableType}
                                              iconTypes={"character"}
                                              columnInfo={ColumnInfo.Text}/>),
                },
            ]}
        />
    );
};