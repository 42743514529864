import {useState} from 'react';
import {ActionButton, IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {BaseDialog, Card, DataTable} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {ClientContactInfo} from "../../../shared";
import {ContactModal} from '..';
import {useWorkflows} from "../../../WorkflowLayoutPage";

type ContactsDataTablePropsType = {
    clientId: number;
    contacts: ClientContactInfo[];
};

export const ContactsDataTable = (props: ContactsDataTablePropsType) => {
    const [showActivatedModal, setShowActivatedModal] = useState<boolean>(false);
    const {formatMessage} = useIntl();
    const { showContactModal } = useWorkflows();
    const theme = useTheme();

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 80,
            maxWidth: 100,
            fieldName: 'commands',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    onClick={() => setShowActivatedModal(true)}
                              styles={{root: {fontWeight: 500}}}>
                    {items.status == "DeActive" ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'name',
            name: formatMessage({id: 'name'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'name',
            onRender: (items: ClientContactInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    onClick={() => {
                   showContactModal(items.id, items.clientId);
                }}>
                    {items?.firstName || ''} {items?.lastName || ''}
                </Link>
            ),
        },
        {
            key: 'email',
            name: formatMessage({id: 'email'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'email',
            onRender: (items: ClientContactInfo) => <Text>{items?.email}</Text>,
        },
        {
            key: 'phone',
            name: formatMessage({id: 'directPhone'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'phone',
            onRender: (items: ClientContactInfo) => <Text>{items?.directPhone}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={[]}
                initialColumns={columns}
                items={props.contacts}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />
            {showActivatedModal && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivatedModal(false)}
                />
            )}

        </Card>
    );
};
