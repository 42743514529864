export function getData<T>(key: string): T {
    const result: string | null =
        window.localStorage.length > 0 ? window.localStorage.getItem(key) : null;

    return result ? JSON.parse(result) : null;
}

export function getDataAndRemove<T>(key: string): T {
    const value: T = getData<T>(key);
    removeData(key);
    return value;
}

export function setData(key: string, data: unknown): void {
     const stringData: string = JSON.stringify(data);

    window.localStorage.setItem(key, stringData);
}

export function removeData(key: string): void {
    window.localStorage.removeItem(key);
}

export function clear(): void {
    window.localStorage.clear();
}

export function contains(key: string): boolean {
    return key in window.localStorage;
}
