import { IDropdownProps, Stack } from '@fluentui/react';
import { ControlledDropdown } from 'components';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useProcedureContext, useSectionContext } from '../..';
import { useValidationsContext } from '../validations';
import { useTabContext } from 'pages/JobPortal';

interface DropdownAnswerProps extends IDropdownProps {
    value?: any;
    onChange?: (answer: any) => void;
    itemId?: number;
    ignoreSectionAnswerUpdating?: boolean;
}

export const DropdownAnswer = forwardRef(({ value, onChange, options, ...otherProps }: DropdownAnswerProps, ref) => {
    const { control, watch, setValue } = useForm<{ dropdownAnswer: string }>({
        mode: 'onChange',
        defaultValues: {
            dropdownAnswer: value,
        },
    });

    const { isPositiveSectionAnswer } = useSectionContext();
    const { isTabEnabled } = useTabContext();
    const { onItemAnswerChanged: validationOnItemAnswerChanged } = useValidationsContext();
    const { onItemAnswerChanged: proceduresOnItemAnswerChanged } = useProcedureContext();

    const onSubmit = useCallback(
        (data: any) => {
            onChange?.(data.dropdownAnswer);

            if (otherProps.ignoreSectionAnswerUpdating !== true) {
                (validationOnItemAnswerChanged ?? proceduresOnItemAnswerChanged)?.(data.dropdownAnswer, otherProps.itemId);
            }
        },
        [isPositiveSectionAnswer]
    );

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    useImperativeHandle(ref, () => ({
        setNewValue(newValue: string) {
            setValue('dropdownAnswer', newValue);
        },
    }));

    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        // console.debug('[ANSWER::DROPDOWN::DISABLE]', isTabEnabled, !!otherProps.disabled);

        setDisabled(!isTabEnabled || !!otherProps.disabled);
    }, [otherProps.disabled, isTabEnabled]);

    return (
        <Stack grow>
            <ControlledDropdown options={options} control={control} name='dropdownAnswer' {...otherProps} disabled={disabled} />
        </Stack>
    );
});
