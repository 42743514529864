import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {BaseDialog} from 'components';

interface Props {
    yesDisabled?: boolean;
    noDisabled?: boolean;
    hidden: boolean;
    toggleHidden: () => void;
    onAgree: () => void;
}

export const RulesetRuleRestoreDialog: FunctionComponent<Props> = (props: Props) => {
    const {formatMessage} = useIntl();
    return (
        <BaseDialog
            title={formatMessage({id: 'restoreRuleDialogTitle'})}
            subText={formatMessage({id: 'restoreRuleDialogSubText'})}
            {...props}
        />
    );
};
