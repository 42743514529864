import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    FontSizes,
    IconButton,
    IContextualMenuItem, IDetailsRowProps,
    mergeStyles,
    MessageBarType,
    NeutralColors,
    Stack,
    Text,
    useTheme
} from '@fluentui/react';
import {useIntl} from 'react-intl';
import { dateFortmatString, getFlatProcedures, getOpenOnStartupIds } from "utils";
import {
    Attachment,
    GeneratedAnswer,
    JobComment,
    PermanentCheckBox,
    ProceduresDataTable,
    useProcedureContext,
    useSectionContext
} from 'pages/JobPortal/components';
import {ITableColumn, SanitizedText, useContextMenu} from 'components';
import {ColumnInfo, ReviewType} from "../../../../enums";
import {logger} from "services";
import {useCreateReviewItem} from "../../../../hooks";
import {NotificationAction, useNotifications} from "components/notifications";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import './Grid.css';
import {useIsDarkTheme} from "../../../../../../hooks";
import {NotificationType, useSignalR, useTenantInfo} from "../../../../../../providers";
import {CalculatorTemplate, getTypeByLink} from "../../../Calculators";
import {getCustomProcedureRowRender} from "../CustomProcedureTemplates";
import {useJobRedirects} from "../../shared";
import { useLocation } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";

import moment from 'moment'

export const GridProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading, answers, updateAnswers} = useProcedureContext();
    const {jobId, priorJob} = useJobContext();
    const {section, updateSectionAnswerIcon} = useSectionContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const isDark = useIsDarkTheme();
    const {isWorkpapers} = useTenantInfo();
    const {navigateToTableRow} = useJobRedirects();
    const { hash } = useLocation();

    const [isTrustDeedReviewSection] = useBoolean(section.reference === 'Trust Deed Review')

    const componentStyles = mergeStyles({
        '.contribution-table td, .contribution-table th': {
            borderColor: isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30,
        },
        '.contribution-table td': {
            color: theme.schemes?.default?.semanticColors.bodyText
        },
        '.contribution-table td .warning': {
            color: theme.schemes?.default?.palette.red
        },
        '.contribution-table th': {
            background: theme.palette.themePrimary
        },
        '.date-of-birth-table td, .date-of-birth-table th': {
            borderColor: isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30,
        },
        '.date-of-birth-table th': {
            background: theme.palette.themePrimary
        },
        '.date-of-birth-table td': {
            color: theme.schemes?.default?.semanticColors.bodyText
        },
    });
    
    const {setContextMenuItems, closeMenu} = useContextMenu();
    const {showNotification} = useNotifications();
    const {create: sendToReview, isCreating: isSending} = useCreateReviewItem();

    const sendItem = useCallback((item: any, reviewType: ReviewType) => {
        logger.debug(`Sending item to review:`, item);
        sendToReview({
            itemId: item.id,
            jobId: jobId,
            sectionId: item.ctxMeta?.sectionId ?? section.id,
            reviewType: reviewType
        }, {
            onSuccess: (res: any) => {
                navigateToTableRow({ tabId: 9, sectionId: ReviewType.Manager ? 64 : 65, itemId: res.data.id })
                showNotification({
                    type: MessageBarType.success,
                    description: formatMessage({id: 'itemAddedToReviews'}),
                    name: ''
                })
            }
        });
        closeMenu();
    }, [setContextMenuItems]);

    const [contextMenuItems] = useState<IContextualMenuItem[]>([
        {
            key: 'sendToManager',
            text: formatMessage({id: 'sendToManager'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Manager)
        },
        {
            key: 'sendToPartner',
            text: formatMessage({id: 'sendToPartner'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Partner)
        }
    ]);

    const [refreshRowsTick, setRefreshRowsTick] = useState<number>(Date.now())
    const [expandedItemIds, setExpandedItemIds] = useState<number[]>(getOpenOnStartupIds(items));
    const rows = useMemo<any[]>(() => getFlatProcedures(items, expandedItemIds), [items, expandedItemIds.length, refreshRowsTick]);
    const onExpand = (id: number) => setExpandedItemIds(prev => [...prev, id]);
    const onCollapse = (id: number) => setExpandedItemIds(prev => [...prev.filter(expandedItemId => expandedItemId !== id)]);

    const {useSignalREffect} = useSignalR();
    useSignalREffect("auditprocedure_answer_text_set", (notification: NotificationType) => {
        const { itemId, jobId: notificationJobId, text, sectionAnswerPositivity, isAnswerDisabled } = notification.content;
        console.log(`isDisabled from notification: ${isAnswerDisabled}`)
        if (notificationJobId === jobId && rows.some(x => x.id === itemId)) {
           updateSectionAnswers(text, itemId, isAnswerDisabled);
           updateSectionAnswerIcon(sectionAnswerPositivity);
        }
    }, [rows]);

    useEffect(() => {
        const id = +(hash.replace('#', ''));
        if (!isNaN(id)) {
            const groupIds: any[] = [];
            const _fn = (procedures: any[]): boolean => {
                return procedures.some(p => {
                    if (p.children?.length) {
                        if (p.children.some((c: any) => c.id === id) || _fn(p.children)) {
                            groupIds.push(p.id)
                            return true;
                        }
                    }
                    return false;
                })
            }
            
            _fn(items);
            groupIds.forEach(gId => onExpand(gId))
        }
    }, [hash, items, refreshRowsTick]);

    const updateSectionAnswers = useCallback((answerText: string | null, itemId: number, isAnswerDisabled?: boolean) => {
        if (!updateAnswers || !answers || answers.length == 0 || !itemId) {
            return;
        }

        const updatedAnswers = [...answers];
        const index = answers.findIndex((value) => value.id == itemId);

        if (index == -1) {
            return;
        }

        updatedAnswers[index].answerText = answerText;

        if(isAnswerDisabled !== undefined){
            updatedAnswers[index].isDisabled = isAnswerDisabled;
        }
        
        updateAnswers(updatedAnswers);
    }, [answers, updateAnswers]);

    const columns: ITableColumn[] = useMemo(() => ([
        {
            key: 'expander',
            name: '',
            fieldName: 'expander',
            onRender: (items) => (
                items?.showExpander && items.children?.length &&
                <IconButton iconProps={{
                    iconName: expandedItemIds.includes(items.id) ? 'Remove' : 'Add',
                    style: {fontSize: FontSizes.small}
                }}
                            onClick={() => expandedItemIds.includes(items.id) ? onCollapse(items.id) : onExpand(items.id)}/>

            ),
            minWidth: 20,
            maxWidth: 20
        },
        {
            key: 'actions',
            name: formatMessage({id: 'actions'}),
            fieldName: 'actions',
            onRender: (items) => (
                <Stack horizontal>
                    {items?.showAttachment && <Attachment itemId={items?.id} hasAttachments={items?.hasAttachments}/>}
                    {items?.showComment && <JobComment itemId={items?.id} hasComments={items?.hasComments}/>}
                    {items?.isIconCalc && <CalculatorTemplate type={getTypeByLink(items.link)}/>}
                </Stack>

            ),
            minWidth: 70,
            maxWidth: 90,
        },
        {
            key: 'auditProcedureNumber',
            name: '',
            fieldName: 'number',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text>{item.number}</Text>
                </Stack>
            )
        },
        {
            key: 'auditProcedure',
            name: formatMessage({id: isWorkpapers ? 'accountingProcedure' : 'auditProcedure'}),
            fieldName: 'auditProcedure',
            flexGrow: 1,
            onRender: (item) => {
                let title = item?.title
                
                if (item.link?.toLowerCase() === 'prioryearcontraventionlodged' && title) {
                    title = title.replaceAll(/(\t)+(\s)+/g, '');
                    
                    if (priorJob) {
                        const lodgedValue = priorJob.contraventionInfo?.lodged ? moment(priorJob.contraventionInfo?.lodged, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY') : 'N/A'
                        title = `${title} [${lodgedValue}]`
                    }
                }
                
                return (
                    <Stack horizontal tokens={{childrenGap: 16}}
                           styles={{root: {maxWidth: '100%', paddingLeft: (item.level || 0) * 16}}}>
                        <SanitizedText data={title ?? ''} styles={{
                            root: {
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellypsis'
                            }
                        }}/>
                    </Stack>
                )
            },
            minWidth: 250,
        },
        {
            key: 'assertion',
            name: formatMessage({id: 'assertion'}),
            fieldName: 'assertion',
            skip: isWorkpapers,
            onRender: (items) => items?.showAssertion ? (
                <Stack>
                    <Text>
                        <span style={{color: theme.palette.themePrimary}}>Financial: </span>
                        <SanitizedText data={items?.assertionFinancial || ''}/>
                    </Text>
                    <Text>
                        <span style={{color: theme.palette.themePrimary}}>Compliance: </span>
                        <SanitizedText data={items?.assertionCompilance || ''}/>
                    </Text>
                </Stack>
            ) : null,
            minWidth: 180,
            maxWidth: 200,
        },
        {
            key: 'auditingSTD',
            name: formatMessage({id: 'auditingSTD'}),
            fieldName: 'auditingSTD',
            skip: isWorkpapers,
            onRender: (items) => <SanitizedText data={items?.auditingStnd}/>,
            minWidth: 120,
            maxWidth: 120,
        },
        {
            key: 'result',
            name: formatMessage({id: 'result'}),
            fieldName: 'result',
            onRender: (item) => (
                <Stack verticalAlign='center' grow>
                    <GeneratedAnswer columnInfo={ColumnInfo.Text} itemId={item.id} tableType={item.tableType}
                                     onUpdate={(value) => {
                                         item.answerText = value
                                         setRefreshRowsTick(Date.now())
                                     }}
                                     value={answers?.find(x => x.id === item.id)?.answerText} auto={item.isAnswerDisabled || answers?.find(x => x.id === item.id)?.isDisabled}
                                     disabled={item.isAnswerDisabled || answers?.find(x => x.id === item.id)?.isDisabled} answerControlType={item?.answerControlType}/>
                </Stack>
            ),
            minWidth: isTrustDeedReviewSection ? 200 : 120,
            maxWidth: isTrustDeedReviewSection ? 200 : 120,
        },
        {
            key: 'permanent',
            name: formatMessage({id: 'permanent'}),
            fieldName: 'permanent',
            onRender: (items) => (
                <Stack verticalAlign='center' horizontalAlign='center' grow>
                    {items?.showCheckBox &&
                        (<PermanentCheckBox checked={items.permanentChecked}
                                            tableType={items.tableType}
                                            disabled={!!items['isPermanentDisabled']}
                                            itemId={items.id}/>)}
                </Stack>
            ),
            minWidth: 75,
            maxWidth: 75,
        },
    ]), [formatMessage, expandedItemIds.length, answers, isTrustDeedReviewSection]);

    const onRenderRow = useCallback((props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        // needs to perform more persice template name check
        const tmplNames = (props?.item?.title ?? '').match(/tmpl([A-Z])\w+/g) ?? [];
        if (tmplNames.length > 0) {
            const rowRender = getCustomProcedureRowRender(tmplNames[0]);
            if (rowRender) {
                return rowRender(props, defaultRender, rows, _ => setRefreshRowsTick(Date.now()));
            }
        }

        if (props?.item?.reference) {
            const rowRender = getCustomProcedureRowRender(props.item.reference);
            if (rowRender) {
                return rowRender(props, defaultRender, rows, _ => setRefreshRowsTick(Date.now()));
            }
        }

        return defaultRender(props);
    }, []);
    
    useEffect(() => {
        setContextMenuItems(contextMenuItems);
    }, [setContextMenuItems]);

    if (items?.length) {
        return (
            <ProceduresDataTable
                items={rows}
                isLoading={isLoading}
                className={componentStyles}
                onRenderRow={onRenderRow}
                columns={columns}/>
            )
    }

    return <></>;
};


