import {INotification, Notification} from "./Notification";
import {AnimationStyles, useTheme} from "@fluentui/react";

export const Toast = ({actions,...props}: INotification) => {
    const theme = useTheme();
    
    return (<Notification truncated styles={{
        root: {
            position: 'absolute',
            zIndex: 9999999,
            top: 8,
            right: 16,
            borderRadius: theme.effects.roundedCorner2,
            maxWidth: 350,
            maxHeight: 100,
            height: 'auto !important',
            ...AnimationStyles.slideLeftIn40
        }
    }} actions={actions} {...props}/>);
};
