import {FunctionComponent, useMemo} from 'react';
import {CommandBar, ICommandBarItemProps, IDropdownOption, Stack, useTheme} from '@fluentui/react';
import {ControlledSelect} from 'components';
import {Control, UseFormReset} from 'react-hook-form';
import {useIntl} from 'react-intl';

export interface IDashboardFilter {
    filterByYear?: string;
    clientGuid?: string;
    fundGuid?: string;
    contactGuid?: string;
}

interface Props {
    control: Control<IDashboardFilter, object>;
    reset: UseFormReset<IDashboardFilter>;
    jobYears?: IDropdownOption[];
    shortClientInfos?: IDropdownOption[];
    shortFundInfos?: IDropdownOption[];
    isLoading?: boolean;
}

export const DashboardFilter: FunctionComponent<Props> = ({
                                                              control,
                                                              reset,
                                                              jobYears = [],
                                                              shortClientInfos = [],
                                                              shortFundInfos = [],
                                                              isLoading,
                                                          }) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                disabled: isLoading,
                text: formatMessage({id: 'clearFilter'}),
                iconProps: {iconName: 'ClearFilter'},
                iconOnly: true,
                onClick: () => reset()
            },
        ],
        [formatMessage, reset, isLoading]
    );

    return (
        <Stack
            horizontal
            horizontalAlign='stretch'
            tokens={{childrenGap: 16}}
            styles={{
                root: {
                    paddingBottom: 16,
                    borderBottom: `1px solid ${theme.palette.neutralLight}`,
                }
            }}
        >
            <Stack.Item styles={{root: {width: '25%', maxWidth: 400, minWidth: 150}}}>
                <ControlledSelect
                    options={shortClientInfos}
                    name='clientGuid'
                    placeholder={formatMessage({id: 'filterByClient'})}
                    control={control}
                    autoComplete
                    disabled={isLoading}/>
            </Stack.Item>
            <Stack.Item styles={{root: {width: '25%', maxWidth: 400, minWidth: 150}}}>
                <ControlledSelect
                    options={shortFundInfos}
                    name='fundGuid'
                    placeholder={formatMessage({id: 'filterByFund'})}
                    control={control}
                    autoComplete
                    disabled={isLoading}/>
            </Stack.Item>
            <Stack.Item styles={{root: {width: '15%', maxWidth: 200, minWidth: 150}}}>
                <ControlledSelect
                    options={jobYears}
                    name='filterByYear'
                    placeholder={formatMessage({id: 'filterByYear'})}
                    control={control}
                    autoComplete
                    disabled={isLoading}/>
            </Stack.Item>
            <Stack.Item grow={1}>
                <CommandBar
                    items={[]}
                    farItems={commandBarItems}
                    styles={{root: {height: '32px'}}}
                />
            </Stack.Item>
        </Stack>
    );
};