import React, {FunctionComponent, PropsWithChildren, useEffect, useMemo} from 'react';
import {createTheme, initializeIcons, Theme, ThemeProvider} from '@fluentui/react';
import {useConfiguration} from 'hooks/useConfiguration';
import {generateTheme} from 'utils';
import {Context} from 'utils/context';
import {SignInLoader} from './navigation/SignInLoader';
import {useGetTheme} from 'hooks';
import {useIsDarkTheme} from 'hooks/useIsDarkTheme';
import { useGetStorageConfiguration } from 'hooks/useGetStorageConfiguration';
import { STORAGE_REFERENCES_KEY } from "constants/authorization";
import * as localStorageService from 'services/localStorageService';

initializeIcons();

export const defaultAppTheme: Theme = createTheme({
    palette: {
        themePrimary: '#bfcc49',
        themeLighterAlt: '#fcfdf6',
        themeLighter: '#f4f7de',
        themeLight: '#ebf0c2',
        themeTertiary: '#d8e08a',
        themeSecondary: '#c6d25c',
        themeDarkAlt: '#acb842',
        themeDark: '#919b38',
        themeDarker: '#6b7229',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#ffffff',
    }
});

export const AppTheme: FunctionComponent = (props: PropsWithChildren<{}>) => {
    const {config, tenant, isConfigLoading, isTenantLoading} = useConfiguration();
    const {colors, isLoading, error} = useGetTheme(config?.data.settingsUrl || '', true, tenant?.data.GlobalId || '');
    const {segments, isLoading: isStorageLoading } = useGetStorageConfiguration(config?.data.storageUrl || '', tenant?.data.GlobalId || '');

    const isUserPreferDarkTheme = useIsDarkTheme();

    const defaultTheme = useMemo(() => {
        return isUserPreferDarkTheme
            ? createTheme({
                palette: generateTheme({
                    primaryColor: defaultAppTheme.palette.themePrimary,
                    textColor: '#ffffff',
                    backgroundColor: '#454342',
                    isDark: isUserPreferDarkTheme,
                }),
            })
            : createTheme({
                palette: generateTheme({
                    primaryColor: defaultAppTheme.palette.themePrimary,
                    textColor: defaultAppTheme.palette.neutralPrimary,
                    backgroundColor: '#ffffff',
                    isDark: isUserPreferDarkTheme,
                }),
            });
    }, [isUserPreferDarkTheme]);

    const theme = useMemo(() => {
        return isUserPreferDarkTheme
            ? createTheme({
                palette: generateTheme({
                    primaryColor: colors?.data.themeViewModel.themePrimary || defaultAppTheme.palette.themePrimary,
                    textColor: '#ffffff',
                    backgroundColor: '#454342',
                    isDark: isUserPreferDarkTheme,
                }),
                schemes: {
                    default: defaultTheme
                }
            })
            : createTheme({
                palette: generateTheme({
                    primaryColor: colors?.data.themeViewModel.themePrimary || defaultAppTheme.palette.themePrimary,
                    textColor: colors?.data.themeViewModel.neutralPrimary || defaultAppTheme.palette.neutralPrimary,
                    backgroundColor: '#ffffff',
                    isDark: isUserPreferDarkTheme,
                }),
                schemes: {
                    default: defaultTheme
                }
            });
    }, [colors, isUserPreferDarkTheme]);

    useEffect(() => {
        if(segments?.data)
        {
            localStorageService.setData(STORAGE_REFERENCES_KEY, segments?.data);
        }
    }, [segments?.data])

    useEffect(() => {
        document.title = tenant?.data.Name || 'Loading...';
    }, [tenant?.data])
    if (isConfigLoading || isTenantLoading || isLoading || isStorageLoading) {
        return <>
            <ThemeProvider theme={theme}>
                <SignInLoader/>
            </ThemeProvider>
        </>;
    }


    return error?.response?.status !== 401 ? (
        <>
            <Context.Provider
                value={{
                    config: config?.data || null,
                    tenantInfo: tenant?.data || null,
                    tenantId: tenant?.data.GlobalId || '',
                    tenantType: tenant?.data.Type || null,
                    logo: colors?.data.logoImageUrl || ''
                }}>
                <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </Context.Provider>
        </>
    ) : (
        <ThemeProvider theme={theme}>
            <SignInLoader/>
        </ThemeProvider>
    );
};
