import {Card, ControlledDatePicker, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle} from "react";
import {ExperienceFormHandle, TabProps, TabRefProps} from "../props/tabProps";
import {useUpdateJobInvoiceInfo} from "../../../hooks";

interface IUpdateJobInvoiceInfoCommand {
    jobGuid: string;
    invoiceDate?: Date;
    invoiceAmount?: string;
    invoiceNumber?: string;
}

export const InvoicingTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {control, handleSubmit} = useForm<IUpdateJobInvoiceInfoCommand>({
        ...DefaultFormSettings, defaultValues: {
            invoiceAmount: props.job?.invoice?.amount,
            invoiceDate: props.job?.invoice?.date,
            invoiceNumber: props.job?.invoice?.number
        }
    });
    const {updateJobInvoiceInfo, isJobInvoiceInfoUpdating} = useUpdateJobInvoiceInfo();

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: IUpdateJobInvoiceInfoCommand) => {

        updateJobInvoiceInfo({
            jobGuid: props.job?.guid || '',
            invoiceNumber: data?.invoiceNumber,
            invoiceDate:data?.invoiceDate,
            invoiceAmount: data?.invoiceAmount
        }, {
            onSuccess: () => {
                props.onSubmitted();
            }
        });
    };
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='invoiceDate'
                        label={formatMessage({id: 'invoiceDate'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='invoiceAmount'
                        label={formatMessage({id: 'invoiceAmount'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='invoiceNumber'
                        label={formatMessage({id: 'invoiceNumber'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});