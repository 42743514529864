import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";


type MutateDataType = {
    jobId: number;
}

function useCreateFileNote() {
    const context = useContext(Context);

    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.proceduresUrl}/api/1.0/procedures/fileNotes`, data));

    return {create, createAsync, isCreating};
}

export {useCreateFileNote};