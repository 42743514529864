import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { BaseDialog } from 'components';

interface Props {
    hidden: boolean;
    toggleHidden: () => void;
    onAgree: () => void;
    noDisabled?: boolean;
    yesDisabled?: boolean;
}
export const UserManagementDeleteUserDialog: FunctionComponent<Props> = (props: Props) => {
    const { formatMessage } = useIntl();
    return (
        <BaseDialog
            title={formatMessage({ id: 'deleteUserDialogTitle' })}
            subText={formatMessage({ id: 'deleteUserDialogSubText' })}
            {...props}
        />
    );
};
