import {useState} from 'react';
import {ActionButton, IColumn, Link, SelectionMode, Text} from '@fluentui/react';
import {BaseDialog, Card, DataTable} from 'components';
import {ActionButtonStyles, CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {ClientInfo, EntityStatus} from "pages/WorkflowPortal/shared";
import {useUpdateClientsStatus} from "pages/WorkflowPortal/Clients";
import {useWorkflows} from "../../../WorkflowLayoutPage";
import {IOrderedRequest} from "../../../../../interfaces";

type ClientsPropsType = {
    clientItems: ClientInfo[] | [];
    setSort: (value: IOrderedRequest) => void;

};

export const TrusteesDataTable = ({clientItems, setSort}: ClientsPropsType) => {
    const {formatMessage} = useIntl();
    const {showTrusteeModal, showEmployeeModal, showContactModal} = useWorkflows();
    const [currentActivatedUser, setCurrentActivatedUser] = useState<{ clientId: number; status: EntityStatus } | null>(null);

    const {
        updateClientStatus,
        isLoadingClientsUpdateStatus
    } = useUpdateClientsStatus(() => setCurrentActivatedUser(null));

    const handleChangeClientStatus = () => {
        if (currentActivatedUser) {
            updateClientStatus({
                clientId: currentActivatedUser?.clientId,
                status: currentActivatedUser?.status === EntityStatus.Active ? EntityStatus.DeActive : EntityStatus.Active,
            });
        }
    };

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            isSorted: false,
            onRender: (items: ClientInfo) => (
                <ActionButton
                    styles={{root: {fontWeight: 500}}}
                    onClick={() => setCurrentActivatedUser({clientId: items.id, status: items.status})}
                >
                    {items.status === EntityStatus.Active ? 'Deactivate' : 'Activate'}
                </ActionButton>
            ),
        },
        {
            key: 'clientName',
            name: formatMessage({id: 'name'}),
            minWidth: 150,
            maxWidth: 600,
            fieldName: 'Name',
            onRender: (items: ClientInfo) => (
                <Link styles={ActionButtonStyles} onClick={() => showTrusteeModal(items.guid)}
                >
                    {items.name}
                </Link>
            ),
        },
        {
            key: 'primaryContact',
            name: formatMessage({id: 'accountant'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.PrimaryContact.FirstName',
            onRender: (items: ClientInfo) => (
                <Link
                    styles={ActionButtonStyles}
                    onClick={() => showContactModal(items.primaryContact.id, items.id)}
                >
                    {`${items.primaryContact?.firstName || ''} ${items.primaryContact?.lastName || ''}`}
                </Link>
            ),
        },
        {
            key: 'mainPhone',
            name: formatMessage({id: 'trusteePhone'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.PrimaryContact.DirectPhone',
            onRender: (items: ClientInfo) => <Text>{items.primaryContact?.directPhone}</Text>,
        },
        {
            key: 'partner',
            name: formatMessage({id: 'partner'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.Partner.FirstName',
            onRender: (items: ClientInfo) => (
                <Link styles={ActionButtonStyles} onClick={() => showEmployeeModal(items.partner.id)}>
                    {`${items.partner?.firstName || ''} ${items.partner?.lastName || ''}`}
                </Link>
            ),
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['clientName', 'primaryContact', 'mainPhone', 'partner']}
                initialColumns={columns}
                items={clientItems}
                setOrder={setSort}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
            />

            {!!currentActivatedUser?.clientId && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={handleChangeClientStatus}
                    toggleHidden={() => setCurrentActivatedUser(null)}
                    noDisabled={isLoadingClientsUpdateStatus}
                    yesDisabled={isLoadingClientsUpdateStatus}
                />
            )}
        </Card>
    );
};
