import { IColumn } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import { formatDate } from 'utils';
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGL360TaxAccouting = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'accountCode',
                name: formatMessage({ id: 'accountCode' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountCode',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'accountName',
                name: formatMessage({ id: 'accountName' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amount',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='round'/>,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
