import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import {NewCreditNote} from "../inderfaces";
import { MessageBarType } from "@fluentui/react";
import { useNotifications } from "../../../../../components/notifications";
import { useIntl } from "react-intl";
import { useInvoicePortalNotifications } from "../../../hooks";

function useCreateCreditNote(clientIdentifier: string) {
    const context = useContext(Context);
    const {showOnCreateNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: NewCreditNote) => axios.post(`${context?.config?.billingUrl}/api/1.0/CreditNote/Create/${clientIdentifier}`, data, {
        ...commonHeaders()
    }), {
        onSuccess: () => { 
            queryClient.invalidateQueries('getCreditNotes');
            showOnCreateNotification.success({ values: { name: 'Credit Note' }})
        },
        onError: (error: AxiosError) => {
            showOnCreateNotification.error({ values: { ...error?.response?.data ?? {} }})
        }
    });

    return {create, createAsync, isCreating};
}

export {useCreateCreditNote};