import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import React, {cloneElement, FunctionComponent, ReactDOM, ReactNode} from 'react';
import {IAdministrationList, IAdministrationListItem} from 'interfaces';
import {
    DefaultButton,
    FocusZone,
    FocusZoneDirection, FontWeights,
    Icon,
    List,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text,
    useTheme
} from '@fluentui/react';
import {useIntl} from "react-intl";

const getButtonByType = (type: 'default' | 'primary') => {
    switch (type) {
        case 'primary':
            return <PrimaryButton/>;
        case 'default':
        default:
            return <DefaultButton/>;
    }
};

export const AdministrationList: FunctionComponent<IAdministrationList> = (props: IAdministrationList) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const {palette, fonts} = theme;


    const onRenderCell = (item: IAdministrationListItem | undefined): ReactNode => {
        const classNames = mergeStyleSets({
            itemCell: [
                {
                    display: 'flex',
                    minHeight: 48,
                    padding: 10,
                    marginBottom: 8,
                    background: theme.schemes?.default?.palette.white,
                    borderRadius: 2,
                    boxSizing: 'border-box',
                    cursor: !!item?.disabled ? '' : 'pointer',
                    selectors: {
                        '&:hover': {background: 'rgba(124, 124, 124, 0.4)'},
                    }
                },
            ],
            itemContent: {
                marginLeft: 10,
                overflow: 'hidden',
                flexGrow: 1,
            },
            itemName: [
                fonts.medium,
                {
                    color: theme.schemes?.default?.semanticColors.bodyText,
                    fontWeight: FontWeights.regular,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            ],
            chevron: {
                alignSelf: 'center',
                fontWeight: FontWeights.bold,
                color: palette.themePrimary,
                fontSize: fonts.smallPlus.fontSize,
                flexShrink: 0,
            },
            buttons: [
                fonts.smallPlus,
                {
                    height: 28
                }
            ]
        });

        const onButtonClick = (event: React.MouseEvent<HTMLElement>, func: Function): void => {
            event.stopPropagation();
            func(item);
        };

        return (
            <Stack
                horizontal
                className={classNames.itemCell}
                tokens={{childrenGap: 32}}
                onClick={(event) => props.onCardClick && props.onCardClick(event, item?.guid, item)}>

                <Stack className={classNames.itemContent} verticalAlign={"center"} horizontal
                       tokens={{childrenGap: 10}}>
                    <Stack.Item style={{width: '2%'}}>{item?.showAcceptIcon &&
                        <Icon styles={{root: {color: theme?.schemes?.default?.palette.green}}}
                              iconName={'accept'}/>}</Stack.Item>
                    <Stack.Item className={classNames.itemName}>{item?.name}</Stack.Item>
                </Stack>

                {props.buttonsConfig?.length && (
                    <Stack horizontal verticalAlign='center' tokens={{childrenGap: 16}}>
                        {props.buttonsConfig.map(({text, onClick, type}, idx) => {
                            return cloneElement(getButtonByType(type), {
                                text,
                                className: classNames.buttons,
                                onClick: (event: React.MouseEvent<HTMLElement>) => onButtonClick(event, onClick),
                                key: `card-${item?.guid}-button-${idx}`
                            })
                        })}
                    </Stack>
                )}
                {!item?.disabled && (
                    <Icon className={classNames.chevron} iconName={props.itemIcon?.iconName || 'ChevronRight'}/>)}
            </Stack>
        );
    };

    return (

        props.isLoading ? (
            <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
                <Spinner size={SpinnerSize.large} label={"Loading"}/>
            </Stack>) : (
            <Stack grow tokens={{childrenGap: 16}}>
                {props.title && (
                    <Text styles={{root: {marginBottom: 8, ...fonts.xLarge}}}>
                        {props.title}
                    </Text>
                )}
                {props.items.length ? (
                        <FocusZone direction={FocusZoneDirection.vertical}>
                            <List items={props.items}
                                  onRenderCell={onRenderCell}/>
                        </FocusZone>)
                    : (<StackItem align={"center"}>
                        <Text>{formatMessage({id: 'noData'})}</Text>
                    </StackItem>)
                }
            </Stack>
        )

    );
};