import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {GeneratedAnswer, ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {ColumnInfo} from "../../../enums";
import {isNoAnswer, isYesAnswer, WorkDoneSwitchAnswer} from "../answers";
import {useMergedRefs} from "@fluentui/react-hooks";

type TestSummaryExtetndedProcedure = {
    isAnswerDisabled: boolean;
    comment?: string;
}


export const TestSummaryProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {formatMessage} = useIntl();
    
    const answerRefs = useRef<any>([]);
    const [shouldRefresh, setShouldRefresh] = useState<number>(Date.now())

    const isItemDisabled = useCallback((item: any & TestSummaryExtetndedProcedure, items: any[], index?: number): boolean => {
        if (index === undefined) return true;

        if ([0, 7].includes(index)) return false;

        if (index === 1) {
            return !items[index - 1].answerText;
        }

        if (index > 1) {
            return isNoAnswer(items[1].answerText) ? !items[index - 1].answerText : true;
        }

        return true;
    }, [])

    const getItemComment = useCallback((item: any & TestSummaryExtetndedProcedure, items: any[], index?: number): string => {
        if (index === undefined) return '';

        const fn = (i: any): string => {
            return isYesAnswer(i.answerText) ? i.yesComment : isNoAnswer(i.answerText) ? i.noComment : '';
        }

        if ([0, 1, 7].includes(index)) return fn(item);

        if (index > 1) {
            return isYesAnswer(items[1].answerText) ? formatMessage({id: 'testNotRequired'}) : fn(item);
        }

        return '';
    }, [])

    const rebuildItems = useCallback((items: any): Array<any & TestSummaryExtetndedProcedure> => {
        if (!items || items.length === 0) {
            return [];
        }

        const result = items.reduce((acc: Array<any & TestSummaryExtetndedProcedure>, item: any, index: number, source: any[]): Array<any & TestSummaryExtetndedProcedure> => {
            const extended = {
                ...item,
                comment: null,
                isAnswerDisabled: false
            };

            const result = {
                ...extended,
                comment: getItemComment(extended, source, index),
                isAnswerDisabled: isItemDisabled(extended, source, index)
            }

            acc.push(result);
            return acc;
        }, []);

        return result;
    }, [])
    
    const procedures = useMemo(() => {
        return rebuildItems(items);
    }, [items, shouldRefresh]);

    useEffect(() => {
        answerRefs.current = answerRefs.current.slice(0, items.length);
    }, [items]);
    
    const onAnswerChange = useCallback((answerText: string | null, id: number, index: number) => {
        const item = (items ?? []).find(x => x.id === id);
        if (!item) return;
        
        item.answerText = answerText;
        if (index < 2) {
            for (let i = index + 1; i < items.length - 1; i++) {
                items[i].answerText = null;
                answerRefs.current[i]?.reset?.();
            }
        }
        
        setShouldRefresh(Date.now())
        
    }, [])
    
    return (
        <ProceduresDataTable
            items={procedures}
            isLoading={isLoading}
            columns={[
                {
                    key: 'test',
                    name: formatMessage({id: 'test'}),
                    fieldName: 'test',
                    onRender: (items) => <SanitizedText data={items?.test}/>,
                    size: 1,
                },
                {
                    key: 'description',
                    name: formatMessage({id: 'description'}),
                    fieldName: 'description',
                    onRender: (items) => <SanitizedText data={items?.description}/>,
                    size: 4,
                },
                {
                    key: 'result',
                    name: formatMessage({id: 'result'}),
                    fieldName: 'result',
                    onRender: (item, index, column) => index != undefined && (
                        <Stack verticalAlign='center' grow>
                            <WorkDoneSwitchAnswer reverse ref={el => answerRefs.current[index!] = el}
                                                  disabled={item.isAnswerDisabled}
                                                  itemId={item.id}
                                                  onChange={(answerText) => {
                                                      onAnswerChange(answerText, item.id, index)
                                                  }}
                                                  tableType={item.tableType}
                                                  value={item?.answerText}
                                                  iconTypes={'character'}
                                                  columnInfo={ColumnInfo.Text}/>
                        </Stack>
                    ),
                    size: 1,
                },
                {
                    key: 'comments',
                    name: formatMessage({id: 'comments'}),
                    fieldName: 'comments',
                    onRender: (item, index, column) => <SanitizedText data={item.comment}/>,
                    size: 4,
                },
            ]}
        />
    );
};