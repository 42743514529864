import { IColumn } from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";



export const ClassSuperConsolidatedInvestmentIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'investmentName',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'investmentName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'asx',
                name: formatMessage({ id: 'asx' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'asx',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed1',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'acquisitionQuantity',
                name: formatMessage({ id: 'acquiQty' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'acquisitionQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'acquisitionCost',
                name: formatMessage({ id: 'acquiCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'acquisitionCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'disposalQuantity',
                name: formatMessage({ id: 'dispQty' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'disposalProceeds',
                name: formatMessage({ id: 'dispProceeds' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalProceeds',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'disposalProfitOrLoss',
                name: formatMessage({ id: 'dispProfitLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalProfitOrLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed2',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed2',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'closingBookQuantity',
                name: formatMessage({ id: 'cbQty' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBookQuantity',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'closingBookCost',
                name: formatMessage({ id: 'cbCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBookCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed3',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed3',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'marketPrice',
                name: formatMessage({ id: 'marketPrice' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketPrice',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'closingBookMarketValue',
                name: formatMessage({ id: 'cbMarketValue' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBookMarketValue',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'balanceIndicator',
                name: formatMessage({ id: 'analyticalReview' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balanceIndicator',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'income',
                name: formatMessage({ id: 'income' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'income',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'returnOnInvestmentPercent',
                name: formatMessage({ id: 'returnOnInvestment' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'returnOnInvestmentPercent',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
