import { PortalRole, useWorkContext } from "../providers";
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { Forbidden } from "./Forbidden";

interface IRequireRolesProps {
	accessFor?: PortalRole[],
	denyFor?: PortalRole[]
}

export const RequireRoles: FunctionComponent<IRequireRolesProps> = ({ accessFor, denyFor, children }: PropsWithChildren<IRequireRolesProps>) => {
	const {isInRoleOrSuperAdministrator, isInRole, workContext} = useWorkContext();

	const [isForbidden, setIsForbidden] = useState<boolean | null>(null);
	useEffect(() => {
		console.debug("[REQUIRE_AUTH::CHECK]", accessFor, denyFor, workContext?.user?.identityUser?.roles);
		if (!workContext?.user?.identityUser) {
			setIsForbidden(null);
		} else {
			setIsForbidden(accessFor?.length ? !isInRoleOrSuperAdministrator(...accessFor) : (denyFor?.length ? isInRole(...denyFor) : true))
		}
	}, [accessFor?.length, denyFor?.length, workContext?.user?.identityUser]);

	if (isForbidden === null) {
		return (
			<></>
		)
	}
	
	if (isForbidden === true) {
		return (
			<Forbidden />
		)
	}
	
	return (
		<>{children}</>
	)
};
