import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, useTheme } from '@fluentui/react';
import { useRevokeConnection } from "./hooks/useRevokeConnection";

interface Props {
    connectionId: string;
}

export const RevokeConnectionButton: FunctionComponent<Props> = ({ connectionId }: Props) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const {revoke} = useRevokeConnection();
    
    const [open, setOpen] = useState(false);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'warning' }),
        subText: formatMessage({ id: 'doYouWishToRevokeAccess' }),
    };
    
    const onRevokeClick = (connectionId: string) => {
        revoke({ connectionId: connectionId });
        setOpen(false);
    }

    return (
        <>
            <ActionButton
                styles={{
                    root: { height: 32 },
                    icon: { color: theme.palette.red },
                    iconHovered: { color: theme.palette.red },
                    label: { color: theme.palette.blue, textDecoration: 'underline' },
                }}
                disabled={true}
                text={formatMessage({ id: 'revoke' })}
                iconProps={{ iconName: 'Trash' }}
                onClick={() => setOpen(true)}
            />
            <Dialog hidden={!open} onDismiss={() => setOpen(false)} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <DefaultButton onClick={() => setOpen(false)} text={formatMessage({ id: 'cancel' })} />
                    <PrimaryButton
                        onClick={() => {
                            onRevokeClick(connectionId)
                        }}
                        text={formatMessage({ id: 'ok' })}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};
