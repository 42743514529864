import { Shimmer, ShimmerElementType } from '@fluentui/react';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { EmailTemplates } from './components/EmailTemplates';
import { useEmailTemplateGet } from './hooks/Email/useEmailTemplateGet';
import { useEmailTemplateUpdate } from './hooks/Email/useEmailTemplateUpdate';

/**
 * Wrapper component for Email Templates.
 * We call API here and then fall it with props or show Shimmer if it loading
 */

export const EmailTemplatesPage: FunctionComponent = () => {
    const { emailTemplate, isLoading } = useEmailTemplateGet();
    const { updateEmailTemplate, updateEmailTemplateLoading } = useEmailTemplateUpdate();

    if (!emailTemplate?.data || isLoading || updateEmailTemplateLoading)
        return <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 753 }]} />;
    return <EmailTemplates emailTemplate={emailTemplate.data} updateEmailTemplate={updateEmailTemplate} />;
};
