import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from '../../../../../../utils';

type RevokeConnectionData = {
    connectionId: string;
};

function useRevokeConnection() {
    const context = useContext(Context);

    const { mutate: revoke, isLoading: isRevoking } = useMutation(
        (data: RevokeConnectionData) =>
            axios.delete(`${context?.config?.settingsUrl}/api/1.0/Settings/externalConnections/${data.connectionId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getExternalConnectionSettings');
            },
        }
    );

    return { revoke, isRevoking };
}

export { useRevokeConnection };
