import { Observable } from 'rxjs';
import {
    ICategory,
    ICreateRuleCommand,
    ICreateRulesetCommand,
    IPaginatedResult, IParamInfo,
    IRuleInfo,
    IRulesetFilters,
    IRulesetInfo,
} from 'interfaces';
import { ConfigurationService, configurationService } from './ConfigurationService';
import { BaseService } from './BaseService';
import {IChangeGlobalVariableResponse} from "../interfaces/ruleset/IChangeGlobalVariableResponse";

class RulesetService extends BaseService {
    constructor(configurationService: ConfigurationService) {
        super('rulesEngineUrl', configurationService);
    }

    public getAllCategories(): Observable<ICategory[]> {
        return this.get<ICategory[]>(`/api/1.0/workflow/categories`);
    }

    public filterRulesets(filters: IRulesetFilters): Observable<IPaginatedResult<IRulesetInfo>> {
        return this.get<IPaginatedResult<IRulesetInfo>>('/api/1.0/workflow/filter', { params: filters });
    }

    public getRuleset(id: IRulesetInfo['id']): Observable<IRulesetInfo> {
        return this.get<IRulesetInfo>(`/api/1.0/workflow/${id}`);
    }

    public renameRuleset(id: IRulesetInfo['id'], name: IRulesetInfo['workflowName']): Observable<any> {
        return this.put<any>(`/api/1.0/workflow/${id}`, { name });
    }

    public deleteRuleset(id: IRulesetInfo['id']): Observable<any> {
        return this.delete(`/api/1.0/workflow/${id}`);
    }

    public restoreRuleset(id: IRulesetInfo['id']): Observable<any> {
        return this.put(`/api/1.0/workflow/${id}/restore`);
    }
    
    public changeGlobalVariable(id: IRulesetInfo['id'], command: IParamInfo): Observable<IChangeGlobalVariableResponse>{
        return this.post<IChangeGlobalVariableResponse>(`/api/1.0/workflow/${id}/variables`, command);
    }
    
    public deleteRuleSetVariable(id: IRulesetInfo['id'], command: IParamInfo): Observable<IParamInfo>{
        
        return this.post<IParamInfo>(`/api/1.0/workflow/${id}/variables`, command);
    }
    
    public createRuleset(ruleset: ICreateRulesetCommand): Observable<IRulesetInfo> {
        return this.post<IRulesetInfo>('/api/1.0/workflow/', ruleset);
    }

    public addRule(id: IRulesetInfo['id'], rule: ICreateRuleCommand): Observable<IRuleInfo> {
        return this.post<IRuleInfo>(`/api/1.0/workflow/${id}/addRule`, rule);
    }

    public editRule(id: IRulesetInfo['id'], ruleId: IRuleInfo['id'], rule: ICreateRuleCommand): Observable<any> {
        return this.put(`/api/1.0/workflow/${id}/rule/${ruleId}`, rule);
    }

    public deleteRule(id: IRulesetInfo['id'], ruleId: IRuleInfo['id']): Observable<IRuleInfo> {
        return this.delete(`/api/1.0/workflow/${id}/rule/${ruleId}`);
    }

    public restoreRule(id: IRulesetInfo['id'], ruleId: IRuleInfo['id'], forceRestore: boolean = false): Observable<IRuleInfo> {
        return this.put(`/api/1.0/workflow/${id}/rule/${ruleId}/restore`, null, { params: { forceRestore } });
    }
}

export const rulesetService = new RulesetService(configurationService);
