"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageEvent = exports.createConnection = exports.isConnectionConnecting = void 0;
const utils_1 = require("../utils");
function isConnectionConnecting(connection) {
    return (connection &&
        (connection.readyState === WebSocket.CONNECTING ||
            connection.readyState === WebSocket.OPEN));
}
exports.isConnectionConnecting = isConnectionConnecting;
function createConnection(context, { url, onOpen, onClose, onErrorRef, logger, }) {
    const connection = new WebSocket(url);
    connection.onopen = () => {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen(connection);
        logger === null || logger === void 0 ? void 0 : logger.log("webSocket connected");
    };
    connection.onmessage = (event) => {
        const data = event.data ? JSON.parse(event.data.toString()) : {};
        (0, utils_1.sendWithHermes)(getMessageEvent(context), data, context.shareConnectionBetweenTab);
    };
    connection.onclose = (event) => {
        onClose === null || onClose === void 0 ? void 0 : onClose(event);
        logger === null || logger === void 0 ? void 0 : logger.log("webSocket closed", event);
    };
    connection.onerror = (error) => { var _a; return (_a = onErrorRef.current) === null || _a === void 0 ? void 0 : _a.call(onErrorRef, error); };
    context.connection = connection;
    return connection;
}
exports.createConnection = createConnection;
function getMessageEvent(context) {
    return context.key + "WEB_SOCKET_EVENT";
}
exports.getMessageEvent = getMessageEvent;
