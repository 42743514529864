import React from "react";
import {commonHeaders, Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {ClientContactInfo} from "../../shared";

function useGetClientContacts(clientId: number | null) {
    const context = React.useContext(Context);


    const {data: contactsResponse, isFetching: isContactsLoading} = useQuery(
        ['getClientContacts', clientId],
        () => axios.get<ClientContactInfo[]>(`${context?.config?.clientUrl}/api/1.0/Clients/${clientId}/contact`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientId,
        }
    );

    return {contactsResponse, isContactsLoading};
}

export {useGetClientContacts};