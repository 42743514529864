import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

export type PublishInitialOptions = {
    jobId: number;
    sendEmail: boolean;
    ignoreChecks: boolean;
    includeInvoice: boolean;
    ignoreNonWithholdingQueries: boolean;
    isDraftArSentInsert: boolean;
    sentPreArQueries?: boolean;
    overrideDraftAr: boolean;
}

export const PublishInitialOptionsDefualts: PublishInitialOptions = {
    jobId: 0,
    sendEmail: false,
    ignoreChecks: true,
    includeInvoice: true,
    ignoreNonWithholdingQueries: false,
    isDraftArSentInsert: true,
    sentPreArQueries: false,
    overrideDraftAr: false
}

function usePublishInitialAudit() {
    const context = useContext(Context);
    const {
        mutate: publish,
        isLoading: isPublishing
    } = useMutation((data: PublishInitialOptions) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/publish/${data.jobId}`, {
        ...data,
        isSourceDispatched: false
    }).then(result => result.data))

    return {publish, isPublishing};
}

export {usePublishInitialAudit};