import {
    mergeStyleSets,
    Stack,
    useTheme,
    Text,
    FontWeights,
    VerticalDivider,
    Separator,
    NeutralColors
} from '@fluentui/react';
import {FunctionComponent, MouseEventHandler, PropsWithChildren, ReactNode} from 'react';
import {IStyle} from '@fluentui/merge-styles/lib/IStyle';

interface Props {
    hideShadow?: boolean;
    styles?: { root?: IStyle,content?:IStyle, title?: IStyle };
    onClick?: MouseEventHandler;
    children: ReactNode;
    title?: string;
    titleChildren?: ReactNode;
}

export const Card: FunctionComponent<Props> = ({
                                                   children,
                                                   styles: additionalStyles,
                                                   onClick,
                                                   hideShadow,
                                                   title,
                                                   titleChildren
                                               }: Props) => {
    const theme = useTheme();
    const classNames = mergeStyleSets({
        root: {
            background: theme.palette.white,
            borderRadius: theme.effects.roundedCorner2,
            boxShadow: hideShadow ? '' : theme.effects.elevation4,
            overflow:'auto'
        },
        content: {
            padding: 16
        },
    }, additionalStyles);

    return (<div className={classNames.root} onClick={onClick}>
        {title && (<CardTitle title={title} styles={additionalStyles?.title}>{titleChildren}</CardTitle>)}
        <div className={classNames.content}>{children}</div>
    </div>);
};

const CardTitle: FunctionComponent<PropsWithChildren<{ title: string, styles?: IStyle }>> = ({
                                                                                                 title,
                                                                                                 styles: additionalStyles,
                                                                                                 children
                                                                                             }) => {
    const theme = useTheme();
    const classNames = mergeStyleSets({
        root: {
            background: 'rgba(184, 184, 184, 0.2)',
            padding: 10,
            borderTopRightRadius: theme.effects.roundedCorner2,
            borderTopLeftRadius: theme.effects.roundedCorner2,
            borderBottom: '1px solid ' + theme.palette.neutralTertiaryAlt
        },
    }, additionalStyles);
    return (<Stack horizontal verticalAlign={'center'} className={classNames.root} horizontalAlign={"space-between"} grow>
        <Text theme={theme.schemes?.default} style={{fontWeight: FontWeights.bold}}>
            {title}
        </Text>
        {children}
    </Stack>);
};
