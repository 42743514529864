import {IColumn, Stack} from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportValidationCell} from "../shared";
import {useCreateReviewItem} from "../../../../hooks";
import {useReportValidationsContext} from "../../../ReportContent";



export const BGLTaxableIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const rebuildItems = (arr: any[]): IReport[] => {
        return (arr || []).reduce((acc, i) => {
            acc.push({
                ...i,
                showAttachmentIcon: i?.valueType === 'Value' || i?.valueType === 'Total' || i?.valueType === 'GrandTotal',
                showCommentsIcon: i?.valueType === 'Value' || i?.valueType === 'Total' || i?.valueType === 'GrandTotal',
            })

            return acc;
        }, [])
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentsIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'name' }),
                minWidth: 400,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'to',
                name: formatMessage({ id: 'thisYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'to',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[1]} isSumChecked={item.isSumChecked} />,
            },
            {
                key: 'validation2',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation2',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[2]} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={rebuildItems(items)} isFetching={isFetching} />;
};
