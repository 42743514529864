import { action, makeObservable, observable } from 'mobx';
import { IBreadcrumbItem } from '@fluentui/react';

export class BreadcrumbsStore {
    public breadcrumbs: Array<IBreadcrumbItem & { id: string }> = [];

    constructor() {
        makeObservable(this, {
            breadcrumbs: observable,
            addBreadcrumb: action,
            updateBreadcrumb: action,
            removeBreadcrumb: action,
        });
    }

    public addBreadcrumb = (breadcrumb: IBreadcrumbItem & { id: string }) => {
        this.breadcrumbs = [...this.breadcrumbs, breadcrumb];
    };

    public updateBreadcrumb = (breadcrumbId: string, breadcrumb: IBreadcrumbItem & { id: string }) => {
        this.breadcrumbs = this.breadcrumbs.map((b) => (b.id === breadcrumbId ? breadcrumb : b));
    };

    public removeBreadcrumb = (breadcrumbId: string) => {
        this.breadcrumbs = this.breadcrumbs.filter((b) => b.id !== breadcrumbId);
    };
}
