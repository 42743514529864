import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

type MutateDataType = {
    id: number;
    responseCompleted: boolean;
    disclosureCompleted: boolean;
    jobId: number;
}
function useUpdateRisksConclusion() {
    const context = useContext(Context);
    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/identifiedrisks/conclusion/${data.jobId}`, data))

    return {update, isUpdating};
}

export {useUpdateRisksConclusion};