import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IPaginationResult} from "../../../interfaces";
import {IInvoice, IInvoicesFilter} from "../interfaces";
import { Md5 } from "ts-md5";

function useGetInvoices(clientIdentifier: string, filter: IInvoicesFilter) {
    const context = useContext(Context);
    const {
        data: invoices,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['getInvoices', clientIdentifier, filter.pageNumber, filter.pageSize, filter.status, filter.fundName, filter.year, filter.invoiceNumber],
        () => axios.get<IInvoice[]>(`${context?.config?.billingUrl}/api/1.0/invoice/GetAll/${clientIdentifier}`, {
            ...commonHeaders(),
            params: {
                page: filter.pageNumber,
                size: filter.pageSize,
                status: filter.status,
                year: filter.year,
                fundName: filter.fundName,
                invoiceNumber: filter.invoiceNumber
            }
        })
            .then(resp => resp.data as any)
            .then((response: { items: IInvoice[], totalItemsCount: number }): IPaginationResult<IInvoice> => {
                return {
                    items: (response.items ?? []).map(calculateHash),
                    pageNumber: filter.pageNumber,
                    pageSize: filter.pageSize,
                    totalCount: response.totalItemsCount
                }
            }), {
            enabled: !!context?.config?.billingUrl,
            staleTime: 1,
            cacheTime: 1
        }
    );

    return {invoices, isLoading, isFetching, refetch};
}

const calculateHash = (invoice: IInvoice): IInvoice => {
    const str = invoice.invoiceJobs.map(ij => ij.jobGuid).join('_');
    invoice.hash = Md5.hashStr(str);
    return invoice;
}

export {useGetInvoices};