import React, {FunctionComponent} from 'react';
import {Image, Shimmer, ShimmerElementType} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Context} from 'utils';
import {useNavigate} from 'react-router-dom';

interface Props {
    size?: 'small' | 'medium';
    currentLogo?: boolean;
    linkToMain?: boolean;
}

export const Logo: FunctionComponent<Props> = ({ size = 'medium', currentLogo = false, linkToMain = false }: Props) => {
    const context = React.useContext(Context);
    const navigate = useNavigate();
    // const { colors, isLoading: getColorsLoading } = useGetCustomizationColors(
    //     context?.config?.settingsUrl || '',
    //     currentLogo,
    //     context?.tenantId || ''
    // );
    const intl = useIntl();
    return !context?.logo ? (
        <Shimmer
            shimmerElements={[{ type: ShimmerElementType.line, width: size === 'medium' ? 300 : 200, height: size === 'medium' ? 71 : 47 }]}
        />
    ) : (
        <Image
            src={context.logo}
            styles={{ image: { width: size === 'medium' ? 300 : 200, maxHeight: 48, objectFit: 'contain' }, root: { cursor: linkToMain ? 'pointer' : 'default' } }}
            alt={intl.formatMessage({ id: 'logoImageAltText' })}
            onClick={() => navigate('/')}
        />
    );
};
