import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Stack} from '@fluentui/react';
import {IMenuLink, ModalWithForm, VerticalMenu} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useBoolean} from '@fluentui/react-hooks';
import {AccountInfoTab, AddressTab, APIAuthorisationFields, AuditTab, ContactTab, FundsTab, NotesTab} from './tabs';
import {
    useGetClientByGuid,
    useUpdateClientsAddresses,
    useUpdateClientsApiAuth,
    useUpdateClientsAuditInfo,
    useUpdateClientsMainInfo,
    useUpdateClientsNotes
} from "../../hooks";
import {ArAclGenerationType, AuditReportDateType, ClientAddressInfo, ClientRiskRating} from "../../../shared";
import {DetailedSoftwareType, SoftwareType} from "enums";

export interface IChangeClientInfoCommand {
    clientName: string;
    mainPhone: string;
    primaryContact: string;
    primaryContactPhone: string;
    secondaryContact: string;
    type: "Corporate" | "Individual" | null;
    secondaryContactPhone: string;
    addresses: ClientAddressInfo[];
    partnerId?: number;
    accountingSoftware: SoftwareType | DetailedSoftwareType | null;
    detailedSoftwareType: DetailedSoftwareType | null;
    turnAroundTime: string;
    clientRiskRating: ClientRiskRating | null;
    aRGeneration: ArAclGenerationType | null;
    unsignedARDate: AuditReportDateType | null;
    unsignedAuditReport: string;
    titleSearchConduct: boolean;
    asicSearchConduct: boolean;
    isDraftAuditReport: boolean,
    apiAuthorisationType: string;
    accSoftwareCodes: {
        value: string;
    }[];
    notes: string;
}

enum ClientModalTabs {
    ACCOUNT_INFO,
    ADDRESS,
    AUDIT,
    API_AUTHORISATION,
    NOTES,
    FUNDS,
    CONTACTS,
}

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    guid?: string | null;
}


export const ClientModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title, guid}: Props) => {
    const {formatMessage} = useIntl();
    const [activeTab, setActiveTab] = useState<ClientModalTabs>(ClientModalTabs.ACCOUNT_INFO);
    const {currentClientData, isCurrentClientLoading} = useGetClientByGuid(guid || '');

    const {updateClientMainInfo, isLoadingClientsUpdateMainInfo} = useUpdateClientsMainInfo();
    const {updateClientAddresses, isUpdatingClientAddresses} = useUpdateClientsAddresses();
    const {updateClientAuditInfo, isUpdatingClientAuditInfo} = useUpdateClientsAuditInfo();
    const {updateClientApiAuth, isUpdatingClientApiAuth} = useUpdateClientsApiAuth();
    const {updateClientNotes, isUpdatingClientNotes} = useUpdateClientsNotes();

    const isUpdating = useMemo(() => {
        return (
            isLoadingClientsUpdateMainInfo ||
            isUpdatingClientAddresses ||
            isUpdatingClientAuditInfo ||
            isUpdatingClientApiAuth ||
            isUpdatingClientNotes
        );
    }, [
        isLoadingClientsUpdateMainInfo,
        isUpdatingClientAddresses,
        isUpdatingClientAuditInfo,
        isUpdatingClientApiAuth,
        isUpdatingClientNotes,
    ])

    const globalLoading = useMemo(() => {
        return (
            isLoadingClientsUpdateMainInfo ||
            isUpdatingClientAddresses ||
            isCurrentClientLoading ||
            isUpdatingClientAuditInfo ||
            isUpdatingClientApiAuth ||
            isUpdatingClientNotes
        );
    }, [
        isCurrentClientLoading,
        isLoadingClientsUpdateMainInfo,
        isUpdatingClientAddresses,
        isUpdatingClientAuditInfo,
        isUpdatingClientApiAuth,
        isUpdatingClientNotes,
    ]);


    const links = useMemo<IMenuLink[]>(() => [
        {
            name: formatMessage({id: 'general'}),
            url: '',
            key: 'General',
            iconProps: {iconName: 'Settings'},
            links: [
                {
                    name: formatMessage({id: 'accountInformation'}),
                    url: '',
                    key: ClientModalTabs[ClientModalTabs.ACCOUNT_INFO],
                    iconProps: {iconName: 'ComplianceAudit'},
                    onClick: () => {
                        setActiveTab(ClientModalTabs.ACCOUNT_INFO);
                    },
                },
                {
                    name: formatMessage({id: 'address'}),
                    url: '',
                    key: ClientModalTabs[ClientModalTabs.ADDRESS],
                    iconProps: {iconName: 'Street'},
                    onClick: () => {
                        setActiveTab(ClientModalTabs.ADDRESS);
                    },
                },
                {
                    name: formatMessage({id: 'auditParticulars'}),
                    url: '',
                    key: ClientModalTabs[ClientModalTabs.AUDIT],
                    iconProps: {iconName: 'MapLayers'},
                    onClick: () => {
                        setActiveTab(ClientModalTabs.AUDIT);
                    },
                },
                {
                    name: formatMessage({id: 'apiAuthorisation'}),
                    isHidden: [SoftwareType.BGL, SoftwareType.Nothing, SoftwareType.Supermate, SoftwareType.DesktopSuper].some(x => x === currentClientData?.data.auditInfo.softwareType),
                    url: '',
                    key: ClientModalTabs[ClientModalTabs.API_AUTHORISATION],
                    iconProps: {iconName: 'AuthenticatorApp'},
                    onClick: () => {
                        setActiveTab(ClientModalTabs.API_AUTHORISATION);
                    },
                },
                {
                    name: formatMessage({id: 'notes'}),
                    url: '',
                    key: ClientModalTabs[ClientModalTabs.NOTES],
                    iconProps: {iconName: 'QuickNoteSolid'},
                    onClick: () => {
                        setActiveTab(ClientModalTabs.NOTES);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({id: 'funds'}),
            url: '',
            key: ClientModalTabs[ClientModalTabs.FUNDS],
            iconProps: {iconName: 'MapLayers'},
            onClick: () => {
                setActiveTab(ClientModalTabs.FUNDS);
            },
        },
        {
            name: formatMessage({id: 'contacts'}),
            url: '',
            key: ClientModalTabs[ClientModalTabs.CONTACTS],
            iconProps: {iconName: 'ConnectContacts'},
            onClick: () => {
                setActiveTab(ClientModalTabs.CONTACTS);
            },
        },
    ], [currentClientData?.data]);

    const {control, handleSubmit, formState, reset, setValue} = useForm<IChangeClientInfoCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            titleSearchConduct: false,
            asicSearchConduct: false,
            clientName: '',
            mainPhone: '',
            primaryContact: '',
            primaryContactPhone: '',
            secondaryContact: '',
            secondaryContactPhone: '',
            accountingSoftware: null,
            turnAroundTime: '',
            unsignedARDate: null,
            clientRiskRating: null,
            aRGeneration: null,
            apiAuthorisationType: '',
            notes: '',
            detailedSoftwareType: null,
            isDraftAuditReport: false,
        },
    });

    useEffect(() => {
        if (currentClientData?.data) {
            setValue('primaryContact', currentClientData.data.primaryContact?.guid || '');
            setValue('primaryContactPhone', currentClientData.data.primaryContact?.mobilePhone || '');
            setValue('secondaryContact', currentClientData.data.secondaryContact?.guid);
            setValue('secondaryContactPhone', currentClientData.data.secondaryContact?.mobilePhone || '');
            setValue('clientName', currentClientData.data?.name);
            setValue('mainPhone', currentClientData.data?.mainPhone);
            setValue('addresses', currentClientData.data.addresses);
            if (currentClientData?.data.auditInfo?.softwareType === SoftwareType.ClassSuper) {
                setValue('accountingSoftware', currentClientData.data.auditInfo?.detailedSoftwareType);
            } else {
                setValue('accountingSoftware', currentClientData.data.auditInfo?.softwareType);
            }

            setValue('detailedSoftwareType', currentClientData.data.auditInfo?.detailedSoftwareType);
            setValue('clientRiskRating', currentClientData.data.auditInfo?.riskRatingType);
            setValue('unsignedARDate', currentClientData.data.auditInfo?.auditReportDateType);
            setValue('aRGeneration', currentClientData.data.auditInfo?.arAclGenerationType);
            setValue('titleSearchConduct', currentClientData.data.auditInfo?.titleSearchConduct);
            setValue('asicSearchConduct', currentClientData.data.auditInfo?.asicSearchConduct);
            setValue('turnAroundTime', currentClientData.data.auditInfo?.turnAroundTime || '');
            setValue('unsignedAuditReport', currentClientData.data.auditInfo?.isDraftAuditReport ? "Yes" : "No" || '');

            //AUTH
            setValue('apiAuthorisationType', currentClientData.data.apiAuthInfo.useAdminFlow ? 'admin' : 'client');
            setValue('accSoftwareCodes', currentClientData.data.apiAuthInfo.authCodes.map(x => ({value: x})) || []);
            setValue('notes', currentClientData.data.notes || '');
        }
    }, [currentClientData?.data, setValue]);

    const onSubmit = (data: IChangeClientInfoCommand) => {
        if (currentClientData?.data) {
            switch (activeTab) {
                case ClientModalTabs.ACCOUNT_INFO:
                    updateClientMainInfo({
                        clientId: currentClientData.data.id,
                        mainPhone: data.mainPhone || '',
                        name: data.clientName || '',
                        primaryContactId: data.primaryContact || '',
                        secondaryContactId: data.secondaryContact,
                    });
                    return;
                case ClientModalTabs.ADDRESS:
                    updateClientAddresses({
                        clientId: currentClientData.data.id,
                        addresses: data.addresses
                    });
                    return;

                case ClientModalTabs.AUDIT:

                    updateClientAuditInfo({
                        clientId: currentClientData.data.id,
                        softwareType: (data.accountingSoftware === DetailedSoftwareType.ClassSuperAPI || data.accountingSoftware === DetailedSoftwareType.ClassSuperPDF) ? SoftwareType.ClassSuper : data.accountingSoftware || null,
                        detailedSoftwareType: (data.accountingSoftware === DetailedSoftwareType.ClassSuperAPI || data.accountingSoftware === DetailedSoftwareType.ClassSuperPDF) ? data.accountingSoftware : data.detailedSoftwareType || null,
                        turnAroundTime: Number(data.turnAroundTime) || 0,
                        riskRatingType: data.clientRiskRating,
                        arAclGenerationType: data.aRGeneration,
                        auditReportDateType: data.unsignedARDate || null,
                        titleSearchConduct: data.titleSearchConduct || false,
                        asicSearchConduct: data.asicSearchConduct || false,
                        isDraftAuditReport: data.unsignedAuditReport === "Yes" || false,
                    });
                    return;
                case ClientModalTabs.API_AUTHORISATION:
                    updateClientApiAuth({
                        clientId: currentClientData.data.id,
                        isAdminFlow: data.apiAuthorisationType === 'admin',
                        codes: data.accSoftwareCodes.map(x => x.value),
                    });
                    return;
                case ClientModalTabs.NOTES:
                    updateClientNotes({
                        clientId: currentClientData.data.id,
                        notes: data.notes || '',
                    });
                    return;

                default:
                    return;
            }
        }
    };

    const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);

    return (
        <ModalWithForm minWidth={'60vw'}
                       isLoading={isCurrentClientLoading}
                       isOpen={isOpen}
                       onDismiss={() => {
                           reset();
                           onDismiss();
                           setActiveTab(ClientModalTabs.ACCOUNT_INFO);
                       }}
                       title={title}
                       onSubmit={handleSubmit(onSubmit)}
                       submitDisabled={globalLoading || (activeTab !== ClientModalTabs.FUNDS && activeTab !== ClientModalTabs.CONTACTS ? (!formState.isDirty || !formState.isValid) : false)}
                       isSubmitting={isUpdating}
                       closeAfterSubmit={true}>
            <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -15px'}}}>
                <VerticalMenu selectedKey={ClientModalTabs[activeTab || ClientModalTabs.ACCOUNT_INFO]} links={links}
                              isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>

                <Stack
                    styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`}}}
                    tokens={{childrenGap: 16}}>
                    <Stack.Item styles={{root: {height: 'calc(100% - 50px)'}}}>
                        <>
                            {activeTab === ClientModalTabs.ACCOUNT_INFO ? (
                                <AccountInfoTab control={control} contacts={currentClientData?.data.contacts}/>
                            ) : activeTab === ClientModalTabs.ADDRESS ? (
                                <AddressTab control={control}/>
                            ) : activeTab === ClientModalTabs.AUDIT ? (
                                <AuditTab control={control}/>
                            ) : activeTab === ClientModalTabs.API_AUTHORISATION ? (
                                <APIAuthorisationFields control={control}/>
                            ) : activeTab === ClientModalTabs.NOTES ? (
                                <NotesTab control={control}/>
                            ) : activeTab === ClientModalTabs.FUNDS ? (
                                <FundsTab clientGuid={currentClientData?.data.guid}/>
                            ) : activeTab === ClientModalTabs.CONTACTS ? (
                                    <ContactTab
                                        clientId={currentClientData?.data.id || 0}
                                        contacts={currentClientData?.data.contacts || []}
                                    />
                                )
                                : ('')
                            }
                        </>
                    </Stack.Item>
                </Stack>
            </Stack>
        </ModalWithForm>
    );
});
