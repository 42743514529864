import axios from 'axios';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from '../../../../../../utils';

type AddApprovevrData = {
    email: string;
};

function useAddApprover() {
    const context = useContext(Context);

    const { mutate: add, isLoading: isAdding } = useMutation(
        (data: AddApprovevrData) =>
            axios.post(`${context?.config?.userUrl}/api/1.0/user/approver`, data, {
                ...commonHeaders()
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getExternalConnectionSettings');
            },
        }
    );

    return { add, isAdding };
}

export { useAddApprover };
