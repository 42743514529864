import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateCreateDataType = {
    clientId: number;
    fundId: number;
    jobId: number;
    employeeLocalId?: number;
    timesheetDate: Date;
    durationMin: number;
    rate: number;
}

function useCreateTimesheet() {
    const context = useContext(Context);
    const {
        mutate: createTimesheet,
        mutateAsync: createTimesheetAsync,
        isLoading: isTimesheetCreating
    } = useMutation((data: MutateCreateDataType) => axios.post(`${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets`, data))

    return {createTimesheet, createTimesheetAsync, isTimesheetCreating};
}

export {useCreateTimesheet};
