import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    scheduleId: number;
}

function usePublish() {
    const context = useContext(Context);
    const host = context?.config?.exportUrl;

    const {
        mutate:publish,
        isLoading
    } = useMutation((data: MutateDataType) => axios.put(`${host}/api/1.0/jobs/${data.jobId}/export/scheduled/${data.scheduleId}/publish`));

    return {publish, isLoading};
}

export {usePublish};