import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {DashboardTableTypeEnum} from "../components";

export interface ITableHeadersColumn {
    displayName: string;
    columnName: string;
    isClickable: boolean;
    onClickPopUpName: string | null;
    popupIdentifierPropertyName: string | null;
    isSortable: boolean;
    ambientName: string;
    displayOrder: number;
    isDateTimeColumn: boolean;
    dateArrayName: string | null;
    dateArrayIndex: number | null;
}

export interface ITableHeaders {
    tableName: string;
    maxQueriesCount: number;
    searchPlaceholder: string;
    hasDateFilter: boolean;
    hasFilterByPeriod: boolean;
    hasYearFilter: boolean;
    columns: ITableHeadersColumn[];
}

function useGetTableHeaders(tableType: DashboardTableTypeEnum) {
    const context = React.useContext(Context);

    const {data: tableHeaders, isLoading: isTableHeadersLoading} = useQuery(
        ['getTableHeaders', tableType],
        () => axios.get<ITableHeaders>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/tableHeaders`,
            {...commonHeaders(), params: {tableType}}),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {tableHeaders, isTableHeadersLoading};
}

export {useGetTableHeaders};