import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultButton, mergeStyleSets, Stack, Text, useTheme } from "@fluentui/react";

type OAuthResultPageProps = {}

export const OAuthResultPage: FunctionComponent<OAuthResultPageProps> = ({ ...props }: OAuthResultPageProps) => {
	const {formatMessage} = useIntl();
	const {palette} = useTheme();
	
	const navigate = useNavigate();
	
	const [searchParams] = useSearchParams()
	
	const [message, setMessage] = useState<string>();

	const classNames = mergeStyleSets({
		container: {
			height: '100%',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		title: {
			fontSize: 144,
			marginBottom: 16,
			color: palette.orange
		},
		subtitle: {
			color: palette.orangeLight,
		},
	});
	
	const onBackClick = () => {
		navigate('/administration/jobs')
	};

	useEffect(() => {
		setMessage(prev => searchParams.get('classSuccess') ?? searchParams.get('classError') ?? 'Unknown error.')
	}, [searchParams]);
	
	return (
		<Stack styles={{root: {height: '100%', overflow: 'hidden'}}}>
			<Stack className={classNames.container} tokens={{childrenGap: 16}}>
				<Text>
					{message}
				</Text>
				<DefaultButton onClick={onBackClick} text={formatMessage({id: 'back'})}/>
			</Stack>
		</Stack>
	)
};