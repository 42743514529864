import React from "react";
import {commonHeaders, Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {ClientContactInfo} from "../../shared";

function useGetClientContactById(contactId: number, clientId: number) {
    const context = React.useContext(Context);


    const {data: contact, isFetching: isContactLoading} = useQuery(
        ['getClientContactById', contactId, clientId],
        () => axios.get<ClientContactInfo>(`${context?.config?.clientUrl}/api/1.0/Clients/${clientId}/contact/${contactId}`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientId && !!contactId,
        }
    );

    return {contact, isContactLoading};
}

export {useGetClientContactById};