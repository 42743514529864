import {useIntl} from "react-intl";
import {Spinner, Stack} from "@fluentui/react";
import {CalculatorRow} from "../shared";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {ITaxRecExemptPensionGrossData} from "./ITaxRecExemptPensionGrossData";
import {useUpdateCalculatorData} from "../../hooks";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {CalculatorType} from "../../CalculatorType";
import {CalculatorTemplateProps} from "../../types";
import {useJobContext} from "../../../../JobPortalLayoutPage";

type TaxRecExemptPensionIncomeGrossMethodCalculatorProps = CalculatorTemplateProps
export const TaxRecExemptPensionGrossCalculator = forwardRef(({
                                                                  data
                                                              }: TaxRecExemptPensionIncomeGrossMethodCalculatorProps, ref) => {
    const {formatMessage} = useIntl();
    const{ jobId}=useJobContext();
    const {update, isUpdating} = useUpdateCalculatorData();
    const {handleSubmit, setValue, watch, getValues, control} = useForm<ITaxRecExemptPensionGrossData>({
        ...DefaultFormSettings,
        defaultValues: {
            ...data?.data
        }
    });
    const watchFields = watch();
    const [grossData, setGrossData] = useState<ITaxRecExemptPensionGrossData>(data?.data as ITaxRecExemptPensionGrossData);
    const updateCalcData = (grossData: ITaxRecExemptPensionGrossData) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.TaxRecExemptPensionIncomeGrossMethod,
            isCustom: false,
            answer: grossData,
            jobId: jobId
        });
    };

    const nonDeductibleExpenses = useMemo(() => {

        return ((watchFields.totalExpenses || 0) * (watchFields.exemptPortionPerActuarialCertificateSightedEvolvServer || 0)) / 100;
    }, [watchFields]);

    const grossIncomeSubjectToExemption1 = useMemo(() => {

        return (watchFields.taxableIncome || 0) +
            (watchFields.exemptPensionIncome || 0) -
            (watchFields.taxableContributions || 0) +
            (watchFields.totalExpenses || 0) -
            (watchFields.nonDeductibleExpenses || 0);
    }, [watchFields]);
    const exempPensionIncomeTotal1 = useMemo(() => {
        return ((watchFields.grossIncomeSubjectToExemption1 || 0) * (watchFields.exemptPortionPerActuarialCertificateSightedEvolvServer || 0)) / 100.0;
    }, [watchFields]);
    const pensionNonDeductibleExpenses = useMemo(() => {
        return ((watchFields.totalExpenses || 0) *
                (watchFields.exemptPortionPerActuarialCertificateSightedEvolvServer || 0)) /
            100.0;
    }, [watchFields]);
    const grossIncomeSubjectToExemption2 = useMemo(() => {
        return (watchFields.assessableIncomePerITR || 0) - (watchFields.taxableContributions || 0);
    }, [watchFields]);
    const exempPensionIncomeTotal2 = useMemo(() => {
        return (grossIncomeSubjectToExemption2 *
            (watchFields.exemptPortionPerActuarialCertificateSightedEvolvServer || 0)) /
            100.0;
    }, [watchFields]);


    const isEPPerACChanged = useMemo(() => {
        return grossData?.isEPPerACChanged || watchFields.exemptPortionPerActuarialCertificateSightedEvolvServer != grossData?.exemptPortionPerActuarialCertificateSightedEvolvServer;
    }, [watchFields]);

    const isTaxableIncomeChanged = useMemo(() => {
        return grossData?.isTaxableIncomeChanged || watchFields.taxableIncome != grossData?.taxableIncome;
    }, [watchFields]);

    const isExemptPensionChanged = useMemo(() => {
        return grossData?.isExemptPensionChanged || watchFields.exemptPensionIncome != grossData?.exemptPensionIncome;
    }, [watchFields]);

    const isTaxableContributionsChanged = useMemo(() => {
        return grossData?.isTaxableContributionsChanged || watchFields.taxableContributions != grossData?.taxableContributions;
    }, [watchFields]);

    const isTotalExpensesChanged = useMemo(() => {
        return grossData?.isTotalExpensesChanged || watchFields.totalExpenses != grossData?.totalExpenses;
    }, [watchFields]);

    const isAssessableIncomePerITRChanged = useMemo(() => {
        return grossData?.isAssessableIncomePerITRChanged || watchFields.assessableIncomePerITR != grossData?.assessableIncomePerITR;
    }, [watchFields]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        }
    }));

    useEffect(() => {
        setGrossData(data?.data as ITaxRecExemptPensionGrossData);
    }, [data?.data]);

    const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

    const handleClickOutside = (event: any) => {
        if((event.srcElement.id as string).includes('TextField'))
            return;

        setEditingRow(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    if(isUpdating)
        return <Spinner />;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <CalculatorRow
                suffix='%'
                changed={isEPPerACChanged}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_portion_per_actuarial_certificate_sighted' })}
                onChangeValue={(newValue) => {
                    setValue('exemptPortionPerActuarialCertificateSightedEvolvServer', newValue);
                    setValue('isEPPerACChanged', true);
                }}
                value={getValues('exemptPortionPerActuarialCertificateSightedEvolvServer')}
                onClick={() => setEditingRow('exemptPortionPerActuarialCertificateSightedEvolvServer')}
                editing={editingRow === 'exemptPortionPerActuarialCertificateSightedEvolvServer'}
                useParamsValue
            />
            <CalculatorRow
                iconType={'equal'}
                changed={isTaxableIncomeChanged}
                onChangeValue={(newValue) => {
                    setValue('taxableIncome', newValue);
                    setValue('isTaxableIncomeChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Taxable_Income' })}
                value={grossData?.taxableIncome}
                onClick={() => setEditingRow('taxableIncome')}
                editing={editingRow === 'taxableIncome'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'plus'}
                changed={isExemptPensionChanged}
                onChangeValue={(newValue) => {
                    setValue('exemptPensionIncome', newValue);
                    setValue('isExemptPensionChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_Pension_Income' })}
                value={grossData?.exemptPensionIncome}
                onClick={()=> setEditingRow('exemptPensionIncome')}
                editing={editingRow === 'exemptPensionIncome'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                changed={isTaxableContributionsChanged}
                onChangeValue={(newValue) => {
                    setValue('taxableContributions', newValue);
                    setValue('isTaxableContributionsChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Taxable_Contributions' })}
                value={grossData?.taxableContributions}
                onClick={()=> setEditingRow('taxableContributions')}
                editing={editingRow === 'taxableContributions'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'plus'}
                changed={isTotalExpensesChanged}
                onChangeValue={(newValue) => {
                    setValue('totalExpenses', newValue);
                    setValue('isTotalExpensesChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Total_Expenses' })}
                value={grossData?.totalExpenses}
                onClick={()=> setEditingRow('totalExpenses')}
                editing={editingRow === 'totalExpenses'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Non_Deductible_Expenses' })}
                value={nonDeductibleExpenses}
            />
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Gross_Income_Subject_to_Exemption' })}
                value={grossIncomeSubjectToExemption1}
            />
            <CalculatorRow
                iconType={'multiply'}
                suffix='%'
                changed={isEPPerACChanged}
                onChangeValue={(newValue) => {
                    setValue('exemptPortionPerActuarialCertificateSightedEvolvServer', newValue);
                    setValue('isEPPerACChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_portion_per_actuarial_certificate_sighted' })}
                value={getValues('exemptPortionPerActuarialCertificateSightedEvolvServer')}
                onClick={() => setEditingRow('exemptPortionPerActuarialCertificateSightedEvolvServer1')}
                editing={editingRow === 'exemptPortionPerActuarialCertificateSightedEvolvServer1'}
                useParamsValue
            />
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_Pension_Income' })}
                value={exempPensionIncomeTotal1}
            />
            <CalculatorRow
                label={formatMessage({ id: 'calculator_taxRecGross_Total_Expenses' })}
                editable={false}
                value={grossData?.totalExpenses}
            />
            <CalculatorRow
                onChangeValue={(newValue) => {
                    setValue('exemptPortionPerActuarialCertificateSightedEvolvServer', newValue);
                    setValue('isEPPerACChanged', true);
                }}
                changed={isEPPerACChanged}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_Portion_Per_Actuarial_Certificate_Sighted' })}
                suffix='%'
                value={getValues('exemptPortionPerActuarialCertificateSightedEvolvServer')}
                onClick={() => setEditingRow('exemptPortionPerActuarialCertificateSightedEvolvServer2')}
                editing={editingRow === 'exemptPortionPerActuarialCertificateSightedEvolvServer2'}
                useParamsValue
            />
            <CalculatorRow
                iconType={'equal'}
                label={formatMessage({ id: 'calculator_taxRecGross_Pension_Non_Deductible_Expenses' })}
                editable={false}
                value={pensionNonDeductibleExpenses}
            />
            <CalculatorRow
                changed={isAssessableIncomePerITRChanged}
                label={formatMessage({ id: 'calculator_taxRecGross_Assessable_Income_As_Per_Itr' })}
                value={grossData?.assessableIncomePerITR}
                onChangeValue={(newValue) => {
                    setValue('assessableIncomePerITR', newValue);
                    setValue('isAssessableIncomePerITRChanged', true);
                }}
                onClick={() => setEditingRow('assessableIncomePerITR')}
                editing={editingRow === 'assessableIncomePerITR'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Taxable_Contributions' })}
                value={grossData?.taxableContributions}
            />
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Gross_Income_Subject_To_Exemption' })}
                value={grossIncomeSubjectToExemption2}
            />
            <CalculatorRow
                iconType={'multiply'}
                onChangeValue={(newValue) => {
                    setValue('exemptPortionPerActuarialCertificateSightedEvolvServer', newValue);
                    setValue('isEPPerACChanged', true);
                }}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_Portion_Per_Actuarial_Certificate_Sighted' })}
                suffix='%'
                changed={isEPPerACChanged}
                value={getValues('exemptPortionPerActuarialCertificateSightedEvolvServer')}
                onClick={() => setEditingRow('exemptPortionPerActuarialCertificateSightedEvolvServer3')}
                editing={editingRow === 'exemptPortionPerActuarialCertificateSightedEvolvServer3'}
                useParamsValue
            />
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({ id: 'calculator_taxRecGross_Exempt_Pension_Income' })}
                value={exempPensionIncomeTotal2}
            />
        </Stack>
    );
});