import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { EmailTemplate } from './useEmailTemplateGet';

/**
 * Mutation query for updating Email Template for Portal Settings
 * Page: Email Templates
 */

function useEmailTemplateUpdate() {
    const context = React.useContext(Context);

    const { mutate: updateEmailTemplate, isLoading: updateEmailTemplateLoading } = useMutation(
        (data: EmailTemplate) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/emailTemplates`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getEmailTemplate'),
        }
    );

    return { updateEmailTemplate, updateEmailTemplateLoading };
}

export { useEmailTemplateUpdate };
