import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

function useRestoreUser() {
    const context = React.useContext(Context);

    const { mutate: restoreUser, isLoading, isSuccess } = useMutation((userId: number) =>
        axios.put(`${context?.config?.userUrl}/api/1.0/User/${userId}/restore`, null, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
        }
    );

    return { restoreUser, isLoading, isSuccess };
}

export { useRestoreUser };