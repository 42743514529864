import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IAdministrationAPWidgetData,
    IDashboardConfig,
    WidgetsEnum
} from "pages/shared/Dashboard";

export const AdministrationAuditPortalDashboard = () => {
    const { formatMessage } = useIntl();

    const getAdministrationDashboardConfig = useCallback(
        (props: IAdministrationAPWidgetData): IDashboardConfig => {
            return {
                id: 'administration',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsAwaitingAuditorAllocation',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingAuditorAllocation, isWorkflow: true }],
                            props: {
                                number: props.auditsAwaitingAuditorAllocationCount,
                                title: formatMessage({ id: 'auditsAwaitingAuditorAllocation' }),
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsInProgressWithAuditor',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_AuditsInProgressWithAuditor, isWorkflow: true }],
                            props: {
                                number: props.auditsInProgressWithAuditorCount,
                                title: formatMessage({ id: 'auditsInProgressWithAuditor' }),
                                variant: 'tertiary'
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsAwaitingManagerReview',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingManagerReview, isWorkflow: true }],
                            props: {
                                number: props.auditsAwaitingManagerReviewCount,
                                title: formatMessage({ id: 'auditsAwaitingManagerReview' }),
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsAwaitingPartnerReview',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_AuditsAwaitingPartnerReview }],
                            props: {
                                number: props.auditsAwaitingPartnerReviewCount,
                                title: formatMessage({ id: 'auditsAwaitingPartnerReview' }),
                                variant: 'tertiary'
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'postARQueriesReadyForDispatch',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_PostArQueriesReadyForDispatch, isWorkflow: true }],
                            props: {
                                number: props.postArQueriesReadyForDispatchCount,
                                title: formatMessage({ id: 'postARQueriesReadyForDispatch' }),
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'arSentThisWeek',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_ArSentThisWeek, isWorkflow: true }],
                            props: {
                                number: props.arSentThisWeekCount,
                                title: formatMessage({ id: 'arSentThisWeek' }),
                                variant: 'tertiary'
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '7',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_AuditsReadyForInitialDispatch,
                                isWorkflow: true,
                            }
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'preARQueriesWaitingToBeReviewed',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingToBeReviewed, isWorkflow: true }],
                            props: {
                                title: formatMessage({ id: 'preARQueriesWaitingToBeReviewed' }),
                                data: props.preArQueriesWaitingToBeReviewed,
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'postARQueriesWaitingToBeReviewed',
                            tables: [{ tableType: DashboardTableTypeEnum.Administrator_PostArQueriesWaitingToBeReviewed, isWorkflow: true }],
                            props: {
                                title: formatMessage({ id: 'postARQueriesWaitingToBeReviewed' }),
                                data: props.postArQueriesWaitingToBeReviewed,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_PreArQueriesWaitingForResponse,
                                isWorkflow: true,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '9',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_PostArQueriesWaitingForResponse,
                                isWorkflow: true,
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '10',
                            props: {
                                tableType: DashboardTableTypeEnum.Administrator_Invoicing,
                                isWorkflow: true,
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return <DashboardContainer
        dashboardTab={DashboardTabsEnum.AP_Administrator}
        getDashboardConfig={getAdministrationDashboardConfig as GetDashboardConfig}
        isWorkflow={true}/>;
};
