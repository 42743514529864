import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type SmtpSettings = {
    senderSMTP: string;
    smtpUser: string;
    smtpPassword: string | null;
    smtpPort: number;
    sslEnabled: boolean;
    senderEmail: string;
    senderName: string;
}

type EmailSettingsRes = {
    smtpSettings: SmtpSettings | null;
    isTestMode: boolean;
    testEmail: string;
    crmAdminEmail: string;
};

/**Query for get email settings data in Portal Settings*/

function useGetEmailSettings() {
    const context = React.useContext(Context);

    const { data: emailSettings, isLoading } = useQuery(
        ['getEmailSettings'],
        () => axios.get<EmailSettingsRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/email`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { emailSettings, isLoading };
}

export { useGetEmailSettings };
