import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { MessageBarType } from "@fluentui/react";
import { useNotifications } from "../../../../components/notifications";
import axios, { AxiosError } from "axios";

export interface ICreateUser {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    isSuperUser: boolean;
}

function useCreateUser() {
    const context = React.useContext(Context);
    const { showNotification } = useNotifications();
    const { mutate: createUser, isLoading, isSuccess } = useMutation((data: ICreateUser) =>
        axios.post(`${context?.config?.userUrl}/api/1.0/User`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getUsersFilter'),
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return { createUser, isLoading, isSuccess };
}

export { useCreateUser };
