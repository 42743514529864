import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    tabCommentId: number;
    jobId: number;
} & { comment?: string }

function useUpdateClientPortalTabComment() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.annotationsUrl}/api/1.0/jobs/${data.jobId}/tabComments/${data.tabCommentId}`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getClientPortalTabs'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useUpdateClientPortalTabComment};