import {DefaultButton, List, Panel, Stack, Text, useTheme} from "@fluentui/react";
import React from "react";
import {useNotifications} from "./NotificationProvider";
import {INotification, Notification} from "./Notification";
import {format} from "date-fns";
import {FormattedMessage, useIntl} from "react-intl";
import {Empty} from "../Empty";

type NotificationPanelProps = {
    isOpen: boolean;
    onDismiss: () => void;
}
export const NotificationPanel = (props: NotificationPanelProps) => {
    const {notifications, dismissNotification, clearAll} = useNotifications();
    const {formatMessage} = useIntl();
    const theme = useTheme();

    return <Panel
        styles={{
            content: {marginTop: theme.spacing.l1, overflowY: 'auto', overflowX: 'hidden', height: '85%'},
            scrollableContent: {height: '100%'},
            closeButton: {
                selectors: {
                    '.ms-Icon': {color: theme.schemes?.default?.semanticColors.bodyText}
                },
            },
            headerText: {color: theme.schemes?.default?.semanticColors.bodyText}
        }}
        headerText='Notification panel'
        isLightDismiss
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        closeButtonAriaLabel='Close'>

        {!!notifications.length && (
            <Stack horizontal horizontalAlign={"end"}>
                <DefaultButton style={{color: theme.schemes?.default?.semanticColors.bodyText}}
                               text={formatMessage({id: 'clear'})} onClick={clearAll}/>
            </Stack>
        )}
        <NotificationList notifications={notifications} onDismiss={dismissNotification}/>
    </Panel>
}


type NotificationListProps = {
    notifications: INotification[];
    onDismiss: (notification: INotification) => void;
}

const NotificationList = ({
                              notifications, onDismiss
                          }: NotificationListProps) => {
    const theme = useTheme();

    if (!notifications.length) {
        return (
            <Stack
                verticalFill
                horizontalAlign="center"
                verticalAlign="center"
                styles={{root: {width: '100%', height: '100%'}}}>
                <Empty imageStyle={{fontSize:'8em'}}/>
            </Stack>
        )
    }

    return (
        <Stack tokens={{childrenGap: 8}}>
            <List items={notifications} onRenderCell={(notification, idx) => {
                if (notification === undefined || idx === undefined) {
                    return null;
                }
                return (
                    <div style={{paddingBottom: 8}} key={`notification-${idx}`}>
                        <Text style={{color: theme.schemes?.default?.semanticColors.bodyText}}
                              key={`notification-date-${idx}`}
                              variant={"small"}>{format(notification.dateEvent || new Date(), 'dd/MM/yyyy HH:mm')}</Text>
                        <Notification key={`notification-message-${idx}`}
                                      onDismiss={() => onDismiss(notification)}
                                      description={notification.description} name={notification.name}
                                      type={notification.type} actions={notification.actions}/>
                    </div>
                )
            }}/>
        </Stack>
    )
}