import {IColumn, Stack} from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {isTotalType, isValueType} from "./consts";
import {isAgreedAnswer, isAutoAnswer} from "../../answers";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGLInvestmentIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            const isTotal = isTotalType(i);
            if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                if (!isTotal) {
                    verifiedCoverage += i.totalIncome;
                }
            }
            if (isTotal) {
                incomeTotal += i.totalIncome;
            }
        })

        return {
            verified: incomeTotal != 0 ? verifiedCoverage / incomeTotal : 0
        };
    }

    const rebuildItems = (arr: any[]): IReport[] => {
        return (arr || []).reduce((acc, i) => {
            const isValue = isValueType(i);
            acc.push({
                ...i,
                showAttachmentIcon: true,
                showCommentsIcon: true,
                showControl: isValue,
                children: rebuildItems(i?.children)
            })

            return acc;
        }, [])
    }

    const reportItems = useMemo(() => rebuildItems(items), [items]);
    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentsIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'name' }),
                minWidth: 300,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'answerText',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl && <GeneratedAnswer
                            itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            answerControlType={AnswerControlType.WorkDoneSwitch} />}
                    </Stack>
                )
            },
            {
                key: 'totalIncome',
                name: formatMessage({ id: 'incomeTotal' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalIncome',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'frankingCredits',
                name: formatMessage({ id: 'frankingCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'frankingCredits',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'addForeignCredits',
                name: formatMessage({ id: 'addForeignCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'addForeignCredits',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'tfnCredits',
                name: formatMessage({ id: 'tfnCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'tfnCredits',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxFree',
                name: formatMessage({ id: 'taxFree' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxFree',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxExempt',
                name: formatMessage({ id: 'taxExempt' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxExempt',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxDeferred',
                name: formatMessage({ id: 'taxDeferred' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxDeferred',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'lessTaxDeferred',
                name: formatMessage({ id: 'lessTaxDeferred' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'lessTaxDeferred',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'expensesGainsDiscount',
                name: formatMessage({ id: 'expenses' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'expensesGainsDiscount',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'capital',
                name: formatMessage({ id: 'capitalGainsDiscount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capital',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'gst',
                name: formatMessage({ id: 'gst' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'gst',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxableIncome',
                name: formatMessage({ id: 'taxableIncomeIncCapGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxableIncome',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'indexedCapitalGains',
                name: formatMessage({ id: 'indexedCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'indexedCapitalGains',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'discountedCapitalGains',
                name: formatMessage({ id: 'discountedCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'discountedCapitalGains',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'otherCapitalGains',
                name: formatMessage({ id: 'otherCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'otherCapitalGains',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxableCapitalGains',
                name: formatMessage({ id: 'taxableCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxableCapitalGains',
                onRender: (item, _, column) =>  <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const reports = useMemo(() => {
        return reportItems.reduce((acc: IReport[], i: any) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
            }
            acc.push(i)
            return acc;
        }, []);
    }, [summaryUpdateTrigger]);

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        if (item.id && field) {
            item[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
            setSummaryUpdateTrigger(Date.now())
        }
    }

    return <ReportTable
        initialColumns={columns}
        header={{ rowHeight: _ExtendedHeaderRowHeight }}
        openAll={true}
        items={reports}
        groupProps={{
            mergedGroupHeader: {propertyKey: 'name'},
            summary: {
                show: true,
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    return <>
                        <Stack horizontal tokens={{childrenGap: 16}}>
                            <Stack.Item>
                                <GroupSummaryItem value={group?.summary.verified} type={'verified'}></GroupSummaryItem>
                            </Stack.Item>
                        </Stack>
                    </>
                }
            }
        }}
        isFetching={isFetching} />;
};
