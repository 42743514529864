import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {IDropdownOption, PrimaryButton, Stack} from '@fluentui/react';
import {ControlledDropdown, Modal, ModalFooter} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useCreateNewJob} from "../../hooks";
import {useGeneralYearGet} from 'pages/ClientPortal/PortalSettings/hooks/General/useGeneralYearGet';
import {useGetClientsWithoutFilter} from "pages/WorkflowPortal/Clients";
import {useGetClientFunds} from "pages/WorkflowPortal/Funds";
import {FundInfo} from "pages/WorkflowPortal/shared";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export interface ICreateJobCommand {
    clientId: number;
    fundId: number;
    year: number;
}

export const JobsAddItemModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title}: Props) => {
    const {addNewJob, isLoading: isAddingJob} = useCreateNewJob(onDismiss);
    const {control, handleSubmit, formState} = useForm<ICreateJobCommand>(DefaultFormSettings);
    const clientIdWatch = useWatch({name: 'clientId', control: control});
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: '50%'}};
    const {generalYears, isLoading} = useGeneralYearGet();
    const {allClients, isAllClientsLoading} = useGetClientsWithoutFilter();
    const {
        dataFunds,
        refetch: refetchFunds,
        isRefetching: isRefetchingFunds,
        isLoadingFunds
    } = useGetClientFunds(String(clientIdWatch || ''));
    const [funds, setFunds] = useState<FundInfo[]>();
    const onSubmit = (data: ICreateJobCommand) => {
        addNewJob(data);
    };

    const jobYearOptions = useMemo<IDropdownOption[]>(() => {
        if (generalYears?.data) {
            return generalYears.data.map((generalYear) => {
                return {
                    text: String(generalYear.year),
                    key: generalYear.year,
                };
            });
        }

        return [];
    }, [generalYears?.data]);

    const clientOptions = useMemo<IDropdownOption[]>(() => {
        if (allClients?.data) {
            return allClients.data.items.map((client) => {
                return {
                    text: String(client.name),
                    key: client.guid,
                };
            });
        }
        return [];
    }, [allClients?.data])

    const fundOptions = useMemo<IDropdownOption[]>(() => {
        if (funds) {
            return funds.map((fund) => {
                return {
                    text: String(fund.name),
                    key: fund.id,
                };
            });
        }
        return [];
    }, [clientIdWatch, funds]);

    useEffect(() => {
        if (clientIdWatch)
            refetchFunds();
        setFunds(dataFunds?.data);
    }, [clientIdWatch, dataFunds?.data]);

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title} width={150}>
            <Stack tokens={{childrenGap: 16}} styles={{root: {paddingBottom: 16}}}>
                <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign={"space-between"}>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={clientOptions || []}
                            disabled={isAllClientsLoading}
                            name='clientId'
                            label={formatMessage({id: 'clientName'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={fundOptions || []}
                            disabled={isRefetchingFunds || !clientIdWatch}
                            name='fundId'
                            label={formatMessage({id: 'fundName'})}
                            control={control}
                            rules={{
                                required: formatMessage({id: 'requiredField'}),
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack.Item styles={{root: {width: '100%'}}}>
                    <ControlledDropdown
                        options={jobYearOptions || []}
                        disabled={isLoading}
                        name='year'
                        label={formatMessage({id: 'jobYear'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                        }}
                    />
                </Stack.Item>
            </Stack>
            <Stack>
                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton disabled={!formState.isDirty || isAddingJob} onClick={handleSubmit(onSubmit)}
                                   text={formatMessage({id: 'save'})}/>
                </ModalFooter>
            </Stack>
        </Modal>
    );
});
