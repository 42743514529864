import { PropsWithChildren } from 'react';
import { useTenantInfo } from '../providers';
import { DisabledChildren } from 'components';

type TenantGuardProps = {
    behavior: 'hide' | 'disable';
    types: ('black' | 'white')[];
};

export const TenantGuard = ({ types, behavior, children }: PropsWithChildren<TenantGuardProps>) => {
    const { isBlackLabel } = useTenantInfo();

    if (types.includes('black') && isBlackLabel) {
        return <>{children}</>;
    }

    if (types.includes('white') && !isBlackLabel) return <>{children}</>;

    if (behavior === 'hide') {
        return <></>;
    }

    return <DisabledChildren disabled>{children}</DisabledChildren>;
};