import {useIntl} from "react-intl";
import {FunctionComponent, useMemo} from "react";
import {Stack, useTheme} from "@fluentui/react";
import {IJobTimesheetItem} from "./interfaces";

type JobTimeSheetTotalProps = {
    items: IJobTimesheetItem[];
}
export const JobTimesheetTotal: FunctionComponent<JobTimeSheetTotalProps> = ({items}) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const {totalTimeMin, totalTimeHours, totalCost} = useMemo(() => {
        const totalTimeMin = Math.round(items.reduce((acc, item) => acc + (item?.durationMin ? Number(item.durationMin) : 0), 0));
        const totalTimeHours = (totalTimeMin / 60).toFixed(2);
        const totalCost = (items.reduce((acc, item) => acc + (item?.cost ? Number(item.cost) : 0), 0)).toFixed(2);
        return {totalTimeMin, totalTimeHours, totalCost};
    }, [items]);
    return (
        <Stack horizontal horizontalAlign='end' styles={{root: {marginTop: 16}}} tokens={{childrenGap: 64}}>
            <Stack.Item style={{ color: theme.schemes?.default?.semanticColors.bodyText}}>
                {`${formatMessage({id: 'totalTime'})}: ${formatMessage({id: 'minOrHours'}, {
                    min: totalTimeMin,
                    hours: totalTimeHours
                })}`}
            </Stack.Item>
            <Stack.Item style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>
                {`${formatMessage({id: 'totalCost'})}: $ ${totalCost}`}
            </Stack.Item>
        </Stack>
    );
}