export * from './JobTimesheetTemplate';

export const jobCodes: { [key: number]: string } = {
    100000000: 'Pre-audit',
    1: 'Audit',
    2: 'Fundamental Queries',
    3: 'Non-Fundamental Queries',
    4: 'Administration',
    5: 'Manager Review',
    6: 'Partner Review',
    7: 'Technical Support',
    8: 'Contravention Report',
    100000003: 'Senior Manager Review',
    100000002: 'Audit Finalisation'
}