import {
    AnimationStyles,
    FontSizes,
    FontWeights,
    INavLink,
    INavLinkGroup,
    mergeStyleSets,
    Nav,
    Theme,
    useTheme
} from '@fluentui/react';
import {RouterPaths} from 'navigation';
import React, {FunctionComponent, MouseEvent, useContext, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { ThemeContext } from '@fluentui/react/lib-amd';

export interface IMenuLink extends INavLink {
    isHidden?: boolean;
}

interface MenuProps {
    links: IMenuLink[];
    selectedKey?: string;
    isCollapsed: boolean;
    toggleIsCollapsed: () => void;
}

const iconStyles = {root: {width: 30, justifyContent: 'center', transition: 'transform 0.18s linear 0s'}};
const setIconProps: (link: INavLink) => INavLink = (link: INavLink) => ({
    ...link,
    iconProps: {...link.iconProps, styles: iconStyles},
});

const getNavStyles = (theme: Theme, isCollapsed: boolean) => ({
    root: {
        boxSizing: 'border-box',
        height: '100%',
        width: isCollapsed ? 200 : 48,
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'width 0.1s ease-in-out'
    },
    groupContent: {
        marginBottom: 0,
    },
    chevronIcon: {
        left: 16,
    },

    chevronButton: {
        width: '100%',
        backgroundColor: 'transparent',
        selectors: {
            '>i': {
                right: 16,
                fontSize: FontSizes.size10,
                fontWeight: FontWeights.bold,
                left: 'auto',
                display: !isCollapsed ? 'none' : undefined,
                color: theme.palette.themePrimary
            },
        },
    },
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
        fontWeight: FontWeights.semibold,

        selectors: {
            '.ms-Nav-chevronButton ~ &': {
                paddingLeft: 0,
            },
            '::after': {
                background: 'rgba(124, 124, 124, 0.2)',
                borderRadius: '0 2px 2px 0',
                borderLeft: `4px solid ${theme.palette.themePrimary}`,
            },

            '.ms-Nav-navItem .ms-Nav-navItems &': {
                fontSize: FontSizes.size12,
                fontWeight: FontWeights.regular,
            },
        },
    },
    linkText: {
        color: theme.schemes?.default?.semanticColors.bodyText ?? theme.semanticColors.bodyText,
    },
});

export const VerticalMenu: FunctionComponent<MenuProps> = (props: MenuProps) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const theme = useTheme();
    const themeContext = useContext(ThemeContext);
    const navStyles = useMemo(() => getNavStyles(theme, !props.isCollapsed), [theme, props.isCollapsed]);

    const currentKey = useMemo(() => {
        switch (pathname) {
            case RouterPaths.portalSettings.auditReadyConnections:
                return {key: 'audit-ready-connections'};
            case RouterPaths.workflow.dashboard:
                return {key: 'workflow-dashboard'};
            default:
                return props?.links?.find((link) => link.url === pathname);
        }
    }, [pathname, props?.links]);

    const filteredLinks = useMemo(() => {
        const links = props.links?.filter(x => !x.isHidden);

        links?.forEach(value => value.links = value.links?.filter(x => !x.isHidden));

        return links;
    }, [props.links])


    const navLinkGroups: INavLinkGroup[] = useMemo(
        () => [
            {
                links: filteredLinks?.map(setIconProps),
            },
            {
                links: [
                    {
                        key: 'collapse',
                        onClick: props.toggleIsCollapsed,
                        target: "",
                        name: '',
                        url: '',
                        iconProps: {
                            iconName: 'DoubleChevronLeftMed',
                            styles: iconStyles,
                            className: 'toggle-icon',
                        },
                    },
                ],
            },
        ],
        [props?.links, props?.toggleIsCollapsed]
    );

    const onLinkClick = (ev?: MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        if (item?.url) {
            navigate({pathname: item?.url!});
        }
    };

    const classNames = mergeStyleSets({
        collapsed: {
            selectors: {
                '.ms-Nav-linkText': {display: 'none'},
                '.toggle-icon': {transform: 'rotate(180deg)'},
                ...AnimationStyles.slideLeftIn40
            },
        }
    });


    return (
        <Nav
            onLinkClick={onLinkClick}
            selectedKey={props?.selectedKey || currentKey?.key}
            className={props.isCollapsed ? classNames.collapsed : undefined}
            styles={navStyles}
            groups={navLinkGroups}
        />
    );
};
