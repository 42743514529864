import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";


type MutateDataType = {
    isAdminFlow: boolean;
    clientId: number;
    codes: string[];
};

function useUpdateClientsApiAuth() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();
    
    const {mutate: updateClientApiAuth, isLoading: isUpdatingClientApiAuth} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/apiAuth`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getClientsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Client Api information'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
             
        }
    );

    return {updateClientApiAuth, isUpdatingClientApiAuth};
}

export {useUpdateClientsApiAuth};
