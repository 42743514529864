import {useMutation} from "react-query";
import axios from "axios";
import {commonHeaders, Context} from "../../../utils";
import {useContext} from "react";
import {useProcedureContext} from "../components";


type MutateDataType = {
    id: number;
    itemId: number;
    jobId: number;
    fundId?: number;
    hinNumber: string | null;
    postCode: string | null;
    notes: string | null;
    perm: boolean;
    isSimpleNote: boolean;

}

function useUpdateHinTableItem(callback?: () => void) {
    const context = useContext(Context);
    const {} = useProcedureContext();
    const {mutate: update, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.post(`${context?.config?.proceduresUrl}/api/1.0/procedures/hinTable`, data, commonHeaders()), {
            onSuccess: () => {
                if (callback)
                    callback();
            }
        }
    );


    return {update, isUpdating};
}

export {useUpdateHinTableItem};