import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {DefaultButton, Dropdown, Icon, IconButton, IDropdownOption, Stack, Text, useTheme} from '@fluentui/react';
import {IButtonStyles} from '@fluentui/react/lib/components/Button/Button.types';

interface Props {
    pageSizeOptions?: number[];
    pageSize?: number;
    onChange?: (pageSize: number, page: number) => void;
    page?: number;
    total?: number;
    itemsCount?: number;
}

export const Pagination: FunctionComponent<Props> = (props: Props) => {
    const { itemsCount, pageSizeOptions = [5, 10, 25, 50, 100] } = props;
    const { onChange } = props;
    const theme = useTheme();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setPage(props.page ?? 1);
    }, [props.page]);
    useEffect(() => {
        setPageSize(props.pageSize ?? 50);
    }, [props.pageSize]);
    useEffect(() => {
        setTotal(props.total ?? 1);
    }, [props.total]);
    useEffect(() => {
        setTotalPages(Math.ceil((props.total ?? 1) / (props.pageSize ?? 50)));
    }, [props.total, props.pageSize]);

    const pageSizeDropdownOptions: IDropdownOption[] = pageSizeOptions.map((ps) => ({ text: ps.toString(), key: ps }));
    const pageButtonStyles: IButtonStyles = {
        root: { padding: 0, minWidth: 32, borderColor: 'transparent', color: theme.schemes?.default?.semanticColors.bodyText },
        rootHovered: { color: theme.schemes?.default?.semanticColors.bodyText },
        rootChecked: { color: theme.schemes?.default?.semanticColors.bodyText },
        rootCheckedHovered: { color: theme.schemes?.default?.semanticColors.bodyText },
        rootPressed: { color: theme.schemes?.default?.semanticColors.bodyText },
    };

    const handlePageChange = (value: number) => {
        setPage(value);
        if (onChange) {
            onChange(pageSize, value);
        }
    };

    const handlePageSizeChange = (value: number) => {
        setPageSize(value);
        setPage(1);
        if (onChange) {
            onChange(value, 1);
        }
    };

    const [pagesToRender, setPagesToRender] = useState<number[]>([]);
    const [skipStartPages, setSkipStartPages] = useState<boolean>(false);
    const [skipEndPages, setSkipEndPages] = useState<boolean>(false);
    useEffect(() => {
        const newPagesToRender: number[] = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                newPagesToRender.push(i);
            }
        } else {
            const d = 2;
            const start: number = page <= d ? 1 : page > d && page < totalPages - d ? page - d : totalPages - 2 * d;
            setSkipStartPages(page > d);
            setSkipEndPages(page < totalPages - d);
            const end: number = start + 2 * d >= totalPages ? totalPages : start + 2 * d;

            for (let i = start; i <= end; i++) {
                newPagesToRender.push(i);
            }
        }

        setPagesToRender(newPagesToRender);
    }, [page, totalPages]);

    return (
        <Stack horizontal horizontalAlign='space-between'>
            <Text styles={{ root: { display: 'flex', alignItems: 'center', color: theme.schemes?.default?.semanticColors.bodyText } }}>
                {itemsCount ?? 0} of {total}
            </Text>
            <Stack horizontal>
                <IconButton styles={{ iconDisabled: { color: theme.palette.themePrimary }, }} disabled={page === 1} iconProps={{ iconName: 'DoubleChevronLeftMed' }} onClick={() => handlePageChange(1)} />
                <IconButton styles={{ iconDisabled: { color: theme.palette.themePrimary }, }} disabled={page === 1} iconProps={{ iconName: 'ChevronLeft' }} onClick={() => handlePageChange(page - 1)} />

                {skipStartPages ? (
                    <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { width: 32, height: 32 } }}>
                        <Icon iconName='More' />
                    </Stack>
                ) : null}

                {pagesToRender.map((pageToRender, index) => (
                    <DefaultButton
                        key={index}
                        styles={pageButtonStyles}
                        checked={pageToRender === page}
                        text={pageToRender.toString()}
                        onClick={() => handlePageChange(pageToRender)}
                    />
                ))}

                {skipEndPages ? (
                    <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { width: 32, height: 32 } }}>
                        <Icon iconName='More' />
                    </Stack>
                ) : null}

                <IconButton
                    styles={{ iconDisabled: { color: theme.palette.themePrimary }, }}
                    disabled={page === totalPages}
                    iconProps={{ iconName: 'ChevronRight' }}
                    onClick={() => handlePageChange(page + 1)}
                />
                <IconButton
                    styles={{ iconDisabled: { color: theme.palette.themePrimary }, }}
                    disabled={page === totalPages}
                    iconProps={{ iconName: 'DoubleChevronRight12' }}
                    onClick={() => handlePageChange(totalPages)}
                />
                <Dropdown
                    options={pageSizeDropdownOptions}
                    dropdownWidth={55}
                    styles={{
                        dropdownOptionText: { color: theme.schemes?.default?.semanticColors.bodyText },
                        dropdown: {
                            color: theme.schemes?.default?.semanticColors.bodyText, selectors: {
                                '&:hover .ms-Dropdown-title': { color: theme.schemes?.default?.semanticColors.bodyText },
                                '&:active .ms-Dropdown-title': { color: theme.schemes?.default?.semanticColors.bodyText },
                                '&:focus .ms-Dropdown-title': { color: theme.schemes?.default?.semanticColors.bodyText },
                            }, },
                        dropdownItem: {
                            selectors: {
                               '&:hover:focus': { color: theme.schemes?.default?.semanticColors.bodyText },
                           }, },
                        title : { color: theme.schemes?.default?.semanticColors.bodyText }
                    }}
                    selectedKey={pageSize}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                        handlePageSizeChange(option?.key as number)
                    }
                />
            </Stack>
        </Stack>
    );
};
