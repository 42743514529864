import { FunctionComponent } from 'react';
import { Stack, Text, useTheme } from '@fluentui/react';

interface IImportReportErrorProps {
    uploadData: any;
}

export const ImportReportError: FunctionComponent<IImportReportErrorProps> = ({ uploadData }) => {
    const theme = useTheme();
    const errors = uploadData?.data?.errors;
    const errorHeaders = typeof errors === 'object' ? Object.keys(errors) : null;
    return (
        <Stack styles={{ root: { color: theme.semanticColors.errorText } }} tokens={{ childrenGap: 16 }}>
            <Text styles={{ root: { color: theme.semanticColors.errorText, ...theme.fonts.large } }}>{uploadData?.data?.title}</Text>
            {errors && errorHeaders?.length
                ? errorHeaders.map((eHeader, idx) =>
                      errors[eHeader]?.length ? (
                          <Stack key={`upload-error-header-${eHeader}-${idx}`}>
                              <Text styles={{ root: { color: theme.semanticColors.errorText, ...theme.fonts.mediumPlus } }}>{eHeader}</Text>
                              <ul>
                                  {errors[eHeader].map((e: string, eIdx: number) => (
                                      <li key={`upload-error-row-${eHeader}-${idx}-${eIdx}`}>{e}</li>
                                  ))}
                              </ul>
                          </Stack>
                      ) : null
                  )
                : null}
        </Stack>
    );
};
