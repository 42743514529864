import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import {SuggestedTestingType} from "../../enums";
import axios from "axios";
import {IJobSuggestedTestingItem} from "./useGetJobSuggestedTesting";

type MutateDataType = {
    jobId: number;
    type: SuggestedTestingType;
    isCustom:boolean;
}

function useCreateJobSuggestedTestingItem() {
    const context = useContext(Context);
    const {
        mutate: create,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post<IJobSuggestedTestingItem>(`${context?.config?.suggestedTestingUrl}/api/1.0/jobs/${data.jobId}/suggestedTesting`, data));

    return {create, isCreating};
}

export {useCreateJobSuggestedTestingItem};