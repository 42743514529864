export enum AuditReportType {
	Qualified,
	Unqualified,
	PartAQualified,
	PartBQualified
}

export interface IAuditReportOptions {
	arDate?: Date,
	auditDate?: Date
	auditReportType: AuditReportType,
	auditReportTypeValue: string,
	complianceQualificationData?: string,
	complianceQualificationDataReadOnly: boolean,
	complianceQualificationOmls: Array<any>
	includeComparativeInfo: boolean;
	isArDateEnabled: boolean;
	isArDateVisible: boolean;
	isQualified: boolean;
	qualificationData?: string;
	qualificationDataOmls: Array<any>;
	qualificationDataReadOnly: boolean;
	skipArGeneration: boolean
}