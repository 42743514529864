import axios from 'axios';
import {DashboardTabsEnum} from '../enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface IClientInfo {
    id: number;
    name: string;
    idGuid: string;
}

interface Props {
    dashboardTab: DashboardTabsEnum;
}

function useGetShortClientInfos({dashboardTab}: Props) {
    const context = React.useContext(Context);

    const {data: shortClientInfos, isLoading: isShortClientInfosLoading} = useQuery(
        ['getShortClientInfos', dashboardTab],
        () => axios.get<IClientInfo[]>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/shortClientInfos`, {
            ...commonHeaders(),
            params: {dashboardTab}
        })
            .then(response => response.data.map(d => ({key: d.idGuid, text: d.name}))),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {shortClientInfos, isShortClientInfosLoading};
}

export {useGetShortClientInfos};