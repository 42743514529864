import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from "../../../utils";
import { IWorkContext, PortalRole } from "../models";
import { _GetWorkContextQueryKey } from "./index";

function useGetWorkContext() {
	const context = useContext(Context);

	const {data, isLoading} = useQuery(
		[_GetWorkContextQueryKey],
		() => axios.get<IWorkContext>(`${context?.config?.jobUrl}/api/1.0/context`, commonHeaders())
			.then(response => {
				const result = response.data;
				
				// override roles if it's needed for local testing
				// if (result?.user?.identityUser) {
					// result.isCurrentUserJobAuditor = true;
					// result.isCurrentUserJobManager = true;
					// result.isCurrentUserJobPartner = true;
					// result.user.identityUser.roles = [PortalRole.BasicUser]
				// }
				return result;
			}),
		{
			enabled: !!context?.config,
		}
	);

	return {workContext: data, isWorkContextLoading: isLoading};
}

export {useGetWorkContext};