import React from "react";
import {commonHeaders, Context} from "../../../../utils";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";

export interface IAvailableAnswerOptions {
    valid: string[];
    invalid: string[];
}

function useGetAllAnswerOptions() {
    const context = React.useContext(Context);

    const {data: response, isLoading: isLoading} = useQuery(
        ['getAllAnswerOptions'],
        () => axios.get<IAvailableAnswerOptions>(
            `${context?.config?.answersUrl}/api/1.0/answers/all`,
            commonHeaders()
        ),
        {
            enabled: !!context?.config?.answersUrl && !!context.tenantId,
            
            retry: false,
        }
    );

    return {response, isLoading};
}

export {useGetAllAnswerOptions};