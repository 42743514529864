import axios from 'axios';
import {DetailedSoftwareType, SoftwareType} from 'enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

export type ClientAuditInfo = {
    softwareType: SoftwareType;
    detailedSoftwareType: DetailedSoftwareType | null;
    turnAroundTime: string | null;
    isDraftAuditReport: boolean | null;
    titleSearchConduct: boolean;
    asicSearchConduct: boolean;

};

function useGetClientAuditInfoByGuid(clientGuid: string, loadAutomatically?:  boolean) {
    const context = React.useContext(Context);

    const {data: clientAuditInfo, isFetching: isLoading, refetch} = useQuery(
        ['getClientByGuid', clientGuid],
        () => axios.get(`${context?.config?.clientUrl}/api/1.0/Clients/${clientGuid || ''}`, commonHeaders())
            .then(resp => resp.data.auditInfo as ClientAuditInfo),
        {
            enabled: !!loadAutomatically && !!context?.config?.clientUrl && !!context.tenantId && !!clientGuid,
        }
    );

    return {clientAuditInfo, isLoading, refetch};
}

export {useGetClientAuditInfoByGuid};
