import { PropsWithChildren } from 'react';
import { PortalRole, useWorkContext } from '../providers';
import { DisabledChildren } from 'components';

type PortalRoleGuardProps = {
    roles: PortalRole[];
    behavior: 'hide' | 'disable';
    includeSuperAdministrator?: boolean;
};

export const PortalRoleGuard = ({
    roles,
    behavior,
    includeSuperAdministrator = true,
    children,
}: PropsWithChildren<PortalRoleGuardProps>) => {
    const { isInRole, isInRoleOrSuperAdministrator } = useWorkContext();
    const hasAccess = () => (includeSuperAdministrator ? isInRoleOrSuperAdministrator(...roles) : isInRole(...roles));

    if (hasAccess()) {
        return <>{children}</>;
    }

    if (behavior === 'hide') {
        return <></>;
    }

    return <DisabledChildren disabled>{children}</DisabledChildren>;
};