import {ControlType} from 'enums/ControlType';
import React, {FunctionComponent, MutableRefObject, useEffect} from 'react';
import {ProceduresContent, useSectionContext} from '../..';
import {ReportContent} from '../../ReportContent';
import {FundDetailsTemplate} from "../../templates/procedures/FundDetailsTemplate";
import {
    AuditReadySummarySubSection,
    EventsSummaryProcedureTemplate,
    JobTimesheetTemplate,
    SuggestedTestingProcedureTemplate
} from "../../templates/procedures";
import {ValidationsContent} from "../../templates/validations";
import {useJobContext} from "../../../JobPortalLayoutPage";
import { FeeCalculationContent } from '../../templates/feeCalculation/FeeCalculationContent';

export const SectionContent: FunctionComponent<{ childRef: MutableRefObject<null> }> = ({childRef}) => {
    const {section} = useSectionContext();
    const {jobId} = useJobContext();
    const isReport = section.controlType === ControlType.ReportStatement;

    if (isReport) return <ReportContent section={section} jobId={jobId} ref={childRef}/>;

    switch (section.controlType) {
        case ControlType.FundDetailsForm:
            return <FundDetailsTemplate ref={childRef}/>
        case ControlType.ValidationError:
            return <ValidationsContent section={section} ref={childRef}/>
        case ControlType.SuggestedTesting:
            return <SuggestedTestingProcedureTemplate ref={childRef}/>
        case ControlType.JobTimeSheet:
            return <JobTimesheetTemplate ref={childRef}/>
        case ControlType.EventsSummary:
            return <EventsSummaryProcedureTemplate section={section} ref={childRef}/>
        case ControlType.AuditReadySummarySection:
            return <AuditReadySummarySubSection section={section} ref={childRef}/>
        case ControlType.FeeCalculation:
                return <FeeCalculationContent section={section} ref={childRef}/>
        default:
            return <ProceduresContent section={section} ref={childRef}/>;
    }
};
