import {FunctionComponent} from 'react';
import {FundsWorkflowPage} from "pages/WorkflowPortal/Funds";

interface IFundTabProps {
    clientGuid?: string | null;
}

export const FundsTab: FunctionComponent<IFundTabProps> = (props: IFundTabProps) => {
    return (
        <FundsWorkflowPage clientGuid={props.clientGuid}/>
    );
};