import {IColumn, Stack} from '@fluentui/react';
import { SanitizedText } from 'components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {ReportValidationCell} from "../shared";

export const BGLInvestmentDisposal = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.valueType !== 'Heading' && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.valueType !== 'Heading' && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'investment' }),
                minWidth: 200,
                maxWidth: 256,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'answerText',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== 'Heading' && item?.valueType !== 'Total' && <GeneratedAnswer itemId={item?.id} tableType={TableType[item?.reportType as keyof typeof TableType]} value={item?.answerText} answerControlType={AnswerControlType.WorkDoneSwitch} />}
                    </Stack>
                )
            },
            {
                key: 'disposalMethod',
                name: formatMessage({ id: 'disposalMethod' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalMethod',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'unitsSold',
                name: formatMessage({ id: 'unitsSold' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unitsSold',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'purchaseCost',
                name: formatMessage({ id: 'purchaseCost' }),
                minWidth: 80,
                maxWidth: 128,
                fieldName: 'purchaseCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'costBaseAdjustments',
                name: formatMessage({ id: 'costBaseAdjustments' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'costBaseAdjustments',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'adjustedCostBase',
                name: formatMessage({ id: 'adjustedCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'adjustedCostBase',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'consideration',
                name: formatMessage({ id: 'consideration' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'consideration',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'totalProfLoss',
                name: formatMessage({ id: 'totalProfLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'totalProfLoss',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxableProfLoss',
                name: formatMessage({ id: 'taxableProfLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxableProfLoss',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'nonTaxableProfLoss',
                name: formatMessage({ id: 'nonTaxableProfLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'nonTaxableProfLoss',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'accountingProfLoss',
                name: formatMessage({ id: 'accountingProfLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingProfLoss',
                onRender: (item, _, column) => <SanitizedText data={item.valueType != 'Heading' ? item[column?.fieldName || 0] : null} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} openAll={true} isFetching={isFetching} />;
};
