import React, {FunctionComponent, useEffect, useState} from 'react';
import {Attachment, ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {AnswerControlType, TableType} from "../../../../../enums";
import {useGetJobReviewDetails, useUpdateJobReviewDetails} from "../../../hooks";
import {useJobContext} from "../../../JobPortalLayoutPage";
import {Path, useForm} from "react-hook-form";
import {IJobReviewDetails} from "../../../interfaces";
import {DefaultFormSettings} from "../../../../../constants";
import {CalendarAnswer, TextBoxAnswer} from "../answers";
import {Spinner, SpinnerSize, Stack} from "@fluentui/react";

interface ITestSummaryReviewItem {
    id: number;
    jobId: number;
    order: number;
    answerControlType: AnswerControlType;
    hasAttachments: boolean;
    hasComments: boolean;
    showAttachment: boolean;
    tabSectionId: number;
    tableType: TableType;
    title: string;
    tempTitle: string;
    reference: string;
}

const camelCase = (str: string): string => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
};

export const TestSummaryReviewProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {jobGuid} = useJobContext();
    const {jobReviewDetails, isFetching} = useGetJobReviewDetails(jobGuid);
    const {update, isUpdating} = useUpdateJobReviewDetails();

    const {setValue, getValues} = useForm<IJobReviewDetails>({
        ...DefaultFormSettings
    });
    
    const [isDataSet, setIsDataSet] = useState<boolean>(false);

    const handleOnChange = (answer: any, property: string) => {
        setValue(camelCase(property) as Path<IJobReviewDetails>, answer instanceof Date ? answer.toISOString() : answer);
        update(getValues());
    };

    const getPropertyValue = (name: string) => {
        const propertyName: Path<IJobReviewDetails> = (name === 'ABN' ? name.toLowerCase() : camelCase(name)) as Path<IJobReviewDetails>;

        const value = getValues(propertyName);

        return value;
    }


    useEffect(() => {
        if (!jobReviewDetails)
            return;

        setValue('id', jobReviewDetails.id);
        setValue('guid', jobReviewDetails.guid);
        setValue('abn', jobReviewDetails.abn);
        setValue('valueOfTotalGrossAssets', jobReviewDetails.valueOfTotalGrossAssets);

        setValue('partnerReview', jobReviewDetails.partnerReview);
        setValue('managerReview', jobReviewDetails.managerReview);
        setValue('contraventionLodged', jobReviewDetails.contraventionLodged);
        setValue('contraventionEventForReview', jobReviewDetails.contraventionEventForReview);
        setValue('draftContraventionComplete', jobReviewDetails.draftContraventionComplete);
        setValue('draftContraventionReviewed', jobReviewDetails.draftContraventionReviewed);
        
        setIsDataSet(true)
    }, [jobReviewDetails]);
    

    return (
         isDataSet ? <ProceduresDataTable
            items={items}
            isLoading={isLoading || isFetching}
            isHeaderVisible={false}
            columns={[
                {
                    key: 'title',
                    name: '',
                    fieldName: 'title',
                    onRender: (item: ITestSummaryReviewItem) => <SanitizedText data={item?.title}/>,
                    minWidth: 250
                },
                {
                    key: 'attachment',
                    name: '',
                    fieldName: 'attachment',
                    onRender: (item: ITestSummaryReviewItem) => (item.showAttachment && (
                        <Attachment itemId={item.id} hasAttachments={item.hasAttachments}/>)),
                    minWidth: 35,
                    maxWidth: 35
                },
                {
                    key: 'text',
                    name: '',
                    fieldName: 'text',
                    onRender: (item: ITestSummaryReviewItem) => (
                        <>
                            {item.answerControlType === AnswerControlType.TextBox && (<TextBoxAnswer
                                value={getPropertyValue(item.reference)}
                                disabled/>)}
                            {item.answerControlType === AnswerControlType.Calendar && (<CalendarAnswer
                                onChange={answer => handleOnChange(answer, item.reference)}
                                disabled={isUpdating}
                                value={getPropertyValue(item.reference)}/>)}
                        </>
                    ),
                    minWidth: 250,
                    maxWidth: 250
                },
            ]}
        /> : <Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
             <Spinner size={SpinnerSize.large} label={"Loading"}/>
         </Stack>
    );
};