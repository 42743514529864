import { Stack, useTheme, Spinner, Text, Dropdown, PrimaryButton, Icon, FontWeights } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IRequiredDocumentsInfo, useGetTabRequiredDocuments } from '../../hooks/useGetTabRequiredDocuments';


interface IRequiredDocumentsInfoProps {
    tabCommentId: number;
}

interface IWizardQuestionInfoProps {
    info: IRequiredDocumentsInfo;
}


const RequiredDocumentInfo: FunctionComponent<IWizardQuestionInfoProps> = ({ info }) => {
    const theme = useTheme();

    const [isOpened, { toggle: toggleIsOpened }] = useBoolean(false);
    const expanderIcon = isOpened ? 'ChevronUp' : 'ChevronDown';

    return (
        <Stack>
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Icon iconName={expanderIcon} style={{
                    alignSelf: 'center',
                    fontWeight: FontWeights.bold,
                    color: theme.palette.themePrimary,
                    fontSize: theme.fonts.tiny.fontsize,
                    flexShrink: 0
                }} onClick={toggleIsOpened}/>
                <Text theme={theme.schemes?.default} styles={{ root: { fontWeight: 'bold' } }}>{info.description}</Text>
            </Stack>
            {isOpened && <ol style={{ marginLeft: '2%', color: theme.schemes?.default?.semanticColors.bodyText }}>{info.items.map(x => <li><Text theme={theme.schemes?.default}>{x}</Text></li>)}</ol>}
        </Stack>
    );
}

export const RequiredDocumentsInfo: FunctionComponent<IRequiredDocumentsInfoProps> = ({ tabCommentId }) => {
    const { formatMessage, formatDate } = useIntl();
    const theme = useTheme();

    const { response, isLoading } = useGetTabRequiredDocuments(tabCommentId);

    return (
        <>
            {isLoading && response ? <Spinner /> :
                <Stack tokens={{ childrenGap: 16 }}>
                    <Text theme={theme.schemes?.default}>{formatMessage({id: 'requiredDocuments'})}</Text>
                    {response?.map(x => <RequiredDocumentInfo info={x} />)}
                </Stack>}
        </>
    );
}