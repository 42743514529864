import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export interface IPdfWorkpaper{
    id: number;
    fileName: string;
    downloadUrl: string;
}


interface Props{
    year: number;
}

function useGetPdfWorkpapersByYear(
    {
        year
    }: Props
) {
    const context = React.useContext(Context);

    const { data: pdfWorkpapersData, isLoading, isFetching, refetch } = useQuery(
        ['getPdfWorkpapersByYear'],
        () => axios.get<IPdfWorkpaper[]>(`${context?.config?.annotationsUrl}/api/1.0/pdfWorkpapers`, {
            ...commonHeaders(),
            params: {
                year
            },
        }),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId,
        }
    );


    return { pdfWorkpapersData, isLoading, isFetching, refetch };
}

export { useGetPdfWorkpapersByYear };