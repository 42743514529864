import { FunctionComponent } from 'react';
import { Checkbox, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { getGrouppedProcedures } from 'utils';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { SanitizedText } from 'components';


export const CollapsableGridProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const { formatMessage } = useIntl();
  const { procedures, groups } = getGrouppedProcedures(items);
  
  return (
    <ProceduresDataTable
      items={procedures}
      contextMenuOptions={{ disable: true }}
      isLoading={isLoading}
      groups={groups}
      columns={[
        {
          key: 'specificCriteria	',
          name: formatMessage({ id: 'specificCriteria' }),
          minWidth: 250,
          fieldName: 'specificCriteria',
          onRender: (items) => <SanitizedText data={items?.criteria} />,
        },
        {
          key: 'auditorsReportPartBSISASISR',
          name: formatMessage({ id: 'auditorsReportPartBSISASISR' }),
          minWidth: 250,
          maxWidth: 300,
          fieldName: 'auditorsReportPartBSISASISR',
          onRender: (items) => <SanitizedText data={items?.acr} />,
        },
        {
          key: 'ACRSISASISR',
          name: formatMessage({ id: 'ACRSISASISR' }),
          minWidth: 250,
          maxWidth: 300,
          fieldName: 'ACRSISASISR',
          onRender: (items) => <SanitizedText data={items?.partB} />,
        },
        {
          key: 'considered',
          name: formatMessage({ id: 'considered' }),
          minWidth: 80,
          maxWidth: 80,
          fieldName: 'considered',
          onRender: (items) => items.showCheckBox ? (
            <Stack verticalAlign='center' horizontalAlign='center' grow={1}>
              <Checkbox checked={items.defaultAnswerCheckBox} />
            </Stack>
          ) : null,
        },
      ]}
    />
  );
};