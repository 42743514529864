import { FunctionComponent, MouseEventHandler } from 'react';
import { DefaultButton, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { Modal } from 'components';

interface ILockUnlockImportProps {
    isJobLocked: boolean;
    disabled: boolean;
    onUnlock: MouseEventHandler<HTMLButtonElement>;
    onLock: MouseEventHandler<HTMLButtonElement>;
}

interface IDeleteAllModalContentProps extends ILockUnlockImportProps {
    onCancel: MouseEventHandler<HTMLButtonElement>;
}

export const LockUnlockImport: FunctionComponent<ILockUnlockImportProps> = ({ isJobLocked, disabled, onUnlock, onLock }) => {
    const { formatMessage } = useIntl();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    return (
        <Stack horizontal horizontalAlign='end'>
            <DefaultButton text={formatMessage({ id: isJobLocked ? 'unlock' : 'lock' })} disabled={disabled} onClick={toggleOpenModal} />
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} width={400} minWidth={400}>
                <LockUnlockImportModalContent
                    isJobLocked={isJobLocked}
                    disabled={disabled}
                    onUnlock={onUnlock}
                    onLock={onLock}
                    onCancel={toggleOpenModal}
                />
            </Modal>
        </Stack>
    );
};

const LockUnlockImportModalContent: FunctionComponent<IDeleteAllModalContentProps> = ({
    isJobLocked,
    disabled,
    onUnlock,
    onLock,
    onCancel,
}) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Text>{formatMessage({ id: isJobLocked ? 'areYouSureYouWantToUnlockJob' : 'areYouSureYouWantToLockJob' })}</Text>
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                <DefaultButton text={formatMessage({ id: 'cancel' })} disabled={disabled} onClick={onCancel} />
                <PrimaryButton
                    text={formatMessage({ id: isJobLocked ? 'unlock' : 'lock' })}
                    disabled={disabled}
                    onClick={isJobLocked ? onUnlock : onLock}
                />
            </Stack>
        </Stack>
    );
};
