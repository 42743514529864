import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';

function useEditJobImportDataLock() {
    const context = React.useContext(Context);

    const { mutate: lockImportData, isLoading, isSuccess } = useMutation((guid: string | number) =>
        axios.put(`${context?.config?.jobUrl}/api/1.0/job/${guid}/lock`, null, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getJobImportData'),
        }
    );

    return { lockImportData, isLoading, isSuccess };
}

export { useEditJobImportDataLock };