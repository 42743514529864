import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import { useNotifications } from '../../../../../components/notifications';
import { useIntl } from 'react-intl';
import { MessageBarType } from '@fluentui/react';

export interface ICreateJob {
    fundId: number;
    year: number;
    fundGuid: string;
    clientId?: number;
    fundName?: string;
}

function useCreateJob() {
    const context = React.useContext(Context);

    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: createJob, isLoading, isSuccess } = useMutation((data: ICreateJob) =>
        axios.post(`${context?.config?.jobUrl}/api/1.0/job`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getFundJobs');

                showNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'jobCreated' })
                });
            }
        }
    );

    return { createJob, isLoading, isSuccess };
}

export { useCreateJob };