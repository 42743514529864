import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";

export interface IClientPortalTab {
    description: string;
    hideInTabs: boolean;
    comment?: string;
    id:number;
    tabCaption: string;
}

function useGetClientPortalTabs(jobId: number) {
    const context = useContext(Context);
    const {
        data: response,
        isLoading,
        isFetching
    } = useQuery(['getClientPortalTabs', jobId], () => axios.get<IClientPortalTab[]>(`${context?.config?.tabsUrl}/api/1.0/tabs/clientPortalTabs?jobId=${jobId}`, commonHeaders()).then(resp => resp.data), {
        enabled: !!context?.config?.tabsUrl && !!jobId
    });

    return {response, isLoading, isFetching};
}

export {useGetClientPortalTabs};