import * as React from 'react';
import {FunctionComponent} from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { PageWithBreadcrumb } from 'components';
import { RouterPaths } from 'navigation';

export const CreditNotesLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'creditNotes',
                key: RouterPaths.invoices.creditNotes,
                text: formatMessage({ id: 'creditNotes' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};