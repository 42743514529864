import {useContext} from "react";
import {Context, queryClient} from "../../../../../../../utils";
import {useMutation} from "react-query";
import {IExportOptionInfo, IScheduledExportInfo} from "../interfaces";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    sentEmail: boolean;
    options: IExportOptionInfo;
}

function useScheduleExport() {
    const context = useContext(Context);

    const {
        mutate: create,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post<IScheduledExportInfo>(`${context?.config?.exportUrl}/api/1.0/jobs/${data.jobId}/export/scheduled`, data).then(resp => resp.data), {
        onSuccess: (data, variables, context) => queryClient.invalidateQueries(['getJobSchedules', variables.jobId])
    });

    return {create, isCreating};
}

export {useScheduleExport};