import * as React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { IColumn, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { DefaultFormSettings } from 'constants/forms';
import { Card, ControlledNumberField, DataTable } from 'components';
import { useGetMaterialityCalculator } from '../hooks/MaterialityCalculator/useGetMaterialityCalculator';
import { useUpdateMaterialityCalculator } from '../hooks/MaterialityCalculator/useUpdateMaterialityCalculator';

interface IMaterialityCalculatorSettingsForm {
    materialityLevelValue: string;
    clearlyTrivialValue: string;
}

export const MaterialityCalculatorSettings = () => {
    const { calcMateriality, getMaterialityCalculatorLoading } = useGetMaterialityCalculator();
    const { formatMessage } = useIntl();
    const { control, setValue, formState, handleSubmit } = useForm<IMaterialityCalculatorSettingsForm>({
        ...DefaultFormSettings,
        defaultValues: {
            materialityLevelValue: '',
            clearlyTrivialValue: '',
        },
    });

    React.useEffect(() => {
        if (calcMateriality?.data) {
            setValue('materialityLevelValue', calcMateriality?.data.materialityLevelValue);
            setValue('clearlyTrivialValue', calcMateriality?.data.clearlyTrivialValue);
        }
    }, [calcMateriality?.data, setValue]);

    const { updateMaterialityCalculator } = useUpdateMaterialityCalculator();

    const items: Array<{
        materialityCalculatorField: string;
        key: keyof IMaterialityCalculatorSettingsForm;
    }> = [
        {
            materialityCalculatorField: formatMessage({ id: 'materialityLevelFinancialMisstatement' }),
            key: 'materialityLevelValue',
        },
        {
            materialityCalculatorField: formatMessage({ id: 'clearlyTrivialFinancialMisstatement' }),
            key: 'clearlyTrivialValue',
        },
    ];

    const columns: IColumn[] = [
        {
            key: 'materialityCalculatorField',
            fieldName: 'materialityCalculatorField',
            name: formatMessage({ id: 'materialityCalculatorField' }),
            minWidth: 100,
            onRender: (item) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item.materialityCalculatorField}
                </Text>
            ),
        },
        {
            key: 'percentageApplied',
            fieldName: 'key',
            name: formatMessage({ id: 'percentageApplied' }),
            minWidth: 200,
            maxWidth: 300,
            onRender: (item) => <ControlledNumberField name={item.key} control={control} borderless suffix='%' />,
        },
    ];

    return (
        <>
            {!getMaterialityCalculatorLoading && (
                <Card>
                    <Stack tokens={{ childrenGap: 16 }}>
                        <DataTable
                            sortableColumnKeys={['materialityCalculatorField', 'percentageApplied']}
                            initialColumns={columns}
                            items={items}
                            selectionMode={SelectionMode.none}
                        />
                        <Stack horizontalAlign='end'>
                            <PrimaryButton
                                disabled={!formState.isDirty}
                                text={formatMessage({ id: 'save' })}
                                onClick={handleSubmit((data) => {
                                    updateMaterialityCalculator(data);
                                })}
                            />
                        </Stack>
                    </Stack>
                </Card>
            )}
        </>
    );
};
