import {Spinner, Stack} from "@fluentui/react";
import {useIntl} from "react-intl";
import {CalculatorRow} from "../shared";
import {useUpdateCalculatorData} from "../../hooks";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {CalculatorType} from "../../CalculatorType";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {IIncomeTaxExpensesData} from "./IIncomeTaxExpensesData";
import {CalculatorTemplateProps} from "../../types";
import {useJobContext} from "../../../../JobPortalLayoutPage";

type IncomeTaxExpenseCalculatorProps = CalculatorTemplateProps
export const IncomeTaxExpenseCalculator = forwardRef(({data}: IncomeTaxExpenseCalculatorProps, ref) => {
    const {formatMessage} = useIntl();
    const {jobId} = useJobContext();
    const {update, isUpdating} = useUpdateCalculatorData();
    const [expensesData, setExpensesData] = useState<IIncomeTaxExpensesData>(data?.data as IIncomeTaxExpensesData);
    const {handleSubmit, setValue, watch} = useForm<IIncomeTaxExpensesData>({
        ...DefaultFormSettings,
        defaultValues: {
            ...data?.data
        }
    });

    const watchFields = watch();
    const updateCalcData = (taxExpensesData: IIncomeTaxExpensesData) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.IncomeTaxExpense,
            isCustom: false,
            answer: taxExpensesData,
            jobId: jobId
        });
    };

    const total = useMemo(() => {
        return (watchFields.taxPayable || 0) -
            (watchFields.frankingCredits || 0) -
            (watchFields.foreignTaxCredit || 0) -
            (watchFields.decreaseInDTL || 0) +
            (watchFields.increaseInDTL || 0) -
            (watchFields.increaseInDTA || 0) +
            (watchFields.decreaseInDTA || 0);
    }, [watchFields])

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        }
    }));

    useEffect(() => {
        setExpensesData(data?.data as IIncomeTaxExpensesData);
    }, [data?.data])

    const isTaxPayableChanged = useMemo(() => {
        return expensesData?.isTaxPayableChanged || watchFields.taxPayable != expensesData?.taxPayable;
    }, [watchFields]);

    const isFrankingCreditsChanged = useMemo(() => {
        return expensesData?.isFrankingCreditsChanged || watchFields.frankingCredits != expensesData?.frankingCredits;
    }, [watchFields]);

    const isForeignTaxCreditChanged = useMemo(() => {
        return expensesData?.isForeignTaxCreditChanged || watchFields.foreignTaxCredit != expensesData?.foreignTaxCredit;
    }, [watchFields]);

    const isDecreaseInDTLChanged = useMemo(() => {
        return expensesData?.isDecreaseInDTLChanged || watchFields.decreaseInDTL != expensesData?.decreaseInDTL;
    }, [watchFields]);

    const isIncreaseInDTLChanged = useMemo(() => {
        return expensesData?.isIncreaseInDTLChanged || watchFields.increaseInDTL != expensesData?.increaseInDTL;
    }, [watchFields]);

    const isIncreaseInDTAChanged = useMemo(() => {
        return expensesData?.isIncreaseInDTAChanged || watchFields.increaseInDTA != expensesData?.increaseInDTA;
    }, [watchFields]);

    const isDecreaseInDTAChanged = useMemo(() => {
        return expensesData?.isDecreaseInDTAChanged || watchFields.decreaseInDTA != expensesData?.decreaseInDTA;
    }, [watchFields]);

    const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

    const handleClickOutside = (event: any) => {
        if((event.srcElement.id as string).includes('TextField'))
            return;

        setEditingRow(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    if(isUpdating)
      return <Spinner />;

    return (
        <Stack tokens={{childrenGap: 16}}>
            <CalculatorRow
                iconType={'plus'}
                changed={isTaxPayableChanged}
                onChangeValue={newValue => {
                    setValue('taxPayable', newValue);
                    setValue('isTaxPayableChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Tax_Payable'})}
                value={expensesData?.taxPayable}
                onClick={() => setEditingRow('taxPayable')}
                editing={editingRow === 'taxPayable'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'minus'}
                changed={isFrankingCreditsChanged}
                onChangeValue={newValue => {
                    setValue('frankingCredits', newValue);
                    setValue('isFrankingCreditsChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Franking_Credits'})}
                value={expensesData?.frankingCredits}
                onClick={() => setEditingRow('frankingCredits')}
                editing={editingRow === 'frankingCredits'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'minus'}
                changed={isForeignTaxCreditChanged}
                onChangeValue={newValue => {
                    setValue('foreignTaxCredit', newValue);
                    setValue('isForeignTaxCreditChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Foreign_Tax_Credit'})}
                value={expensesData?.foreignTaxCredit}
                onClick={() => setEditingRow('foreignTaxCredit')}
                editing={editingRow === 'foreignTaxCredit'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'minus'}
                changed={isDecreaseInDTLChanged}
                onChangeValue={newValue => {
                    setValue('decreaseInDTL', newValue);
                    setValue('isDecreaseInDTLChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Decrease_in_DTL'})}
                value={expensesData?.decreaseInDTL}
                onClick={() => setEditingRow('decreaseInDTL')}
                editing={editingRow === 'decreaseInDTL'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'plus'}
                changed={isIncreaseInDTLChanged}
                onChangeValue={newValue => {
                    setValue('increaseInDTL', newValue);
                    setValue('isIncreaseInDTLChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Increase_in_DTL'})}
                value={expensesData?.increaseInDTL}
                onClick={() => setEditingRow('increaseInDTL')}
                editing={editingRow === 'increaseInDTL'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'minus'}
                changed={isIncreaseInDTAChanged}
                onChangeValue={newValue => {
                    setValue('increaseInDTA', newValue);
                    setValue('isIncreaseInDTAChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Increase_in_DTA'})}
                value={expensesData?.increaseInDTA}
                onClick={() => setEditingRow('increaseInDTA')}
                editing={editingRow === 'increaseInDTA'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'plus'}
                changed={isDecreaseInDTAChanged}
                onChangeValue={newValue => {
                    setValue('decreaseInDTA', newValue);
                    setValue('isDecreaseInDTAChanged', true);
                }}
                label={formatMessage({id: 'calculator_incomeTax_Decrease_in_DTA'})}
                value={expensesData?.decreaseInDTA}
                onClick={() => setEditingRow('decreaseInDTA')}
                editing={editingRow === 'decreaseInDTA'}
                applyCurrencyFormat/>
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                changed={isTaxPayableChanged}
                label={formatMessage({id: 'calculator_incomeTax_Income_Tax_Expense'})}
                value={total}/>
        </Stack>
    );
});