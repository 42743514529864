import { FunctionComponent, useMemo } from 'react';
import { Text } from '@fluentui/react';
import { SanitizedText } from 'components';
import { getFlatProcedures, getOpenOnStartupIds } from 'utils';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';

export const RelevantAuditStandardsProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items)), [items]);
  
  return (
    <ProceduresDataTable
      items={rows}
      contextMenuOptions={{ disable: true }}
      isLoading={isLoading}
      isHeaderVisible={false}
      columns={[
        {
          key: 'auditingSTD',
          name: '',
          minWidth: 300,
          maxWidth: 300,
          fieldName: 'auditingSTD',
          onRender: (items) => <Text styles={{ root: { fontWeight: items?.children?.length ? 600 : 400 } }}>{items.number}</Text>,
        },
        {
          key: 'number',
          name: '',
          minWidth: 500,
          fieldName: 'number',
          onRender: (items) => <SanitizedText data={items?.title} style={{ fontWeight: items?.children?.length ? 600 : 400 }} />,
        },
      ]}
    />
  );
};