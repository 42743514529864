import { Dispatch, FunctionComponent, SetStateAction, useMemo, useState } from 'react';
import { Card, DataTable, Pagination } from 'components';
import { ActionButtonStyles } from 'constants/tableStylesPeset';
import { ConstrainMode, IColumn, IStackProps, Link, SelectionMode, Stack, useTheme } from '@fluentui/react';
import { IOrderedRequest } from 'interfaces';
import { getColumnKey, getContent, getItemTextColor } from 'utils/dashboardHelpers';
import { ITableHeaders, ITableItems } from "../../hooks";
import { useWorkflows } from "pages/WorkflowPortal";
import { SanitizedText } from 'components';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../../../../navigation';
import { DocumentsModal } from '../../../../ClientPortal/Administration/jobs/components/documents/DocumentsModal';
import { useBoolean } from '@fluentui/react-hooks';

export enum OnClickPopUpNamesEnum {
    JOB_MODAL = 'jobModal',
    FUND_MODAL = 'fundModal',
    CLIENT_MODAL = 'clientModal',
    EMPLOYEE_MODAL = 'employeeModal',
    AUDIT_QUERIES = "AuditQueries",
    DOCUMENTS = "Documents",
    INITIAL_INFORMATION_CHECKLIST = "InitialInformationChecklist"
}

export enum DashboardTableTypeEnum {
    // Audit portal workflow tables
    Administrator_AuditsAwaitingAuditorAllocation = 1,
    Administrator_AuditsInProgressWithAuditor = 2,
    Administrator_AuditsAwaitingManagerReview = 3,
    Administrator_AuditsAwaitingPartnerReview = 4,
    Administrator_PostArQueriesReadyForDispatch = 5,
    Administrator_ArSentThisWeek = 6,
    Administrator_AuditsReadyForInitialDispatch = 7,
    Administrator_PreArQueriesWaitingToBeReviewed = 8,
    Administrator_PostArQueriesWaitingToBeReviewed = 9,
    Administrator_PreArQueriesWaitingForResponse = 10,
    Administrator_PostArQueriesWaitingForResponse = 11,
    Administrator_Invoicing = 12,

    Auditor_MyAuditsNotStarted = 13,
    Auditor_AuditsInProgress = 14,
    Auditor_PreArQueriesToBeReviewed = 15,
    Auditor_PostArQueriesToBeReviewed = 16,
    Auditor_Timesheet = 17,
    Auditor_ReviewPointsToBeCleared = 18,
    Auditor_JobsByPeriod = 19,

    Contravention_AcrReadyForLodgement = 20,
    Contravention_AcrLodgedThisMonth = 21,
    Contravention_AcrLodgedThisYear = 22,
    Contravention_AuditsWithinContraventionProcess = 23,

    Manager_MyAuditsInProgress = 24,
    Manager_TeamAuditsAssignedNotStarted = 25,
    Manager_TeamAuditsInProgress = 26,
    Manager_MyAuditsWithReviewPoints = 27,
    Manager_AuditsReadyForReview = 28,
    Manager_ReviewPointsToBeCleared = 29,
    Manager_JobsByPeriod = 30,

    Partner_AuditsReadyForReview = 31,
    Partner_ReviewPointsToBeCleared = 32,
    Partner_ActiveClients = 33,
    Partner_ArSentThisWeek = 34,
    Partner_AllAuditsInProgress = 35,

    // Workpapers workflow tables
    Administrator_JobsAwaitingAccountantAllocation = 36,
    Administrator_JobsInProgressWithAccountant = 37,
    Administrator_JobsAwaitingManagerReview = 38,
    Administrator_JobsAwaitingPartnerReview = 39,
    Administrator_JobsInProgressWithAuditor = 40,
    Administrator_WorkpapersFinalisedThisWeek = 41,
    Administrator_JobsReadyForAudit = 42,
    Administrator_DraftAuditReportReleased = 43,
    Administrator_AuditReportReleased = 44,
    Administrator_WP_Invoicing = 45,

    Accountant_JobsNotStarted = 46,
    Accountant_JobsInProgress = 47,
    Accountant_Timesheet = 48,
    Accountant_ReviewPointsToBeCleared = 49,
    Accountant_WorkpapersPrepCompletedByPeriod = 50,

    Manager_MyJobsInProgress = 51,
    Manager_TeamJobsAssignedNotStarted = 52,
    Manager_TeamJobsInProgress = 53,
    Manager_MyJobsWithReviewPoints = 54,
    Manager_JobsReadyForReview = 55,
    Manager_WP_ReviewPointsToBeCleared = 56,
    Manager_WP_JobsByPeriod = 57,

    Partner_JobsReadyForReview = 58,
    Partner_WP_ReviewPointsToBeCleared = 59,
    Partner_WP_ActiveClients = 60,
    Partner_WorkpapersFinalisedThisWeek = 61,
    Partner_AllYearEndPrepInProgress = 62,

    // Client portal white label tables
    CpWhite_AuditsNotSubmitted = 63,
    CpWhite_AuditReportReleased = 64,
    CpWhite_AuditsInProgress = 65,
    CpWhite_PreArQueriesWaitingForResponse = 66,
    CpWhite_UnsignedArReleased = 67,
    CpWhite_PreArQueriesWaitingForReview = 68,
    CpWhite_PostArQueriesWaitingForResponse = 69,
    CpWhite_PostArQueriesWaitingForReview = 70,

    // Client portal black label tables
    CpBlack_AuditsNotSubmitted = 71,
    CpBlack_AuditReportReleased = 72,
    CpBlack_PreAuditIssues = 73,
    CpBlack_AuditsInProgress = 74,
    CpBlack_PreArQueriesWaitingForResponse = 75,
    CpBlack_UnsignedArReleased = 76,
    CpBlack_PreArQueriesWaitingForReview = 77,
    CpBlack_PostArQueriesWaitingForResponse = 78,
    CpBlack_PostArQueriesWaitingForReview = 79
}

export enum FilterByPeriod {
    Week = 1,
    Month = 2,
    Year = 3,
    Day = 4,
    Yesterday = 5,
}

export interface ITableFilters {
    pageSize: number;
    page: number;
    filterBy?: string;
    startDate?: string;
    endDate?: string;
    filterByYear?: string;
}

interface IGeneratedTableProps {
    columnsData: ITableHeaders;
    setFilters: Dispatch<SetStateAction<ITableFilters>>;
    containerProps?: IStackProps;
    isItemsLoading?: boolean;
    setSort: (value: IOrderedRequest) => void;
    itemsData?: ITableItems;
    isWidget?: boolean;
}

export const GeneratedDataTable: FunctionComponent<IGeneratedTableProps> = ({
    columnsData,
    itemsData,
    setFilters,
    containerProps = {},
    isItemsLoading = false,
    setSort,
    isWidget
}) => {
    const { showClientModal, showEmployeeModal, showFundModal, showJobModal } = useWorkflows();
    const navigate = useNavigate();
    const theme = useTheme();

    const [openDocumentsModal, { toggle: toggleOpenDocumentsModal }] = useBoolean(false);
    const [currentJobGuid, setCurrentJobGuid] = useState('');

    const getClickHandler = (item: any, onClickPopUpName: string | null, propertyIdentifier: string | null) => {
        switch (onClickPopUpName) {
            case OnClickPopUpNamesEnum.JOB_MODAL:
                return () => {
                    showJobModal(item.jobGuid);
                };
            case OnClickPopUpNamesEnum.FUND_MODAL:
                return () => {
                    showFundModal(item.fundId, item.clientId, item.fundGuid, item.clientGuid);
                };
            case OnClickPopUpNamesEnum.CLIENT_MODAL:
                return () => {
                    showClientModal(item.clientGuid);
                };
            case OnClickPopUpNamesEnum.EMPLOYEE_MODAL:
                return () => {
                    showEmployeeModal(Number(item[propertyIdentifier || 'guid']));
                };
            case OnClickPopUpNamesEnum.DOCUMENTS:
                return () => {
                    setCurrentJobGuid(item.jobGuid);
                    toggleOpenDocumentsModal();
                };
            case OnClickPopUpNamesEnum.AUDIT_QUERIES:
                return () => {
                    navigate(RouterPaths.administration.jobs.funds.jobList.root(item.clientGuid, item.fundGuid) + `?showQueries=true&jobGuid=${item.jobGuid}`);
                };
            case OnClickPopUpNamesEnum.INITIAL_INFORMATION_CHECKLIST:
                return () => {
                     navigate(RouterPaths.administration.jobs.funds.jobList.root(item.clientGuid, item.fundGuid) + `?showIic=true&jobGuid=${item.jobGuid}`);
                };
            default:
                return null;
        }
    };

    const [columns] = useState<IColumn[]>(
        columnsData.columns.map((column) => ({
            key: getColumnKey(column),
            name: column.displayName,
            minWidth: 150,
            maxWidth: 200,
            fieldName: column.columnName,
            onRender: (items) => {
                const content = getContent(column, items);
                const textColor = getItemTextColor(items);
                const onClick = getClickHandler(items, column.onClickPopUpName, column.popupIdentifierPropertyName);

                return column.isClickable && onClick ? (
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={onClick}>
                        {content}
                    </Link>
                ) : textColor == null ? (
                    <SanitizedText data={content} styles={{
                        root: {
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellypsis'
                        }
                    }} />
                ) : (
                    <SanitizedText data={content} color={textColor} styles={{
                        root: {
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellypsis'
                        }
                    }} />
                );
            },
        }))
    );

    const sortableColumnsKey: string[] = useMemo(
        () => columnsData.columns.filter((column) => column.isSortable).map((column) => getColumnKey(column)),
        [columnsData]
    );

    const paginationProps = useMemo(() => {
        if (!itemsData) return {};

        const {
            count: total = 0,
            items = [],
            filter: { page = 1, pageSize = 10 },
        } = itemsData;
        const onChange = (pageSize: number, page: number) => {
            setFilters((prev) => ({ ...prev, pageSize, page }));
        };
        return {
            itemsCount: (page - 1) * pageSize + items?.length,
            total,
            page,
            pageSize,
            onChange,
        };
    }, [itemsData, setFilters]);

    return (
        <Stack {...containerProps}>
            <Stack.Item grow={1} styles={{ root: { overflow: 'hidden' } }}>
                <Card>
                    <DataTable
                        sortableColumnKeys={sortableColumnsKey}
                        initialColumns={columns}
                        selectionMode={SelectionMode.none}
                        containerHeight='100%'
                        items={itemsData?.items || []}
                        enableShimmer={isItemsLoading}
                        setOrder={setSort}
                    />
                    <DocumentsModal open={openDocumentsModal} toggle={toggleOpenDocumentsModal} jobGuid={currentJobGuid}/>
                </Card>
            </Stack.Item>
            <Stack.Item styles={{ root: { padding: isWidget ? '0 16px 16px' : '' } }}>
                < Pagination {...paginationProps} />
            </Stack.Item>
        </Stack>
    );
};