import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontWeights, IColumn, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { Card, ControlledCheckbox, DataTable, ModalFooter, Pagination } from 'components';
import { IUserInfo } from 'interfaces';
import {
    ItemSelectionMode,
    useEditUserAssignClients
} from "../hooks/useEditUserAssignClients";
import { useGetClientByFilter } from '../../../WorkflowPortal';
import { ClientInfo, EntityStatus } from '../../../WorkflowPortal/shared';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../constants';

interface Props {
    user: IUserInfo;
}

interface IPagination {
    page: number;
    pageSize: number;
}

type ClientTableItem = {
    key: string,
    checked: boolean;
    type: 'DataItem' | 'ServiceItem',
    client: Pick<ClientInfo, 'guid' | 'name'>
}

type SelectionState = {
    mode: ItemSelectionMode;
    selectedGuids: string[];
    excludedGuids: string[];
}

export const UserManagementEditClients: FunctionComponent<Props> = ({user}: Props) => {
    const {formatMessage} = useIntl();
    
    const [pagination, setPagination] = useState<IPagination>({page: 1, pageSize: 10});
    const {dataClientsFilter, isLoadingClientsFilter} = useGetClientByFilter({...pagination, status: EntityStatus.Active});

    const [isAllSelected, setIsAllSelected] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);

    const [selectionState, setSelectionState] = useState<SelectionState>({
        mode: ItemSelectionMode.AddByOne,
        selectedGuids: user.clients.map(client => client.clientGuid),
        excludedGuids: []
    });
    
    const { control, setValue, getValues, watch } = useForm<{ items: ClientTableItem[] }>(DefaultFormSettings);
    const { fields, append, remove} = useFieldArray({ name: 'items', control: control });

    const {editUserAssignClients, isLoading: clientsAssigning} = useEditUserAssignClients();
    
    const onCheck = useCallback((checked: boolean, guid: string) => {
        setSelectionState(prev => {
            const newState = {
                ...prev,
                selectedGuids: (checked ? [...prev.selectedGuids, guid] : [...prev.selectedGuids.filter(g => g !== guid)]),
                excludedGuids: prev.mode === ItemSelectionMode.AddByOne
                    ? [...prev.excludedGuids]
                    : (checked ? [...prev.excludedGuids.filter(g => g !== guid)] : [...prev.excludedGuids, guid])
            }

            if (newState.mode === ItemSelectionMode.SelectAll) {
                setValue('items.0.checked', checked && !newState.excludedGuids.length);
            }
            
            return newState;
        })
        
    }, [selectionState, totalCount]);
    
    const selectAllItemValue = watch('items.0.checked')
    
    const onSelectAllCheck = (checked: boolean) => {
        setIsAllSelected(prev => checked);
        setSelectionState(prev => ({
            ...prev,
            mode: checked ? ItemSelectionMode.SelectAll : ItemSelectionMode.AddByOne,
            selectedGuids: checked ? [...getValues('items').filter(i => i.type === 'DataItem').map(i => i.key)] : [],
            excludedGuids: []
        }))
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'commands',
                name: '',
                minWidth: 20,
                maxWidth: 20,
                fieldName: 'commands',
                onRender: (item: ClientTableItem, index: number | undefined) => (
                    <ControlledCheckbox name={`items.${index!}.checked`}
                                        control={control} 
                                        onValueChange={(checked: boolean) => {
                                            if (item.type === 'DataItem') {
                                                onCheck(checked, item.client.guid);
                                            } else {
                                                onSelectAllCheck(checked);
                                            }
                                        }} 
                    />
                ),
            },
            {
                key: 'id',
                fieldName: 'name',
                minWidth: 100,
                name: formatMessage({ id: 'name' }),
                onRender: (item: ClientTableItem, index: number | undefined) => (
                    <Text styles={{ root: { fontWeight: item.type === 'ServiceItem' ? FontWeights.bold : FontWeights.regular }}}>{getValues(`items.${index!}.client.name`)}</Text>
                ),
            },
        ],
        [formatMessage]
    );

    const onSubmit = useCallback(() => {
        const guids = selectionState.mode === ItemSelectionMode.AddByOne 
            ? selectionState.selectedGuids.map(guid => ({clientGuid: guid})) 
            : selectionState.excludedGuids.map(guid => ({clientGuid: guid}))
        editUserAssignClients({userId: user.id, mode: selectionState.mode, body: guids});
    }, [selectionState]);

    const onPaginationChange = useCallback((pageSize: number, page: number) => {
        setPagination(prev => ({...prev, pageSize, page}));
    }, []);

    useEffect(() => {
        const items: ClientTableItem[] = dataClientsFilter?.data.items.map(i => ({
            key: i.guid,
            checked: selectionState.selectedGuids.some(guid => guid === i.guid) || (selectionState.mode === ItemSelectionMode.SelectAll && !selectionState.excludedGuids.some(guid => guid === i.guid)),
            type: 'DataItem',
            client: {...i}
        })) || [];
        
        const count: number = dataClientsFilter?.data.totalItemsCount ?? 0;
        if (items.length) {
            items.unshift({ key: '', checked: (selectionState.mode === ItemSelectionMode.SelectAll && count - selectionState.excludedGuids.length === count), type: 'ServiceItem', client: { guid: '', name: formatMessage({id: 'selectAll'})} })
        }
        
        setValue('items', items)
        setTotalCount(count)
    }, [dataClientsFilter, selectionState, isAllSelected, selectAllItemValue]);

    return (
        <Stack tokens={{childrenGap: 16}}>
            <Card>
                <Stack styles={{root: {height: 500, overflowX: 'none', overflowY: 'auto'}}}>
                    <DataTable
                        initialColumns={columns}
                        items={fields}
                        containerHeight={'100%'}
                        selectionMode={SelectionMode.none}
                        enableShimmer={isLoadingClientsFilter}
                    />
                </Stack>
                <Text variant='small' styles={{root: {fontWeight: 500}}}>
                    <FormattedMessage id='clientsSelected' 
                                      values={{value: selectAllItemValue 
                                              ? 'All'
                                              : selectionState.mode === ItemSelectionMode.SelectAll && totalCount ? totalCount - selectionState.excludedGuids.length : selectionState.selectedGuids.length}}/>
                </Text>
            </Card>
            <Card>
                <Pagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    onChange={onPaginationChange}
                    total={totalCount}
                    itemsCount={dataClientsFilter?.data.shownItemsCount}
                />
            </Card>
            <ModalFooter horizontalAlign='end'>
                <PrimaryButton disabled={clientsAssigning}
                               onClick={onSubmit} >
                    {clientsAssigning
                        ? <Spinner size={SpinnerSize.small}></Spinner>
                        : formatMessage({id: 'save'})}
                </PrimaryButton>
            </ModalFooter>
        </Stack>
    );
};
