import { useMutation } from "react-query";
import axios from "axios";
import { useContext } from "react";
import { Context, queryClient } from "utils";
import { useNotifications } from "../../../../../components/notifications";
import { useIntl } from "react-intl";
import { MessageBarType } from "@fluentui/react";

type MutateDataType = {
    jobGuid: string;
}

function useSubmitWizardAnswers() {
    const context = useContext(Context);

    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobGuid}/submitAuditQuestions`, data)
        ,
        {
            onSuccess: () => {
                showNotification({
                    name: formatMessage({ id: 'success' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'allAnswersAreSaved' })
                });

                queryClient.invalidateQueries('getCurrentJobs');
            },
        });

    return { update, updateAsync, isUpdating };
}

export { useSubmitWizardAnswers };