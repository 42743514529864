import { Stack, useTheme, Spinner, Text, Dropdown, PrimaryButton, StackItem } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ClientAuditQuestionAnswerValue, ClientAuditQuestionType, IWizardQuestion, useGetWizardQuestions } from '../../hooks/useGetWizardQuestions';
import { useSubmitWizardAnswers } from '../../hooks/useSubmitWizardAnswers';
import { useUpdateWizardAnswer } from '../../hooks/useUpdateWizardAnswer';
import { DetailedSoftwareType, SoftwareType } from 'enums';


interface IWizardQuestionsProps {
    job: any;
    selectedSoftwareType: SoftwareType;
}

interface IWizardQuestionProps {
    question: IWizardQuestion;
    number: number;
    jobId: number;
    enabled: boolean;
    updateAnwerValue: (index: number, answerValue?: ClientAuditQuestionAnswerValue) => void;
}

const answerOptions = [
    {
        key: ClientAuditQuestionAnswerValue.Unknown,
        text: 'Please select'
    },
    {
        key: ClientAuditQuestionAnswerValue.Yes,
        text: Object.values(ClientAuditQuestionAnswerValue)[ClientAuditQuestionAnswerValue.Yes].toString()
    },
    {
        key: ClientAuditQuestionAnswerValue.No,
        text: Object.values(ClientAuditQuestionAnswerValue)[ClientAuditQuestionAnswerValue.No].toString()
    }
];

const WizardQuestion: FunctionComponent<IWizardQuestionProps> = ({ question, number, jobId, enabled, updateAnwerValue }) => {
    const theme = useTheme();
    const { update, isUpdating } = useUpdateWizardAnswer();
    const [selectedKey, setSelectedKey] = useState(question.answer);
    const { formatMessage } = useIntl();

    const answerUpdateHandler = (option: any) => {
        if(question.order === 2)
        {
            updateAnwerValue(number, option.key);
        }

        update({
            jobId: jobId,
            answer: option.key,
            answerId: question.answerId,
            questionId: question.questionId
        });
    }

    useEffect(() => {
        setSelectedKey(question.answer);
    }, [question.answer]);

    const delimiter = "\r\n";
    const successParts = question.successText?.split(new RegExp(`(?=${delimiter})`)).filter(x=> x !== "");
    const failParts = question.failText?.split(new RegExp(`(?=${delimiter})`)).filter(x => x !== "");

    const showSuccessText = question.answer === ClientAuditQuestionAnswerValue.Yes && successParts && successParts.length > 0;
    const showFailText = question.answer === ClientAuditQuestionAnswerValue.No && failParts && failParts.length > 0;

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '2%', 
                marginBottom:  showFailText || showSuccessText ? '2%' : '0'} }} horizontal>
                <Stack.Item align={'center'} styles={{ root: { width: '40%' } }}>
                    <Text styles={{ root: { fontWeight: question.type !== ClientAuditQuestionType.FirstAnswer ? 'bold' : '' } }}
                        theme={theme.schemes?.default}>{question.type !== ClientAuditQuestionType.FirstAnswer ? `Q${number}: ${question.text}` : question.text}</Text>
                </Stack.Item>
                <Stack.Item align={'center'} styles={{ root: { width: '10%' } }}>
                    <Dropdown disabled={isUpdating || !enabled} theme={theme.schemes?.default} selectedKey={selectedKey} options={answerOptions} onChanged={answerUpdateHandler} />
                </Stack.Item>
            </Stack>
            {showSuccessText &&
                <Stack tokens={{ childrenGap: 8 }} styles={{ root: { margin: '2%', backgroundColor: '#b1acac66', width: '65%' } }}>
                    <Text theme={theme.schemes?.default}>{formatMessage({ id: 'pleaseProvide' })}</Text>
                    {successParts.map(x => <Text theme={theme.schemes?.default} styles={{ root: { marginLeft: '1%' } }}>{x}</Text>)}
                </Stack>
            }
            {showFailText &&
                <Stack tokens={{ childrenGap: 8 }} styles={{ root: { margin: '2%', backgroundColor: '#b1acac66', width: '65%' } }}>
                    <Text theme={theme.schemes?.default}>{formatMessage({ id: 'pleaseProvide' })}</Text>
                    {failParts.map(x => <Text theme={theme.schemes?.default} styles={{ root: { marginLeft: '1%' } }}>{x}</Text>)}
                </Stack>
            }
        </Stack>
    );
}

export const WizardQuestions: FunctionComponent<IWizardQuestionsProps> = ({ job, selectedSoftwareType }) => {
    const { formatMessage, formatDate } = useIntl();
    const theme = useTheme();

    const [displayedQuestions, setDisplayedQuestions] = useState<IWizardQuestion[]>();
    const [updatedQuestions, setUpdatedQuestions] = useState<IWizardQuestion[] | undefined>(undefined);
    const { response, isLoading } = useGetWizardQuestions(job.id);
    const { update, isUpdating } = useSubmitWizardAnswers();

    const updateAnswerValue = (index: number, answerValue?: ClientAuditQuestionAnswerValue) => {
        const updated = [...displayedQuestions!];
        updated[index].answer = answerValue;
        setUpdatedQuestions(updated);
    }

    const submitButtonClickHandler = () => {
        update({
            jobGuid: job.guid
        });
    }

    const isClassSuper = selectedSoftwareType === SoftwareType.ClassApiInCp || 
        selectedSoftwareType === SoftwareType.ClassPdfInCp ||
        selectedSoftwareType === SoftwareType.ClassSuper ||
        (!selectedSoftwareType && job.softwareType === SoftwareType.ClassSuper);


    useEffect(() => {
        const firstQuestion = response?.find(x => x.type === ClientAuditQuestionType.FirstAnswer);

        if (!firstQuestion || !response) {
            return;
        }

        const firstQuestionAnswer = firstQuestion.answer;

        if (firstQuestionAnswer === ClientAuditQuestionAnswerValue.Unknown || !firstQuestionAnswer) {
            setDisplayedQuestions([firstQuestion]);
        }

        if (firstQuestionAnswer === ClientAuditQuestionAnswerValue.Yes) {
            setDisplayedQuestions((prev) => [
                firstQuestion,
                ...response.filter(
                    (x) =>
                        x.type === ClientAuditQuestionType.YesJob &&
                        ((isClassSuper && x.order !== 3) ||
                            (x.order !== 2 && x.order !== 3) ||
                            (x.order === 3 && isClassSuper && (!!updatedQuestions
                                ? updatedQuestions.find((x) => x.type === ClientAuditQuestionType.YesJob && x.order === 2)?.answer ===
                                  ClientAuditQuestionAnswerValue.Yes
                                : response.find((x) => x.type === ClientAuditQuestionType.YesJob && x.order === 2)?.answer ===
                                  ClientAuditQuestionAnswerValue.Yes)))
                ),
            ]);
        }

        if (firstQuestionAnswer === ClientAuditQuestionAnswerValue.No) {
            setDisplayedQuestions((prev) => [
                firstQuestion,
                ...response.filter(
                    (x) =>
                        x.type === ClientAuditQuestionType.NoJob &&
                        ((isClassSuper && x.order !== 3) ||
                            (x.order !== 2 && x.order !== 3) ||
                            (x.order === 3 && isClassSuper && (!!updatedQuestions
                                ? updatedQuestions.find((x) => x.type === ClientAuditQuestionType.NoJob && x.order === 2)?.answer ===
                                  ClientAuditQuestionAnswerValue.Yes
                                : response.find((x) => x.type === ClientAuditQuestionType.NoJob && x.order === 2)?.answer ===
                                  ClientAuditQuestionAnswerValue.Yes)))
                ),
            ]);
        }
    }, [response, isClassSuper, updatedQuestions]);

    return (
        <>
            {isLoading && response ? <Spinner /> :
                <Stack tokens={{ childrenGap: 0 }}>
                    <StackItem styles={{root: {marginBottom: '2%'}}}>
                    {displayedQuestions?.map((x, index) => <WizardQuestion updateAnwerValue={updateAnswerValue} key={x.questionId} jobId={job.id} question={x} number={index}
                        enabled={index === 0 || displayedQuestions[index - 1].answer !== ClientAuditQuestionAnswerValue.Unknown} />)}
                    </StackItem>
                    <PrimaryButton disabled={isUpdating || displayedQuestions?.some(q=>q.answer === ClientAuditQuestionAnswerValue.Unknown)}
                        text={formatMessage({ id: 'submitAnswers' })} onClick={submitButtonClickHandler} />
                </Stack>}
        </>
    );
}