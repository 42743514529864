import { IColumn, Stack } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const SuperMateOperating = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 300,
                maxWidth: 500,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== "Heading" &&
                            <GeneratedAnswer itemId={item?.id} tableType={item?.reportType}
                                value={item?.answerText} answerControlType={item?.answerControlType}
                                auto={item?.isAnswerDisabled} disabled={item?.isAnswerDisabled} />}
                    </Stack>
                ),
            },
            {
                key: 'to',
                name: formatMessage({ id: 'thisYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'to',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'from',
                name: formatMessage({ id: 'lastYear' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'from',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'change',
                name: formatMessage({ id: 'moneyChange' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'change',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'percentChange',
                name: formatMessage({ id: 'percentChange' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percentChange',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType === 'Heading' ? '' : item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isBold} format={item?.format} textAlign='right' numberFormat='percentage-fractional' />,
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[1]} isSumChecked={item.isSumChecked} />,
            },
            {
                key: 'validation2',
                name: formatMessage({ id: 'validation' }) + ' 2',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation2',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} useValidations={[2, 3, 4]} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
