import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

type MutateDataType = {
    jobId: number;
}
function useCreateUOCItem() {
    const context = useContext(Context);
    const {
        mutate: createUOC,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.proceduresUrl}/api/1.0/procedures/uoc/${data.jobId}`, data))

    return {createUOC, isCreating};
}

export {useCreateUOCItem};