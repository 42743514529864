import {FunctionComponent, useMemo} from 'react';
import {useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {mergeStyles, Stack, useTheme} from "@fluentui/react";
import {getFlatProcedures, getOpenOnStartupIds} from "../../../../../utils";
import {useJobContext} from "../../../JobPortalLayoutPage";

export const OverallAuditStrategyProcedureTemplate: FunctionComponent = () => {
    const theme = useTheme();
    const {jobYear} = useJobContext();
    const {items} = useProcedureContext();

    if (!items?.length) return <></>;
    const styles = mergeStyles({
        '.static-table': {
            width: '100%'
        },
        '.static-table > *': {
            boxSizing: 'border-box',
            fontSize: theme.fonts.medium.fontSize
        },
        '.static-table-body-cell, .static-table-header-cell': {
            padding: '0.75rem',
            verticalAlign: 'top',
            textAlign: 'left',
            wordSpacing: 'normal',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            color: theme.schemes?.default?.semanticColors.bodyText
        }
    });
    return (
        <Stack>
            <SanitizedText className={styles} data={items[0].text.replace(/\[date]/g, String((jobYear + 1)))}/>
        </Stack>
    );
};