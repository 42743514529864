import {IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, TextField, useTheme} from '@fluentui/react';
import {Controller, Path} from 'react-hook-form';
import * as React from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import { deepMerge } from 'utils/objectsHelper';

interface Props<FormValues> {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    borderless?: boolean;
    underlined?: boolean;
    prefix?: string;
    suffix?: string;
    disabled?: boolean;
    styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
    onChanged?: (newValue?: string) => void,
    autoFocus?: boolean,
}

export function ControlledNumberField<FormValues>({ name, control, rules, styles, ...otherProps }: Props<FormValues>) {
    const theme = useTheme();

    const defaultStyles = {
        root: {
            selectors: {
                '.ms-Label': { color: theme.schemes?.default?.semanticColors.bodyText },
            },
        },
        field: {
            '-webkit-box-shadow': `inset 0 0 0 50px ${theme.palette.white}`,
            color: theme.schemes?.default?.semanticColors.inputText,
            '::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
        },
        fieldGroup: {
            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
            selectors: {
                '&:hover': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 },
            },
        },
    };

    const mergedStyles = deepMerge(defaultStyles, styles);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
                <TextField
                    name={fieldName}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        onChange(newValue)
                        otherProps.onChanged?.(newValue);
                    }}
                    value={String(value)}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    type='number'
                    {...otherProps}
                    styles={mergedStyles}
                />
            )}
        />
    );
}
