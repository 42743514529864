import { SanitizedText } from 'components';
import { IColumn, Stack } from '@fluentui/react';
import { Attachment, GeneratedAnswer, JobComment } from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from 'utils';
import { ReportTable } from '../Table/ReportTable';
import { ReportTemplateTypeProps } from '../Types';
import { AnswerControlType, TableType } from '../../../../../../enums';

export const BankAccountMovementReport = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'date',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={formatDate(item[column?.fieldName || 0])}
                        isHeadingRow={item?.isHeadingRow}
                        format={item?.format}
                    />
                ),
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 250,
                fieldName: 'description',
                onRender: (item, _, column) => (
                    <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />
                ),
            },
            {
                key: 'withdrawals',
                name: formatMessage({ id: 'withdrawals' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'withdrawals',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'deposits',
                name: formatMessage({ id: 'deposits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'deposits',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'balance',
                name: formatMessage({ id: 'balance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balance',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow}
                        format={item?.format}
                        textAlign='right'
                        numberFormat='fractional'
                    />
                ),
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed',
                onRender: (item) =>
                    (item?.depth != 1 && item?.isTotalRow) || item?.depth > 0 ? (
                        <Stack verticalAlign='center' grow>
                            <GeneratedAnswer
                                itemId={item?.id}
                                tableType={TableType[item?.reportType as keyof typeof TableType]}
                                value={item?.answerText}
                                auto={item?.isAnswerDisabled}
                                disabled={item?.isAnswerDisabled}
                                answerControlType={AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType]}
                            />
                        </Stack>
                    ) : undefined,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns}
                        items={items} 
                        hideIfEmpty={false}
                        isFetching={isFetching} 
    />;
};
