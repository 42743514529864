import {Card} from "../../../../../../components";
import {DefaultButton, Dialog, DialogFooter, DialogType, Stack, Text, useTheme} from "@fluentui/react";
import {WorkDoneSwitchAnswer} from "../../answers";
import {ColumnInfo} from "../../../../enums";
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {IAuditCompletionLetterItem} from "./interfaces";
import {useSectionContext} from "../../../Section";
import {useProcedureContext} from "../../../ProceduresContent";
import {useIntl} from "react-intl";
import {useId} from "@fluentui/react-hooks";
import {logger} from "../../../../../../services";


type AuditCompletionLetterPanelProps = {
    item: IAuditCompletionLetterItem;
    onAnswerUpdate?: (answer: string | null) => void;
    disabled?: boolean;
}

const cardStyles = {
    root: {
        width: '33%'
    }
};

export const AuditCompletionLetterPanelTemplate = forwardRef(({
                                                                  item,
                                                                  onAnswerUpdate,
                                                              }: AuditCompletionLetterPanelProps, ref) => {
    const {formatMessage} = useIntl();
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const [showAlert, setShowAlert] = useState<boolean>(false);

    const {setApprovingDisable, section, enableWarningMessage} = useSectionContext();
    const {items} = useProcedureContext();

    const handleOnAnswerUpdated = (answer: string | null) => {
        item.answerText = answer ?? '';
        updateSectionAnswers();
        onAnswerUpdate?.(answer);
    };

    useImperativeHandle(ref, () => ({
        onApprove(approved: boolean, type: 'manager' | 'partner' | 'sendEmail', update: Function) {
            if (item.answerText === 'Yes' && items.every(x => !x.omlItems?.length) && type !== 'sendEmail' && approved) {
                setShowAlert(true);
                return;
            } else {
                update(approved, type);
            }
        }
    }));

    const hideAlert = () => {
        setShowAlert(false);
    };

    const updateSectionAnswers = () => {
        const hasAnswers = items.every(x => !!x.answerText);
        setApprovingDisable(!hasAnswers)
        enableWarningMessage(!hasAnswers, true);
    }

    const theme = useTheme();

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({id: 'warning'}),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({id: 'jobPortal_acl_warning'}),
    };

    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
        }),
        [labelId, subTextId],
    );

    const panelDisabled = useMemo(() => {
        return !!section.sectionAnswer?.managerApproval && !!section?.sectionAnswer.partnerApproval;
    }, [section.sectionAnswer]);

    useEffect(() => {
        updateSectionAnswers();
    }, [items, item]);

    return (
        <>
            <Card styles={cardStyles}>
                <Stack horizontal verticalAlign="center" horizontalAlign='space-between' grow>
                    <Stack.Item>
                        <Text theme={theme.schemes?.default}>{item.title}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <WorkDoneSwitchAnswer itemId={item.id}
                                              tableType={item.tableType!}
                                              iconTypes="character"
                                              reverse
                                              disabled={panelDisabled || items.some(x => !!x.omlItems?.length) && item.answerText === 'Yes'}
                                              disabledPartialy={items.some(x => !!x.omlItems?.length) ? 'invalid' : undefined}
                                              value={item.answerText}
                                              onUpdate={handleOnAnswerUpdated}
                                              columnInfo={ColumnInfo.Text}/>
                    </Stack.Item>
                </Stack>
            </Card>

            <Dialog
                hidden={!showAlert}
                onDismiss={hideAlert}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={hideAlert} text={formatMessage({id: 'close'})}/>
                </DialogFooter>
            </Dialog>
        </>
    );
});
