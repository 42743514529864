import {ActionButton, IconButton, IDropdownOption, Stack, useTheme} from "@fluentui/react";
import {useIntl} from "react-intl";
import {IEventSummaryContraventionItem} from "./interfaces/IEventSummaryContraventionItem";
import {FunctionComponent, useMemo} from "react";
import {ProceduresDataTable} from "../../../ProceduresDataTable";
import {ControlledDropdown, ControlledTextField, ITableColumn} from "../../../../../../components";
import {IEventSummaryGeneralQuestion} from "./interfaces/IEventSummaryGeneralQuestion";
import {useFieldArray} from "react-hook-form";
import {IEventSummaryItem} from "./interfaces/IEventSummaryItem";
import {Control} from "react-hook-form/dist/types/form";

type EventSummaryContraventionTemplateProps = {
    control: Control<IEventSummaryItem>;
    questions?: IEventSummaryGeneralQuestion[];
    jobId: number;
    eventId?: number;
}
export const EventSummaryContraventionTemplate: FunctionComponent<EventSummaryContraventionTemplateProps> = ({
                                                                                                                 control,
                                                                                                                 questions,
                                                                                                                 jobId,
                                                                                                                 eventId
                                                                                                             }) => {
    const {formatMessage} = useIntl();
    const {fonts, palette} = useTheme();
    
    const generalQuestionOptions = useMemo<IDropdownOption[]>(() => {
        return questions?.map(x => ({key: x.id, text: x.heading})) || [];
    }, [questions]);
    
    const questionOptions: IDropdownOption[] = [
        {key: '', text: ''},
        {key: 'Yes', text: 'Yes'},
        {key: 'No', text: 'No'}
    ];
    
    const {append, remove, fields} = useFieldArray({
        control,
        name: 'eventSummaryContraventions'
    });


    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'contravention',
                name: formatMessage({id: 'eventSummaryContravention_label_contravention'}),
                fieldName: 'generalQuestionId',
                minWidth: 150,
                onRender: (field, index) => (
                    <Stack grow>
                        {index !== undefined && (
                            <ControlledDropdown
                                name={`eventSummaryContraventions.${index}.generalQuestionId`}
                                control={control}
                                options={generalQuestionOptions}/>)}
                    </Stack>
                )
            },
            {
                key: 'maxValue',
                name: formatMessage({id: 'eventSummaryContravention_label_maximumValue'}),
                fieldName: 'maximumValue',
                minWidth: 100,
                maxWidth: 100,
                onRender: (field, index) => (
                    index !== undefined ? (
                        <ControlledTextField name={`eventSummaryContraventions.${index}.maximumValue`}
                                             control={control}/>) : null
                )
            },
            {
                key: 'hasContraventionRectified',
                name: formatMessage({id: 'eventSummaryContravention_label_hasContraventionRectified'}),
                fieldName: 'hasContraventionRectified',
                minWidth: 100,
                maxWidth: 100,
                onRender: (field, index) => (
                    <Stack grow>
                        {index !== undefined ? (
                            <ControlledDropdown
                                name={`eventSummaryContraventions.${index}.hasContraventionRectified`}
                                control={control}
                                options={questionOptions}/>) : null}
                    </Stack>
                )
            },
            {
                key: 'outstandingValue',
                name: formatMessage({id: 'eventSummaryContravention_label_outstandingValue'}),
                fieldName: 'outstandingValue',
                minWidth: 100,
                maxWidth: 100,
                onRender: (field, index) => (
                    index !== undefined ? (
                        <ControlledTextField
                            name={`eventSummaryContraventions.${fields.indexOf(field)}.outstandingValue`}
                            control={control}/>) : null
                )
            }, {
                key: 'actions',
                name: '',
                minWidth: 35,
                maxWidth: 35,
                onRender: (field, index) => {
                    return (<Stack grow horizontalAlign='center'>
                        <IconButton iconProps={{iconName: 'delete'}}
                                    onClick={() => remove(fields.indexOf(field))}
                                    styles={{icon: {color: palette.red}, iconHovered: {color: palette.redDark}}}
                        />
                    </Stack>);
                }
            }
        ]
    }, [fields]);

    const theme = useTheme();

    return (
        <Stack horizontalAlign='center'>
            <Stack.Item style={{width: '100%'}}>
                <ProceduresDataTable items={fields || []}
                                     columns={columns}
                                     header={{rowHeight: 100, horizontalAlign: 'center'}}
                />
            </Stack.Item>
            <Stack.Item>
                <ActionButton theme={theme.schemes?.default}
                              text={formatMessage({id: 'eventSummaryContravention_button_addNew'})}
                              iconProps={{iconName: 'add'}}
                              styles={{icon: {fontSize: fonts.small.fontSize}}}
                              onClick={() => append({})}/>
            </Stack.Item>
        </Stack>
    );
};