import {commonHeaders, Context} from "utils";
import {useContext} from "react";
import {useQuery} from "react-query";
import axios, {AxiosError} from "axios";
import {TeamInfo} from "../../shared";

function useGetAllTeams() {
    const context = useContext(Context);
    const {data: response, isLoading: isLoading} = useQuery(
        ['getAllTeams'],
        () => axios.get<TeamInfo[]>(`${context?.config?.userUrl}/api/1.0/teams`, commonHeaders()),
        {
            enabled: !!context?.config?.userUrl && !!context.tenantId,
           
        }
    );

    return {response, isLoading}
}

export {useGetAllTeams};