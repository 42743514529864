import {FunctionComponent, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {IconButton, useTheme} from '@fluentui/react';
import {IContextualMenuProps} from '@fluentui/react/lib/ContextualMenu';
import {useBoolean} from '@fluentui/react-hooks';
import {ActiveIconColorStyles} from 'constants/tableStylesPeset';
import {IUserInfo} from 'interfaces';
import {Modal} from 'components';
import {UserManagementDeleteUserDialog} from './UserManagementDeleteUserDialog';
import {UserManagementEditUserForm} from './UserManagementEditUserForm';
import {UserManagementEditClients} from './UserManagementEditClients';
import {UserManagementRestoreUserDialog} from './UserManagementRestoreUserDialog';
import {useDeleteUser} from '../hooks/useDeleteUser';
import {useRestoreUser} from '../hooks/useRestoreUser';

interface Props {
    item: IUserInfo;
}

export const UserManagementActions: FunctionComponent<Props> = ({item}: Props) => {
    const {deleteUser, isLoading: userDeleting, isSuccess: deleteSuccess} = useDeleteUser();
    const {restoreUser, isLoading: userRestoring, isSuccess: restoreSuccess} = useRestoreUser();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const [hiddenDeleteDialog, {toggle: toggleHiddenDeleteDialog}] = useBoolean(true);
    const [hiddenRestoreDialog, {toggle: toggleHiddenRestoreDialog}] = useBoolean(true);
    const [isModalEditUserOpen, {toggle: toggleIsModalEditUserOpen}] = useBoolean(false);
    const [isModalEditClientsOpen, {toggle: toggleIsModalEditClientsOpen}] = useBoolean(false);

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'addRemoveClient',
                text: formatMessage({id: 'addRemoveClient'}),
                iconProps: {iconName: 'Add'},
                onClick: toggleIsModalEditClientsOpen,
            },
            {
                key: 'edit',
                text: formatMessage({id: 'editUser'}),
                iconProps: {iconName: 'Edit'},
                onClick: toggleIsModalEditUserOpen,
            },
            {
                key: 'restore',
                text: formatMessage({id: 'restore'}),
                iconProps: {iconName: 'Redo'},
                disabled: !item.isDeleted,
                onClick: toggleHiddenRestoreDialog,
            },
            {
                key: 'delete',
                text: formatMessage({id: 'delete'}),
                iconProps: {iconName: 'Delete', styles: ActiveIconColorStyles(theme.palette.red)},
                disabled: item.isDeleted,
                onClick: toggleHiddenDeleteDialog,
            },
        ],
    };

    useEffect(() => {
        if (deleteSuccess && !hiddenDeleteDialog) {
            toggleHiddenDeleteDialog();
        }
    }, [deleteSuccess, hiddenDeleteDialog, toggleHiddenDeleteDialog]);

    useEffect(() => {
        if (restoreSuccess && !hiddenRestoreDialog) {
            toggleHiddenRestoreDialog();
        }
    }, [restoreSuccess, hiddenRestoreDialog, toggleHiddenRestoreDialog]);

    const onDelete = () => {
        deleteUser(item.id);
    };

    const onRestore = () => {
        restoreUser(item.id);
    };

    return (
        <>
            <IconButton
                onRenderMenuIcon={() => <div/>}
                menuProps={menuProps}
                iconProps={{iconName: 'More'}}
            />

            <UserManagementDeleteUserDialog
                hidden={hiddenDeleteDialog}
                toggleHidden={toggleHiddenDeleteDialog}
                onAgree={onDelete}
                noDisabled={userDeleting}
                yesDisabled={userDeleting}
            />

            <UserManagementRestoreUserDialog
                hidden={hiddenRestoreDialog}
                toggleHidden={toggleHiddenRestoreDialog}
                onAgree={onRestore}
                noDisabled={userRestoring}
                yesDisabled={userRestoring}
            />

            <Modal
                isOpen={isModalEditUserOpen}
                onDismiss={toggleIsModalEditUserOpen}
                title={formatMessage({id: 'editUser'})}>
                <UserManagementEditUserForm user={item} onDismiss={toggleIsModalEditUserOpen}/>
            </Modal>

            <Modal
                isOpen={isModalEditClientsOpen}
                onDismiss={toggleIsModalEditClientsOpen}
                title={formatMessage({id: 'addRemoveClient'})}>
                <UserManagementEditClients user={item}/>
            </Modal>
        </>
    );
};
