import {useState} from 'react';
import {IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {BaseDialog, Card, DataTable} from 'components';
import {ActionButtonStyles, CardTableContainerStyles} from 'constants/tableStylesPeset';
import {ActionButton} from '@fluentui/react/lib/Button';
import {useIntl} from 'react-intl';
import {format} from "date-fns";
import {IOrderedRequest} from "interfaces";
import {JobInfo} from "pages/WorkflowPortal/shared";
import {useWorkflows} from "pages/WorkflowPortal";

interface IJobsDataTableProps {
    isLoading: boolean;
    jobs?: JobInfo[] | undefined;
    setSort: (value: IOrderedRequest) => void;

}

export const JobsDataTable = ({jobs, isLoading, setSort}: IJobsDataTableProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const [showActivatedModal, setShowActivatedModal] = useState<boolean>(false);
    const {showClientModal, showEmployeeModal, showFundModal, showJobModal} = useWorkflows();

    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={{root: {fontWeight: 500}}} onClick={() => setShowActivatedModal(true)}
                >
                    {items.status === 'Active' ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'clientName',
            name: formatMessage({id: 'client'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'Fund.Client.Name',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => {
                    showClientModal(items.client.guid);
                }}>
                    {items.client?.name}
                </Link>
            ),
        },
        {
            key: 'fund',
            name: formatMessage({id: 'fund'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'Fund.Name',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => {
                    showFundModal(items.fund.id, items.client.id, items.fund.guid, items.client.guid);
                }}>
                    {items.fund?.name}
                </Link>
            ),
        },
        {
            key: 'year',
            name: formatMessage({id: 'job'}),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'Year',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => {
                    showJobModal(items.id);
                }}>
                    {items.year}
                </Link>
            ),
        },
        {
            key: 'dateReceived',
            name: formatMessage({id: 'dateReceived'}),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'JobLocalData.DateReceived',
            onRender: (items) => items.dateReceived && (
                <Text>{format(new Date(items.dateReceived), 'dd/MM/yyyy')}</Text>)
        },
        {
            key: 'auditAllocated',
            name: formatMessage({id: 'auditAllocatedTo'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'JobLocalData.Auditor.FirstName',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => {
                    showEmployeeModal(items.auditor.id);
                }}
                >
                    {items.auditor?.name}
                </Link>
            ),
        },
        {
            key: 'partner',
            name: formatMessage({id: 'partner'}),
            minWidth: 150,
            maxWidth: 400,
            fieldName: 'JobLocalData.Partner.FirstName',
            onRender: (items) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => {
                    showEmployeeModal(items.partner.id);
                }}>
                    {items.partner?.name}
                </Link>
            ),
        },
        {
            key: 'dateARSent',
            name: formatMessage({id: 'arSent'}),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'JobLocalData.DateArSent',
            onRender: (items) => items.dateARSent && (<Text>{format(new Date(items.dateARSent), 'dd/MM/yyyy')}</Text>)
        },
        {
            key: 'daysIn',
            name: formatMessage({id: 'daysIn'}),
            minWidth: 120,
            maxWidth: 120,
            fieldName: 'DaysIn',
            onRender: (items) => <Text>{items.daysIn}</Text>,
        },
    ]);

    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={[
                    'clientName',
                    'fund',
                    'year',
                    'dateReceived',
                    'auditAllocated',
                    'partner',
                    'dateARSent',
                    'daysIn',
                ]}
                initialColumns={columns}
                columns={columns}
                enableShimmer={isLoading}
                items={jobs || []}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
                setOrder={setSort}
            />

            {showActivatedModal && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogTitle'})}
                    hidden={false}
                    onAgree={() => console.log('Accept')}
                    toggleHidden={() => setShowActivatedModal(false)}
                />
            )}
        </Card>
    );
};
