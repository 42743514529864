import {Card, ControlledDropdown} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, useImperativeHandle} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";
import {useUpdateJobQueryRating} from "../../../hooks";

interface IUpdateJobQueryRatingCommand {
    jobGuid: string;
    queryRating: "Yes" | "No" | "Checked" | null;
}

export const QueryRatingTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const stackItemStyles = {root: {width: '100%'}};
    const {formatMessage} = useIntl();
    const {updateJobQueryRating, isQueryRatingUpdating} = useUpdateJobQueryRating();
    const {control, handleSubmit, setValue} = useForm<IUpdateJobQueryRatingCommand>({
            ...DefaultFormSettings, defaultValues: {queryRating: props.job?.queryRating}
        }
    );

    const queryRatingOptions = [
        {key: "Yes", text: "Yes"},
        {key: "No", text: "No"},
        {key: "Checked", text: 'Checked'},
    ];

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: IUpdateJobQueryRatingCommand) => {
        console.debug(data);

        updateJobQueryRating({
            jobGuid: props.job?.guid || '',
            queryRating: data.queryRating
        }, {
            onSuccess: () => {
                if (props.onSubmitted)
                    props.onSubmitted();
            }
        });

    }

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='queryRating'
                        label={formatMessage({id: 'queryRating'})}
                        options={queryRatingOptions}
                        control={control}
                        placeholder={formatMessage({id: 'selectQueryRating'})}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});