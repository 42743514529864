import {useContext} from "react";
import {useQuery} from "react-query";
import axios from "axios";
import { Context } from "../../../../../utils";
import { IAttachment } from "../components/attachments/IAttachment";

function useGetJobDocuments(jobId: number) {
    const context = useContext(Context);
    const dispatchItemsDocumentType = 4;

    const host = context?.config?.annotationsUrl;
    const {
        data: response,
        refetch,
        isLoading,
        isFetching
    } = useQuery(['getJobDocuments', jobId], () => axios.get<IAttachment[]>(`${host}/api/1.0/jobs/${jobId}/attachments`, {
        params: { documentType: dispatchItemsDocumentType },
    }).then(resp => resp.data), { enabled: !!context?.config?.annotationsUrl && !!context.tenantId && !!jobId});

    return {response, refetch, isLoading, isFetching};
}

export {useGetJobDocuments};