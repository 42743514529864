import {ComboBox, IComboBox, IComboBoxOption, IComboBoxProps, useTheme} from '@fluentui/react';
import {Controller, Path} from 'react-hook-form';
import * as React from 'react';
import {useEffect} from 'react';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

import { deepMerge } from '../../utils/objectsHelper';

interface Props<FormValues> extends IComboBoxProps {
    rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    name: Path<FormValues>;
    control: Control<FormValues>;
    label?: string;
    placeholder?: string;
    options: IComboBoxOption[];
    dropdownWidth?: number;
    dropdownMaxWidth?: number;
    allowFreeform?: boolean;
    disabled?: boolean;
}

export function ControlledAutoComplete<FormValues>({
                                                       name,
                                                       control,
                                                       rules,
                                                       label,
                                                       placeholder,
                                                       options: initialOptions,
                                                       dropdownWidth = 200,
                                                       dropdownMaxWidth,
                                                       allowFreeform,
                                                       disabled,
                                                       ...otherProps
                                                   }: Props<FormValues>) {
    const [options, setOptions] = React.useState(initialOptions);
    const theme = useTheme();

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const onChangeComboBox = React.useCallback(
        (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, value?: string, onChange?: (...event: any[]) => void): void => {
            let key = option?.key;
            if (allowFreeform && !option && value) {
                // If allowFreeform is true, the newly selected option might be something the user typed that
                // doesn't exist in the options list yet. So there's extra work to manually add it.
                setOptions((prevOptions) => [...prevOptions, {key: value, text: value}]);
                key = value;
            }

            if (onChange) onChange(key);
        },
        [allowFreeform]
    );

    const defaultStyles = {
                        label: { color: theme.schemes?.default?.semanticColors.bodyText },
                        root: {
                            '.ms-Icon': { color: theme.schemes?.default?.semanticColors.bodyText },
                            border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40,
                            ':after': { border: '1px ' + theme.schemes?.default?.palette.blackTranslucent40 }
                        },
                        input: {
                            color: theme.schemes?.default?.semanticColors.bodyText,
                            '::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
                        },
                        callout: {
                            '.ms-ComboBox-optionText': { color: theme.schemes?.default?.semanticColors.bodyText },
                        },
                        rootHovered: {
                            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
                            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
                        },
                         rootDisabled: {
                            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
                            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
                        },
                        rootPressed: {
                            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
                            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
                        },
                        rootFocused: {
                            '.ms-ComboBox-Input': { color: theme.schemes?.default?.semanticColors.bodyText },
                            '.ms-ComboBox-Input::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
                        }
                    };

    const mergedStyles = deepMerge(defaultStyles, otherProps.styles);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                <ComboBox
                    label={label}
                    placeholder={placeholder}
                    options={options}
                    dropdownWidth={dropdownWidth}
                    dropdownMaxWidth={dropdownMaxWidth}
                    onInputValueChange={onChange}
                    onChange={(ev, option, index, v) => onChangeComboBox(ev, option, v, onChange)}
                    selectedKey={value as string}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    allowFreeform={allowFreeform}
                    disabled={disabled}
                    autoComplete='on'
                    {...otherProps}
                    styles={mergedStyles}
                />
            )}
        />
    );
}
