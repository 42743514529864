import {Card, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {IChangeEmployeeCommand} from '..';
import {useTenantInfo} from "../../../../../../providers";

type AccountTabType = {
    control: Control<IChangeEmployeeCommand>;
};

export const OtherInfoTab = ({control}: AccountTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='nameOfAuditingFirm'
                        label={formatMessage({id: isWorkpapers ? 'nameOfAccountingFirm' : 'nameOfAuditingFirm'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='SMSFAuditorNumber'
                        label={formatMessage({id: isWorkpapers ? 'registeredTaxAgent': 'smsfAuditorNumber'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={{root: {width: '100%'}}}>
                    <ControlledTextField
                        name='postNominals'
                        label={formatMessage({id: 'postNominals'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
