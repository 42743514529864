export enum ControlType {
  Empty = 0,
  StaticList = 1,
  Grid = 2,
  ReviewItem = 3,
  StaticListWithHeader = 4,
  CollapsableGrid = 5,
  GeneralQuestion = 6,
  MandatoryQuestion = 7,
  TestSummary = 8,
  FundDetailsForm = 9,
  AuditFeeForm = 10,
  AuditQualification = 11,
  TestSummaryReview = 12,
  EventsSummary = 13,
  AuditTrail = 14,
  JobTimeSheet = 15,
  RiskCategorization = 16,
  ValidationError = 17,
  Administration = 18,
  Corresspondance = 19,
  PrintToPDF = 20,
  ComparativeInformation = 21,
  GridWithoutAnswers = 22,
  OverallAuditStrategy = 23,
  RiskMatrix = 24,
  BankAccount = 25,

  GeneralLedger = 26,
  DashboardTable = 27,
  DashboardTableData = 28,

  ReportStatement = 100,
  InitialAudit = 101,
  AssessmentIdentifiedRisk = 102,
  UndersAndOversCalculation = 103,
  DispatchAudit = 104,
  UserGrid = 105,
  AuditQueriesForClients = 106,
  WrapAccounts = 107,
  WrapAccountDetail = 108,
  PropertyDetails = 109,
  PropertyDetailsText = 110,
  DeletedAudit = 111,
  ClientComments = 113,
  FileNotes = 114,
  QuerySummary = 115,
  PdfLibrary = 116,
  PdfLibraryDetail = 117,

  SuggestedTesting = 200,
  AuditMethodology = 201,

  AuditAcceptance = 995,
  AuditAcceptanceIssues = 996,
  IIChecklist = 997,
  HINTable = 998,
  NotImplemented = 999,
  CompletionLetter = 1000,
  ReviewQualificationItem = 1001,
  Conclusion = 1002,
  FeeCalculation = 1010,
  SubsequentEvents = 1011,
  ConclusionOpinion = 1012,
  AuditReadySummary = 1013,
  AuditReadySummarySection = 1014,
  FeeCalculationV2 = 1015,

  SiteCustomization_WL = 665,
  SiteCustomization_CP = 666,
  SiteCustomization_AP = 667,
  ReportTemplate = 668,
  VisibleTabs = 669,
  BulkImport = 670,
  BulkExport = 671,

  // Explanatory Notes
  AuditAssertions = 2001,
  RelevantAuditStandards = 2002
}