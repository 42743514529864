import {IJobSuggestedTestingItem} from "../../../../hooks";
import React, {FunctionComponent, Key, useEffect, useState} from "react";
import {Dropdown, IDropdownOption, mergeStyleSets, Stack, StackItem, Text, TextField, useTheme} from "@fluentui/react";
import {logger} from "../../../../../../services";
import {SuggestedTestingEditableCellMode} from "./SuggestedTestingNumericCell";
import {SuggestedTestingType} from "../../../../enums";
import {calculateSuggestedTestingLabelColor} from "./helpers";
import { FullWidth } from "constants/styles";
import { Control } from "react-hook-form";
import { ControlledDropdown, ControlledTextField, SanitizedText } from "components";


type SuggestedTestingStringCellProps = {
    item: IJobSuggestedTestingItem;
    value: string | null;
    mode: SuggestedTestingEditableCellMode;
    isEditable?: boolean;
    options?: IDropdownOption[];
    onClick: () => void;
    control: Control<{suggestedTestingItems: IJobSuggestedTestingItem[]}>;
    fieldName: "investment";
    index?: number;
}


export const SuggestedTestingStringCell: FunctionComponent<SuggestedTestingStringCellProps> = ({
    item,
    mode,
    value,
    options,
    onClick,
    control,
    fieldName,
    index,
    isEditable = true,
}) => {
    const theme = useTheme();
    const cellStyle = mergeStyleSets({
        cell: {
            minHeight: '100%',
            minWidth: '100%',
            cursor: isEditable ? 'pointer' : 'default',
            paddingLeft: item.deep ? 16 : 0,
        },
        label: {
            color: calculateSuggestedTestingLabelColor(item, theme.schemes?.default?.palette ?? theme.palette),
        },
    });

    const color = calculateSuggestedTestingLabelColor(item, theme.schemes?.default?.palette ?? theme.palette);

    return (
        <Stack
            verticalAlign={'center'}
            grow
            tokens={{ childrenGap: 16 }}
            className={cellStyle.cell}>
            {(!isEditable || (mode === 'view' && isEditable && item.type !== SuggestedTestingType.Investments)) && (
                <StackItem align={'center'} styles={{root: {width: '100%'}}}>
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100,
                        }}
                        onClick={(event) => {
                            onClick();
                            event.preventDefault();
                        }}></div>
                    <SanitizedText textAlign="left" color={color} styles={FullWidth} data={value || ''} />
                </>
                </StackItem>
            )}

            {mode === 'edit' && isEditable && item.type !== SuggestedTestingType.Investments && index !== undefined && (
                <ControlledTextField 
                    id={`SuggestedTesting-${item.id}-TextField`}
                     autoFocus
                      control={control} 
                      name={`suggestedTestingItems.${index}.${fieldName}`}/>
            )}

            {isEditable && item.type === SuggestedTestingType.Investments && index !== undefined && (
                <ControlledDropdown
                    control={control}
                    name={`suggestedTestingItems.${index}.${fieldName}`}
                    options={options || []}
                    onValueChange={onClick}
                />
            )}
        </Stack>
    );
};