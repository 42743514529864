import {useContext} from "react";
import {useQuery} from "react-query";
import axios from "axios";
import { commonHeaders, Context } from "../../../utils";
import { Fee } from "../interfaces";

function useGetFees(clinetIdentifier: string, jobIds: number[]) {
    const context = useContext(Context);
    const {
        data: fees,
        isLoading: isFeesLoading,
        refetch: refetchFees
    } = useQuery(['getFees', clinetIdentifier, jobIds],
        () => axios.get<Fee[]>(`${context?.config?.billingUrl}/api/1.0/fee/get/${clinetIdentifier}?${(jobIds ?? [])
            .reduce((acc: string[] , i): string[] => {
                acc.push(`jobIds=${i}`);
                return acc;
            }, []).join('&')}`, {
            ...commonHeaders()
        })
            .then(resp => resp.data ?? []), {
            enabled: !!context?.config?.billingUrl && !!jobIds.length,
            staleTime: 0
        }
    );

    return {fees, isFeesLoading, refetchFees};
}

export {useGetFees};