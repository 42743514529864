import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IColumn, PrimaryButton, SelectionMode, Stack, Text } from '@fluentui/react';
import { Card, ControlledCheckbox, ControlledNumberField, DataTable } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { CardTableContainerStyles } from 'constants/tableStylesPeset';
import { MethodologySettingsRes } from '../hooks/Testing/useGetMethodologySettings';
import { UseMutateFunction } from 'react-query';
import { AxiosResponse } from 'axios';
import { MethodologySettingsReq } from '../hooks/Testing/useUpdateMethodologySettings';

interface IItem {
    assets: string;
    prefix:
        | 'cash'
        | 'listedSecurities'
        | 'managedFunds'
        | 'termDeposits'
        | 'realProperty'
        | 'unlistedInvestments'
        | 'offMarket'
        | 'contributions'
        | 'transfersInRolloversIn'
        | 'pensionsPaid'
        | 'transfersOutRolloversOut'
        | 'lumpSumPayments';
}

type TestingTableProps = {
    methodologySettings: MethodologySettingsRes;
    updateMethodologySettings: UseMutateFunction<AxiosResponse<any, any>, unknown, MethodologySettingsReq, unknown>;
};

export const TestingAndMethodologySettings = ({ methodologySettings, updateMethodologySettings }: TestingTableProps) => {
    const { formatMessage } = useIntl();
    const { control, handleSubmit, formState } = useForm({ ...DefaultFormSettings, defaultValues: methodologySettings });
    const items: IItem[] = [
        {
            assets: formatMessage({ id: 'cash' }),
            prefix: 'cash',
        },
        {
            assets: formatMessage({ id: 'listedSecurities' }),
            prefix: 'listedSecurities',
        },
        {
            assets: formatMessage({ id: 'managedFunds' }),
            prefix: 'managedFunds',
        },
        {
            assets: formatMessage({ id: 'termDeposits' }),
            prefix: 'termDeposits',
        },
        {
            assets: formatMessage({ id: 'realEstateProperty' }),
            prefix: 'realProperty',
        },
        {
            assets: formatMessage({ id: 'unlistedInvestmentUnrelated' }),
            prefix: 'unlistedInvestments',
        },
        {
            assets: formatMessage({ id: 'otherOffMarket' }),
            prefix: 'offMarket',
        },
        {
            assets: formatMessage({ id: 'contributions' }),
            prefix: 'contributions',
        },
        {
            assets: formatMessage({ id: 'transfersInRolloversIn' }),
            prefix: 'transfersInRolloversIn',
        },
        {
            assets: formatMessage({ id: 'pensionsPaid' }),
            prefix: 'pensionsPaid',
        },
        {
            assets: formatMessage({ id: 'transfersOutRolloversOut' }),
            prefix: 'transfersOutRolloversOut',
        },
        {
            assets: formatMessage({ id: 'lumpSumPayments' }),
            prefix: 'lumpSumPayments',
        },
    ];

    const onRenderInput = (item: IItem, key: string) => {
        if (methodologySettings[item.prefix]) {
            //@ts-ignore
            return <ControlledNumberField name={`${item.prefix}.${key}`} control={control} borderless suffix='%' />;
        }

        return null;
    };

    const columns: IColumn[] = [
        {
            key: 'assets',
            name: formatMessage({ id: 'assets' }),
            fieldName: 'assets',
            minWidth: 100,
            onRender: (item) => (
                <Text variant='medium' styles={{ root: { fontWeight: 500 } }}>
                    {item.assets}
                </Text>
            ),
        },
        {
            key: 'wrapNonDataFed',
            name: formatMessage({ id: 'wrapNonDataFed' }),
            fieldName: 'wrapNonDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'wrap'),
        },
        {
            key: 'wrapDataFed',
            name: formatMessage({ id: 'wrapDataFed' }),
            fieldName: 'wrapDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'wrapDataFed'),
        },
        {
            key: 'lowNonDataFed',
            name: formatMessage({ id: 'lowNonDataFed' }),
            fieldName: 'lowNonDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'low'),
        },
        {
            key: 'lowDataFed',
            name: formatMessage({ id: 'lowDataFed' }),
            fieldName: 'lowDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'lowDataFed'),
        },
        {
            key: 'medium1NonDataFed',
            name: formatMessage({ id: 'medium1NonDataFed' }),
            fieldName: 'medium1NonDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'medium1'),
        },
        {
            key: 'medium1DataFed',
            name: formatMessage({ id: 'medium1DataFed' }),
            fieldName: 'medium1DataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'medium1DataFed'),
        },
        {
            key: 'medium2NonDataFed',
            name: formatMessage({ id: 'medium2NonDataFed' }),
            fieldName: 'medium2NonDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'medium2'),
        },
        {
            key: 'medium2DataFed',
            name: formatMessage({ id: 'medium2DataFed' }),
            fieldName: 'medium2DataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'medium2DataFed'),
        },
        {
            key: 'highNonDataFed',
            name: formatMessage({ id: 'highNonDataFed' }),
            fieldName: 'highNonDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'high'),
        },
        {
            key: 'highDataFed',
            name: formatMessage({ id: 'highDataFed' }),
            fieldName: 'highDataFed',
            minWidth: 100,
            onRender: (item) => onRenderInput(item, 'highDataFed'),
        },
    ];

    const onSubmit = (form: MethodologySettingsRes) => {
        updateMethodologySettings(form);
    };

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
                <Stack.Item>
                    <ControlledCheckbox
                        name='reducedTestingApproachEnabled'
                        control={control}
                        label={formatMessage({ id: 'reducedTestingApproachEnabled' })}
                    />
                </Stack.Item>
                <Stack.Item styles={{ root: { height: 'calc(100% - 20px - 2 * 16px - 32px)' } }}>
                    <DataTable initialColumns={columns} items={items} selectionMode={SelectionMode.none} />
                </Stack.Item>
                <Stack horizontalAlign='end'>
                    <PrimaryButton disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)} text={formatMessage({ id: 'save' })} />
                </Stack>
            </Stack>
        </Card>
    );
};
