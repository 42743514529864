import { Stack } from '@fluentui/react';
import { ControlledDropdown } from 'components';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const DropdownNotColoredAnswer: FunctionComponent = () => {
  const { control, watch } = useForm<{ dropdownAnswer: string }>({
		mode: 'onChange',
		defaultValues: {
			dropdownAnswer: '',
		},
	});

  // !TODO
	const onSubmit = useCallback((props: any) => console.log(props), []);

	useEffect(() => {
		const subscription = watch(onSubmit);
		return () => subscription.unsubscribe();
	}, [watch, onSubmit]);

  return (
    <Stack grow>
      <ControlledDropdown
        options={[]}
        control={control}
        name='dropdownAnswer'/>
    </Stack>
  );
};