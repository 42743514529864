import { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCreateTimesheet, useGetJobTimesheet, useUpdateTimesheet } from './hooks';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { ITableColumn, Loading, ModalWithForm, SanitizedText } from '../../../../../../components';
import { ProceduresDataTable } from '../../../ProceduresDataTable';
import { IJobTimesheetItem } from './interfaces';
import { JobTimesheetForm, SubmitJobTimesheet } from './JobTimesheetForm';
import { IconButton, Stack, Text } from '@fluentui/react';
import { formatDate } from '../../../../../../utils';
import { JobTimesheetTotal } from './JobTimesheetTotal';
import { jobCodes } from './index';
import { useSectionContext } from '../../..';

export const JobTimesheetTemplate = forwardRef((props, ref) => {
    const { formatMessage } = useIntl();
    const { jobGuid } = useJobContext();
    const { response, isLoading, refetch, isRefetching } = useGetJobTimesheet(jobGuid);
    const [timesheet, setTimesheet] = useState<IJobTimesheetItem[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentTimesheet, setCurrentTimesheet] = useState<IJobTimesheetItem>();

    const { createTimesheet, isTimesheetCreating } = useCreateTimesheet();
    const { updateTimesheet, isTimesheetUpdating } = useUpdateTimesheet();

    const childRef = createRef<any>();

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'actions',
                name: '',
                fieldName: '',
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IJobTimesheetItem) => (
                    <IconButton
                        onClick={() => {
                            setCurrentTimesheet(item);
                            setShowEditModal(true);
                        }}
                        iconProps={{ iconName: 'edit' }}
                    />
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                fieldName: 'timesheetDate',
                minWidth: 200,
                maxWidth: 200,
                onRender: (item: IJobTimesheetItem) => <Text>{formatDate(item.timesheetDate?.toString())}</Text>,
            },
            {
                key: 'employee',
                name: formatMessage({ id: 'employee' }),
                fieldName: 'employee',
                minWidth: 250,
                maxWidth: 250,
                onRender: (item: IJobTimesheetItem) => <Text>{item.employee?.fullName}</Text>,
            },
            {
                key: 'jobCode',
                name: formatMessage({ id: 'jobCode' }),
                fieldName: 'jobCode',
                minWidth: 250,
                maxWidth: 250,
                onRender: (item: IJobTimesheetItem) => (item?.jobCode ? <Text>{jobCodes[item.jobCode]}</Text> : <></>),
            },
            {
                key: 'duration',
                name: formatMessage({ id: 'durationMin' }),
                fieldName: 'durationMin',
                minWidth: 180,
                maxWidth: 180,
                onRender: (item) => <SanitizedText data={item.durationMin} numberFormat='round' textAlign='left' />,
            },
            {
                key: 'rate',
                name: formatMessage({ id: 'rateWithCurrency' }, { currency: '$' }),
                fieldName: 'rate',
                minWidth: 100,
                maxWidth: 100,
                onRender: (item) => <SanitizedText data={item.rate} numberFormat='fractional' textAlign='right' />,
            },
            {
                key: 'cost',
                name: formatMessage({ id: 'costWithCurrency' }, { currency: '$' }),
                fieldName: 'cost',
                minWidth: 100,
                maxWidth: 100,
                onRender: (item) => <SanitizedText data={item.cost} numberFormat='fractional' textAlign='right' />,
            },
        ];
    }, [timesheet]);

    const onSubmitSuccess = useCallback(() => {
        setShowEditModal(false);
        setCurrentTimesheet(undefined);
        refetch();
    }, []);

    const handleSubmit = (item: SubmitJobTimesheet) => {
        if (currentTimesheet) {
            // use Update
            updateTimesheet(
                {
                    timesheetGuid: currentTimesheet.uid,
                    timesheetDate: item.timesheetDate,
                    durationMin: item.durationMin,
                    jobCode: item.jobCode,
                },
                { onSuccess: onSubmitSuccess }
            );
        } else {
            createTimesheet({ ...item }, { onSuccess: onSubmitSuccess });
        }
    };

    useImperativeHandle(ref, () => ({
        onAddClick() {
            setShowEditModal(true);
        },
        refresh() {
            refetch();
        },
    }));

    useEffect(() => {
        if (response?.data) setTimesheet(response.data);
    }, [response?.data]);

    const { updateLoadedState } = useSectionContext();

    useEffect(() => {
        updateLoadedState(!(isLoading || isRefetching));
    }, [timesheet]);

    if (isLoading || isRefetching) return <Loading />;
    return (
        <>
            <ProceduresDataTable columns={columns} items={timesheet} emptyProps={{ iconName: 'SearchCalendar' }} />
            <JobTimesheetTotal items={timesheet} />
            <ModalWithForm
                isOpen={showEditModal}
                title={formatMessage({ id: currentTimesheet ? 'editTimesheet' : 'creatingTimesheet' })}
                isSubmitting={isTimesheetCreating || isTimesheetUpdating}
                onDismiss={() => {
                    setShowEditModal(false);
                    setCurrentTimesheet(undefined);
                }}
                onSubmit={() => {
                    childRef?.current?.submit();
                }}>
                <JobTimesheetForm jobGuid={jobGuid} timesheet={currentTimesheet} ref={childRef} onSubmit={handleSubmit} />
            </ModalWithForm>
        </>
    );
});
