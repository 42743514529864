import {useContext} from "react";
import {commonHeaders, Context,} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IClientInfo} from "../../../interfaces";

function useGetAllClients() {
    const context = useContext(Context);
    const {
        data: clients,
        isLoading,
        isFetching,
        refetch
    } = useQuery(['getAllClients'],
        () => axios.get<IClientInfo[]>(`${context?.config?.clientUrl}/api/1.0/clients/mine/`, {
            ...commonHeaders()
        })
            .then(resp => resp.data ?? []), {
            enabled: !!context?.config?.clientUrl
        }
    );

    return {clients, isLoading, isFetching, refetch};
}

export {useGetAllClients};