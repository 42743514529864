import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import { IAttachment } from '../components/attachments/IAttachment';

interface IGetAttachmentsParams {
  jobId: number;
  itemId: number;
}

function useGetAttachments({ jobId, itemId }: IGetAttachmentsParams) {
  const context = React.useContext(Context);

  const { data: attachmentsData, isLoading: isAttachmentsLoading, refetch: refetchAttachments, isFetching: isAttachmentsFetching } = useQuery(
    ['getAttachments', jobId, itemId],
    () => axios.get<IAttachment[]>(
      `${context?.config?.annotationsUrl}/api/1.0/jobs/${jobId}/items/${itemId}/auditQueryAttachments`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
    
      retry: false,
    }
  );

  return { attachmentsData, isAttachmentsLoading, refetchAttachments, isAttachmentsFetching };
}

export { useGetAttachments };