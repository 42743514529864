import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { SanitizedText } from 'components';


export const FeeCalculationProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const { formatMessage } = useIntl();
  
  return (
    <ProceduresDataTable
      items={items}
      isLoading={isLoading}
      columns={[
        {
          key: 'question',
          name: formatMessage({ id: 'question' }),
          fieldName: 'question',
          onRender: (items) => <SanitizedText data={items?.title} />,
          size: 5,
        },
        {
          key: 'answer',
          name: formatMessage({ id: 'answer' }),
          fieldName: 'answer',
          onRender: (items) => <span></span>,
          size: 1,
        },
        {
          key: 'quantityQuestion',
          name: formatMessage({ id: 'quantityQuestion' }),
          fieldName: 'quantityQuestion',
          onRender: (items) => <span></span>,
          size: 1,
        },
        {
          key: 'quantityAnswer',
          name: formatMessage({ id: 'quantityAnswer' }),
          fieldName: 'quantityAnswer',
          onRender: (items) => <span></span>,
          size: 1,
        },
        {
          key: 'feeType',
          name: formatMessage({ id: 'feeType' }),
          fieldName: 'feeType',
          onRender: (items) => <span></span>,
          size: 1,
        },
        {
          key: 'feeApplicable',
          name: formatMessage({ id: 'feeApplicable' }),
          fieldName: 'feeApplicable',
          onRender: (items) => <span></span>,
          size: 1,
        },
      ]}
    />
  );
};

