import {FunctionComponent, useState} from 'react';
import {IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {Card, DataTable} from 'components';
import {ActionButtonStyles, CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {TimesheetTotal} from './TimesheetTotal';
import {format} from "date-fns";
import {IOrderedRequest} from "interfaces";
import {TimesheetInfo} from 'pages/WorkflowPortal/shared';
import {useWorkflows} from "../../../WorkflowLayoutPage";

interface TimesheetDataTableProps {
    timesheet: TimesheetInfo[] | undefined;
    setSort: (value: IOrderedRequest) => void;
    isLoading?: boolean;
}

export const TimesheetDataTable: FunctionComponent<TimesheetDataTableProps> = (props: TimesheetDataTableProps) => {
    const {showClientModal, showTimesheetModal, showEmployeeModal, showJobModal, showFundModal} = useWorkflows();
    const theme = useTheme();
    const {formatMessage} = useIntl();

    const [columns] = useState<IColumn[]>([
        {
            key: 'timesheetDate',
            name: formatMessage({id: 'date'}),
            minWidth: 80,
            maxWidth: 100,
            fieldName: 'TimesheetDate',
            onRender: (items) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => {
                        showTimesheetModal(items.id);
                    }}
                    >
                    {format(new Date(items.timesheetDate), 'dd/MM/yyyy')}
                </Link>
            ),
        },
        {
            key: 'employee',
            name: formatMessage({id: 'employee'}),
            minWidth: 150,
            maxWidth: 200,
            fieldName: 'Employee.FirstName',
            onRender: (items) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => {
                        showEmployeeModal(items.employee.id);
                    }}
                    >
                    {items?.employee?.firstName} {items?.employee?.lastName}
                </Link>
            ),
        },
        {
            key: 'client',
            name: formatMessage({id: 'client'}),
            minWidth: 150,
            maxWidth: 250,
            fieldName: 'Client.Name',
            onRender: (items) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => {
                        showClientModal(items.client.guid);
                    }}>
                    {items.client.name}
                </Link>
            ),
        },
        {
            key: 'fund',
            name: formatMessage({id: 'fund'}),
            minWidth: 150,
            maxWidth: 250,
            fieldName: 'Fund.Name',
            onRender: (items) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => {
                        showFundModal(items.fund.id, items.client.id, items.fund.guid, items.client.guid);
                    }}
                >
                    {items.fund.name}
                </Link>
            ),
        },
        {
            key: 'job',
            name: formatMessage({id: 'job'}),
            minWidth: 80,
            maxWidth: 100,
            fieldName: 'Job.Year',
            onRender: (items) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => {
                        showJobModal(items.job.id);
                    }}
                     >
                    {items.job.year}
                </Link>
            ),
        },
        {
            key: 'durationMin',
            name: formatMessage({id: 'durationMin'}),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'DurationMin',
            onRender: (items) => <Text>{items.durationMin}</Text>,
        },
        {
            key: 'rate',
            name: formatMessage({id: 'rate'}) + ' ($)',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'Rate',
            onRender: (items) => <Text>{items.rate}</Text>,
        },
        {
            key: 'cost',
            name: formatMessage({id: 'cost'}) + '($)',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'cost',
            onRender: (items) => <Text>{items.cost.toFixed(2)}</Text>,
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                sortableColumnKeys={['timesheetDate', 'employee', 'client', 'fund', 'job', 'durationMin', 'rate']}
                initialColumns={columns}
                items={props.timesheet || []}
                enableShimmer={props.isLoading}
                setOrder={props.setSort}
                selectionMode={SelectionMode.none}
                containerHeight='90%'
            />
            <TimesheetTotal items={props.timesheet || []}/>
        </Card>
    );
};
