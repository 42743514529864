import {IColumn, Stack} from '@fluentui/react';
import {SanitizedText} from 'components';
import {IReport} from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {ReportTable} from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {GetSummaryItemColor, GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {isAgreedAnswer, isAutoAnswer} from "../../answers";
import {isCashBankStatement, isGrandTotalType, isTotalType, isValueType} from "./consts";
import {ColumnInfo} from "../../../../enums";
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGLInvestmentSummary = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
        
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            if (!isCashBankStatement(i) && (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) ||
                isCashBankStatement(i) && (isAgreedAnswer(i.answerText2) || isAutoAnswer(i.answerText2))) {
                if (!isTotalType(i)) {
                    verifiedCoverage += i.marketGainLoss;
                }
            }
            if (isTotalType(i)) {
                incomeTotal += i.marketGainLoss;
            }
        })

        return {
            verified: incomeTotal != 0 ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const rebuildItems = (arr: any[]): IReport[] => {
        return (arr || []).reduce((acc, i) => {
            const isValue = isValueType(i);
            const isTotal = isTotalType(i);
            const isGrandTotal = isGrandTotalType(i);
            const isCashBank = isCashBankStatement(i);
            acc.push({
                ...i,
                showAttachmentIcon: isValue || isTotal || isGrandTotal,
                showCommentsIcon: isValue || isTotal || isGrandTotal,
                showControl: (isValue || isGrandTotal) && !isCashBank,
                showControl1: isValue && !isCashBank,
                showControl2: isValue,
                showControl3: isTotal,
                children: rebuildItems(i?.children)
            })

            return acc;
        }, [])
    }
    
    const reportItems = useMemo(() => rebuildItems(items), [items]);
    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentsIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 300,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'asx',
                name: formatMessage({ id: 'asx' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'asx',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'answerText',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl && <GeneratedAnswer
                            itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            answerControlType={AnswerControlType.WorkDoneSwitch} />}
                    </Stack>
                )
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' />,
            },
            {
                key: 'aveCost',
                name: formatMessage({ id: 'aveCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'aveCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'answerText1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText1',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl1 && <GeneratedAnswer itemId={item?.id} tableType={TableType[item?.reportType as keyof typeof TableType]} value={item?.answerText1}
                            auto={item?.isAuto1} disabled={item?.isAuto1} answerControlType={AnswerControlType.WorkDoneSwitch} columnInfo={ColumnInfo.AnswerText1} />}
                    </Stack>
                )
            },
            {
                key: 'mktPrice',
                name: formatMessage({ id: 'mktPrice' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'mktPrice',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'cost',
                name: formatMessage({ id: 'cost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'answerText2',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText2',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl2 && <GeneratedAnswer itemId={item?.id} tableType={TableType[item?.reportType as keyof typeof TableType]} value={item?.answerText2}
                            auto={item?.isAuto2} disabled={item?.isAuto2} answerControlType={AnswerControlType.WorkDoneSwitch} columnInfo={ColumnInfo.AnswerText2}/>}
                    </Stack>
                )
            },
            {
                key: 'marketGainLoss',
                name: formatMessage({ id: 'market' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketGainLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'unrealisedGainLoss',
                name: formatMessage({ id: 'unrealisedGainLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unrealisedGainLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'percent',
                name: formatMessage({ id: 'percentGainLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percent',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='percentage-fractional' />,
            },
            {
                key: 'percentTotal',
                name: formatMessage({ id: 'portfolioWeight' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percentTotal',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='percentage-fractional' />,
            },
            {
                key: 'answerText3',
                name: formatMessage({ id: 'agreedToInvestmentStrategy' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText3',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl3 && <GeneratedAnswer itemId={item?.id} tableType={TableType[item?.reportType as keyof typeof TableType]} value={item?.answerText3}
                            auto={item?.isAuto3} disabled={item?.isAuto3} answerControlType={AnswerControlType.WorkDoneSwitch} columnInfo={ColumnInfo.AnswerText3} />}
                    </Stack>
                )
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const reports = useMemo(() => {
        return reportItems.reduce((acc: IReport[], i: any) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
                i.colors = {
                    verified: GetSummaryItemColor(i.summary.verified, i.answerText)
                }
            }
            acc.push(i)
            return acc;
        }, []);
    }, [summaryUpdateTrigger]);

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        if (item.id && field) {
            item[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
            setSummaryUpdateTrigger(Date.now())
        }
    }

    return <ReportTable
        initialColumns={columns} 
        openAll={true}
        header={{ rowHeight: _ExtendedHeaderRowHeight }}
        items={reports} 
        groupProps={{
            mergedGroupHeader: {propertyKey: 'name'},
            summary: {
                show: true,
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    return <>
                        <Stack horizontal tokens={{childrenGap: 16}}>
                            <Stack.Item>
                                <GroupSummaryItem color={group?.colors?.verified} value={group?.summary.verified} type={'verified'}></GroupSummaryItem>
                            </Stack.Item>
                        </Stack>
                    </>
                }
            }
        }}
        isFetching={isFetching} 
    />;
};
