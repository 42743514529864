import { FunctionComponent, useMemo } from 'react';
import {FontWeights, mergeStyleSets, Stack, useTheme} from '@fluentui/react';
import { DoghnutChart } from 'components/charts';

interface IDoughnutDataItem {
    name: string;
    count: number;
}

// !TODO add click handler on widget to open modals
export interface IDoughnutWidget {
    title: string;
    data: IDoughnutDataItem[];
}

export const DoughnutWidget: FunctionComponent<IDoughnutWidget> = ({ title, data }) => {
    const theme = useTheme();

    const { dataNumbers, groupedLabels } = useMemo(() => {
        const dataNumbers: number[] = data.map((item) => item.count);
        const groupedLabels: string[] = data.map((item) => item.name);
        return { dataNumbers, groupedLabels };
    }, [data]);

    const classNames = mergeStyleSets({
        container: {
            height: '100%',
            boxSizing: 'border-box',
            border: `2px solid ${theme.palette.themePrimary}`,
            borderRadius: 2,
        },
        title: {
            padding: '8px 16px',
            backgroundColor: theme.palette.themePrimary,
            fontWeight: FontWeights.bold
        },
        content: {
            overflow: 'hidden',
            padding: '16px',
        },
    });

    return (
        <Stack className={classNames.container}>
            <Stack.Item className={classNames.title}>{title}</Stack.Item>
            <Stack.Item grow={1} className={classNames.content}>
                <DoghnutChart dataNumbers={dataNumbers} groupedLabels={groupedLabels} />
            </Stack.Item>
        </Stack>
    );
};
