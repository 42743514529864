import {FunctionComponent, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {IDropdownOption, Stack} from '@fluentui/react';
import {Card, ControlledDropdown, ControlledTextField} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {phoneNumberRegexp} from 'constants/validators';
import {ModalWithForm} from "components/ModalForm";
import {DetailedSoftwareType, SoftwareType} from "enums";
import {AuditReportDateType, ClientRiskRating, EmployeePosition} from "pages/WorkflowPortal/shared";
import {useCreateNewClient} from "../../hooks";
import {useGetEmployeesByPosition} from 'pages/WorkflowPortal/Employees';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

export interface ICreateClientCommand {
    clientName: string;
    partnerId: number;
    phone: string;
    clientRiskRating: ClientRiskRating,
    unsignedARDate: AuditReportDateType;
    accountingSoftware: SoftwareType | DetailedSoftwareType;
}

export const ClientsAddItemModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title}: Props) => {
        const defaultFormValues = {
            clientName: '',
            partner: '',
            phone: '',
            clientRiskRating: ClientRiskRating.Low,
            unsignedARDate: AuditReportDateType.Yes,
            accountingSoftware: DetailedSoftwareType.ClassSuperAPI
        };
        const {control, handleSubmit, formState, reset} = useForm<ICreateClientCommand>({
            ...DefaultFormSettings,
            defaultValues: defaultFormValues,
        });
        const {formatMessage} = useIntl();
        const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
        const {employeesByPosition, isEmployeesLoading} = useGetEmployeesByPosition(EmployeePosition.Partner);
        const {addNewClient, addNewClientLoading} = useCreateNewClient(() => {
            onDismiss();
            reset();
        });

        const onSubmit = (data: ICreateClientCommand) => {
            addNewClient({
                partnerId: data.partnerId,
                guid: null,
                name: data.clientName || '',
                mainPhone: data.phone || '',
                auditReportDate: data.unsignedARDate || null,
                detailedSoftwareType: data.accountingSoftware as DetailedSoftwareType || null,
                riskRating: data.clientRiskRating || null,
                softwareType: data.accountingSoftware?.includes('ClassSuper') ? SoftwareType.ClassSuper :
                    data.accountingSoftware as SoftwareType || null
            })
            ;
        };

        const partnerOptions = useMemo<IDropdownOption[]>(() => employeesByPosition?.data.map(x => ({
            key: x.id,
            text: `${x.firstName} ${x.lastName}`
        })) || [], [employeesByPosition]);

        const optionsRisk: IDropdownOption[] = Object.entries(ClientRiskRating).map(([number, word]) => ({
            key: number,
            text: word
        }));

        const optionsSoftware: IDropdownOption[] = [
            {key: SoftwareType.Supermate, text: formatMessage({id: 'supermate'})},
            {key: SoftwareType.BGL, text: formatMessage({id: 'bglSimpleFund'})},
            {key: SoftwareType.BGL360, text: formatMessage({id: 'bgl360'})},
            {key: SoftwareType.Nothing, text: formatMessage({id: 'other'})},
            {key: DetailedSoftwareType.ClassSuperAPI, text: formatMessage({id: 'classSuperApi'})},
            {key: DetailedSoftwareType.ClassSuperPDF, text: formatMessage({id: 'classSuperPDF'})},
        ];

        return (
            <ModalWithForm isOpen={isOpen}
                           onDismiss={() => {
                               reset();
                               onDismiss();
                           }}
                           title={title}
                           isSubmitting={addNewClientLoading}
                           submitDisabled={!formState.isDirty || !formState.isValid || addNewClientLoading}
                           onSubmit={handleSubmit(onSubmit)}>
                <Stack tokens={{childrenGap: 16}}>
                    <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                        <Stack horizontal tokens={{childrenGap: 16}} wrap>
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name='clientName'
                                    required
                                    label={formatMessage({id: 'clientName'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledDropdown options={partnerOptions} name='partnerId'
                                                    disabled={isEmployeesLoading}
                                                    label={formatMessage({id: 'partner'})}
                                                    control={control}/>
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledDropdown
                                    options={optionsSoftware}
                                    name='accountingSoftware'
                                    label={formatMessage({id: 'accountingSoftware'})}
                                    control={control}
                                />
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledDropdown
                                    name='clientRiskRating'
                                    required
                                    label={formatMessage({id: 'clientRiskRating'})}
                                    control={control}
                                    options={optionsRisk}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledDropdown
                                    options={[
                                        {key: 'No', text: 'No'},
                                        {key: 'Yes', text: 'Yes'},
                                    ]}
                                    name='unsignedARDate'
                                    label={formatMessage({id: 'unsignedARDate'})}
                                    control={control}
                                />
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name='phone'
                                    required
                                    label={formatMessage({id: 'mainPhone'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                        pattern: {
                                            value: phoneNumberRegexp,
                                            message: formatMessage({id: 'invalidPhoneNumber'})
                                        },
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack>
            </ModalWithForm>
        );
    })
;
