import {IOrderedRequest} from "interfaces";
import {EntityStatus, TeamInfo} from "../../../shared";
import {ActionButtonStyles, CardTableContainerStyles} from "../../../../../constants/tableStylesPeset";
import {BaseDialog, Card, DataTable} from "components";
import {ActionButton, IColumn, Link, SelectionMode, useTheme } from "@fluentui/react";
import {useIntl} from "react-intl";
import {useWorkflows} from "../../../WorkflowLayoutPage";
import {useState} from "react";
import {useChangeTeamsStatus} from "../../hooks";

type TeamDataTableProps = {
    teams?: TeamInfo[];
    isLoading?: boolean;
    setSort: (value: IOrderedRequest) => void;
}

export const TeamDataTable = (props: TeamDataTableProps) => {
    const {formatMessage} = useIntl();
    const {showTeamModal} = useWorkflows();
    const [currentTeams, setCurrentTeams] = useState<{ teamId: number; status: EntityStatus } | null>(null);
    const theme = useTheme();

    const {
        update: changeTeamStatus,
        isUpdating
    } = useChangeTeamsStatus(()=> setCurrentTeams(null));

    const handleChangeTeamStatus = () => {
        if (currentTeams) {
            changeTeamStatus({
                teamId: currentTeams?.teamId,
                status: currentTeams?.status === EntityStatus.DeActive ? EntityStatus.Active : EntityStatus.DeActive,
            });
        }
    };


    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            isSorted: false,
            onRender: (items: TeamInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={{root: {fontWeight: 500}}}
                    onClick={() => setCurrentTeams({teamId: items.id, status: items.status!})}>
                    {items.status !== EntityStatus.DeActive ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'name',
            name: formatMessage({id: 'name'}),
            minWidth: 150,
            maxWidth: 600,
            fieldName: 'Name',
            onRender: (items: TeamInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles} onClick={() => showTeamModal(items.id)}
                >
                    {items.name}
                </Link>
            ),
        }
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                compact
                sortableColumnKeys={[]}
                initialColumns={columns}
                items={props.teams || []}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
                setOrder={props.setSort}
            />

            {!!currentTeams?.teamId && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={handleChangeTeamStatus}
                    toggleHidden={() => setCurrentTeams(null)}
                    noDisabled={isUpdating}
                    yesDisabled={isUpdating}
                />
            )}
        </Card>
    )
}