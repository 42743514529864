import {IColumn, Stack} from "@fluentui/react";
import {SanitizedText} from "components";
import {Attachment, JobComment, useReportValidationsContext} from "pages/JobPortal/components";
import { useEffect, useMemo, useRef, useState } from "react";
import {useIntl} from "react-intl";
import {getDepthPaddingStyles, ReportTable} from "../Table/ReportTable";
import {TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import {WorkDoneSwitchAnswer} from "../../answers";
import {ColumnInfo} from "../../../../enums";
import { ReportValidationCell } from "../shared";


export const StatmentofFP = ({items, isFetching}: ReportTemplateTypeProps) => {
    const {formatMessage} = useIntl();
    const isOpenedItem = (item: any): boolean => [0, 1].indexOf(item.depth) !== -1;

    const columns: IColumn[] = useMemo(() => ([
        {
            key: 'actions',
            name: formatMessage({id: 'actions'}),
            minWidth: 60,
            maxWidth: 60,
            fieldName: 'actions',
            onRender: (item) => (
                <Stack horizontal>
                    {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>}
                    {<JobComment itemId={item?.id} hasComments={item?.hasComments}/>}
                </Stack>
            ),
        },
        {
            key: 'description',
            name: formatMessage({id: 'description'}),
            minWidth: 300,
            fieldName: 'description',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)}
                               isHeadingRow={item?.isHeadingRow} format={item?.format} minWidth={'auto'}/>
            )
        },
        {
            key: 'fundNotesReferenceNumber',
            name: formatMessage({id: 'fundNotesReferenceNumber'}),
            minWidth: 60,
            maxWidth: 60,
            fieldName: 'fundNotesReferenceNumber',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow}
                               format={item?.format} textAlign='center'/>
            )
        },
        {
            key: 'reviewed',
            name: formatMessage({id: 'reviewed'}),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'reviewed',
            onRender: (item, i) => {
                return (
                    <Stack verticalAlign='center' grow>
                        {!item?.isTotal && item?.depth >= 1 &&
                                <WorkDoneSwitchAnswer itemId={item?.id}
                                                      tableType={TableType[item?.reportType as keyof typeof TableType]}
                                                      value={item.answerText}
                                                      columnInfo={ColumnInfo.AnswerText}
                                />
                        }
                    </Stack>
                )
            }
        },
        {
            key: 'balance',
            name: formatMessage({id: 'thisYear'}),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'balance',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow}
                               format={item?.format} textAlign='right' numberFormat='fractional'/>
            )
        },
        {
            key: 'previousYearBalance',
            name: formatMessage({id: 'previousYearBalance'}),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'previousYearBalance',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow}
                               format={item?.format} textAlign='right' numberFormat='fractional'/>
            )
        },
        {
            key: 'change',
            name: formatMessage({id: 'change'}),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'change',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow}
                               format={item?.format} textAlign='right'/>
            )
        },
        {
            key: 'pChange',
            name: formatMessage({id: 'pChange'}),
            minWidth: 100,
            maxWidth: 128,
            fieldName: 'pChange',
            onRender: (item, _, column) => (
                <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow}
                               format={item?.format} textAlign='right' numberFormat='percentage'/>
            )
        },
        {
            key: 'isSumChecked',
            name: formatMessage({id: 'isSumChecked'}),
            minWidth: 150,
            maxWidth: 150,
            fieldName: 'isSumChecked',
            onRender: (item, _, column) => (
                <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />
            )
        },
    ]), [formatMessage]);

    return <ReportTable initialColumns={columns} 
                        isOpenedItem={isOpenedItem}
                        items={items} 
                        isFetching={isFetching}
    />
};