import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {
    DefaultButton,
    mergeStyles,
    mergeStyleSets,
    NeutralColors,
    SharedColors,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useIsDarkTheme} from "../../../../../../../hooks";
import {useUpdateQueryRating} from "../hooks/useUpdateQueryRating";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {useGetQueryRating} from "../hooks/useGetQueryRating";

interface IAuditQueryRatingProps {
    disabled?: boolean;
}
export const AuditQueryRating: FunctionComponent<IAuditQueryRatingProps> = ({ 
                                                                                disabled = false
                                                                            } : IAuditQueryRatingProps) => {
    const {formatMessage} = useIntl();
    
    const jobContext = useJobContext();
    
    const {jobQueryRating} = useGetQueryRating(jobContext.jobId);
    const {update, isUpdating} = useUpdateQueryRating();
    
    const theme = useTheme();
    const isDark = useIsDarkTheme();
    const borderColor = isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30;
    
    const [ratingValue, setRatingValue] = useState<number | undefined>(jobQueryRating.rating);
    
    const className = mergeStyleSets({
        master: [{
            padding: 16,
            border: `1px solid ${borderColor}`,
            borderRadius: 4,
        }],
        first: [{
            minWidth: 36,
            width: 36,
            padding: '0 10px',
            borderColor: NeutralColors.gray60,
            color: theme.semanticColors.successIcon,
            borderRadius: '4px 0 0 4px',
            '&:hover, &:selected,&.is-checked': {
                backgroundColor: SharedColors.yellowGreen10,
                borderColor: theme.semanticColors.successIcon,
                color: theme.palette.white
            }
        }],
        second: [{
            minWidth: 36,
            width: 36,
            padding: '0 10px',
            borderLeft: 'none',
            borderColor: NeutralColors.gray60,
            color: theme.palette.orangeLighter,
            borderRadius: 0,
            '&:hover, &:selected, &.is-checked': {
                backgroundColor: theme.palette.orangeLighter,
                borderColor: theme.palette.orangeLighter,
                color: theme.palette.white
            }
        }],
        third: [{
            minWidth: 36,
            width: 36,
            padding: '0 10px',
            borderLeft: 'none',
            borderColor: NeutralColors.gray60,
            color: theme.semanticColors.errorIcon,
            borderRadius: '0 4px 4px 0',
            '&:hover, &:selected, &.is-checked': {
                backgroundColor: theme.palette.red,
                borderColor: theme.semanticColors.errorIcon,
                color: theme.palette.white
            }
        }]
    });
    
    const onClick = useCallback((value: number) => {
        if (value === ratingValue) {
            return;
        }
        
        setRatingValue(value);
        update({ id: jobContext.jobId, rating: value })
    }, []);
    
    useEffect(() => {
        setRatingValue(jobQueryRating.rating)
    }, [jobQueryRating?.rating])
    
    return (
        <Stack className={className.master} tokens={{childrenGap: 16}}>
            <Stack.Item>
                <Text theme={theme.schemes?.default}>{formatMessage({id: 'queryRating'})}</Text>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontalAlign='center' horizontal grow>
                    <DefaultButton toggle
                                   onClick={() => { onClick(1) }}
                                   disabled={disabled || isUpdating}
                                   checked={ratingValue === 1}
                                   className={className.first}
                                   text={'1'}
                                   />
                    <DefaultButton toggle
                                   onClick={() => { onClick(2) }}
                                   disabled={disabled || isUpdating}
                                   checked={ratingValue === 2}
                                   className={className.second}
                                   text={'2'}
                    />
                    <DefaultButton toggle
                                   onClick={() => { onClick(3) }}
                                   disabled={disabled || isUpdating}
                                   checked={ratingValue === 3}
                                   className={className.third}
                                   text={'3'}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}