import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {useNotifications} from "../../../../components/notifications";
import {useWorkflows} from "../../WorkflowLayoutPage";
import {useIntl} from "react-intl";
import {MessageBarType} from "@fluentui/react";
import {NotificationAction} from "../../../../components/notifications/NotificationAction";
import {FundInfo} from "../../shared";


type MutateDataType = {
    name: string;
    trustee: string;
    abn: string;
    clientId: string;
    primaryContactId?: number;
};

function useCreateNewFund(onSuccess: () => void) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {showFundModal} = useWorkflows();
    const {formatMessage} = useIntl();
    const {mutate: addNewFund, isLoading: addNewFundLoading} = useMutation(
        (data: MutateDataType) => axios.post<FundInfo>(`${context?.config?.clientUrl}/api/1.0/clients/${data.clientId}/funds`, data, commonHeaders()),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('getFundsByFilter');
                onSuccess();
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'createdSuccessfully'}),
                    description: formatMessage({id: "entityWasCreated"}, {name: variables.name}),
                    actions: (
                        <NotificationAction
                            onClick={() => {
                                console.debug('Create fund guid: ' + data.data.guid);
                                showFundModal(data.data.id, data.data.clientId, data.data.guid, data.data.clientGuid);
                            }}>{formatMessage({id: 'view'})}</NotificationAction>
                    )
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {addNewFund, addNewFundLoading};
}

export {useCreateNewFund};
