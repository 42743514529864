import React, {useContext} from "react";
import {commonHeaders, Context} from "../../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IReportInfo} from "../../../../../interfaces/IReportsInfo";

function useGetTabReports(jobId: number, tabReference: string) {
    const context = useContext(Context);
    const host = context?.config?.reportsUrl;

    const {
        data,
        isLoading: isReportContentLoading,
        refetch: refetchReport,
        isFetching: isReportContentFetching
    } = useQuery(['getExternalReportsItems', jobId, tabReference], () =>
            axios.get<IReportInfo>(`${host}/api/1.0/Reports/GetOnlyExternal/${jobId}`, {
                ...commonHeaders(),
                params: {tabReference: tabReference}
            }),
        {
            enabled: !!context?.config?.reportsUrl && !!context.tenantId,
            retry: false,
        }
    );

    const cleanedData = React.useMemo(
        () =>
            (data?.data?.reports || [])?.map((r) => {
                if (r?.report) {
                    return {...r, report: r.report.filter((item) => item?.depth === 0)};
                }
                return r;
            }),
        [data]
    );

    return {
        reportContent: {...data, reports: cleanedData},
        isReportContentLoading,
        refetchReport,
        isReportContentFetching
    };
}

export {useGetTabReports}