import moment from 'moment-timezone';
import {Text} from "@fluentui/react";
import { FunctionComponent } from "react";
import { globalTimeZone } from "../utils";

type TimeZoneDateTimeTextProps = {
	dateTime?: string | Date;
	
	timezone?: string;
	format?: TimeZoneDateTimeFormat;
}

enum TimeZoneDateTimeFormat {
	DateOnlyFormat = 'DD/MM/yyyy'
}


export const TimeZoneDateTimeText: FunctionComponent<TimeZoneDateTimeTextProps> = ({ dateTime, timezone = globalTimeZone, format = TimeZoneDateTimeFormat.DateOnlyFormat, ...props }: TimeZoneDateTimeTextProps) => {
	return (
		<>
			<Text>{moment.utc(dateTime).tz(timezone).format(format)}</Text>
		</>
	);
};