import {INavLink, Stack, useTheme} from '@fluentui/react';
import {Header} from './Header';
import {VerticalMenu} from './VerticalMenu';
import React, {FunctionComponent, ReactNode} from 'react';
import {useBoolean} from '@fluentui/react-hooks';
import {observer} from 'mobx-react';
import {NotificationPanel} from "./notifications";
import { Footer } from './Footer';

interface Props {
    children: ReactNode;
    links: INavLink[] | [];
    headerLeft?: ReactNode;
    headerRight?: ReactNode;
    footerLeft?: ReactNode;
    footerRight?: ReactNode;
}

export const Layout: FunctionComponent<Props> = observer(({children, links, headerLeft, headerRight, footerLeft, footerRight}: Props) => {
    const theme = useTheme();
    const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);
    const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false);

    return (
        <Stack
            styles={{
                root: {
                    height: '100%',
                    background: theme.palette.white,
                },
            }}>
            <Header openPanel={openPanel} left={headerLeft} right={headerRight} />
            <Stack horizontal styles={{ root: { height: 'calc(100% - 49px)'}}}>
                <VerticalMenu links={links} isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>
                <Stack
                    styles={{
                        root: {
                            height: footerLeft || footerRight ? 'calc(100% - 49px)' : '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            width: `calc(100% - ${isCollapsed ? 44 : 200}px)`,
                            background: theme.palette.neutralLighter,
                            padding: 16,
                            boxSizing: 'border-box',
                            transition: 'width 0.1s ease-in-out'
                        },
                    }}
                    tokens={{childrenGap: 16}}>
                    <Stack.Item>
                    </Stack.Item>
                    <Stack.Item styles={{root: {height: '100%'}}}>{children}</Stack.Item>
                    <Footer left={footerLeft} right={footerRight} />
                </Stack>
            </Stack>
            <NotificationPanel isOpen={isOpen} onDismiss={dismissPanel}/>
        </Stack>
    );
});
