import {useIntl} from 'react-intl';
import {DefaultButton, PrimaryButton, Shimmer, ShimmerElementType, Stack} from '@fluentui/react';
import {DefaultFormSettings} from 'constants/forms';
import {Card, ControlledCheckbox, ControlledNumberField, ControlledTextField} from 'components';
import {FunctionComponent, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {SmtpSettings, useGetEmailSettings} from "../../hooks/EmailSettings/useGetEmailSettings";
import {useUpdateEmailSettings} from "../../hooks/EmailSettings/useUpdateEmailSettings";
import { useSendTestEmail } from '../../hooks/EmailSettings/useSendTestEmail';

interface IEmailSettingsForm {
    smtpSettings: SmtpSettings | null;
    isTestMode: boolean;
    testEmail: string;
    crmAdminEmail: string;
}

export const EmailSettingsForm: FunctionComponent = () => {
    const {formatMessage} = useIntl();
    const {emailSettings, isLoading: getSettingsLoading} = useGetEmailSettings();
    const { updateEmailSettings, isLoading: updateSettingsLoading } = useUpdateEmailSettings();
    const { sendTestEmail } = useSendTestEmail();

    const {control, setValue, handleSubmit, formState} = useForm<IEmailSettingsForm>({
        ...DefaultFormSettings,
        defaultValues: {
            smtpSettings: emailSettings?.data?.smtpSettings,
            isTestMode: emailSettings?.data?.isTestMode,
            testEmail: emailSettings?.data?.testEmail,
            crmAdminEmail: emailSettings?.data?.crmAdminEmail,
        },
    });

    useEffect(() => {
        if (emailSettings?.data) {
            setValue('smtpSettings', emailSettings?.data?.smtpSettings);
            setValue('isTestMode', emailSettings?.data?.isTestMode);
            setValue('testEmail', emailSettings?.data?.testEmail);
            setValue('crmAdminEmail', emailSettings?.data?.crmAdminEmail);
        }
    }, [emailSettings?.data, setValue]);

    const onSubmit = (form: IEmailSettingsForm) => {
        updateEmailSettings({...form});
    };

    const sendTestEmailHandle = () => {
        sendTestEmail();
    };

    return (
        <Card>
            {!emailSettings?.data || getSettingsLoading || updateSettingsLoading ? (
                <Shimmer shimmerElements={[{type: ShimmerElementType.line, height: 247}]}/>
            ) : (
                <>
                    <Stack tokens={{childrenGap: 16}}>
                            <ControlledTextField
                                name='smtpSettings.senderSMTP'
                                control={control}
                                label={formatMessage({ id: 'smtpServerAddress' })}
                                rules={{ required: formatMessage({ id: 'requiredField' }) }}
                            />
                            <ControlledTextField
                                name='smtpSettings.smtpUser'
                                control={control}
                                label={formatMessage({ id: 'smtpServerUsername' })}
                                rules={{ required: formatMessage({ id: 'requiredField' }) }}
                            />
                            <ControlledTextField
                                name='smtpSettings.smtpPassword'
                                control={control}
                                label={formatMessage({ id: 'smtpServerPassword' })}
                                isPassword
                            />
                            <ControlledNumberField
                                name='smtpSettings.smtpPort'
                                control={control}
                                label={formatMessage({ id: 'smtpServerPort' })}
                                rules={{ required: formatMessage({ id: 'requiredField' }) }}
                            />
                            <ControlledCheckbox
                                name='smtpSettings.sslEnabled'
                                control={control}
                                label={formatMessage({ id: 'sslEnabled' })}
                            />
                            <ControlledTextField
                                name='smtpSettings.senderEmail'
                                control={control}
                                label={formatMessage({ id: 'senderEmail' })}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                    }}
                            />
                            <ControlledTextField
                                name='smtpSettings.senderName'
                                control={control}
                                label={formatMessage({ id: 'senderName' })}
                                rules={{ required: formatMessage({ id: 'requiredField' }) }}
                            />
                            <ControlledCheckbox
                                name='isTestMode'
                                control={control}
                                label={formatMessage({ id: 'testEmailModeEnabled' })}
                            />
                            <ControlledTextField
                                name='testEmail'
                                control={control}
                                label={formatMessage({ id: 'testEmailAddress' })}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                            <ControlledTextField
                                name='crmAdminEmail'
                                control={control}
                                label={formatMessage({ id: 'adminEmail' })}
                                rules={{
                                    required: formatMessage({ id: 'requiredField' }),
                                    minLength: { value: 3, message: formatMessage({ id: 'minLength' }, { min: 3 }) },
                                }}
                            />
                    </Stack>
                        <Stack horizontal horizontalAlign='end' styles={{ root: { marginTop: '16px' } }} tokens={{ childrenGap: 16 }}>
                            <Stack.Item>
                                <PrimaryButton
                                    disabled={!formState.isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    text={formatMessage({ id: 'save' })}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton
                                    onClick={sendTestEmailHandle}
                                    text={formatMessage({ id: 'sendTestEmail' })}
                                />
                            </Stack.Item>
                    </Stack>
                </>
            )}
        </Card>
    );
};
