import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { DataTable, SanitizedText } from 'components';
import {Attachment, GeneratedAnswer, JobComment, useSectionContext} from 'pages/JobPortal/components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AnswerControlType, TableType } from '../../../../../../enums';
import {ReportTemplateTypeProps} from "../Types";
import { ReportTable } from "../Table/ReportTable";



export const ClassSuperHoldingsBalanceReconciliation = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { section } = useSectionContext();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'holdingAccount',
                name: formatMessage({ id: 'holdingsAccount' }),
                minWidth: 250,
                fieldName: 'holdingAccount',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'fundHoldingReference',
                name: formatMessage({ id: 'fundHoldingReference' }),
                minWidth: 200,
                maxWidth: 220,
                fieldName: 'fundHoldingReference',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'marketType',
                name: formatMessage({ id: 'marketType' }),
                minWidth: 200,
                maxWidth: 220,
                fieldName: 'marketType',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'ledgerBalance',
                name: formatMessage({ id: 'ledgerBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'ledgerBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='default' />,
            },
            {
                key: 'feedBalance',
                name: formatMessage({ id: 'feedBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'feedBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='default' />,
            },
            {
                key: 'difference',
                name: formatMessage({ id: 'difference' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'difference',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='default' />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed',
                onRender: (item, _, column) => 
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText} auto={item?.isAuto} disabled={item?.isAuto}
                            answerControlType={AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType]} />
                    </Stack>,
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={items}
        enableShimmer={isFetching}
    />
};
