import {
    AnimationStyles,
    FontSizes,
    IMessageBarProps,
    mergeStyles,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    useTheme
} from "@fluentui/react";
import {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";


export interface INotification extends IMessageBarProps {
    dateEvent?: Date;
    name: string;
    description: string;
    type: MessageBarType;
    promise?: boolean;
}

export const Notification: FunctionComponent<INotification> = ({
                                                                   name,
                                                                   description,
                                                                   type,
                                                                   actions,
                                                                   styles, ...props
                                                               }: INotification) => {

    const theme = useTheme();
    const {formatMessage} = useIntl();
    const [iClosing, setClosing] = useState<boolean>(false)
    const progressStyles = mergeStyles({
        position: 'absolute',
        bottom: -8,
        left: 0
    });
    

    return (
        <MessageBar
            messageBarType={type}
            isMultiline={true}
            actions={actions}
            onDismiss={props.onDismiss}
            dismissButtonAriaLabel={formatMessage({id: 'close'})}
            title={name}
            styles={{
                root: {
                    minWidth: '25%',
                    width: 'auto',
                    borderRadius: theme.effects.roundedCorner2,
                    boxShadow: theme.semanticColors.cardShadow,
                    ...AnimationStyles.slideLeftIn40
                },
                text: { fontSize: FontSizes.medium, color: theme.schemes?.default?.semanticColors.bodyText},
                ...styles,

            }}>
            {description}
            <ProgressIndicator progressHidden={!props.promise} className={progressStyles}
                               styles={{root: {width: '100%'}}}/>
        </MessageBar>
    );


};