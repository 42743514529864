import React, {FunctionComponent, useMemo, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {INavLink} from '@fluentui/react';
import {IMenuLink, Layout} from 'components';
import {RouterPaths} from 'navigation';
import {Menu} from './components/Menu';
import {HelpMenu} from './components/HelpMenu';
import {useIntl} from "react-intl";
import { PortalRole, useAuthContext, useTenantInfo, useWorkContext } from "../../providers";

export const MainLayoutPage: FunctionComponent = () => {
    const location = useLocation();
    const {isBlackLabel, isWorkpapers} = useTenantInfo();
    const {user} = useAuthContext();
    const {isInRole, isInRoleOrSuperAdministrator} = useWorkContext();
    const {formatMessage} = useIntl();
    const links = useMemo<IMenuLink[]>(() => [
        {
            name: 'Workflow',
            isHidden: isBlackLabel,
            url: '',
            key: 'workflow',
            iconProps: {iconName: 'PreviewLink'},
            isExpanded: location.pathname.includes(RouterPaths.workflow.root),
            links: [
                {
                    name: 'Dashboard',
                    url: RouterPaths.workflow.dashboard,
                    key: 'workflow-dashboard',
                    iconProps: {iconName: 'PieSingle'},
                },
                {
                    name: 'Jobs',
                    url: RouterPaths.workflow.jobs,
                    key: 'workflow-jobs',
                    iconProps: {iconName: 'KnowledgeArticle'},
                },
                {
                    name: 'Funds',
                    url: RouterPaths.workflow.funds,
                    key: 'workflow-funds',
                    iconProps: {iconName: 'MapLayers'},
                },
                {
                    name: !isWorkpapers ? 'Clients' : 'Trustees',
                    url: !isWorkpapers ? RouterPaths.workflow.clients : RouterPaths.workflow.trustees,
                    key: 'workflow-clients',
                    iconProps: {iconName: 'Contact'},
                },
                {
                    name: 'Reports',
                    url: RouterPaths.workflow.reports,
                    key: 'workflow-reports',
                    iconProps: {iconName: 'ClipboardList'},
                },
                {
                    name: 'Employees',
                    url: RouterPaths.workflow.employees,
                    key: 'workflow-employees',
                    iconProps: {iconName: 'RecruitmentManagement'},
                },
                {
                    name: 'Timesheet',
                    url: RouterPaths.workflow.timesheet,
                    key: 'workflow-timesheet',
                    iconProps: {iconName: 'clock'},
                },
            ],
        },
        {
            name: 'Administration',
            isHidden: isWorkpapers,
            url: '',
            key: 'administration',
            iconProps: {iconName: 'AccountManagement'},
            isExpanded: location.pathname.includes(RouterPaths.administration.root),
            links: (hasAccessToDashboards => {
                const items = [
                        {
                            name: 'Jobs',
                            url: RouterPaths.administration.jobs.root,
                            key: 'jobs',
                            iconProps: {iconName: 'Stack'},
                        },
                    ];
                
                if (hasAccessToDashboards) {
                    items.unshift({
                        name: formatMessage({id: 'dashboards'}),
                        url: RouterPaths.administration.dashboards,
                        key: 'dashboards',
                        iconProps: {iconName: 'PieSingle'},
                    })
                }
                
                return items;
            })(!!user?.accessToDashboard)
        },
        {
            name: 'User Management',
            isHidden: isWorkpapers || !isInRoleOrSuperAdministrator(),
            url: RouterPaths.userManagement,
            key: 'user-management',
            iconProps: {iconName: 'PlayerSettings'},
        },
        {
            name: 'Library',
            isHidden: isWorkpapers || !isInRoleOrSuperAdministrator(),
            url: RouterPaths.library,
            key: 'library',
            iconProps: {iconName: 'Library'},
        },
        {
            name: 'Customization',
            isHidden: !isInRoleOrSuperAdministrator(),
            url: RouterPaths.customization,
            key: 'customization',
            iconProps: {iconName: 'Color'},
        },
        {
            name: 'Portal Settings',
            isHidden: !isInRoleOrSuperAdministrator(),
            url: '',
            key: 'portal-settings',
            iconProps: {iconName: 'Settings'},
            isExpanded: location.pathname.includes(RouterPaths.portalSettings.root),
            links: [
                {
                    name: 'Audit Ready Connections',
                    url: RouterPaths.portalSettings.auditReadyConnections,
                    key: 'audit-ready-connections',
                    iconProps: {iconName: 'ComplianceAudit'},
                },
                {
                    name: 'General Settings',
                    url: RouterPaths.portalSettings.generalSettings,
                    key: 'general-settings',
                    iconProps: {iconName: 'Settings'},
                },
                {
                    name: 'Email Settings',
                    url: RouterPaths.portalSettings.emailSettings,
                    key: 'email-settings',
                    iconProps: { iconName: 'MailOptions' },
                },
                {
                    name: 'Risk Matrix Settings',
                    url: RouterPaths.portalSettings.riskMatrixSettings,
                    key: 'risk-matrix-settings',
                    iconProps: {iconName: 'ColumnOptions'},
                },
                {
                    name: 'Materiality Calculator Settings',
                    url: RouterPaths.portalSettings.materialityCalculatorSettings,
                    key: 'materiality-calculator-settings',
                    iconProps: {iconName: 'Calculator'},
                },
                {
                    name: 'Testing and Methodology Settings',
                    url: RouterPaths.portalSettings.testingAndMethodologySettings,
                    key: 'testing-and-methodology-settings',
                    iconProps: {iconName: 'DataManagementSettings'},
                },
                {
                    name: 'Email Templates',
                    url: RouterPaths.portalSettings.emailTemplates,
                    key: 'email-templates',
                    iconProps: {iconName: 'EditMail'},
                },
            ],
        },
    ], [isWorkpapers, isBlackLabel, user?.accessToDashboard]);

    return (
        <Layout links={links} headerLeft={location.pathname === '/private' ? <Menu/> : <></>} headerRight={<HelpMenu/>}>
            <Outlet/>
        </Layout>
    );
};
