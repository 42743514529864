import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {
    DefaultButton,
    IColumn,
    IDropdownOption,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from "@fluentui/react";
import {useIntl} from "react-intl";
import {Card, ControlledDropdown, DataTable, Modal} from "../../../../../components";
import {useBoolean} from "@fluentui/react-hooks";
import {useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../constants";
import {IInvoice} from "../../Invoices/interfaces";
import {useGetAllClients} from "../hooks";
import {ClientInvoicesFilter, useGetClientInvoices} from "../hooks";
import {useInvoicePortalContext} from "../../../InvoicePortalLayoutPage";
import {useGetClientFunds} from "../../../hooks";
import {Selection} from "@fluentui/react/lib/DetailsList";
import {OtherClientInvoice, useOtherClientsContext} from "../interfaces";
import {IClientInfo} from "../../../interfaces";

interface IOtherClientsSelectorProps {

}

export const OtherClientsSelector: FunctionComponent<IOtherClientsSelectorProps> = ({}: IOtherClientsSelectorProps) => {
    const {formatMessage} = useIntl();

    const {selectedItems, setSelectedItems} = useOtherClientsContext();

    const [modalOpen, {toggle: toggleModalOpen}] = useBoolean(false);
    
    const onSubmit = (items: OtherClientInvoice[]) => {
        setSelectedItems([...selectedItems, ...items.filter(i => !selectedItems.some(x => x.jobId === i.jobId))]);
        toggleModalOpen();
    }

    return (
        <>
            <Stack>
                <Stack.Item>
                    <PrimaryButton onClick={toggleModalOpen}>{formatMessage({id: 'selectJobs'})}</PrimaryButton>
                </Stack.Item>
            </Stack>

            <Modal title={''}
                   isOpen={modalOpen}
                   onDismiss={toggleModalOpen}
            >
                <Card>
                    <OtherClientsJobsSelectorFrom onClose={toggleModalOpen}
                                                  onSubmit={onSubmit}
                    />
                </Card>
            </Modal>
        </>
    )
}

interface IOtherClientsJobsSelectorFormProps {
    onClose: () => void;
    onSubmit?: (items: OtherClientInvoice[]) => void;
}

interface IOtherClientsJobsSelectorFormItem {
    clientIdentifier?: string,
    year?: string;
    fundName?: string;
    items: OtherClientInvoice[] 
}

const OtherClientsJobsSelectorFrom: FunctionComponent<IOtherClientsJobsSelectorFormProps> = ({   onClose,
                                                                                                 ...props
                                                                                             }: IOtherClientsJobsSelectorFormProps) => {
    const {formatMessage} = useIntl();

    const {years} = useInvoicePortalContext();

    const [filter, setFilter] = useState<ClientInvoicesFilter>({clientIdentifier: ''});

    const {clients, isLoading: isClientsLoading} = useGetAllClients();
    const {invoices, isLoading} = useGetClientInvoices(filter);

    const [selectedItems, setSelectedItems] = useState<OtherClientInvoice[]>([])
    const [selection, setSelection] = useState<Selection>(new Selection({
        onSelectionChanged: () => {
            setSelectedItems([...selection.getSelection() as OtherClientInvoice[]])
        }
    }));

    const {control, watch, setValue, getValues, handleSubmit, formState} = useForm<IOtherClientsJobsSelectorFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            clientIdentifier: undefined,
            year: years.find(y => y.isSelected && y.year == (new Date(Date.now())).getUTCFullYear())?.year?.toString() ?? '',
            fundName: '',
            items: []
        }
    });

    const {fields, append, update, remove} = useFieldArray({
        control,
        name: 'items'
    })

    const clientWatcher = watch('clientIdentifier')
    const yearWatcher = watch('year');
    const fundNameWatcher = watch('fundName')

    const {clientFunds, isClientFundsLoading} = useGetClientFunds(clientWatcher ?? '');

    const yearsOptions: IDropdownOption[] = useMemo(() => {
        return [
            {key: '', text: formatMessage({id: 'all'})},
            ...years
                .filter(y => y.isSelected)
                .map(y => y.year)
                .sort().reverse()
                .map(y => ({key: y.toString(), text: y.toString()}))
        ]
    }, [years]);

    const fundsOptions: IDropdownOption[] = useMemo(() => {
        return [
            {key: '', text: formatMessage({id: 'all'})},
            ...(clientFunds ?? []).map(f => ({key: f.id, text: f.name}))
        ]
    }, [clientFunds])

    useEffect(() => {
        if (clientWatcher) {
            setFilter(prev => ({
                ...prev,
                clientIdentifier: clientWatcher,
                year: yearWatcher,
                fundName: fundNameWatcher
            } as ClientInvoicesFilter))
        }
    }, [clientWatcher, yearWatcher, fundNameWatcher]);

    useEffect(() => {
        const selectedClientIdentifier: string | undefined = getValues('clientIdentifier');
        if (!selectedClientIdentifier) {
            return;
        }
        const currentClient = (clients ?? []).find(c => c.guid === selectedClientIdentifier);

        setValue('items', [...(invoices?.items ?? []).map(i => ({
            jobId: i.invoiceJobs?.[0]?.jobId,
            client: {...currentClient as IClientInfo},
            invoiceJobs: [...i.invoiceJobs],
            invoiceDate: i.invoiceDate,
            gst: i.gst,
            paragraph: i.paragraph,
            amount: i.amount
        } as OtherClientInvoice))])
    }, [invoices]);

    const clientsOptions: IDropdownOption[] = useMemo(() => {
        return (clients ?? []).map(c => ({key: c.guid, text: c.name}))
    }, [clients])

    const columns: IColumn[] = useMemo(() => [
        {
            key: 'jobName',
            name: formatMessage({id: 'job'}),
            fieldName: 'jobName',
            minWidth: 100,
            maxWidth: 120,
            isMultiline: true,
            onRender: (item: IInvoice, index?: number) => (
                <Text>{item.invoiceJobs?.[0]?.jobName}</Text>
            )
        },
        {
            key: 'fundName',
            name: formatMessage({id: 'fund'}),
            fieldName: 'fundName',
            minWidth: 190,
            maxWidth: 500,
            isMultiline: true,
            onRender: (item: IInvoice, index?: number) => (
                <Text>{item.invoiceJobs?.[0]?.fundName}</Text>
            ),
        }
    ], [formatMessage]);

    const onAddClick = () => {
        props.onSubmit?.(selectedItems ?? []);
    }

    if (isClientsLoading) {
        return (
            <Stack>
                <Stack.Item>
                    <Spinner size={SpinnerSize.medium}></Spinner>
                </Stack.Item>
            </Stack>
        )
    }

    const fieldWidth = 'calc(33% - 16px)';

    return (
        <>
            <Stack tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign={'space-between'}>
                        <Stack.Item styles={{root: {width: fieldWidth}}}>
                            <ControlledDropdown name={'clientIdentifier'}
                                                control={control}
                                                options={clientsOptions}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{root: {width: fieldWidth}}}>
                            <ControlledDropdown name={'year'}
                                                control={control}
                                                disabled={!getValues('clientIdentifier')}
                                                options={yearsOptions}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{root: {width: fieldWidth}}}>
                            <ControlledDropdown name={'fundName'}
                                                control={control}
                                                disabled={!getValues('clientIdentifier') || isClientFundsLoading}
                                                options={fundsOptions}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>

                <Stack.Item>
                    <DataTable initialColumns={columns}
                               items={fields ?? []}
                               enableShimmer={isLoading}
                               selectionMode={SelectionMode.multiple}
                               selection={selection}
                               hideIfEmpty={false}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign={'end'}>
                        <Stack.Item>
                            <PrimaryButton disabled={!selection.count}
                                           onClick={onAddClick}>
                                {formatMessage({id: 'add'})}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton text={formatMessage({id: 'close'})} onClick={onClose}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    )
}