import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { DataTable, SanitizedText } from 'components';
import { Attachment } from 'pages/JobPortal/components/Attachment';
import { JobComment } from 'pages/JobPortal/components/JobComment';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getGrouppedReports } from 'utils';
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import {useSectionContext} from "../../../Section";
import { ReportTable } from "../Table/ReportTable";
import { ReportMoneyValueCell } from "../shared/cells";



export const ClassSuperTaxAccountingReconciliation = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { section } = useSectionContext();
    const { formatMessage } = useIntl();
    const { reports, groups } = useMemo(() => {
        const groupedReports = getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'group' });
        
        const subHeaderIndex = groupedReports.reports.findIndex(r => r.isFirst && !r.description);
        if (subHeaderIndex > 0) {
            groupedReports.reports[subHeaderIndex] = {
                ...groupedReports.reports[subHeaderIndex],
                grossAmount: formatMessage({ id: 'incomeAmount' }),
                amount: formatMessage({ id: 'taxAmount' })
            }
        }
        
        return groupedReports;
    }, [items]);

    const getTextWeightFormat = (item: { isCategory: boolean }) => item?.isCategory ? 'Bold' : 'Normal';
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'description',
                name: formatMessage({id: 'description'}),
                minWidth: 300,
                fieldName: 'description',
                onRender: (item, _, column) => (
                    <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format || getTextWeightFormat(item) } />
                )
            },
            {
                key: 'grossAmount',
                name: '',
                minWidth: 200,
                maxWidth: 200,
                fieldName: 'grossAmount',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isCategory} />,
            },
            {
                key: 'amount',
                name: '',
                minWidth: 200,
                maxWidth: 200,
                fieldName: 'amount',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isCategory} />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={reports}
        groups={groups}
        openAll={true}
        expandProps={{showExpander: false}}
        enableShimmer={isFetching}
    />;
};
