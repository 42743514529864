export enum AnswerControlType {
    Dropdown = 1,
    Label = 2,
    TextBox = 3,
    DropdownNotColored = 5,
    DropdownNotColoredDispabledOption = 6,
    CheckboxWorkDone = 7,
    WorkDoneSwitch = 8,
    Calendar = 9,
  
  
    AnswerChooser = 100
}