import {Card, ControlledCheckbox, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {IChangeClientInfoCommand} from "..";
import {useMemo} from "react";
import {useGetEmployeesByPosition} from "pages/WorkflowPortal/Employees";
import {AuditReportDateType, ClientRiskRating, EmployeePosition} from "pages/WorkflowPortal/shared";
import {DetailedSoftwareType, SoftwareType} from "../../../../../../enums";

type AuditTabType = {
    control: Control<IChangeClientInfoCommand>;
};

export const AuditTab = ({control}: AuditTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const checkboxStackItemStyles = {root: {width: 'calc(25% - 16px)', marginTop: 43}};

    const {formatMessage} = useIntl();
    const {employeesByPosition, isEmployeesLoading} = useGetEmployeesByPosition(EmployeePosition.Partner);

    const optionsSoftware = [
        {key: SoftwareType.Supermate, text: formatMessage({id: 'supermate'})},
        {key: SoftwareType.BGL, text: formatMessage({id: 'bglSimpleFund'})},
        {key: SoftwareType.BGL360, text: formatMessage({id: 'bgl360'})},
        {key: SoftwareType.Nothing, text: formatMessage({id: 'other'})},
        {key: DetailedSoftwareType.ClassSuperAPI, text: formatMessage({id: 'classSuperApi'})},
        {key: DetailedSoftwareType.ClassSuperPDF, text: formatMessage({id: 'classSuperPDF'})},
    ];
    const partnerOptions = useMemo<IDropdownOption[]>(() => {
        return employeesByPosition?.data.map(x => ({key: x.id, text: `${x.firstName} ${x.lastName}`})) || [];
    }, [employeesByPosition?.data]);
    const optionsRisk = Object.keys(ClientRiskRating)
        .filter((x) => Number.isNaN(Number(x)))
        .map((c => ({key: c.toString(), text: c.toString()})));

    const auditReportDateOptions = Object.keys(AuditReportDateType)
        .filter((x) => Number.isNaN(Number(x)))
        .map((c => ({key: c.toString(), text: c.toString()})));

    const optionsGenerator = Object.keys(EmployeePosition)
        .filter((x) => Number.isNaN(Number(x)))
        .map((c => ({key: c.toString(), text: formatMessage({id: c.toLowerCase()})})));

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={optionsSoftware}
                        name='accountingSoftware'
                        label={formatMessage({id: 'accountingSoftware'})}
                        control={control}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={partnerOptions}
                        disabled={isEmployeesLoading}
                        name='partnerId'
                        label={formatMessage({id: 'partner'})}
                        control={control}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField name='turnAroundTime'
                                         label={formatMessage({id: 'turnAroundTime'})}
                                         control={control}/>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        required
                        name='clientRiskRating'
                        options={optionsRisk}
                        label={formatMessage({id: 'clientRiskRating'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                        }}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={auditReportDateOptions}
                        name='unsignedAuditReport'
                        label={formatMessage({id: 'unsignedAuditReport'})}
                        control={control}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={optionsGenerator}
                        name='aRGeneration'
                        label={formatMessage({id: 'aRGeneration'})}
                        control={control}
                    />
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        required
                        options={auditReportDateOptions}
                        name='unsignedARDate'
                        label={formatMessage({id: 'unsignedARDate'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={checkboxStackItemStyles}>
                    <ControlledCheckbox label={formatMessage({id: 'titleSearchConduct'})}
                                        control={control}
                                        name='titleSearchConduct'/>
                </Stack.Item>
                <Stack.Item styles={checkboxStackItemStyles}>
                    <ControlledCheckbox name='asicSearchConduct' control={control}
                                        label={formatMessage({id: 'ASICSearchConduct'})}/>
                </Stack.Item>
            </Stack>
        </Card>
    );
};
