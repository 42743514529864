import { FunctionComponent, useEffect } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IDropdownOption, Stack } from '@fluentui/react';
import { ControlledTextField } from 'components/controls/ControlledTextField';
import { ControlledDropdown } from 'components';
import { ClientContactInfo } from '../hooks/useGetClientByGuid';

interface Props {
    control: Control<any>;
    watch: UseFormWatch<any>;
    setValue: UseFormSetValue<any>;
    contacts: ClientContactInfo[];
    primaryContact?: ClientContactInfo;
}

export const ContactTab: FunctionComponent<Props> = ({ control, watch, setValue, contacts, primaryContact }: Props) => {
    const { formatMessage } = useIntl();

    const contactOptions: IDropdownOption[] = contacts.sort((x, y) => x.firstName + x.lastName > y.firstName + y.lastName ? 1 : -1).map((contact) =>
        ({ key: contact.guid, text: contact.firstName + ' ' + contact.lastName, isSelected: primaryContact && contact.guid === primaryContact.guid }));

    const currentContactId = watch('contact');

    useEffect(() => {
        const currentContact: ClientContactInfo | undefined = contacts.find((c) => c.guid === currentContactId) ?? contacts.find((c) => c.guid === primaryContact?.guid);
        if (currentContact) {
            setValue('contactId', currentContact.id);
            setValue('contactGuid', currentContact.guid);
            setValue('firstName', currentContact.firstName);
            setValue('lastName', currentContact.lastName);
            setValue('email', currentContact.email);
            setValue('businessPhone', currentContact.directPhone);
        }
    }, [currentContactId, setValue, contacts]);

    return (
        <>
            <Stack.Item>
                <ControlledDropdown required name='contact' control={control} label={formatMessage({ id: 'contact' })} options={contactOptions} />
            </Stack.Item>
            <Stack.Item>
                <hr style={{ width: '100%' }} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='firstName' control={control} label={formatMessage({ id: 'firstName' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='lastName' control={control} label={formatMessage({ id: 'lastName' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField name='email' control={control} label={formatMessage({ id: 'email' })} disabled={true} />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='businessPhone'
                    control={control}
                    label={formatMessage({ id: 'businessPhone' })}
                    disabled={true}
                />
            </Stack.Item>
        </>
    );
};
