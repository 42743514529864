import { useContext } from 'react';
import { Context } from '../../../../utils';
import { useMutation } from 'react-query';
import axios, { AxiosError } from "axios";
import { useNotifications } from "../../../../components/notifications";
import { MessageBarType } from "@fluentui/react";

type MutateDataType = {
    id: number;
    jobId: number;
    initials: string;
    note: string;
    checked: boolean;
};

function useUpdateFileNote() {
    const context = useContext(Context);
    const {showNotification} = useNotifications()
    
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating,
    } = useMutation(
        (data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/fileNotes/${data.id}`, data),
        {
            onError: (error: AxiosError) => {
                if (error?.response?.data?.code === 300100) {
                    showNotification({
                        type: MessageBarType.error,
                        name: 'Error',
                        description: `${error.response?.data?.messages[0]}`
                    })
                }
            },
        }
    );

    return { update, updateAsync, isUpdating };
}

export { useUpdateFileNote };
