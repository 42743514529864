import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {IProcedure} from '../interfaces';

interface IGetProceduresParams {
    jobId: number;
    jobYear: number;
    sectionId: number;
    controlType: number;
    templateId: number;
    fundId: number;
    tabReference?: string;
}

function useGetProcedures({jobId, jobYear, sectionId, controlType, templateId, fundId, tabReference}: IGetProceduresParams) {
    const context = React.useContext(Context);
    const host = context?.config?.proceduresUrl;
    const {
        data: proceduresData,
        isLoading: isProceduresLoading,
        refetch: refetchProcedures,
        isFetching: isProceduresFetching
    } = useQuery(
        ['getProcedures_' + sectionId, jobId, jobYear, sectionId, controlType, templateId, fundId],
        () => axios.get<IProcedure>(
            `${host}/api/1.0/job/${jobId}/section/${sectionId}/${controlType}`,
            {...commonHeaders(), params: {jobYear, templateId, fundId, tabReference}}
        ),
        {
            enabled: !!context?.config?.proceduresUrl && !!context.tenantId && !!controlType,
            staleTime: 100,
            cacheTime: 100,
            retry: false,
        }
    );

    return {proceduresData, isProceduresLoading, refetchProcedures, isProceduresFetching};
}

export {useGetProcedures};
