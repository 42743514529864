import { DefaultButton, IColumn, Link, PrimaryButton, SelectionMode, Spinner, Stack, Text, Checkbox, useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useJobContext } from '.';
import { DataTable, DisabledChildren, Modal, Search } from '../../components';
import { AttachmentInfo } from './components';
import { useGetDisplayDocuments, useUpdateAttachmentMarkAsPerm, useUpdateAttachmentUnmarkAsPerm } from './hooks';
import { useWorkContext } from 'providers';

interface IDisplayDocumentsProps {
    onlyPermanent: boolean;
    disabled?: boolean;
}

const DisplayDocumentContent: FunctionComponent<IDisplayDocumentsProps> = ({ onlyPermanent, disabled }) => {
    const { markAsPerm } = useUpdateAttachmentMarkAsPerm();
    const { unmarkAsPerm } = useUpdateAttachmentUnmarkAsPerm();
    const { formatMessage } = useIntl();
    const { job } = useJobContext();
    const { tabId } = useParams();
    const [getAll, setGetAll] = useState(false);

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetDisplayDocuments({
        tabId: Number(tabId),
        getAll,
        onlyPermanent,
        jobId: job.id,
        year: job.year,
        fundId: job.fund?.id,
    });

    const toggleMarkAsPerm = (id: number, itemId: number, isPermanent: boolean) => {
        const markProps = {
            jobId: job.id,
            fundId: job.fund?.id,
            itemId,
            id,
            year: job.year,
        };

        if (isPermanent) {
            markAsPerm(markProps);
        } else {
            unmarkAsPerm(markProps);
        }
    };

    const allDocumentsCheckboxChangeHandler = (event: any) => {
        setGetAll(event.target.checked);
        setSearchString('');
    };

    const [searchString, setSearchString] = useState('');

    const searchHandler = (searchString: any) => {
        setSearchString(searchString);
    };

    const clearHandler = (searchString: any) => {
        setSearchString('');
    };

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) => (
                <AttachmentInfo
                    id={item.id}
                    isPermanent={item.isPermanent}
                    attachmentJobId={item.jobId}
                    fileName={item.fileName}
                    originalFileName={item.originalFileName}
                    isEdited={item.isEdited}
                    downloadUrl={item.downloadUrl}
                    jobId={job.id}
                />
            ),
        },
        {
            key: 'perm',
            name: formatMessage({ id: 'perm' }),
            minWidth: 180,
            maxWidth: 180,
            fieldName: 'perm',
            onRender: (item) => (
                <Stack horizontal styles={{ root: { width: '100%' } }}>
                    <DisabledChildren disabled={!!disabled}>
                        <PrimaryButton
                            toggle
                            primary={item.isPermanent}
                            minLength={100}
                            styles={{ root: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                            checked={item.isPermanent}
                            text={item.isPermanent ? 'Marked' : 'Mark'}
                            onClick={() => toggleMarkAsPerm(item.id, item.itemId, true)}
                        />
                        <DefaultButton
                            toggle
                            minLength={100}
                            styles={{ root: { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 } }}
                            checked={!item.isPermanent}
                            text={item.isPermanent ? 'Unmark' : 'Unmarked'}
                            onClick={() => toggleMarkAsPerm(item.id, item.itemId, false)}
                        />
                    </DisabledChildren>
                </Stack>
            ),
        },
    ]);

    if (isAttachmentsLoading)
        return (
            <Stack horizontalAlign='center'>
                <Spinner />
            </Stack>
        );
    if (!attachmentsData?.data) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack horizontal horizontalAlign='space-between'>
                {!onlyPermanent && (
                    <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 16 }}>
                        <Text>{formatMessage({ id: 'showAllDocuments' })}</Text>
                        <Checkbox checked={getAll} onChange={allDocumentsCheckboxChangeHandler} />
                    </Stack>
                )}
                {getAll && (
                    <Stack horizontalAlign='end'>
                        <Search onSearch={searchHandler} onClear={clearHandler} placeholder={formatMessage({ id: 'searchFiles' })} />
                    </Stack>
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 16 }}>
                <DataTable
                    initialColumns={columns}
                    columns={columns}
                    items={
                        searchString
                            ? attachmentsData.data.filter((x) => x.fileName.toLowerCase().includes(searchString.toLowerCase()))
                            : attachmentsData.data
                    }
                    selectionMode={SelectionMode.none}
                    enableShimmer={isAttachmentsFetching}
                    containerHeight='100%'
                    emptyProps={{
                        iconName: 'ComplianceAudit',
                    }}
                />
            </Stack>
        </Stack>
    );
};

interface IDisplayDocumentsComponentProps {
    isPermanentDocs: boolean;
}

export const DisplayDocuments: FunctionComponent<IDisplayDocumentsComponentProps> = ({ isPermanentDocs }) => {
    const { formatMessage } = useIntl();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const { job, jobTabs } = useJobContext();
    const { isJobPortalEnabled } = useWorkContext();
    const [isTabEnabled, setIsTabEnabled] = useState<boolean>(true);

    const { tabId } = useParams();
    const theme = useTheme();

    const { attachmentsData } = useGetDisplayDocuments({
        tabId: Number(tabId),
        getAll: false,
        onlyPermanent: false,
        jobId: job.id,
        year: job.year,
        fundId: job.fund?.id,
    });

    const linkClickHandler = () => {
        if (!isOpenModal) {
            toggleOpenModal();
        }
    };

    useEffect(() => {
        const tab = jobTabs.find((t) => t.id === +tabId!);
        setIsTabEnabled(isJobPortalEnabled || !tab?.isFreezedEnabled);
    }, [isJobPortalEnabled, tabId, jobTabs]);

    const canBeMarkedAsPermant = job.dateARSent == null;

    return (
        <>
            {(isPermanentDocs ||
                (!isPermanentDocs && (!attachmentsData?.data || (attachmentsData?.data && attachmentsData.data.length == 0)))) && (
                <Link style={{ color: theme.schemes?.default?.semanticColors.bodyText }} onClick={linkClickHandler}>
                    {isPermanentDocs ? formatMessage({ id: 'permanentDocuments' }) : formatMessage({ id: 'displayDocuments' })}
                </Link>
            )}
            {!isPermanentDocs && attachmentsData?.data && attachmentsData.data.length > 0 && (
                <Link style={{ color: theme.schemes?.default?.palette.red }} onClick={linkClickHandler}>
                    {isPermanentDocs ? formatMessage({ id: 'permanentDocuments' }) : formatMessage({ id: 'displayDocuments' })}
                </Link>
            )}
            <Modal
                title={isPermanentDocs ? formatMessage({ id: 'permanentDocuments' }) : formatMessage({ id: 'documents' })}
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal();
                }}>
                <DisplayDocumentContent onlyPermanent={isPermanentDocs} disabled={!(isTabEnabled && canBeMarkedAsPermant)} />
            </Modal>
        </>
    );
};
