import { Observable } from 'rxjs';
import { IAuthorizationToken } from 'interfaces';
import { BaseService } from './BaseService';
import { ConfigurationService, configurationService } from './ConfigurationService';

class IdentityService extends BaseService {
    constructor(configurationService: ConfigurationService) {
        super('identityUrl', configurationService);
    }

    public getToken(code: string, userId: string): Observable<IAuthorizationToken> {
        console.log(`[AuthService] Getting token with code: ${code}, userId: ${userId}`);

        return this.get('/api/1.0/Authorization/GetToken', { params: { code, userId } });
    }

    public refreshToken(refreshToken: string): Observable<IAuthorizationToken> {
        return this.get('/api/1.0/Authorization/RefreshToken', { params: { refreshToken } });
    }
}

export const identityService = new IdentityService(configurationService);
