import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';
import { FunctionComponent } from 'react';

/**Only for breadcrumb in Testing And Methodology page*/

export const TestingAndMethodologySettingsLayoutPage: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'testingAndMethodologySettings',
                key: RouterPaths.portalSettings.testingAndMethodologySettings,
                text: formatMessage({ id: 'testingAndMethodologySettings' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
