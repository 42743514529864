import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {DefaultButton, mergeStyles, mergeStyleSets, PrimaryButton, Spinner, Stack, useTheme} from "@fluentui/react";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../../constants";
import {IAuditQueryItem} from "../interfaces";
import {ControlledCheckbox} from "../../../../../../../components";
import {Editor, EditorState} from "draft-js";
import {convertFromHTML} from "draft-convert";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {useUpdateAuditQuery} from "../hooks/useUpdateAuditQuery";

interface IQueryEditProps {
    query: IAuditQueryItem,
    onAfterSave?: () => void;
    onCancel: () => void;
}

type QueryEditFormProps = Pick<IAuditQueryItem, 'id' | 'text' | 'isNonFundamental'>

export const QueryEditForm: FunctionComponent<IQueryEditProps> = ({
                                                                      query, onAfterSave, onCancel
                                                                  }: IQueryEditProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const {jobId} = useJobContext();
    
    const {update, isUpdating} = useUpdateAuditQuery();

    const {control, setValue, handleSubmit, getValues} = useForm<QueryEditFormProps>({
        ...DefaultFormSettings,
        defaultValues: {
            id: query.id,
            isNonFundamental: query.isNonFundamental
        }
    });

    const [formState, setFormState] = useState<{ text: EditorState }>({
        text: EditorState.push(EditorState.createEmpty(), convertFromHTML((query.text ?? '').replaceAll('\n', '<br/>')), 'insert-characters'),
    });

    const onSaveClick = () => {
        update({
            id: query.id,
            jobId: jobId,
            text: formState.text.getCurrentContent().getPlainText(),
            comment: query.comment,
            isNonFundamental: getValues('isNonFundamental'),
            isSelected: query.isSelected
        }, {
            onSuccess: () => {
                onAfterSave?.()
            }
        })
    }
    
    const className = mergeStyleSets({
        stack: {
            '.public-DraftEditor-content': {
                minHeight: 120,
                color: theme.schemes?.default?.semanticColors.bodyText
            },
        },
    });

    return (
        <Stack className={className.stack} tokens={{childrenGap: 16}}>
            <Stack.Item>
                <Stack styles={{
                    root: {
                        border: `1px solid ${theme.schemes?.default?.palette.neutralPrimary}`,
                        borderRadius: 2,
                        overflow: 'hidden',
                        padding: 8
                    }
                }}>
                    <Editor
                        editorState={formState.text}
                        onChange={(v) => setFormState(prev => ({...prev, text: v}))}
                    /> 
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <ControlledCheckbox name={'isNonFundamental'} control={control} label={formatMessage({id: 'nonWithholding'})} />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap: 16}}>
                    {isUpdating && <Spinner />}
                    <PrimaryButton text={formatMessage({id: 'save'})}
                                   onClick={onSaveClick}
                                   disabled={isUpdating}
                    />
                    <DefaultButton onClick={() => onCancel()}
                                   text={formatMessage({id: 'cancel'})}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}