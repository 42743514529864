import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

interface IGetSoftwareUploadsParams {
    clientGuid: string;
    fundGuid: string;
    jobGuid: string;
    softwareType: string;
    bucketName: string;
}

export interface ISofwareUpload {
    name: string;
    downloadUrl: string;
    storageFolder: string;
    path: string;
}

function useGetSoftwareUploads({ clientGuid, fundGuid, jobGuid, softwareType, bucketName }: IGetSoftwareUploadsParams) {
  const context = React.useContext(Context);

    const { data: softwareUploadsData, isLoading: isSoftwareUploadsLoading, refetch: refetchSoftwareUploads, isFetching: isSoftwareUploadsFetching } = useQuery(
        ['getSoftwareUploads', clientGuid, fundGuid, jobGuid, softwareType, bucketName],
    () => axios.get<ISofwareUpload[]>(
        `${context?.config?.storageUrl}/api/1.0/storage/files/list?storageFolder=${bucketName}&filesPath=${clientGuid}/${fundGuid}/${jobGuid}/${softwareType}&providerType=1`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.storageUrl && !!context.tenantId,
    
      retry: false,
    }
  );

  return { softwareUploadsData, isSoftwareUploadsLoading, refetchSoftwareUploads, isSoftwareUploadsFetching };
}

export { useGetSoftwareUploads };