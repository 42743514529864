import { FunctionComponent, useCallback, useEffect } from 'react';
import { FontSizes, FontWeights, Icon, Label, mergeStyles, NeutralColors, Stack, TextField, useTheme } from '@fluentui/react';
import { IStyle } from '@fluentui/merge-styles/lib/IStyle';
import { ControlledTextField } from '../../../../../../components';
import { useForm } from 'react-hook-form';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useTabContext } from 'pages/JobPortal';

type Icon = {
    iconName: string;
    styles?: IStyle;
};
type IconType = 'plus' | 'minus' | 'multiply' | 'percentage' | 'equal';

type CalculatorRow = {
    iconType?: IconType;
    label: string;
    value: number | undefined;
    changed?: boolean;
    suffix?: string;
    editable?: boolean;
    onChangeValue?: (newValue: number) => void;
    editing?: boolean;
    applyCurrencyFormat?: boolean;
    onClick?: () => void;
    useParamsValue?: boolean;
};

export const CalculatorRow: FunctionComponent<CalculatorRow> = ({
    iconType,
    label,
    value,
    changed,
    suffix,
    onChangeValue,
    editable = true,
    editing = true,
    applyCurrencyFormat = false,
    onClick,
    useParamsValue,
}) => {
    const theme = useTheme();
    const { isTabEnabled } = useTabContext();
    const plusIcon: Icon = {
        iconName: 'CircleAdditionSolid',
    };
    const minusIcon: Icon = {
        iconName: 'Blocked2Solid',
    };
    const equalIcon: Icon = {
        iconName: 'CirclePauseSolid',
        styles: {
            transform: 'rotate(90deg)',
        },
    };
    const multiplyIcon: Icon = {
        iconName: 'CircleAdditionSolid',
        styles: {
            transform: 'rotate(45deg)',
        },
    };
    const percentageIcon: Icon = {
        iconName: 'CalculatorPercentage',
        styles: {
            color: theme.palette.white,
            padding: 7,
            fontWeight: FontWeights.semibold,
            background: NeutralColors.gray90,
            borderRadius: 15,
            fontSize: FontSizes.size10,
        },
    };
    const textFieldStyles = {
        root: {
            width: 150,
        },
        field: {
            color: changed ? theme.schemes?.default?.palette.blue : theme.schemes?.default?.semanticColors.bodyText,
            textAlign: 'right',
        },
        suffix: {
            color: theme.schemes?.default?.semanticColors.bodyText,
        },
        prefix: {
            color: theme.schemes?.default?.semanticColors.bodyText,
        },
    };
    const getIcon = () => {
        switch (iconType) {
            case 'plus':
                return plusIcon;
            case 'minus':
                return minusIcon;
            case 'equal':
                return equalIcon;
            case 'percentage':
                return percentageIcon;
            case 'multiply':
                return multiplyIcon;
            case null:
                return null;
        }
    };
    const currentIcon = getIcon();
    const styles = mergeStyles(
        {
            color: NeutralColors.gray90,
            fontSize: FontSizes.size24,
        },
        currentIcon?.styles
    );

    const { control, setValue, watch, formState } = useForm<{ value: number | undefined }>({
        mode: 'onChange',
        defaultValues: {
            value: value,
        },
    });

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!isTabEnabled) return;

            onClick?.();
            event.stopPropagation();
        },
        [isTabEnabled]
    );

    const onSubmit = useCallback((data: any) => {
        if (onChangeValue) onChangeValue(Number(data.value || 0));
    }, []);

    useEffect(() => {
        setValue('value', value || undefined);
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    const watchFields = watch();

    return (
        <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} tokens={{ childrenGap: 16 }} grow>
            <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign={'center'}>
                {currentIcon?.iconName && <Icon iconName={currentIcon?.iconName} className={styles} />}
                {!currentIcon?.iconName && <div style={{ width: 24 }}></div>}
                <Label style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>{label}</Label>
            </Stack>
            {editable &&
                (editing ? (
                    <ControlledTextField
                        autoFocus
                        control={control!}
                        name={'value'}
                        type={'number'}
                        placeholder={'0'}
                        suffix={suffix}
                        prefix={changed || formState.touchedFields.value ? '*' : undefined}
                        underlined
                        styles={textFieldStyles}
                    />
                ) : (
                    <Stack style={{ position: 'relative' }}>
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0,
                                    zIndex: 100,
                                }}
                                onClick={onEditClick}></div>
                            <TextField
                                disabled
                                suffix={suffix}
                                prefix={changed || formState.touchedFields.value ? '*' : undefined}
                                styles={textFieldStyles}
                                value={
                                    applyCurrencyFormat
                                        ? toCurrencyFormat((useParamsValue ? value : watchFields.value) || 0)?.toString()
                                        : useParamsValue
                                        ? value?.toString()
                                        : watchFields.value?.toString()
                                }
                            />
                        </>
                    </Stack>
                ))}
            {!editable && (
                <Label style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>{`${(value || 0).toLocaleString('au-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} ${suffix || ''}`}</Label>
            )}
        </Stack>
    );
};
