import {JobInfo} from "pages/WorkflowPortal/shared";
import {TimesheetWorkflowPage} from "pages/WorkflowPortal/Timesheet";

type TabProps = {
    job?: JobInfo;
};

export const TimesheetTab = ({job}: TabProps) => {
    return (
        <TimesheetWorkflowPage jobId={job?.id}/>
    );
};
