import {useState} from 'react';
import {ActionButton, IColumn, Link, SelectionMode, Text, useTheme} from '@fluentui/react';
import {BaseDialog, Card, DataTable} from 'components';
import {ActionButtonStyles, CardTableContainerStyles} from 'constants/tableStylesPeset';
import {useIntl} from 'react-intl';
import {IOrderedRequest} from "interfaces";
import {useUpdateClientsStatus} from "../../hooks";
import {ClientAddressType, ClientContactInfo, ClientInfo, EntityStatus} from "pages/WorkflowPortal/shared";
import {useWorkflows} from "../../../WorkflowLayoutPage";

type ClientsPropsType = {
    clientItems: ClientInfo[] | [];
    isLoading?: boolean;
    setSort: (value: IOrderedRequest) => void;

};

export const ClientsDataTable = ({clientItems, isLoading, setSort}: ClientsPropsType) => {
    const {formatMessage} = useIntl();
    const {showClientModal, showEmployeeModal, showContactModal} = useWorkflows();
    const theme = useTheme();
    const [currentActivatedUser, setCurrentActivatedUser] = useState<{ clientId: number; status: EntityStatus } | null>(null);

    const {
        updateClientStatus,
        isLoadingClientsUpdateStatus
    } = useUpdateClientsStatus(() => setCurrentActivatedUser(null));

    const handleChangeClientStatus = () => {
        if (currentActivatedUser) {
            updateClientStatus({
                clientId: currentActivatedUser?.clientId,
                status: currentActivatedUser?.status,
            });
        }
    };


    const [columns] = useState<IColumn[]>([
        {
            key: 'commands',
            name: formatMessage({id: 'commands'}),
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'commands',
            isSorted: false,
            onRender: (items: ClientInfo) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={{root: {fontWeight: 500}}}
                    onClick={() => setCurrentActivatedUser({clientId: items.id, status: items.status})}
                >
                    {items.status === EntityStatus.DeActive ? 'Deactivate' : 'Activate'}
                </Link>
            ),
        },
        {
            key: 'clientName',
            name: formatMessage({id: 'name'}),
            minWidth: 150,
            maxWidth: 600,
            fieldName: 'Name',
            onRender: (items: ClientInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }} styles={ActionButtonStyles} onClick={() => showClientModal(items.guid)}
                >
                    {items.name}
                </Link>
            ),
        },
        {
            key: 'primaryContact',
            name: formatMessage({id: 'primaryContact'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.PrimaryContact.FirstName',
            onRender: (items: ClientInfo) => (
                <Link
                    underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles}
                    onClick={() => showContactModal(items.primaryContact.id, items.id)}
                >
                    {`${items.primaryContact?.firstName || ''} ${items.primaryContact?.lastName || ''}`}
                </Link>
            ),
        },
        {
            key: 'mainPhone',
            name: formatMessage({id: 'mainPhone'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.MainPhone',
            onRender: (items: ClientInfo) => <Text>{items.mainPhone}</Text>,
        },
        {
            key: 'state',
            name: formatMessage({id: 'state'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'Addresses.State',
            onRender: (items: ClientInfo) =>
                <Text>{items.addresses.filter(x => x.type === ClientAddressType.Physical)[0]?.state}</Text>,
        },
        {
            key: 'partner',
            name: formatMessage({id: 'partner'}),
            minWidth: 150,
            maxWidth: 350,
            fieldName: 'AdditionalInfo.Partner.FirstName',
            onRender: (items: ClientInfo) => (
                <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                    styles={ActionButtonStyles} onClick={() => showEmployeeModal(items.partner.id)}>
                    {`${items.partner?.firstName || ''} ${items.partner?.lastName || ''}`}
                </Link>
            ),
        },
    ]);
    return (
        <Card styles={CardTableContainerStyles}>
            <DataTable
                compact
                sortableColumnKeys={['clientName', 'primaryContact', 'mainPhone', 'state', 'partner']}
                initialColumns={columns}
                columns={columns}
                items={clientItems}
                enableShimmer={isLoading}
                selectionMode={SelectionMode.none}
                containerHeight='100%'
                setOrder={setSort}
            />

            {!!currentActivatedUser?.clientId && (
                <BaseDialog
                    title={formatMessage({id: 'changeActiveDialogTitle'})}
                    subText={formatMessage({id: 'changeActiveDialogSubText'})}
                    hidden={false}
                    onAgree={handleChangeClientStatus}
                    toggleHidden={() => setCurrentActivatedUser(null)}
                    noDisabled={isLoadingClientsUpdateStatus}
                    yesDisabled={isLoadingClientsUpdateStatus}
                />
            )}


        </Card>
    );
};
