import {IColumn, IconButton, IDetailsRowProps, SelectionMode, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { ReportTable } from '../Table/ReportTable';
import {SanitizedText} from 'components';
import { dateFortmatString, formatDate } from "utils";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {ReportValueType} from "../../../../../../enums";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import * as React from "react";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import { isAgreedAnswer, isAutoAnswer } from "../../answers";


export const BGL360RealisedCapitalGains = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            if (i.valueType === 4 && !!i.children?.length) {
                i.children.forEach((z: any) => {
                    if ((isAgreedAnswer(z.answerText) || isAutoAnswer(z.answerText)) && z.valueType === 1) {
                        verifiedCoverage += Math.abs(z.accountingTreatmentProceeds ?? 0)
                    }
                    if (z.valueType === 1) {
                        incomeTotal += Math.abs(z.accountingTreatmentProceeds ?? 0)
                    }
                })
            }
        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const rebuildReports = (items: IReport[], depth: number = 0): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.depth = depth;
            i.format = i.valueType === ReportValueType.Value ? 'Normal' : 'Bold'
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
                i.children = rebuildReports(i.children, depth + 1);
            }

            acc.push(i);
            return acc;
        }, []);
    }

    const [tableItems, setTableItems] = useState<IReport[]>(rebuildReports(items));
    
    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(rebuildReports(tableItems));
        }
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item, _, column) => {
                    return <Stack horizontal>
                                <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>
                                <JobComment itemId={item?.id} hasComments={item?.hasComments}/>
                            </Stack>
                },
            },
            {
                key: 'accountingTreatmentPurchaseContractDate',
                name: formatMessage({ id: 'purchaseContractDate' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentPurchaseContractDate',
                onRender: (item, _, column) => {
                    return <SanitizedText data={formatDate(item[column?.fieldName || 0], dateFortmatString, false)} isHeadingRow={item?.isHeadingRow} format={item?.format}/>
                }
            },
            {
                key: 'accountingTreatmentDisposalContractDate',
                name: formatMessage({ id: 'disposalContractDate' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentDisposalContractDate',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== ReportValueType.Heading && item?.valueType !== ReportValueType.SubHeading && 
                            <GeneratedAnswer itemId={item?.id} 
                                             tableType={item?.reportType} 
                                             value={item?.answerText} 
                                             answerControlType={item?.answerControlType} 
                                             auto={item?.isAnswerDisabled}
                                             onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                             disabled={item?.isAnswerDisabled}
                            />}
                    </Stack>
                ),
            },
            {
                key: 'accountingTreatmentUnits',
                name: formatMessage({ id: 'atUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'accountingTreatmentCost',
                name: formatMessage({ id: 'atCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'accountingTreatmentProceeds',
                name: formatMessage({ id: 'atProceeds' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentProceeds',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'accountingTreatmentAccountingProfitLoss',
                name: formatMessage({ id: 'atAccountingProfitLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'accountingTreatmentAccountingProfitLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentAdjustedCostBase',
                name: formatMessage({ id: 'adjCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentAdjustedCostBase',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentReducedCostBase',
                name: formatMessage({ id: 'reducedCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentReducedCostBase',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentIndexedCostBase',
                name: formatMessage({ id: 'indexedCostBase' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentIndexedCostBase',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentIndexedGains',
                name: formatMessage({ id: 'indexedGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentIndexedGains',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentDiscountedGainsGross',
                name: formatMessage({ id: 'discountedGainsGross' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentDiscountedGainsGross',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'taxTreatmentOtherGains',
                name: formatMessage({ id: 'otherGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentOtherGains',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxTreatmentCapitalLoss',
                name: formatMessage({ id: 'capitalLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxTreatmentCapitalLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable 
        initialColumns={columns}
        items={tableItems}
        groupProps={{
            mergedGroupHeader: {propertyKey: 'investmentName'},
            summary: {
                show: true,
                style: { paddingLeft: 0 },
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    const summary = group.summary;
                    return <>
                        {
                            <Stack horizontal>
                                <Stack.Item>
                                    {summary.verified ? <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem> : <></>}
                                </Stack.Item>
                            </Stack>
                        }
                    </>
                }
            }
        }}
        isFetching={isFetching} 
        openAll={true}/>;
};
