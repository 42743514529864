import { FunctionComponent, ReactNode } from 'react';
import { Card } from './Card';
import { IconButton, Stack, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';

interface Props {
    onSearch?: () => void;
    onClear?: () => void;
    searchDisabled?: boolean;
    clearDisabled?: boolean;
    children: ReactNode;
}

export const SearchPanel: FunctionComponent<Props> = ({ searchDisabled, clearDisabled, onSearch, onClear, children }: Props) => {
    const { formatMessage } = useIntl();
    const { palette } = useTheme();

    return (
        <Card>
            <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 16 }}>
                <Stack.Item grow={10}>{children}</Stack.Item>
                <Stack.Item grow={1}>
                    <IconButton
                        iconProps={{ iconName: 'Clear' }}
                        disabled={clearDisabled}
                        styles={{ icon: { color: palette.red }, iconHovered: { color: palette.redDark } }}
                        text={formatMessage({ id: 'clear' })}
                        onClick={onClear}
                    />
                    <IconButton
                        iconProps={{ iconName: 'Search' }}
                        disabled={searchDisabled}
                        text={formatMessage({ id: 'search' })}
                        onClick={onSearch}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
