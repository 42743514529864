import { Spinner, Stack } from '@fluentui/react';
import { FunctionComponent } from 'react';
import { useGetJobByGuid } from 'hooks/useGetJobByGuid';
import { useParams } from 'react-router-dom';
import { useProcedureContext } from '../ProceduresContent';
import { useGetJobComments } from '../../hooks';
import { JobCommentForm } from './JobCommentForm';
import {useSectionContext} from "../Section";

interface IJobCommentProps {
  itemId: number;
  onCancel: Function;
  onSuccessUpdate: Function;
  tableType: number;
}

export const JobCommentContent: FunctionComponent<IJobCommentProps> = ({ itemId, onCancel, onSuccessUpdate, tableType }) => {
  const { guid } = useParams();
  const { dataJobs } = useGetJobByGuid({ guid });
  
  const { commentsData, isCommentsLoading } = useGetJobComments({
    jobId: dataJobs?.data?.id,
    fundId: dataJobs?.data?.fund?.id,
    itemId,
    tableType: tableType,
    year:  dataJobs?.data?.year,
  });

  if (isCommentsLoading) return <Stack grow horizontalAlign='center'><Spinner /></Stack>;
  if (!commentsData?.data) return <Stack>No data</Stack>;
  return <JobCommentForm onCancel={onCancel} onSuccessUpdate={onSuccessUpdate} data={commentsData.data} />;
};