import React, { FunctionComponent, useMemo } from "react";
import { IconButton, Stack } from "@fluentui/react";
import { IReportValidationItem } from "../../../../interfaces/IReportsInfo";
import { ValidationIcon } from "../../validations";
import { IValidationItem, ManuallyValidValidationItemAnswers, ValidationState } from "../../../../interfaces";
import { useReportValidationsContext } from "../../../ReportContent";
import { useSectionContext } from "../../../Section";

interface IReportValidationCellProps {
    itemId: number;
    useValidations?: Array<1 | 2 | 3 | 4> | 'all';
    isSumChecked?: boolean | null
}

export const ReportValidationCell: FunctionComponent<IReportValidationCellProps> = ({
                                                                                        itemId,
                                                                                        useValidations = 'all',
                                                                                        isSumChecked
                                                                                    }: IReportValidationCellProps) => {
    const { reportValidations } = useReportValidationsContext();
    const { section } = useSectionContext();
    
    const validationItems = useMemo<IReportValidationItem[]>(() => {
        const validations = (reportValidations ?? []).filter(x => x.validationErrorItems.some((vi: IValidationItem) => vi.itemId === itemId && vi.tableType === section.tableType)).sort((a, b) => {
            return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
        });

        let reduced = validations.reduce((acc: IReportValidationItem[], v, index) => {
            acc.push({
                isValid: v.isValidItem,
                answerText: v.answerText,
                validationId: v.id,
                validationType: v.validationType,
                validationItems: [...v.validationErrorItems.filter((x: any) => x.itemId !== itemId)]
            })

            return acc;
        
        }, []);
        
        // specific case when two or more one-item validations point to the same item
        if (reduced.length > 4) {
            reduced = reduced.reduce((acc: IReportValidationItem[], v, index) => {
                if (v.validationItems.length === 1 && acc.some(a => a.validationItems.length === 1 && a.validationItems[0].itemId === v.validationItems[0].itemId)) {
                    return acc;
                }
                acc.push(v)
                return acc;
            }, []);
        }
        
        return reduced.filter((r, index) => {
            return (useValidations === 'all' || (useValidations ?? []).some(vn => vn === index + 1));
        })
        
    }, [reportValidations, useValidations])
    
    return (
        <Stack grow horizontal horizontalAlign={'center'}>
            {validationItems.map(vi => {
                return <ValidationIcon key={`report-validation-item-${vi.validationId}`}
                                       state={vi.isValid ? ValidationState.Valid : 
                                        Object.values(ManuallyValidValidationItemAnswers).includes(vi.answerText as ManuallyValidValidationItemAnswers) ? ValidationState.ManuallyValid
                                         : ValidationState.Invalid}
                                       type={vi.validationType}
                                       items={vi.validationItems}></ValidationIcon>
            })}
            {isSumChecked &&
                <Stack.Item>
                    <IconButton iconProps={{iconName: 'add'}}/>
                </Stack.Item>
            }
        </Stack>
    )
}