import * as React from 'react';
import {FunctionComponent, useMemo} from 'react';
import {observer} from 'mobx-react';
import {CommandBar, ICommandBarItemProps} from '@fluentui/react/lib/CommandBar';
import {DataTable} from 'components';
import {useIntl} from 'react-intl';
import {useBoolean} from '@fluentui/react-hooks';
import {IColumn, MessageBarType, SelectionMode, Stack, Text, useTheme} from '@fluentui/react';
import {VariablesActions} from './VariablesActions';
import {IParamInfo} from 'interfaces';
import {sortBy} from 'lodash';
import {VariableForm} from './VariableForm';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from 'constants/forms';
import {useRulesetStore} from '../../RulesetLayoutPage';
import {useIsDestroy} from 'utils';
import {takeUntil} from 'rxjs';
import {VariableModal} from '../../Rules/ScopedVariables/components/VariableModal';
import {useNotifications} from "../../../../../components/notifications";

export const Variables: FunctionComponent = observer(() => {
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {control, handleSubmit, formState, reset, clearErrors} = useForm<IParamInfo>({
        ...DefaultFormSettings,
        defaultValues: {
            name: '',
            expression: '',
        },
    });
    const {addGlobalVariable, loadRuleset} = useRulesetStore();
    const {ruleset} = useRulesetStore();
    const [isAddModalOpen, {toggle: toggleIsAddModalOpen}] = useBoolean(false);
    const variables: IParamInfo[] = useMemo(() => sortBy(ruleset?.globalParams ?? [], 'isDeleted'), [ruleset?.globalParams]);
    const theme = useTheme();
    const isDestroy = useIsDestroy();
    const [tooltip, setTooltip] = React.useState<boolean>(true);
    const [addingVariable, setAddingVariable] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isAddModalOpen) clearErrors();
    }, [clearErrors, isAddModalOpen]);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({id: 'add'}),
                iconProps: {iconName: 'add'},
                iconOnly: true,
                onClick: () => {
                    setTooltip(false);
                    toggleIsAddModalOpen();
                },
                tooltipHostProps: {
                    tooltipProps: {
                        styles: {root: {display: tooltip ? 'block' : 'none'}},
                    },
                },
            },
        ],
        [formatMessage, tooltip, toggleIsAddModalOpen]
    );

    const columns: IColumn[] = [
        {
            key: 'name',
            name: formatMessage({id: 'variableName'}),
            fieldName: 'name',
            minWidth: 150,
            targetWidthProportion: 1,
        },
        {
            key: 'expression',
            name: formatMessage({id: 'variableExpression'}),
            fieldName: 'expression',
            minWidth: 150,
            maxWidth: 350,
            targetWidthProportion: 2,
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            targetWidthProportion: 1,
            onRender: (item) => <VariablesActions variable={item}/>,
        },
    ];

    const onAddVariable = (data: IParamInfo) => {
        setAddingVariable(true);
        addGlobalVariable(data)
            .pipe(takeUntil(isDestroy))
            .subscribe(
                (variable) => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successVariableCreateMessage'}),
                    });
                    clearErrors();
                    reset();
                    toggleIsAddModalOpen();
                    loadRuleset();
                    setAddingVariable(false);
                },
                (error) => {
                    const nameExist = error?.response?.data?.code === 125030;
                    showNotification({
                        name: formatMessage({id: 'error'}),
                        type: MessageBarType.error,
                        description: formatMessage({id: nameExist ? 'variableNameAlreadyExist' : 'somethingWentWrong'}),
                    });

                    clearErrors();
                    setAddingVariable(false);
                }
            );
    };

    return (
        <>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{root: {padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}`}}}
                onMouseEnter={() => setTooltip(true)}
            />
            {variables?.length ? (
                <DataTable
                    initialColumns={columns}
                    items={variables}
                    selectionMode={SelectionMode.none}
                    containerHeight='calc(100% - 32px)'
                    disableDragDrop={true}
                />
            ) : (
                <Stack>
                    <Stack.Item align={'center'} styles={{root: {margin: 16}}}>
                        <Text>{'No variables found. Press "+" button to add a new one.'}</Text>
                    </Stack.Item>
                </Stack>
            )}

            {isAddModalOpen && (
                <VariableModal
                    loading={addingVariable}
                    disabled={!formState.isDirty}
                    isOpen={isAddModalOpen}
                    title={formatMessage({id: 'addNewGlobalVariable'})}
                    onDismiss={toggleIsAddModalOpen}
                    onSubmit={handleSubmit(onAddVariable)}
                    btnTitle={formatMessage({id: 'save'})}>
                    <VariableForm control={control} onSubmit={handleSubmit(onAddVariable)} formState={formState}/>
                </VariableModal>
            )}
        </>
    );
});
