import React from "react";
import {commonHeaders, Context, queryClient} from "../../../../utils";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";

type MutateDataType = {
    name: string;
    type: "Corporate" | "Individual"|null;
    primaryContactId: string;
    clientId: number;
};

function useUpdateTrusteeMainInfo() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: updateTrusteeMainInfo, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.clientUrl}/api/1.0/trustees/${data.clientId}/mainInfo`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getClientsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Trustee main information'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateTrusteeMainInfo, isUpdating};
}

export {useUpdateTrusteeMainInfo};