import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {createContext, useContext, useEffect, useState} from 'react';
import {ClientModal, ContactModal} from "./Clients";
import {FundModal} from "./Funds";
import {EmployeeModal} from "./Employees";
import {JobModal} from "./Jobs";
import {TimesheetModal} from "./Timesheet";
import {TrusteeModal} from "./Trustees";
import {TeamModal} from "./Team";

export interface IWorkflowContext {
    showJobModal: (jobId: number) => void;
    closeJobModal: () => void;
    showFundModal: (fundId: number, clientId: number, fundGuid: string, clientGuid: string) => void;
    closeFundModal: () => void;
    showClientModal: (clientGuid: string) => void;
    closeClientModal: () => void;

    showTrusteeModal: (clientGuid: string) => void;
    closeTrusteeModal: () => void;
    showEmployeeModal: (employeeId: number) => void;
    closeEmployeeModal: () => void;
    showTimesheetModal: (timesheetId: number) => void;
    closeTimesheetModal: () => void;
    showContactModal: (contactId: number, clientId: number) => void;
    closeContactModal: () => void;
    showTeamModal: (teamId: number) => void;
    closeTeamModal: () => void;
}

const WorkflowContext = createContext<IWorkflowContext>({
    showEmployeeModal: () => {
    },
    showClientModal: () => {
    },
    showTrusteeModal: () => {
    },
    showFundModal: () => {
    },
    showJobModal: () => {
    },
    showTimesheetModal: () => {
    },
    closeClientModal: () => {
    },
    closeTrusteeModal: () => {
    },
    closeEmployeeModal: () => {
    },
    closeFundModal: () => {
    },
    closeJobModal: () => {
    },
    closeTimesheetModal: () => {
    },
    showContactModal: () => {
    },
    closeContactModal: () => {
    },
    showTeamModal: () => {
    },
    closeTeamModal: () => {
    }
});

export const useWorkflows = () => {
    return useContext(WorkflowContext);
}

export const WorkflowLayoutPage = () => {
    const {formatMessage} = useIntl();
    const location = useLocation();
    const navigation = useNavigate();

    const [isEmployeeModalShown, setEmployeeModalAsShown] = useState<boolean>(false);
    const [isClientModalShown, setClientModalAsShown] = useState<boolean>(false);
    const [isTrusteeModalShown, setTrusteeModalAsShown] = useState<boolean>(false);
    const [isTimesheetModalShown, setTimesheetModalAsShown] = useState<boolean>(false);
    const [isFundModalShown, setFundModalAsShown] = useState<boolean>(false);
    const [isJobModalShown, setJobModalAsShown] = useState<boolean>(false);
    const [isContactModalShown, setContactModalAsShown] = useState<boolean>(false);
    const [isTeamModalShown, setTeamModalAsShown] = useState<boolean>(false);


    const [activeClientIdentifiers, setActiveClientIdentifiers] = useState<{ guid: string | null, id: number | null }>({
        guid: null,
        id: null
    });
    const [activeFundIdentifiers, setActiveFundIdentifiers] = useState<{ guid: string | null, id: number | null }>({
        guid: null,
        id: null
    });
    const [currentEmployeeId, setCurrentEmployeeId] = useState<number | null>();
    const [currentTimesheetId, setCurrentTimesheetId] = useState<number | null>();
    const [currentContactId, setCurrentContactId] = useState<number | null>();
    const [currentJobId, setCurrentJobId] = useState<number | null>();
    const [currentTeamId, setCurrentTeamId] = useState<number | null>();

    const showContactModal = (contactId: number, clientId: number) => {
        console.debug(`Opening modal for contact. clientId: ${clientId}. contactId: ${contactId}`);

        setCurrentContactId(contactId);
        setActiveClientIdentifiers(prev => ({...prev, id: clientId}));
        setContactModalAsShown(true);
    }
    const showEmployeeModal = (employeeId: number) => {
        console.debug(`Opening modal for employee. Id: ${employeeId}`);
        setCurrentEmployeeId(employeeId);
        setEmployeeModalAsShown(true);
    };
    const showClientModal = (clientGuid: string) => {
        console.debug(`Opening modal for client. Guid: ${clientGuid}`);
        setActiveClientIdentifiers(prev => ({...prev, guid: clientGuid}));
        setClientModalAsShown(true);
    };

    const showTrusteeModal = (clientGuid: string) => {
        console.debug(`Opening modal for trustee. Guid: ${clientGuid}`);
        setActiveClientIdentifiers(prev => ({...prev, guid: clientGuid}));
        setTrusteeModalAsShown(true);
    };
    const showFundModal = (fundId: number, clientId: number, fundGuid: string, clientGuid: string) => {
        console.debug(`Opening modal for fund. id: ${fundId}. FundGuid: ${fundGuid}. ClientId: ${clientId}. ClientGuid: ${clientGuid}`);
        setActiveClientIdentifiers({guid: clientGuid, id: clientId});
        setActiveFundIdentifiers({id: fundId, guid: fundGuid});
        setFundModalAsShown(true);
    };
    const showJobModal = (jobId: number) => {
        console.debug(`Opening modal for job. Id: ${jobId}.`);
        setCurrentJobId(jobId);
        setJobModalAsShown(true);
    };
    const showTimesheetModal = (timesheetId: number) => {
        console.debug(`Opening modal for timesheet. Id: ${timesheetId}.`);
        setCurrentTimesheetId(timesheetId);
        setTimesheetModalAsShown(true);
    };
    const showTeamModal = (teamId: number) => {
        console.debug(`Opening modal for team. Id: ${teamId}.`);
        setCurrentTeamId(teamId);
        setTeamModalAsShown(true);
    }
    const closeContactModal = () => {
        setCurrentContactId(null);
        setContactModalAsShown(false);
    }
    const closeClientModal = () => {
        setActiveClientIdentifiers({id: null, guid: null});
        setClientModalAsShown(false);
    };

    const closeTrusteeModal = () => {
        setActiveClientIdentifiers({id: null, guid: null});
        setTrusteeModalAsShown(false);
    };
    const closeEmployeeModal = () => {
        setCurrentEmployeeId(null);
        setEmployeeModalAsShown(false);
    };
    const closeFundModal = () => {
        setActiveFundIdentifiers({id: null, guid: null})
        setActiveClientIdentifiers({id: null, guid: null});
        setFundModalAsShown(false);
    };
    const closeJobModal = () => {
        setCurrentJobId(null);
        setJobModalAsShown(false);
    };
    const closeTimesheetModal = () => {
        setCurrentTimesheetId(null);
        setTimesheetModalAsShown(false);
    };

    const closeTeamModal = () => {
        setCurrentTeamId(null);
        setTeamModalAsShown(false);
    }

    useEffect(() => {
        if (location.pathname === '/workflow-portal') {
            navigation(RouterPaths.workflow.dashboard);
        }
    }, [location.pathname, navigation]);

    return (
        <WorkflowContext.Provider value={{
            showEmployeeModal: showEmployeeModal,
            showJobModal: showJobModal,
            showTimesheetModal: showTimesheetModal,
            showFundModal: showFundModal,
            showClientModal: showClientModal,
            closeTimesheetModal: closeTimesheetModal,
            closeJobModal: closeJobModal,
            closeFundModal: closeFundModal,
            closeEmployeeModal: closeEmployeeModal,
            closeClientModal: closeClientModal,
            showContactModal: showContactModal,
            closeContactModal: closeContactModal,
            showTrusteeModal: showTrusteeModal,
            closeTrusteeModal: closeTrusteeModal,
            showTeamModal: showTeamModal,
            closeTeamModal: closeTeamModal
        }}>
            <PageWithBreadcrumb
                breadcrumb={{
                    id: 'workflow',
                    key: RouterPaths.workflow.root,
                    text: formatMessage({id: 'workflow'}),
                }}>
                <Outlet/>

                <ClientModal
                    isOpen={isClientModalShown}
                    onDismiss={() => closeClientModal()}
                    title={formatMessage({id: 'client'})}
                    guid={activeClientIdentifiers.guid}
                />

                <TrusteeModal isOpen={isTrusteeModalShown} onDismiss={() => closeTrusteeModal()}
                              title={formatMessage({id: 'trustee'})}
                              guid={activeClientIdentifiers.guid}/>

                <FundModal
                    isOpen={isFundModalShown}
                    onDismiss={() => closeFundModal()}
                    title={formatMessage({id: 'fund'})}
                    fundGuid={activeFundIdentifiers.guid || ''}
                    clientGuid={activeClientIdentifiers.guid || ''}
                    fundId={activeFundIdentifiers.id || undefined}
                    clientId={activeClientIdentifiers.id || undefined}
                />

                <EmployeeModal
                    isOpen={isEmployeeModalShown}
                    onDismiss={() => closeEmployeeModal()}
                    title={formatMessage({id: 'employee'})}
                    id={currentEmployeeId}
                />

                <JobModal
                    isOpen={isJobModalShown}
                    onDismiss={() => closeJobModal()}
                    title={formatMessage({id: 'job'})}
                    guid={currentJobId?.toString() || ''}
                />
                <TimesheetModal onDismiss={() => closeTimesheetModal()} type={"Update"}
                                title={formatMessage({id: 'timesheet'})}
                                isOpen={isTimesheetModalShown}
                                timesheetId={currentTimesheetId ?? undefined}/>

                <ContactModal
                    isOpen={isContactModalShown}
                    onDismiss={() => closeContactModal()}
                    title={formatMessage({id: 'contact'})}
                    clientId={activeClientIdentifiers!.id!}
                    contactId={currentContactId!}
                />

                <TeamModal isOpen={isTeamModalShown} onDismiss={closeTeamModal} teamId={currentTeamId!}/>
            </PageWithBreadcrumb>
        </WorkflowContext.Provider>
    );
};
