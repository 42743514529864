import * as React from 'react';
import { cloneElement, ReactElement, useCallback, useEffect, useState } from 'react';
import {
    AnimationStyles,
    CheckboxVisibility,
    ColumnActionsMode,
    DetailsListLayoutMode,
    FontSizes,
    IColumn,
    IDetailsGroupDividerProps,
    IDetailsRowProps,
    IShimmeredDetailsListProps,
    Label,
    mergeStyles,
    NeutralColors,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    useTheme,
} from '@fluentui/react';
import { ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { IOrderedRequest } from 'interfaces';
import { OrderDirectionType } from 'enums';
import { IDetailsHeaderProps } from '@fluentui/react/lib/components/DetailsList/DetailsHeader';
import { IGroup } from '@fluentui/react/dist/react';
import { useIsDarkTheme, useScrollTo } from 'hooks';
import { useIntl } from 'react-intl';
import { useContextMenu } from '../contextMenu';
import { AttachmentDropZone } from '../../pages/JobPortal/components';
import { useLocation } from 'react-router-dom';
import { Empty, EmptyProps } from 'components';
import { useJobRedirects } from "../../pages/JobPortal/components/templates/shared";

export interface IDataTableProps extends IShimmeredDetailsListProps {
    sortableColumnKeys?: string[];
    initialColumns: ITableColumn[];
    setOrder?: (value: IOrderedRequest) => void;
    afterOrderSet?: () => void;
    containerHeight?: string | number;
    inherit?: boolean;
    groups?: IGroup[];
    showItemsCount?: boolean;
    borderless?: boolean;
    hideIfEmpty?: boolean;
    emptyProps?: EmptyProps;
    header?: {
        horizontalAlign?: 'start' | 'center' | 'end';
        rowHeight?: number | string;
    };
    disableDragDrop?: boolean;
    contextMenuOptions?: { meta?: object; disable?: boolean };
    navigationKeySelector?: string;
}

export interface ITableColumn extends IColumn {
    skip?: boolean;
    horizontalAlign?: 'start' | 'center' | 'end';
}

export const DataTable: React.FunctionComponent<IDataTableProps> = ({
    groups,
    initialColumns,
    setOrder,
    afterOrderSet,
    borderless,
    containerHeight = '100%',
    className,
    constrainMode = ConstrainMode.horizontalConstrained,
    layoutMode = DetailsListLayoutMode.justified,
    onShouldVirtualize = () => false,
    showItemsCount,
    header,
    groupProps,
    hideIfEmpty = true,
    emptyProps,
    navigationKeySelector,
    items,
    ...otherProps
}) => {
    const rowPrefix: string = 'data-table-row-';

    const theme = useTheme();
    const isDark = useIsDarkTheme();
    const { formatMessage } = useIntl();
    const { hash } = useLocation();
    const { scrollTo } = useScrollTo(rowPrefix, 17 * 100);

    const borderColor = isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30;
    const cellStyles = {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: 8,
        fontSize: FontSizes.size14,
        borderTop: borderless ? '' : `1px solid ${borderColor}`,
        borderLeft: borderless ? '' : `1px solid ${borderColor}`,
    };
    const cellStylesHighlighted = mergeStyles({
        backgroundColor: theme.semanticColors.bodyBackgroundHovered,
    });

    const styles = mergeStyles({
        '&.ms-DetailsList': {
            borderRadius: theme.effects.roundedCorner2,
            boxShadow: theme.effects.elevation4,
        },
        'div.ms-GroupHeader-title': { color: theme.schemes?.default?.semanticColors.bodyText },
        '.ms-GroupHeader span': { color: theme.schemes?.default?.semanticColors.bodyText },
        'button.ms-GroupHeader-expand i': { color: theme.palette.themePrimary },
        '.ms-DetailsRow-cell': cellStyles,
        '.ms-DetailsRow-cell span:not(button span):not(:has(:not(b)):not(:has(br)):not(:has(small)):not(:has(div)):not(:has(p)))': {
            color: theme.schemes?.default?.semanticColors.bodyText,
        },
        'div.ms-DetailsRow-cell': { color: theme.schemes?.default?.semanticColors.bodyText },
        '.ms-DetailsRow-cell:last-of-type': { borderRight: borderless ? '' : `1px solid ${borderColor}` },
        '.ms-List-page': { borderBottom: borderless ? '' : `1px solid ${borderColor}` },
        '.ms-DetailsHeader': {
            padding: 0,
            height: 'max-content',
            minHeight: header?.rowHeight || 42,
        },
    });
    const containerStyles = mergeStyles({
        width: '100%',
        height: containerHeight,
        ...AnimationStyles.fadeIn400,
    });

    const [rowIdKeySelector] = useState(navigationKeySelector ?? 'id');

    const { openMenu, closeMenu, contextMenuItems } = useContextMenu();

    const onRenderRow = useCallback(
        (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
            if (!props || !defaultRender) {
                return null;
            }


            if (items.length === 0 && !otherProps.enableShimmer && !hideIfEmpty) {
                return (
                    <div className={containerStyles}>
                        <Stack horizontalAlign='center' tokens={{ childrenGap: 16 }}>
                            <Label disabled style={{ padding: 16, color: theme.schemes?.default?.semanticColors.bodyText }}>
                                {formatMessage({ id: 'nothingFound' })}
                            </Label>
                        </Stack>
                    </div>
                );
            }

            let row = otherProps.onRenderRow ? otherProps.onRenderRow(props, defaultRender) : defaultRender(props);
            row =
                !contextMenuItems?.length || !row || !!otherProps.contextMenuOptions?.disable
                    ? row
                    : cloneElement(row, {
                          onClick: (ev: MouseEvent) => {
                              closeMenu();
                          },
                          onContextMenu: (ev: MouseEvent) => {
                              ev.preventDefault();
                              openMenu(ev.pageX, ev.pageY, { ...props.item, ctxMeta: { ...otherProps.contextMenuOptions?.meta } });
                          },
                      });

            const overrideRow =
                props?.item && props?.item[rowIdKeySelector] ? (
                    <Stack id={`${rowPrefix}${props.item[rowIdKeySelector]}`}>{row}</Stack>
                ) : (
                    row
                );

            if (!(props?.columns ?? []).find((x) => x.key === 'actions') || !!otherProps.disableDragDrop) {
                return overrideRow;
            }

            return (
                <AttachmentDropZone itemId={props?.item[rowIdKeySelector]} tableType={props?.item?.tableType}>
                    {overrideRow}
                </AttachmentDropZone>
            );
        },
        [contextMenuItems, otherProps.onRenderRow, items, rowIdKeySelector]
    );

    const scrollToRow = useCallback(
        (id: number) => {
            scrollTo(id, cellStylesHighlighted);
        },
        [scrollTo]
    );

    useEffect(() => {
        scrollToRow(+hash.replace('#', ''));
    }, [hash, scrollToRow]);

    const onRowDidMount = useCallback(
        (item?: any, index?: number) => {
            if (hash && +hash.replace('#', '') === item?.id) {
                scrollToRow(item.id);
            }
        },
        [hash, scrollToRow]
    );

    useEffect(() => {
        const id = +hash.replace('#', '');

        if (!isNaN(id)) {
            // open group hierarchy
            if (groups?.length) {
                // open if groups are set manually
                const _fn = (groupsArr: IGroup[]) => {
                    groupsArr.forEach((g: IGroup) => {
                        // checking if group conttains item
                        const isContainsItem = items.slice(g.startIndex, g.startIndex + g.count).some((i) => i[rowIdKeySelector] === id);
                        if (isContainsItem) {
                            // open the group
                            g.isCollapsed = false;
                            if (g.children?.length) {
                                // open chhildren groups
                                _fn(g.children);
                            }
                        }
                    });
                };

                _fn(groups);
            } else {
                // open if groups are defined by items themselves
                // algorithm works but groups open/close state is handled in parent components
                // is not needed for now
                // const _fn = (items: any[]): boolean | null => {
                //     let isContainsItem: boolean | null = null;
                //     items.forEach(i => {
                //         if (i[rowIdKeySelector] === id) {
                //             isContainsItem = true;
                //             return true;
                //         }
                //         if (i.children?.length) {
                //             if (_fn(i.children)) {
                //                 i.isOpen = true;
                //                 isContainsItem = true;
                //             }
                //         }
                //     })
                //
                //     return isContainsItem;
                // }
                //
                // _fn(otherProps.items);
            }

            // 1-to-1 redirects
            scrollToRow(id);
        }
    }, [hash, groups, items, rowIdKeySelector]);

    const onRenderDetailsHeader = (
        headerProps?: IDetailsHeaderProps,
        defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
    ) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        const props = {
            ...headerProps,
            styles: {
                root: {
                    '.ms-DetailsHeader-cell:last-of-type': {
                        borderRight: `1px solid ${borderColor}`,
                    },
                    '.ms-DetailsHeader-cell': {
                        borderTop: `1px solid ${borderColor}`,
                        borderBottom: `1px solid ${borderColor}`,
                        borderLeft: `1px solid ${borderColor}`,
                        background: theme.palette.themePrimary,
                        whiteSpace: 'normal',
                        textOverflow: 'clip',
                        lineHeight: 'normal',
                        textAlign: 'center',
                        height: 'max-content',
                        minHeight: header?.rowHeight || 42,
                    },
                    '.ms-DetailsHeader-cell:hover, .ms-DetailsHeader-cell.is-empty:hover, .ms-DetailsHeader-cell.is-actionable:hover': {
                        filter: 'brightness(115%)',
                        background: theme.palette.themePrimary,
                    },
                    '.ms-DetailsHeader-cell:active, .ms-DetailsHeader-cell.is-empty:active, .ms-DetailsHeader-cell.is-actionable:active': {
                        filter: 'brightness(75%)',
                        background: theme.palette.themePrimary,
                    },
                    '.ms-DetailsHeader-cellTitle': {
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: header?.horizontalAlign || 'start',
                    },
                },
            },
        };

        return otherProps.onRenderDetailsHeader ? otherProps.onRenderDetailsHeader(props, defaultRender) : defaultRender(props);
    };

    const onRenderHeader = (headerProps?: IDetailsGroupDividerProps, defaultRender?: (props?: any) => JSX.Element | null) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        const props = {
            styles: {
                root: {
                    'span[class^="headerCount"]': {
                        display: showItemsCount ? 'initial' : 'none',
                    },
                },
            },
        };

        if (groupProps?.onRenderHeader) {
            let element = groupProps.onRenderHeader(headerProps, defaultRender);

            if (element) {
                return cloneElement(element, props);
            }
        }

        return defaultRender({ ...headerProps, ...props });
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        const newColumns: IColumn[] = renderingColumns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;

                if (setOrder) {
                    setOrder({
                        orderDirection: currColumn.isSortedDescending ? OrderDirectionType.DESC : OrderDirectionType.ASC,
                        orderBy: currColumn.fieldName,
                    });
                }
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        setRenderingColumns(newColumns);
        if (afterOrderSet) {
            afterOrderSet();
        }
    };

    const [renderingColumns, setRenderingColumns] = useState<IColumn[]>(
        initialColumns
            .filter((x) => !x.skip)
            .map((c) => ({
                ...c,
                onColumnClick: otherProps.sortableColumnKeys?.includes(c.key) ? onColumnClick : c.onColumnClick,
                columnActionsMode: otherProps.sortableColumnKeys?.includes(c.key) ? ColumnActionsMode.clickable : c.columnActionsMode,
            }))
    );

    if (!items.length && !otherProps.enableShimmer && hideIfEmpty) {
        return (
            <div className={containerStyles}>
                <Empty {...emptyProps} imageStyle={{ fontSize: '5em' }} />
            </div>
        );
    }

    return (
        <div className={containerStyles}>
            <ShimmeredDetailsList
                theme={theme.schemes?.default}
                onShouldVirtualize={onShouldVirtualize}
                shimmerLines={10}
                layoutMode={layoutMode}
                constrainMode={constrainMode}
                className={`${styles} ${className}`}
                selectionMode={SelectionMode.single}
                checkboxVisibility={CheckboxVisibility.hidden}
                compact
                groups={groups}
                groupProps={{
                    ...groupProps,
                    onRenderHeader: onRenderHeader,
                }}
                columns={renderingColumns}
                items={items.length > 0 ? items : [{}]}
                {...otherProps}
                onRenderDetailsHeader={onRenderDetailsHeader}
                onRenderRow={onRenderRow}
                onRowDidMount={onRowDidMount}
                onRenderItemColumn={() => null}
            />
        </div>
    );
};
