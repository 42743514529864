export type IIChecklistAnswer = {
	canShowQ: boolean;
	answerText: string;
}

export type IIChecklistItem = {
	jobId: number;
	hasAttachments: boolean;
	hasComments: boolean;
	questionId: number;
	order: number;
	text: string;
	description: null | string;
	successText: null | string;
	failText: null | string;
	type: number;
	answer?: ClientAuditQuestionAnswerValue;
	linkId: number;
} & IIChecklistAnswer

export enum ClientAuditQuestionAnswerValue
{
	Unknown = 0,
	Yes = 1,
	No = 2
}