import {DefaultButton, Dialog, DialogFooter, IDialogContentProps, IModalProps, PrimaryButton} from '@fluentui/react';
import {useIntl} from "react-intl";
import React from "react";

type DialogMessageProps = {
    hidden: boolean;
    onDismis: () => void;
    dialogContentProps: IDialogContentProps;
    modalProps?: IModalProps;
    onClick: () => void;
    showOk?: boolean;
    okText?: string;
    showCancel?: boolean;
    cancelText?: string;
    children?: any
};

export const DialogMessage = ({showOk = true, showCancel = true, ...props}: DialogMessageProps) => {
    const {formatMessage} = useIntl();
    
    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismis}
            dialogContentProps={props.dialogContentProps}
            modalProps={props.modalProps}>
            {props.children}
            <DialogFooter>
                {showCancel && <DefaultButton onClick={props.onDismis} text={props.cancelText ?? formatMessage({id: 'cancel'})} />}
                {showOk && <PrimaryButton onClick={props.onClick} text={props.okText ?? formatMessage({id: 'ok'})} />}
            </DialogFooter>
        </Dialog>
    );
};
