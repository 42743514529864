import { FunctionComponent, useMemo } from 'react';
import { formatDate, getFlatProcedures, getOpenOnStartupIds } from 'utils';
import { ProceduresDataTable, useProcedureContext } from 'pages/JobPortal/components';
import { SanitizedText } from 'components';
import { useIntl } from 'react-intl';

export const AuditTrailProcedureTemplate: FunctionComponent = () => {
  const { items, isLoading } = useProcedureContext();
  const { formatMessage } = useIntl();
  const rows = useMemo<any[]>(() => getFlatProcedures(items, getOpenOnStartupIds(items)), [items]);
  
  return (
    <ProceduresDataTable
      items={rows}
      isLoading={isLoading}
      columns={[
        {
          key: 'userName',
          name: formatMessage({ id: 'userName' }),
          fieldName: 'userName',
          onRender: (items) => <SanitizedText data={items?.userName} />,
          size: 2,
        },
        {
          key: 'auditDate',
          name: formatMessage({ id: 'auditDate' }),
          fieldName: 'auditDate',
          onRender: (items) => <SanitizedText data={formatDate(items?.loginDate)} />,
          size: 1,
        },
        {
          key: 'loginTime',
          name: formatMessage({ id: 'loginTime' }),
          fieldName: 'loginTime',
          onRender: (items) => <SanitizedText data={formatDate(items?.loginDate, 'HH:mm:ss')} />,
          size: 1,
        },
        {
          key: 'logoutTime',
          name: formatMessage({ id: 'logoutTime' }),
          fieldName: 'logoutTime',
          onRender: (items) => <SanitizedText data={formatDate(items?.logoutDate, 'HH:mm:ss')} />,
          size: 1,
        },
      ]}
    />
  );
};