import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "utils";

type MutateDataType = {
    text: string;
    isNonFundamental: boolean;
    refId: number;
}

function useCopyToAcl(jobId: number) {
    const context = useContext(Context);
    const {
        mutate: copyToAcl,
        isLoading: isCopying
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/copyToAcl/${jobId}`, data, {
        ...commonHeaders()
    }), {
        onSuccess: () => { }
    })
    
    return {copyToAcl, isCopying};
}

export {useCopyToAcl};