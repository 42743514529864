import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {IValidationErrors} from "../../interfaces";

interface IGetValidationErrorsParams {
    jobId: number;
}

function useGetValidationErrors({jobId}: IGetValidationErrorsParams) {
    const context = React.useContext(Context);

    const {
        data: validationData,
        isLoading: isValidationsLoading,
        refetch: refetchValidations,
        isFetching: isValidationsFetching
    } = useQuery(
        ['getValidationErrors', jobId],
        () => axios.get<IValidationErrors>(
            `${context?.config?.validationsUrl}/api/1.0/validation/${jobId}`,
            {...commonHeaders()}
        ),
        {
            enabled: !!context?.config?.proceduresUrl && !!context.tenantId,
            retry: false,
        }
    );

    return {validationData, isValidationsLoading, refetchValidations, isValidationsFetching};
}

export {useGetValidationErrors};
