import {IColumn, SelectionMode, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {DataTable, SanitizedText} from 'components';
import {getGrouppedReports} from "../../../../../../utils";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useSectionContext} from "../../../Section";

enum ReportValueType
{
    Heading,
    Value,
    Total,
    GrandTotal,
    SubHeading,
    SubTotal
}

enum MemberDetailsType
{
    Details,
    Balance,
    DetailedSummary
}

export const BGL360MemberStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
        
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups,
        reports: items.map((i: any) => {
            i.groupName = `${i.name}_${i.id}`;
            i.children = i.memberDetails.map((md: any) => {
                if (md.key === 'Your Detailed Account Summary') {
                    md.thisYearValue = formatMessage({id: 'thisYear'})
                    md.validation = formatMessage({id: 'validation'})
                    md.align = 'center'
                }
                return md;
            });
            return i;
        }),
        groupKey: 'groupName',
        formatGroupName: report => `${report.name}, ${report.address}`
    }), [items]);
    
    const isHeader = (item?: any): boolean => item?.valueType === ReportValueType.Heading || item?.valueType === ReportValueType.Total

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.memberDetailsType === MemberDetailsType.DetailedSummary && item.valueType !== ReportValueType.Heading && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.memberDetailsType === MemberDetailsType.DetailedSummary && item.valueType !== ReportValueType.Heading && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'key',
                name: formatMessage({ id: 'name' }),
                minWidth: 500,
                fieldName: 'key',
                onRender: (item, _, column) => 
                    <SanitizedText 
                        data={item[column?.fieldName || 0]} 
                        styles={{...getDepthPaddingStyles(item?.depth), ...{root: { textDecorationLine: item?.valueType === ReportValueType.SubHeading ? 'underline' : 'inherit' } }}}
                        isHeadingRow={isHeader(item)} textAlign={item?.align}
                        format={item?.format} />,
            },
            {
                key: 'thisYearValue',
                name: '',
                minWidth: 128,
                maxWidth: 256,
                fieldName: 'thisYearValue',
                onRender: (item, _, column) => 
                    <SanitizedText 
                        data={item.memberDetailsType === MemberDetailsType.Details ? item.value : item[column?.fieldName || 0]}
                        isHeadingRow={isHeader(item)} numberFormat={item.key === 'Age' ? 'text' : 'fractional'} textAlign={item?.align || (item?.memberDetailsType === MemberDetailsType.DetailedSummary ? 'right' : 'left')}
                        format={item?.format} />,
            },
            {
                key: 'validation',
                name: '',
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={reports}
        groups={groups}
        openAll={true}
        enableShimmer={isFetching}
        isHeaderVisible={false}
    />;
};
