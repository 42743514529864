import { createContext, useContext } from "react";
import { TableType } from "../../../../../../enums";
import { IAttachment } from "./IAttachment";

export interface IAttachmentsContext {
    jobId: number;
    fundId: number;
    clientId: number;
    itemId: number;
    attachments?: IAttachment[];
    isAttachmentsLoading: boolean;
    isAttachmentsFetching: boolean;
    tableType: TableType;
}

export const AttachmentsContext = createContext<IAttachmentsContext | undefined>(undefined);

export const useAttachmentsContext = () => {
    let context = useContext(AttachmentsContext);

    if (!context)
        throw new Error('No AttachmentsContext.Provider found');

    return context;
}