import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

function useResetDispatchAudit() {
    const context = useContext(Context);
    const {
        mutate: reset,
        isLoading: isResetting
    } = useMutation((jobId: number) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/resetDispatch/${jobId}`, null))

    return {reset, isResetting};
}

export {useResetDispatchAudit};