import {useState} from "react";
import {useIntl} from "react-intl";
import {Stack} from "@fluentui/react";
import {DataTableFilter} from "../shared";
import {CreateTeamModal, TeamDataTable} from "./components";
import {useGetAllTeams} from "./hooks";

export const TeamWorkflowPage = () => {
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const {formatMessage} = useIntl();
    const {response: getAllTeamsResp, isLoading} = useGetAllTeams();

    return (
        <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
            <Stack.Item shrink={0}>
                <DataTableFilter hideStatusFilter hideSearchField onAddClick={() => setShowAddModal(true)}/>
                <CreateTeamModal
                    isOpen={showAddModal}
                    onDismiss={() => setShowAddModal(false)}
                    title={formatMessage({id: 'addingNewItem'})}
                />
            </Stack.Item>
            <Stack.Item grow={1} styles={{root: {overflow: 'hidden'}}}>
                <TeamDataTable teams={getAllTeamsResp?.data || []}
                               isLoading={isLoading} setSort={() => {
                }}/>
            </Stack.Item>
        </Stack>
    );
}