import {useContext} from "react";
import {Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IEmployeeShortInfo} from "../interfaces/IEmployeeShortInfo";

function useGetAllActiveEmployees() {
    const context = useContext(Context);
    const host = context?.config?.userUrl;

    const {
        data: employees,
        isLoading,
        refetch,
        isRefetching
    } = useQuery(['getAllActiveEmployees'], () => axios.get<IEmployeeShortInfo[]>(`${host}/api/1.0/employee/active`).then(resp => resp.data));

    return {employees, isLoading, refetch, isRefetching};
}

export {useGetAllActiveEmployees};