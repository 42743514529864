import axios, { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { Context, commonHeaders } from '../../../../../../utils';
import { FeeCalculationSection } from '../Types';

interface IGetFeeCalculationSectionParams {
  jobId: number;
  jobGuid: string;
  feeMatrixVersion: number;
}

function useGetFeeCalculationSection({ jobId, jobGuid, feeMatrixVersion }: IGetFeeCalculationSectionParams) {
  const context = useContext(Context);

  const { data: feeCalculationSectionData, isLoading: isFeeCalculationSectionLoading, refetch: refetchFeeCalculationSection, isFetching: isFeeCalculationSectionFetching } = useQuery(
    ['getFeeCalculationSection', jobId, jobGuid, feeMatrixVersion],
    () => axios.get<FeeCalculationSection>(
      `${context?.config?.calculationUrl}/api/1.0/feeCalculation?jobId=${jobId}&jobGuid=${jobGuid}&feematrixVersion=${feeMatrixVersion}`,
       commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
    
      retry: false,
    }
  );

  return { feeCalculationSectionData, isFeeCalculationSectionLoading, isFeeCalculationSectionFetching, refetchFeeCalculationSection };
}

export { useGetFeeCalculationSection };