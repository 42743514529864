import {
    Callout,
    DelayedRender,
    IconButton,
    mergeStyleSets,
    useTheme,
    DirectionalHint,
    IButtonStyles, FontSizes, StackItem, Stack, TextField, PrimaryButton, DefaultButton, mergeStyles, Spinner
} from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Modal, SanitizedText } from 'components';
import { Target, useBoolean, useId } from '@fluentui/react-hooks';
import { useIntl } from "react-intl";
import { useUpdateAuditQueryComment } from '../../hooks/useUpdateAuditQueryComment';
import { Editor, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';

interface IQueryCommentProps {
    comment?: string;
    itemId: number;
    disabled: boolean;
    jobId: number;
}

interface IQueryCommentPreviewProps {
    comment: string;
    target?: Target;
}


const QueryCommentPreview: FunctionComponent<IQueryCommentPreviewProps> = ({
   comment, target,
}) => {

    const { formatMessage } = useIntl();
    const theme = useTheme();

    const styles = mergeStyleSets({
        header: {
        },
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 350,
            maxWidth: 500,
            padding: '20px 24px',
        },
    });


    return (
        <>
            <Callout className={styles.callout}
                target={`#${target}`}
                onDismiss={() => { }}
                gapSpace={3}
                role="alert"
                directionalHint={DirectionalHint.rightCenter}>
                        <Stack grow tokens={{ childrenGap: 16 }}>
                            <StackItem>
                                <SanitizedText color={theme.schemes?.default?.semanticColors.bodyText} data={comment} />
                            </StackItem>
                        </Stack>
            </Callout>
        </>
    );
};

interface IQueryCommentFormProps {
    itemId: number;
    data?: string;
    disabled: boolean;
    isUpdating: boolean;
    onSave: (newDate?: string) => void;
    onCancel: Function;
}

interface IQueryCommentFormState {
    value: EditorState;
}

export const QueryCommentForm: FunctionComponent<IQueryCommentFormProps> = ({
    data,
    disabled,
    isUpdating,
    onSave,
    onCancel,
    ...otherProps
}: IQueryCommentFormProps) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const [formState, setFormState] = useState<IQueryCommentFormState>({
        value: EditorState.push(EditorState.createEmpty(), convertFromHTML(data ?? ''), 'insert-characters'),
    });

    const onSaveClick = () => {
        onSave(formState.value.getCurrentContent().getPlainText())
    }

    const className = mergeStyles({
        selectors: {
            '.public-DraftEditor-content': {
                minHeight: 120
            },
        },
    });

    return (
        <Stack tokens={{ childrenGap: 16 }} className={className}>
            <Stack.Item>
                <Stack styles={{
                    root: {
                        border: `1px solid ${disabled ? theme.palette.neutralSecondaryAlt : theme.palette.neutralPrimary}`,
                        borderRadius: 2,
                        overflow: 'hidden',
                        padding: 8,
                        color: theme.schemes?.default?.semanticColors.bodyText
                    }
                }}>
                    <Editor
                        editorState={formState.value}
                        onChange={(v) => setFormState(prev => ({ ...prev, value: v }))}
                        readOnly={disabled || isUpdating}
                    />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                    {isUpdating && <Spinner />}
                    <PrimaryButton text={formatMessage({ id: 'save' })}
                        onClick={onSaveClick}
                        disabled={disabled || isUpdating}
                    />
                    <DefaultButton onClick={() => onCancel()}
                        text={formatMessage({ id: 'cancel' })}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

export const QueryComment: FunctionComponent<IQueryCommentProps> = ({ comment, itemId, disabled, jobId }) => {
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isPreviewVisible, { toggle: toggleIsPreviewVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const { update, isUpdating } = useUpdateAuditQueryComment();

    const styles: IButtonStyles = {
        root: {
            color: comment ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: comment ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color: comment ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    const handlePointerEnter = () => {
        toggleIsPreviewVisible();
    };

    const handlePointerLeave = () => {
        toggleIsPreviewVisible();
    };

    const onSave = (newComment?: string) => {
        if (newComment === comment) {
            return;
        }

        update({
            jobId: jobId,
            id: itemId,
            comment: newComment,
            isClientComment: true,
        }, {
            onSuccess: () => {
                toggleOpenModal()
            }
        })
    }

    return (
        <>
            <IconButton id={buttonId} 
                iconProps={{ iconName: 'Comment', style: { fontSize: FontSizes.size14 } }}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                styles={styles}
                onClick={toggleOpenModal} />
            {isPreviewVisible && comment && (<QueryCommentPreview
                comment={comment}
                target={buttonId}
            />)}
            <Modal isOpen={isOpenModal} onDismiss={toggleOpenModal} title={formatMessage({ id: 'comments' })}>
                <QueryCommentForm
                    data={comment}
                    itemId={itemId}
                    onCancel={toggleOpenModal}
                    onSave={onSave}
                    isUpdating={isUpdating}
                    disabled={disabled}>
                </QueryCommentForm>
            </Modal>
        </>
    );
};