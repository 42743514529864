import React from 'react';
import {commonHeaders, Context} from "utils";
import {useQuery} from "react-query";
import axios from "axios";


interface IGetFundJobsParams {
    fundId: string | number;
}

export interface IJobInfo {
    id: number;
    guid: string;
    year: string;
    dateARSent: Date;
}

function useGetFundJobs({fundId}: IGetFundJobsParams) {
    const context = React.useContext(Context);


    const {
        data: jobsData,
        isLoading: isJobsLoading,
        refetch: refetchJobs,
        isFetching: isJobsFetching
    } = useQuery(
        ['getFundJobs'],
        () => axios.get<IJobInfo[]>(
            `${context?.config?.jobUrl}/api/1.0/job/fund/${fundId}/`,
            {...commonHeaders()}
        ),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId,

            retry: false,
        }
    );

    return {jobsData, isJobsLoading, refetchJobs, isJobsFetching};
}

export {useGetFundJobs};
