import {format} from 'date-fns';
import {zonedTimeToUtc, utcToZonedTime} from "date-fns-tz";
import moment from 'moment'


export const globalTimeZone: string = 'Australia/Sydney';
export const dateFortmatString: string = 'dd/MM/yyyy';

export function formatDate(date: string | null, formatString?: string, exposeError: boolean = true) {
    try {
        return date ? format(new Date(date), formatString || dateFortmatString) : '';
    } catch (error) {
        return exposeError ? 'Invalid date' : date ?? '';
    }
}

export function formatStringDate(date: string | null, inputFormat: string = dateFortmatString, outputFormat: string = 'DD/MM/YYYY'): string {
    if (!date) {
        return '';
    }
    
    return moment(date, inputFormat || dateFortmatString).format(outputFormat);
}

export function toUtc(date: Date | null): Date | null {
    if (date)
        return zonedTimeToUtc(date, globalTimeZone);

    return date;

}

export function fromUtc(date: Date | string | null): Date | null {
    if (!date)
        return null;

    return utcToZonedTime(date, globalTimeZone);
}