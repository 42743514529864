import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import {IComboBoxOption, PrimaryButton, Stack} from '@fluentui/react';
import {ControlledAutoComplete, ControlledTextField, ModalFooter} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {ICreateRulesetCommand} from 'interfaces';
import {useRulesetsStore} from '../RulesetsPage';

interface Props {
    onSubmit: (data: ICreateRulesetCommand) => void;
    onDismiss: () => void;
}

export const RulesetNewForm: FunctionComponent<Props> = observer(({onSubmit, onDismiss}: Props) => {
    const {formatMessage} = useIntl();
    const {control, handleSubmit, formState} = useForm<ICreateRulesetCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            category: '',
            name: '',
        },
    });
    const {getCategories, categories, categoriesLoading} = useRulesetsStore();

    const comboBoxOptions: IComboBoxOption[] = categories.map((category) => ({
        id: category.name,
        key: category.name!,
        text: category.name!,
    }));

    useEffect(() => {
        if (!categories.length && !categoriesLoading) {
            getCategories();
        }
    });

    return (
        <Stack tokens={{childrenGap: 16}}>
            <Stack tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <ControlledAutoComplete
                        label={formatMessage({id: 'category'})}
                        name='category'
                        control={control}
                        options={comboBoxOptions}
                        rules={{required: formatMessage({id: 'requiredCategory'})}}
                        allowFreeform
                    />
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item>
                    <ControlledTextField
                        label={formatMessage({id: 'name'})}
                        name='name'
                        control={control}
                        rules={{required: formatMessage({id: 'requiredRulesetName'})}}
                    />
                </Stack.Item>
            </Stack>
            <ModalFooter horizontalAlign='end'>
                {/* <DefaultButton disabled={rulesetCreating} text={formatMessage({id: 'close'})}
                               onClick={() => onDismiss()}/> */}
                <PrimaryButton disabled={!formState.isDirty} text={formatMessage({id: 'save'})}
                               onClick={handleSubmit(onSubmit)}/>
            </ModalFooter>
        </Stack>
    );
});
