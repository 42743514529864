import {FunctionComponent, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {IDropdownOption, Stack} from '@fluentui/react';
import {Card, ControlledDropdown, ControlledTextField, ModalWithForm} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useCreateEmployee} from '../../hooks';
import {EmployeePosition, EmployeeRole} from "pages/WorkflowPortal/shared";
import {useTenantInfo} from "../../../../../providers";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
}

interface ICreateEmployeeCommand {
    firstName: string;
    lastName: string;
    email: string;
    position: EmployeePosition | null
    securityLevel: EmployeeRole | null;
    teamId: number;
}

export const EmployeesAddItemModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title}: Props) => {
    const {isWorkpapers} = useTenantInfo();
    const {control, handleSubmit, formState} = useForm<ICreateEmployeeCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            position: null,
            securityLevel: null,
        },
    });
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {create: createEmployee, isCreating} = useCreateEmployee();

    const positionOptions: IDropdownOption[] = useMemo(() => {
        if (isWorkpapers)
            return [
                {key: EmployeePosition.Auditor, text: formatMessage({id: 'accountant'})},
                {key: EmployeePosition.Administrator, text: EmployeePosition[EmployeePosition.Administrator]},
                {key: EmployeePosition.Partner, text: EmployeePosition[EmployeePosition.Partner]},
                {key: EmployeePosition.Manager, text: EmployeePosition[EmployeePosition.Manager]},
            ]

        return Object.keys(EmployeePosition)
            .filter((x) => Number.isNaN(Number(x)))
            .map((c => ({key: c.toString(), text: c.toString()})));
    }, [isWorkpapers])

    const roleOptions: IDropdownOption[] = useMemo<IDropdownOption[]>(() => {
        if (isWorkpapers)
            return [
                {key: EmployeeRole.Auditor, text: formatMessage({id: 'accountant'})},
                {key: EmployeeRole.Administrator, text: EmployeeRole[EmployeeRole.Administrator]},
                {key: EmployeeRole.SuperUser, text: EmployeeRole[EmployeeRole.SuperUser]},
                {key: EmployeeRole.Workflow, text: EmployeeRole[EmployeeRole.Workflow]}
            ]

        return Object.keys(EmployeeRole)
            .filter((x) => Number.isNaN(Number(x)))
            .map((c => ({key: c.toString(), text: c.toString()})));
    }, [isWorkpapers])

    const onSubmit = (data: ICreateEmployeeCommand) => {
        createEmployee({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            position: data.position!,
            teamId: data.teamId,
            role: data.securityLevel!
        });
        onDismiss();
    };
    return (
        <ModalWithForm isSubmitting={isCreating} isOpen={isOpen} onDismiss={onDismiss}
                       submitDisabled={!formState.isDirty} onSubmit={handleSubmit(onSubmit)}
                       title={title}>
            <Stack tokens={{childrenGap: 16}}>
                <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='firstName'
                                required
                                label={formatMessage({id: 'firstName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='lastName'
                                required
                                label={formatMessage({id: 'lastName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='email'
                                required
                                label={formatMessage({id: 'email'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDropdown
                                options={positionOptions}
                                required
                                name='position'
                                label={formatMessage({id: 'position'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledDropdown
                                name='securityLevel'
                                required
                                options={roleOptions}
                                label={formatMessage({id: 'securityLevel'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
            </Stack>
        </ModalWithForm>
    );
});
