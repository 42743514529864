export function deepMerge(target: any, source: any): any {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            if (target.hasOwnProperty(key) && typeof target[key] === 'object' && typeof source[key] === 'object') {
                deepMerge(target[key], source[key]); // Recursively merge nested objects
            } else {
                target[key] = source[key]; // Assign value if property is not an object
            }
        }
    }
    return target;
}