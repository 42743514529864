import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import { useInvoicePortalNotifications } from "../../../hooks";

type MutateDataType = {
    clinetIdentifier: string;
    id: number;
}

function useDeleteInvoice() {
    const context = useContext(Context);
    const {showOnDeleteNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: deleteInvoice,
        isLoading: isDeleting
    } = useMutation((data: MutateDataType) => axios.delete(`${context?.config?.billingUrl}/api/1.0/Invoice/Delete/${data.clinetIdentifier}/${data.id}`,
        commonHeaders()), {
        onSuccess: () => { 
            queryClient.invalidateQueries('getInvoices')
            showOnDeleteNotification.success({ values: { name: 'Invoice' }});
        },
        onError: (error: AxiosError) => {
            showOnDeleteNotification.error({ values: { ...error?.response?.data ?? {} }})
        }
    })

    return {deleteInvoice, isDeleting};
}

export {useDeleteInvoice};