import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {ContextualMenu, IContextualMenuItem} from '@fluentui/react';
import {logger} from "../../services";

interface IContextualMenuContext {
    caller?: any | null;
    contextMenuItems: IContextualMenuItem[];
    setContextMenuItems: (items: IContextualMenuItem[]) => void;
    isOpen: boolean;
    openMenu: (x: number, y: number, item: any) => void;
    closeMenu: () => void;
}

const ContextualMenuContext = createContext<IContextualMenuContext>({
    setContextMenuItems: () => {
    },
    closeMenu: () => {
    },
    openMenu: () => {
    },
    isOpen: false,
    caller: {},
    contextMenuItems: []
});


export const useContextMenu = (): IContextualMenuContext => {
    const context = useContext(ContextualMenuContext);

    if (!context) {
        throw new Error(
            'No ContextualMenuContext.Provider found when calling useContextMenu.'
        );
    }

    return context;
};

export const ContextMenuProvider = ({
                                        children,
                                        ...otherProps
                                    }: PropsWithChildren<{}>) => {
    const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number } | null>(null);
    const [contextItem, setContextItem] = useState(null);
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([]);

    const openContextMenu = (x: number, y: number, item: any) => {
        setContextMenuPosition({x: x, y: y});
        // console.debug('[CONTEXT_PROVIDER::OPEN_MENU]', item);
        setContextItem(item);
    };
    const closeMenu = () => {
        setContextMenuPosition(null);
        setContextItem(null);
    };

    const setContextMenuItems = (items: IContextualMenuItem[]) => {
        items.forEach(x => {
            x.onClick = (e, item: any) => x.onItemClick(e, contextItem)
        });
        setMenuItems(items);
    };

    useEffect(() => {

        document.addEventListener("click", () => {
            if (!!contextItem)
                closeMenu();
        });
        return () => {
            document.addEventListener("click", () => {
                if (!!contextItem)
                    closeMenu();
            });
        };
    });
    return (
        <ContextualMenuContext.Provider value={{
            isOpen: !!contextItem,
            caller: contextItem,
            openMenu: openContextMenu,
            closeMenu: closeMenu,
            contextMenuItems: menuItems,
            setContextMenuItems: setContextMenuItems
        }}>
            {!!menuItems.length && (
                <ContextualMenu  items={menuItems} target={{x: contextMenuPosition?.x, y: contextMenuPosition?.y}}
                                hidden={!contextItem} {...otherProps}/>)}
            {children}
        </ContextualMenuContext.Provider>
    );
};