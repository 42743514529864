import React, {createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useGetJobByGuid} from "../../../../../hooks";
import {useGetValidationErrors} from "../../../hooks";
import {Loading} from "../../../../../components";
import {
    ISectionInfo,
    ISectionItemAnswerInfo,
    ISectionWithAnswersContext,
    IValidationError
} from "../../../interfaces";
import {ValidationErrors} from "./ValidationErrors";
import { AnswerControlType } from "../../../../../enums";
import { calculateSectionAnswer } from "../answers";
import { useJobContext } from "../../../JobPortalLayoutPage";
import { useSectionContext } from "../../Section";

interface Props {
    section: ISectionInfo;
}

interface IValidationsContext extends ISectionWithAnswersContext {
    errors: IValidationError[];
    options: any[];
    tableType?: number;
    isLoading?: boolean;
    refresh: () => void;
}

const ValidationsContext = createContext<IValidationsContext>({
    errors: [], 
    refresh: () => {},
    options: []
});

export const useValidationsContext = () => useContext(ValidationsContext);

export const ValidationsContent = forwardRef(({section}: Props, ref) => {
    const {guid} = useParams();
    const {dataJobs} = useGetJobByGuid({guid});
    const {answerOptions} = useJobContext();
    const {isPositiveSectionAnswer, updateSectionAnswer} = useSectionContext();
    const childRef = useRef<any>(null);

    const getAnswers = (validations: any[]) => {
        let result: { id: any, answerText: any }[] = [];
        for (let validation of validations) {
            if (validation.answerControlType == AnswerControlType.Dropdown) {
                const { id, answerText } = validation;
                result.push({ id, answerText });
            }
        }
        return result;
    }

    const { validationData, isValidationsLoading, isValidationsFetching, refetchValidations } = useGetValidationErrors({
        jobId: dataJobs?.data?.id,
    });

    const [answers, setAnswers] = useState<Partial<ISectionItemAnswerInfo>[]>([]);
    const updateAnswers = (answers: Partial<ISectionItemAnswerInfo>[]) => setAnswers(answers);

    const onItemAnswerChanged = useCallback((newValue: string, itemId?: number) => {
        console.debug("[VALIDATION::onItemAnswerChanged::initial]", newValue, itemId, answers);

        if (!answers?.length || !itemId) {
            return;
        }

        const answerIndex = answers.findIndex(x => x.id === itemId);
        if (answerIndex === -1) {
            return;
        }

        const updatedAnswers = [
            ...answers.slice(0, answerIndex),
            {
                ...answers.at(answerIndex),
                answerText: newValue
            },
            ...answers.slice(answerIndex + 1)
        ];
        updateAnswers(updatedAnswers);

        const expression = (newValue: boolean | null): boolean | null => {
            console.debug("[CUSTOM_EXPR]", newValue, updatedAnswers);
            return !!newValue && updatedAnswers.some((a: any) => !!a.hasNotClearedOmls) ? false : newValue;
        }
        const sectionAnswer = calculateSectionAnswer(updatedAnswers, answerOptions, isPositiveSectionAnswer, expression);
        console.debug("[VALIDATION::onItemAnswerChanged::calculated_answer]", sectionAnswer, updatedAnswers);
        updateSectionAnswer(sectionAnswer);

    }, [answers, answerOptions, isPositiveSectionAnswer])

    useEffect(() => {
        setAnswers(validationData?.data.validationErrors ? getAnswers(validationData.data.validationErrors) : []);
    }, [validationData]);

    
    const addHandler = () => {
        if (childRef?.current?.onAddClick) {
            childRef?.current?.onAddClick();
        }
    };

    useImperativeHandle(ref, () => ({
        refresh() {
            refetchValidations();
        },
        onAddClick() {
            addHandler();
        }
    }));

    if (isValidationsLoading || isValidationsFetching) return <Loading/>;
    return (
        <ValidationsContext.Provider value={{
            errors: validationData?.data?.validationErrors || [],
            options: validationData?.data?.answerOptions || [],
            tableType: section.tableType,
            isLoading: isValidationsFetching,
            refresh: refetchValidations,
            answers: answers,
            updateAnswers: updateAnswers,
            onItemAnswerChanged: onItemAnswerChanged
        }}>
            <ValidationErrors/>
        </ValidationsContext.Provider>
    );
});