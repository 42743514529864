import {IColumn, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { ReportTable } from '../Table/ReportTable';
import { SanitizedText } from 'components';
import {useIntl} from "react-intl";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { ReportMoneyValueCell } from "../shared/cells";



export const SuperMateMemberStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
        
    const rebuildItems = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            if (i.id === 0) {
                i.id = -1 * Date.now() + Math.random();
            }
            
            if (i.children?.length > 0) {
                i.children = rebuildItems(i.children);
            }
            
            acc.push(i);
            
            return acc;
        }, [])
    }
    
    const isActionsShown = (type?: string) => type && ['AccountItem', 'MoneyItem'].indexOf(type) !== -1;
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {isActionsShown(item?.type) && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {isActionsShown(item?.type) && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 300,
                fieldName: 'name',
                onRender: (item, _, column) => (
                    <SanitizedText
                        data={item[column?.fieldName || 0]}
                        isHeadingRow={item?.isHeadingRow || item?.isBold}
                        format={item?.format}
                    />
                ),
            },
            {
                key: 'text',
                name: '',
                minWidth: 300,
                fieldName: 'text',
                onRender: (item, _, column) => {
                    if ((item.type === 'MoneyItem' || item.type === 'AccountItem')) {
                        return <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow || item?.isBold} />
                    }
                    
                    return (
                        <SanitizedText
                            data={item[column?.fieldName || 0]}
                            isHeadingRow={item?.isHeadingRow || item?.isBold}
                            format={item?.format}
                        />
                    )
                },
            },
            {
                key: 'validation1',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation1',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        []
    );

    return <ReportTable initialColumns={columns} expandProps={{ expandChildren: true }} openAll={true} items={rebuildItems(items)} isFetching={isFetching} />;
};
