import {IconButton, ProgressIndicator, Stack, Text} from "@fluentui/react";
import {Badge, BadgeType, Card, DataTable, ITableColumn, Loading} from "../../../../../../components";
import React, {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {formatDate} from "../../../../../../utils";
import {useGetJobSchedules, useStartScheduledExport} from "./hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {IScheduledExportInfo} from "./interfaces";
import {ExecutionStatus} from "./enums";
import {PrintToPdfExportOptions} from "./PrintToPdfExportOptions";
import { NotificationType, PortalRole, useSignalR, useWorkContext } from "../../../../../../providers";
import { usePublish } from "./hooks/usePublish";

const dateFormat = "dd/MM/yyyy hh:mm aaaaa'm'";

const getType = (status: ExecutionStatus): BadgeType => {
    switch (status) {
        case ExecutionStatus.Created:
            return 'neutral';
        case ExecutionStatus.Executing:
            return 'info';
        case ExecutionStatus.Succeed:
            return 'success'
        case ExecutionStatus.Failed:
            return 'danger';

    }
}
export const PrintToPdfSchedules = () => {
    const {formatMessage} = useIntl();
    const {isInRoleOrSuperAdministrator} = useWorkContext();
    const {jobId} = useJobContext();
    const {useSignalREffect} = useSignalR();
    const {response, isLoading, refetch, isFetching} = useGetJobSchedules(jobId);
    const {start} = useStartScheduledExport();
    const [scheduledItems, setScheduledItems] = useState<IScheduledExportInfo[]>();
    const [exportOptionsModal, setExportOptionsModal] = useState<{ showModal: boolean, item: IScheduledExportInfo }>();
    const {publish} = usePublish();    
    const handleOnShowExportOptions = (item: IScheduledExportInfo) => {
        setExportOptionsModal({showModal: true, item: item});
    };

    const handleOnCloseExportOptions = () => {
        setExportOptionsModal(undefined);
    };

    const handleOnStart = (item: IScheduledExportInfo) => {
        start({
            jobId: item.jobId,
            scheduleId: item.id
        }, {
            onSuccess: () => {
                setScheduledItems(prev => prev?.map(x => x.id === item.id ? {
                    ...item,
                    executionStatus: ExecutionStatus.Executing
                } : x));
            }
        });
    }

    const handleOnPublish = (item: IScheduledExportInfo) => {
        publish({
            jobId: item.jobId,
            scheduleId: item.id
        }, {
            onSuccess: () => {
                setScheduledItems(prev => prev?.map(x => x.id === item.id ? {
                    ...item,
                    publishedToCP: true
                } : x));
            }
        });
    }

    useSignalREffect(
        'exportStatusChanged',
        (notification: NotificationType) => {
            setScheduledItems(prev => prev?.map(x => x.id === notification.content.Id ? {
                ...x,
                executionStatus: notification.content.ExecutionStatus
            } : x));
        }, []);

    const [isStartButtonEnabled] = useState<boolean>(isInRoleOrSuperAdministrator(PortalRole.Administrator));

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'status',
                name: formatMessage({id: 'printPdf_title_status'}),
                minWidth: 280,
                maxWidth: 280,
                onRender: (item: IScheduledExportInfo, index) => (
                    <Stack grow horizontalAlign={'center'}>
                        {item.executionStatus === ExecutionStatus.Executing && (
                            <ProgressIndicator styles={{root: {width: '90%'}, itemDescription: {textAlign: 'center'}}}
                                               description={'Initializing...'}/>)}
                        {item.executionStatus !== ExecutionStatus.Executing && (
                            <Badge styles={{root: {width: 80}}} text={ExecutionStatus[item.executionStatus]}
                                   type={getType(item.executionStatus)}/>)}
                    </Stack>
                )
            },
            {
                key: 'created',
                name: formatMessage({id: 'printPdf_title_created'}),
                minWidth: 300,
                maxWidth: 300,
                onRender: (item: IScheduledExportInfo, index) => (
                    <Stack grow horizontalAlign={'center'}>
                        <Text>{formatDate(item.createdDate, dateFormat)}</Text>
                    </Stack>
                )
            },
            {
                key: 'actions',
                name: formatMessage({id: 'printPdf_title_actions'}),
                minWidth: 80,
                maxWidth: 80,
                onRender: (item: IScheduledExportInfo) => (
                    <Stack grow horizontalAlign={'center'}>
                        {item.executionStatus === ExecutionStatus.Created && (
                            <IconButton
                                disabled={!isStartButtonEnabled}
                                onClick={() => handleOnStart(item)}
                                iconProps={{iconName: 'Play'}}
                            />)}
                    </Stack>
                )
            },
            {
                key: 'generated',
                name: formatMessage({id: 'printPdf_title_generated'}),
                minWidth: 280,
                maxWidth: 280,
                onRender: (item: IScheduledExportInfo, index) => (
                    <Stack grow horizontalAlign={'center'}>
                        <Text>{formatDate(item.executionFinished?.toString() || '', dateFormat)}</Text>
                    </Stack>
                )
            },
            {
                key: 'options',
                name: formatMessage({id: 'printPdf_title_options'}),
                minWidth: 80,
                maxWidth: 80,
                onRender: (item: IScheduledExportInfo) => (
                    <Stack grow horizontalAlign={'center'}>
                        <IconButton onClick={() => handleOnShowExportOptions(item)}
                                    iconProps={{iconName: 'DataManagementSettings'}}/>
                    </Stack>
                )
            },
            {
                key: 'publish',
                name: formatMessage({id: 'printPdf_title_publish'}),
                minWidth: 80,
                maxWidth: 80,
                onRender: (item: IScheduledExportInfo) => (
                    <Stack grow horizontalAlign={'center'}>
                        {item.executionStatus === ExecutionStatus.Succeed && item.reportId && !item.publishedToCP &&(
                            <IconButton onClick={() => handleOnPublish(item)}
                                iconProps={{iconName: 'PublishContent'}}/>)}
                    </Stack>
                )
            }
        ];
    }, [formatMessage, scheduledItems]);
    const refreshBtn: React.ReactElement = <IconButton onClick={() => refetch()} iconProps={{iconName: 'refresh'}}/>

    useEffect(() => {
        setScheduledItems(response);
    }, [response]);

    if (isLoading)
        return <Loading/>;

    return (
        <Card title={formatMessage({id: 'printPdf_title_scheduledReports'})} titleChildren={refreshBtn}>
            <DataTable enableShimmer={isFetching} initialColumns={columns} columns={columns}
                       emptyProps={{
                           iconName:'DocumentManagement'
                       }}
                       items={scheduledItems || []}/>
            <PrintToPdfExportOptions isOpen={exportOptionsModal?.showModal || false}
                                     onDismiss={handleOnCloseExportOptions}
                                     canEdited={exportOptionsModal?.item.executionStatus === ExecutionStatus.Created}
                                     options={exportOptionsModal?.item.option!}
                                     scheduleId={exportOptionsModal?.item.id || 0}/>
        </Card>
    );
}