import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterPaths } from "../../navigation";

type JobPortalRedirectPageProps = {
	
}

export const JobPortalRedirectPage: FunctionComponent<JobPortalRedirectPageProps> = ({ ...props }: JobPortalRedirectPageProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const search = location.search;
		
		const params = search.split('&');
		const jobGuidParam = params.at(0)
		
		if (params.length && jobGuidParam) {
			const guid = jobGuidParam
				.replace('?id=', '')
				.replace('{', '')
				.replace('}', '')
			
			const restParams = params.filter(p => p !== jobGuidParam);
			const tabId = location.hash ? location.hash.replace('#', '') : null;
			
			const redirectJobPath = (tabId ? RouterPaths.job.tab(guid, tabId) : RouterPaths.job.root(guid)) + `${restParams.length ? '?' + restParams.join('&') : ''}`

			console.debug("[JOB_REDIRECT::REDIRECT_PATH]", redirectJobPath);
			
			navigate(redirectJobPath)
			
		} else {
			console.debug("[JOB_REDIRECT::INVALID_PATH]");
			navigate('/')
		}
		
	}, [location]);
	
	return (
		<></>
	);
};