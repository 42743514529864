import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';
import { RiskMatrixDetailedRes } from './useGetRiskMatrixDetailed';

export type RiskMatrixReq = {
    detailedRiskMatrixSettings: RiskMatrixDetailedRes;
};

/**
 * Mutation Query for get Risk Matrix Detaied data in Portal Settings
 * Second table in page Risk Matrix Settings
 */

function useUpdateRiskMatrixDetailed() {
    const context = React.useContext(Context);

    const { mutate: updateRiskMatrixDetailed, isLoading: updateRiskMatrixDetailedLoading } = useMutation(
        (data: RiskMatrixReq) => axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/detailedRiskMatrix`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getRiskMatrixDetailed'),
        }
    );

    return { updateRiskMatrixDetailed, updateRiskMatrixDetailedLoading };
}

export { useUpdateRiskMatrixDetailed };
