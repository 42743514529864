import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    sendEmail: boolean;
    ignoreChecks: boolean;
}

function usePublishAudit() {
    const context = useContext(Context);
    const {
        mutate: publish,
        isLoading: isPublishing
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/publish/${data.jobId}`, {
        ...data,
        isSourceDispatched: true
    }).then(result => result.data))

    return {publish, isPublishing};
}

export {usePublishAudit};