import {FunctionComponent, ReactElement, useState} from 'react';
import {
    BarChartWidget,
    CountWidget,
    DoughnutWidget,
    GeneratedTableModal,
    IBarChartWidget,
    ICountWidget,
    IDoughnutWidget,
    ITableModal,
    ITableWidget,
    TableWidget
} from 'pages/shared/Dashboard';
import {Stack} from '@fluentui/react';

export enum WidgetsEnum {
    COUNT_WIDGET,
    TABLE_WIDGET,
    DOUGHNUT_WIDGET,
    BAR_CHART_WIDGET,
    DOUBLE_COUNT_WIDGET,
}

interface _IWidget {
    type: WidgetsEnum;
    id: string;
    size?: number;
    tables?: ITableModal[];
}

interface IWidget1 extends _IWidget {
    type: WidgetsEnum.COUNT_WIDGET;
    props: ICountWidget;
}

interface IWidget2 extends _IWidget {
    type: WidgetsEnum.TABLE_WIDGET;
    props: ITableWidget;
}

interface IWidget3 extends _IWidget {
    type: WidgetsEnum.DOUGHNUT_WIDGET;
    props: IDoughnutWidget;
}

interface IWidget4 extends _IWidget {
    type: WidgetsEnum.BAR_CHART_WIDGET;
    props: IBarChartWidget;
}

export type Widgets = IWidget1 | IWidget2 | IWidget3 | IWidget4;

export const Widget: FunctionComponent<Widgets> = ({type, props, tables}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const isClickable: boolean = !!tables?.length;

    const getWidgetByType = (type: WidgetsEnum): ReactElement | null => {
        switch (type) {
            case WidgetsEnum.COUNT_WIDGET:
                return <CountWidget {...(props as ICountWidget)} />;
            case WidgetsEnum.TABLE_WIDGET:
                return <TableWidget {...(props as ITableWidget)} />;
            case WidgetsEnum.DOUGHNUT_WIDGET:
                return <DoughnutWidget {...(props as IDoughnutWidget)} />;
            case WidgetsEnum.BAR_CHART_WIDGET:
                return <BarChartWidget {...(props as IBarChartWidget)} />;
            default:
                return null;
        }
    };

    const handleClick = isClickable ? () => setOpen(true) : undefined;

    return (
        <Stack styles={{root: {height: '100%', cursor: isClickable ? 'pointer' : 'auto'}}} onClick={handleClick}>
            {getWidgetByType(type)}

            {tables && tables.length && (
                <GeneratedTableModal
                    tables={tables}
                    isOpen={isOpen}
                    onDismiss={() => setOpen(false)}/>
            )}
        </Stack>
    );
};
