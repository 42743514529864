import { action, observable, computed, makeObservable } from 'mobx';
import { filter, Observable, take, tap } from 'rxjs';
import { IAuthorizationToken } from 'interfaces';
import { identityService, configurationService } from 'services';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'constants/authorization';
import * as localStorageService from '../services/localStorageService';
import { RouterPaths } from "../navigation";

export class AuthStore {
    public accessToken: string | undefined;
    public refreshToken: string | undefined;
    public isSigningIn: boolean = false;
    public isSigningOut: boolean = false;

    constructor() {
        const accessToken: string | null = localStorageService.getData(ACCESS_TOKEN_KEY);
        const refreshToken: string | null = localStorageService.getData(REFRESH_TOKEN_KEY);
        if (accessToken && refreshToken) {
            this.setToken({ accessToken, refreshToken });
        }

        makeObservable(this, {
            accessToken: observable,
            refreshToken: observable,
            isSigningIn: observable,
            isSigningOut: observable,
            isAuthorised: computed,
            clearToken: action,
            getToken: action,
            signIn: action,
        });
    }

    public getToken = (code: string, userId: string) => {
        identityService.getToken(code, userId).subscribe(this.setTokenAndReload);
    };

    public signIn = () => {
        this.isSigningIn = true;
        configurationService.config
            .pipe(
                filter((value) => !!value),
                take(1)
            )
            .subscribe({
                next: (config) => {
                    let redirectUrl = window.location.href;
                    window.location.replace(config!.identityUrl! + '/Authentication/SignIn?redirectUrl=' + encodeURIComponent(redirectUrl));
                },
                error: () => (this.isSigningIn = false),
            });
    };

    public getTokenByRefreshToken: () => Observable<IAuthorizationToken> = () => {
        return identityService.refreshToken(this.refreshToken!).pipe(tap(this.setToken));
    };

    public signOut = () => {
        this.isSigningOut = true;
        configurationService.config
            .pipe(
                filter((value) => !!value),
                take(1)
            )
            .subscribe({
                next: (config) => {
                    this.clearToken();
                    window.location.replace(config!.identityUrl! + '/Authentication/SignOut');
                },
                error: () => (this.isSigningOut = false),
            });
    };

    public clearToken = () => {
        this.accessToken = undefined;
        this.refreshToken = undefined;
        localStorageService.removeData(ACCESS_TOKEN_KEY);
        localStorageService.removeData(REFRESH_TOKEN_KEY);
    };

    public get isAuthorised(): boolean {
        return !!this.refreshToken && !!this.accessToken;
    }

    private setToken = (token: IAuthorizationToken) => {
        this.accessToken = token.accessToken;
        this.refreshToken = token.refreshToken;
        localStorageService.setData(ACCESS_TOKEN_KEY, token.accessToken);
        localStorageService.setData(REFRESH_TOKEN_KEY, token.refreshToken);
    };

    private setTokenAndReload = (token: IAuthorizationToken) => {
        this.setToken(token);
        window.location.reload();
    };
}
