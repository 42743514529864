import React, {
    createContext,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {Shimmer, ShimmerElementType, Stack} from '@fluentui/react';
import {Layout, Loading} from 'components';
import {useGetJobTabs} from 'hooks/useGetJobTabs';
import {UpperMenu} from './UpperMenu';
import {useGetJobByGuid} from 'hooks/useGetJobByGuid';
import {RouterPaths} from "../../navigation";
import {IAvailableAnswerOptions, useGetAllAnswerOptions} from './hooks/answers';
import {useCreateAduitTrail} from "./hooks/useCreateAuditTrail";
import {useUpdateAduitTrail} from "./hooks/useUpdateAuditTrail";
import * as localStorageService from "../../services/localStorageService";
import {JobFooter} from './JobFooter';
import {useGetPriorJob} from "../../hooks";
import {JOB_IDENTIFIER_KEY} from "../../constants/authorization";
import {queryClient} from "../../utils";
import {_GetWorkContextQueryKey} from "../../providers";

export interface IJobPortalContext {
    jobId: number;
    job: any
    jobGuid: string;
    jobYear: number;
    fundId: number;
    clientGuid: string;
    isJobAuditFinalised: boolean;
    answerOptions: IAvailableAnswerOptions | undefined;
    isAnswerOptionsLoading: boolean;
    refetch?: Function;
    jobTabs: any[];
    suggestedTestingInfo?: { isApproved: boolean, setIsApproved: (isApproved: boolean) => void; }
    priorJob?: any;
}

export const JobContext = createContext<IJobPortalContext>({
    jobGuid: '',
    jobId: 0,
    job: {},
    fundId: 0,
    jobYear: 0,
    clientGuid: '',
    isJobAuditFinalised: false,
    answerOptions: undefined,
    isAnswerOptionsLoading: false,
    refetch: undefined,
    jobTabs: [],
    priorJob: {}
});

export const useJobContext = () => {
    return useContext(JobContext);
}

const getMenuIcon = (reference: string) => {
    switch (reference) {
        case "Client Assessment":
            return "Onboarding";
        case "Planning":
            return "AllApps";
        case "Regulatory Review":
            return "ComplianceAudit";
        case "Validation":
            return "Completed";
        case "Statement OF FP":
            return "Financial";
        case "Operating Statement":
            return "ReportDocument";
        case "Investments":
            return "PieSingle";
        case "Income":
            return "AllCurrency";
        case "Members":
            return "PlannerLogo";
        case "reviews":
            return "FileComment";
        case "Conclusion_new":
            return 'Warning';
        case "Contravention Report":
            return "InsertSignatureLine";
        case "TimeSheet":
            return "Calendar";
        case "Administration":
            return "WorkFlow";
        case "AmendedFinancials":
            return "BarChartVerticalEdit"
    }
}

export const JobPortalLayoutPage: FunctionComponent = () => {
    const {guid, tabId} = useParams();
    const navigate = useNavigate();
    const {dataJobs, isJobLoading, refetchJob, isJobRefetching} = useGetJobByGuid({guid});
    const {priorJobData, isPriorJobLoading, isPriorJobRefetching} = useGetPriorJob(dataJobs?.data?.id)
    const {dataJobsTab} = useGetJobTabs(dataJobs?.data?.templateId);
    const {response: optionsResponse, isLoading: isOptionsLoading} = useGetAllAnswerOptions();

    const {create: createAduitTrail, isCreating: isAuditTrailCreating} = useCreateAduitTrail();
    const {update: updateAuditTrail, isUpdating: isAuditInfoUpdating} = useUpdateAduitTrail();

    const [isDetailsOpened, setIsDetailsOpened] = useState(false);

    const [isSuggestedTestingApproved, setIsSuggestedTestingApproved] = useState<boolean>(false);

    const links = dataJobsTab?.data.map((item: any) => ({
        name: item.caption,
        url: RouterPaths.job.tab(guid, item.id),
        key: item.caption,
        iconProps: {iconName: getMenuIcon(item.reference)},
    }));

    const scrollRef = useRef(null);

    const toggleDetails = useCallback(() => {
        setIsDetailsOpened(value => !value);
        (scrollRef?.current as any)?.scrollIntoView({behavior: 'smooth'});
    }, [scrollRef]);

    const leftMenu = useMemo(() => {
        if (isJobLoading && !dataJobs?.data?.id) {
            return (
                <Shimmer shimmerElements={[
                    {type: ShimmerElementType.circle},
                    {type: ShimmerElementType.gap, width: 16},
                    {type: ShimmerElementType.line, width: 250},
                    {type: ShimmerElementType.gap, width: 16},
                    {type: ShimmerElementType.line, width: 250},
                    {type: ShimmerElementType.gap, width: 16},
                    {type: ShimmerElementType.line, width: 250}]}/>
            )
        }

        return (
            <UpperMenu dataClient={dataJobs} toggleDetails={toggleDetails} detailsOpened={isDetailsOpened}/>
        )
    }, [dataJobs?.data?.id, toggleDetails, isDetailsOpened]);


    useEffect(() => {
        if (!tabId && dataJobsTab?.data?.length > 0) {
            navigate(`${dataJobsTab?.data[0].id}`);
        }
    }, [dataJobsTab, tabId, navigate]);

    useEffect(() => {
        if (dataJobs?.data?.id) {
            localStorageService.setData(JOB_IDENTIFIER_KEY, dataJobs?.data?.guid)
            queryClient.invalidateQueries(_GetWorkContextQueryKey);
            createAduitTrail({jobId: dataJobs.data.id}, {
                onSuccess: (res) => {
                    localStorageService.setData('auditTrailId', res?.data?.id);
                }
            })
        }
    }, [dataJobs?.data?.id])

    useEffect(() => {
        window.onbeforeunload = () => {
            sessionStorage.clear();
            localStorageService.removeData(JOB_IDENTIFIER_KEY);
            const id = localStorageService.getDataAndRemove<number>('auditTrailId');
            if (id) {
                updateAuditTrail({id: id!});
            }
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <JobContext.Provider value={{
            jobGuid: guid || '',
            jobYear: dataJobs?.data.year,
            jobId: dataJobs?.data?.id,
            fundId: dataJobs?.data?.fund?.id,
            clientGuid: dataJobs?.data.client.guid,
            isJobAuditFinalised: !!dataJobs?.data?.auditFinalised,
            job: dataJobs?.data,
            answerOptions: optionsResponse?.data,
            isAnswerOptionsLoading: isOptionsLoading,
            refetch: refetchJob,
            jobTabs: dataJobsTab?.data ?? [],
            suggestedTestingInfo: {
                isApproved: isSuggestedTestingApproved,
                setIsApproved: (isApproved: boolean) => setIsSuggestedTestingApproved(isApproved)
            },
            priorJob: priorJobData?.data
        }}>
            <Layout links={links} headerLeft={leftMenu}
                    footerRight={(!isJobLoading && dataJobs?.data?.id) && <JobFooter/>}>
                <div ref={scrollRef}>
                </div>
                <Stack
                    styles={{
                        root: {
                            width: '100%',
                            paddingBottom: 16,
                            paddingTop: isDetailsOpened ? 120 : 0,
                        },
                    }}>

                    {(isJobLoading || isAuditTrailCreating) && !dataJobs?.data?.id && (
                        <Stack horizontalAlign='center' verticalAlign='center' styles={{root: {height: '85vh'}}}>
                            <Loading/>
                        </Stack>)}
                    {!isJobLoading && !isAuditTrailCreating && (<Outlet/>)}
                </Stack>
            </Layout>
        </JobContext.Provider>
    );
};
