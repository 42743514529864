import {IExportOptionInfo, IPrintToPdfOption} from "./interfaces";
import {useForm} from "react-hook-form";
import {Stack} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useCallback, useEffect, useMemo, useState} from "react";
import {DefaultFormSettings} from "../../../../../../constants";
import {ControlledCheckbox, ModalWithForm} from "components";
import {useUpdateExportOptions} from "./hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useTenantInfo} from "../../../../../../providers";
import {SoftwareType} from "../../../../../../enums";

type PrintToPdfExportOptionsProps = {
    isOpen: boolean;
    onDismiss: () => void;
    options: IExportOptionInfo;
    scheduleId: number;
    canEdited: boolean;
}

export const PrintToPdfExportOptions = (props: PrintToPdfExportOptionsProps) => {
    const {formatMessage} = useIntl();
    const {jobId, job} = useJobContext();
    const {update, isUpdating} = useUpdateExportOptions();
    const {isBlackLabel} = useTenantInfo();
    const {control, setValue, reset, formState, watch, handleSubmit} = useForm<IExportOptionInfo>({
        ...DefaultFormSettings,
        defaultValues: props.options
    });

    const watchFields = watch();
    const handleOnSubmit = (options: IExportOptionInfo) => {
        update({
            options: options,
            exportId: props.scheduleId,
            jobId: jobId
        }, {
            onSuccess: () => {
                handleOnClose();
            }
        });
    };
    const handleOnClose = () => {
        reset();
        props.onDismiss();
    };

    useEffect(() => {
        if (!props.options)
            return;

        setValue('isInternalUse', props.options.isInternalUse);
        setValue('isEvolvBranded', props.options.isEvolvBranded);
        setValue('isGeneralLedgerIncluded', props.options.isGeneralLedgerIncluded);
        setValue('isBankAccountMovementReportIncluded', props.options.isBankAccountMovementReportIncluded);
        setValue('isBalanceReconciliationReportIncluded', props.options.isBalanceReconciliationReportIncluded);
        setValue('isTrialBalanceIncluded', props.options.isTrialBalanceIncluded);
        setValue('isCurrentYearDocsIncluded', props.options.isCurrentYearDocsIncluded);
        setValue('isPermanentDocsIncluded', props.options.isPermanentDocsIncluded);
        setValue('isClientPortalDocsIncluded', props.options.isClientPortalDocsIncluded);
        setValue('isAclAuditContraventionIncluded', props.options.isAclAuditContraventionIncluded);
        setValue('isBankStatementIncluded', props.options.isBankStatementIncluded);
    }, [props.options]);

    return (
        <ModalWithForm isOpen={props.isOpen}
                       onSubmit={handleSubmit(handleOnSubmit)}
                       isSubmitting={isUpdating}
                       submitDisabled={!formState.isDirty || !props.canEdited}
                       title={formatMessage({id: 'printToPdf_exportOptions_title'})}
                       minWidth={250}
                       width={500}
                       onDismiss={handleOnClose}>
            <Stack tokens={{childrenGap: 16}}>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isInternal'})}
                                    disabled={!props.canEdited}
                                    name={'isInternalUse'}
                                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isEvolvBranded'})}
                                    name={'isEvolvBranded'}
                                    disabled={!props.canEdited || (isBlackLabel && watchFields.isInternalUse)}
                                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isGeneralLedgerIncluded'})}
                                    name={'isGeneralLedgerIncluded'}
                                    disabled={!props.canEdited}
                                    control={control}/>
                <ControlledCheckbox
                    label={formatMessage({id: 'printToPdf_exportOptions_isBankAccountMovementReportIncluded'})}
                    name={'isBankAccountMovementReportIncluded'}
                    disabled={!props.canEdited || job.softwareType !== SoftwareType.ClassSuper}
                    control={control}/>
                <ControlledCheckbox
                    label={formatMessage({id: 'printToPdf_exportOptions_isBalanceReconciliationReportIncluded'})}
                    name={'isBalanceReconciliationReportIncluded'}
                    disabled={!props.canEdited || job.softwareType !== SoftwareType.ClassSuper}
                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isTrialBalanceIncluded'})}
                                    name={'isTrialBalanceIncluded'}
                                    disabled={!props.canEdited || (job.softwareType !== SoftwareType.DesktopSuper && job.softwareType !== SoftwareType.BGL360)}
                                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isCurrentYearDocsIncluded'})}
                                    name={'isCurrentYearDocsIncluded'}
                                    disabled={!props.canEdited}
                                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isPermanentDocsIncluded'})}
                                    name={'isPermanentDocsIncluded'}
                                    disabled={!props.canEdited}
                                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isClientPortalDocsIncluded'})}
                                    name={'isClientPortalDocsIncluded'}
                                    disabled={!props.canEdited}
                                    control={control}/>
                <ControlledCheckbox
                    label={formatMessage({id: 'printToPdf_exportOptions_isAclAuditContraventionIncluded'})}
                    name={'isAclAuditContraventionIncluded'}
                    disabled={!props.canEdited || !watchFields.isInternalUse}
                    control={control}/>
                <ControlledCheckbox label={formatMessage({id: 'printToPdf_exportOptions_isBankStatementIncluded'})}
                                    name={'isBankStatementIncluded'}
                                    disabled={!props.canEdited || job.softwareType !== SoftwareType.BGL360}
                                    control={control}/>
            </Stack>
        </ModalWithForm>
    );
}