import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context } from 'utils';

/**Mutation query for sending test email*/

function useSendTestEmail() {
    const context = React.useContext(Context);
    const { mutate: sendTestEmail } = useMutation(
        () => axios.post(`${context?.config?.notificationApiUrl}/api/1.0/Email/sendTestEmail`, null, commonHeaders()),
        {
        }
    );

    return { sendTestEmail };
}

export { useSendTestEmail };
