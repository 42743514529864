import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
    DetailedImportStatus,
    IImportStatusNotification,
    ImportStatus,
    useEditJobImportDataLock,
    useEditJobImportDataUnlock,
    useGetJobImportData,
    useGetJobImportStatus,
} from '../hooks';
import { Link, MessageBarType, Stack, Text, useTheme } from '@fluentui/react';
import { UncalledForm } from './UncalledForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { ImportReportForm } from './ImportReportForm';
import { LockUnlockImport } from './LockUnlockImport';
import { ImportStatusPanel } from './ImportStatusPanel';
import { Loading } from '../../../components';
import { useGetJobByGuid } from '../../../hooks';
import { SoftwareType } from '../../../enums';
import { NotificationType, useSignalR } from '../../../providers';
import { useNotifications } from '../../../components/notifications';
import { TenantGuard } from 'guards';
import { ImportReportProgress } from './ImportReportProgress';

interface IImportReportContentProps {
    guid?: number | string;
}

type LastImportState = {
    key?: string;
    status?: DetailedImportStatus;
};

export const ImportReportContent: FunctionComponent<IImportReportContentProps> = ({ guid }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { jobImportData, isJobImportDataFetching, refetchJobImportData, isJobImportDataRefetching } = useGetJobImportData({ guid });
    const { jobImportStatus, isJobImportStatusFetching, refetchJobImportStatus } = useGetJobImportStatus({
        id: jobImportData?.data?.jobId,
    });

    const { useSignalREffect } = useSignalR();
    const { showNotification } = useNotifications();

    const { dataJobs, isJobLoading } = useGetJobByGuid({ guid: guid!.toString() });
    const { lockImportData, isLoading: isLockLoading } = useEditJobImportDataLock();
    const { unlockImportData, isLoading: isUnlockLoading } = useEditJobImportDataUnlock();

    const [isImportInProgress, setIsImportInProgress] = useState<boolean>(jobImportStatus?.data.statusCode === ImportStatus.InProgress);
    const [importStage, setImportStage] = useState<string | null>(null);

    const [lastImport, setLastImport] = useState<LastImportState>({});
    const [showStatus, setShowStatus] = useState<boolean>(false);

    const onImport = (reportStatementKey?: string) => {
        setImportStage(null);
        setIsImportInProgress(true);
        setShowStatus(false);
        setLastImport({ key: reportStatementKey, status: undefined });
    };

    const onDelete = () => {
        setLastImport({});
    };

    const onImportStatusChanged = (
        status: DetailedImportStatus,
        options: { showMessage?: boolean; shouldRefetchInfo?: boolean; showStatus?: boolean }
    ) => {
        let type: MessageBarType = MessageBarType.info;
        let message: string | null = null;
        let showMessage: boolean = false;
        let shouldRefetchInfo: boolean = false;

        switch (status) {
            case DetailedImportStatus.Preparing:
            case DetailedImportStatus.ImportStarted:
            case DetailedImportStatus.ImportScheduled:
            case DetailedImportStatus.FilesUploaded:
                message = formatMessage({ id: 'importStageUploading' });
                showMessage = false;
                break;
            case DetailedImportStatus.Parsing:
            case DetailedImportStatus.Parsed:
                message = formatMessage({ id: 'importStageParsing' });
                showMessage = false;
                break;
            case DetailedImportStatus.ReportsCreation:
            case DetailedImportStatus.ReportsCreated:
                message = formatMessage({ id: 'importStageReportCreating' });
                showMessage = false;
                break;
            case DetailedImportStatus.Validating:
            case DetailedImportStatus.ValidationFinished:
                message = formatMessage({ id: 'importStageValidating' });
                showMessage = false;
                break;
            case DetailedImportStatus.ApiCallFailed:
            case DetailedImportStatus.ImportFailed:
            case DetailedImportStatus.Failed:
                type = MessageBarType.error;
                message = formatMessage({ id: 'importError' });
                showMessage = true;
                shouldRefetchInfo = true;
                setIsImportInProgress(false);
                setShowStatus(!!options.showStatus);
                setLastImport((prev) => ({ ...prev!, status: DetailedImportStatus.Failed }));
                break;
            case DetailedImportStatus.ImportCompleted:
            case DetailedImportStatus.Success:
                type = MessageBarType.success;
                message = formatMessage({ id: 'importCompleted' });
                showMessage = true;
                shouldRefetchInfo = true;
                setIsImportInProgress(false);
                setShowStatus(!!options.showStatus);

                setLastImport((prev) => ({ ...prev!, status: DetailedImportStatus.Success }));
                break;
            default:
                type = MessageBarType.info;
                break;
        }

        if (!showMessage) {
            setImportStage(message);
        }

        if (!!options.showMessage && showMessage && message) {
            showNotification({
                name: message,
                description: message,
                type: type,
            });
        }

        if (!!options.shouldRefetchInfo && shouldRefetchInfo) {
            refetchJobImportData();
            refetchJobImportStatus();
        }
    };

    const disabled = useMemo<boolean>(
        () => isLockLoading || isUnlockLoading || isImportInProgress,
        [isLockLoading, isUnlockLoading, isImportInProgress]
    );

    const isContentLoading = useMemo<boolean>(() => {
        return (
            (!jobImportData && isJobImportDataFetching) ||
            isJobImportDataRefetching ||
            (!dataJobs && isJobLoading) ||
            isJobImportStatusFetching
        );
    }, [isJobLoading, jobImportData, isJobImportDataFetching, isJobImportDataRefetching, dataJobs, isJobImportStatusFetching]);

    const softwareTypeLabel = useMemo<string>(() => {
        const postfix = formatMessage({ id: 'dataImport' });
        if (!jobImportData || !jobImportData.data) {
            return postfix;
        }

        const builder = (softwareTypeString: string, type: 'API' | 'PDF' | string, post?: string): string =>
            `${softwareTypeString} ${type} ${postfix}`;

        switch (jobImportData.data.softwareType) {
            case SoftwareType.BGL: {
                return builder('BGL', 'Simple Fund', postfix);
            }
            default: {
                return builder(jobImportData.data.softwareType, jobImportData.data.isApi ? 'API' : 'PDF', postfix);
            }
        }
    }, [jobImportData, formatMessage]);

    useSignalREffect(
        'import_status_updated',
        (notification: NotificationType) => {
            const content: IImportStatusNotification = { ...notification.content };

            if (content.JobId !== jobImportData?.data.jobId) return;

            onImportStatusChanged(content.NewStatus, { shouldRefetchInfo: true, showMessage: true, showStatus: true });
        },
        [showNotification, formatMessage, lastImport, jobImportData]
    );

    useEffect(() => {
        const inProgress = jobImportStatus?.data.statusCode === ImportStatus.InProgress;
        setIsImportInProgress(jobImportStatus?.data.statusCode === ImportStatus.InProgress);
        if (inProgress)
            onImportStatusChanged(jobImportStatus?.data.detailedStatus, { shouldRefetchInfo: false, showMessage: false, showStatus: true });
    }, [jobImportStatus]);

    if (isContentLoading)
        return (
            <Stack grow={1}>
                <Loading />
            </Stack>
        );

    if (!jobImportData?.data || !guid)
        return (
            <Stack grow={1} horizontalAlign='center' verticalAlign='center'>
                <Text variant='medium' theme={theme.schemes?.default}>
                    <FormattedMessage id='noData' />
                </Text>
            </Stack>
        );

    return (
        <Stack grow={1} tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <Stack horizontal horizontalAlign='space-between'>
                    <Stack.Item>
                        <Text theme={theme.schemes?.default}>{softwareTypeLabel}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        {!jobImportData.data.isJobLocked && dataJobs?.data?.dateReceived && (
                            <Link
                                styles={{ root: { color: theme.schemes?.default?.palette.blue } }}
                                underline
                                href={RouterPaths.job.root(guid)}>
                                <FormattedMessage id='launchAuditProgram' />
                            </Link>
                        )}
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            {!dataJobs?.data?.dateReceived && (
                <Stack.Item>
                    <Text theme={theme.schemes?.default}>
                        <FormattedMessage id='importUnsubmittedJobMessage' />
                    </Text>
                </Stack.Item>
            )}

            {dataJobs?.data?.dateReceived && (
                <Stack.Item>
                    {!isImportInProgress &&
                        jobImportData.data.isApi &&
                        (jobImportData.data.isApiCalled
                            ? !jobImportData.data.isJobLocked && (
                                  <ImportReportForm
                                      jobImportData={jobImportData.data}
                                      onImport={onImport}
                                      onDelete={onDelete}
                                      selectedKey={lastImport.key}
                                      disabledButtons={disabled}
                                  />
                              )
                            : !isImportInProgress && (
                                  <UncalledForm jobId={jobImportData.data.jobId} onImport={onImport} disabledButtons={disabled} />
                              ))}
                    {!jobImportData.data.isApi && !jobImportData.data.isJobLocked && (
                        <ImportReportForm
                            jobImportData={jobImportData.data}
                            onImport={onImport}
                            onDelete={onDelete}
                            selectedKey={lastImport.key}
                            disabledButtons={disabled}
                        />
                    )}
                    <TenantGuard behavior='hide' types={['white']}>
                        {!isImportInProgress && jobImportData.data.allowLockJob && (
                            <Stack.Item styles={{ root: { marginTop: theme.spacing.m } }}>
                                <LockUnlockImport
                                    isJobLocked={jobImportData.data.isJobLocked}
                                    disabled={disabled}
                                    onLock={() => lockImportData(guid)}
                                    onUnlock={() => unlockImportData(guid)}
                                />
                            </Stack.Item>
                        )}
                    </TenantGuard>
                    {isImportInProgress && <ImportReportProgress stage={importStage} />}
                    {showStatus && lastImport?.status === DetailedImportStatus.Success && (
                        <ImportStatusPanel autoHide success message={<FormattedMessage id='importSuccessful' />} />
                    )}
                    {showStatus &&
                        (jobImportStatus?.data?.detailedStatus === DetailedImportStatus.ImportFailed ||
                            jobImportStatus?.data?.detailedStatus === DetailedImportStatus.Failed) && (
                            <ImportStatusPanel
                                autoHide
                                success={false}
                                message={<FormattedMessage id='importFailedMessage' values={{ error: jobImportStatus?.data.message }} />}
                            />
                        )}
                </Stack.Item>
            )}
        </Stack>
    );
};
