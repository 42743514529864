import { AuthStore } from './AuthStore';
import { BreadcrumbsStore } from './BreadcrumbsStore';

export class MainStore {
    public AuthStore: AuthStore;
    public BreadcrumbsStore: BreadcrumbsStore;

    constructor() {
        this.AuthStore = new AuthStore();
        this.BreadcrumbsStore = new BreadcrumbsStore();
    }
}

const mainStore: MainStore = new MainStore();
export const stores = {
    MainStore: mainStore,
    AuthStore: mainStore.AuthStore,
    BreadcrumbsStore: mainStore.BreadcrumbsStore,
};
