import {PropsWithChildren, useEffect} from "react";
import {useAxiosConfig} from "../hooks";

export const HttpServiceProvider = (props: PropsWithChildren<any>) => {
    const axiosConfig = useAxiosConfig();

    useEffect(() => {
        axiosConfig.config();
    }, []);

    return (<>{props.children}</>)
}