import {useGetTabReports} from "./hooks";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {ISectionInfo} from "../../../../interfaces";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {Stack, Text} from "@fluentui/react";
import {getReportTemplate} from "../../../../factories";
import {ReportTemplateTypeProps} from "../../reports/Types";
import {Loading} from "../../../../../../components";
import {TableType} from "../../../../../../enums";
import {IReport, IReportInfo} from "../../../../interfaces/IReportsInfo";
import {getFlatProcedures, getOpenOnStartupIds} from "../../../../../../utils";
import {useIntl} from "react-intl";

type Props = {
    section: ISectionInfo;
}

export const AuditReadySummaryReportContent = forwardRef(({section}: Props, ref) => {
    const {formatMessage} = useIntl();
    const {job} = useJobContext();
    const {
        reportContent,
        refetchReport,
        isReportContentFetching,
        isReportContentLoading
    } = useGetTabReports(job.id, section.reference || '');

    const [reports, setReports] = useState<IReportInfo>();

    useImperativeHandle(ref, () => ({
        refresh() {
            refetchReport();
        }
    }));

    useEffect(() => {
        if (reportContent.data)
            setReports(reportContent.data);
    }, [reportContent.data])

    if (isReportContentLoading || isReportContentFetching) return <Loading/>;

    return (
        <>
            {reports?.reports.map((reportContent, index) => {
                const ReportTemplate = getReportTemplate(job.softwareType, reportContent.reportType);
                const reportProps: ReportTemplateTypeProps = {
                    items: reportContent.report || [],
                    isFetching: isReportContentFetching,
                    allowEmptyGroups: true
                };
                if (ReportTemplate && reportProps.items.length)
                    return (<Stack tokens={{childrenGap: 16}}>
                        <Text
                            variant={"mediumPlus"}>{formatMessage({id: `tableType_${TableType[reportContent.reportType]}`})}</Text>
                        <ReportTemplate key={`report-template-${index}`} {...reportProps}/>
                    </Stack>)

                return null;
            })}</>
    )
});