import {FunctionComponent} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {PrimaryButton, Stack} from '@fluentui/react';
import {Card, ControlledTextField, Modal, ModalFooter} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useCreateNewClientContact} from "../../hooks";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    clientId: number;
    title?: string;
}

interface ICreateClientContactCommand {
    firstName: string;
    lastName: string;
    jobTitle: string;
}

export const ContactsAddItemModal: FunctionComponent<Props> = observer(({
                                                                            onDismiss,
                                                                            isOpen,
                                                                            title,
                                                                            clientId
                                                                        }: Props) => {
    const {addNewContactForClient, isAddingNewContactForClient} = useCreateNewClientContact(onDismiss);
    const {control, handleSubmit, formState} = useForm<ICreateClientContactCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            firstName: '',
            lastName: '',
            jobTitle: '',
        },
    });
    const {formatMessage} = useIntl();
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};

    const onSubmit = (data: ICreateClientContactCommand) => {
        addNewContactForClient({
            clientId,
            firstName: data.firstName || '',
            jobTitle: data.jobTitle || '',
            lastName: data.lastName || '',
        });
    };
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={title}>
            <Stack tokens={{childrenGap: 16}}>
                <Card styles={{root: {padding: '16px 16px 20px 16px'}}}>
                    <Stack horizontal tokens={{childrenGap: 16}} wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='firstName'
                                required
                                label={formatMessage({id: 'firstName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={stackItemStyles}>
                            <ControlledTextField
                                name='lastName'
                                required
                                label={formatMessage({id: 'lastName'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item styles={{root: {width: '100%'}}}>
                            <ControlledTextField
                                required
                                name='jobTitle'
                                label={formatMessage({id: 'jobTitle'})}
                                control={control}
                                rules={{
                                    required: formatMessage({id: 'requiredField'}),
                                    minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Card>
                <ModalFooter horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty || isAddingNewContactForClient}
                        onClick={handleSubmit(onSubmit)}
                        text={formatMessage({id: 'save'})}
                    />
                </ModalFooter>
            </Stack>
        </Modal>
    );
});
