import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

type GeneralSettingsRes = {
    passwordRecoveryLinkValidHours: number;
    infoTrackUsername: string;
    infotrackPassword: string;
    engagementLetterName: string;
    representationLetterName: string;
    apiAccess: number | null;
};

/**Query for get general settings data in Portal Settings*/

function useGetGeneralSettings() {
    const context = React.useContext(Context);

    const { data: generalSettings, isLoading } = useQuery(
        ['getGeneralSettings'],
        () => axios.get<GeneralSettingsRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/general`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { generalSettings, isLoading };
}

export { useGetGeneralSettings };
