import {useProcedureContext} from "../../../ProceduresContent";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useSectionContext} from "../../../Section";
import {useIntl} from "react-intl";
import {
    IconButton,
    IContextualMenuItem,
    mergeStyles,
    MessageBarType,
    NeutralColors,
    Stack,
    Text,
    useTheme
} from "@fluentui/react";
import {useIsDarkTheme} from "../../../../../../hooks";
import {useTenantInfo} from "../../../../../../providers";
import {ITableColumn, SanitizedText, useContextMenu} from "../../../../../../components";
import {NotificationAction, useNotifications} from "../../../../../../components/notifications";
import {useCreateReviewItem} from "../../../../hooks";
import {ReviewType} from "../../../../enums";
import {logger} from "../../../../../../services";
import React, {useEffect, useState} from "react";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {CalculatorTemplate, getTypeByLink} from "../../../Calculators";
import {ProceduresDataTable} from "../../../ProceduresDataTable";
import {useJobRedirects} from "../../shared";

export const AuditReadySummaryProcedureContent = () => {
    const {items, isLoading} = useProcedureContext();
    const {jobId} = useJobContext();
    const {section} = useSectionContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const isDark = useIsDarkTheme();
    const {isWorkpapers} = useTenantInfo();

    const componentStyles = mergeStyles({
        '.contribution-table td, .contribution-table th': {
            borderColor: isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30,
        },
        '.contribution-table th': {
            background: theme.palette.themePrimary
        },
        '.date-of-birth-table td, .date-of-birth-table th': {
            borderColor: isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30,
        },
        '.date-of-birth-table th': {
            background: theme.palette.themePrimary
        }
    });
    const {setContextMenuItems, closeMenu} = useContextMenu();
    const {showNotification} = useNotifications();
    const {create: sendToReview, isCreating: isSending} = useCreateReviewItem();
    const {navigateToTableRow} = useJobRedirects()

    const sendItem = (item: any, reviewType: ReviewType) => {
        logger.debug(`Sending item to review:`, item);
        sendToReview({
            itemId: item.id,
            jobId: jobId,
            sectionId: section.id,
            reviewType: reviewType
        }, {
            onSuccess: (res: any) => {
                navigateToTableRow({ tabId: 9, sectionId: ReviewType.Manager ? 64 : 65, itemId: res.data.id })
                showNotification({
                    type: MessageBarType.success,
                    description: formatMessage({id: 'itemAddedToReviews'}),
                    name: ''
                })
            }
        });
        closeMenu();

    };

    const [contextMenuItems] = useState<IContextualMenuItem[]>([
        {
            key: 'sendToManager',
            text: formatMessage({id: 'sendToManager'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Manager)
        },
        {
            key: 'sendToPartner',
            text: formatMessage({id: 'sendToPartner'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Partner)
        }
    ]);


    const columns: ITableColumn[] = [
        {
            key: 'actions',
            name: formatMessage({id: 'actions'}),
            fieldName: 'actions',
            onRender: (items) => (
                <Stack horizontal>
                    {<IconButton iconProps={{iconName: 'RedEye'}}/>}

                    {items?.showAttachment && <Attachment itemId={items?.id} hasAttachments={items?.hasAttachments}/>}
                    {items?.showComment && <JobComment itemId={items?.id} hasComments={items?.hasComments}/>}
                    {items?.isIconCalc && <CalculatorTemplate type={getTypeByLink(items.link)}/>}
                </Stack>

            ),
            minWidth: 70,
            maxWidth: 90,
        },
        {
            key: 'auditProcedureNumber',
            name: '',
            fieldName: 'number',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text>{item.number}</Text>
                </Stack>
            )
        },
        {
            key: 'auditProcedure',
            name: formatMessage({id: isWorkpapers ? 'accountingProcedure' : 'auditProcedure'}),
            fieldName: 'auditProcedure',
            flexGrow: 1,
            onRender: (items) => (
                <Stack horizontal tokens={{childrenGap: 16}}
                       styles={{root: {maxWidth: '100%', paddingLeft: (items.level || 0) * 16}}}>
                    <SanitizedText data={items?.title} styles={{
                        root: {
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellypsis'
                        }
                    }}/>
                </Stack>
            ),
            minWidth: 250,
        },
        {
            key: 'assertion',
            name: formatMessage({id: 'assertion'}),
            fieldName: 'assertion',
            skip: isWorkpapers,
            onRender: (items) => items?.showAssertion ? (
                <Stack>
                    <Text>
                        <span style={{color: theme.palette.themePrimary}}>Financial: </span>
                        <SanitizedText data={items?.assertionFinancial || ''}/>
                    </Text>
                    <Text>
                        <span style={{color: theme.palette.themePrimary}}>Compliance: </span>
                        <SanitizedText data={items?.assertionCompilance || ''}/>
                    </Text>
                </Stack>
            ) : null,
            minWidth: 250,
            maxWidth: 250,
        },
        {
            key: 'auditingSTD',
            name: formatMessage({id: 'auditingSTD'}),
            fieldName: 'auditingSTD',
            skip: isWorkpapers,
            onRender: (items) => <SanitizedText data={items?.auditingStnd}/>,
            minWidth: 150,
            maxWidth: 150,
        }
    ];

    useEffect(() => {
        setContextMenuItems(contextMenuItems);
    }, [setContextMenuItems]);

    if (items?.length)
        return <ProceduresDataTable
            items={items}
            isLoading={isLoading}
            className={componentStyles}
            columns={columns}/>;

    return <></>;
};