import {Card, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {IChangeFundInfoCommand} from '..';

type AccountTabType = {
    control: Control<IChangeFundInfoCommand>;
};

export const AuditParticularsTab = ({control}: AccountTabType) => {
    const {formatMessage} = useIntl();

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={{root: {width: '100%'}}}>
                    <ControlledTextField
                        name='accountingSoftware'
                        label={formatMessage({id: 'accountingSoftware'})}
                        control={control}
                        disabled
                        readonly
                    />
                </Stack.Item>

                <Stack.Item styles={{root: {width: '100%'}}}>
                    <ControlledTextField
                        name='auditingCode'
                        label={formatMessage({id: 'accSoftwareCode1'})}
                        control={control}
                        rules={{
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
};
