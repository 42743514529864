import {IExportOptionInfo} from "../interfaces";
import {useContext} from "react";
import {Context, queryClient} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    exportId: number;
    options: IExportOptionInfo;
}

function useUpdateExportOptions() {
    const context = useContext(Context);

    const {
        mutate: update,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.exportUrl}/api/1.0/jobs/${data.jobId}/export/scheduled/${data.exportId}/options`, data), {
        onSuccess: (data, variables, context) => queryClient.invalidateQueries(['getJobSchedules', variables.jobId])
    });

    return {update, isUpdating};
}

export {useUpdateExportOptions};