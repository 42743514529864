import {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {AxiosError} from 'axios';
import {IconButton, MessageBarType, useTheme} from '@fluentui/react';
import {IContextualMenuProps} from '@fluentui/react/lib/ContextualMenu';
import {useBoolean} from '@fluentui/react-hooks';
import {takeUntil} from 'rxjs';
import {IRuleInfo} from 'interfaces';
import {RouterPaths} from 'navigation';
import {ActiveIconColorStyles} from 'constants/tableStylesPeset';
import {useIsDestroy} from 'utils';
import {useRulesetStore} from '../../RulesetLayoutPage';
import {RulesetRuleDeleteDialog} from './RulesetRuleDeleteDialog';
import {RulesetRuleRestoreDialog} from './RulesetRuleRestoreDialog';
import {RulesetRuleForceRestoreDialog} from './RulesetRuleForceRestoreDialog';
import {useNotifications} from "../../../../../components/notifications";

interface Props {
    rule: IRuleInfo;
}

export const RulesetRulesDataTableActions: FunctionComponent<Props> = observer(({rule}: Props) => {
    const {
        deleteRule,
        restoreRule,
        ruleset,
        rulesetRuleDeleting,
        rulesetRuleRestoring,
        loadRuleset
    } = useRulesetStore();
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const navigate = useNavigate();
    const theme = useTheme();
    const [hiddenDeleteDialog, {toggle: toggleHiddenDeleteDialog}] = useBoolean(true);
    const [hiddenRestoreDialog, {toggle: toggleHiddenRestoreDialog}] = useBoolean(true);
    const [hiddenForceRestoreDialog, {toggle: toggleHiddenForceRestoreDialog}] = useBoolean(true);
    const isDestroy = useIsDestroy();

    const onDelete = () => {
        deleteRule(rule.id)
            .pipe(takeUntil(isDestroy))
            .subscribe(() => {
                showNotification({
                    name: formatMessage({id: 'success'}),
                    type: MessageBarType.success,
                    description: formatMessage({id: 'successRuleDeleteMessage'}),
                });
                toggleHiddenDeleteDialog();
                loadRuleset();
            });
    };

    const onRestore = () => {
        restoreRule(rule.id)
            .pipe(takeUntil(isDestroy))
            .subscribe({
                next: () => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successRuleRestoreMessage'}),
                    });
                    toggleHiddenRestoreDialog();
                    loadRuleset();
                },
                error: (error: AxiosError) => {
                    toggleHiddenRestoreDialog();

                    if (error.response?.data?.code === 125060) {
                        toggleHiddenForceRestoreDialog();
                    }
                },
            });
    };

    const onForceRestore = () => {
        restoreRule(rule.id, true)
            .pipe(takeUntil(isDestroy))
            .subscribe({
                next: () => {
                    showNotification({
                        name: formatMessage({id: 'success'}),
                        type: MessageBarType.success,
                        description: formatMessage({id: 'successRuleRestoreMessage'}),
                    });
                    toggleHiddenRestoreDialog();
                    loadRuleset();
                },
                error: () => {
                    toggleHiddenRestoreDialog();
                },
            });
    };

    const menuProps: IContextualMenuProps = {
        alignTargetEdge: true,
        items: [
            {
                key: 'edit',
                text: formatMessage({id: 'edit'}),
                iconProps: {iconName: 'Edit'},
                disabled: ruleset?.isDeleted || rule?.isDeleted,
                onClick: () => navigate(RouterPaths.admin.rulesets.ruleset.rules.rule(ruleset?.id, rule.id)),
            },
            {
                key: 'delete',
                text: formatMessage({id: 'delete'}),
                iconProps: {iconName: 'Delete', styles: ActiveIconColorStyles(theme.palette.red)},
                disabled: ruleset?.isDeleted || rule?.isDeleted,
                onClick: toggleHiddenDeleteDialog,
            },
            {
                key: 'restore',
                text: formatMessage({id: 'restore'}),
                iconProps: {iconName: 'Redo'},
                disabled: ruleset?.isDeleted || !rule?.isDeleted,
                onClick: toggleHiddenRestoreDialog,
            },
        ],
    };

    return (
        <>
            <IconButton onRenderMenuIcon={() => <div/>} menuProps={menuProps} iconProps={{iconName: 'More'}}/>
            <RulesetRuleDeleteDialog
                hidden={hiddenDeleteDialog}
                toggleHidden={toggleHiddenDeleteDialog}
                onAgree={onDelete}
                yesDisabled={rulesetRuleDeleting}
            />
            <RulesetRuleRestoreDialog
                hidden={hiddenRestoreDialog}
                toggleHidden={toggleHiddenRestoreDialog}
                onAgree={onRestore}
                yesDisabled={rulesetRuleRestoring}
            />
            <RulesetRuleForceRestoreDialog
                hidden={hiddenForceRestoreDialog}
                toggleHidden={toggleHiddenForceRestoreDialog}
                onAgree={onForceRestore}
                yesDisabled={rulesetRuleRestoring}
            />
        </>
    );
});
