import React, {forwardRef, FunctionComponent, useEffect, useImperativeHandle} from "react";
import {
    ControlledDatePicker,
    ControlledDropdown,
    ControlledTextField,
    ModalWithForm
} from "../../../../../../components";
import {useIntl} from "react-intl";
import {IJobTimesheetItem} from "./interfaces";
import {IDropdownOption, Label, Link, Stack} from "@fluentui/react";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {IJobPortalContext, useJobContext} from "../../../../JobPortalLayoutPage";
import {useAuthContext} from "../../../../../../providers";
import {useGetEmployeeById} from "./hooks";
import {IEventSummaryItem} from "../EventsSummary/interfaces/IEventSummaryItem";
import {jobCodes} from "./index";

type CreateJobTimesheetProps = {
    jobGuid: string;
    timesheet?: IJobTimesheetItem;
    onSubmit: (item: SubmitJobTimesheet) => void;
}

interface IEditJobTimesheet {
    timesheetDate: Date;
    employee: string;
    duration: number;
    job: string;
    client: string;
    fund: string;
    rate: number;
    jobCode: number;
}

export type SubmitJobTimesheet = {
    clientId: number;
    fundId: number;
    jobId: number;
    employeeLocalId?: number;
    timesheetDate: Date;
    durationMin: number;
    rate: number;
    jobCode?: number;
    jobGuid?: string;
}

export const JobTimesheetForm = forwardRef(({
                                                                                 jobGuid,
                                                                                 timesheet,
                                                                                 onSubmit,
                                                                             }: CreateJobTimesheetProps, ref) => {
    const jobContext = useJobContext();
    const userContext = useAuthContext();
    const {employee, isEmployeeLoading} = useGetEmployeeById({id: userContext.user?.employeeId});

    const {formatMessage} = useIntl();
    const {control, setValue, handleSubmit} = useForm<IEditJobTimesheet>({
        ...DefaultFormSettings,
        defaultValues: ((t?: IJobTimesheetItem, ctx?: IJobPortalContext) => {
            if (!t) {
                return {
                    timesheetDate: new Date(Date.now()).toString() as any,
                    employee: userContext.user?.displayName ?? '',
                    duration: undefined,
                    job: ctx?.job?.year?.toString() || '',
                    fund: ctx?.job?.fund?.name,
                    rate: (employee == null || employee == undefined) ? userContext.user?.rate! : employee!.rate
                }
            } else {
                return {
                    timesheetDate: timesheet?.timesheetDate,
                    employee: timesheet?.employee?.fullName,
                    duration: timesheet?.durationMin,
                    job: ctx?.job?.year?.toString() || '',
                    fund: ctx?.job?.fund?.name,
                    rate: timesheet?.rate,
                    jobCode: timesheet?.jobCode,
                }
            }

        })(timesheet, jobContext)
    });
    
    const jobCodeOptions: IDropdownOption[] = Object.keys(jobCodes).reduce((acc: any[], codeKey) => {
        acc.push({ key: +codeKey, text: jobCodes[+codeKey] });
        return acc;
    }, []);

    // useEffect(() => {
    //     if (!timesheet) {
    //         setValue('employee', employee?.firstName + ' ' + employee?.lastName)
    //         setValue('rate', employee?.rate ?? 0)
    //     }
    // }, [employee])

    const handleSubmitForm = () => {
        handleSubmit((i) => {
            onSubmit({
                clientId: jobContext.job.client.id,
                fundId: jobContext.fundId,
                jobId: jobContext.jobId,
                employeeLocalId: (employee == null || employee == undefined) ? undefined : employee!.id,
                timesheetDate: control._formValues['timesheetDate'] ?? new Date(Date.now()),
                durationMin: control._formValues['duration'],
                rate: control._formValues['rate'],
                jobCode: control._formValues['jobCode'],
                jobGuid: jobContext.jobGuid
            });
        })();
    }

    useImperativeHandle(ref, () => ({
        submit() {
            handleSubmitForm();
        }
    }));

    return (
        <Stack tokens={{childrenGap: 16}} wrap>
            <Stack.Item>
                <ControlledDatePicker
                    name='timesheetDate'
                    required
                    label={formatMessage({id: 'date'})}
                    control={control}
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='employee'
                    label={formatMessage({id: 'employee'})}
                    control={control}
                    readonly
                    disabled
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='job'
                    label={formatMessage({id: 'job'})}
                    control={control}
                    readonly
                    disabled
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='fund'
                    label={formatMessage({id: 'fund'})}
                    control={control}
                    readonly
                    disabled
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledDropdown name={'jobCode'}
                                    control={control}
                                    label={formatMessage({id: 'jobCode'})}
                                    placeholder={formatMessage({id: 'placeholder_jobCode'})}
                                    required
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                    }}
                                    options={jobCodeOptions}/>
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='duration'
                    required
                    label={formatMessage({id: 'durationMin'})}
                    placeholder={formatMessage({id: 'placeholder_durationMin'})}
                    control={control}
                    rules={{
                        required: formatMessage({id: 'requiredField'}),
                    }}
                />
            </Stack.Item>
            <Stack.Item>
                <ControlledTextField
                    name='rate'
                    readonly
                    disabled
                    label={formatMessage({id: 'rateWithCurrency'}, {currency: '$'})}
                    control={control}
                />
            </Stack.Item>
        </Stack>

    );
});