import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import { useInvoicePortalNotifications } from "../../../hooks";

function useImportPayments() {
    const context = useContext(Context);
    const { showApiOnErrorNotification, showApiOnSuccessNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: importPayments,
        isLoading: isImporting
    } = useMutation(() => axios.post(`${context?.config?.billingUrl}/api/1.0/Payments/Import`,
        commonHeaders()
    ), {
        onSuccess: () => { 
            queryClient.invalidateQueries('getInvoices')
            showApiOnSuccessNotification({ id: 'importCompleted', values: {name: ''}})
        },
        onError: (error: AxiosError) => {
            showApiOnErrorNotification({ values: { ...error?.response?.data ?? {} }})
        }
    })

    return {importPayments, isImporting};
}

export {useImportPayments};