import {IColumn, Stack} from '@fluentui/react';
import {IReport} from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo, useState } from "react";
import {useIntl} from 'react-intl';
import {getDepthPaddingStyles, ReportTable} from '../Table/ReportTable';
import {SanitizedText} from 'components';
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {ReportValueType} from "../../../../../../enums";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import { _ExtendedHeaderRowHeight, InvestmentIncomeCrsAnswer, ReportValidationCell } from "../shared";
import {ColumnInfo} from "../../../../enums";
import {useReportValidationsContext} from "../../../ReportContent";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import { isBankRow } from "../ClassSuper";
import { isAgreedAnswer, isAutoAnswer } from "../../answers";


export const BGL360InvestmentSummary = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;
        
        reportItems.forEach((i: any) => {
            const isTotal = i.valueType === 'Total';
            if ((i.statementType !== 'CashBankAccounts' && (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText))) ||
                (i.statementType === 'CashBankAccounts' && (isAgreedAnswer(i.answerText2) || isAutoAnswer(i.answerText2)))
            ) {
                if (!isTotal && i.marketGainLoss) {
                    verifiedCoverage += i.marketGainLoss;
                }
            }
            
            if (isTotal && i.marketGainLoss) {
                incomeTotal += i.marketGainLoss;
            }
        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const rebuildReports = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.format = i.valueType === ReportValueType.Total || i.isBold ? 'Bold' : 'Normal'
            i.showAttachmentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            i.showCommentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
                i.children = rebuildReports(i.children);
            }

            acc.push(i);
            return acc;
        }, []);
    }

    const [tableItems, setTableItems] = useState<IReport[]>(rebuildReports(items));

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(rebuildReports(tableItems));
        }
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'investment',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 300,
                fieldName: 'investment',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'asx',
                name: formatMessage({ id: 'asx' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'asx',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== 'Total' && item?.valueType !== 'Heading' && item?.statementType !== 'CashBankAccounts' &&
                            <GeneratedAnswer itemId={item?.id} 
                                             tableType={item?.reportType} 
                                             value={item?.answerText}
                                             onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                             answerControlType={item?.answerControlType}
                            />}
                    </Stack>
                )
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} textAlign='right' format={item?.format} />,
            },
            {
                key: 'aveCost',
                name: formatMessage({ id: 'aveCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'aveCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed1',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== 'Total' && item?.valueType !== 'Heading' && item?.statementType !== 'CashBankAccounts' && <InvestmentIncomeCrsAnswer itemId={item?.id} tableType={item.reportType} value={item?.answerText1}
                            auto={item?.isAuto1} disabled={item?.isAuto1} crsInfo={item.crsInfo} columnInfo={ColumnInfo.AnswerText1}/>}
                    </Stack>
                )
            },
            {
                key: 'mktPrice',
                name: formatMessage({ id: 'mktPrice' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'mktPrice',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'cost',
                name: formatMessage({ id: 'cost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'cost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'reviewed2',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText2',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType !== 'Total' && item?.valueType !== 'Heading' &&
                            <GeneratedAnswer itemId={item?.id} 
                                             tableType={item?.reportType} 
                                             value={item?.answerText2} 
                                             auto={item?.isAuto2} 
                                             disabled={item?.isAuto2} 
                                             answerControlType={item?.answerControlType}
                                             onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                             columnInfo={ColumnInfo.AnswerText2} />}
                    </Stack>
                )
            },

            {
                key: 'marketGainLoss',
                name: formatMessage({ id: 'market' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketGainLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'unrealisedGainLoss',
                name: formatMessage({ id: 'unrealisedGainLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'unrealisedGainLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'percent',
                name: formatMessage({ id: 'percentGainLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percent',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='percentage' />,
            },
            {
                key: 'percentTotal',
                name: formatMessage({ id: 'portfolioWeight' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'percentTotal',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='percentage' />,
            },
            {
                key: 'agreedToInvestmentStrategy',
                name: formatMessage({ id: 'agreedToInvestmentStrategy' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'agreedToInvestmentStrategy',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === 'Total' && item?.valueType !== 'Heading'
                            && <GeneratedAnswer itemId={item?.id} tableType={item?.reportType} columnInfo={ColumnInfo.AnswerText3} value={item?.answerText3} answerControlType={item?.answerControlType} auto={item?.isAuto3} disabled={item?.isAuto3}/>}
                    </Stack>
                )
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} 
                        items={tableItems}
                        header={{ rowHeight: _ExtendedHeaderRowHeight }}
                        groupProps={{
                            mergedGroupHeader: {propertyKey: 'investment'},
                            summary: {
                                show: true,
                                style: { paddingLeft: 0 },
                                childRenderer(group: IGroupWithSummary): React.ReactNode {
                                    const summary = group.summary;
                                    return <>
                                        {
                                            <Stack horizontal>
                                                <Stack.Item>
                                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    </>
                                }
                            }
                        }}
                        openAll={true} 
                        isFetching={isFetching} 
                        expandProps={{ showExpander: false }} 
                        containerHeight='100%' />
};
