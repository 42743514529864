import {CommandBarButton, FontSizes, Icon, mergeStyles, useTheme} from "@fluentui/react";
import React, {FunctionComponent, useMemo} from "react";
import {useNotifications} from "./NotificationProvider";

type NotificationButtonProps = {
    onShow: () => void;
}

export const NotificationPanelButton: FunctionComponent<NotificationButtonProps> = ({
                                                                                   onShow
                                                                               }: NotificationButtonProps) => {
    const {notifications} = useNotifications();
    const theme = useTheme();
    const badgeStyles = mergeStyles({
        position: 'absolute',
        background: theme.palette.red,
        color: 'white',
        borderRadius: 10,
        minWidth: 14,
        height: 14,
        fontSize: FontSizes.xSmall,
        top: 8,
        right: 5
    });

    const notificationCount = useMemo(() => {
        return notifications.length;
    }, [notifications.length]);

    const renderIcon = () => {
        return (
            <>
                {notificationCount > 0 && (<div className={badgeStyles}> {notificationCount} </div>)}
                <Icon iconName="Ringer"
                      styles={{root: {fontSize: 16, color: theme.palette.themePrimary}}}/>
            </>
        );
    }

    return (<CommandBarButton onClick={onShow}
                              styles={{root: {height: 48}}}
                              onRenderIcon={renderIcon}/>);
}