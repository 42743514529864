import {CustomProcedureRowRender} from "../../CustomProcedureTemplates";
import {IDetailsRowProps} from "@fluentui/react";
import {ReactElement} from "react";
import {getFlatProcedures} from "../../../../../../../utils";

export const trusteeCorporateConfirmationProcedureRowRender: CustomProcedureRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const {isCorporateTrusteeType} = ((items?: any): { isCorporateTrusteeType: boolean } => {
        const flatted = getFlatProcedures(items ?? [], []);

        const isCorporateTrusteeType = flatted.find(x => x.reference === 'Trustee Type')?.answerText == 'Corporate';
        return {isCorporateTrusteeType}
    })(rows)
    
    props.item = {
        ...props.item,
        isAnswerDisabled: !isCorporateTrusteeType,
        isPermanentDisabled: !isCorporateTrusteeType
    }

    return defaultRender(props);
}