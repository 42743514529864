import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

function useRemoveWrapAccount() {
    const context = React.useContext(Context);

    const { mutate: deleteAttachment, isLoading, isSuccess } = useMutation((id: number) =>
        axios.delete(`${context?.config?.annotationsUrl}/api/1.0/wrapAccount/${id}`, commonHeaders()),
        {
            onSuccess: () => { queryClient.invalidateQueries('getWrapAccountsByYear'); },
        }
    );

    return { deleteAttachment, isLoading, isSuccess };
}

export { useRemoveWrapAccount };