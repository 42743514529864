import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateUpdateDataType = {
    timesheetGuid: string;
    timesheetDate: Date;
    durationMin: number;
    jobCode?: number;
}

function useUpdateTimesheet() {
    const context = useContext(Context);
    const {
        mutate: updateTimesheet,
        mutateAsync: updateTimesheetAsync,
        isLoading: isTimesheetUpdating
    } = useMutation((data: MutateUpdateDataType) => axios.put(`${context?.config?.timesheetUrl}/api/1.0/job/timesheets/${data.timesheetGuid}`, data))

    return {updateTimesheet, updateTimesheetAsync, isTimesheetUpdating};
}

export {useUpdateTimesheet};