import {IPivotItemProps, Pivot, PivotItem, useTheme} from '@fluentui/react';
import {DashboardTabsEnum} from '../enums';
import {FunctionComponent, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useTenantInfo} from "../../../../providers";
import {DashboardCodes} from "../../../WorkflowPortal/shared";

interface Props {
    dashboards: DashboardCodes[];
    selectedKey?: string;
    setSelectedKey: Function;
}

export interface IDashboardPivotItemProps extends IPivotItemProps {
    itemKey: DashboardTabsEnum
}

export const DashboardTabsMenu: FunctionComponent<Props> = ({selectedKey, setSelectedKey, dashboards}) => {
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setSelectedKey(item.props.itemKey!);
        }
    };

    const theme = useTheme();

    const tabs = useMemo<IDashboardPivotItemProps[]>(() => [
        {
            headerText: formatMessage({id: 'administrator'}),
            itemKey: isWorkpapers ? DashboardTabsEnum.WP_Administrator : DashboardTabsEnum.AP_Administrator,
            hidden: dashboards.filter(x => x === DashboardCodes.Administrator).length == 0
        },
        {
            headerText: formatMessage({id: isWorkpapers ? 'accountant' : 'auditor'}),
            itemKey: isWorkpapers ? DashboardTabsEnum.WP_Accountant : DashboardTabsEnum.AP_Auditor,
            hidden: dashboards.filter(x => x == DashboardCodes.Auditor).length == 0
        },
        {
            hidden: isWorkpapers || dashboards.filter(x => x == DashboardCodes.Contravention).length == 0,
            headerText: formatMessage({id: 'contravention'}),
            itemKey: DashboardTabsEnum.AP_Contravention,
        },
        {
            headerText: formatMessage({id: 'manager'}),
            itemKey: isWorkpapers ? DashboardTabsEnum.WP_Manager : DashboardTabsEnum.AP_Manager,
            hidden: dashboards.filter(x => x == DashboardCodes.Manager).length == 0
        },
        {
            headerText: formatMessage({id: 'partner'}),
            itemKey: isWorkpapers ? DashboardTabsEnum.WP_Partner : DashboardTabsEnum.AP_Partner,
            hidden: dashboards.filter(x => x == DashboardCodes.Partner).length == 0
        },
    ], [dashboards, isWorkpapers]);

    if (tabs.filter(x => !x.hidden).length == 0) {
        return <></>
    }

    return (
        <Pivot
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
            headersOnly={true}
            styles={{
                root: {
                    height: 48,
                    width: '100%',
                }, link: {
                    height: 48, minWidth: 150, color: theme.schemes?.default?.semanticColors.bodyText, selectors: {
                        '&:hover': { color: theme.schemes?.default?.semanticColors.bodyText },
                        '&:active': { color: theme.schemes?.default?.semanticColors.bodyText }
                    }}
            }}>
            {tabs.filter(x => !x.hidden).map((tab, idx) => (
                <PivotItem key={`tab-${tab.itemKey}-${idx}`} {...tab} />
            ))}
        </Pivot>
    );
};