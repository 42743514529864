import axios from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

export type DataUpdateMaterialityCalculator = {};

export const useUpdateMaterialityCalculator = () => {
    const context = React.useContext(Context);

    const { mutate: updateMaterialityCalculator, isLoading: updateMaterialityCalculatorLoading } = useMutation(
        (data: DataUpdateMaterialityCalculator) =>
            axios.put(`${context?.config?.settingsUrl}/api/1.0/Settings/materialityCalcSettings`, data, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getMaterialityCalculator'),
        }
    );

    return { updateMaterialityCalculator, updateMaterialityCalculatorLoading };
};
