import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CalculatorTemplateProps } from '../../types';
import { useIntl } from 'react-intl';
import { Stack, Text, TextField, useTheme } from '@fluentui/react';
import { useUpdateCalculatorData } from '../../hooks';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { CalculatorType } from '../../CalculatorType';
import { ControlledNumberField, ControlledTextField, DataTable, ITableColumn, SanitizedText } from '../../../../../../components';
import { IOverallMaterialityModel } from './IOverallMaterialityModel';
import { CalculatorDataTable } from '../shared';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useTabContext } from 'pages/JobPortal';
import { logger } from 'services';

type OverallMaterialityCalculatorProps = CalculatorTemplateProps;

export const OverallMaterialityCalculator = forwardRef(({ data }: OverallMaterialityCalculatorProps, ref) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const { update, isUpdating } = useUpdateCalculatorData();
    const [materialityData, setMaterialityData] = useState<IOverallMaterialityModel>();
    const { handleSubmit, watch, control, setValue } = useForm<IOverallMaterialityModel>({
        ...DefaultFormSettings,
        defaultValues: materialityData,
    });
    const watchFields = watch();

    const changedTextStyle = { color: theme.schemes?.default?.palette.blue };
    const defaultTextStyle = { color: theme.schemes?.default?.semanticColors.bodyText };

    const updateCalcData = (calculatorData: any) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.OverallMateriality,
            isCustom: false,
            answer: calculatorData,
            answers: calculatorData.items,
            jobId: data?.jobId || 0,
        });
    };

    const totalAssetsModified = useMemo(() => {
        return materialityData?.totalAssetsChanged || watchFields.totalAssets != materialityData?.totalAssets;
    }, [watchFields]);

    const totalNetAssetsModified = useMemo(() => {
        return materialityData?.totalNetAssetsChanged || watchFields.totalNetAssets != materialityData?.totalNetAssets;
    }, [watchFields]);

    useEffect(() => {
        setValue('totalAssetsChanged', totalAssetsModified);
    }, [totalAssetsModified]);

    useEffect(() => {
        setValue('totalNetAssetsChanged', totalNetAssetsModified);
    }, [totalNetAssetsModified]);

    const financialMisstatement = useMemo(() => {
        return (watchFields.totalNetAssets || 0) * (Number.parseFloat(materialityData?.materialityLevel || '0.00') / 100);
    }, [watchFields]);

    const clearlyTrivial = useMemo(() => {
        return (watchFields.totalNetAssets || 0) * (Number.parseFloat(materialityData?.clearlyTrivialValue || '0.00') / 100);
    }, [watchFields]);

    const oldFundMaterialityLevel = useMemo(() => {
        if ((watchFields.totalAssets || 0) * 0.05 < 30000) {
            return (watchFields.totalAssets || 0) * 0.05;
        } else {
            return 30000;
        }
    }, [watchFields]);

    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: number) => {
            logger.debug('CALC:OVERALL:IS_TAB_ENABLED', isTabEnabled);
            if (!isTabEnabled) return;

            setEditingIndex(index);
            event.stopPropagation();
        },
        [isTabEnabled]
    );

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'name',
                name: '',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Text>
                        {formatMessage({
                            id: index === 0 ? 'calculator_overallMateriality_totalAssets' : 'calculator_overallMateriality_totalNetAssets',
                        })}
                    </Text>
                ),
            },
            {
                key: 'assetsValue',
                name: '',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Stack>
                        {editingIndex === index && (
                            <ControlledNumberField
                                autoFocus
                                styles={
                                    index === 0
                                        ? totalAssetsModified == true
                                            ? { field: changedTextStyle, prefix: changedTextStyle }
                                            : {}
                                        : totalNetAssetsModified == true
                                        ? { field: changedTextStyle, prefix: changedTextStyle }
                                        : {}
                                }
                                prefix={'$'}
                                name={index === 0 ? 'totalAssets' : 'totalNetAssets'}
                                control={control}
                            />
                        )}
                        {editingIndex !== index && (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        zIndex: 100,
                                    }}
                                    onClick={(event) => onEditClick(event, index)}></div>
                                <TextField
                                    readOnly
                                    disabled
                                    prefix={'$'}
                                    styles={
                                        index === 0
                                            ? totalAssetsModified == true
                                                ? { field: changedTextStyle, prefix: changedTextStyle }
                                                : { field: defaultTextStyle }
                                            : totalNetAssetsModified == true
                                            ? { field: changedTextStyle, prefix: changedTextStyle }
                                            : { field: defaultTextStyle }
                                    }
                                    value={
                                        index === 0
                                            ? toCurrencyFormat(watchFields.totalAssets || 0)?.toString()
                                            : toCurrencyFormat(watchFields.totalNetAssets || 0)?.toString()
                                    }
                                />
                            </>
                        )}
                    </Stack>
                ),
            },
            {
                key: 'materialityLevelComplianceNewFund',
                name: formatMessage(
                    { id: 'calculator_overallMateriality_materialityLevelComplianceNewFund' },
                    { jobYear: materialityData?.jobYear }
                ),
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Stack grow>
                        {index === 0 ? (
                            <SanitizedText
                                textAlign={'right'}
                                prefix={'$'}
                                numberFormat={'fractional'}
                                data={(materialityData?.newFundMaterialityLevel || 0)?.toString()}
                            />
                        ) : null}
                    </Stack>
                ),
            },
            {
                key: 'materialityLevelComplianceOldFund',
                name: formatMessage({ id: 'calculator_overallMateriality_materialityLevelComplianceOldFund' }),
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Stack grow>
                        {index === 0 ? (
                            <SanitizedText
                                textAlign={'right'}
                                prefix={'$'}
                                numberFormat={'fractional'}
                                data={oldFundMaterialityLevel?.toString()}
                            />
                        ) : null}
                    </Stack>
                ),
            },
            {
                key: 'materialityLevelFinancial',
                name: formatMessage(
                    { id: 'calculator_overallMateriality_materialityLevelFinancial' },
                    { materialityLevel: materialityData?.materialityLevel }
                ),
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Stack grow>
                        {index === 1 ? (
                            <SanitizedText
                                textAlign={'right'}
                                prefix={'$'}
                                numberFormat={'fractional'}
                                data={financialMisstatement?.toString()}
                            />
                        ) : null}
                    </Stack>
                ),
            },
            {
                key: 'clearlyTrivial',
                name: formatMessage(
                    { id: 'calculator_overallMateriality_clearlyTrivial' },
                    { clearlyTrivial: materialityData?.clearlyTrivialValue }
                ),
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) => (
                    <Stack grow>
                        {index === 1 ? (
                            <SanitizedText textAlign={'right'} prefix={'$'} numberFormat={'fractional'} data={clearlyTrivial?.toString()} />
                        ) : null}
                    </Stack>
                ),
            },
        ];
    }, [materialityData, financialMisstatement, clearlyTrivial, oldFundMaterialityLevel, editingIndex]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        },
    }));

    useEffect(() => {
        const calcData = data?.data as IOverallMaterialityModel;
        setMaterialityData(calcData);
        setValue('totalAssets', calcData?.totalAssets || 0);
        setValue('totalNetAssets', calcData?.totalNetAssets || 0);
        setValue('totalAssetsChanged', calcData?.totalAssetsChanged || null);
        setValue('totalNetAssetsChanged', calcData?.totalNetAssetsChanged || null);
    }, [data?.data]);

    const handleClickOutside = (event: any) => {
        if ((event.srcElement?.id as string)?.includes('TextField')) return;

        setEditingIndex(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Stack onClick={handleClickOutside} tokens={{ childrenGap: 16 }} horizontalAlign={'center'} grow>
            <CalculatorDataTable
                initialColumns={columns}
                columns={columns}
                shimmerLines={2}
                enableShimmer={isUpdating}
                header={{
                    rowHeight: 150,
                    horizontalAlign: 'center',
                }}
                items={[{}, {}]}
            />
        </Stack>
    );
});
