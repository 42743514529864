import {IconButton, Spinner, SpinnerSize, Stack, useTheme} from "@fluentui/react";
import React, {FunctionComponent} from "react";
import {IJobSuggestedTestingItem} from "../../../../hooks";

type SuggestedTestingActionCellProps = {
    item: IJobSuggestedTestingItem;
    onDelete: (item: IJobSuggestedTestingItem) => void;
    isChanging: boolean;
    showDelete?: boolean;
    disabled: boolean
}

export const SuggestedTestingActionCell: FunctionComponent<SuggestedTestingActionCellProps> = ({
                                                                                                   item,
                                                                                                   onDelete,
                                                                                                   isChanging,
                                                                                                   showDelete,
                                                                                                   disabled
                                                                                               }) => {
    const {palette} = useTheme();
    return (
        <Stack grow={1} horizontal verticalAlign={"center"} horizontalAlign={"center"}>
            {isChanging && (<Stack.Item><Spinner size={SpinnerSize.small}/></Stack.Item>)}
            {showDelete && (
                <Stack.Item>
                    <IconButton
                        onClick={() => {
                            onDelete(item);
                        }}
                        disabled={disabled}
                        iconProps={{iconName: 'delete'}}
                        styles={{ icon: { color: palette.red }, iconHovered: { color: palette.redDark } }}
/>
                </Stack.Item>
            )}
        </Stack>
    );
}