import { useIntl } from 'react-intl';
import { Spinner, Stack } from '@fluentui/react';
import { CalculatorRow } from '../shared';
import { IDeferredTaxData } from './IDeferredTaxData';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { CalculatorType } from '../../CalculatorType';
import { useUpdateCalculatorData } from '../../hooks';
import { CalculatorTemplateProps } from '../../types';
import { useJobContext } from 'pages/JobPortal/JobPortalLayoutPage';

type DeferredTaxCalculatorProps = CalculatorTemplateProps;
export const DeferredTaxCalculator = forwardRef(({ data }: DeferredTaxCalculatorProps, ref) => {
    const { formatMessage } = useIntl();
    const { jobId } = useJobContext();
    const { update, isUpdating } = useUpdateCalculatorData();
    const [taxData, setTaxData] = useState<IDeferredTaxData>(data?.data as IDeferredTaxData);
    const { handleSubmit, setValue, watch } = useForm<IDeferredTaxData>({
        ...DefaultFormSettings,
        defaultValues: {
            ...data?.data,
        },
    });
    const watchFields = watch();
    const updateCalcData = (deferredTaxData: IDeferredTaxData) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.DeferredTax,
            isCustom: false,
            answer: deferredTaxData,
            jobId: jobId,
        });
    };

    const subTotal = useMemo(() => {
        return (
            (watchFields.taxableProfitLoss || 0) -
            (watchFields.taxLossesCarriedForward || 0) -
            (watchFields.capitalLossesCarriedForward || 0)
        );
    }, [watchFields]);

    const total = useMemo(() => {
        return subTotal * (1 - (watchFields.actuarialPercentage || 0) / 100.0) * 0.15;
    }, [watchFields]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        },
    }));

    useEffect(() => {
        setTaxData(data?.data as IDeferredTaxData);
    }, [data?.data]);

    const isTaxableProfitLossChanged = useMemo(() => {
        return taxData?.isTaxableProfitLossChanged || watchFields.taxableProfitLoss != taxData?.taxableProfitLoss;
    }, [watchFields]);

    const isTaxLossesCarriedForwardChanged = useMemo(() => {
        return taxData?.isTaxLossesCarriedForwardChanged || watchFields.taxLossesCarriedForward != taxData?.taxLossesCarriedForward;
    }, [watchFields]);

    const isCapitalLossesCarriedForwardChanged = useMemo(() => {
        return (
            taxData?.isCapitalLossesCarriedForwardChanged || watchFields.capitalLossesCarriedForward != taxData?.capitalLossesCarriedForward
        );
    }, [watchFields]);

    const isActuarialPercentageChanged = useMemo(() => {
        return taxData?.isActuarialPercentageChanged || watchFields.actuarialPercentage != taxData?.actuarialPercentage;
    }, [watchFields]);

    const [editingRow, setEditingRow] = useState<string | undefined>(undefined);

    const handleClickOutside = (event: any) => {
        if ((event.srcElement.id as string).includes('TextField')) return;

        setEditingRow(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (isUpdating) return <Spinner />;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <CalculatorRow
                iconType={'plus'}
                label={formatMessage({ id: 'calculator_deferredTax_Unrealised_Gain' })}
                changed={isTaxableProfitLossChanged}
                onChangeValue={(newValue) => {
                    setValue('taxableProfitLoss', newValue);
                    setValue('isTaxableProfitLossChanged', true);
                }}
                value={taxData?.taxableProfitLoss}
                onClick={() => setEditingRow('taxableProfitLoss')}
                editing={editingRow === 'taxableProfitLoss'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                label={formatMessage({ id: 'calculator_deferredTax_Tax_Losses_Carried_Forward' })}
                changed={isTaxLossesCarriedForwardChanged}
                onChangeValue={(newValue) => {
                    setValue('taxLossesCarriedForward', newValue);
                    setValue('isTaxLossesCarriedForwardChanged', true);
                }}
                value={taxData?.taxLossesCarriedForward}
                onClick={() => setEditingRow('taxLossesCarriedForward')}
                editing={editingRow === 'taxLossesCarriedForward'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'minus'}
                label={formatMessage({ id: 'calculator_deferredTax_Capital_Losses_Carried_Forward' })}
                changed={isCapitalLossesCarriedForwardChanged}
                onChangeValue={(newValue) => {
                    setValue('capitalLossesCarriedForward', newValue);
                    setValue('isCapitalLossesCarriedForwardChanged', true);
                }}
                value={taxData?.capitalLossesCarriedForward}
                onClick={() => setEditingRow('capitalLossesCarriedForward')}
                editing={editingRow === 'capitalLossesCarriedForward'}
                applyCurrencyFormat
            />
            <CalculatorRow
                iconType={'equal'}
                editable={false}
                label={formatMessage({ id: 'calculator_deferredTax_Sub_Total' })}
                value={subTotal}
            />
            <CalculatorRow
                iconType={'multiply'}
                suffix={'%'}
                label={formatMessage({ id: 'calculator_deferredTax_Actuarial_Percentage' })}
                changed={isActuarialPercentageChanged}
                onChangeValue={(newValue) => {
                    setValue('actuarialPercentage', newValue);
                    setValue('isActuarialPercentageChanged', true);
                }}
                value={taxData?.actuarialPercentage}
                onClick={() => setEditingRow('actuarialPercentage')}
                editing={editingRow === 'actuarialPercentage'}
            />
            <CalculatorRow
                iconType={'equal'}
                label={formatMessage({ id: 'calculator_deferredTax_Total' })}
                editable={false}
                value={total}
            />
        </Stack>
    );
});
