import React, { FunctionComponent } from 'react';
import { ClientAuditQuestionAnswerValue, IIChecklistItem } from "../../interfaces";
import { ITableColumn } from '../../../../../../../../components';
import { Stack, Text } from '@fluentui/react';
import { Attachment } from '../../../../../Attachment';
import { JobComment } from '../../../../../JobComment';
import { useIntl } from 'react-intl';
import { TableType } from '../../../../../../../../enums';
import { ColumnInfo } from '../../../../../../enums';
import { WorkDoneSwitchAnswer } from '../../../../answers';
import { ProceduresDataTable } from "../../../../../ProceduresDataTable";
import { AuditAcceptanceSection } from "../AuditAcceptanceSection";

type IIChecklistProps = {
    items: IIChecklistItem[];
};

export const IIChecklist: FunctionComponent<IIChecklistProps> = ({ items, ...props }: IIChecklistProps) => {
	const { formatMessage} = useIntl()
	
	const columns: ITableColumn[] = [
		{
			key: 'actions',
			name: formatMessage({id: 'actions'}),
			fieldName: 'actions',
			onRender: (item: IIChecklistItem) => (
				<Stack horizontal>
					<Attachment itemId={item.questionId} tableType={TableType.IIChecklistTable} hasAttachments={item.hasAttachments}/>
					<JobComment itemId={item.questionId} tableType={TableType.IIChecklistTable} hasComments={item.hasComments}/>
				</Stack>

			),
			minWidth: 60,
			maxWidth: 60,
		},
		{
			key: 'auditProcedureNumber',
			name: '',
			fieldName: 'number',
			minWidth: 20,
			maxWidth: 20,
			onRender: (item: IIChecklistItem) => (
				<Stack horizontalAlign={"center"} grow>
					<Text>{item.order}</Text>
				</Stack>
			)
		},
		{
			key: 'question',
			name: formatMessage({id: 'question'}),
			minWidth: 180,
			fieldName: 'question',
			onRender: (item: IIChecklistItem, index, column) => (
				<Stack grow>
					<Text>{item.text}</Text>
				</Stack>
			)
		},
		{
			key: 'result',
			name: formatMessage({id: 'result'}),
			fieldName: 'result',
			onRender: (item: IIChecklistItem) => (
				<Stack verticalAlign='center' grow>
					<WorkDoneSwitchAnswer columnInfo={ColumnInfo.Text}
					                      itemId={item.questionId}
					                      tableType={TableType.IIChecklistTable} 
					                      value={!item.answer ? '' : item.answer === ClientAuditQuestionAnswerValue.Yes ? 'Yes' : 'No'} 
					                      disabled 
					/>
				</Stack>
			),
			minWidth: 120,
			maxWidth: 120,
		},
	]
	
	return (
		<AuditAcceptanceSection name={formatMessage({id: 'initialInformationChecklist'})} bodrered={false}>
			<ProceduresDataTable
				items={items}
				hideIfEmpty={false}
				columns={columns}
			/>
		</AuditAcceptanceSection>
	);
};