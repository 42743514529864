import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import {IAttachment} from "../attachments";

interface IGetJobCommentsParams {
  fundId: number;
  jobId: number;
  itemId: number;
  tableType: number;
  year: number;
}

export interface IJobCommentDocument {
  id?: number;
  commentId?: number;
  isPerm: boolean;
  attachmentId: number;
  attachment?: IAttachment;
}

export interface IJobComment {
  id?: number;
  text?: string;
  htmlText?: string;
  isHidden?: boolean;
  itemId?: number;
  tableType?: number;
  documents?: IJobCommentDocument[],
}

export interface IPermanentJobComment extends IJobComment {
  fundId?: number;
  year?: number;
  isActivated?: boolean;
}

export interface IStandartPoint {
  id: number;
  itemId: number;
  tableType: number;
  order: number;
  text: string;
  isHeader: boolean;
}

export interface IJobCommentResponse {
  itemId: number;
  tableType: number;
  comment: IJobComment | null;
  permanentComment: IPermanentJobComment | null;
  standardPoints: IStandartPoint[] | null;
  externalComment: IJobComment | null;
  externalPermanentComment: IJobComment | null;
}

function useGetJobComments({ fundId, jobId, itemId, tableType, year }: IGetJobCommentsParams) {
  const context = React.useContext(Context);

  const { data: commentsData, isLoading: isCommentsLoading, refetch: refetchComments, isFetching: isCommentsFetching } = useQuery(
    ['getJobComments', fundId, jobId, itemId, tableType, year],
    () => axios.get<IJobCommentResponse>(
      `${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/items/${itemId}/comments?tableType=${tableType}&year=${year}`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
     
      retry: false,
    }
  );

  return { commentsData, isCommentsLoading, refetchComments, isCommentsFetching };
}

export { useGetJobComments };