import axios, {AxiosError} from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders,  queryClient} from 'utils';

function useUploadLogo(url: string) {
    const {mutate: uploadLogo, isLoading: uploadLogoLoading} = useMutation(
        (data: any) => axios.post(`${url}/api/1.0/Customisation/setLogoImage`, data, commonHeaders(true)),
        {
            onSuccess: () => queryClient.invalidateQueries('getCustomizationColors'),
        }
    );

    return {uploadLogo, uploadLogoLoading};
}

export {useUploadLogo};
