import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {QueryDate} from "pages/WorkflowPortal/shared";
import {useCommonErrorNotification, useNotifications} from "../../../../components/notifications";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";

type MutateType = {
    jobGuid: string;
    jobQueriesDispatch?: QueryDate[];
};

function useUpdateJobDispatchDates() {
    const context = React.useContext(Context);

    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {mutate: updateJobDispatchDates, isLoading: isDispatchDatesUpdating} = useMutation(
        (data: MutateType) =>
            axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobGuid}/dispatchDates`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getJobsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Job dispatch info'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateJobDispatchDates, isDispatchDatesUpdating};
}


export {useUpdateJobDispatchDates};