import axios, {AxiosError} from 'axios';
import {useQuery} from 'react-query';
import {useLocation} from 'react-router-dom';
import {commonHeaders} from 'utils';
import * as localStorageService from '../services/localStorageService';
import {REFRESH_TOKEN_KEY} from "../constants/authorization";


type CustomizationResponse = {
    isDefault: boolean;
    logoImageUrl: string;
    themeViewModel: {
        neutralPrimary: string;
        themePrimary: string;
    };
};

function useGetTheme(url: string, isApplied: boolean, tenantId: string) {
    const refreshToken: string = localStorageService.getData(REFRESH_TOKEN_KEY);
    const location = useLocation();

    const {
        data: colors,
        isLoading,
        error
    } = useQuery(
        ['getTheme', isApplied],
        () => axios.get<CustomizationResponse>(`${url}/api/1.0/Customisation?isApplied=${isApplied}`, commonHeaders()),
        {
            enabled: !!url && !!tenantId && !!refreshToken && refreshToken !== 'undefined' && (!location.search.includes('?Code=') || !location.search.includes('&Code=')),
            retry: false,
            onError: (error:AxiosError) => {
            }
        }
    );

    return {colors, isLoading, error};
}

export {useGetTheme};
