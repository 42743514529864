import {FunctionComponent} from "react";
import {Card, Pagination} from "../../../../../components";

interface IInvoicesPaginator {
    pageCount: number;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    onChange: (pageSize: number, page: number) => void;
}

export const InvoicesPaginator: FunctionComponent<IInvoicesPaginator> = (props: IInvoicesPaginator) => {
    
    return (
        <Card>
            <Pagination
                itemsCount={props.pageCount}
                page={props.pageNumber}
                pageSize={props.pageSize}
                total={props.totalCount}
                onChange={props.onChange}
            />
        </Card>
    )
}