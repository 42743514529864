import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';

export const PortalSettingsLayoutPage = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigation = useNavigate();
    console.log('location', location.pathname);
    React.useEffect(() => {
        if (location.pathname === '/portal-settings') {
            navigation(RouterPaths.portalSettings.auditReadyConnections);
        }
    }, [location.pathname, navigation]);
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'portalSettings',
                key: RouterPaths.portalSettings.root,
                text: formatMessage({ id: 'portalSettings' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
