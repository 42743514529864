import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';

export interface IUploadImport {
  data: FormData;
  jobId: number;
}

function useUploadImport() {
    const context = React.useContext(Context);

    const { mutate: uploadImport, isLoading, isSuccess, data } = useMutation(({ data, jobId }: IUploadImport) =>
        axios.post(`${context?.config?.importUrl}/api/1.0/import/upload/${jobId}`, data, commonHeaders()).catch(e => e.response),
        {
            onSuccess: (data) => data?.status < 400 ? queryClient.invalidateQueries('getJobImportData') : undefined,
        }
    );

    return { uploadImport, isLoading, isSuccess, data };
}

export { useUploadImport };