import {
    CommandBarButton, DefaultButton,
    IColumn,
    IconButton,
    IContextualMenuProps,
    IStyle,
    SelectionMode,
    Stack,
    Text,
    TextField,
    useTheme
} from '@fluentui/react';
import {FunctionComponent, useMemo, useState} from 'react';
import { Card, DataTable, SanitizedText } from "components";
import {useIntl} from 'react-intl';
import {IStandartPoint} from 'pages/JobPortal/hooks';
import {useConst} from "@fluentui/react-hooks";

interface IStandardPointSectionProps {
    standardPoints: IStandartPoint[];
    onAddToPermanent: Function;
    onAddToCurrentYear: Function;
    disabledPerm: boolean;
}

export const StandartPointSection: FunctionComponent<IStandardPointSectionProps> = ({
                                                                                        standardPoints,
                                                                                        onAddToPermanent,
                                                                                        onAddToCurrentYear,
                                                                                        disabledPerm
                                                                                    }) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);

    const headerStyles: IStyle = useMemo(() => ({
        fontWeight: 600,
        textTransform: 'uppercase',
        color: theme.palette.themePrimary,
    }), [theme]);

    const columns = useMemo<IColumn[]>(() => [
        {
            key: 'text',
            name: '',
            minWidth: 300,
            fieldName: 'text',
            onRender: (items) => <SanitizedText data={items.text} styles={{root: items?.isHeader ? headerStyles : {}}} />
                
                // <Text styles={{root: items?.isHeader ? headerStyles : {}}}>{items.text}</Text>,
        },
        {
            key: 'add',
            name: '',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'add',
            onRender: (items) => !items?.isHeader && (
                <Stack horizontal horizontalAlign='end' styles={{root: {width: '100%'}}}>
                    <IconButton iconProps={{iconName: 'Add'}} split persistMenu menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                            {
                                key: 'addCurrent',
                                text: 'Add as current year',
                                onClick: () => onAddToCurrentYear(items.text)
                            },
                            {
                                key: 'addPermanent',
                                text: 'Add as permanent',
                                onClick: () => onAddToPermanent(items.text),
                                disabled: disabledPerm
                            }
                        ]
                    }}/>
                </Stack>
            ),
        },
    ], [onAddToCurrentYear, onAddToPermanent, disabledPerm, headerStyles]);

    return (
        <Stack>
            <Card>
                <Stack tokens={{childrenGap: 16}}>
                    <Stack tokens={{childrenGap: 16}} horizontalAlign='space-between' horizontal>
                        <Stack tokens={{childrenGap: 16}} verticalAlign='center' horizontal>
                            <IconButton onClick={() => setOpen((prev) => !prev)}
                                        iconProps={{iconName: open ? 'Remove' : 'Add'}}/>
                            <Stack.Item><Text theme={theme.schemes?.default}>{formatMessage({id: 'standardAnnotations'})}</Text></Stack.Item>
                        </Stack>
                    </Stack>
                    {open && (
                        <DataTable
                            initialColumns={columns}
                            columns={columns}
                            items={standardPoints}
                            selectionMode={SelectionMode.none}
                            containerHeight='100%'
                            isHeaderVisible={false}
                        />
                    )}
                </Stack>
            </Card>
        </Stack>
    );
};