import {IconButton, IIconProps, Stack} from '@fluentui/react';
import {AdministrationFilters} from './components/AdministrationFilters';
import {FundsList} from './components/FundsList';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {AddFundModal} from './components/modals/AddFundModal';
import { useAdministrationContext } from './JobsLayoutPage';

export const FundsListPage = () => {
    const [openAddFundModal, { toggle: toggleOpenAddFundModal }] = useBoolean(false);
    const { selectedClient } = useAdministrationContext();

    const add: IIconProps = { iconName: 'add' };
    const addBtnToolTipId = useId('add-tooltip');

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack horizontal horizontalAlign={'space-between'}>
                <Stack.Item align={'center'} shrink={0} grow={1}>
                    <AdministrationFilters />
                </Stack.Item>
                <Stack.Item align={'center'}>
                    <IconButton aria-describedby={addBtnToolTipId} iconProps={add}
                        onClick={toggleOpenAddFundModal} label='Add' />
                </Stack.Item>
             </Stack>
            <FundsList />
            <AddFundModal open={openAddFundModal} toggle={toggleOpenAddFundModal} clientGuid={selectedClient?.guid ?? ''} clientId={selectedClient?.id ?? 0}/>
        </Stack>
    );
};