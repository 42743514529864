import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import { commonHeaders, Context } from 'utils';

export enum EntityStatus {
    Unknown = 'Unknown',
    Active = 'Active',
    DeActive = 'DeActive',
    Remove = 'Remove'
}

export enum ClientAddressType {
    Physical = 'Physical',
    Postal = 'Postal',
    Other = 'Other'
}

export type ClientInfo = {
    id: number;
    guid: string;
    name: string;
    type: "Corporate" | "Individual" | null;
    status: EntityStatus;
    addresses: ClientAddressInfo[];
    apiAuthInfo: ClientApiAuthInfo;
    billingEntity: string | null;
    contacts: ClientContactInfo[];
    directDebit: boolean;
    mainPhone: string;
    notes: string | null;
    primaryContact: ClientContactInfo;
    secondaryContact: ClientContactInfo;
};

export type ClientAddressInfo = {
    id: number;
    country: string;
    city: string;
    state: string;
    postalCode: string;
    street1: string;
    street2: string;
    type: ClientAddressType;
};

export type ClientApiAuthInfo = {
    authCodes: string[];
    useAdminFlow: boolean;
};

export type ClientContactInfo = {
    id: number;
    guid: string;
    clientId: number;
    status: EntityStatus;
    email: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    companyName: string | null;
    directPhone: string;
    jobTitle: string | null;
    mobilePhone: string;
};

function useGetClientByGuid(clientGuid: string) {
    const context = React.useContext(Context);


    const {data: currentClientData, isFetching: isCurrentClientLoading} = useQuery(
        ['getClientByGuid', clientGuid],
        () => axios.get<ClientInfo>(`${context?.config?.clientUrl}/api/1.0/Clients/${clientGuid || ''}`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientGuid,
        }
    );

    return {currentClientData, isCurrentClientLoading};
}

export {useGetClientByGuid};
