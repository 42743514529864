import { useMutation } from "react-query";
import axios from "axios";
import { useContext } from "react";
import { Context, queryClient } from "utils";
import { ClientAuditQuestionAnswerValue } from "./useGetWizardQuestions";

type MutateDataType = {
    questionId: number;
    answerId: number;
    answer: ClientAuditQuestionAnswerValue;
    jobId: number;
}

function useUpdateWizardAnswer() {
    const context = useContext(Context);

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.answersUrl}/api/1.0/jobs/${data.jobId}/clientAuditQuestions/${data.questionId}`, data)
        ,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getWizardQuestions');
            },
        });

    return { update, updateAsync, isUpdating };
}

export { useUpdateWizardAnswer };