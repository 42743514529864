export enum PropertyDetailsState {
    Nothing = 0,
    NSW = 1,
    QLD = 2,
    TAS = 3,
    SA = 4,
    WA = 5,
    ACT = 6,
    NT = 7,
    VIC = 8
}