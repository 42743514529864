import {Card, ControlledDatePicker} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useFieldArray, useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";
import {JobInfo} from "pages/WorkflowPortal/shared";
import {useUpdateJobQueryDates} from "../../../hooks";

interface IUpdateQueriesDatesCommand {

}

export const PreARQueriesTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {control, handleSubmit, setValue, register} = useForm<JobInfo>({
        defaultValues: {
            initialQueries: [{}]
        }, ...DefaultFormSettings
    });
    const {fields, append} = useFieldArray({control, name: 'initialQueries'});
    const {updateJobQueryDates, isQueryDatesUpdating} = useUpdateJobQueryDates();
    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: IUpdateQueriesDatesCommand) => {

        updateJobQueryDates({
            jobGuid: props.job?.guid || '',
            jobQueriesDate: props.job?.initialQueries
        }, {
            onSuccess: () => {
                if (props.onSubmitted)
                    props.onSubmitted();
            }
        });
    };


    useEffect(() => {
        if (props.job?.initialQueries)
            append(props.job?.initialQueries);
    }, [props.job]);
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                {
                    props.job?.initialQueries && fields.length ? (fields.map((query, index) => {
                            return (
                                <Stack styles={{root: {paddingBottom: 16, width: '100%'}}}
                                       tokens={{childrenGap: 16}}
                                       horizontal horizontalAlign={"space-between"}>
                                    <Stack.Item styles={stackItemStyles}>
                                        <ControlledDatePicker
                                            name={`initialQueries.${index}.dateSent`}
                                            label={formatMessage({id: 'preARQueriesSent'})}
                                            control={control}
                                        />
                                    </Stack.Item>
                                    <Stack.Item styles={stackItemStyles}>
                                        <ControlledDatePicker
                                            name={`initialQueries.${index}.dateResponse`}
                                            label={formatMessage({id: 'preARQueriesResponse'})}
                                            control={control}
                                        />
                                    </Stack.Item>
                                    <Stack.Item styles={stackItemStyles}>
                                        <ControlledDatePicker
                                            name={`initialQueries.${index}.dateReviewed`}
                                            label={formatMessage({id: 'reviewedOSQueries'})}
                                            control={control}
                                        />
                                    </Stack.Item>
                                </Stack>
                            )
                        }))
                        : null
                }

            </Stack>
        </Card>
    );
});