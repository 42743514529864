import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {ClientInfo} from "../../shared";

function useGetClientByGuid(clientGuid: string) {
    const context = React.useContext(Context);


    const {data: currentClientData, isFetching: isCurrentClientLoading} = useQuery(
        ['getClientByGuid', clientGuid],
        () => axios.get<ClientInfo>(`${context?.config?.clientUrl}/api/1.0/Clients/${clientGuid || ''}`, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId && !!clientGuid,
        }
    );

    return {currentClientData, isCurrentClientLoading};
}

export {useGetClientByGuid};
