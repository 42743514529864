export interface IAppTenantIdResponse {
    DatabaseName: string;
    GlobalId: string;
    Hostname: string;
    IsActive: boolean;
    IsWhiteLabel: boolean;
    Name: string;
    Type: number;
    TypeDescription: InstanceType;
}

export enum InstanceType {
    AuditPortal = 'AuditPortal',
    AuditReadyPortal = 'Workpapers'
}
