import axios, {AxiosError} from 'axios';
import React from 'react';
import {useIntl} from 'react-intl';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {useNotifications} from "../../../../components/notifications";
import {TimesheetInfo} from "../../shared";
import {MessageBarType} from "@fluentui/react";


function useGetTimesheetById(timesheetId?: number) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {data: timesheet, refetch, isRefetching, isLoading: isTimesheetLoading} = useQuery(
        ['getTimesheetById', timesheetId],
        () => axios.get<TimesheetInfo>(`${context?.config?.timesheetUrl}/api/1.0/workflow/timesheets/${timesheetId}`, commonHeaders()),
        {
            enabled: !!context?.config?.jobUrl && !!context.tenantId && !!timesheetId,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0] || formatMessage({id:'somethingWentWrong'})}`
                })
            }
        }
    );

    return {timesheet, refetch, isRefetching, isTimesheetLoading};

}

export {useGetTimesheetById};
