import {useQuery} from "react-query";
import axios from "axios";
import {useContext} from "react";
import {Context} from "../../../../utils";
import {ReviewType} from "../../enums";

export interface IReviewDates {
    jobId: number;
    reviewType: ReviewType;
    pointRaised?: Date | null;
    reviewedDate?: Date | null;
}

function useGetReviewDates(jobId: number, reviewType: ReviewType) {
    const context = useContext(Context);
    const {data: response, isLoading, refetch: refetchDates, isRefetching} = useQuery(['getJobReviewDates', reviewType],
        () => axios.get<IReviewDates>(`${context?.config?.jobUrl}/api/1.0/job/${jobId}/reviewDates?reviewType=${reviewType}`));

    return {response, isLoading, refetchDates, isRefetching};
}

export {useGetReviewDates}