import { BehaviorSubject, map, Observable, pluck, timer } from 'rxjs';
import { Axios } from 'axios-observable';
import { IAppConfiguration } from 'interfaces';
import { httpClientInstanceFactory } from './HttpClientInstance';

export class ConfigurationService {
    public config: BehaviorSubject<IAppConfiguration | null> = new BehaviorSubject<IAppConfiguration | null>(null);

    constructor(private httpClient: Axios = httpClientInstanceFactory('')) {
        this.getConfig().subscribe((config) => this.config.next(config));
    }

    private getConfig(): Observable<IAppConfiguration> {
        return this.httpClient
            .get(
                process.env.NODE_ENV === 'development'
                    ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/app/configuration'
                    : 'app/configuration'
            )
            .pipe(pluck('data'));
    }
}

export const configurationService = new ConfigurationService();
