import {FunctionComponent, useState} from 'react';
import {IColumn, Icon, SelectionMode, Stack, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {Card, DataTable, ItemDeletedStatus} from 'components';
import {IUserInfo} from 'interfaces';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {UserManagementActions} from './UserManagementActions';
import {UserManagementClients} from './UserManagementClients';
import {useUserManagement} from '../UserManagementPage';
import {IUser} from '../hooks/useGetUsersFilter';

interface Props {
    reset: Function;
    isLoading: boolean;
    users: IUser[];
}

export const UserManagementDataTable: FunctionComponent<Props> = ({reset, isLoading, users}) => {
    const {filters: {pageSize}, setSort} = useUserManagement();
    const {formatMessage} = useIntl();
    const theme = useTheme();

    const [columns] = useState<IColumn[]>([
        {
            key: 'firstName',
            name: formatMessage({id: 'firstName'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'firstName',
            onRender: (item, _, column) => (
                <Stack>{item[column!.fieldName!]}</Stack>
            )
        },
        {
            key: 'lastName',
            name: formatMessage({id: 'lastName'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'lastName',
            onRender: (item, _, column) => (
                <Stack>{item[column!.fieldName!]}</Stack>
            )
        },
        {
            key: 'displayName',
            name: formatMessage({id: 'displayName'}),
            minWidth: 150,
            maxWidth: 300,
            fieldName: 'displayName',
            onRender: (item, _, column) => (
                <Stack>{item[column!.fieldName!]}</Stack>
            )
        },
        {
            key: 'email',
            name: formatMessage({id: 'emailAddress'}),
            minWidth: 150,
            fieldName: 'email',
            onRender: (item, _, column) => (
                <Stack>{item[column!.fieldName!]}</Stack>
            )
        },
        {
            key: 'clients',
            name: formatMessage({id: 'clients'}),
            minWidth: 50,
            maxWidth: 50,
            className: 'centred',
            onRender: (item) => (<Stack grow horizontalAlign={"center"}><UserManagementClients item={item}/></Stack>),
        },
        {
            key: 'superUser',
            name: formatMessage({id: 'superUser'}),
            minWidth: 70,
            maxWidth: 70,
            fieldName: 'isSuperUser',
            className: 'centred',
            onRender: (item: IUserInfo) =>
                (<Stack grow horizontalAlign={"center"}>
                    {item.isSuperUser ?
                        <Icon iconName='CheckMark' styles={{root: {fontSize: 16, color: theme.palette.green}}}/> : null}
                </Stack>),
        },
        {
            key: 'status',
            name: formatMessage({id: 'status'}),
            minWidth: 70,
            maxWidth: 150,
            fieldName: 'isDeleted',
            onRender: (item) => (<ItemDeletedStatus item={item}/>),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item) => (<Stack grow horizontalAlign={"center"}><UserManagementActions item={item}/></Stack>),
        },
    ]);

    // const commandBarItems: ICommandBarItemProps[] = useMemo(
    // () => [
    // {
    //             key: 'add',
    //             text: formatMessage({ id: 'add' }),
    //             iconProps: { iconName: 'add' },
    //             iconOnly: true,
    //             onClick: toggleIsAddModalOpen,
    //         },
    //         {
    //             key: 'refresh',
    //             disabled: isLoading,
    //             text: formatMessage({ id: 'refresh' }),
    //             iconProps: { iconName: 'Refresh' },
    //             iconOnly: true,
    //             onClick: () => { reset() },
    //         },
    //     ],
    //     [isLoading, reset, toggleIsAddModalOpen, formatMessage]
    // );

    return (
        <Card styles={CardTableContainerStyles}>
            {/*<CommandBar*/}
            {/*    items={[]}*/}
            {/*    farItems={commandBarItems}*/}
            {/*    styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}*/}
            {/*/>*/}
            <DataTable
                sortableColumnKeys={['userName', 'firstName', 'lastName', 'displayName', 'email']}
                initialColumns={columns}
                items={cloneDeep(users)}
                shimmerLines={pageSize}
                enableShimmer={isLoading}
                setOrder={setSort}
                selectionMode={SelectionMode.none}
                containerHeight='calc(100% - 32px)'
                disableDragDrop={true}
            />


        </Card>
    );
};
