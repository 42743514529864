import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {DetailedSoftwareType, SoftwareType} from "enums";
import {AuditReportDateType, ClientInfo, ClientRiskRating} from "pages/WorkflowPortal/shared";
import {NotificationAction, useNotifications} from "components/notifications";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useWorkflows} from "../../WorkflowLayoutPage";

type CreateClientType = {
    partnerId: number | null;
    guid: string | null;
    name: string;
    mainPhone: string;
    riskRating: ClientRiskRating;
    auditReportDate: AuditReportDateType;
    softwareType: SoftwareType | null;
    detailedSoftwareType: DetailedSoftwareType | null;
};

function useCreateNewClient(onSuccess: () => void) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {showClientModal} = useWorkflows();
    const {formatMessage} = useIntl();

    const {mutate: addNewClient, isLoading: addNewClientLoading} = useMutation(
        (data: CreateClientType) => axios.post<ClientInfo>(`${context?.config?.clientUrl}/api/1.0/Clients`, data, commonHeaders()),
        {
            onSuccess: (data, variables) => {
                onSuccess();
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'createdSuccessfully'}),
                    description: formatMessage({id: "entityWasCreated"}, {name: variables.name}),
                    actions: (
                        <NotificationAction
                            onClick={() => {
                                console.debug('Create client guid: ' + data.data.guid);
                                showClientModal(data.data.guid);
                            }}>{formatMessage({id: 'view'})}</NotificationAction>
                    )
                });
                queryClient.invalidateQueries('getClientsByFilter');
                queryClient.invalidateQueries('getFundsByFilter');
                queryClient.invalidateQueries('getJobsByFilter');
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {addNewClient, addNewClientLoading};
}

export {useCreateNewClient};
