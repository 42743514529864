import {Card, ControlledDatePicker, ControlledDropdown} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Label, Link, Stack, Text, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "constants/forms";
import React, {
    ForwardedRef,
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useImperativeHandle,
    useMemo
} from "react";
import {ExperienceFormHandle, TabProps, TabRefProps} from "../props/tabProps";
import {EmployeePosition,} from "pages/WorkflowPortal/shared";
import {useUpdateJobGeneralInformation} from "../../../hooks";
import {useGetEmployeesByPosition} from "pages/WorkflowPortal/Employees";
import {useWorkflows} from "pages/WorkflowPortal";


interface IUpdateJobGeneralCommand {
    auditorId: number | null;
    dateReceived?: Date | null;
    auditorReviewed?: Date | null;
    auditorStartedDate?: Date | null;
    auditorTargetDate?: Date | null;
}


export const GeneralTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const theme = useTheme();
    const textItemsStyles = {root: {color: theme.schemes?.default?.semanticColors.bodyText}};
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {control, setValue, handleSubmit} = useForm<IUpdateJobGeneralCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            auditorId: props.job?.auditor?.id || 0,
            dateReceived: props.job?.dateReceived,
            auditorReviewed: props.job?.auditor?.reviewed,
            auditorStartedDate: props.job?.auditor?.pointsRaised,
            auditorTargetDate: props.job?.auditor?.queriesReviewed
        }
    });
    const {
        employeesByPosition: auditors,
        isEmployeesLoading: isAuditorsLoading
    } = useGetEmployeesByPosition(EmployeePosition.Auditor);
    const {updateJobGeneralInfo} = useUpdateJobGeneralInformation();
    const {showFundModal} = useWorkflows();

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));
    const onFormSubmit = (data: IUpdateJobGeneralCommand) => {

        updateJobGeneralInfo({
            jobGuid: props.job?.guid || '',
            auditorId: data.auditorId || null,
            dateReceived: data.dateReceived,
            auditorReviewed: data.auditorReviewed,
            auditorStartedDate: data.auditorStartedDate,
            auditorTargetDate: data.auditorTargetDate
        }, {
            onSuccess: () => {
                props.onSubmitted();
            }
        });

    }


    const auditorOptions = useMemo<IDropdownOption[]>(() => {
        return auditors?.data.map((auditor) => ({
            key: auditor.id,
            text: `${auditor.firstName} ${auditor.lastName}`
        })) || Array.of<IDropdownOption>();
    }, [auditors]);

    useEffect(() => {
        if (props.job) {
            setValue("auditorId", props.job.auditor?.id || null);
            setValue("auditorTargetDate", props.job.auditor?.queriesReviewed);
            setValue("auditorReviewed", props.job.auditor?.reviewed);
            setValue("auditorStartedDate", props.job.auditor?.pointsRaised);
            setValue("dateReceived", props.job.dateReceived);
        }
    });


    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <Label styles={textItemsStyles}>{formatMessage({id: 'auditJobName'})}</Label>
                    <Text styles={textItemsStyles}>
                        {`${props.job?.year} Audit`}
                    </Text>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Label styles={textItemsStyles}>{formatMessage({id: 'daysIn'})}</Label>
                    <Text styles={textItemsStyles}>
                        {String(props.job?.daysIn || '')}
                    </Text>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Label styles={textItemsStyles}>{formatMessage({id: 'fundName'})}</Label>
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                        onClick={() => showFundModal(props.job?.fund.id || 0, props.job?.client.id || 0, props.job?.fund.guid || '', props.job?.client.guid || '')}
                        >
                        {props.job?.fund.name}
                    </Link>

                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='dateReceived'
                        label={formatMessage({id: 'dateReceived'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Label styles={textItemsStyles}>{formatMessage({id: 'jobYear'})}</Label>
                    <Text styles={textItemsStyles}>
                        {String(props.job?.year || '')}
                    </Text>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Label styles={textItemsStyles}>{formatMessage({id: 'inherentRiskRating'})}</Label>
                    <Text styles={textItemsStyles}>
                        {String(props.job?.inherentRiskRating || '')}
                    </Text>
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='auditorId'
                        disabled={isAuditorsLoading}
                        label={formatMessage({id: 'auditAllocatedTo'})}
                        options={auditorOptions || []}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='auditorTargetDate'
                        label={formatMessage({id: 'targetDateforCompletion'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='auditorStartedDate'
                        label={formatMessage({id: 'dateAuditStarted'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='auditorReviewed'
                        label={formatMessage({id: 'readyForMGRReview'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});