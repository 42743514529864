import {FunctionComponent, useMemo} from 'react';
import {CommandBar, ICommandBarItemProps, IDropdownOption, Stack, useTheme} from '@fluentui/react';
import {Control, UseFormReset} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {ControlledSelect} from "components";

export interface IAdministrationDashboardFilter {
    clientGuid?: string;
    fundGuid?: string;
    filterByYear?: string;
    contactGuid?: string;
}

interface Props {
    control: Control<IAdministrationDashboardFilter, object>;
    reset: UseFormReset<IAdministrationDashboardFilter>;
    jobYears?: IDropdownOption[];
    shortClientInfos?: IDropdownOption[];
    shortFundInfos?: IDropdownOption[];
    shortContactInfos?: IDropdownOption[];
    isLoading?: boolean;
}

export const AdministrationDashboardFilter: FunctionComponent<Props> = ({
                                                                            control,
                                                                            reset,
                                                                            jobYears = [],
                                                                            shortClientInfos = [],
                                                                            shortFundInfos = [],
                                                                            shortContactInfos = [],
                                                                            isLoading,
                                                                        }) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();

    const filterItemStyles = { root: { width: '25%', } };
    const sortedYears = jobYears.sort((x,y) => x.key < y.key ? 1 : -1);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                disabled: isLoading,
                text: formatMessage({id: 'clearFilter'}),
                iconProps: {iconName: 'ClearFilter'},
                iconOnly: true,
                onClick: () => reset(),
            },
        ],
        [formatMessage, reset, isLoading]
    );

    return (
        <Stack
            horizontal
            horizontalAlign='stretch'
            tokens={{childrenGap: 16}}
            styles={{
                root: {
                    paddingBottom: 16,
                    borderBottom: `1px solid ${theme.palette.neutralLight}`,
                }
            }}
        >
            <Stack.Item styles={filterItemStyles}>
                <ControlledSelect
                    options={shortClientInfos}
                    name='clientGuid'
                    placeholder={formatMessage({id: 'filterByClient'})}
                    control={control}
                    disabled={isLoading}
                    allowFreeform
                    autoComplete/>
            </Stack.Item>
            <Stack.Item styles={filterItemStyles}>
                <ControlledSelect
                    options={shortFundInfos}
                    name='fundGuid'
                    placeholder={formatMessage({id: 'filterByFund'})}
                    control={control}
                    disabled={isLoading}
                    allowFreeform
                    autoComplete/>
            </Stack.Item>
            <Stack.Item styles={filterItemStyles}>
                <ControlledSelect
                    options={sortedYears}
                    name='filterByYear'
                    placeholder={formatMessage({id: 'filterByYear'})}
                    control={control}
                    disabled={isLoading}
                    allowFreeform
                    autoComplete/>
            </Stack.Item>
            <Stack.Item styles={filterItemStyles}>
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack.Item styles={{root: {width: '85%'}}}>
                        <ControlledSelect
                            options={shortContactInfos}
                            name='contactGuid'
                            placeholder={formatMessage({id: 'filterByContact'})}
                            control={control}
                            disabled={isLoading}
                            allowFreeform
                            autoComplete/>
                    </Stack.Item>
                    <Stack.Item styles={{root: {width: '15%'}}}>
                        <CommandBar
                            items={[]}
                            farItems={commandBarItems}
                            styles={{root: {height: '32px'}}}
                        />
                    </Stack.Item>

                </Stack>

            </Stack.Item>

        </Stack>
    );
};