import {IColumn, IContextualMenuItem, MessageBarType, SelectionMode, Stack} from '@fluentui/react';
import {DataTable, SanitizedText, useContextMenu} from 'components';
import React, {useEffect, useMemo, useState} from 'react';
import { useIntl } from 'react-intl';
import { getGrouppedReports } from 'utils';
import { getDepthPaddingStyles, ReportTable } from "../Table/ReportTable";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import { _ExtendedHeaderRowHeight, ReportValidationCell } from "../shared";
import {useSectionContext} from "../../../Section";
import {ReviewType} from "../../../../enums";
import {logger} from "../../../../../../services";
import {useCreateReviewItem} from "../../../../hooks";
import {useJobRedirects} from "../../shared";
import {useNotifications} from "../../../../../../components/notifications";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import { isAgreedAnswer, isAutoAnswer, isYesAnswer } from "../../answers";
import { IReport } from "../../../../interfaces/IReportsInfo";

export const InvestmentIncome = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let manualCoverage = 0;
        let systemCoverage = 0;
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            if (isAutoAnswer(i.answerText) || isAgreedAnswer(i.answerText)) {
                if (!!i.investmentName) {
                    if (i.incomeTotal) {
                        verifiedCoverage += i.incomeTotal;
                    } else {
                        verifiedCoverage += i.totalAcquisitionCost;
                    }
                }
            }
            if (!i.investmentName) {
                if (i.incomeTotal) {
                    incomeTotal += i.incomeTotal;
                } else {
                    incomeTotal += i.totalAcquisitionCost;
                }
            }
        })

        const result = {
            manual: 0,
            system: 0,
            total: 0,
            verified: incomeTotal != 0 ? verifiedCoverage / incomeTotal : 0
        }

        result.total = result.manual + result.system;
        return result;
    }
    
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'marketName' }), [items]);
    
    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    const [groupsWithSummaries, setGroupsWithSummaries] = useState<IGroupWithSummary[]>([...groups] as IGroupWithSummary[]);

    useEffect(() => {
        setGroupsWithSummaries(prev => [...prev.reduce((acc: IGroupWithSummary[], g) => {
                let children = reports.slice(g.startIndex, g.startIndex + g.count);
                acc.push({
                    ...g,
                    isCollapsed: g.isCollapsed,
                    summary: calculateAnswersSummary(children)
                });
                return acc;
            }, [])]
        );
    }, [summaryUpdateTrigger])

    const getAnswerControlValue = (text?: string): string => !text ? '' : isAgreedAnswer(text) || isAutoAnswer(text) || isYesAnswer(text) ? 'Yes' : 'No';

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(reports, item.id, field, value);
            setSummaryUpdateTrigger(Date.now())
        }
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'investmentName',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 200,
                maxWidth: 300,
                fieldName: 'investmentName',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {!item?.isHeadingRow && !item?.isTotalRow && <GeneratedAnswer itemId={item?.id} tableType={item?.reportType}
                            auto={item?.isAuto} disabled={item?.isAnswerDisabled} onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            value={item?.answerText} answerControlType={item?.answerControlType} />}
                    </Stack>
                )
            },
            {
                key: 'incomeTotal',
                name: formatMessage({ id: 'incomeTotal' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'incomeTotal',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'frankingCredits',
                name: formatMessage({ id: 'frankingCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'frankingCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'foreignCredits',
                name: formatMessage({ id: 'foreignCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'foreignCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'tfnCredits',
                name: formatMessage({ id: 'tfnCredits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'tfnCredits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxFree',
                name: formatMessage({ id: 'taxFree' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxFree',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxExempt',
                name: formatMessage({ id: 'taxExempt' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxExempt',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxDeferred',
                name: formatMessage({ id: 'taxDeferred' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'taxDeferred',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'distributedCapitalGains',
                name: formatMessage({ id: 'distributedCapitalGains' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'distributedCapitalGains',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'gst',
                name: formatMessage({ id: 'gst' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'gst',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'taxableIncomeExclCapitalGains',
                name: formatMessage({ id: 'taxableIncomeExclCapitalGains' }),
                minWidth: 130,
                maxWidth: 158,
                fieldName: 'taxableIncomeExclCapitalGains',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'capitalGainsIndexed',
                name: formatMessage({ id: 'capitalGainsIndexed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalGainsIndexed',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'capitalGainsDiscounted',
                name: formatMessage({ id: 'capitalGainsDiscounted' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalGainsDiscounted',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'capitalGainsOther',
                name: formatMessage({ id: 'capitalGainsOther' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalGainsOther',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'capitalGainsCgtConcession',
                name: formatMessage({ id: 'capitalGainsCGTConcession' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'capitalGainsCgtConcession',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow || item?.isTotalRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 200,
                maxWidth: 200,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const groupSummaryRenderer = (group: IGroupWithSummary): React.ReactNode => {
        const summary = group.summary;
        return <>
            {
                group?.name && group?.name?.indexOf("Total") === -1 &&
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                    </Stack.Item>
                </Stack>
            }
        </>
    }

    return <ReportTable
        initialColumns={columns}
        columns={columns}
        items={reports}
        header={{ rowHeight: _ExtendedHeaderRowHeight }}
        groups={groupsWithSummaries.map(g => {g.isCollapsed = false; return g})}
        groupProps={{ summary: {
                show: true,
                childRenderer: groupSummaryRenderer
            }}}
        enableShimmer={isFetching}
        openAll={true}
    />;
};
