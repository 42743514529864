import {FontSizes, IComboBoxOption, IconButton, PrimaryButton, Spinner, SpinnerSize, Stack} from '@fluentui/react';
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import Dropzone, {useDropzone} from "react-dropzone";
import { useCreateWrapAccount } from '../hooks/useCreateWrapAccount';
import { ControlledSelect} from 'components';
import { useForm } from 'react-hook-form';

interface IUploadWrapAccount {
    label?: string;
}

interface IYearsDropdown{
    year: number;
}

export const UploadWrapAccount: FunctionComponent<IUploadWrapAccount> = ({label}) => {
    const {createWrapAccount, isLoading: isCreateLoading} = useCreateWrapAccount();
    const {formatMessage} = useIntl();
    const years = Array.from({ length: 51 }, (_, index) => 2000 + index);
    const yearsOptions: IComboBoxOption[] = years.map((number) => {
        return {
            key: number,
            text: `${number}`
        };
    });
    const {control, watch, reset, resetField, getValues} = useForm<IYearsDropdown>({
        mode: 'onChange',
        defaultValues: { year : (new Date()).getFullYear()},
    });
    const yearValue = watch('year');
    
    const [dropDownData, setDropDownData] = useState<IYearsDropdown>({ year : (new Date()).getFullYear()});
    useEffect(() => { setDropDownData({ year: yearValue}) }, [yearValue]);

    const upload = useCallback((selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        const year = getValues('year');
        createWrapAccount({
            year: year,
            data: formData,
        });
    }, [])

    const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList?.length) return;

        upload(fileList[0]);
    }, []);

    const onDrop = useCallback(files => {
        if (!files?.length) return;

        upload(files[0])
    }, [])

    return (
        <Stack horizontal  tokens={{childrenGap: 16}}>
            <Stack.Item>
                <ControlledSelect
                    options={yearsOptions}
                    name='year'
                    placeholder={formatMessage({id: 'year'})}
                    control={control}
                    allowFreeform
                    autoComplete/>
            </Stack.Item>
            <Stack.Item>
                <Dropzone onDrop={onDrop} noDragEventsBubbling={true}>
                {({getRootProps, getInputProps, inputRef}) => (
                    <div {...getRootProps()}>
                        <Stack styles={{root: {width: 'max-content'}}}>
                            <PrimaryButton
                                disabled={isCreateLoading}
                                iconProps={{iconName: 'Upload'}}
                                width='100%'
                                text={label ?? formatMessage({id: 'uploadAttachment'})}
                            >
                            </PrimaryButton>
                            <input
                                name='attachmentBlob'
                                ref={inputRef}
                                style={{display: 'none'}}
                                type='file'
                                onChange={onUpload}
                                disabled={isCreateLoading}
                                {...getInputProps()}
                            />
                        </Stack>

                    </div>
                )}
                </Dropzone>
            </Stack.Item>
        </Stack>
    );
};
