import {IColumn, Stack} from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const BGLInvestmentSummaryWithYield = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 300,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'units',
                name: formatMessage({ id: 'units' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'units',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'costPerUnit',
                name: formatMessage({ id: 'costPerUnit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'costPerUnit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'total',
                name: formatMessage({ id: 'total' }),
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'total',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'marketPerUnit',
                name: formatMessage({ id: 'marketPerUnit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketPerUnit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'total1',
                name: formatMessage({ id: 'total' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'total1',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'income',
                name: formatMessage({ id: 'income' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'income',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'yieldCost',
                name: formatMessage({ id: 'yieldCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'yieldCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' />,
            },
            {
                key: 'market',
                name: formatMessage({ id: 'market' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'market',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
