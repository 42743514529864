import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    isPermanent: boolean;
}
/**
 * @deprecated The method should not be used. Use 'useUpdateFileNote' instetad
 */
function useTogglePermFileNote() {
    const context = useContext(Context);

    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/fileNotes/${data.id}`, data))

    return {update, updateAsync, isUpdating};
}

export {useTogglePermFileNote};