import { Stack, useTheme, Spinner, Text, Dropdown, PrimaryButton } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ClientAuditQuestionAnswerValue, ClientAuditQuestionType, IWizardQuestion, useGetWizardQuestions } from '../../hooks/useGetWizardQuestions';


interface IWizardQuestionsInfoProps {
    job: any;
}

interface IWizardQuestionInfoProps {
    question: IWizardQuestion;
}


const WizardQuestionInfo: FunctionComponent<IWizardQuestionInfoProps> = ({ question }) => {
    const theme = useTheme();

    const delimiter = "\r\n";
    const successParts = question.successText?.split(new RegExp(`(?=${delimiter})`));
    const failParts = question.failText?.split(new RegExp(`(?=${delimiter})`));


    return (
        (question.answer === ClientAuditQuestionAnswerValue.Yes && successParts && successParts.length > 0) ?
            <li style={{ marginTop: '2%' }}><Stack tokens={{ childrenGap: 5 }}>
                {successParts.map(x => <Text theme={theme.schemes?.default} styles={{ root: { marginLeft: '1%' } }}>{x}</Text>)}
            </Stack></li> :
            (question.answer === ClientAuditQuestionAnswerValue.No && failParts && failParts.length > 0) ?
                <li style={{ marginTop: '2%' }}><Stack tokens={{ childrenGap: 5 }}>
                    {failParts.map(x => <Text theme={theme.schemes?.default} styles={{ root: { marginLeft: '1%' } }}>{x}</Text>)}
                </Stack></li> : <></>

    );
}

export const WizardQuestionsInfo: FunctionComponent<IWizardQuestionsInfoProps> = ({ job }) => {
    const { formatMessage, formatDate } = useIntl();
    const theme = useTheme();

    const [displayedQuestions, setDisplayedQuestions] = useState<IWizardQuestion[]>();
    const { response, isLoading } = useGetWizardQuestions(job.id);

    useEffect(() => {
        const firstQuestion = response?.find(x => x.type === ClientAuditQuestionType.FirstAnswer);

        if (!firstQuestion || !response) {
            return;
        }

        const firstQuestionAnswer = firstQuestion.answer;

        if (firstQuestionAnswer === ClientAuditQuestionAnswerValue.Yes) {
            setDisplayedQuestions(response.filter(x=>x.type === ClientAuditQuestionType.YesJob));
        }

        if (firstQuestionAnswer === ClientAuditQuestionAnswerValue.No) {
            setDisplayedQuestions(response.filter(x => x.type === ClientAuditQuestionType.NoJob));
        }
    }, [response]);

    return (
        <>
            {isLoading && response ? <Spinner /> :
                <Stack tokens={{ childrenGap: 16 }}>
                    <Text theme={theme.schemes?.default}>{formatMessage({id: 'questionsPostSubmitText'})}</Text>
                    <ul style={{color: theme.schemes?.default?.semanticColors.bodyText}}>{displayedQuestions?.map(x => <WizardQuestionInfo key={x.questionId} question={x} />)}</ul>
                </Stack>}
        </>
    );
}