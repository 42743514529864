import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import { AuditReportType } from "../interfaces";

type MutateDataType = {
    jobId: number;
    sendEmail: boolean;
    aduitReportDate: number,
    includeComparativeInformation: boolean,
    qualification: string,
    complianceQualification: string,
    isAuditReportDateVisible: boolean,
    auditReportType: AuditReportType,
    isDraftAuditReportOverride: boolean,
    isDraft: boolean,
    skipArGeneration: boolean
}

function useDispatchAudit() {
    const context = useContext(Context);
    const {
        mutate: dispatch,
        isLoading: isDispatching
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/dispatch/${data.jobId}`, {
        ...data,
        isSourceDispatched: true
    }).then(result => result.data))

    return {dispatch, isDispatching};
}

export {useDispatchAudit};