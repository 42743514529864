import { FunctionComponent, useEffect } from 'react';
import { Stack } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { Card } from 'components';
import { ImportReport } from './components';
import * as localStorageService from '../../services/localStorageService';
import { JOB_IDENTIFIER_KEY } from '../../constants/authorization';
import { queryClient } from '../../utils';
import { _GetWorkContextQueryKey } from '../../providers';

export const ImportReportPage: FunctionComponent = () => {
    const { guid } = useParams();

    useEffect(() => {
        if (guid) {
            localStorageService.setData(JOB_IDENTIFIER_KEY, guid);
            queryClient.invalidateQueries(_GetWorkContextQueryKey);
        }
    }, [guid]);

    useEffect(() => {
        window.onbeforeunload = () => {
            sessionStorage.clear();
            localStorageService.removeData(JOB_IDENTIFIER_KEY);
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <Stack grow={1} horizontalAlign='center'>
            <Stack styles={{ root: { maxWidth: 1440, width: '100%', padding: 16 } }}>
                <Card>
                    <ImportReport guid={guid} />
                </Card>
            </Stack>
        </Stack>
    );
};
