import {FunctionComponent} from 'react';
import {observer} from 'mobx-react';
import {Navigate, Outlet} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {PageWithBreadcrumb} from 'components';
import {useRulesetStore} from '../RulesetLayoutPage';

export const RulesetRuleLayoutPage: FunctionComponent = observer(() => {
    const {ruleset, rule} = useRulesetStore();

    if (ruleset?.isDeleted) {
        return <Navigate replace to={RouterPaths.admin.rulesets.ruleset.root(ruleset?.id)}/>;
    }

    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'rule',
                text: rule?.ruleName ?? '',
                key: RouterPaths.admin.rulesets.ruleset.rules.rule(ruleset?.id, rule?.id),
            }}>
            <Outlet/>
        </PageWithBreadcrumb>
    );
});
