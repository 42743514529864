import { IDatePickerProps, Stack } from '@fluentui/react';
import { ControlledDatePicker } from 'components';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateItemAnswer } from '../../../hooks/answers';
import { useJobContext } from '../../../JobPortalLayoutPage';
import { TableType } from '../../../../../enums';
import { ColumnInfo } from '../../../enums';
import { format } from 'date-fns';
import { useTabContext } from '../../../JobPortalPage';

interface CalendarAnswerProps extends IDatePickerProps {
    value?: any;
    tableType?: TableType;
    itemId?: number;
    columnInfo?: ColumnInfo;
    onChange?: (answer: any) => void;
    onUpdate?: (answer: any) => void;
}

export const CalendarAnswer: FunctionComponent<CalendarAnswerProps> = ({
    value,
    onChange,
    tableType,
    itemId,
    columnInfo,
    onUpdate,
    ...otherProps
}: CalendarAnswerProps) => {
    const { jobId } = useJobContext();
    const { isTabEnabled } = useTabContext();
    const { update, isUpdating } = useUpdateItemAnswer();

    const { control, watch } = useForm<{ dateAnswer: string }>({
        mode: 'onChange',
        defaultValues: {
            dateAnswer: (value || '').replace(/\.+/g, '/'),
        },
    });

    const onSubmit = useCallback((props: any) => {
        if (onChange) {
            onChange(props.dateAnswer);
            return;
        }

        const formatedDate = props.dateAnswer ? format(props.dateAnswer, 'dd.MM.yyyy') : '';
        update(
            {
                jobId: jobId,
                tableType: tableType!,
                itemId: itemId!,
                text: formatedDate,
                columnInfo: columnInfo!,
            },
            {
                onSuccess: () => {
                    onUpdate?.(formatedDate);
                },
            }
        );
    }, []);

    useEffect(() => {
        const subscription = watch(onSubmit);
        return () => subscription.unsubscribe();
    }, [watch, onSubmit]);

    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        //console.debug('[ANSWER::CALENDAR::DISABLE]', !isTabEnabled, isUpdating, !!otherProps.disabled);

        setDisabled(!isTabEnabled || isUpdating || !!otherProps.disabled);
    }, [otherProps.disabled, isTabEnabled, isUpdating]);

    return (
        <Stack grow>
            <ControlledDatePicker control={control} name='dateAnswer' {...otherProps} disabled={disabled} />
        </Stack>
    );
};
