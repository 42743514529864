import {Card, ControlledDatePicker} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {Stack, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useFieldArray, useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import {TabProps, TabRefProps} from "../props/tabProps";
import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle} from "react";
import {QueryDate} from "pages/WorkflowPortal/shared";
import {useUpdateJobDispatchDates} from "../../../hooks";

interface IUpdateDispatchDatesCommand {
    jobGuid: string;
    dateDraftARSent?: Date;
    dateARSent?: Date;
    datePDFCreated?: Date;
    auditFinalised?: Date;
    dispatchQueries?: QueryDate[];
}

export const DispatchTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const {control, handleSubmit} = useForm<IUpdateDispatchDatesCommand>({
        ...DefaultFormSettings, defaultValues: {
            dateDraftARSent: props.job?.dateDraftARSent,
            dateARSent: props.job?.dateARSent,
            datePDFCreated: props.job?.datePDFCreated,
            auditFinalised: props.job?.auditFinalised,
            dispatchQueries: props.job?.dispatchQueries || [{}]
        }
    });
    const {fields, append} = useFieldArray({control, name: 'dispatchQueries'});
    const {updateJobDispatchDates, isDispatchDatesUpdating} = useUpdateJobDispatchDates();


    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();

        },
    }));

    const onFormSubmit = (data: IUpdateDispatchDatesCommand) => {

        updateJobDispatchDates({
            jobGuid: props.job?.guid || '',
            jobQueriesDispatch: props.job?.dispatchQueries
        }, {
            onSuccess: () => {
                props.onSubmitted();
            }
        });
    };


    useEffect(() => {
        append(props.job?.dispatchQueries || [{}, {}, {}]);
        console.log(fields);
    }, [props.job, append]);

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='dateDraftARSent'
                        label={formatMessage({id: 'unsignedARSent'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='dateARSent'
                        label={formatMessage({id: 'arSent'})}
                        control={control}
                    />
                </Stack.Item>


                {(fields.map((date, index) => {
                    return (
                        <Stack.Item
                            styles={{
                                root: {
                                    width: '100%',
                                    padding: '16px 0',
                                    borderTop: `1px solid ${theme.palette.neutralLight}`,
                                    borderBottom: `1px solid ${theme.palette.neutralLight}`,
                                },
                            }}>
                            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                                <Stack.Item styles={stackItemStyles}>
                                    <ControlledDatePicker
                                        name={`dispatchQueries.${index}.dateSent`}
                                        label={formatMessage({id: 'postARQueriesSent'})}
                                        control={control}
                                    />
                                </Stack.Item>

                                <Stack.Item styles={stackItemStyles}>
                                    <ControlledDatePicker
                                        name={`dispatchQueries.${index}.dateResponse`}
                                        label={formatMessage({id: 'postARQueriesResponse'})}
                                        control={control}
                                    />
                                </Stack.Item>

                                <Stack.Item styles={stackItemStyles}>
                                    <ControlledDatePicker
                                        name={`dispatchQueries.${index}.dateReviewed`}
                                        label={formatMessage({id: 'reviewedPostARQueries'})}
                                        control={control}
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    );
                }))}


                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='datePDFCreated'
                        label={formatMessage({id: 'pdfWorkpaperCreated'})}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='auditFinalised'
                        label={formatMessage({id: 'auditFinalised'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});
