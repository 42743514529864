import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface IGetJobImportStatusParams {
    id?: number;
}

export interface IImportStatusNotification {
    JobId: number;
    NewStatus: DetailedImportStatus;
    OldStatus: DetailedImportStatus;
    UserIdentifier: string;
}

export enum ImportStatus {
    Ready = 250115,
    Completed = 250120,
    InProgress = 250130
}

export enum DetailedImportStatus {
    Initial = 0,
    Preparing = 1,
    ImportStarted = 10,
    ImportScheduled = 15,
    FilesUploaded = 17,
    ApiImportCompleted = 18,
    Parsing = 2,
    Parsed = 20,
    ReportsCreation = 22,
    ReportsCreated = 25,
    UpdateAnswers = 3,
    UpdateCalculators = 4,
    Validating = 5,
    ValidationFinished = 55,
    CrsCompare = 6,
    Success = 7,
    ImportCompleted = 70,
    Failed = 8,
    ImportFailed = 80,
    ApiCallFailed = 9
}

export interface IJobImportStatus {
    statusCode: ImportStatus;
    detailedStatus: DetailedImportStatus;
    message?: string;
}

function useGetJobImportStatus({id}: IGetJobImportStatusParams) {
    const context = React.useContext(Context);

    const {
        data: jobImportStatus,
        isLoading: isJobImportStatusLoading,
        refetch: refetchJobImportStatus,
        isFetching: isJobImportStatusFetching
    } = useQuery(
        ['getJobImportStatus', id],
        () => axios.get<IJobImportStatus>(`${context?.config?.importUrl}/api/1.0/import/status/${id}`, commonHeaders()),
        {
            enabled: !!context?.config?.jobUrl && !!context.tenantId && !!id,
            retry: false,
        }
    );

    return {jobImportStatus, isJobImportStatusLoading, refetchJobImportStatus, isJobImportStatusFetching};
}

export {useGetJobImportStatus};
