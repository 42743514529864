import {IColumn, SelectionMode, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {DataTable, SanitizedText} from 'components';
import { dateFortmatString, formatDate, getGrouppedReports } from "utils";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, ReportValueType, TableType} from "../../../../../../enums";
import {ReportTemplateTypeProps} from "../Types";
import {useSectionContext} from "../../../Section";



export const BGL360BankStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups,
        reports: items,
        groupKey: 'accountName',
        formatGroupName: (item) => item?.accountName ? `Account name: ${item.accountName}` : item?.accountName,
    }), [items]);

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 300,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'debit',
                name: formatMessage({ id: 'debit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'debit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'credit',
                name: formatMessage({ id: 'credit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'credit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'ledgerBalance',
                name: formatMessage({ id: 'ledgerBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'ledgerBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed1',
                onRender: (item) => (item?.valueType == ReportValueType.Value && item?.answerText &&
                    <Stack verticalAlign='center' grow>
                    <GeneratedAnswer itemId={item?.id} tableType={item?.reportType} value={item?.answerText} answerControlType={item?.answerControlType}
                        auto={item?.isAuto} disabled={item?.isAuto}/>
                    </Stack>
                )
            },
            {
                key: 'statementBalance',
                name: formatMessage({ id: 'statementBalance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'statementBalance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'  />,
            },
            {
                key: 'variance',
                name: formatMessage({ id: 'variance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'variance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed2',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'reviewed2',
                onRender: (item) => (item?.valueType == ReportValueType.Value && item?.answerText1 &&
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer itemId={item?.id} tableType={item?.reportType} value={item?.answerText1} answerControlType={item?.answerControlType}
                            auto={item?.isAuto1} disabled={item?.isAuto1} />
                    </Stack>
                )
            },
        ],
        [formatMessage]
    );

    return <ReportTable
        initialColumns={columns}
        items={reports}
        openAll={true}
        groups={groups}
        enableShimmer={isFetching}
    />
};
