import {IconButton, IIconProps, Stack} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useState } from 'react';
import {AdministrationFilters} from './components/AdministrationFilters';
import { AuditQueriesPage } from './components/auditQueries/AuditQueriesPage';
import { InitialInformationChecklistPage } from './components/intialInformationChecklist/InitialInformationChecklistPage';
import {JobsList} from "./components/JobsList";
import { AddJobModal } from './components/modals/AddJobModal';


export const JobListPage = () => {
    const [openAddJobModal, { toggle: toggleOpenAddJobModal }] = useBoolean(false);

    const add: IIconProps = { iconName: 'add' };
    const addBtnToolTipId = useId('add-tooltip');

    const [currentJobGuid, setCurrentJobGuid] = useState('');
    const [showAuditQueriesPage, { toggle: toggleShowAuditQueriesPage }] = useBoolean(false);
    const [showIicPage, { toggle: toggleShowIicPage }] = useBoolean(false);

    if (showIicPage) {
        return <InitialInformationChecklistPage toggle={toggleShowIicPage} jobGuid={currentJobGuid} />
    }

    if (showAuditQueriesPage) {
        return <AuditQueriesPage toggle={toggleShowAuditQueriesPage} jobGuid={currentJobGuid} />
    }

    return (
        <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: '100%' } }}>
            <Stack horizontal horizontalAlign={'space-between'}>
                <Stack.Item align={'center'} shrink={0} grow={1}>
                    <AdministrationFilters/>
                </Stack.Item>
                <Stack.Item align={'center'}>
                    <IconButton aria-describedby={addBtnToolTipId} iconProps={add}
                        onClick={toggleOpenAddJobModal} label='Add' />
                </Stack.Item>
             </Stack>
            <JobsList toggleShowAuditQueriesPage={toggleShowAuditQueriesPage} toggleShowInitialInformationChecklistPage={toggleShowIicPage} setCurrentJobGuid={setCurrentJobGuid} currentJobGuid={currentJobGuid}/>
            <AddJobModal useSelectedFund useSelectedClient open={openAddJobModal} toggle={toggleOpenAddJobModal} />
        </Stack>
    );
};