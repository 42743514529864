import {IconButton, Stack, TextField, useTheme} from "@fluentui/react";
import {useIntl} from "react-intl";
import {FullWidth} from "../../../../constants";
import {ControlledTextField} from "../../../../components";
import {Control} from "react-hook-form/dist/types/form";

type AuthCodeFieldProps = {
    control:Control<any>
    index: number;
    value: string;
    onChange: (newValue?: string) => void;
    onDelete: (index: number) => void;
}

export const AuthCodeField = (props: AuthCodeFieldProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();


    return (
        <Stack horizontal horizontalAlign={"space-between"} verticalAlign={"center"} styles={FullWidth}
               tokens={{childrenGap: 16}}>
            <Stack.Item styles={{root: {width: '95%'}}}>
                <ControlledTextField label={`${formatMessage({id: 'accSoftwareCode'})} ${props.index + 1}`}
                                     control={props.control}
                                     name={`accSoftwareCodes.${props.index}.value`}
                                     required
                                     rules={{
                                         required: formatMessage({id: 'requiredField'}),
                                     }}
                />
            </Stack.Item>
            <Stack.Item styles={{root: {width: '5%', paddingTop: 25}}}>
                <IconButton iconProps={{iconName: 'Cancel', styles: {root: {color: theme.palette.red}}}}
                            onClick={() => props.onDelete(props.index)}/>
            </Stack.Item>
        </Stack>
    )
}