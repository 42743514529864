import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {useIntl} from 'react-intl';
import {IConclusionOpinionItem} from "./interfaces";
import {Icon, Stack, useTheme} from "@fluentui/react";
import {ConclusionStatement} from "./enums";


export const ConclusionOpinionProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const [conclusionItems, setConclusionItems] = useState<IConclusionOpinionItem[]>();

    const getColor = useCallback((conclusionStatement) => {
        switch (conclusionStatement) {
            case ConclusionStatement.Positive:
            case ConclusionStatement.Conditional:
                return theme.schemes?.default?.semanticColors.bodyText;
            case ConclusionStatement.NoOpinion:
            case ConclusionStatement.Negative:
                return theme.palette.red;
        }
    }, [theme, items]);
    const getAnswerText = useCallback((item: IConclusionOpinionItem) => {
        switch (item.conclusionStatement) {
            case ConclusionStatement.Positive:
                return item.positiveAnswer;
            case ConclusionStatement.Negative:
                return item.negativeAnswer;

            case ConclusionStatement.Conditional:
                return item.conditionalAnswer;

            case ConclusionStatement.NoOpinion:
                return item.noOpinionAnswer;
        }
    }, [items]);

    useEffect(() => {
        setConclusionItems(items);
    }, [items]);

    return (
        <ProceduresDataTable
            items={conclusionItems || []}
            isLoading={isLoading}
            columns={[
                {
                    key: 'action',
                    name: '',
                    fieldName: 'action',
                    onRender: (item: IConclusionOpinionItem) => (
                        <Stack horizontalAlign='center' grow>
                            {item.conclusionStatement === ConclusionStatement.NoOpinion && (
                                <Icon iconName='AlertSolid'
                                      styles={{root: {color: getColor(item.conclusionStatement)}}}/>
                            )}
                        </Stack>
                    ),
                    minWidth: 15,
                    maxWidth: 15,
                },
                {
                    key: 'section',
                    name: formatMessage({id: 'auditSection'}),
                    fieldName: 'auditSection',
                    onRender: (item: IConclusionOpinionItem) => <SanitizedText data={item?.tabName}
                                                                               format={"Bold"}
                                                                               color={getColor(item.conclusionStatement)}/>,
                    minWidth: 200,
                    maxWidth: 200,
                },
                {
                    key: 'auditConclusion',
                    name: formatMessage({id: 'auditConclusion'}),
                    fieldName: 'auditConclusion',
                    onRender: (item: IConclusionOpinionItem) => <SanitizedText data={getAnswerText(item)}
                                                                               color={getColor(item.conclusionStatement)}/>,
                    minWidth: 400,
                },
            ]}
        />
    );
};