import axios from 'axios';
import {DashboardTabsEnum} from '../enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface IContactInfo {
    id: number;
    name: string;
    idGuid: string;
}

interface Props {
    dashboardTab: DashboardTabsEnum;
    clientId?: string;
    fundId?: string;
    clientGuid?: string;
    fundGuid?: string;
}

function useGetContactInfos({clientId, fundId, dashboardTab, clientGuid, fundGuid}: Props) {
    const context = React.useContext(Context);

    const {data: mainContacts, isLoading: isMainContactsLoading} = useQuery(
        ['getShortContactInfos', clientId, fundId, dashboardTab, clientGuid, fundGuid],
        () => axios.get<IContactInfo[]>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/mainContacts`, {
            ...commonHeaders(),
            params: {clientId, fundId, dashboardTab, clientGuid, fundGuid}
        })
            .then(response => response.data.map(d => ({key: d.idGuid, text: d.name}))),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {mainContacts, isMainContactsLoading};
}

export {useGetContactInfos};