import {IColumn, Stack} from '@fluentui/react';
import {SanitizedText} from 'components';
import {IReport} from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {getDepthPaddingStyles, ReportTable} from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {GetSummaryItemColor, GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {isAgreedAnswer, isAutoAnswer} from "../../answers";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {AnswerControlType, TableType} from "../../../../../../enums";
import {isTotalType, isValueType} from "./consts";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";


export const BGLInvestmentMovement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            const isTotal = isTotalType(i);
            if (i.hasData) {
                if (isAgreedAnswer(i.answerText1) || isAutoAnswer(i.answerText1)) {
                    if (!isTotal) {
                        verifiedCoverage += i.additionsCost;
                    }
                }
                if (isTotal) {
                    incomeTotal += i.additionsCost;
                }
            }
            else {
                if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                    if (!isTotal) {
                        verifiedCoverage += i.additionsCost;
                    }
                }
                if (isTotal) {
                    incomeTotal += i.additionsCost;
                }
            }
        })

        return {
            verified: incomeTotal != 0 ? verifiedCoverage / incomeTotal : 0
        };
    }

    const rebuildItems = (arr: any[]): IReport[] => {
        return (arr || []).reduce((acc, i) => {
            const isValue = isValueType(i);
            acc.push({
                ...i,
                showAttachmentIcon: true,
                showCommentsIcon: true,
                showControl: isValue,
                children: rebuildItems(i?.children)
            })

            return acc;
        }, [])
    }

    const reportItems = useMemo(() => rebuildItems(items), [items]);
    const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState<number>(Date.now());
    
    const columns: IColumn[] = useMemo(
        () => [{
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentsIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 300,
                maxWidth: 400,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
            },
            {
                key: 'answerText',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.showControl && <GeneratedAnswer
                            itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText}
                            onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                            answerControlType={AnswerControlType.WorkDoneSwitch} />}
                    </Stack>
                )
            },
            {
                key: 'openingBalanceUnits',
                name: formatMessage({ id: 'obUnit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'openingBalanceUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' />,
            },
            {
                key: 'openingBalanceCost',
                name: formatMessage({ id: 'obCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'openingBalanceCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'additionsUnits',
                name: formatMessage({ id: 'additionsUnits' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'additionsUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'additionsCost',
                name: formatMessage({ id: 'cost' }),
                minWidth: 80,
                maxWidth: 128,
                fieldName: 'additionsCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'disposalsUnits',
                name: formatMessage({ id: 'dispUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalsUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'disposalsCost',
                name: formatMessage({ id: 'disposalCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalsCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'profLoss',
                name: formatMessage({ id: 'profitLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'profLoss',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'closingBalanceUnits',
                name: formatMessage({ id: 'cbUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBalanceUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'closingBalanceCost',
                name: formatMessage({ id: 'cbCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBalanceCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'market',
                name: formatMessage({ id: 'market' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'market',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const reports = useMemo(() => {
        return reportItems.reduce((acc: IReport[], i: any) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
            }
            acc.push(i)
            return acc;
        }, []);
    }, [summaryUpdateTrigger]);

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        if (item.id && field) {
            item[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
            setSummaryUpdateTrigger(Date.now())
        }
    }

    return <ReportTable
        initialColumns={columns}
        openAll={true}
        items={reports}
        groupProps={{
            mergedGroupHeader: {propertyKey: 'name'},
            summary: {
                show: true,
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    return <>
                        <Stack horizontal tokens={{childrenGap: 16}}>
                            <Stack.Item>
                                <GroupSummaryItem value={group?.summary.verified} type={'verified'}></GroupSummaryItem>
                            </Stack.Item>
                        </Stack>
                    </>
                }
            }
        }}
        isFetching={isFetching} />;
};
