import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Card, ControlledTextField, ModalFooter } from 'components';
import { DefaultFormSettings } from 'constants/forms';
import { useAddApprover } from "./hooks/useAddApprover";

interface Props {
    onDismiss?: () => void;
    onSubmit?: (form: { email: string }) => void;
}

export const AddConnectionApproverForm: FunctionComponent<Props> = ({ onDismiss, onSubmit }: Props) => {
    const { formatMessage } = useIntl();
    const { add, isAdding } = useAddApprover();
    
    const { control, handleSubmit, formState } = useForm<{ email: string }>({
        ...DefaultFormSettings,
        defaultValues: {
            email: '',
        },
    });

    const addConnectionApprover = (form: { email: string }) => {
        add({email: form.email}, {
            onSuccess: () => {
                onSubmit?.(form);
            }
        })
    };

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                <ControlledTextField label={formatMessage({ id: 'approverEmail' })} name='email' control={control} />
            </Card>
            <ModalFooter horizontalAlign='end'>
                <PrimaryButton disabled={!formState.isDirty || isAdding}
                               onClick={handleSubmit(addConnectionApprover)}
                >
                    {isAdding ? <Spinner size={SpinnerSize.small}/> : formatMessage({ id: 'save' })}
                </PrimaryButton>
            </ModalFooter>
        </Stack>
    );
};
