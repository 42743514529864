import React from 'react';
import axios from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';

function useResetImport() {
    const context = React.useContext(Context);

    const { mutate: deleteAllReports, isLoading, isSuccess } = useMutation((jobId: number) =>
        axios.post(`${context?.config?.importUrl}/api/1.0/Import/Reset/${jobId}`, commonHeaders()),
        {
            onSuccess: () => queryClient.invalidateQueries('getJobImportData'),
        }
    );

    return { deleteAllReports, isLoading, isSuccess };
}

export { useResetImport };