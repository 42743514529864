import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;

    isPermanent: boolean;

}

function useTogglePermHinTableItem(callback?: () => void) {
    const context = useContext(Context);
    const {mutate: update, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/hinTable/${data.id}/togglePerm`, data, commonHeaders()), {
            onSuccess: () => {
                if (callback)
                    callback();
            }
        }
    );


    return {update, isUpdating};
}

export {useTogglePermHinTableItem};