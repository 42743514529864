import {IColumn, SelectionMode, Stack} from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, { useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {SanitizedText} from 'components';
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {ReportValueType} from "../../../../../../enums";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportTemplateTypeProps} from "../Types";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";
import { GroupSummaryItem, IGroupSummary, IGroupWithSummary } from "../Table/GroupSummary";
import { isAgreedAnswer, isAutoAnswer } from "../../answers";



export const BGL360InvestmentMovement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            const isTotal = i.valueType === 2;
            
            if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                if (!isTotal) {
                    verifiedCoverage += i.additionsCost ?? 0;
                }
            }
            
            if (isTotal) {
                incomeTotal += i.additionsCost ?? 0;
            }
        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const rebuildReports = (items: IReport[]): IReport[] => {
        return items.reduce((acc: IReport[], i) => {
            i.format = i.valueType == ReportValueType.Total || i.valueType === ReportValueType.Heading || i.isBold ? 'Bold' : 'Normal'
            i.showAttachmentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            i.showCommentIcon = !i.children?.length || i.valueType === ReportValueType.GrandTotal
            if (i.additionsCost === 0) i.additionsCost = null;
            if (i.disposalsAccounting === 0) i.disposalsAccounting = null;
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
                i.children = rebuildReports(i.children);
            }

            acc.push(i);
            return acc;
        }, []);
    }

    const [tableItems, setTableItems] = useState<IReport[]>(rebuildReports(items));

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(rebuildReports(tableItems));
        }
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'investment',
                name: formatMessage({ id: 'investmentName' }),
                minWidth: 400,
                fieldName: 'investment',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === ReportValueType.Value && 
                            <GeneratedAnswer itemId={item?.id}
                                             tableType={item?.reportType}
                                             value={item?.answerText}
                                             onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)} 
                                             answerControlType={item?.answerControlType} 
                                             auto={item?.isAnswerDisabled} 
                                             disabled={item?.isAnswerDisabled}/>}
                    </Stack>
                )
            },
            {
                key: 'openingBalanceUnits',
                name: formatMessage({ id: 'obUnit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'openingBalanceUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0] || ''} isHeadingRow={item?.isHeadingRow} textAlign='right' numberFormat='text'/>,
            },
            {
                key: 'openingBalanceCost',
                name: formatMessage({ id: 'obCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'openingBalanceCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'additionsUnits',
                name: formatMessage({ id: 'additionsUnits' }),
                minWidth: 128,
                maxWidth: 160,
                fieldName: 'additionsUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0] || ''} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='text' />,
            },
            {
                key: 'additionsCost',
                name: formatMessage({ id: 'cost' }),
                minWidth: 80,
                maxWidth: 128,
                fieldName: 'additionsCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'disposalsUnits',
                name: formatMessage({ id: 'disposalUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalsUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0] || ''} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='text' />,
            },
            {
                key: 'disposalCost',
                name: formatMessage({ id: 'disposalCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'disposalsAccounting',
                name: formatMessage({ id: 'profitLoss' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'disposalsAccounting',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional'/>,
            },
            {
                key: 'closingBalanceUnits',
                name: formatMessage({ id: 'cbUnits' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBalanceUnits',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0] || ''} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='text' />,
            },
            {
                key: 'closingBalanceCost',
                name: formatMessage({ id: 'cbCost' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBalanceCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'closingBalanceMarketValue',
                name: formatMessage({ id: 'market' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingBalanceMarketValue',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='right' numberFormat='fractional' />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 128,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns}
                        items={tableItems}
                        groupProps={{
                            mergedGroupHeader: {propertyKey: 'investment'},
                            summary: {
                                show: true,
                                style: { paddingLeft: 0 },
                                childRenderer(group: IGroupWithSummary): React.ReactNode {
                                    const summary = group.summary;
                                    return <>
                                        {
                                            <Stack horizontal>
                                                <Stack.Item>
                                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                                </Stack.Item>
                                            </Stack>
                                        }
                                    </>
                                }
                            }
                        }}
                        isFetching={isFetching} 
                        expandProps={{ showExpander: false }}
                        openAll={true} />;
};
