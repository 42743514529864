import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {useProcedureContext, useSectionContext} from 'pages/JobPortal/components';
import {Stack, Text} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {ITableColumn, SanitizedText} from 'components';
import {AuditQueriesDataTable, AuditQueryPublishedIcon} from "./AuditQueries";


export const QuerySummaryProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const {updateSectionAnswer, enableWarningMessage} = useSectionContext();
    const {formatMessage} = useIntl();
    
    const columns = useMemo((): ITableColumn[] => [
        {
            key: 'number',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            fieldName: 'number',
            onRender: (item, idx, column) => (
                <Stack horizontalAlign={"center"} grow>
                    <Text>{item.isNonFundamental ? String.fromCharCode((idx ?? 0) + 97) : (idx ?? 0)  + 1}</Text>
                </Stack>
            )
        },
        {
            key: 'text',
            name: formatMessage({id: 'withholdingQueries'}),
            minWidth: 256,
            fieldName: 'text',
            onRender: (item, _, column) => (
                <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                    <SanitizedText data={item?.text}
                                   styles={{
                                       root: {
                                           maxWidth: '100%',
                                           overflow: 'hidden',
                                           textOverflow: 'ellypsis',
                                           ...((isPublished, isCleared) => {
                                               return isPublished && isCleared ? { textDecoration: 'line-through' } : {}
                                           })(item?.isPublished, !!(item?.cleared ?? item?.lastCleared ?? false))
                                       }
                                   }}/>
                </Stack>
            )
        },
        {
            key: 'published',
            name: formatMessage({id: 'published'}),
            minWidth: 128,
            fieldName: 'published',
            onRender: (item, _, column) => item?.isPublished && (
                <Stack horizontal horizontalAlign='center' grow={1}>
                    <AuditQueryPublishedIcon publishedCount={(item?.pushCount || 0)}></AuditQueryPublishedIcon>
                </Stack>
            )
        }
    ], [formatMessage])
    
    useEffect(() => {
        enableWarningMessage(false);
        
        if (!items) {
            return;
        }
        
        const nonFundamental = items.filter(x => !x.isNonFundamental);
        if (!nonFundamental.length) {
            return;
        }
        
        const cleared = nonFundamental.filter(x => x.cleared || x.lastCleared);
        updateSectionAnswer?.(cleared.length === nonFundamental.length);
        
    }, [items])
    
    return (
        
        <Stack grow={1} tokens={{childrenGap: 16}}>
            <Stack.Item>
                <AuditQueriesDataTable
                    items={(items ?? []).filter(x => !x.isNonFundamental)}
                    isLoading={isLoading}
                    columns={columns}
                />
            </Stack.Item>
            <Stack.Item>
                <AuditQueriesDataTable
                    items={(items ?? []).filter(x => x.isNonFundamental)}
                    isLoading={isLoading}
                    columns={((cols): ITableColumn[] =>
                        cols.reduce((acc: ITableColumn[], c) => {
                            acc.push({
                                ...c,
                                name: c.key === 'text' ? formatMessage({id: 'nonWithholdingQueries'}) : c.name
                            });
                            return acc;
                        }, []))(columns)}
                />
            </Stack.Item>
        </Stack>

    )
};