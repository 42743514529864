import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

import {ShortClientInfo} from "pages/WorkflowPortal/shared";

type GetClientByGuidsProps = {
    clientGuids: string[];
}


function useGetClientsByGuids(data: GetClientByGuidsProps) {
    const context = React.useContext(Context);
   

    const {data: dataClients, isFetching: isLoadingClients} = useQuery(
        ['getClientsByGuidsData'],
        () => axios.post<ShortClientInfo[]>(`${context?.config?.clientUrl}/api/1.0/Clients/byGuids`, data, commonHeaders()),
        {
            enabled: !!context?.config?.clientUrl && !!context.tenantId,

        }
    );

    return {dataClients, isLoadingClients};
}

export {useGetClientsByGuids};