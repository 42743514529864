import {useContext} from "react";
import {Context} from "../../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";
import {IRiskNote} from "../interfaces";

function useDeleteRiskNote(){
    const context = useContext(Context);
    const host = context?.config?.riskMatrixUrl;

    const {
        mutate: deleteNote,
        mutateAsync: deleteNoteAsync,
        isLoading: isDeleting
    } = useMutation((data: IRiskNote) => axios.delete(`${host}/api/1.0/jobs/${data.jobId}/riskMatrix/notes/${data.id}`));

    return {deleteNote, deleteNoteAsync, isDeleting};
}

export {useDeleteRiskNote};