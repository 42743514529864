import {ISectionInfo} from "../../../interfaces";
import {createContext, useContext} from "react";

export interface ISectionContext {
    section: ISectionInfo;
    open: () => void;
    close: () => void;
    fullScreen: () => void;
    isOpened: boolean;
    setApprovingDisable: (isDisabled: boolean) => void;
    refresh: Function;
    isPositiveSectionAnswer: boolean | null;
    forcePositiveSectionAnswer: (isPositive: boolean) => void;
    updateSectionAnswer: Function;
    updateSectionAnswerIcon: Function;
    hide: () => void;
    enableWarningMessage: (enable: boolean, force?: boolean) => void;
    isWarningMessageForced: boolean;
    isWarningMessageEnabled: boolean;
    refreshSectionAnswers?: (values: { manager: boolean; sendEmail: boolean; partner: boolean }, updateValues?: boolean) => void;
    sectionAnswersRefreshedTick: number;
    updateLoadedState: (state: boolean) => void;
    isLoaded: boolean;
    styles?: { container?: any };

    onItemAnswerChanged?: (newValue: string, itemId?: number) => void;
}


export const SectionContext = createContext<ISectionContext | undefined>(undefined);

export const useSectionContext = () => {
    let context = useContext(SectionContext);

    if (!context)
        throw new Error('No SectionContext.Provider found');

    return context;
}