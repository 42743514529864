import {
    forwardRef,
    FunctionComponent,
    MutableRefObject,
    PropsWithChildren,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    DefaultButton,
    DialogType,
    FontSizes,
    IconButton,
    IDropdownOption,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { CalendarAnswer, DropdownAnswer } from 'pages/JobPortal/components/templates/answers';
import { Attachment, PermanentCheckBox, ProceduresDataTable, useProcedureContext, useSectionContext } from 'pages/JobPortal/components';
import { ControlledTextField, DialogMessage } from 'components';
import { AnswerControlType, TableType } from '../../../../../enums';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings, FullWidth } from '../../../../../constants';
import {
    IReviewDates,
    useCreateReviewItem,
    useDeleteReviewItem,
    useGetReviewDates,
    useToggleApproveReviewItemState,
    useUpdateReviewDate,
    useUpdateReviewItem,
    useUpdateReviewItemAnswer,
} from '../../../hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { useJobContext } from '../../../JobPortalLayoutPage';
import { PortalRole, useWorkContext } from '../../../../../providers';
import { ReviewType } from '../../../enums';
import { RedirectButton } from '../shared';
import { useTabContext } from '../../../JobPortalPage';

interface IReviewItem {
    id: number;
    jobId: number;
    hasAttachments: boolean;
    answerControlType: AnswerControlType;
    tableType: TableType;
    refNo: string;
    item: string;
    staffComments: string;
    initials: string;
    showCheckBox: boolean;
    approved: boolean;
    answerText: string;
    tabID?: number;
    itemID?: number;
    sectionID?: number;
    subSectionID?: number;
}

export const ReviewItemProcedureTemplate: FunctionComponent<{ childRef: MutableRefObject<null> }> = ({ childRef }) => {
    const { section } = useSectionContext();
    const [isManagerSection] = useState(section.reference === 'Manager Review');

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <ReviewItemsTable reviewType={isManagerSection ? ReviewType.Manager : ReviewType.Partner} ref={childRef} />
            <ReviewDates reviewType={isManagerSection ? ReviewType.Manager : ReviewType.Partner} />
        </Stack>
    );
};

const ReviewItemsTable = forwardRef((props: PropsWithChildren<{ reviewType: ReviewType }>, ref) => {
    const { items, refresh, isLoading } = useProcedureContext();
    const { jobId } = useJobContext();
    const childRef = useRef<any>(ref);
    const theme = useTheme();

    const { workContext, isInRoleOrSuperAdministrator } = useWorkContext();

    const { palette } = useTheme();
    const { formatMessage } = useIntl();
    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    const { create, isCreating } = useCreateReviewItem();
    const { update, isUpdating } = useUpdateReviewItem();
    const { updateAnswer, isUpdating: isUpdatingAnswer } = useUpdateReviewItemAnswer();
    const { toggleApproveState, isProcessing } = useToggleApproveReviewItemState();
    const { deleteItem, isDeleting } = useDeleteReviewItem();

    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);
    const [reviewItems, setReviewItems] = useState<IReviewItem[]>(items);
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);
    const { control, setValue, getValues } = useForm<{
        qualificationItems: IReviewItem[];
    }>(DefaultFormSettings);

    const { fields, append, remove } = useFieldArray({
        name: 'qualificationItems',
        control: control,
    });

    const [answerOptions] = useState<IDropdownOption[]>([
        { key: '', text: '' },
        { key: 'Yes', text: 'Yes' },
        { key: 'No', text: 'No' },
    ]);

    const switchToEditMode = (index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    };

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    const deleteNote = useCallback(
        (index: number) => {
            const noteId = getValues(`qualificationItems.${index}.id`);
            setLastUpdatedIndex(index);

            deleteItem(
                {
                    id: noteId,
                },
                {
                    onSuccess: () => {
                        setReviewItems(reviewItems.splice(index, 1));
                        remove(index);
                        setEditingIndex(undefined);
                    },
                }
            );
        },
        [getValues, remove, reviewItems, deleteItem]
    );

    const changeApprovedState = (id: number, isPerm: boolean) => {
        toggleApproveState({
            id: id,
            approved: isPerm,
        });
    };

    const onUpdateAnswer = (id: number, newAnswer: string) => {
        updateAnswer(
            {
                id: id,
                answerText: newAnswer,
            },
            {
                onSuccess: () => refresh(),
            }
        );
    };

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const reviewItem = getValues(`qualificationItems.${editingIndex}`);
        const item = reviewItems.find((x) => x.id === reviewItem.id);

        if (
            (reviewItem.initials === item?.initials || (!reviewItem.initials && !item?.initials)) &&
            (reviewItem.staffComments === item?.staffComments || (!reviewItem.staffComments && !item?.staffComments)) &&
            (reviewItem.item === item?.item || (!reviewItem.item && !item?.item)) &&
            (reviewItem.refNo === item?.refNo || (!reviewItem.refNo && !item?.refNo))
        ) {
            return;
        }

        setLastUpdatedIndex(editingIndex);

        update(
            {
                id: reviewItem!.id,
                jobId: reviewItem!.jobId,
                item: reviewItem.item,
                staffComments: reviewItem.staffComments,
                refNo: reviewItem.refNo,
                reviewType: props.reviewType,
                initials: reviewItem.initials ?? '',
            },
            {
                onSuccess: () => {
                    if (!!reviewItem.answerText && reviewItem.answerText !== item?.answerText) {
                        updateAnswer({ id: reviewItem.id, answerText: reviewItem.answerText });
                    }
                    setReviewItems([...reviewItems.slice(0, editingIndex), { ...reviewItem }, ...reviewItems.slice(editingIndex + 1)]);
                },
            }
        );
    }, [editingIndex, getValues, reviewItems, update, updateAnswer, props.reviewType]);

    useImperativeHandle(
        ref,
        () => ({
            onAddClick() {
                create(
                    {
                        jobId: jobId,
                        reviewType: props.reviewType,
                    },
                    {
                        onSuccess: (data) => {
                            setReviewItems([...reviewItems, data.data]);
                            append(data.data);
                        },
                    }
                );
            },
        }),
        [reviewItems, props.reviewType, jobId, create, append]
    );

    useEffect(() => {
        setReviewItems(items);
        setValue('qualificationItems', items || []);
    }, [items, setValue]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('ReviewItems')) return;

            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState<boolean>(false);

    useEffect(() => {
        if (props.reviewType === ReviewType.Manager) {
            setIsDeleteButtonVisible(
                !!workContext?.isCurrentUserJobManager ||
                    !!workContext?.isCurrentUserJobPartner ||
                    isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.ReviewerUser)
            );
        } else if (props.reviewType === ReviewType.Partner) {
            setIsDeleteButtonVisible(!!workContext?.isCurrentUserJobPartner || isInRoleOrSuperAdministrator(PortalRole.Administrator));
        } else {
            setIsDeleteButtonVisible(true);
        }
    }, [
        workContext?.isCurrentUserJobPartner,
        workContext?.isCurrentUserJobManager,
        isInRoleOrSuperAdministrator,
        isUpdating,
        props.reviewType,
    ]);

    const [isClearControlEnabled, setIsClearControlEnabled] = useState<boolean>(isDeleteButtonVisible);

    useEffect(() => {
        setIsClearControlEnabled(isDeleteButtonVisible && !isUpdatingAnswer);
    }, [isDeleteButtonVisible, isUpdatingAnswer]);

    const [isApprovedControlEnabled, setIsApprovedControlEnabled] = useState<boolean>(isDeleteButtonVisible);
    useEffect(() => {
        setIsApprovedControlEnabled(isDeleteButtonVisible && !isProcessing);
    }, [isDeleteButtonVisible, isProcessing]);

    return (
        <div ref={childRef}>
            <ProceduresDataTable
                items={fields}
                isLoading={isLoading || isCreating}
                columns={[
                    {
                        key: 'action',
                        name: '',
                        maxWidth: 60,
                        minWidth: 60,
                        fieldName: 'action',
                        onRender: (item: IReviewItem) => (
                            <Stack horizontal>
                                <RedirectButton
                                    tabId={item.tabID}
                                    sectionId={item.sectionID}
                                    subSectionId={item.subSectionID}
                                    itemId={item.itemID}
                                />
                                <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                                {!!item?.tabID && <IconButton iconProps={{ iconName: 'RedEye', style: { fontSize: FontSizes.small } }} />}
                            </Stack>
                        ),
                    },
                    {
                        key: 'refNo',
                        name: formatMessage({ id: 'refNo' }),
                        minWidth: 100,
                        maxWidth: 100,
                        fieldName: 'refNo',
                        onRender: (item: IReviewItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'refNo'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.refNo`) : ''}
                            />
                        ),
                    },
                    {
                        key: 'item',
                        name: formatMessage({ id: 'item' }),
                        minWidth: 200,
                        fieldName: 'item',
                        onRender: (item: IReviewItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'item'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.item`) : ''}
                            />
                        ),
                    },
                    {
                        key: 'staffComments',
                        name: formatMessage({ id: 'staffComments' }),
                        minWidth: 130,
                        maxWidth: 130,
                        fieldName: 'staffComments',
                        onRender: (item: IReviewItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'staffComments'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.staffComments`) : ''}
                            />
                        ),
                    },
                    {
                        key: 'initials',
                        name: formatMessage({ id: 'initials' }),
                        minWidth: 130,
                        maxWidth: 130,
                        fieldName: 'initials',
                        onRender: (item: IReviewItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'initials'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.initials`) : ''}
                            />
                        ),
                    },
                    {
                        key: 'cleared',
                        name: formatMessage({ id: 'cleared' }),
                        minWidth: 130,
                        maxWidth: 130,

                        fieldName: 'cleared',
                        onRender: (item: IReviewItem, index, column) => (
                            <DropdownAnswer
                                options={answerOptions}
                                errorMessage={item.answerText === 'No' ? ' ' : undefined}
                                onChange={(newAnswer) =>
                                    onUpdateAnswer(index !== undefined ? getValues(`qualificationItems.${index}.id`) : item.id, newAnswer)
                                }
                                value={index !== undefined ? getValues(`qualificationItems.${index}.answerText`) : ''}
                                disabled={!isClearControlEnabled}
                            />
                        ),
                    },
                    {
                        key: 'approved',
                        name: formatMessage({ id: props.reviewType === ReviewType.Manager ? 'managerApproved' : 'partnerApproved' }),
                        minWidth: 130,
                        maxWidth: 130,
                        fieldName: 'approved',
                        onRender: (item: IReviewItem, index) =>
                            item.showCheckBox && (
                                <Stack grow horizontalAlign={'center'}>
                                    <PermanentCheckBox
                                        itemId={item.id}
                                        checked={item.approved}
                                        disabled={!isApprovedControlEnabled}
                                        onUpdate={(newValue) => {
                                            changeApprovedState(index !== undefined ? getValues(`qualificationItems.${index}.id`) : item.id, newValue)
                                        }}
                                        tableType={item.tableType}
                                    />
                                </Stack>
                            ),
                    },
                    {
                        key: 'result',
                        name: '',
                        minWidth: 70,
                        maxWidth: 70,
                        fieldName: 'result',
                        onRender: (item: IReviewItem, index) => (
                            <Stack grow={1} horizontal verticalAlign={'center'} horizontalAlign={'center'}>
                                {isDeleteButtonVisible ? (
                                    <>
                                        {(isDeleting || isUpdating) && lastUpdatedIndex === index && (
                                            <Stack.Item>
                                                <Spinner size={SpinnerSize.small} />
                                            </Stack.Item>
                                        )}
                                        {!isDeleting && editingIndex !== index && !showDeleteDialog && (
                                            <Stack.Item>
                                                <IconButton
                                                    onClick={() => {
                                                        setEditingIndex(index);
                                                        toggleDeleteDialog();
                                                    }}
                                                    iconProps={{ iconName: 'delete' }}
                                                    styles={{
                                                        icon: { color: theme.palette.red },
                                                        iconHovered: { color: theme.palette.redDark },
                                                    }}
                                                />
                                            </Stack.Item>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Stack>
                        ),
                    },
                ]}
            />

            <DialogMessage
                onClick={() => {
                    if (editingIndex !== undefined) {
                        toggleDeleteDialog();
                        deleteNote(editingIndex);
                    }
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setEditingIndex(undefined);
                }}
            />
        </div>
    );
});

const ReviewDates: FunctionComponent<{ reviewType: ReviewType }> = ({ reviewType }) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { job } = useJobContext();
    const { isTabEnabled } = useTabContext();
    const { isCurrentUserJobPartner, isCurrentUserJobManager, isCurrentUserJobAuditor, isInRoleOrSuperAdministrator } = useWorkContext();

    const { response, isLoading, refetchDates, isRefetching } = useGetReviewDates(job.id, reviewType);

    const { update, isUpdating } = useUpdateReviewDate();

    const { setValue, handleSubmit, watch } = useForm<IReviewDates>();
    const updateDates = useCallback((data: IReviewDates) => {
        update(
            {
                jobId: job.id,
                reviewType: reviewType,
                pointRaised: data.pointRaised ?? response?.data.pointRaised,
                reviewedDate: data.reviewedDate ?? response?.data.reviewedDate,
            },
            {
                onSuccess: () => {
                    refetchDates();
                },
            }
        );
    }, [response?.data])
    
    const [isPointsRaisedDateControlEnabled, setIsPointsRaisedDateControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        const isInitialValueSet = !!response?.data.pointRaised;
        
        if (reviewType === ReviewType.Manager) {
            setIsPointsRaisedDateControlEnabled((isCurrentUserJobManager || isInRoleOrSuperAdministrator(PortalRole.ReviewerUser)) && !isUpdating && !isInitialValueSet && isTabEnabled);
        } else if (reviewType === ReviewType.Partner) {
            setIsPointsRaisedDateControlEnabled(isCurrentUserJobPartner && !isUpdating && !isInitialValueSet);
        } else {
            setIsPointsRaisedDateControlEnabled(!isInitialValueSet && isTabEnabled);
        }
    }, [isCurrentUserJobPartner, isCurrentUserJobManager, isUpdating, reviewType, response?.data.pointRaised, isTabEnabled]);

    const [isPointsReviewedAuditorControlEnabled, setIsPointsReviewedAuditorControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        const isInitialValueSet = !!response?.data.reviewedDate;
        setIsPointsReviewedAuditorControlEnabled(isCurrentUserJobAuditor && !isUpdating && !isInitialValueSet && isTabEnabled);
    }, [isCurrentUserJobAuditor, isUpdating, response?.data.reviewedDate, isTabEnabled]);

    const columns = useMemo(() => {
        return [
            {
                key: 'pointsRaisedDate',
                name: '',
                fieldName: 'pointsRaisedDate',
                onRender: () => (
                    <Stack grow={1} horizontal verticalAlign='center'>
                        <Stack grow={1}>
                            {formatMessage({ id: reviewType === ReviewType.Manager ? 'mgrPointsRaisedDate' : 'pnrPointsRaisedDate' })}
                            <div>
                                {response?.data.pointRaised ? new Date(response?.data.pointRaised).toLocaleDateString('en-AU') : null}
                            </div>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Stack styles={{ root: { minWidth: 120 } }}>
                                <CalendarAnswer
                                    onSelectDate={(date?: Date | null) => setValue('pointRaised', date)}
                                    value={response?.data.pointRaised}
                                    disabled={!isPointsRaisedDateControlEnabled}
                                />
                            </Stack>
                            <DefaultButton
                                theme={theme.schemes?.default}
                                onClick={handleSubmit(updateDates)}
                                disabled={!isPointsRaisedDateControlEnabled}
                                text={formatMessage({ id: 'update' })}
                            />
                        </Stack>
                    </Stack>
                ),
                size: 1,
            },
            {
                key: 'pointsReviewedByAuditorDate',
                name: '',
                fieldName: 'pointsReviewedByAuditorDate',
                onRender: () => (
                    <Stack grow={1} horizontal verticalAlign='center'>
                        <Stack grow={1}>
                            {formatMessage({
                                id:
                                    reviewType === ReviewType.Manager
                                        ? 'mgrPointsReviewedByAuditorDate'
                                        : 'pnrPointsReviewedByAuditorDate',
                            })}
                            <div>
                                {response?.data.reviewedDate ? new Date(response?.data.reviewedDate).toLocaleDateString('en-AU') : null}
                            </div>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <Stack styles={{ root: { minWidth: 120 } }}>
                                <CalendarAnswer
                                    onSelectDate={(date?: Date | null) => setValue('reviewedDate', date)}
                                    value={response?.data.reviewedDate}
                                    disabled={!isPointsReviewedAuditorControlEnabled}
                                />
                            </Stack>
                            <DefaultButton
                                theme={theme.schemes?.default}
                                onClick={handleSubmit(updateDates)}
                                disabled={!isPointsReviewedAuditorControlEnabled}
                                text={formatMessage({ id: isUpdating ? 'loading' : 'update' })}
                            />
                        </Stack>
                    </Stack>
                ),
                size: 1,
            },
        ]
    }, [isPointsReviewedAuditorControlEnabled, isPointsRaisedDateControlEnabled, response?.data])
    
    return (
        <ProceduresDataTable
            items={[{}]}
            isLoading={isLoading || isRefetching}
            isHeaderVisible={false}
            columns={columns}
        />
    );
};

type ReviewItemCellProps = {
    editMode: boolean;
    label: string | null;
    control: Control<{ qualificationItems: IReviewItem[] }>;
    fieldName: 'refNo' | 'item' | 'staffComments' | 'initials' | 'answerText' | 'approved';
    onClick: () => void;
    disabled?: boolean;
    index?: number;
};
const ReviewItemCell = (props: ReviewItemCellProps) => {
    const classNames = mergeStyleSets({
        textFieldDisabled: {
            'input[disabled].ms-TextField-field': {
                color: 'inherit',
            },
        },
    });

    return (
        <Stack
            horizontal
            verticalAlign={'center'}
            onClick={props.onClick}
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: '100%', width: '100%' } }}>
            {!props.editMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100,
                        }}
                        onClick={(event) => {
                            props.onClick();
                            event.preventDefault();
                        }}></div>
                    <TextField styles={FullWidth} className={classNames.textFieldDisabled} disabled value={props.label || ''} />
                </>
            )}
            {props.editMode && props.index !== undefined && (
                <ControlledTextField
                    id={`ReviewItems-${props.index}-${props.fieldName}`}
                    autoFocus
                    control={props.control}
                    name={`qualificationItems.${props.index}.${props.fieldName}`}
                    disabled={props.disabled}
                    styles={FullWidth}
                />
            )}
        </Stack>
    );
};
