import React from 'react';
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";
import {useIntl} from "react-intl";
import {useNotifications} from "../../../../components/notifications";

type MutateJobData = {
    jobGuid: string;
    auditorId?: number | null;
    auditorTargetDate?: Date | null;
    auditorStartedDate?: Date | null;
    auditorReviewed?: Date | null;
    dateReceived?: Date | null;
}

function useUpdateJobGeneralInformation() {
    const context = React.useContext(Context);
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();

    const {mutate: updateJobGeneralInfo, isLoading: isInfoUpdating} = useMutation(
        (data: MutateJobData) =>
            axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobGuid}/generalInfo`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getJobsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Job general info'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateJobGeneralInfo, isInfoUpdating};
}

export {useUpdateJobGeneralInformation};