import axios, { AxiosError } from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {EntityStatus} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";


type MutateDataType = {
    status: EntityStatus;
    clientId: number;
};

function useUpdateClientsStatus(closeModal: () => void) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: updateClientStatus, isLoading: isLoadingClientsUpdateStatus} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/status?status=${data.status}`, {}, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getClientsByFilter');
                closeModal();
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Client status'})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {updateClientStatus, isLoadingClientsUpdateStatus};
}

export {useUpdateClientsStatus};
