import axios, {AxiosRequestConfig} from "axios";
import {RefreshTokenResponse} from "../hooks";
import * as localStorageService from '../services/localStorageService';
import {
    ACCESS_TOKEN_KEY, JOB_IDENTIFIER_HEADER, JOB_IDENTIFIER_KEY,
    REFRESH_TOKEN_KEY,
    TENANT_ID_HEADER,
    TENANT_ID_KEY,
    TIME_ZONE_HEADER,
    STORAGE_REFERENCES_KEY
} from "../constants/authorization";
import {useCommonErrorNotification, useNotifications} from "../components/notifications";
import {MessageBarType} from "@fluentui/react";
import {logger} from "../services";
import { Context, globalTimeZone } from "../utils";
import { useContext } from 'react';

export function useAxiosConfig() {
    const {showCommonError} = useCommonErrorNotification();
    const {showNotification} = useNotifications();
    const context = useContext(Context);

    function checkIfNotStorageUrl(url: string,): boolean {
        if(!localStorageService.contains(STORAGE_REFERENCES_KEY))
        {
            return true;
        }
        const storageReferences: string[] = localStorageService.getData(STORAGE_REFERENCES_KEY);
        if(storageReferences == undefined || storageReferences == null)
        {
            return true;
        }
        for (const reference of storageReferences!) {
            if (reference.trim() === "") {
                continue;
            }
    
            if (url.includes(reference)) {
                return false;
            }
        }
    
        return true;
    }
    
    const config = () => {
        axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                const accessToken: string = localStorageService.getData(ACCESS_TOKEN_KEY);
                const tenantId: string = localStorageService.getData(TENANT_ID_KEY);

                if(checkIfNotStorageUrl(config.url!))
                {
                    if (accessToken) {
                        config.headers!.Authorization = 'Bearer ' + accessToken;
                    }
                }

                if (tenantId) {
                    config.headers![TENANT_ID_HEADER] = String(tenantId);
                }

                config.headers!['Access-Control-Allow-Origin'] = '*';

                let timeZone: string = localStorageService.getData(TIME_ZONE_HEADER);
                if (!timeZone) {
                    timeZone = globalTimeZone;
                    localStorageService.setData(TIME_ZONE_HEADER, timeZone);

                }
                config.headers![TIME_ZONE_HEADER] = timeZone;

                const jobIdentifier: string = localStorageService.getData(JOB_IDENTIFIER_KEY);
                if (jobIdentifier) {
                    config.headers![JOB_IDENTIFIER_HEADER] = jobIdentifier;
                }
                
                return config;
            },
            error => Promise.reject(error));

        axios.interceptors.response.use(
            response => {

                if (response?.status == 201) {
                    logger.log(`Entity with id: ${response.data?.id} was created successfully`);
                } else if (response?.status == 204) {
                    logger.log(`Entity with id: ${response.data?.id} was updated successfully`);
                }

                return response;
            },
            async error => {

                if (error.response?.status === 401) {
                    const httpClientConfig = window.location.host.includes('localhost:44465') ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/identity/' : '/identity/' ?? context!.config!.identityUrl;
                    console.debug("[CLIENT::CONFIG]", httpClientConfig);
                    const refreshToken: string = localStorageService.getData(REFRESH_TOKEN_KEY);
                    localStorageService.removeData(ACCESS_TOKEN_KEY);
                    const data = await axios.get<RefreshTokenResponse>(`${httpClientConfig}api/1.0/Authorization/RefreshToken?refreshToken=${refreshToken}`);

                    if (data.data?.accessToken) {
                        localStorageService.setData(REFRESH_TOKEN_KEY, data.data.refreshToken);
                        localStorageService.setData(ACCESS_TOKEN_KEY, data.data.accessToken);

                        error.config.headers.Authorization = 'Bearer ' + data.data.accessToken;

                        return axios.request(error.config);
                    } else {
                        localStorageService.clear();
                        window.location.replace(`${httpClientConfig}Authentication/SignIn?redirectUrl=` + window.location.href);
                    }
                } else if (error.response?.status == 400) {
                    if (error.response.data?.code && error.response.data?.message?.length)
                        showNotification({
                            type: MessageBarType.error,
                            name: 'Error',
                            description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                        });
                } else if (error.response?.status == 404) {
                    showNotification({
                        type: MessageBarType.warning,
                        name: 'Not Found',
                        description: 'Requesting entity was not found'
                    });
                    logger.warn(`Entity was not found`);
                } else if (error.response?.status >= 500) {
                    showCommonError();
                }

                throw error;
            },
        );
    }

    return {config};
}


