import {FontSizes, Icon, IModalProps, PrimaryButton, Stack, Text, useTheme} from "@fluentui/react";
import {Modal} from "./Modal";
import DocViewer from "@cyntler/react-doc-viewer";
import {Loading} from "./Loading";
import {useGetFile} from "../hooks";
import {useIntl} from "react-intl";
import * as localStorageService from "../services/localStorageService";
import {ACCESS_TOKEN_KEY, TENANT_ID_KEY} from "../constants/authorization";

interface IFileViewerProps extends IModalProps {
    url: string;
    fileName: string;
}

export const FileViewer = ({url, fileName, ...otherProps}: IFileViewerProps) => {
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const {getFile} = useGetFile();
    const loadingComponent = () => {
        return (
            <Stack grow horizontalAlign='center' styles={{root: {padding: 16}}}>
                <Loading/>
            </Stack>
        )
    };

    const noRenderComponent = () => {
        return (
            <Stack styles={{root: {padding: 16}}} grow horizontalAlign='center' tokens={{childrenGap: 16}}>
                <Icon iconName={'FileBug'} styles={{root: {fontSize: FontSizes.size68}}}/>
                <Text variant={"large"}>{formatMessage({id: 'fileViewer_label_previewUnavailable'})}</Text>
                <PrimaryButton onClick={handleOnDownload} iconProps={{iconName: 'download'}}
                               text={formatMessage({id: 'fileViewer_button_download'})}/>
            </Stack>
        )
    };

    const handleOnDownload = () => {
        getFile({
            url: url,
            fileName: fileName
        });
    };


    const headers: Record<any, any> = {
        "Authorization": `Bearer ${localStorageService.getData(ACCESS_TOKEN_KEY)}`,
        'x-tenant-id': localStorageService.getData(TENANT_ID_KEY),
        'Access-Control-Allow-Origin': '*',
        'credentials': 'include'
    };

    return (
        <Modal title={fileName} {...otherProps}>
            <Stack grow>
                <DocViewer style={{background: theme.semanticColors.defaultStateBackground}}
                           documents={[{uri: url || '', fileType: fileName.split('.').pop(), fileName: fileName}]}
                           requestHeaders={headers}
                           prefetchMethod={'GET'}
                           theme={{
                               primary: theme.palette.themePrimary,
                               secondary: theme.semanticColors.defaultStateBackground,
                               textTertiary: theme.palette.black
                           }}
                           config={{
                               header: {disableFileName: true, disableHeader: true},
                               loadingRenderer: {overrideComponent: loadingComponent},
                               noRenderer: {overrideComponent: noRenderComponent},
                               pdfVerticalScrollByDefault:true,
                               pdfZoom: {defaultZoom: 0.7, zoomJump: 0.1}
                           }}/>
            </Stack>
        </Modal>
    )
};