import {IColumn, Stack} from '@fluentui/react';
import {SanitizedText} from 'components';
import {Attachment} from 'pages/JobPortal/components/Attachment';
import {JobComment} from 'pages/JobPortal/components/JobComment';
import {IReport} from 'pages/JobPortal/interfaces/IReportsInfo';
import * as React from 'react';
import { useCallback, useMemo, useState } from "react";
import {useIntl} from 'react-intl';
import {ReportTable} from "../Table/ReportTable";
import {GeneratedAnswer} from "../../../GeneratedAnswer";
import {GroupSummaryItem, IGroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {isBankRow} from "../ClassSuper";
import {isAgreedAnswer, isAutoAnswer} from "../../answers";
import {ReportTemplateTypeProps} from "../Types";
import {InvestmentIncomeCrsAnswer, ReportValidationCell} from "../shared";
import {ColumnInfo} from "../../../../enums";
import {useReportValidationsContext} from "../../../ReportContent";


export const SuperMateInvestmentSummary = ({items, isFetching}: ReportTemplateTypeProps) => {
    const {formatMessage} = useIntl();
    
    const calculateAnswersSummary = (reportItems: IReport[]): IGroupSummary => {
        let verifiedCoverage = 0;
        let incomeTotal = 0;

        reportItems.forEach((i: any) => {
            let isBankItem = isBankRow(i.type);
            let isTotal = i.valueType === 'Total';

            if (isBankItem) {
                if (isAgreedAnswer(i.answerText2) || isAutoAnswer(i.answerText2)) {
                    if (!isTotal && i.marketValue) {
                        verifiedCoverage += i.marketValue;
                    }
                }
                if (!isTotal && i.marketValue) {
                    incomeTotal += i.marketValue;
                }
            } else {
                if (!i.isBold) {
                    if (isAgreedAnswer(i.answerText) || isAutoAnswer(i.answerText)) {
                        if (!isTotal && i.marketValue) {
                            verifiedCoverage += i.marketValue;
                        }
                    }
                    if (!isTotal && i.marketValue) {
                        incomeTotal += i.marketValue;
                    }
                }
            }
        })

        return {
            manual: 0,
            system: 0,
            total: 0,
            verified: !!incomeTotal ? verifiedCoverage / incomeTotal : 0
        };
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {<Attachment itemId={item?.id} hasAttachments={item?.hasAttachments}/>}
                        {<JobComment itemId={item?.id} hasComments={item?.hasComments}/>}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 200,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format}/>,
            },
            {
                key: 'code',
                name: formatMessage({id: 'asx'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'code',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format}/>,
            },
            {
                key: 'reviewed1',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === 'Value' && !!item?.closingUnit && <GeneratedAnswer itemId={item?.id}
                                                                                                tableType={item?.reportType}
                                                                                                value={item?.answerText}
                                                                                                onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                                                                                auto={item?.isAuto}
                                                                                                disabled={item?.isAuto}
                                                                                                answerControlType={item?.answerControlType}/>}
                    </Stack>
                )
            },
            {
                key: 'closingUnit',
                name: formatMessage({id: 'units'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closingUnit',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'averageUnitCost',
                name: formatMessage({id: 'averageUnitCost'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'averageUnitCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'reviewed2',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText1',
                onRender: (item) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === 'Value' && <InvestmentIncomeCrsAnswer itemId={item?.id} crsInfo={item.crsInfo}
                                                                         tableType={item.reportType}
                                                                         value={item?.answerText1} auto={item?.isAuto1} disabled={item?.isAuto1}
                                                                         columnInfo={ColumnInfo.AnswerText1}/>}
                    </Stack>
                )
            },
            {
                key: 'marketPrice',
                name: formatMessage({id: 'mktPrice'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketPrice',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'actualCost',
                name: formatMessage({id: 'adjustedCost'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'actualCost',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'reviewed3',
                name: formatMessage({id: 'reviewed'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'answerText2',
                onRender: (item, _, column) => (
                    <Stack verticalAlign='center' grow>
                        {item?.valueType === 'Value' && <GeneratedAnswer itemId={item?.id}
                                                                         tableType={item?.reportType}
                                                                         value={item?.answerText2} auto={item?.isAuto2} disabled={item?.isAuto2}
                                                                         onUpdate={newValue => onAnswerUpdate(item, column?.fieldName, newValue)}
                                                                         answerControlType={item?.answerControlType} columnInfo={ColumnInfo.AnswerText2}/>}
                    </Stack>
                )
            },
            {
                key: 'marketValue',
                name: formatMessage({id: 'marketValue'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'marketValue',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'netMarket',
                name: formatMessage({id: 'gainLoss'}) + ' ($)',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'netMarket',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'gainLossPer',
                name: formatMessage({id: 'gainPercentage'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'gainLossPer',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'closePer',
                name: formatMessage({id: 'totalPercentage'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'closePer',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]}
                                                              isHeadingRow={item?.isHeadingRow || item?.isBold}
                                                              format={item?.format} textAlign='right'
                                                              numberFormat='fractional'/>,
            },
            {
                key: 'validation',
                name: formatMessage({id: 'validation'}),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );

    const mapItems = useCallback((initialItems: IReport[]) => {
        return (initialItems ?? []).reduce((acc: IReport[], i) => {
            if (i.children?.length > 0) {
                i.summary = calculateAnswersSummary(i.children);
            }
            acc.push(i)
            return acc;
        }, []);
    }, []);

    const [tableItems, setTableItems] = useState<IReport[]>(mapItems(items));

    const onAnswerUpdate = (item: IReport, field: string | undefined, value: string | undefined) => {
        const setAnswerValue = (reportItems: IReport[], itemId: number, field: string, value: string | undefined) => {
            (reportItems ?? []).forEach((i: IReport) => {
                if (i.id === itemId) {
                    i[field] = value === 'Yes' ? 'Agreed' : value === 'No' ? 'Not Agreed' : undefined;
                }

                if (i.children?.length > 0) {
                    setAnswerValue(i.children, itemId, field, value);
                }
            })
        }

        if (item.id && field) {
            setAnswerValue(tableItems, item.id, field, value);
            setTableItems(mapItems(tableItems));
        }
    }

    return <ReportTable
        initialColumns={columns}
        groupProps={{
            mergedGroupHeader: {propertyKey: 'name'},
            summary: {
                show: true, 
                childRenderer(group: IGroupWithSummary): React.ReactNode {
                    const summary = group.summary;
                    return <>
                        {
                            <Stack horizontal tokens={{childrenGap: 16}}>
                                <Stack.Item>
                                    <GroupSummaryItem value={summary.verified} type={'verified'}></GroupSummaryItem>
                                </Stack.Item>
                            </Stack>
                        }
                    </>
                }
            }
        }}
        openAll={true}
        items={tableItems}
        isFetching={isFetching}/>;
};
