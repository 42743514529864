import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {INavLink, Stack} from '@fluentui/react';
import {ModalWithForm, VerticalMenu} from 'components';
import {useBoolean} from '@fluentui/react-hooks';
import {JobInfo} from "pages/WorkflowPortal/shared";
import {useGetJobByGuid} from "../../hooks";
import {
    AuditProgramTab, ContraventionTab, DispatchTab,
    GeneralTab, InvoicingTab,
    ManagerTab,
    PartnerTab,
    PreARQueriesTab,
    QueryRatingTab,
    TimesheetTab
} from "./tabs";
import {TabRefProps} from "./props/tabProps";

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
    title?: string;
    guid?: string | null;
}

enum YearModalTabs {
    GENERAL,
    MANAGER,
    PARTNER,
    AUDIT_PROGRAM,
    QUERY_RATING,
    PRE_AR_QUERIES,
    DISPATCH,
    INVOICING,
    CONTRAVENTION,
    TIMESHEET,
}

export const JobModal: FunctionComponent<Props> = observer(({onDismiss, isOpen, title, guid}: Props) => {

    const childRef = useRef<TabRefProps>(null);
    const {job: getJob, refetch: refetchJob, isRefetching, isJobLoading} = useGetJobByGuid(guid || '');
    const [job, setJob] = useState<JobInfo>();
    const {formatMessage} = useIntl();
    const [isCollapsed, {toggle: toggleIsCollapsed}] = useBoolean(false);
    const [activeTab, setActiveTab] = useState<YearModalTabs>(YearModalTabs.GENERAL);

    const [links] = useState<INavLink[]>([
        {
            name: formatMessage({id: 'jobWorkflowTab'}),
            url: '',
            title: formatMessage({id: 'jobWorkflowTab'}),
            key: 'jobWorkflowTab',
            iconProps: {iconName: 'MapLayers'},
            links: [
                {
                    name: formatMessage({id: 'generalInformation'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.GENERAL],
                    iconProps: {iconName: 'Info'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.GENERAL);
                    },
                },
                {
                    name: formatMessage({id: 'manager'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.MANAGER],
                    iconProps: {iconName: 'ContactInfo'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.MANAGER);
                    },
                },
                {
                    name: formatMessage({id: 'partner'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.PARTNER],
                    iconProps: {iconName: 'ContactInfo'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.PARTNER);
                    },
                },
            ],
            isExpanded: true,
        },
        {
            name: formatMessage({id: 'auditProgramTab'}),
            url: '',
            key: YearModalTabs[YearModalTabs.AUDIT_PROGRAM],
            iconProps: {iconName: 'CloudImportExport'},
            onClick: () => {
                setActiveTab(YearModalTabs.AUDIT_PROGRAM);
            },
        },
        {
            name: formatMessage({id: 'queriesTab'}),
            url: '',
            iconProps: {iconName: 'AnalyticsQuery'},
            links: [
                {
                    name: formatMessage({id: 'queryRating'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.QUERY_RATING],

                    iconProps: {iconName: 'QueryList'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.QUERY_RATING);
                    },
                },
                {
                    name: formatMessage({id: 'preARQueries'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.PRE_AR_QUERIES],
                    iconProps: {iconName: 'QueryList'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.PRE_AR_QUERIES);
                    },
                },
            ],
            isExpanded: false,
        },
        {
            name: formatMessage({id: 'dispatchTab'}),
            url: '',
            iconProps: {iconName: 'ProductRelease'},
            links: [
                {
                    name: formatMessage({id: 'dispatch'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.DISPATCH],
                    iconProps: {iconName: 'KnowledgeArticle'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.DISPATCH);
                    },
                },
                {
                    name: formatMessage({id: 'invoicing'}),
                    url: '',
                    key: YearModalTabs[YearModalTabs.INVOICING],
                    iconProps: {iconName: 'M365InvoicingLogo'},
                    onClick: () => {
                        setActiveTab(YearModalTabs.INVOICING);
                    },
                },
            ],
            isExpanded: false,
        },
        {
            name: formatMessage({id: 'contravention'}),
            url: '',
            key: YearModalTabs[YearModalTabs.CONTRAVENTION],
            iconProps: {iconName: 'ReportDocument'},
            onClick: () => {
                setActiveTab(YearModalTabs.CONTRAVENTION);
            },
        },
        {
            name: formatMessage({id: 'timesheet'}),
            url: '',
            key: 'timesheet',
            iconProps: {iconName: 'Calendar'},
            onClick: () => {
                setActiveTab(YearModalTabs.TIMESHEET);
            },
        },
    ]);

    useEffect(() => {
        if (getJob?.data)
            setJob(getJob.data);

    }, [getJob]);

    const handleOnSubmit = () => {

        if (childRef?.current?.submitForm)
            childRef?.current?.submitForm();
    };

    const onClose = () => {
        setActiveTab(YearModalTabs.GENERAL);
        onDismiss();
    }

    return (
        <ModalWithForm isLoading={isJobLoading} onSubmit={handleOnSubmit} isOpen={isOpen} onDismiss={onClose}
                       title={title} minWidth={'80vw'}>

            <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {height: '100%', margin: '0 0 0 -16px'}}}>
                <VerticalMenu selectedKey={YearModalTabs[activeTab || YearModalTabs.GENERAL]} links={links}
                              isCollapsed={isCollapsed} toggleIsCollapsed={toggleIsCollapsed}/>
                <Stack
                    styles={{root: {width: `calc(100% - ${isCollapsed ? 44 : 200}px)`}}}
                    tokens={{childrenGap: 16}}>
                    <Stack.Item>
                        <>
                            {activeTab === YearModalTabs.GENERAL ? (
                                <GeneralTab ref={childRef} job={job} onSubmitted={onClose}
                                />
                            ) : activeTab === YearModalTabs.MANAGER ? (
                                <ManagerTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.PARTNER ? (
                                <PartnerTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.AUDIT_PROGRAM ? (
                                <AuditProgramTab ref={childRef} job={job} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.QUERY_RATING ? (
                                <QueryRatingTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.PRE_AR_QUERIES ? (
                                <PreARQueriesTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.DISPATCH ? (
                                <DispatchTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.INVOICING ? (
                                <InvoicingTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.CONTRAVENTION ? (
                                <ContraventionTab job={job} ref={childRef} onSubmitted={onClose}/>
                            ) : activeTab === YearModalTabs.TIMESHEET ? (
                                    <TimesheetTab job={job}/>
                                )
                                : ('')
                            }
                        </>
                    </Stack.Item>
                </Stack>
            </Stack>
        </ModalWithForm>
    );
});
