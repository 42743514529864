import {ChangeEvent, FunctionComponent, useCallback, useRef} from 'react';
import {Controller, Path} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {PrimaryButton, Stack, Text, useTheme} from '@fluentui/react';
import {IIconProps} from '@fluentui/react/lib/Icon';
import {useDropzone} from 'react-dropzone';

interface Props<FormValues> {
  rules?: Omit<RegisterOptions<FormValues, Path<FormValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  name: Path<FormValues>;
  control: Control<FormValues>;
  label?: string;
  iconProps?: IIconProps;
  accept?: string;
  preview?: boolean;
}

interface DropzoneProps {
  fieldName: string;
  onChange: (...event: any[]) => void;
  onBlur: (...event: any[]) => void;
  label?: string;
  iconProps?: IIconProps;
  accept?: string;
  preview?: boolean;
  value?: any;
  errorMessage?: string;
}

const FileNameLabel: FunctionComponent<{ file: any }> = ({ file }) => {
  const getFileName = (file: any) => file?.name || 'Unknown';
  return <Text>{getFileName(file)}</Text>;
};

export const Dropzone: FunctionComponent<DropzoneProps> = ({ fieldName, onChange, onBlur, label, iconProps, accept, preview, value, errorMessage }) => {
  const ref = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles);
  }, [onChange]);

  const {getRootProps, getInputProps} = useDropzone({ onDrop });

  const handleChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList?.length) return;
    onChange(fileList);
  };

  return (
    <Stack>
      <div {...getRootProps()}>
        <PrimaryButton iconProps={iconProps} styles={{ root: { width: '100%' } }} text={label} onClick={() => ref?.current?.click()} />
        <input
          onBlur={onBlur}
          name={fieldName}
          ref={ref}
          style={{ display: 'none' }}
          type='file'
          accept={accept}
          onChange={handleChangeFileInput}
          // onChange={(ev: ChangeEvent<HTMLInputElement>) => handleChangeFileInput(ev, onChange)}
          {...getInputProps()}
        />
      </div>
      
      {errorMessage && <Text styles={{ root: { color: theme.semanticColors.errorText, paddingTop: 5 } }}>{errorMessage}</Text>}

      {preview && value?.length ? (
        <Stack styles={{ root: { paddingTop: 5 } }} tokens={{ childrenGap: 5 }}>
          {value?.map((file: any, idx: number) => <FileNameLabel file={file} key={`${fieldName}-file-name-${idx}`} />)}
        </Stack>
      ) : null}
    </Stack>
  );
};

export function ControlledDropzone<FormValues>({ name, control, rules, label, iconProps, accept, preview }: Props<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, name: fieldName, value }, fieldState: { error } }) => (
        <Dropzone
          fieldName={fieldName}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          iconProps={iconProps}
          accept={accept}
          value={value}
          preview={preview}
          errorMessage={error?.message}
        />
      )}
    />
  );
}