import * as React from 'react';
import { createTheme, Theme } from '@fluentui/react';
import { generateTheme } from 'utils';

type CustomizationPaletePropsType = {
    primaryColor: string;
    textColor: string;
    setColorPalette: (theme: Theme) => void;
    isDarkTheme?: boolean;
};

export const CustomizationPalete = ({ primaryColor, textColor, setColorPalette, isDarkTheme }: CustomizationPaletePropsType) => {
    React.useEffect(() => {
        const theme = !isDarkTheme
            ? createTheme({
                  palette: generateTheme({ primaryColor, textColor, backgroundColor: '#ffffff', isDark: isDarkTheme }),
              })
            : createTheme({
                  palette: generateTheme({
                      primaryColor,
                      textColor: '#ffffff',
                      backgroundColor: '#454342',
                      isDark: isDarkTheme,
                  }),
              });
        setColorPalette(theme);
    }, [isDarkTheme, primaryColor, setColorPalette, textColor]);

    return <></>;
};
