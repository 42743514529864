import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    id: number;
    approved: boolean;
}

function useToggleApproveReviewItemState() {
    const context = useContext(Context);

    const {
        mutate: toggleApproveState,
        isLoading: isProcessing
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.proceduresUrl}/api/1.0/procedures/jobReview/${data.id}/changeApproval`, data));

    return {toggleApproveState, isProcessing};
}

export {useToggleApproveReviewItemState};