import { IColumn, SelectionMode, Stack } from '@fluentui/react';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useIntl } from 'react-intl';
import { formatDate, getGrouppedReports } from 'utils';
import { DataTable, SanitizedText } from 'components';
import { useMemo } from 'react';
import { Attachment, GeneratedAnswer, JobComment } from 'pages/JobPortal/components';
import {ReportTable} from "../Table/ReportTable";
import {ReportTemplateTypeProps} from "../Types";
import { AnswerControlType, TableType } from '../../../../../../enums';
import { ReportMoneyValueCell } from "../shared/cells";



export const GeneralLedger = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'groupLabel' }), [items]);
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 100,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'quantity',
                name: formatMessage({ id: 'quantity' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'quantity',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow} />,
            },
            {
                key: 'debit',
                name: formatMessage({ id: 'debit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'debit',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow} />,
            },
            {
                key: 'credit',
                name: formatMessage({ id: 'credit' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'credit',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow} />,
            },
            {
                key: 'balance',
                name: formatMessage({ id: 'balance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balance',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow} allowNull={true} />,
            },
            {
                key: 'balanceIndicator',
                name: formatMessage({ id: 'balanceIndicator' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balanceIndicator',
                onRender: (item, _, column) => !item?.answerControlType || AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType] != AnswerControlType.WorkDoneSwitch ?
                    <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} textAlign='center' />
                    : <Stack verticalAlign='center' grow>
                        <GeneratedAnswer itemId={item?.id}
                            tableType={TableType[item?.reportType as keyof typeof TableType]}
                            value={item?.answerText} auto={item?.isAnswerDisabled} disabled={item?.isAnswerDisabled}
                            answerControlType={AnswerControlType[item?.answerControlType as keyof typeof AnswerControlType]} />
                    </Stack>,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns}
                        groups={groups} 
                        items={reports} 
                        isFetching={isFetching}
                        expandProps={{showExpander: false}}
    />
};
