import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {ProceduresDataTable, useProcedureContext} from 'pages/JobPortal/components';
import {ControlledTextField, DialogMessage, SanitizedText} from 'components';
import {useJobContext} from "../../../JobPortalLayoutPage";
import {
    DialogType,
    IconButton,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme
} from "@fluentui/react";
import {useBoolean} from "@fluentui/react-hooks";
import {Control, useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings, FullWidth} from "../../../../../constants";
import {useCreateAuditQuery, useDeleteAuditQuery, useUpdateAuditQuery} from "../../../hooks";

interface IReviewQualificationItem {
    id: number;
    jobId: number;
    refNo: string;
    managerPartnerComments: string;
    staffComments: string;
    initials: string;
}

export const ReviewQualificationItemProcedureTemplate = forwardRef((props, ref) => {
    const theme = useTheme();
    const {formatMessage} = useIntl();

    const childRef = useRef<any>(ref);
    const {items, refresh, isLoading} = useProcedureContext();
    const {jobId} = useJobContext();

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({id: 'removingItemDialogTitle'}),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({id: 'removingItemDialogMessage'}),
    };

    const {deleteItem, isDeleting} = useDeleteAuditQuery();
    const {update, isUpdating} = useUpdateAuditQuery();
    const {create, isCreating} = useCreateAuditQuery();
    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);
    const [reviewItems, setReviewItems] = useState<IReviewQualificationItem[]>(items);

    const [showDeleteDialog, {toggle: toggleDeleteDialog}] = useBoolean(false);
    const {control, setValue, handleSubmit, getValues} = useForm<{qualificationItems: IReviewQualificationItem[]}>(DefaultFormSettings);

    const {fields, append, remove} = useFieldArray({
        name: 'qualificationItems',
        control: control
    });

    const switchToEditMode = (index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    };

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    const deleteNote = useCallback((index: number) => {
        const noteId = getValues(`qualificationItems.${index}.id`);
        setLastUpdatedIndex(index);

        deleteItem(
            {
                id: noteId,
            },
            {
                onSuccess: () => {
                    setReviewItems(reviewItems.splice(index, 1));
                    remove(index);
                    setEditingIndex(undefined);
                },
            }
        );
    }, [getValues, remove, reviewItems]);

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const reviewItem = getValues(`qualificationItems.${editingIndex}`);
        const item = reviewItems.find((x) => x.id === reviewItem.id);

        if (
            (reviewItem.initials === item?.initials || (!reviewItem.initials && !item?.initials)) &&
            (reviewItem.staffComments === item?.staffComments || (!reviewItem.staffComments && !item?.staffComments)) &&
            (reviewItem.managerPartnerComments === item?.managerPartnerComments || (!reviewItem.managerPartnerComments && !item?.managerPartnerComments))
        ) {
            return;
        }

        setLastUpdatedIndex(editingIndex);

        update(
            {
                id: reviewItem!.id,
                jobId: reviewItem!.jobId,
                initials: reviewItem.initials ?? '',
                staffComments: reviewItem.staffComments,
                managerPartnerComments: reviewItem.managerPartnerComments ?? ''
            },
            {
                onSuccess: () => {
                    setReviewItems([...reviewItems.slice(0, editingIndex), { ...reviewItem }, ...reviewItems.slice(editingIndex + 1)]);
                },
            }
        );
    }, [editingIndex, getValues, reviewItems]);

    useImperativeHandle(ref, () => ({
        onAddClick() {
            create({
                jobId: jobId
            }, {
                onSuccess: (data) => {
                    setReviewItems([...reviewItems, data.data]);
                    append(data.data);
                }
            });
        }
    }), [reviewItems]);

    useEffect(() => {
        setReviewItems(items);
        setValue('qualificationItems', items || []);
    }, [items]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('ReviewQualificationItems')) return;

            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={childRef}>
            <ProceduresDataTable
                items={fields}
                isLoading={isLoading || isCreating}
                columns={[
                    {
                        key: 'auditorComments',
                        name: formatMessage({ id: 'auditorComments' }),
                        fieldName: 'auditorComments',
                        onRender: (item: IReviewQualificationItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'staffComments'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.staffComments`) : ''}
                            />
                        ),
                        minWidth: 300,
                    },
                    {
                        key: 'initials',
                        name: formatMessage({ id: 'initials' }),
                        fieldName: 'initials',
                        onRender: (item: IReviewQualificationItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'initials'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.initials`) : ''}
                            />
                        ),
                        minWidth: 100,
                        maxWidth: 128,
                    },
                    {
                        key: 'managerPartnerComments',
                        name: formatMessage({ id: 'managerPartnerComments' }),
                        fieldName: 'managerPartnerComments',
                        onRender: (item: IReviewQualificationItem, index, column) => (
                            <ReviewItemCell
                                onClick={() => {
                                    if (index !== undefined && !isInEditMode(index, column?.name)) {
                                        handleOnAccept();
                                        switchToEditMode(index, column?.name);
                                    }
                                }}
                                editMode={index !== undefined && isInEditMode(index, column?.name)}
                                index={index}
                                fieldName={'managerPartnerComments'}
                                control={control}
                                disabled={isUpdating}
                                label={index !== undefined ? getValues(`qualificationItems.${index}.managerPartnerComments`) : ''}
                            />
                        ),
                        minWidth: 190,
                        maxWidth: 190,
                    },
                    {
                        key: 'action',
                        name: '',
                        fieldName: 'action',
                        onRender: (item: IReviewQualificationItem, index) => (
                            <Stack grow={1} horizontal verticalAlign={'center'} horizontalAlign={'center'}>
                                {(isDeleting || isUpdating) && lastUpdatedIndex === index && (
                                    <Stack.Item>
                                        <Spinner size={SpinnerSize.small} />
                                    </Stack.Item>
                                )}
                                {!isDeleting && editingIndex !== index && !showDeleteDialog && (
                                    <Stack.Item>
                                        <IconButton
                                            onClick={() => {
                                                setEditingIndex(index);
                                                toggleDeleteDialog();
                                            }}
                                            iconProps={{ iconName: 'delete' }}
                                            styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}

                                        />
                                    </Stack.Item>
                                )}
                            </Stack>
                        ),
                        minWidth: 70,
                        maxWidth: 70,
                    },
                ]}
            />
            <DialogMessage
                onClick={() => {
                    if (editingIndex !== undefined) {
                        toggleDeleteDialog();
                        deleteNote(editingIndex);
                    }
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setEditingIndex(undefined);
                }}
            />
        </div>
    );
});


type ReviewItemCellProps = {
    editMode: boolean;
    label: string | null;
    control: Control<{qualificationItems: IReviewQualificationItem[]}>;
    fieldName: "initials" | "staffComments" | "managerPartnerComments";
    onClick: () => void;
    disabled?: boolean;
    index?: number;
}

const ReviewItemCell = (props: ReviewItemCellProps) => {
    const theme = useTheme();

    const classNames = mergeStyleSets({
        textFieldDisabled: {
            'input[disabled].ms-TextField-field': {
                color: 'inherit'
            }
        }
    })
    
    return (
        <Stack
            horizontal
            verticalAlign={'center'}
            onClick={props.onClick}
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: '100%', width: '100%' } }}>
            {!props.editMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100,
                        }}
                        onClick={(event) => {
                            props.onClick();
                            event.preventDefault();
                        }}></div>
                    <TextField styles={FullWidth} className={classNames.textFieldDisabled} disabled value={props.label || ''} />
                </>
            )}
            {props.editMode && props.index !== undefined && (
                <ControlledTextField
                    id={`ReviewQualificationItems-${props.index}-${props.fieldName}`}
                    autoFocus
                    control={props.control}
                    name={`qualificationItems.${props.index}.${props.fieldName}`}
                    disabled={props.disabled}
                    styles={FullWidth}
                />
            )}
        </Stack>
    );
}