"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providerNativeFactory = void 0;
const react_1 = require("react");
const utils_1 = require("../../utils");
const utils_2 = require("../utils");
function providerNativeFactory(Context) {
    const Provider = ({ url, connectEnabled = true, children, dependencies = [], accessTokenFactory, onError, ...rest }) => {
        const onErrorRef = (0, utils_1.usePropRef)(onError);
        const accessTokenFactoryRef = (0, utils_1.usePropRef)(accessTokenFactory);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const clear = (0, react_1.useRef)(() => { });
        async function refreshConnection() {
            var _a;
            if (!connectEnabled) {
                return;
            }
            const connection = (0, utils_2.createConnection)(url, {
                extraHeaders: {
                    Authorization: (await ((_a = accessTokenFactoryRef.current) === null || _a === void 0 ? void 0 : _a.call(accessTokenFactoryRef))) || "",
                },
                ...rest,
            });
            Context.connection = connection;
            //@ts-ignore
            Context.reOn();
            async function checkForStart() {
                var _a;
                if (!(0, utils_2.isConnectionConnecting)(connection)) {
                    try {
                        await connection.open();
                    }
                    catch (err) {
                        console.log(err);
                        (_a = onErrorRef.current) === null || _a === void 0 ? void 0 : _a.call(onErrorRef, err);
                    }
                }
            }
            checkForStart();
            const checkInterval = setInterval(checkForStart, 6000);
            clear.current = () => {
                clearInterval(checkInterval);
                connection.disconnect();
            };
        }
        (0, react_1.useState)(() => {
            refreshConnection();
        });
        const isMounted = (0, react_1.useRef)(false);
        (0, react_1.useEffect)(() => {
            if (isMounted.current) {
                refreshConnection();
            }
            isMounted.current = true;
            return () => {
                clear.current();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connectEnabled, url, ...dependencies]);
        return children;
    };
    return Provider;
}
exports.providerNativeFactory = providerNativeFactory;
