import {EntityStatus} from "../../shared";
import React from "react";
import {commonHeaders, Context, queryClient} from "../../../../utils";
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {MessageBarType} from "@fluentui/react";

type MutateDataType = {
    teamId: number;
    status: EntityStatus;
}

function useChangeTeamsStatus(callback?: () => void) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: update, isLoading: isUpdating} = useMutation(
        (data: MutateDataType) =>
            axios.put(`${context?.config?.userUrl}/api/1.0/teams/${data.teamId}/changeStatus`, data, commonHeaders()),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries('getAllTeams');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: 'Team'})
                });
                
                if (callback)
                    callback();
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );
    return {update, isUpdating};
}

export {useChangeTeamsStatus};