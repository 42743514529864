import {Card, ControlledDatePicker, ControlledDropdown} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {DefaultFormSettings} from "constants/forms";
import React, {ForwardedRef, forwardRef, useImperativeHandle, useMemo} from "react";
import {TabProps, TabRefProps} from "../props/tabProps";
import {useGetEmployeesByPosition} from "pages/WorkflowPortal/Employees";
import {useUpdateJobPartnerInfo} from "../../../hooks";
import {EmployeePosition} from "pages/WorkflowPortal/shared";


interface IUpdateJobPartnerInfoCommand {
    partnerId: number;
    dateReceived?: Date | null;
    partnerReviewed?: Date | null;
    partnerStartedDate?: Date | null;
    partnerTargetDate?: Date | null;
}

export const PartnerTab = forwardRef((props: TabProps, ref: ForwardedRef<TabRefProps>) => {

    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {
        employeesByPosition: partners,
        isEmployeesLoading: isPartnersLoading
    } = useGetEmployeesByPosition(EmployeePosition.Partner);
    const {updateJobPartnerInfo, isPartnerInfoUpdating} = useUpdateJobPartnerInfo();
    const {control, handleSubmit} = useForm<IUpdateJobPartnerInfoCommand>({
        ...DefaultFormSettings,
        defaultValues: {
            partnerId: props.job?.partner?.id || 0,
            dateReceived: props.job?.dateReceived,
            partnerReviewed: props.job?.partner?.reviewed,
            partnerStartedDate: props.job?.partner?.pointsRaised,
            partnerTargetDate: props.job?.partner?.queriesReviewed
        }
    });

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onFormSubmit)();
        },
    }));

    const onFormSubmit = (data: IUpdateJobPartnerInfoCommand) => {

        updateJobPartnerInfo({
            jobGuid: props.job?.guid || '',
            partnerId: data.partnerId,
            partnerReviewed: data.partnerReviewed,
            partnerPointsRaised: data.partnerStartedDate,
            partnerQueriesReviewed: data.partnerTargetDate
        }, {
            onSuccess: () => {
                if (props.onSubmitted)
                    props.onSubmitted();
            }
        });

    }

    const partnerOptions = useMemo<IDropdownOption[]>(() => {
        return partners?.data.map((partner) => ({
            key: partner.id,
            text: `${partner.firstName} ${partner.lastName}`
        })) || Array.of<IDropdownOption>();
    }, [partners]);
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        name='partnerId'
                        label={formatMessage({id: 'partner'})}
                        options={partnerOptions || []}
                        control={control}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='partnerStartedDate'
                        label={formatMessage({id: 'pnrPointsRaised'})}
                        control={control}

                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='partnerTargetDate'
                        label={formatMessage({id: 'pnrPointsReviewedByAuditor'})}
                        control={control}

                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDatePicker
                        name='partnerReviewed'
                        label={formatMessage({id: 'reviewedByPartner'})}
                        control={control}
                    />
                </Stack.Item>
            </Stack>
        </Card>
    );
});