import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { IContextualMenuItem, Spinner, SpinnerSize, Stack, TextField, useTheme } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { Attachment, PermanentCheckBox, ProceduresDataTable, useProcedureContext, useSectionContext } from 'pages/JobPortal/components';
import { ContextMenuProvider, ControlledTextField, useContextMenu } from 'components';
import { FullWidth } from '../../../../../constants';
import { useCreateReviewItem, useTogglePermHinTableItem, useUpdateHinTableItem } from '../../../hooks';
import { TableType } from '../../../../../enums';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../constants/forms';
import { useJobContext } from '../../../JobPortalLayoutPage';
import { ReviewType } from '../../../enums';
import { useJobRedirects } from '../shared';
import { useTabContext } from 'pages/JobPortal';

interface IHintItem {
    id: number;
    itemId: number;
    jobId: number;
    fundId: number;
    hinNumber: string | null;
    postcode: string | null;
    notes: string | null;
    perm: boolean;
    tableType: TableType;
    hasAttachments: boolean;
    hasComments: boolean;
    isSimpleNote: boolean;
}

export const HINTableProcedureContent: FunctionComponent = () => {
    // Have to use a separate ContextMenyProvider since HINTable placed with another section which overrides HIN context menu.
    return (
        <ContextMenuProvider>
            <HINTableProcedureTemplate></HINTableProcedureTemplate>
        </ContextMenuProvider>
    );
};

export const HINTableProcedureTemplate: FunctionComponent = () => {
    const { items, refresh, isLoading } = useProcedureContext();
    const { section } = useSectionContext();
    const { fundId, jobId } = useJobContext();
    const { isTabEnabled } = useTabContext();
    const { formatMessage } = useIntl();

    const { setContextMenuItems, closeMenu } = useContextMenu();
    const { navigateToTableRow } = useJobRedirects();
    const { create: sendToReview, isCreating: isSending } = useCreateReviewItem();

    const { update, isUpdating } = useUpdateHinTableItem();
    const { update: togglePermState, isUpdating: isUpdatingToggleState } = useTogglePermHinTableItem(() => refresh());
    const { control, setValue, handleSubmit, getValues } = useForm<{ hinItems: IHintItem[] }>(DefaultFormSettings);

    const [hinItems, setHinItems] = useState<IHintItem[]>(items);
    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined);
    const [editingFieldName, setEditingFieldName] = useState<string | undefined>(undefined);
    const [lastUpdatedIndex, setLastUpdatedIndex] = useState<number | undefined>(undefined);

    const { fields } = useFieldArray({
        name: 'hinItems',
        control: control,
    });

    const sendItem = (item: any, reviewType: ReviewType) => {
        sendToReview(
            {
                itemId: item.itemId,
                jobId: jobId,
                sectionId: section.id,
                reviewType: reviewType,
            },
            {
                onSuccess: (res: any) => {
                    navigateToTableRow({ tabId: 9, sectionId: ReviewType.Manager ? 64 : 65, itemId: res.data.id });
                },
            }
        );
        closeMenu();
    };

    const [contextMenuItems] = useState<IContextualMenuItem[]>([
        {
            key: 'sendToManager',
            text: formatMessage({ id: 'sendToManager' }),
            iconProps: { iconName: 'Share' },
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Manager),
        },
        {
            key: 'sendToPartner',
            text: formatMessage({ id: 'sendToPartner' }),
            iconProps: { iconName: 'Share' },
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Partner),
        },
    ]);

    useEffect(() => {
        setContextMenuItems(contextMenuItems);
    }, [setContextMenuItems]);

    const switchToEditMode = (index: number, fieldName?: string) => {
        setEditingIndex(index);
        setEditingFieldName(fieldName);
    };

    const isInEditMode = useCallback(
        (index: number, fieldName?: string): boolean => {
            return editingIndex === index && editingFieldName === fieldName;
        },
        [editingIndex, editingFieldName]
    );

    // const deleteHinItem = useCallback((index: number) => {
    //     const noteId = getValues(`hinItems.${index}.id`);
    //     setLastUpdatedIndex(index);

    //     deleteItem(
    //         {
    //             id: noteId,
    //         },
    //         {
    //             onSuccess: () => {
    //                 setHinItems(hinItems.splice(index, 1));
    //                 remove(index);
    //                 setEditingIndex(undefined);
    //             },
    //         }
    //     );
    // }, [getValues, remove, hinItems]);

    const updatePermState = (item: IHintItem, newPermState: boolean) => {
        togglePermState({
            id: item.id,
            isPermanent: newPermState,
        });
    };

    const handleOnAccept = useCallback(() => {
        if (editingIndex === undefined) {
            return;
        }

        const hinItem = getValues(`hinItems.${editingIndex}`);
        const item = hinItems.find((x) => x.id === hinItem.id);

        if (
            (hinItem.hinNumber === item?.hinNumber || (!hinItem.hinNumber && !item?.hinNumber)) &&
            (hinItem.postcode === item?.postcode || (!hinItem.postcode && !item?.postcode)) &&
            (hinItem.notes === item?.notes || (!hinItem.notes && !item?.notes)) &&
            (hinItem.isSimpleNote === item?.isSimpleNote || (!hinItem.isSimpleNote && !item?.isSimpleNote)) &&
            (hinItem.perm === item?.perm || (!hinItem.perm && !item?.perm))
        ) {
            return;
        }

        setLastUpdatedIndex(editingIndex);

        update(
            {
                id: hinItem!.id,
                itemId: hinItem!.itemId,
                jobId: hinItem!.jobId,
                fundId: fundId,
                hinNumber: hinItem.hinNumber,
                postCode: hinItem.postcode,
                notes: hinItem.notes,
                perm: hinItem.perm,
                isSimpleNote: hinItem.isSimpleNote,
            },
            {
                onSuccess: (data) => {
                    if (!!data.data.id) {
                        hinItem.id = data.data.id;
                        setValue(`hinItems.${editingIndex}.id`, data.data.id);
                    }
                    setHinItems([...hinItems.slice(0, editingIndex), { ...hinItem }, ...hinItems.slice(editingIndex + 1)]);
                },
            }
        );
    }, [editingIndex, getValues, hinItems]);

    useEffect(() => {
        setHinItems(items);
        setValue('hinItems', items || []);
    }, [items]);

    const handleClickOutside = useCallback(
        (event: any) => {
            if ((event.srcElement.id as string).includes('HinItems')) return;

            handleOnAccept();
            setEditingIndex(undefined);
            setEditingFieldName(undefined);
        },
        [handleOnAccept]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <ProceduresDataTable
            items={fields}
            isLoading={isLoading}
            isHeaderVisible={true}
            navigationKeySelector={'itemId'}
            columns={[
                {
                    key: 'attachments',
                    name: '',
                    minWidth: 35,
                    maxWidth: 35,
                    fieldName: '',
                    onRender: (item: IHintItem) => (
                        <Stack horizontal>
                            <Attachment itemId={item?.itemId} hasAttachments={item?.hasAttachments} />
                        </Stack>
                    ),
                },
                {
                    key: 'actions',
                    name: '',
                    minWidth: 35,
                    maxWidth: 70,
                    fieldName: '',
                    onRender: (items: IHintItem, index) => (
                        <Stack styles={FullWidth} horizontalAlign={'center'}>
                            {isUpdating && lastUpdatedIndex === index && (
                                <Stack.Item>
                                    <Spinner size={SpinnerSize.small} />
                                </Stack.Item>
                            )}
                        </Stack>
                    ),
                },
                {
                    key: 'hin',
                    name: formatMessage({ id: 'hin' }),
                    minWidth: 150,
                    maxWidth: 150,
                    fieldName: 'hin',
                    onRender: (items: IHintItem, index, column) => (
                        <HintItemCell
                            onClick={() => {
                                if (index !== undefined && !isInEditMode(index, column?.name)) {
                                    handleOnAccept();
                                    switchToEditMode(index, column?.name);
                                }
                            }}
                            editMode={index !== undefined && isInEditMode(index, column?.name)}
                            index={index}
                            disabled={isUpdating || !isTabEnabled}
                            label={index !== undefined ? getValues(`hinItems.${index}.hinNumber`) : ''}
                            control={control}
                            fieldName={'hinNumber'}
                        />
                    ),
                },
                {
                    key: 'postcode',
                    name: formatMessage({ id: 'postcode' }),
                    minWidth: 150,
                    maxWidth: 150,
                    fieldName: 'postcode',
                    onRender: (items: IHintItem, index, column) => (
                        <HintItemCell
                            onClick={() => {
                                if (index !== undefined && !isInEditMode(index, column?.name)) {
                                    handleOnAccept();
                                    switchToEditMode(index, column?.name);
                                }
                            }}
                            editMode={index !== undefined && isInEditMode(index, column?.name)}
                            index={index}
                            disabled={isUpdating || !isTabEnabled}
                            label={index !== undefined ? getValues(`hinItems.${index}.postcode`) : ''}
                            control={control}
                            fieldName={'postcode'}
                        />
                    ),
                },
                {
                    key: 'notes',
                    name: formatMessage({ id: 'notes' }),
                    minWidth: 200,
                    fieldName: 'notes',
                    onRender: (items: IHintItem, index, column) => (
                        <HintItemCell
                            onClick={() => {
                                if (index !== undefined && !isInEditMode(index, column?.name)) {
                                    handleOnAccept();
                                    switchToEditMode(index, column?.name);
                                }
                            }}
                            editMode={index !== undefined && isInEditMode(index, column?.name)}
                            index={index}
                            control={control}
                            disabled={isUpdating || !isTabEnabled}
                            fieldName={'notes'}
                            label={index !== undefined ? getValues(`hinItems.${index}.notes`) : ''}
                        />
                    ),
                },
                {
                    key: 'permanent',
                    name: formatMessage({ id: 'permanent' }),
                    minWidth: 80,
                    maxWidth: 80,
                    fieldName: 'permanent',
                    onRender: (item: IHintItem, index) => (
                        <Stack verticalAlign='center' horizontalAlign='center' grow={1}>
                            <PermanentCheckBox
                                disabled={isUpdating}
                                checked={item.perm}
                                onUpdate={(newValue) => {
                                    updatePermState(index !== undefined ? getValues(`hinItems.${index}`) : item, newValue);
                                }}
                                tableType={item.tableType}
                                itemId={item.id}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    );
};

type HinItemCellProps = {
    editMode: boolean;
    label: string | null;
    control: Control<{ hinItems: IHintItem[] }>;
    fieldName: 'postcode' | 'hinNumber' | 'notes';
    onClick: () => void;
    disabled?: boolean;
    index?: number;
};
const HintItemCell = (props: HinItemCellProps) => {
    const theme = useTheme();

    return (
        <Stack
            horizontal
            verticalAlign={'center'}
            onClick={props.onClick}
            tokens={{ childrenGap: 16 }}
            styles={{ root: { height: '100%', width: '100%' } }}>
            {!props.editMode && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            zIndex: 100,
                        }}
                        onClick={(event) => {
                            props.onClick();
                            event.preventDefault();
                        }}></div>
                    <TextField
                        styles={{ ...FullWidth, field: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                        disabled
                        value={props.label || ''}
                    />
                </>
            )}
            {props.editMode && props.index !== undefined && (
                <ControlledTextField
                    id={`HinItems-${props.index}-${props.fieldName}`}
                    autoFocus
                    control={props.control}
                    name={`hinItems.${props.index}.${props.fieldName}`}
                    disabled={props.disabled}
                    styles={FullWidth}
                />
            )}
        </Stack>
    );
};
