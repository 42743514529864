import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {EmployeeInfo, EntityStatus, FilteredResult, IPaginationFilter} from "pages/WorkflowPortal/shared";
import {MessageBarType} from "@fluentui/react";
import {useNotifications} from "../../../../components/notifications";


export interface IEmployeePaginationFilter extends IPaginationFilter {
    status?: EntityStatus | null;
    searchString?: string;
}

function useGetEmployeesByFilter(filter: IEmployeePaginationFilter) {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();

    const {data: dataEmployeesFilter, isLoading: isLoadingEmployeesFilter} = useQuery(
        ['getEmployeesByFilter', filter],
        () =>
            axios.get<FilteredResult<EmployeeInfo>>(
                `${context?.config?.userUrl}/api/1.0/Employee/filter`, {...commonHeaders(), params: filter}),
        {
            enabled: !!context?.config?.userUrl,
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }
        }
    );

    return {dataEmployeesFilter, isLoadingEmployeesFilter};
}

function useGetAllEmployees() {
    const context = React.useContext(Context);

    const {data: allEmployees, isLoading: isAllEmployeesLoading} = useQuery(
        ['getAllEmployees'],
        () => axios.get<FilteredResult<EmployeeInfo>>(`${context?.config?.userUrl}/api/1.0/Employee/filter`, {
            params: {
                isPaginationEnabled: false,
                status: EntityStatus.Active
            }, ...commonHeaders()
        }),
        {
            enabled: !!context?.config?.userUrl,
        }
    );

    return {allEmployees, isAllEmployeesLoading};
}

export {useGetEmployeesByFilter, useGetAllEmployees};
