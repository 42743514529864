import React from "react";
import {commonHeaders, Context, queryClient} from "utils";
import {useMutation} from "react-query";
import axios, {AxiosError} from "axios";
import {EmployeeInfo} from "../../shared";
import {EmployeePosition, EmployeeRole} from "pages/WorkflowPortal/shared";
import {useWorkflows} from "../../WorkflowLayoutPage";
import {useNotifications} from "../../../../components/notifications";
import {MessageBarType} from "@fluentui/react";
import {NotificationAction} from "../../../../components/notifications/NotificationAction";
import {useIntl} from "react-intl";

type MutateDataType = {
    firstName: string;
    lastName: string;
    email: string;
    teamId: number | null;
    position: EmployeePosition;
    role: EmployeeRole;
}

function useCreateEmployee() {
    const context = React.useContext(Context);
    const {showEmployeeModal} = useWorkflows();
    const {formatMessage} = useIntl();
    const {showNotification} = useNotifications();
    const {mutate: create, isLoading: isCreating} = useMutation(
            (data: MutateDataType) =>
                axios.post<EmployeeInfo>(`${context?.config?.userUrl}/api/1.0/Employee`, data, commonHeaders()),
            {
                onSuccess: (data, variables, context) => {
                    queryClient.invalidateQueries('getEmployeesByFilter');
                    showNotification({
                        type: MessageBarType.success,
                        name: formatMessage({id: 'createdSuccessfully'}),
                        description: formatMessage({id: "entityWasCreated"}, {
                            name: `${variables.firstName} ${variables.lastName}`
                        }),
                        actions:
                            (
                                <NotificationAction onClick={() => {
                                    console.debug('Create employee id: ' + data.data.id);
                                    showEmployeeModal(data.data.id);
                                }}>
                                    {
                                        formatMessage({id: 'view'})
                                    }
                                </NotificationAction>
                            )
                    });
                },
                onError: (error: AxiosError) => {
                    showNotification({
                        type: MessageBarType.error,
                        name: 'Error',
                        description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                    })
                }
            }
        )
    ;

    return {create, isCreating};
}

export {useCreateEmployee};