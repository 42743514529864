import {IJobSuggestedTestingItem} from "../../../../hooks";
import {IPalette} from "@fluentui/react";

export const calculateSuggestedTestingLabelColor = (item: IJobSuggestedTestingItem, palette: IPalette): string => {
    if (item.showFeed && (item.feed === 'Not Agreed' || item.feed === 'No')) {
        return palette.red;
    }

    const keyMap: { [key: string]: string } = {
        'showFeed': 'feed',
        'showBalRec': 'balRec',
        'showFundSuspense': 'fundSuspense',
        'showGlatr': 'glatr'
    }

    const keys = Object.keys(item).filter(k => Object.keys(keyMap).includes(k) && item[k as keyof IJobSuggestedTestingItem])

    const values = keys.map(k => item[keyMap[k] as keyof IJobSuggestedTestingItem])
    const isAllPositive = values.length && values.every(v => ['Agreed', 'Yes'].includes(v as string))

    return isAllPositive ? palette.themePrimary : 'inherit';
}