import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    jobGuid: string;
}

function useSubmitJob() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.put(`${context?.config?.jobUrl}/api/1.0/job/${data.jobGuid}/submit`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getCurrentJobs'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useSubmitJob};