import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IStatus, StatusType} from "../interfaces";

function useGetStatus(clientIdentifier: string) {
    const context = useContext(Context);
    const {
        data: statusInfo,
        isLoading: isStatusInfoLoading,
        refetch
    } = useQuery(['getStatus', clientIdentifier],
        () => axios.get<IStatus>(`${context?.config?.billingUrl}/api/1.0/status/current/${clientIdentifier}`, {
            ...commonHeaders()
        })
            .then(resp => {
                const status = resp.data;
                
                if (status.externalServiceStatus.status === StatusType.Expired && !!status.externalServiceStatus.message) {
                    window.location.href = status.externalServiceStatus.message;
                }
            }),
        {
            enabled: !!context?.config?.billingUrl
        }
    );

    return {statusInfo, isStatusInfoLoading, refetch};
}

export {useGetStatus};