import axios from 'axios';
import {DashboardTabsEnum} from '../enums';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';

interface IFundInfo {
    id: number;
    name: string;
    idGuid: string;
}

interface Props {
    dashboardTab: DashboardTabsEnum;
    clientId?: string;
    clientGuid?: string;
    contactGuid?: string;
}

function useGetShortFundInfos({clientId, dashboardTab, clientGuid, contactGuid}: Props) {
    const context = React.useContext(Context);

    const { data: shortFundInfos, isLoading: isShortFundInfosLoading } = useQuery(
        ['getShortFundInfos', clientId, dashboardTab, clientGuid, contactGuid],
        () => axios.get<IFundInfo[]>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/shortFundInfos`, {
            ...commonHeaders(),
            params: { clientId, dashboardTab, clientGuid, contactGuid }
        })
            .then(response => response.data.filter(x => !!x.name).map(d => ({ key: d.idGuid, text: d.name }))),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {shortFundInfos, isShortFundInfosLoading};
}

export {useGetShortFundInfos};