import {useContext} from "react";
import {commonHeaders, Context} from "../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {IClientInfo} from "../interfaces";

function useGetClientInfo(clientIdentifier: string) {
    const context = useContext(Context);
    const {
        data: clientInfo,
        isLoading: isClientInfoLoading,
        refetch
    } = useQuery(['getClientInfo', clientIdentifier],
        () => axios.get<IClientInfo>(`${context?.config?.clientUrl}/api/1.0/clients/${clientIdentifier}`, {
            ...commonHeaders()
        })
            .then(resp => resp.data), 
        {
            enabled: !!context?.config?.clientUrl
        }
    );

    return {clientInfo, isClientInfoLoading, refetch};
}

export {useGetClientInfo};