import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';

export type JobYear = {
    isSelected: boolean;
    year: number;
};

type GeneralYearRes = JobYear[];

/**Query for getting Years for Portal Settings*/

function useGeneralYearGet() {
    const context = React.useContext(Context);

    const { data: generalYears, isLoading } = useQuery(
        ['getGeneralYear'],
        () => axios.get<GeneralYearRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/jobYears`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { generalYears, isLoading };
}

export { useGeneralYearGet };
