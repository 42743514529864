import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import {useJobContext} from "../../../../../JobPortalLayoutPage";

type TabSection = { id: number, reference: string, sectionAnswer?: TabSectionApproval }
type TabSectionApproval = { partnerApproval?: boolean };

function useGetAclSectionAnswer() {
    const context = useContext(Context);
    const {job, jobTabs} = useJobContext();

    const tabId = jobTabs.find(x => x.reference === 'Contravention Report')?.id;
    
    const {
        data: approval,
        isLoading,
        refetch, isRefetching
    } = useQuery(['getAclSectionAnswer', job.id, job.templateId],
        () => axios.get<TabSection[]>(`${context?.config?.tabsUrl}/api/1.0/Tabs/${tabId}`, {
            ...commonHeaders(),
            params: {
                jobId: job.id,
                templateId: job.templateId
            }
        })
            .then(resp => resp.data ?? [])
            .then(sections => {
                const aclSection = sections.find(x => x.reference === 'Audit Completion Letter')
                return aclSection?.sectionAnswer;
            }),
        {
            enabled: !!context?.config?.tabsUrl && !!tabId
        }
    );

    return {approval, isLoading, refetch};
}

export {useGetAclSectionAnswer};