import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BusinessAuthInfo, useGetExternalClientInfo } from '../../hooks/useGetExternalClientInfo';
import {
    DefaultButton,
    IColumn,
    Icon,
    IDropdownOption,
    Link,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { Card, ControlledDropdown, ControlledTextField, DataTable, Modal } from '../../../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { useSendAuth } from '../../hooks/useSendAuth';
import { useNotifications } from '../../../../../../components/notifications';
import { SoftwareType } from '../../../../../../enums';

type ClientAuthorizationTemplateProps = {
    clientGuid: string;
};

type BusinessAuthInfoTableItem = BusinessAuthInfo & { onClick: (item: BusinessAuthInfo) => void, titleId: string }

export const ClientAuthorizationTemplate: FunctionComponent<ClientAuthorizationTemplateProps> = ({ clientGuid, ...props }: ClientAuthorizationTemplateProps) => {
    const { formatMessage } = useIntl();
    const { palette } = useTheme();
    
    const { info, isLoading } = useGetExternalClientInfo(clientGuid);
    
    const [items, setItems] = useState<BusinessAuthInfoTableItem[]>([]);
    const [sendItem, setSendItem] = useState<BusinessAuthInfo | null>(null);
    const [isEmailModalShown, { toggle: toggleIsEmailModalShown }, ] = useBoolean(false);

    const businessCodes = useMemo(() => {
        // @ts-ignore
        return [...new Set(items.filter(x => x.businessCode !== '').map(x => x.businessCode))]
    }, [items]);

    const onAuthorizeClicked = (item: BusinessAuthInfo) => {
        if (item && !!item.link) {
            window.location.href = item.link;
        }
    }
    
    const onEmailItemClicked = (item: BusinessAuthInfo) => {
        setSendItem(prev => item);
        toggleIsEmailModalShown();
    }
    
    const onModalClose = () => {
        setSendItem(prev => null)
        toggleIsEmailModalShown()
    }
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'softwareTypeName',
                name: '',
                minWidth: 100,
                maxWidth: 150,
                fieldName: 'actions',
                onRender: (item: BusinessAuthInfo, index, column) =>  index !== undefined && !!items && (index === 0 || item.softwareType != items[index - 1].softwareType) && (
                    <Text>{item.softwareTypeName}</Text>
                ),
            },
            {
                key: 'businessCode',
                name: '',
                minWidth: 100,
                fieldName: 'actions',
                onRender: (item: BusinessAuthInfoTableItem, _, column) => (
                    <Link onClick={() => item.onClick(item)}>
                        <FormattedMessage id={item.titleId}
                                          values={{businessCode: item.businessCode}}
                        />
                    </Link>
                ),
            },
            {
                key: 'isAuthorized',
                name: '',
                minWidth: 64,
                maxWidth: 64,
                fieldName: 'validation',
                onRender: (item: BusinessAuthInfo, _, column) => item.isAuthenticated && (
                    <Stack horizontalAlign='center' verticalAlign='center' >
                        <Icon iconName='CheckMark' styles={{root: { color: palette.green }}} />
                    </Stack>
                ),
            },
        ],
        [formatMessage, items]
    );

    useEffect(() => {
        if (info) {
            const items: BusinessAuthInfoTableItem[] = [...info.businessAuthInfo.map(i => ({...i, onClick: onAuthorizeClicked, titleId: 'externalClientAuthorize{businessCode}' }))];
            
            // @ts-ignore
            items.push(...[...new Set(info.businessAuthInfo.length ? info.businessAuthInfo.map(i => i.softwareType) : [SoftwareType.ClassSuper, SoftwareType.BGL360])]
                .map(st => {
                    const stName = items.length ? items.find(x => x.softwareType === st)!.softwareTypeName : ((softwareType: SoftwareType) => SoftwareType[softwareType])(st);
                    return {
                        softwareType: st === SoftwareType.ClassSuper ? 1 : st === SoftwareType.BGL360 ? 5 : st,
                        softwareTypeName: stName,
                        businessCode: '',
                        link: '',
                        isAuthenticated: false,
                        onClick: onEmailItemClicked,
                        titleId: 'externalClientAuthorize{email}'
                    } as BusinessAuthInfoTableItem
                }))

            setItems(prev => items.sort((a, b) => +a.softwareType - +b.softwareType))
        }
    }, [info]);
    
    if (isLoading) {
        return (
            <Spinner></Spinner>
        )
    }
    
    return (
        <>
            <DataTable initialColumns={columns}
                       columns={columns}
                       items={items}
                       hideIfEmpty={false}
                       disableDragDrop={true}
                       selectionMode={SelectionMode.none}
                       isHeaderVisible={false}
                       containerHeight='100%'
            />

            <Modal isOpen={isEmailModalShown}
                   width={300}
                   title={formatMessage({id: 'sendAuthorisation'})}
                   onDismiss={onModalClose}>
                <SendAuthorisationForm item={sendItem!} clientGuid={clientGuid} businessCodes={businessCodes} onClose={onModalClose} onSubmit={onModalClose}>
                </SendAuthorisationForm>
            </Modal>
        </>
    );
};


type SendAuthorisationFormProps = {
    businessCodes: string[];
    item: BusinessAuthInfo;
    onClose: () => void;
    onSubmit?: () => void;
    clientGuid: string
}

type SendAuthorisationFormItem = { businessCode: string, name: string, email: string }

export const SendAuthorisationForm: FunctionComponent<SendAuthorisationFormProps> = ({ item, clientGuid, businessCodes, onClose, ...props }: SendAuthorisationFormProps) => {
    const { formatMessage } = useIntl();
    
    const { send, isSending } = useSendAuth(clientGuid);
    const { showNotification } = useNotifications();

    const [showBusinessCodeField, setShowBusinessCodeField] = useState(!!businessCodes.length);
    
    const { control, handleSubmit, formState } = useForm<SendAuthorisationFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            businessCode: businessCodes.length > 0 ? businessCodes[0] : '',
            name: '',
            email: ''
        }
    });

    const options: IDropdownOption[] = useMemo<IDropdownOption[]>(
        () => [
            ...businessCodes.map((bc) => ({
                key: bc,
                text: bc,
            })),
        ],
        [formatMessage, businessCodes]
    );
    
    const onSubmit = (form: SendAuthorisationFormItem) => {
        send({
            softwareType: +item.softwareType,
            name: form.name,
            email: form.email,
            businessCode: form.businessCode
        }, {
            onSuccess: () => {
                showNotification({ 
                    type: MessageBarType.success,
                    name: formatMessage({id: 'success'}),
                    description: formatMessage({id: 'authSent'})
                })
                props.onSubmit?.()
            }
        })
    }
    
    const fieldWidth = 'calc(100%)';
    
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <Card styles={{ root: { padding: '0 16px 20px 16px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                        {showBusinessCodeField && <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledDropdown
                                name='businessCode'
                                control={control}
                                label={formatMessage({ id: 'businessCode' })}
                                options={options}
                                rules={{ required: formatMessage({ id: 'requiredBusinessCode' }) }}
                            />
                        </Stack.Item>}
                        <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledTextField
                                name='email'
                                control={control}
                                rules={{ required: formatMessage({ id: 'requiredEmail' }) }}
                                label={formatMessage({ id: 'email' })}
                            />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: fieldWidth } }}>
                            <ControlledTextField
                                name='name'
                                control={control}
                                rules={{ required: formatMessage({ id: 'requiredName' }) }}
                                label={formatMessage({ id: 'name' })}
                            />
                        </Stack.Item>
                        
                    </Stack>
                </Card>
            </Stack.Item>

            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty || isSending}
                                       onClick={handleSubmit(onSubmit)} >
                            {isSending
                                ? <Spinner size={SpinnerSize.small}></Spinner>
                                : formatMessage({id: 'save'})}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton text={formatMessage({id: 'close'})} onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};