import {CustomProcedureRowRender} from "../../CustomProcedureTemplates";
import {IDetailsRowProps, Stack} from "@fluentui/react";
import React, {createElement, FunctionComponent, ReactElement} from "react";
import {ITableColumn} from "../../../../../../../components";
import {ColumnInfo} from "../../../../../enums";
import {GeneratedAnswer} from "../../../../GeneratedAnswer";
import {getFlatProcedures} from "../../../../../../../utils";
import {PermanentCheckBox} from "../../../../PermanentCheckBox";

interface ITrusteeMemberProcedureProps {
    procedure: any,
    disabled: boolean
}

const TrusteeMemberProcedureResultTemplate: FunctionComponent<ITrusteeMemberProcedureProps> = ({
                                                                                               procedure, disabled,
                                                                                           }: ITrusteeMemberProcedureProps) => {
    return (
        <Stack verticalAlign='center' grow>
            <GeneratedAnswer columnInfo={ColumnInfo.Text} itemId={procedure.id} tableType={procedure.tableType}
                             value={procedure.answerText} auto={procedure.isAnswerDisabled}
                             disabled={procedure.isAnswerDisabled || disabled} answerControlType={procedure?.answerControlType}/>
        </Stack>
    )
}

const TrusteeMemberProcedurePermanentTemplate: FunctionComponent<ITrusteeMemberProcedureProps> = ({
                                                                                                  procedure,
                                                                                                  disabled,
                                                                                              }: ITrusteeMemberProcedureProps) => {
    return (
        <Stack verticalAlign='center' horizontalAlign='center' grow>
            {procedure?.showCheckBox &&
                (<PermanentCheckBox checked={procedure.permanentChecked}
                                    tableType={procedure.tableType}
                                    itemId={procedure.id}
                                    disabled={disabled}
                />)}
        </Stack>
    )
}

type TrusteeMemberType = 'Trustee' | 'Director';
export const trusteeMemberProcedureRowRenderFactory = (memberType: TrusteeMemberType): CustomProcedureRowRender => {
    return (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        const {isCorporateTrusteeType} = ((items?: any): { isCorporateTrusteeType: boolean } => {
            const flatted = getFlatProcedures(items ?? [], []);

            const isCorporateTrusteeType = flatted.find(x => x.reference === 'Trustee Type')?.answerText === 'Corporate';

            return {isCorporateTrusteeType}
        })(rows)

        const resultColumn = (props?.columns ?? []).find(x => x.key === 'result');
        if (resultColumn) {
            // custom result column render
            const trusteeMemberAuditProcedureColumn: ITableColumn = {
                ...resultColumn,
                key: 'result_trusteeMember',
                onRender: (item, index, column) => {
                    return createElement(TrusteeMemberProcedureResultTemplate, {
                        procedure: item,
                        disabled: (isCorporateTrusteeType && memberType === 'Trustee') || (!isCorporateTrusteeType && memberType === 'Director')
                    })
                }
            }

            const index = props.columns.indexOf(resultColumn);

            const columns = [...props.columns];
            columns[index] = {...trusteeMemberAuditProcedureColumn}

            props.columns = [...columns]
        }

        const permanentColumn = (props?.columns ?? []).find(x => x.key === 'permanent');
        if (permanentColumn) {
            // custom permanent column render
            const trusteeMemberPermanentColumn: ITableColumn = {
                ...permanentColumn,
                key: 'permanent_trusteeMember',
                onRender: (item, index, column) => {
                    return createElement(TrusteeMemberProcedurePermanentTemplate, {
                        procedure: item,
                        disabled: (isCorporateTrusteeType && memberType === 'Trustee') || (!isCorporateTrusteeType && memberType === 'Director')
                    })
                }
            }

            const index = props.columns.indexOf(permanentColumn);

            const columns = [...props.columns];
            columns[index] = {...trusteeMemberPermanentColumn}

            props.columns = [...columns]
        }

        return defaultRender(props);
    };
}