import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    jobId: number;
    isApproved: boolean;
}

function useApproveFeeMatrix() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.calculationUrl}/api/1.0/feeCalculation/approve`, data)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getFeeCalculationSection'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useApproveFeeMatrix};