import {IColumn, IDetailsRowProps, NeutralColors, Stack, useTheme} from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import React, {cloneElement, ReactElement, useCallback, useMemo} from 'react';
import { useIntl } from 'react-intl';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import {GroupSummary, IGroupWithSummary} from "../Table/GroupSummary";
import {getDepthIndent} from "../Table/consts";
import {Attachment} from "../../../Attachment";
import {JobComment} from "../../../JobComment";
import {ReportValidationCell} from "../shared";
import {useReportValidationsContext} from "../../../ReportContent";



export const BGLMemberStatement = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();
    
    const memberDetailsColumns: IColumn[] = useMemo(() => [
        {
            key: 'name',
            name: '',
            minWidth: 200,
            fieldName: 'name',
            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
        },
        {
            key: 'value',
            name: '',
            minWidth: 200,
            fieldName: 'value',
            onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} />,
        },
    ], [formatMessage])
    
    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'name',
                name: formatMessage({ id: 'yourDetailedAccount' }),
                minWidth: 200,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText
                    data={item[column?.fieldName || 0]}
                    textWhiteSpace={'nowrap'}
                    isHeadingRow={true} 
                    format={item?.format} />,
            },
        ],
        [formatMessage]
    );

    const memberAccountColumns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: '',
                minWidth: 64,
                maxWidth: 100,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        {item?.showAttachmentIcon && <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />}
                        {item?.showCommentIcon && <JobComment itemId={item?.id} hasComments={item?.hasComments} />}
                    </Stack>
                ),
            },
            {
                key: 'name',
                name: '',
                minWidth: 200,
                fieldName: 'name',
                onRender: (item, _, column) => <SanitizedText
                    data={item[column?.fieldName || 0]}
                    isHeadingRow={item?.isBold}
                    styles={{...{root: { textDecorationLine: item?.isUnderline ? 'underline' : 'inherit' } }}}
                    format={item?.format} 
                    textAlign={item?.textAlign} />,
            },
            {
                key: 'preserved',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'preserved',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType && item[column?.fieldName || 0] ? formatNumber(item[column?.fieldName || 0]) : item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign={item?.textAlign || 'right'}/>,
            },
            {
                key: 'restricted',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'restricted',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType && item[column?.fieldName || 0] ? formatNumber(item[column?.fieldName || 0]) : item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign={item?.textAlign || 'right'}/>,
            },
            {
                key: 'unrestricted',
                name: '',
                minWidth: 200,
                maxWidth: 200,
                fieldName: 'unrestricted',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType && item[column?.fieldName || 0] ? formatNumber(item[column?.fieldName || 0]) : item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign={item?.textAlign || 'right'}/>,
            },
            {
                key: 'total',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'total',
                onRender: (item, _, column) => <SanitizedText data={item?.valueType && item[column?.fieldName || 0] ? formatNumber(item[column?.fieldName || 0]) : item[column?.fieldName || 0]} isHeadingRow={item?.isBold} format={item?.format} textAlign={item?.textAlign || 'right'} />,
            },
            {
                key: 'validation',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <ReportValidationCell itemId={item.id} isSumChecked={item.isSumChecked} />,
            },
        ],
        [formatMessage]
    );
    
    const onMemberDetailsRowRender = useCallback((props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        
        props.columns = [...memberDetailsColumns];
        
        let element = defaultRender(props);
        if (!element) {
            return element;
        }
        return cloneElement(element, {
            styles: {
                root: {
                    width: '100%',
                    '.ms-DetailsRow-fields': {
                        display: 'flex',
                        width: '100%'
                    },
                    '.ms-DetailsRow-cell': {
                        flex: 1
                    }
                }
            }
        })
        
    }, [])
    
    const onMemberAccountRowRender = useCallback((props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        props.columns = [...memberAccountColumns];
        
        if (!!props?.item) {
            // supposed to be a headline
            if (!props?.item?.id && !props?.item?.order) {
                props.item = {
                    ...props.item,
                    textAlign: 'center',
                    isHeader: true,
                    name: formatMessage({ id: 'yourDetailedAccount' }),
                    preserved: formatMessage({ id: 'preserved' }),
                    restricted: formatMessage({ id: 'restrictedNonPreserved' }),
                    unrestricted: formatMessage({ id: 'unrestrictedNonPreserved' }),
                    percentChange: formatMessage({ id: 'percentChange' }),
                    total: formatMessage({ id: 'total' }),
                    validation: formatMessage({ id: 'validation' })
                }
            }
            
            props.item.showAttachmentIcon = !props.item.isHeader && props.item.valueType !== 'Heading';
            props.item.showCommentIcon = !props.item.isHeader && props.item.valueType !== 'Heading';
        }
        
        let element = defaultRender(props);
        if (!element) {
            return element;
        }
        
        return cloneElement(element, {
            styles: {
                root: {
                    background: props?.item?.isHeader ? theme.palette.neutralLight : 'inherit',
                    width: '100%',
                    '.ms-DetailsRow-fields': {
                        display: 'flex',
                        width: '100%'
                    },
                    '.ms-DetailsRow-cell:first-child': {
                        flex: 1,
                        maxWidth: '100px'
                    },
                    '.ms-DetailsRow-cell:nth-child(2)': {
                        flex: 3
                    },
                    '.ms-DetailsRow-cell': {
                        flex: 1
                    }
                }
            }
        })
    }, [])

    const onRenderRow = useCallback((props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        
        if (props?.item?.isHeadingRow) {
            return defaultRender(props);
        }
        
        if (props?.item?.formType === 'YourDetails' || props?.item?.formType === 'YourBalance') {
            return onMemberDetailsRowRender(props, defaultRender)
        }

        return onMemberAccountRowRender(props, defaultRender);
    }, []);

    return <ReportTable 
        initialColumns={columns}
        items={items} 
        openAll={true}
        onRenderRow={onRenderRow}
        isFetching={isFetching}
        isHeaderVisible={false}
    />;
};
