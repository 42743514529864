import { Stack } from '@fluentui/react';
import { EmailSettingsForm } from './components/EmailSettings';

/**
 * UI component for Email Settings in Portal Settings.
 * all logic in inner components
 */

export const EmailSettingsPage = () => {
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <EmailSettingsForm />
        </Stack>
    );
};
