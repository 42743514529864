import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {IAttachment} from "./useGetAttachments";

interface IGetJobAttachmentsParams {
    fundId: number;
    jobId: number;
    year: number;
}

function useGetJobAttachments({fundId, jobId, year}: IGetJobAttachmentsParams) {
    const context = React.useContext(Context);

    const {
        data: attachmentsData,
        isLoading: isAttachmentsLoading,
        refetch: refetchJobAttachments,
        isFetching: isJobAttachmentsFetching
    } = useQuery(
        ['getJobAttachments', fundId, jobId, year],
        () => axios.get<IAttachment[]>(
            `${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/attachments?year=${year}`,
            commonHeaders(),
        ),
        {
            enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
           
            retry: false,
        }
    );

    return {attachmentsData, isAttachmentsLoading, refetchJobAttachments, isJobAttachmentsFetching};
}

export {useGetJobAttachments}