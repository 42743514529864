export enum SoftwareType {
    ClassSuper = 'ClassSuper',
    DesktopSuper = 'DesktopSuper',
    Supermate = 'Supermate',
    BGL = 'BGL',
    BGL360 = 'BGL360',
    Nothing = 'Nothing',
    ClassApiInCp = 'ClassApiInCp',
    ClassPdfInCp = 'ClassPdfInCp',
}

export enum DetailedSoftwareType {
    ClassSuperAPI = 'ClassSuperAPI',

    ClassSuperPDF = 'ClassSuperPDF'
}