import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {DashboardTableTypeEnum, FilterByPeriod} from "../components";

export interface ITableItemsProps {
    tableType: DashboardTableTypeEnum;
    isWorkflow: boolean;
    clientId?: string | null;
    fundId?: string | null;
    clientGuid?: string | null;
    fundGuid?: string | null;
    jobId?: number | null;
    filterByYear?: string | null;
    filterByPeriod?: FilterByPeriod | null;
    invoiceStartDate?: string | null;
    invoiceEndDate?: string | null;
    page?: number;
    pageSize?: number;
    orderBy?: string | null;
    orderDirection?: string | null;
    filterBy?: string | null;
    contactGuid?: string | null;
}

export interface ITableItemsFilter extends ITableItemsProps {
    page: number;
    pageSize: number;
}

export interface ITableItems {
    items: object[];
    count: number;
    filter: ITableItemsFilter;
}

function useGetTableItems(params: ITableItemsProps) {
    const context = React.useContext(Context);

    const {data: tableItems, isLoading: isTableItemsLoading} = useQuery(
        ['getTableItems', params],
        () => axios.get<ITableItems>(`${context?.config?.dashboardUrl}/api/1.0/Dashboard/tableItems`,
            {...commonHeaders(), params}),
        {
            enabled: !!context?.config?.dashboardUrl && !!context.tenantId,
        }
    );

    return {tableItems, isTableItemsLoading};
}

export {useGetTableItems};