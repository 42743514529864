import {useContext} from "react";
import {Context} from "../../../../../utils";
import {useMutation} from "react-query";
import {IJobOmlItem} from "../interfaces/IJobOmlItem";
import axios from "axios";
import {TableType} from "../../../../../enums";

type MutateDataType = {
    jobId: number;
    omls: Array<IJobOmlItem & { sectionId: number}>;
}

function useCreateJobOmlItems() {
    const context = useContext(Context);
    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.queriesUrl}/api/1.0/queries/createBatch/${data.jobId}`, data.omls))

    return {create, createAsync, isCreating};
}

export {useCreateJobOmlItems};