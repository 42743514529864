import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';


type PropsType = {
    id?: string | number;
};

function useGetJobTabs(id: PropsType) {

    const context = React.useContext(Context);
    const {data: dataJobsTab, isFetching: isJobsTabLoading} = useQuery(
        ['getCurrentTabs', id],
        () => axios.get(`${context?.config?.tabsUrl}/api/1.0/Tabs/tabsByJobTemplateId?jobTemplateId=${id}`, commonHeaders()),
        {
            enabled: !!id,
        }
    );

    return {dataJobsTab, isJobsTabLoading};
}

export {useGetJobTabs};
