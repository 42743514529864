import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CalculatorTemplateProps } from '../../types';
import { useIntl } from 'react-intl';
import { useUpdateCalculatorData } from '../../hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { ActionButton, IColumn, IconButton, Stack, TextField, useTheme } from '@fluentui/react';
import { CalculatorType } from '../../CalculatorType';
import { ControlledTextField, ITableColumn, SanitizedText } from '../../../../../../components';
import { IRentalIncomeCalculatorItem } from './IRentalIncomeCalculatorItem';
import { CalculatorDataTable } from '../shared';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useTabContext } from 'pages/JobPortal';

type RentalIncomeYieldCalculatorProps = CalculatorTemplateProps;
export const RentalIncomeYieldCalculator = forwardRef(({ data }: RentalIncomeYieldCalculatorProps, ref) => {
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const theme = useTheme();
    const [editingField, setEditingField] = useState<{ index: number; columnName: string } | undefined>(undefined);
    const { update, isUpdating } = useUpdateCalculatorData();
    const [incomeData, setIncomeData] = useState<IRentalIncomeCalculatorItem[]>(data?.listData as IRentalIncomeCalculatorItem[]);
    const { handleSubmit, getValues, control, setValue } = useForm<{ items: IRentalIncomeCalculatorItem[] }>({
        ...DefaultFormSettings,
        defaultValues: { items: incomeData },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    });
    const updateCalcData = (calculatorData: any) => {
        if (!isTabEnabled) return;

        update({
            saveComments: false,
            calculatorType: CalculatorType.RentalIncomeYield,
            isCustom: false,
            answer: data?.data,
            answers: calculatorData.items,
            jobId: data?.jobId || 0,
        });
    };

    const handleOnAddClick = () => {
        if (!isTabEnabled) return;

        append({
            id: 0,
            isCustom: true,
            totalRentalIncomeYield: 0,
            totalRentalIncome: 0,
            valueOfRealState: 0,
            propertyName: '',
        });
    };

    const handleOnRemoveClick = (index: number) => {
        if (!isTabEnabled) return;

        remove(index);
    };

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, column: IColumn) => {
            if (!isTabEnabled) return;

            setEditingField({ index: index!, columnName: column?.name! });
            event.preventDefault();
        },
        [isTabEnabled]
    );

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'propertyName',
                name: formatMessage({ id: 'calculator_rentalIncome_title_propertyName' }),
                fieldName: 'propertyName',
                minWidth: 120,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    incomeData[index]?.isPropertyNameChanged ||
                                                    incomeData[index]?.propertyName != getValues(`items.${index}.propertyName`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={getValues(`items.${index}.propertyName`)}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    onChange={(e) => {
                                        setValue(`items.${index}.isPropertyNameChanged`, true);
                                    }}
                                    autoFocus
                                    name={`items.${index}.propertyName`}
                                    control={control}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'totalRentalIncome',
                name: formatMessage({ id: 'calculator_rentalIncome_title_totalRentalIncome' }),
                fieldName: 'totalRentalIncome',
                minWidth: 120,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    incomeData[index]?.isTotalRentalIncomeChanged ||
                                                    incomeData[index]?.totalRentalIncome != getValues(`items.${index}.totalRentalIncome`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`items.${index}.totalRentalIncome`) ?? 0).toString())}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    onChange={(e) => {
                                        setValue(`items.${index}.isTotalRentalIncomeChanged`, true);
                                    }}
                                    autoFocus
                                    type={'number'}
                                    name={`items.${index}.totalRentalIncome`}
                                    control={control}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'valueOfRealState',
                name: formatMessage({ id: 'calculator_rentalIncome_title_valueOfRealState' }),
                fieldName: 'valueOfRealState',
                minWidth: 120,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    incomeData[index]?.isValueOfRealStateChanged ||
                                                    incomeData[index]?.valueOfRealState != getValues(`items.${index}.valueOfRealState`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`items.${index}.valueOfRealState`) ?? 0).toString())}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    onChange={(e) => {
                                        setValue(`items.${index}.isValueOfRealStateChanged`, true);
                                    }}
                                    autoFocus
                                    type={'number'}
                                    name={`items.${index}.valueOfRealState`}
                                    control={control}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'totalRentalIncomeYield',
                name: formatMessage({ id: 'calculator_rentalIncome_title_totalRentalIncomeYield' }),
                fieldName: 'totalRentalIncomeYield',
                minWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText data={total(index).toString()} textAlign={'right'} numberFormat={'percentage-fractional'} />
                    ) : null,
            },
            {
                key: 'actions',
                name: '',
                onRender: (item, index, column) => (
                    <Stack grow horizontal horizontalAlign={'center'}>
                        {index !== undefined && isTabEnabled && (
                            <IconButton
                                iconProps={{ iconName: 'delete' }}
                                styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                                onClick={() => handleOnRemoveClick(index)}
                            />
                        )}
                    </Stack>
                ),
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [editingField]);

    const total = useCallback(
        (index: number) => {
            const items = getValues().items;
            return items.length && items[index].valueOfRealState
                ? (items[index].totalRentalIncome || 0) / items[index].valueOfRealState
                : 0;
        },
        [fields]
    );

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        },
    }));

    useEffect(() => {
        setIncomeData(data?.listData as IRentalIncomeCalculatorItem[]);
    }, [data?.listData]);

    const handleClickOutside = (event: any) => {
        if ((event.srcElement.id as string).includes('TextField')) return;

        setEditingField(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Stack styles={{ root: { minWidth: 550 } }} tokens={{ childrenGap: 16 }} horizontalAlign={'center'} grow>
            <CalculatorDataTable
                initialColumns={columns}
                columns={columns}
                shimmerLines={getValues().items?.length || 3}
                enableShimmer={isUpdating}
                header={{
                    rowHeight: 60,
                    horizontalAlign: 'center',
                }}
                items={fields || []}
            />
            <Stack.Item>
                {isTabEnabled && (
                    <ActionButton
                        styles={{ textContainer: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                        text={formatMessage({ id: 'clickToAddRow' })}
                        iconProps={{ iconName: 'add' }}
                        onClick={handleOnAddClick}
                    />
                )}
            </Stack.Item>
        </Stack>
    );
});
