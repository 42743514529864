import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import { IReportInfo } from '../interfaces/IReportsInfo';

function useGetReportContent(jobId?: number | null, tableType?: number | null) {
    const context = React.useContext(Context);

    const { data, isLoading: isReportContentLoading, refetch: refetchReport, isFetching: isReportContentFetching } = useQuery(['getReportsItems', jobId, tableType], () =>
        axios.get<IReportInfo>(`${context?.config?.reportsUrl}/api/1.0/Reports/Get/${jobId}?type=${tableType}`, {
            ...commonHeaders(),
        }),
        {
            enabled: !!context?.config?.reportsUrl && !!context.tenantId,
            retry: false,
        }
    );

    const cleanedData = React.useMemo(
        () =>
            (data?.data?.reports || [])?.map((r) => {
                if (r?.report) {
                    return { ...r, report: r.report.filter((item) => item?.depth === 0) };
                }
                return r;
            }),
        [data]
    );

    return { reportContent: { ...data, reports: cleanedData }, isReportContentLoading, refetchReport, isReportContentFetching };
}

export { useGetReportContent };
