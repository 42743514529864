import {FunctionComponent, ReactNode} from 'react';
import {ContentState} from 'draft-js';

interface Props {
    contentState: ContentState;
    entityKey: string;
    children: ReactNode;
}

export const RichTextImage: FunctionComponent<Props> = (props: Props) => {
    const { src, width, height } = props.contentState.getEntity(props.entityKey).getData();

    return <img src={src} style={{ height, width }} />;
};
