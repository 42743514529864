import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RouterPaths } from 'navigation';
import { PageWithBreadcrumb } from 'components';

export const UserManagementLayoutPage = () => {
    const { formatMessage } = useIntl();
    return (
        <PageWithBreadcrumb
            breadcrumb={{
                id: 'userManagement',
                key: RouterPaths.userManagement,
                text: formatMessage({ id: 'userManagement' }),
            }}>
            <Outlet />
        </PageWithBreadcrumb>
    );
};
