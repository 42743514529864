import {IColumn, Stack} from '@fluentui/react';
import {SanitizedText} from 'components';
import {Attachment, GeneratedAnswer, JobComment} from 'pages/JobPortal/components';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {formatDate, getGrouppedReports} from 'utils';
import {AnswerControlType, TableType} from '../../../../../../enums';
import {useJobContext} from '../../../../JobPortalLayoutPage';
import {ReportTable} from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";
import { ReportMoneyValueCell } from "../shared/cells";


export const GeneralLedgerAuditTrail = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();
    const { reports, groups } = useMemo(() => getGrouppedReports({ pushEmptyGroup: !!allowEmptyGroups, reports: items, groupKey: 'group', formatGroupName: i => i.description }), [items]);
    const { jobYear } = useJobContext();

    const isDatePriorToFinancialYearEnd = (dateString: string) => {
        const date = new Date(dateString);
        const financialYearDate = new Date(jobYear, 6, 30);
        return date < financialYearDate;
    }

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'actions',
                name: formatMessage({ id: 'actions' }),
                minWidth: 60,
                maxWidth: 60,
                fieldName: 'actions',
                onRender: (item) => (
                    <Stack horizontal>
                        <Attachment itemId={item?.id} hasAttachments={item?.hasAttachments} />
                        <JobComment itemId={item?.id} hasComments={item?.hasComments} />
                    </Stack>
                ),
            },
            {
                key: 'processedDate',
                name: formatMessage({ id: 'processedDate' }),
                minWidth: 100,
                maxWidth: 200,
                fieldName: 'processedDate',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'user',
                name: formatMessage({ id: 'user' }),
                minWidth: 200,
                maxWidth: 228,
                fieldName: 'user',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed1',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed1',
                onRender: (item, _, column) => item?.date && isDatePriorToFinancialYearEnd(item.date) &&
                    <Stack verticalAlign='center' grow>
                        <GeneratedAnswer itemId={item?.id}
                    tableType={TableType[item?.reportType as keyof typeof TableType]}
                    value={item?.answerText} auto={true} disabled={true}
                            answerControlType={AnswerControlType.WorkDoneSwitch} />
                    </Stack>
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 250,
                maxWidth: 278,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'state',
                name: formatMessage({ id: 'state' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'state',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reviewed2',
                name: formatMessage({ id: 'reviewed' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reviewed2',
                onRender: (item, _, column) => item?.date && isDatePriorToFinancialYearEnd(item.date) && <Stack verticalAlign='center' grow>
                    <GeneratedAnswer itemId={item?.id}
                                     tableType={TableType[item?.reportType as keyof typeof TableType]}
                                     value={item?.answerText1} auto={true} disabled={true}
                                     answerControlType={AnswerControlType.WorkDoneSwitch} />
                    </Stack>,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amount',
                onRender: (item, _, column) => <ReportMoneyValueCell value={item[column?.fieldName || 0]} isBold={item?.isHeadingRow} />,
            },
            {
                key: 'amountIndicator',
                name: '',
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amountIndicator',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
        ],
        [formatMessage]
    );



    return <ReportTable initialColumns={columns}
                        groups={groups}
                        items={reports}
                        isFetching={isFetching}
                        expandProps={{showExpander: false}}
    />
};
