import {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';
import {IColumn, SelectionMode} from '@fluentui/react';
import {DataTable, IDataTableProps, ITableColumn} from 'components';
import {ColumnWithSize} from '../interfaces';
import {useSectionContext} from "./Section";

export interface IProceduresDataTableProps extends Omit<IDataTableProps, 'columns' | 'initialColumns'> {
    items: any[];
    columns: ColumnWithSize[];
    currentColumns?: ColumnWithSize[];
    isLoading?: boolean;
}

const getColumns = (columns: ColumnWithSize[], clientWidth: number): IColumn[] => {
    const minWidthLimit = 70;
    const padding = 20;

    const columnMinWidthsSum = columns.reduce((acc: number, col) => col.minWidth ? acc + col.minWidth : acc, 0);
    const columnSizesSum = columns.reduce((acc: number, col) => col.size ? acc + col.size : acc, 0);

    const clientWidthRest = clientWidth - columnMinWidthsSum;

    const getMinWidth = (col: ColumnWithSize): number => {
        if (col.minWidth) return col.minWidth;
        if (col.size) {
            const calcWidth = Math.round((clientWidthRest / columnSizesSum * col.size) - padding);
            return calcWidth < minWidthLimit ? minWidthLimit : calcWidth;
        }
        return 0;
    };

    return columns.map(col => ({
        ...col,
        minWidth: getMinWidth(col),
    }));
};

export const ProceduresDataTable: FunctionComponent<IProceduresDataTableProps> = ({
                                                                                      items,
                                                                                      columns,
                                                                                      isLoading,
                                                                                      currentColumns,
                                                                                      onShouldVirtualize = () => false,
                                                                                      ...props
                                                                                  }) => {
    const ref = useRef<any>(null);
    const {section} = useSectionContext();
    const [clientWidth, setClientWidth] = useState<number | null>(null);
    const procColumns = useMemo<IColumn[] | null>(() => clientWidth ? getColumns(columns, clientWidth).filter((x: ITableColumn) => !x.skip) : null, [clientWidth, columns]);
    
    useEffect(() => {
        if (ref?.current?.clientWidth) setClientWidth(ref.current.clientWidth);
    }, [ref]);

    return (
        <div ref={ref} style={{width: '100%'}}>
            {procColumns && (
                <DataTable
                    initialColumns={procColumns}
                    columns={procColumns}
                    onShouldVirtualize={onShouldVirtualize}
                    items={items}
                    selectionMode={SelectionMode.none}
                    enableShimmer={isLoading}
                    header={{ horizontalAlign:'center'}}
                    contextMenuOptions={{ meta: { sectionId: section.id }}}
                    {...props}
                />
            )}
        </div>
    );
};