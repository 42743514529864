import {useMutation} from "react-query";
import axios from "axios";
import {useContext} from "react";
import { Context, queryClient } from "utils";

type MutateDataType = {
    generateRepresentation: boolean;
    generateEngagement: boolean;
    jobInformation: JobInformation;
}

type JobInformation = {
    jobGuid: string;
    clientGuid: string;
    fundGuid: string;
    jobId: number;
    clientId: number;
    fundId: number;
    clientName: string;
    fundName: string;
    jobYear: number;
}

function useGenerateJobLetters() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.annotationsUrl}/api/1.0/job/${data.jobInformation.jobId}/generateLetters?generateRepresentation=${data.generateRepresentation}&generateEngagement=${data.generateEngagement}`, data.jobInformation)
        ,
        {
            onSuccess: () => { queryClient.invalidateQueries('getJobDocuments'); },
        });
    
    return {update, updateAsync, isUpdating};
}

export {useGenerateJobLetters};