import {IAdministrationListItem, IButtonConfig} from 'interfaces';
import {useNavigate, useParams} from 'react-router-dom';
import {RouterPaths} from 'navigation';
import {FunctionComponent, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useBoolean} from '@fluentui/react-hooks';
import {AddFundModal} from './modals/AddFundModal';
import {AddJobModal} from './modals/AddJobModal';
import {AdministrationList} from "./AdministrationList";
import {useAdministrationContext} from "../JobsLayoutPage";
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useGetFilteredFunds } from '../hooks/useGetFilteredFunds';
import { useGetUserClients } from '../hooks/useGetUserClients';
import { EditFundModal } from './modals/EditFundModal';


export const FundsList: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientsData, isClientsLoading: isClientLoading } = useGetUserClients({});
    const {clientId} = useParams();
    const {formatMessage} = useIntl();
    const {filters, setSelectedFund, setTargetFund, selectedClient, setSelectedClient, setTargetClient, targetFund} = useAdministrationContext();
    const { fundsData, isFundsLoading, isFundsFetching } = useGetFilteredFunds({ clientGuid: clientId as string, name: filters.search?.toLowerCase() as string}); 
    const [openAddJobModal, {toggle: toggleOpenAddJobModal}] = useBoolean(false);
    const [openEditFundModal, {toggle: toggleOpenEditFundModal}] = useBoolean(false);

    const buttonsConfig: IButtonConfig[] = [
        {
            text: formatMessage({id: 'addJob'}),
            type: 'primary',
            onClick: (data: any) => {
                setTargetFund(data);
                setTargetClient(clientsData?.data.find(x => x.guid === fundsData?.data.items.find(x => x.guid === data.guid)?.clientGuid) as IAdministrationListItem);
                toggleOpenAddJobModal();
            },
        },
        {
            text: formatMessage({id: 'editFund'}),
            type: 'primary',
            onClick: (data) => {
                setTargetFund(data);
                toggleOpenEditFundModal();
            },
        },
    ];
    const onCardClick = (_: React.MouseEvent<HTMLElement>, id: string, data: IAdministrationListItem) => {
        const clientGuid = fundsData?.data?.items?.find(x => x.guid === data.guid)?.clientGuid;

        if (clientGuid !== clientId && !!clientGuid) {
            setSelectedClient(null);
        }

        setSelectedFund(data);
        navigate(RouterPaths.administration.jobs.funds.jobList.root(clientGuid ?? clientId, data.guid));
    };

    const onSubmitEditFundModal = (data: any) => {
        console.log(data, 'submit edit fund data');
    };


    const funds = useMemo(() => {
        const funds = fundsData?.data?.items.map(({id, name, guid}) => ({
            id,
            name,
            guid: guid,
        })) as IAdministrationListItem[];
        const searchItems = filters?.search
            ? funds?.filter(fund => fund.name?.toLowerCase().includes(filters.search?.toLowerCase() as string))
            : funds;

        return searchItems || [];
    }, [filters, clientId, fundsData?.data]);

    if (isFundsLoading || isFundsFetching) return (<Stack styles={{ root: { height: '100%' } }} verticalAlign={"center"}>
        <Spinner size={SpinnerSize.large} label={"Loading"} />
    </Stack>);

    return (
        <>
            <AdministrationList
                isLoading={isFundsLoading}
                onCardClick={onCardClick}
                items={funds}
                buttonsConfig={buttonsConfig}
                title={filters.search ? formatMessage({id: 'funds'}) : ''}/>
            <EditFundModal
                clientGuid={selectedClient?.guid ?? ''}
                fundGuid={targetFund?.guid ?? ''}
                clientId={selectedClient?.id ?? 0}
                open={openEditFundModal}
                toggle={toggleOpenEditFundModal}
                title={formatMessage({id: 'editFund'})}/>
            <AddJobModal
                open={openAddJobModal}
                toggle={toggleOpenAddJobModal}/>
        </>
    );
};