import {useContext} from "react";
import {commonHeaders, Context} from "../../../../../utils";
import {useQuery} from "react-query";
import axios from "axios";
import { IExtraAmountParagraph } from "../interfaces/IExtraAmountParagraph";

function useGetExtraAmountParagraphs(clientIdentifier: string) {
    const context = useContext(Context);
    const {
        data: extraAmountParagraphs,
        isLoading
    } = useQuery(['getExtraAmountParagraphs', clientIdentifier],
        () => axios.get<IExtraAmountParagraph[]>(`${context?.config?.billingUrl}/api/1.0/invoice/GetExtraAmountParagraphs/${clientIdentifier}`, {
            ...commonHeaders()
        })
            .then(resp => resp.data),
            {
            enabled: !!context?.config?.billingUrl
        }
    );

    return {extraAmountParagraphs, isLoading};
}

export {useGetExtraAmountParagraphs};