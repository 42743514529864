"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConnection = exports.isConnectionConnecting = void 0;
const signalr_1 = require("@microsoft/signalr");
function isConnectionConnecting(connection) {
    return (connection.state === signalr_1.HubConnectionState.Connected ||
        connection.state === signalr_1.HubConnectionState.Reconnecting ||
        connection.state === signalr_1.HubConnectionState.Connecting);
}
exports.isConnectionConnecting = isConnectionConnecting;
function createConnection(url, transportType) {
    let connectionBuilder = new signalr_1.HubConnectionBuilder()
        .withUrl(url, transportType)
        .withAutomaticReconnect();
    if (transportType.logger) {
        connectionBuilder = connectionBuilder.configureLogging(transportType.logger);
    }
    const connection = connectionBuilder.build();
    return connection;
}
exports.createConnection = createConnection;
