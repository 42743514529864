import { IColumn } from '@fluentui/react';
import { SanitizedText } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from 'utils';
import { getDepthPaddingStyles, ReportTable } from '../Table/ReportTable';
import {ReportTemplateTypeProps} from "../Types";



export const TaxableIncomeReport = ({ items, isFetching, allowEmptyGroups }: ReportTemplateTypeProps) => {
    const { formatMessage } = useIntl();

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'description',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} styles={getDepthPaddingStyles(item?.depth)} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'reference',
                name: formatMessage({ id: 'reference' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'reference',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'date',
                name: formatMessage({ id: 'date' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'date',
                onRender: (item, _, column) => <SanitizedText data={formatDate(item[column?.fieldName || 0])} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'code',
                name: formatMessage({ id: 'code' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'code',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'amount',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'balance',
                name: formatMessage({ id: 'balance' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'balance',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
            {
                key: 'validation',
                name: formatMessage({ id: 'validation' }),
                minWidth: 100,
                maxWidth: 128,
                fieldName: 'validation',
                onRender: (item, _, column) => <SanitizedText data={item[column?.fieldName || 0]} isHeadingRow={item?.isHeadingRow} format={item?.format} />,
            },
        ],
        [formatMessage]
    );

    return <ReportTable initialColumns={columns} items={items} isFetching={isFetching} />;
};
