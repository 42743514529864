import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
    DashboardContainer,
    DashboardTableTypeEnum,
    DashboardTabsEnum,
    GetDashboardConfig,
    IDashboardConfig,
    IPartnerAPWidgetData,
    WidgetsEnum
} from "pages/shared/Dashboard";

export const PartnerAuditPortalDashboard = () => {
    const {formatMessage} = useIntl();

    const getPartnerDashboardConfig = useCallback(
        (props: IPartnerAPWidgetData): IDashboardConfig => {
            return {
                id: 'partner',
                rows: [
                    [
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'auditsReadyForReview',
                            tables: [{ tableType: DashboardTableTypeEnum.Partner_AuditsReadyForReview, isWorkflow: true}],
                            props: {
                                number: props.auditsReadyForReview,
                                title: formatMessage({id: 'auditsReadyForReview'}),
                                variant: 'tertiary',
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.DOUGHNUT_WIDGET,
                            id: 'reviewPointsToBeCleared',
                            size: 3,
                            tables: [{ tableType: DashboardTableTypeEnum.Partner_ReviewPointsToBeCleared, isWorkflow: true}],
                            props: {
                                title: formatMessage({id: 'reviewPointsToBeCleared'}),
                                data: props.reviewPointsToBeCleared,
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'activeClients',
                            tables: [{ tableType: DashboardTableTypeEnum.Partner_ActiveClients, isWorkflow: true}],
                            props: {
                                number: props.activeClients,
                                title: formatMessage({id: 'activeClients'}),
                                variant: 'tertiary',
                                counterAlign: 'center',
                            },
                        },
                        {
                            type: WidgetsEnum.COUNT_WIDGET,
                            id: 'arSentThisWeek',
                            tables: [{ tableType: DashboardTableTypeEnum.Partner_ArSentThisWeek, isWorkflow: true}],
                            props: {
                                number: props.arSentThisWeek,
                                title: formatMessage({id: 'arSentThisWeek'}),
                                counterAlign: 'center',
                            },
                        },
                    ],
                    [
                        {
                            type: WidgetsEnum.TABLE_WIDGET,
                            id: '8',
                            props: {
                                tableType: DashboardTableTypeEnum.Partner_AllAuditsInProgress,
                                isWorkflow: true
                            },
                        },
                    ],
                ],
            };
        },
        [formatMessage]
    );

    return <DashboardContainer dashboardTab={DashboardTabsEnum.AP_Partner}
        getDashboardConfig={getPartnerDashboardConfig as GetDashboardConfig}
        isWorkflow={true} />;
};
