import { FunctionComponent } from 'react';
import { IconButton, Spinner, Stack, Text } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useBoolean } from '@fluentui/react-hooks';
import { IUserInfo } from 'interfaces';
import { Card, Modal } from 'components';
import {useGetClientsByGuids} from "../../../WorkflowPortal";

interface Props {
    item: IUserInfo;
}

export const UserManagementClients: FunctionComponent<Props> = ({ item }) => {
    const { formatMessage } = useIntl();
    const [show, { toggle: toggleShow }] = useBoolean(false);
    if (!item.clients.length) {
        return null;
    }
    return (
        <>
            <IconButton iconProps={{ iconName: 'RedEye' }} onClick={toggleShow} />
            <Modal title={formatMessage({ id: 'clients' })} isOpen={show} onDismiss={toggleShow} children={<UserManagementClientsModal item={item} />} />
        </>
    );
};

export const UserManagementClientsModal: FunctionComponent<Props> = ({ item }) => {
    const { dataClients, isLoadingClients } = useGetClientsByGuids({ clientGuids: item.clients.map(client => client.clientGuid) });
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {isLoadingClients ? <Spinner /> : dataClients?.data.map((c, idx) => (
                <Card key={`client-view-card-${idx}-${c.guid}`}>
                    <Text>{(dataClients?.data[idx]?.name ?? null)}</Text>{' '}
                </Card>
            ))}
        </Stack>
    );
};
