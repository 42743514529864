import { useCallback, useMemo } from 'react';
import {
    ContextualMenuItemType,
    DirectionalHint,
    FontIcon,
    IconButton,
    IPersonaProps,
    Label,
    mergeStyles,
    mergeStyleSets,
    Persona,
    PersonaPresence,
    PersonaSize,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { stores } from 'stores';
import { RouterPaths } from '../navigation';
import { ActiveIconColorStyles } from '../constants/tableStylesPeset';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { PortalRole, useWorkContext, WorkContextType } from "../providers";
import { useJobContext } from "../pages/JobPortal";

type UserActionPropsType = {};

type UserInfo = {
    id: string;
    displayName: string;
    email: string;
    role: string;
    roles: string;
    isCrmUser: boolean;
    isDeveloper: boolean;
};

export const UserActions = observer(({}: UserActionPropsType) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const theme = useTheme();
    const iconClass = mergeStyles({ color: theme.semanticColors.menuIcon });
    const { isInRoleOrSuperAdministrator, workContext, isInRole } = useWorkContext();
    const { job } = useJobContext()

    const userInfo = useMemo<UserInfo>(() => {
        const identity = workContext?.user?.identityUser;
        
        let prefix = '';
        if (workContext?.type === WorkContextType.JobWorkConext) {
            if (workContext.isCurrentUserJobAuditor) {
                prefix += ' (Auditor)';
            } else if (workContext.isCurrentUserJobManager) {
                prefix += ' (Manager)';
            } else if (workContext.isCurrentUserJobPartner) {
                prefix += ' (Partner)';
            } else {
                prefix = '';
            }
        }
        
        return {
            id: identity?.id ?? '',
            displayName: identity?.name ?? '',
            email: identity?.email ?? '',
            role: (workContext?.user?.maxRole ?? '') + prefix,
            roles: (identity?.roles ?? []).join('; '),
            isCrmUser: identity?.isCrmUser ?? false,
            isDeveloper: identity?.isDeveloper ?? false
        } as UserInfo;
    }, [workContext]);

    const _onRenderSecondaryText = useCallback((props?: IPersonaProps) => {
        return (
            <Stack tokens={{childrenGap: 0}}>
                <Stack.Item>
                    {userInfo.email}
                </Stack.Item>
                <Stack.Item title={userInfo.roles}>
                    {userInfo.role}
                </Stack.Item>
            </Stack>
        )
    }, [userInfo])
    
    const _onRenderPrimaryText = useCallback((props?: IPersonaProps) => {
        return (
            <div title={userInfo.id} onClick={() => navigator.clipboard.writeText(userInfo.id)}>{userInfo.displayName}</div>
        )
    }, [userInfo])
    
    const classNames = mergeStyleSets({
        persona: {
            '.ms-Persona-details': {
                'line-height': 'normal'
            }
        }
    })
    
    const onRenderMenuItemContent = (text: string, iconName: string, externalLink: boolean, textColor?: string, iconColor?: string, textHint?: string) => {
        return (
            <Stack
                horizontal
                horizontalAlign='space-between'
                tokens={{ padding: '0 4px' }}
                styles={{ root: { width: '100%' } }}>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Text variant='mediumPlus'>
                        <FontIcon aria-label='Workflow'
                                  iconName={iconName}
                                  className={iconClass}
                                  style={{ color: iconColor ?? theme.semanticColors.menuIcon }}
                        />
                    </Text>
                    <Text title={textHint} style={{ color: textColor ?? theme.schemes?.default?.semanticColors.bodyText }} variant='medium'>
                        {text}
                    </Text>
                </Stack>
                {externalLink && <Text variant='mediumPlus'>
                    <FontIcon
                        style={{ color: textColor ?? theme.schemes?.default?.semanticColors.bodyText }}
                        aria-label='NavigateExternalInline'
                        iconName='NavigateExternalInline'
                    />
                </Text>}
            </Stack>
        )
    }
    
    const menuProps: IContextualMenuProps = useMemo(() => {
        return {
            alignTargetEdge: true,
            beakWidth: 20,
            styles: {
                header: {
                    padding: '12px 6px',
                }
            },
            items: [
                {
                    canCheck: false,
                    key: 'userName',
                    text: userInfo.displayName,
                    itemType: ContextualMenuItemType.Header,
                    onRenderContent: () => (
                        <Persona
                            className={classNames.persona}
                            initialsColor={theme.palette.themePrimary}
                            text={userInfo.displayName}
                            onRenderPrimaryText={_onRenderPrimaryText}
                            secondaryText={userInfo.email}
                            onRenderSecondaryText={_onRenderSecondaryText}
                            presence={userInfo.isDeveloper ? PersonaPresence.busy : PersonaPresence.online}
                            isOutOfOffice={userInfo.isCrmUser}
                            size={PersonaSize.size48}
                            styles={{ primaryText: { fontWeight: 600, color: theme.palette.themePrimary, lineHeight: 'normal' } }}
                        />
                    ),
                },
                ...(() => {
                    const developerSection: any[] = [];
                    if (job?.guid && job?.fund?.guid && job?.client?.guid && userInfo.isDeveloper && workContext?.job) {
                        developerSection.push({
                            key: 'jobInfo',
                            onClick: () => {
                                navigator.clipboard.writeText(workContext.job!.id?.toString())
                            },
                            onRenderContent: () => onRenderMenuItemContent(
                                `Job Id: ${workContext.job!.id}`,
                                'Work',
                                false,
                                theme.schemes?.default?.semanticColors.disabledText,
                                workContext.job?.isDispatched
                                    ? theme.schemes?.default?.semanticColors.errorText
                                    : theme.schemes?.default?.semanticColors.disabledText,
                                `Dispatched: ${workContext.job?.isDispatched ? 'True' : 'False'}`
                                )
                        })
                        
                        developerSection.push({
                            key: 'importReport',
                            onClick: () => {
                                window.open(`/import/report/${job.guid}`, '_blank');
                            },
                            onRenderContent: () => onRenderMenuItemContent('Go to Import Reports', 'ReportDocument', true)
                        });

                        developerSection.push({
                            key: 'cpjob',
                            onClick: () => {
                                window.open(`/administration/jobs/${job.client.guid}/${job.fund.guid}`, '_blank');
                                
                            },
                            onRenderContent: () => onRenderMenuItemContent('Go to CP', 'ComplianceAudit', true)
                        })
                        
                        developerSection.push({ key: 'dividerLast', itemType: ContextualMenuItemType.Divider })
                        
                    }
                    if (isInRoleOrSuperAdministrator(PortalRole.SuperAdministrator, PortalRole.SuperUser)) {
                        return [
                            { key: 'divider1', itemType: ContextualMenuItemType.Divider },
                            {
                                key: 'adminPortal',
                                style: { color: theme.schemes?.default?.semanticColors.bodyText },
                                text: intl.formatMessage({ id: 'adminPortal' }),
                                iconProps: { iconName: 'Admin' },
                                onClick: () => navigate(RouterPaths.admin.rulesets.root),
                            },
                            {
                                key: 'workflowEditor',
                                onClick: () => {
                                    window.open('/workflow-editor', '_blank');
                                },
                                onRenderContent: () => (
                                    <Stack
                                        horizontal
                                        horizontalAlign='space-between'
                                        tokens={{ padding: '0 4px' }}
                                        styles={{ root: { width: '100%' } }}>
                                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                                            <Text variant='mediumPlus'>
                                                <FontIcon aria-label='Workflow' iconName='PageHeaderEdit' className={iconClass} />
                                            </Text>
                                            <Text style={{ color: theme.schemes?.default?.semanticColors.bodyText }} variant='medium'>
                                                {intl.formatMessage({ id: 'workflowEditor' })}
                                            </Text>
                                        </Stack>
                                        <Text variant='mediumPlus'>
                                            <FontIcon
                                                style={{ color: theme.schemes?.default?.semanticColors.bodyText }}
                                                aria-label='NavigateExternalInline'
                                                iconName='NavigateExternalInline'
                                            />
                                        </Text>
                                    </Stack>
                                ),
                            },
                            { key: 'divider2', itemType: ContextualMenuItemType.Divider },
                            ...developerSection,
                        ];
                    } else {
                        return [
                            { key: 'divider0', itemType: ContextualMenuItemType.Divider },
                            ...developerSection
                        ];
                    }
                })(),
                // {
                //     key: 'changePassword',
                //     text: intl.formatMessage({ id: 'changePassword' }),
                //     iconProps: { iconName: 'AccountManagement' },
                //     onClick: () => navigate('/login/change-password'), // TODO: set real link to reset password page
                // },
                {
                    key: 'logout',
                    style: { color: theme.schemes?.default?.semanticColors.bodyText },
                    text: intl.formatMessage({ id: 'logout' }),
                    iconProps: { iconName: 'SignOut', styles: ActiveIconColorStyles(theme.palette.redDark) },
                    onClick: stores.AuthStore.signOut,
                },
            ],
            directionalHint: DirectionalHint.bottomLeftEdge,
        };
    }, [iconClass, intl, navigate, theme.palette.redDark, userInfo, job, workContext?.job]);

    return (
        <IconButton
            onRenderMenuIcon={() => <div />}
            styles={{ root: { height: 48, paddingLeft: 16, paddingRight: 16 } }}
            menuProps={menuProps}
            iconProps={{ iconName: 'Contact' }}
        />
    );
});
