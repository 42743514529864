import { useContext } from 'react';
import { commonHeaders, Context } from '../../../../../../../../../utils';
import { useMutation } from 'react-query';
import axios from 'axios';

type MutateDataType = {
    id: number | null;
    jobId: number;
    fundId: number;
    notes: string | null;
};

function useSaveIssueItem() {
    const context = useContext(Context);

    const { mutate: save, isLoading: isSaving } = useMutation(
        (data: MutateDataType) => axios.post(`${context?.config?.proceduresUrl}/api/1.0/procedures/hinTable`, {
            ...data,
            itemId: data.id,
            perm: false,
            isSimpleNote: true
        }, commonHeaders()),
        {}
    );

    return { save, isSaving };
}

export { useSaveIssueItem };
