import React, {FunctionComponent} from 'react';
import {useIntl} from 'react-intl';
import {useFieldArray, useForm} from 'react-hook-form';
import {IColumn, PrimaryButton, SelectionMode, Stack, Text, useTheme} from '@fluentui/react';
import {IChoiceGroupOption} from '@fluentui/react/lib/components/ChoiceGroup/ChoiceGroup.types';
import {Card, ControlledChoiceGroup, ControlledNumberField, DataTable} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {RiskMatrixDetailedRes} from "../../hooks/RiskMatrix/useGetRiskMatrixDetailed";
import {RiskMatrixReq} from "../../hooks/RiskMatrix/useUpdateRiskMatrixDetailed";
import {CardTableContainerStyles} from "../../../../../constants/tableStylesPeset";

interface Props {
    mainTableFields: RiskMatrixDetailedRes;
    updateRiskMatrixDetailed: (a: RiskMatrixReq) => void;
}

export const RiskMatrixSettingsMainTable: FunctionComponent<Props> = ({
                                                                          mainTableFields,
                                                                          updateRiskMatrixDetailed
                                                                      }: Props) => {
    const {formatMessage} = useIntl();

    const theme = useTheme();

    const {control, handleSubmit, formState} = useForm<{ mainTableFields: RiskMatrixDetailedRes }>({
        ...DefaultFormSettings,

        defaultValues: React.useMemo(() => {
            return {mainTableFields};
        }, [mainTableFields]),
    });

    const {fields} = useFieldArray({
        control,
        name: 'mainTableFields',
    });

    const updateMainTable = (form: { mainTableFields: RiskMatrixDetailedRes }) => {
        updateRiskMatrixDetailed({detailedRiskMatrixSettings: form.mainTableFields});
    };

    const optionValueOptions: IChoiceGroupOption[] = [
        {
            value: 1,
            text: 'Low',
            key: 1 as any,
        },
        {
            value: 2,
            text: 'Medium',
            key: 2 as any,
        },
        {
            value: 3,
            text: 'High',
            key: 3 as any,
        },
    ];
    const columns: IColumn[] = [
        {
            key: 'name',
            name: '',
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 340,
            isMultiline: true,
            onRender: (item) => (
                <Text variant='medium' styles={{root: {fontWeight: 500}}}>
                    {item ? item.name : null}
                </Text>
            ),
        },
        {
            key: 'valueOfTotalAssets',
            name: formatMessage({id: 'valueOfTotalAssets'}),
            fieldName: 'valueOfTotalAssets',
            minWidth: 200,
            onRender: (item, index) =>
                index !== undefined ? (
                    // fields[index].valueOfTotalAssets
                    <ControlledNumberField control={control}
                                           name={`mainTableFields.${index}.valueOfTotalAssets`}/>
                ) : (
                    <div style={{backgroundColor: theme.palette.neutralLighter, width: '100%', height: 32}}/>
                ),
        },
        {
            key: 'optionValue',
            name: '',
            minWidth: 250,
            onRender: (item, index) =>
                index !== undefined && fields ? (
                    <ControlledChoiceGroup control={control} name={`mainTableFields.${index}.optionValue`}
                                           options={optionValueOptions}/>
                ) : null,
        },
    ];

    return fields ? (
        <>
            <Card styles={{
                ...CardTableContainerStyles,
                root: {...CardTableContainerStyles.root, padding: '16px 16px 50px 16px'}
            }}>
                <DataTable inherit={true} initialColumns={columns} items={fields} selectionMode={SelectionMode.none}/>
                <Stack styles={{root: {marginTop: 10}}} horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!formState.isDirty}
                        onClick={handleSubmit(updateMainTable)}
                        text={formatMessage({id: 'save'})}
                    />
                </Stack>
            </Card>
        </>
    ) : null;
};
