import {createContext, FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {Stack} from '@fluentui/react';
import {useForm} from 'react-hook-form';
import {debounce} from 'lodash';
import {AdministrationDashboardFilter, IAdministrationDashboardFilter} from "./AdministrationDashboardFilter";
import {
    DashboardContent,
    DashboardTabsEnum,
    IDashboardConfig,
    IDashboardContainer,
    useGetContactInfos,
    useGetJobYears,
    useGetShortClientInfos,
    useGetShortFundInfos,
    useGetWidgetData,
    WidgetData
} from "../../../../shared/Dashboard";

export type GetDashboardConfig = (widgetData: WidgetData) => IDashboardConfig;

export interface IAdministrationDashboardContext {
    dashboardTab: DashboardTabsEnum;
    filterData: IAdministrationDashboardFilter;
}

const defaultSearchValues: IAdministrationDashboardFilter = {
    clientGuid: '',
    fundGuid: '',
    filterByYear: '',
    contactGuid: '',
};

// const defaultContextValues: IAdministrationDashboardContext = {
//     dashboardTab: DashboardTabsEnum.ClientPortalWhite,
//     filterData: defaultSearchValues,
// }

const AdministrationDashboardContext = createContext<IAdministrationDashboardContext | undefined>(undefined);

export const useAdministrationDashboard = () => useContext(AdministrationDashboardContext);

export const AdministrationDashboardContainer: FunctionComponent<IDashboardContainer> = ({
                                                                                             dashboardTab,
                                                                                             getDashboardConfig,
                                                                                             isWorkflow = false,
                                                                                         }) => {
    const {control, watch, reset, resetField} = useForm<IAdministrationDashboardFilter>({
        mode: 'onChange',
        defaultValues: defaultSearchValues,
    });
    const [filterData, setFilterData] = useState<IAdministrationDashboardFilter>(defaultSearchValues);

    const {shortClientInfos, isShortClientInfosLoading} = useGetShortClientInfos({dashboardTab});
    const {shortFundInfos, isShortFundInfosLoading} = useGetShortFundInfos({
        clientGuid: filterData.clientGuid,
        dashboardTab
    });
    const {jobYears, isJobYearsLoading} = useGetJobYears({
        clientGuid: filterData.clientGuid,
        fundGuid: filterData.fundGuid,
        dashboardTab
    });

    const {mainContacts, isMainContactsLoading} = useGetContactInfos({
        clientGuid: filterData.clientGuid,
        fundGuid: filterData.fundGuid,
        dashboardTab
    })
    const { widgetData, isWidgetDataLoading } = useGetWidgetData({
        dashboardTab, isWorkflow, clientGuid: filterData.clientGuid,
        fundGuid: filterData.fundGuid, filterByYear: filterData.filterByYear,
        contactGuid: filterData.contactGuid,
    });

    const isFilterLoading = isJobYearsLoading || isShortClientInfosLoading || isShortFundInfosLoading || isMainContactsLoading;
    const dashboardConfig = useMemo(() => (!isWidgetDataLoading && widgetData?.data) ? getDashboardConfig(widgetData.data) : null, [getDashboardConfig, isWidgetDataLoading, widgetData]);
    const onSubmit = (newFilterData: IAdministrationDashboardFilter) => setFilterData(newFilterData);
    const clientGuid = watch('clientGuid');
    const fundGuid = watch('fundGuid');

    useEffect(() => {
        const subscription = watch(debounce(onSubmit, 100));
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        resetField('fundGuid');
        resetField('filterByYear');
    }, [clientGuid, resetField]);

    useEffect(() => {
        resetField('contactGuid');
        resetField('filterByYear');
    }, [fundGuid, resetField]);

    return (
        <AdministrationDashboardContext.Provider value={{filterData, dashboardTab}}>
            <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
                <AdministrationDashboardFilter
                    isLoading={isFilterLoading}
                    jobYears={jobYears}
                    shortClientInfos={shortClientInfos}
                    shortFundInfos={shortFundInfos}
                    shortContactInfos={mainContacts}
                    control={control}
                    reset={reset}/>
                <Stack tokens={{childrenGap: 16}} styles={{root: {height: '100%'}}}>
                    <DashboardContent dashboardConfig={dashboardConfig} isLoading={isWidgetDataLoading}/>
                </Stack>
            </Stack>
        </AdministrationDashboardContext.Provider>
    );
};