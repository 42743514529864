import {useContext} from "react";
import {Context} from "../../../../../../../utils";
import {useMutation} from "react-query";
import axios from "axios"

function useDeleteIdentifiedRisksItem() {
    const context = useContext(Context);
    const {
        mutate: deleteIdentifiedRisks,
        isLoading: isDeleting
    } = useMutation((id: number) => axios.delete(`${context?.config?.proceduresUrl}/api/1.0/procedures/identifiedrisks/${id}`))

    return {deleteIdentifiedRisks, isDeleting};
}

export {useDeleteIdentifiedRisksItem};