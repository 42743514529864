import axios from 'axios';
import {IAppConfiguration} from 'interfaces';
import {useQuery} from 'react-query';
import {useGetTenantId} from './useGetTenantId';

/** Getting Data for configuration routes - Main hook */

function useConfiguration() {
    const url =
        process.env.NODE_ENV === 'development'
            ? 'https://evolv.1c591120a461425a8042.australiaeast.aksapp.io/app/configuration'
            : 'app/configuration';
    const {data: config, isLoading: isConfigLoading} = useQuery(
        ['getConfigs'],
        () =>
            axios.get<IAppConfiguration>(url)
    );

    const {data: tenant, isLoading: isTenantLoading} = useGetTenantId(config?.data.tenantProviderUrl || '');

    if (config?.data) {
        config.data = {
            ...config.data,
            // timesheetUrl: 'http://localhost:7241',
            // proceduresUrl: 'http://localhost:7831',
            // suggestedTestingUrl: 'http://localhost:7141'
            // answersUrl: 'http://localhost:7931',
            // jobUrl: 'http://localhost:7531'
            // tabsUrl: 'http://localhost:7251'
            // queriesUrl: 'http://localhost:7631'
            // billingUrl: 'http://localhost:7221'
        }
    }

    return {
        config,
        isConfigLoading,
        tenant,
        isTenantLoading,
    };
}

export {useConfiguration};
