import {useContext} from "react";
import {Context} from "../../../../utils";
import {useMutation} from "react-query";
import axios from "axios";

type MutateDataType = {
    jobId: number;
    sectionId: number;
    managerApproval?: boolean;
    partnerApproval?: boolean;
    sendEmail?: boolean;
}

function useUpdateSectionAnswer() {
    const context = useContext(Context);
    const {
        mutate: update,
        mutateAsync: updateAsync,
        isLoading: isUpdating
    } = useMutation((data: MutateDataType) => axios.post(`${context?.config?.answersUrl}/api/1.0/jobs/${data.jobId}/sections/${data.sectionId}/answer`, data));

    return {update, updateAsync, isUpdating};
}

export {useUpdateSectionAnswer};