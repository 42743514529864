import axios, {AxiosError} from 'axios';
import React from 'react';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import {useNotifications} from "../../../../components/notifications";
import {useIntl} from "react-intl";
import {MessageBarType} from "@fluentui/react";


type MutateDataType = {
    notes: string;
    clientId: number;
};

function useUpdateClientsNotes() {
    const context = React.useContext(Context);
    const {showNotification} = useNotifications();
    const {formatMessage} = useIntl();

    const {mutate: updateClientNotes, isLoading: isUpdatingClientNotes} = useMutation(
        (data: MutateDataType) => axios.put(`${context?.config?.clientUrl}/api/1.0/Clients/${data.clientId}/notes`, data, commonHeaders()),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getClientsByFilter');
                showNotification({
                    type: MessageBarType.success,
                    name: formatMessage({id: 'updated'}),
                    description: formatMessage({id: "entityUpdated"}, {name: "Client's note"})
                });
            },
            onError: (error: AxiosError) => {
                showNotification({
                    type: MessageBarType.error,
                    name: 'Error',
                    description: `Code: ${error.response?.data?.code}. Messages: ${error.response?.data?.messages[0]}`
                })
            }

        }
    );

    return {updateClientNotes, isUpdatingClientNotes};
}

export {useUpdateClientsNotes};
