import axios, {AxiosError} from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import {commonHeaders, Context} from 'utils';
import {IEmployee} from '../interfaces';

interface IGetEmployeeByIdParams {
    id?: number | null;
}

function useGetEmployeeById({id}: IGetEmployeeByIdParams) {
    const context = React.useContext(Context);
    const host = context?.config?.userUrl;
    const {data: employee, isLoading: isEmployeeLoading} = useQuery(
        ['getEmployeeById', id],
        () => axios.get<IEmployee>(`${host}/api/1.0/Employee/${id}`, {
            ...commonHeaders()
        }),
        {
            enabled: !!context?.config?.tabsUrl && !!context.tenantId,
            retry: false,
        }
    );

    return {employee: employee?.data, isEmployeeLoading};
}

export {useGetEmployeeById};