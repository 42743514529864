import {Card, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {PrimaryButton, Stack} from '@fluentui/react';
import {Control, useFieldArray} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {IChangeClientInfoCommand} from '..';
import {ClientAddressType} from "../../../../shared";

type AddressTabType = {
    control: Control<IChangeClientInfoCommand>;
};

export const AddressTab = ({control}: AddressTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {fields, append} = useFieldArray({control, name: 'addresses'});
    const fullWidthStyle = {root: {width: '100%'}};

    const addressTypesOptions = [
        {key: ClientAddressType.Physical, text: formatMessage({id: 'physical'})},
        {key: ClientAddressType.Postal, text: formatMessage({id: 'postal'})},
        {key: ClientAddressType.Other, text: formatMessage({id: 'other'})},
    ];

    const addNewAddress = () => {
        append({type: ClientAddressType.Physical});
    }

    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} styles={fullWidthStyle} wrap>
                {fields.map((address, index) => {
                    return (
                        <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {marginTop: index == 0 ? 0 : 32}}}
                               wrap>
                            <Stack.Item styles={fullWidthStyle}>
                                <ControlledDropdown options={addressTypesOptions} name={`addresses.${index}.type`}
                                                    label={formatMessage({id: 'type'})} control={control}/>
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField name={`addresses.${index}.state`}
                                                     label={formatMessage({id: 'state'})}
                                                     control={control}/>
                            </Stack.Item>
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name={`addresses.${index}.postalCode`}
                                    required
                                    label={formatMessage({id: 'postCode'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name={`addresses.${index}.street1`}
                                    required
                                    label={formatMessage({id: 'street'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField name={`addresses.${index}.street2`}
                                                     label={formatMessage({id: 'street2'})} control={control}/>
                            </Stack.Item>


                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField name={`addresses.${index}.country`}
                                                     label={formatMessage({id: 'country'})} control={control}/>
                            </Stack.Item>
                            <Stack.Item styles={stackItemStyles}>
                                <ControlledTextField
                                    name={`addresses.${index}.city`}
                                    required
                                    label={formatMessage({id: 'city'})}
                                    control={control}
                                    rules={{
                                        required: formatMessage({id: 'requiredField'}),
                                        minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    );
                })}
                {fields.length < 3 && (
                    <Stack.Item styles={fullWidthStyle}>
                        <PrimaryButton styles={fullWidthStyle} text={formatMessage({id: 'addNewAddress'})}
                                       onClick={addNewAddress}/>
                    </Stack.Item>)}
            </Stack>
        </Card>
    );
}
