import {Card, ControlledDropdown, ControlledTextField} from 'components';
import {CardTableContainerStyles} from 'constants/tableStylesPeset';
import {IDropdownOption, Label, Link, Stack, useTheme} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {useMemo} from "react";
import {IChangeFundInfoCommand} from '..';
import {useGetClientByGuid} from "pages/WorkflowPortal/Clients";
import {useWorkflows} from "../../../../WorkflowLayoutPage";
import {useTenantInfo} from "../../../../../../providers";
import {useGetAuditFirms} from "../../../../../ClientPortal/PortalSettings/hooks/Audit/useGetAuditFirms";

type AccountTabType = {
    control: Control<IChangeFundInfoCommand>;
    clientGuid?: string;
    clientName?: string;
};

export const FoundInfoTab = ({control, clientGuid, clientName}: AccountTabType) => {
    const stackItemStyles = {root: {width: 'calc(50% - 16px)'}};
    const {formatMessage} = useIntl();
    const {isWorkpapers} = useTenantInfo();
    const {showClientModal} = useWorkflows();
    const {currentClientData, isCurrentClientLoading} = useGetClientByGuid(clientGuid || '');
    const {auditFirms, isLoading: isAuditFirmsLoading} = useGetAuditFirms();
    const theme = useTheme();

    const contacts = useMemo<IDropdownOption[]>(() => {
        return currentClientData?.data.contacts.map(x => ({key: x.id, text: `${x.firstName} ${x.lastName}`})) || [];
    }, [currentClientData]);


    const auditFirmOptions = useMemo<IDropdownOption[]>(() => {
        return auditFirms?.data.map(x => ({key: x.id, text: x.auditFirmTitle})) || [];
    }, [auditFirms?.data])
    return (
        <Card styles={CardTableContainerStyles}>
            <Stack horizontal tokens={{childrenGap: 16}} wrap>
                <Stack.Item styles={stackItemStyles}>
                    <ControlledTextField
                        name='name'
                        required
                        label={formatMessage({id: 'name'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={stackItemStyles}>
                    <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: isWorkpapers ? 'trusteeName' : 'clientName'})}</Label>
                    <Link underline style={{ color: theme.schemes?.default?.palette.blue }} onClick={() => {
                        showClientModal(clientGuid || '');
                    }}>{clientName}</Link>

                </Stack.Item>

                {isWorkpapers && (
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledDropdown
                            options={auditFirmOptions}
                            disabled={isAuditFirmsLoading}
                            name='auditFirmId'
                            label={formatMessage({id: 'auditor'})}
                            control={control}
                        />
                    </Stack.Item>
                )}
                {!isWorkpapers && (
                    <Stack.Item styles={stackItemStyles}>
                        <ControlledTextField name='trustee' required
                                             label={formatMessage({id: 'trustee'})}
                                             control={control}/>
                    </Stack.Item>
                )}

                <Stack.Item styles={stackItemStyles}>
                    <ControlledDropdown
                        options={contacts}
                        name='contactId'
                        required
                        label={formatMessage({id: isWorkpapers ? 'accountant' : 'primaryContact'})}
                        control={control}
                        rules={{
                            required: formatMessage({id: 'requiredField'}),
                            minLength: {value: 3, message: formatMessage({id: 'minLength'}, {min: 3})},
                        }}
                    />
                </Stack.Item>

                <Stack.Item styles={{root: {width: '100%'}}}>
                    <ControlledTextField name='abn' label={formatMessage({id: 'abn'})} control={control}/>
                </Stack.Item>
            </Stack>

        </Card>
    );
};
